import React from 'react'
import PropTypes from 'prop-types'
import { get, noop } from 'lodash'
import { connect } from 'react-redux'
import { injectIntl } from 'react-intl'
import { compose } from 'redux'

import * as UXPDialog from 'common/components/dialog/UXPDialog'
import DetailView from 'protected/common/DetailView'
import {
  TextPrimaryFillButton,
  TextSecondaryButton,
} from 'common/components/button/Button'

import withStyles from '@mui/styles/withStyles'
import Radio from '@mui/material/Radio'
import RadioGroup from '@mui/material/RadioGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import FormControl from '@mui/material/FormControl'
import Typography from '@mui/material/Typography'

import { loadUser } from 'common/actions/user'
import { updateNotificationChannel } from 'common/processes/userManagement'
import * as selectors from '../selectors'
import { labelFormatter } from '../constants'
import { analyticsProvider } from '../../../App'
import { displayIdentifier } from 'common/utils/formatNumber'

const styles = () => ({
  formLabel: {
    fontSize: '1rem',
  },
})

class PreferredContactDetailView extends React.PureComponent {
  static propTypes = {
    loadUser: PropTypes.func,
    updateNotificationChannel: PropTypes.func,
    preferredContact: PropTypes.object,
    preferredContactList: PropTypes.array,
  }

  constructor(props) {
    super(props)

    this.flowType = 'detailView'
    this.pageTitle = 'preferred_contact_detail_view'
    this.baseEventTag = `${this.flowType}.${this.pageTitle}`
  }

  state = {
    preferredContact: this.props?.preferredContact?.value,
    inProgress: false,
    asyncError: '',
    changeDialogOpen: false,
  }

  onChangeDialogOpen = () => {
    analyticsProvider.sendAnalytics({
      type: 'event',
      action: 'click',
      event_category: 'button',
      event_label: `${this.baseEventTag}.change-dialog-open`,
      value: 0,
    })
    this.setState({ changeDialogOpen: true })
  }

  onChangeDialogClose = () => {
    analyticsProvider.sendAnalytics({
      type: 'event',
      action: 'click',
      event_category: 'button',
      event_label: `${this.baseEventTag}.change-dialog-close`,
      value: 0,
    })

    const { preferredContact } = this.props

    this.setState({
      changeDialogOpen: false,
      preferredContact: preferredContact.value,
    })
  }

  onPreferredContactChange = (e, preferredContact) =>
    this.setState({ preferredContact })

  getFormattedPreferredContact = () => {
    const { type, value } = this.props?.preferredContact
    const { appId } = this.props
    return displayIdentifier(value, type, appId)
  }

  getError = errorCode => {
    const { asyncError } = this.state

    if (asyncError) {
      return asyncError
    }

    if (errorCode) {
      return this.props.intl.formatMessage({ id: errorCode })
    }

    return ''
  }

  showNotification = () => {
    const { addNotification, intl } = this.props
    const entity = intl.formatMessage({ id: 'notification.preferred-contact' })

    addNotification({
      message: intl.formatMessage(
        {
          id: 'notification.updated-successfully',
        },
        { entity },
      ),
      variant: 'success',
    })
  }

  handlePreferredContactChange = () => {
    analyticsProvider.sendAnalytics({
      type: 'event',
      action: 'click',
      event_category: 'button',
      event_label: `${this.baseEventTag}.change-preferred-contact`,
      value: 0,
    })
    const { preferredContact } = this.state
    const { loadUser } = this.props

    this.setState({
      asyncError: '',
      inProgress: true,
    })

    updateNotificationChannel(preferredContact)
      .then(loadUser)
      .then(() => {
        this.setState({
          inProgress: false,
          asyncError: '',
        })
        this.onChangeDialogClose()
        this.showNotification()
      })
      .catch(err => {
        analyticsProvider.sendAnalytics({
          type: 'event',
          action: 'click',
          event_category: 'button',
          event_label: `${this.baseEventTag}.change-preferred-contact`,
          value: 1,
        })

        const errorCode = get(err, 'body.operationError[0].code')

        this.setState({
          inProgress: false,
          asyncError: this.getError(
            errorCode ? `error.${errorCode}` : 'error.try-again',
          ),
        })
      })
  }

  render() {
    const { intl, preferredContactList, classes, appId } = this.props
    const { changeDialogOpen, inProgress, preferredContact } = this.state

    const errorText = this.getError()
    const preferredContactFormatted = this.getFormattedPreferredContact()
    return (
      <DetailView
        title={intl.formatMessage({ id: 'profile.detail.preferred.title' })}
        description={intl.formatMessage({
          id: 'profile.detail.preferred.description',
        })}
      >
        <DetailView.Title
          title={preferredContactFormatted}
          onEditClick={this.onChangeDialogOpen}
        />

        <UXPDialog.Dialog
          open={changeDialogOpen}
          fullScreen
          fullWidth
          maxWidth="xs"
        >
          <UXPDialog.Title
            onClose={this.onChangeDialogClose}
            title={intl.formatMessage({
              id: 'profile.detail.preferred.update-preferred',
            })}
          />
          <UXPDialog.Content>
            <FormControl component="fieldset">
              <RadioGroup
                value={preferredContact}
                onChange={this.onPreferredContactChange}
              >
                {preferredContactList.map(({ id, value, type }) => {
                  const label = displayIdentifier(value, type, appId)
                  return (
                    <FormControlLabel
                      classes={{
                        root: classes.formLabel,
                      }}
                      key={`${value}-${id}`}
                      value={value}
                      control={<Radio color="primary" />}
                      label={<Typography variant="body1">{label}</Typography>}
                    />
                  )
                })}
              </RadioGroup>
            </FormControl>

            {errorText && (
              <Typography color="error" variant="body1">
                {errorText}
              </Typography>
            )}
          </UXPDialog.Content>
          <UXPDialog.Actions>
            <TextSecondaryButton onClick={this.onChangeDialogClose}>
              Cancel
            </TextSecondaryButton>
            <TextPrimaryFillButton
              onClick={this.handlePreferredContactChange}
              disabled={
                inProgress ||
                (preferredContactList?.length === 1 &&
                  preferredContactList[0]?.value === preferredContact)
              }
            >
              Save
            </TextPrimaryFillButton>
          </UXPDialog.Actions>
        </UXPDialog.Dialog>
      </DetailView>
    )
  }
}

const mapStateToProps = state => ({
  preferredContact: selectors.getPreferredContact(state),
  preferredContactList: selectors.getPreferredContactList(state),
  appId: state.configuration.config.id,
})

const mapDispatchToProps = { loadUser }

const connectedProps = connect(mapStateToProps, mapDispatchToProps)

export default compose(
  connectedProps,
  injectIntl,
  withStyles(styles),
)(PreferredContactDetailView)
