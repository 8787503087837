import React, { useState } from 'react'
import { get } from 'lodash'
import { useIntl } from 'react-intl'
import TwoStepSetup from '../../signin-v2/2FASteps/TwoStepSetup'
import TwoStepTypeSelect from '../../signin-v2/2FASteps/TwoStepTypeSelect'
import SelectEmailStep from '../../signin-v2/2FASteps/Email/SelectEmailStep'
import SelectMobileStep from '../../signin-v2/2FASteps/Mobile/SelectMobileStep'
import QRScanStep from '../../signin-v2/2FASteps/TOTP/QRScanStep'
import TOTPSetupVerificationStep from '../../signin-v2/2FASteps/TOTP/TOTPSetupVerificationStep'
import TwoFASetupSuccessStep from '../../signin-v2/2FASteps/TwoFASetupSuccessStep'
import { useHistory } from 'react-router-dom'
import VerifyUsername from './VerifyUsername'
import FlowLayout from 'common/layouts/FlowLayout'

const MfaSetup = props => {
  const { response } = props
  const intl = useIntl()
  const history = useHistory()

  const [step, setStep] = useState()
  const [emailSetup, setEmailSetup] = useState()
  const [mobileSetup, setMobileSetup] = useState()
  const [otpData, setOtpData] = useState()
  const [qrScanData, setQrScanData] = useState()
  const [lastStepData, setLastStepData] = useState()

  const twoStepTypeSelectPromt = response => {
    setStep('TwoStepTypeSelect')
  }

  const handleSelectEmailStep = res => {
    setStep('emailStep')
    setEmailSetup(res)
  }

  const handleSelectMobileStep = res => {
    setStep('mobileStep')
    setMobileSetup(res)
  }

  const handleTwoFASetupSuccess = () => {
    setStep('TwoFASetupSuccess')
  }

  const handleOtpStep = res => {
    setOtpData(res)
    setStep('OtpStep')
  }

  const handleSelectQRScanStep = res => {
    setQrScanData(res)
    setStep('QRCodeScanStep')
  }

  const handleTOTRedirectStep = qrData => {
    setQrScanData(qrData)
    setStep('TOTStep')
  }

  const goHome = () => {
    history.push('/')
  }

  const handleVerified = (verified, response, code, pkat) => {
    const isLastStep = get(response, 'data.lastStep')
    if (isLastStep) {
      setStep('TwoFASetupSuccess')
      setLastStepData(response)
    } else {
      goHome()
    }
  }

  if (step === 'TwoFASetupMethodPrompt') {
    return <TwoStepSetup twoStepTypeSelect={twoStepTypeSelectPromt} />
  }

  if (step === 'TwoStepTypeSelect') {
    return (
      <TwoStepTypeSelect
        response={response}
        handleSelectEmailStep={handleSelectEmailStep}
        handleSelectMobileStep={handleSelectMobileStep}
        handleSelectQRScanStep={handleSelectQRScanStep}
      />
    )
  }

  if (step === 'emailStep') {
    return (
      <SelectEmailStep
        emailSetup={emailSetup}
        handleTwoFASetupSuccess={handleTwoFASetupSuccess}
        handleOtpStep={handleOtpStep}
      />
    )
  }

  if (step === 'mobileStep') {
    return (
      <SelectMobileStep
        mobileSetup={mobileSetup}
        handleTwoFASetupSuccess={handleTwoFASetupSuccess}
        handleOtpStep={handleOtpStep}
      />
    )
  }

  if (step === 'TwoFASetupSuccess') {
    return <TwoFASetupSuccessStep goHome={goHome} lastStepData={lastStepData} />
  }

  if (step === 'QRCodeScanStep') {
    return (
      <QRScanStep
        qrScanData={qrScanData}
        handleOtpStep={handleOtpStep}
        handleSelectQRScanStep={handleSelectQRScanStep}
        handleTOTRedirectStep={handleTOTRedirectStep}
      />
    )
  }

  if (step === 'TOTStep') {
    return (
      <TOTPSetupVerificationStep
        qrScanData={qrScanData}
        handleSelectQRScanStep={handleSelectQRScanStep}
        handleTwoFASetupSuccess={handleTwoFASetupSuccess}
      />
    )
  }

  if (step === 'OtpStep') {
    const titleType =
      otpData.type === 'phone-setup' ? 'setupPhoneOTP' : 'setupEmailOTP'
    return (
      <FlowLayout
        title={intl.formatMessage({
          id: `separatedMultiStep.authenticate-${titleType}.title`,
        })}
      >
        <VerifyUsername
          process="sign-up"
          actionName="onboardUser"
          onVerified={handleVerified}
          history={history}
          {...otpData}
        />
      </FlowLayout>
    )
  }

  return (
    <TwoStepSetup twoStepTypeSelect={twoStepTypeSelectPromt} goHome={goHome} />
  )
}

export default MfaSetup
