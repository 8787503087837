import React, { useState } from 'react'
import { Body } from '../../common/components/Typography'
import SidebarNavigationLayout from 'common/layouts/SidebarNavigationLayout'
import Grid from '@mui/material/Grid'
import { useIntl } from 'react-intl'
import Paper from '@mui/material/Paper'
import { List } from '@admin-ui-common/base-user'
import ProfileItem from '../profile/ProfileItem'
import Divider from '@mui/material/Divider'
import Box from '@mui/material/Box'
import getSocialProviderName from '../../common/utils/getSocialProviderName'
import { getSocialMediaInfo } from '../../common/selectors/settings'
import { useSelector } from 'react-redux'
import {
  getConnection,
  isCredentialSet,
  isPinSet,
  isPasscodeSet,
  twoStepVerificationMethodsCount,
} from '../profile/selectors'
import { isEmpty } from 'lodash'
import SocialListItem from './SocialListItem'
import { routes } from './constants'
import ListSectionTitle from 'protected/profile/ListSectionTitle'
import * as UXPDialog from 'common/components/dialog/UXPDialog'
import { TextPrimaryFillButton } from '../../common/components/button/Button'
import { hasUIFeatureFlag } from 'common/services/FeatureFlagsService'
import { isSectionVisible } from 'common/utils/getFeatureFlag'

const securitySection = [
  'showPasswordField',
  'showPINField',
  'showPasscodeField',
  'showMFAConfigurationPage',
]

const SecurityAndSignInView = ({ addNotification }) => {
  const intl = useIntl()

  const {
    CHANGE_PASSWORD,
    TWO_STEP_VERIFICATION,
    PIN,
    PASSCODE,
    FIDO2,
  } = routes

  const state = useSelector(state => state)

  const passwordSet = isCredentialSet(state)
  const pinSet = isPinSet(state)
  const passcodeSet = isPasscodeSet(state)

  const twoStepVerificationMethodsNumber = twoStepVerificationMethodsCount(
    state,
  )

  const passwordValue = passwordSet ? '••••••••••' : null
  const twoStepVerificationValue = twoStepVerificationMethodsNumber
    ? intl.formatMessage(
        {
          id: 'profile.detail.two-step-verification-methods-set-up',
        },
        {
          twoStepVerificationMethodsNumber: twoStepVerificationMethodsNumber,
        },
      )
    : null
  const pinValue = pinSet ? '••••' : null
  const passcodeValue = passcodeSet ? '••••' : null

  const supportedSocialMedia = getSocialMediaInfo(state)

  const getSocialConnectedValue = providerId => {
    const connection = getConnection(state, providerId)
    if (!isEmpty(connection)) {
      return intl.formatMessage({
        id: 'profile.detail.social-media.connected',
      })
    }

    return ''
  }

  const [showDialog, setShowDialog] = useState(false)
  const [dialogTitle, setDialogTitle] = useState(null)
  const [dialogContent, setDialogContent] = useState(null)

  const closeDialog = () => setShowDialog(false)

  return (
    <SidebarNavigationLayout
      title={intl.formatMessage({
        id: 'security-and-sign-in.basic.section.title',
      })}
    >
      {isSectionVisible('showSecuritySection', securitySection) && (
        <Box component={Paper} marginBottom={3} py={2}>
          <UXPDialog.Dialog open={showDialog} maxWidth="xs">
            <UXPDialog.Title title={dialogTitle} onClose={closeDialog} />
            <UXPDialog.Content>
              <Body>{dialogContent}</Body>
            </UXPDialog.Content>
            <UXPDialog.Actions>
              <TextPrimaryFillButton onClick={closeDialog}>
                {intl.formatMessage({ id: 'common.button.ok' })}
              </TextPrimaryFillButton>
            </UXPDialog.Actions>
          </UXPDialog.Dialog>
          <Grid item xs={12}>
            <List
              subheader={
                <ListSectionTitle
                  title={intl.formatMessage({
                    id: 'security-and-sign-in.security.section.title',
                  })}
                />
              }
              disablePadding
            >
              {hasUIFeatureFlag('showPasswordField') && (
                <>
                  <ProfileItem
                    name={intl.formatMessage({ id: CHANGE_PASSWORD.label })}
                    value={passwordValue}
                    path={`/security-and-sign-in${CHANGE_PASSWORD.path}`}
                    placeholder={intl.formatMessage({
                      id: CHANGE_PASSWORD.placeholder,
                    })}
                    interactive
                    addNotification={addNotification}
                  />
                  <Divider light />
                </>
              )}

              {hasUIFeatureFlag('showMFAConfigurationPage') && (
                <>
                  <ProfileItem
                    name={intl.formatMessage({
                      id: TWO_STEP_VERIFICATION.label,
                    })}
                    value={twoStepVerificationValue}
                    path={`/security-and-sign-in${TWO_STEP_VERIFICATION.path}`}
                    placeholder={intl.formatMessage({
                      id: TWO_STEP_VERIFICATION.placeholder,
                    })}
                    interactive
                    addNotification={addNotification}
                  />
                  <Divider light />
                </>
              )}
              {hasUIFeatureFlag('showPasscodeField') && (
                <>
                  <ProfileItem
                    name={intl.formatMessage({ id: PASSCODE.label })}
                    value={passcodeValue}
                    path={`/security-and-sign-in${PASSCODE.path}`}
                    placeholder={intl.formatMessage({
                      id: PASSCODE.placeholder,
                    })}
                    interactive
                    addNotification={addNotification}
                  />
                  <Divider light />
                </>
              )}
              {hasUIFeatureFlag('showPINField') && (
                <ProfileItem
                  name={intl.formatMessage({ id: PIN.label })}
                  value={pinValue}
                  path={`/security-and-sign-in${PIN.path}`}
                  placeholder={intl.formatMessage({
                    id: PIN.placeholder,
                  })}
                  interactive
                  addNotification={addNotification}
                />
              )}
              {hasUIFeatureFlag('enableFIDO2') && (
                <>
                  <Divider light />
                  <ProfileItem
                    name={intl.formatMessage({ id: FIDO2.label })}
                    path={`/security-and-sign-in${FIDO2.path}`}
                    placeholder={intl.formatMessage({
                      id: FIDO2.placeholder,
                    })}
                    interactive
                    addNotification={addNotification}
                  />
                </>
              )}
            </List>
          </Grid>
        </Box>
      )}
      {hasUIFeatureFlag('showSocialSignInSection') &&
        supportedSocialMedia &&
        supportedSocialMedia.length > 0 && (
          <Box component={Paper} marginBottom={3} py={2}>
            <Grid item xs={12}>
              <List
                subheader={
                  <ListSectionTitle
                    title={intl.formatMessage({
                      id: 'profile.external.section.title',
                    })}
                    subtitle={intl.formatMessage({
                      id: 'profile.external.section.message',
                    })}
                  />
                }
                disablePadding
              >
                {supportedSocialMedia.map((providerId, index) => (
                  <>
                    <SocialListItem
                      key={providerId}
                      name={intl.formatMessage(
                        { id: 'profile.label.social' },
                        { providerTitle: getSocialProviderName(providerId) },
                      )}
                      providerId={providerId}
                      value={getSocialConnectedValue(providerId)}
                      placeholder={intl.formatMessage(
                        {
                          id: 'profile.placeholder.social-media.not-connected',
                        },
                        {
                          socialProvider: getSocialProviderName(providerId),
                        },
                      )}
                      icon={true}
                      setShowDialog={setShowDialog}
                      setDialogTitle={setDialogTitle}
                      setDialogContent={setDialogContent}
                    />
                    {index < supportedSocialMedia.length - 1 && (
                      <Divider light />
                    )}
                  </>
                ))}
              </List>
            </Grid>
          </Box>
        )}
    </SidebarNavigationLayout>
  )
}

export default SecurityAndSignInView
