import React, { useState } from 'react'
import TwoStepVerificationWrapper from '../TwoStepVerificationWrapper'
import { List } from '@admin-ui-common/base-user'
import { useTheme } from '@mui/styles'

import SettingsBackupRestore from '@mui/icons-material/SettingsBackupRestore'
import DeleteIcon from '@mui/icons-material/Delete'

import { Box, CircularProgress, Typography } from '@mui/material'
import { TextPrimaryButton } from 'common/components/button/Button'

import { useIntl } from 'react-intl'
import GeneratedBackupCodesDialog from './GeneratedBackupCodesDialog'
import { generateBackupCodes } from 'common/actions/twoStepVerification'
import { useDispatch } from 'react-redux'
import { addNotification } from 'common/actions/notification'
import { loadUser } from 'common/actions/user'
import DeleteBUCDialog from './DeleteBUCDialog'

const generateBUC = (dispatch, setBUC, showGeneratedBUC, setLoading, intl) => {
  setLoading(true)
  generateBackupCodes()(dispatch)
    .then(res => {
      dispatch(loadUser())
      const backupCodes = res?.body?.codes.map(o => o?.backupCode)
      setBUC(backupCodes)
      showGeneratedBUC()
    })
    .catch(() => {
      dispatch(
        addNotification({
          message: intl.formatMessage({
            id: 'error.try-again',
          }),
          variant: 'error',
        }),
      )
    })
    .finally(() => {
      setLoading(false)
    })
}

const ActiveCodes = ({
  backupCodeCount,
  backupCodeCreatedDate,
  showGeneratedBUC,
  setBUC,
  loading,
  setLoading,
  setDeleteBUCDialogOpen,
  intl,
}) => {
  const theme = useTheme()
  const backupCodesActiveText =
    backupCodeCount > 1
      ? backupCodeCount +
        intl.formatMessage({ id: 'security.text.buc-count-multiple' })
      : intl.formatMessage({ id: 'security.text.buc-count-single' })

  const dispatch = useDispatch()

  const buttonText =
    backupCodeCount > 0
      ? intl.formatMessage({ id: 'security.button.re-generate-buc' })
      : intl.formatMessage({ id: 'security.button.generate-buc' })

  return (
    <>
      <List.ListItem style={{ marginBottom: '16px', marginTop: '8px' }}>
        <List.ListItemIcon style={{ alignSelf: 'flex-start' }}>
          <SettingsBackupRestore
            style={{ fontSize: '40px', color: theme?.palette?.text?.primary }}
          />
        </List.ListItemIcon>
        <List.ListItemText
          primary={
            <Typography fontWeight="bold" variant="body1">
              {backupCodeCount > 0
                ? backupCodesActiveText
                : intl.formatMessage({ id: 'security.text.buc-count-zero' })}
            </Typography>
          }
          secondary={
            backupCodeCount && backupCodeCreatedDate
              ? 'Created date: ' + backupCodeCreatedDate
              : null
          }
        />
        {backupCodeCount > 0 && !loading && (
          <List.ListItemSecondaryAction>
            <DeleteIcon
              style={{
                fontSize: 24,
                color: theme?.palette?.text?.secondary,
                cursor: 'pointer',
              }}
              onClick={() => setDeleteBUCDialogOpen(true)}
            />
          </List.ListItemSecondaryAction>
        )}
      </List.ListItem>
      <Box mt={2} ml={8}>
        <TextPrimaryButton
          disabled={loading}
          onClick={() =>
            generateBUC(dispatch, setBUC, showGeneratedBUC, setLoading, intl)
          }
        >
          {loading ? <CircularProgress size="24px" /> : buttonText}
        </TextPrimaryButton>
      </Box>
    </>
  )
}

const ManageBUCView = ({ backupCodeCount, backupCodeCreatedDate }) => {
  const intl = useIntl()

  const [generatedBUCDialogOpen, setGeneratedBUCDialogOpen] = useState(false)
  const [deleteBUCDialogOpen, setDeleteBUCDialogOpen] = useState(false)

  const [backupCodes, setBUC] = useState([])

  const [loading, setLoading] = useState(false)

  return (
    <TwoStepVerificationWrapper
      title={
        backupCodeCount > 0
          ? intl.formatMessage({
              id: 'security.label.manage-buc-title',
            })
          : intl.formatMessage({
              id: 'security.label.generate-buc-title',
            })
      }
      subtitle={
        backupCodeCount > 0
          ? intl.formatMessage({
              id: 'security.label.manage-buc-subtitle',
            })
          : intl.formatMessage({
              id: 'security.label.generate-buc-subtitle',
            })
      }
    >
      <List>
        <ActiveCodes
          backupCodeCount={backupCodeCount}
          backupCodeCreatedDate={backupCodeCreatedDate}
          showGeneratedBUC={() => setGeneratedBUCDialogOpen(true)}
          setBUC={setBUC}
          loading={loading}
          setLoading={setLoading}
          setDeleteBUCDialogOpen={setDeleteBUCDialogOpen}
          intl={intl}
        />
      </List>
      <GeneratedBackupCodesDialog
        open={generatedBUCDialogOpen}
        backupCodes={backupCodes}
        onClose={() => {
          setGeneratedBUCDialogOpen(false)
        }}
      />
      <DeleteBUCDialog
        open={deleteBUCDialogOpen}
        onClose={() => setDeleteBUCDialogOpen(false)}
      />
    </TwoStepVerificationWrapper>
  )
}

export default ManageBUCView
