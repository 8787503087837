import qs from 'qs'
import trimStart from 'lodash/trimStart'
import 'isomorphic-fetch'
import { X_ULM_LOCALE } from '@admin-ui-common/utils'
import axios from 'axios'

import FeatureFlagsService from 'common/services/FeatureFlagsService'
import getCurrentSubRouteConfiguration from './getCurrentSubRouteConfiguration'
import {
  clearAccessToken,
  getAccessToken,
  storeAccessToken,
} from './accessToken'
import { get } from 'lodash'

const defaults = {
  headers: {
    'content-type': 'application/json',
    'x-process-debug': true,
  },
}

const signOutResponseStatuses = [401, 403]

const getResponseError = (response, body = {}) => ({
  body,
  metadata: response,
  status: response.status,
  statusText: response.statusText,
})

const processLocale = locale => {
  if (locale) {
    window.localStorage.setItem(X_ULM_LOCALE, locale)
  }
}

const getXULMQueryComponent = () => {
  let parsedQuery = qs.parse(trimStart(window.location.search, '?'))

  const forceDisablePasswordPrompt = FeatureFlagsService.get(
    'uiFeatureFlags.idp.forceDisablePasswordPrompt',
  )
  const enableProductIdAlias = FeatureFlagsService.get(
    'uiFeatureFlags.idp.enableProductIdAlias',
  )

  if (forceDisablePasswordPrompt)
    parsedQuery = { ...parsedQuery, 'x-ulm-create-password-prompt': false }

  if (enableProductIdAlias) {
    const productAlias = get(parsedQuery, 'x-ulm-product_id')

    if (productAlias) {
      delete parsedQuery['x-ulm-product_id']
      parsedQuery = { ...parsedQuery, 'x-ulm-productId': productAlias }
    }
  }

  const result = {}

  const queryKeysToIgnore = [
    /* use this array if you need to ignore some query types */
  ]

  Object.keys(parsedQuery).forEach(key => {
    let parsedKey = key.match(/x-ulm-(.*)/i)

    if (Array.isArray(parsedKey)) {
      parsedKey = parsedKey[1]
    }

    if (parsedKey && !queryKeysToIgnore.includes(parsedKey.toLowerCase())) {
      result[parsedKey] = parsedQuery[key]
    }
  })

  const queryLocale = result.locale
  processLocale(queryLocale)

  return result
}

const request = (method, url, options = {}) => {
  const verb = method.toUpperCase()

  const { ignoreErrorRedirect, omitCredentials, noXProcessDebug } = options
  delete options.ignoreErrorRedirect

  const token = getAccessToken()

  const accessTokenHeaders =
    token && !omitCredentials ? { Authorization: `Bearer ${token}` } : {}

  const defaultHeaders = {
    ...defaults.headers,
  }
  if (noXProcessDebug) {
    delete defaultHeaders['x-process-debug']
  }

  const config = {
    withCredentials: !omitCredentials,
    headers: {
      ...defaultHeaders,
      ...options.headers,
      ...(!options.omitAccessToken ? accessTokenHeaders : {}), // some public endpoints may fail when passed invalid Authorization
    },
    params: options.params,
    method: verb,
  }

  const hasRequestBody = verb === 'PUT' || verb === 'POST'
  if (hasRequestBody) {
    config.data = JSON.stringify(options.data)
  }

  const [urlRoute, urlQuery = ''] = url.split('?')
  const parsedQuery = qs.parse(decodeURIComponent(urlQuery), {
    decoder: str => str,
  })
  const xULMQueryComponent = getXULMQueryComponent()
  const encodedQuery = qs.stringify({ ...parsedQuery, ...xULMQueryComponent })
  const parsedUrl = `${urlRoute}${encodedQuery && `?${encodedQuery}`}`

  return axios({
    url: parsedUrl,
    ...config,
  })
    .then(response => {
      const accessToken = get(response.headers, 'x-access-token')
      if (accessToken) {
        storeAccessToken(accessToken)
      }
      return {
        body: response.data,
        metadata: response,
      }
    })
    .catch(error => {
      if (error.response) {
        const responseError = getResponseError(
          error.response,
          error.response.data,
        )
        if (
          !ignoreErrorRedirect &&
          signOutResponseStatuses.includes(responseError.status)
        ) {
          // TODO Replace with history.push when we have Thunk
          // and middleware with access to store/dispatch
          window.localStorage.setItem('__logoutPhase__', true)

          // Access token should be cleared when being logged out
          clearAccessToken()

          const { currentSubRoute } = getCurrentSubRouteConfiguration()

          window.location.pathname = currentSubRoute
        }
        return Promise.reject(responseError)
      }
    })
}

export const killUserSession = () => {
  // TODO Replace with history.push when we have Thunk
  // and middleware with access to store/dispatch
  window.localStorage.setItem('__logoutPhase__', true)
  clearAccessToken()
}

const verbs = ['get', 'put', 'post', 'delete']
const i = verbs.reduce((obj, method) => {
  obj[method] = (url, options) => request(method, url, options)
  return obj
}, {})

export default i
