import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { injectIntl } from 'react-intl'
import { withRouter } from 'react-router-dom'

import { Body } from 'common/components/Typography'
import Box from '@mui/material/Box'
import * as UXPDialog from 'common/components/dialog/UXPDialog'
import { UserButton } from '@admin-ui-common/base-user'

import { getAccountNameLabelById } from 'common/selectors/accounts'
import { unlinkAccount, loadAccounts } from 'common/actions/accounts'
import { addNotification } from 'common/actions/notification'
import { resolveErrorEntity } from 'common/utils/processBackendErrors'
import { analyticsProvider } from '../../../App'

const UnlinkAccountDialog = ({
  accountId,
  accountName,
  intl,
  open,
  toggleDialog,
  addNotification,
  loadAccounts,
  history,
}) => {
  const [inProgress, setInProgress] = useState(false)

  const onUnlinkAccount = e => {
    e.stopPropagation()

    analyticsProvider.sendAnalytics({
      type: 'event',
      action: 'click',
      event_category: 'button',
      event_label: `unlinkAccount.unlink`,
      value: 0,
    })

    setInProgress(true)

    unlinkAccount(accountId)
      .then(loadAccounts)
      .then(() => {
        addNotification({
          message: intl.formatMessage({ id: 'notification.account-unlinked' }),
          variant: 'success',
        })
        history.push('/accounts')
      })
      .catch(err => {
        analyticsProvider.sendAnalytics({
          type: 'event',
          action: 'click',
          event_category: 'button',
          event_label: `unlinkAccount.unlink`,
          value: 1,
        })
        setInProgress(false)
        addNotification({
          message: resolveErrorEntity({ intl, error: err.body }),
        })
      })
  }

  return (
    <UXPDialog.Dialog
      open={open}
      fullWidth
      maxWidth="xs"
      onClose={toggleDialog}
    >
      <UXPDialog.Title
        onClose={toggleDialog}
        title={intl.formatMessage({ id: 'accounts.are-you-sure-unlink' })}
      />
      <UXPDialog.Content>
        <Box mt={1} mb={2}>
          <Body>
            {intl.formatMessage(
              { id: 'accounts.you-will-lose-access' },
              { name: accountName },
            )}
          </Body>
        </Box>
      </UXPDialog.Content>
      <UXPDialog.Actions>
        <UserButton
          disableElevation
          disableRipple
          onClick={toggleDialog}
          color="secondary"
          variant="outlined"
          size={'small'}
        >
          Cancel
        </UserButton>

        <UserButton
          destructive
          disableElevation
          disableRipple
          onClick={onUnlinkAccount}
          disabled={inProgress}
          size={'small'}
        >
          {intl.formatMessage({ id: 'accounts.unlink-account' })}
        </UserButton>
      </UXPDialog.Actions>
    </UXPDialog.Dialog>
  )
}

UnlinkAccountDialog.propTypes = {
  accountId: PropTypes.number,
  intl: PropTypes.object,
  accountName: PropTypes.string,
  unlinkAccount: PropTypes.func,
  loadAccounts: PropTypes.func,
  history: PropTypes.object,
}

const mapState = (state, { accountId }) => ({
  accountName: getAccountNameLabelById(state, accountId),
})
const mapDispatch = {
  addNotification,
  loadAccounts,
}

const connectedToProps = connect(mapState, mapDispatch)

export default compose(
  connectedToProps,
  injectIntl,
  withRouter,
)(UnlinkAccountDialog)
