import Wizard from 'common/components/wizard/Wizard'
import { findIndex } from 'lodash'
import EnforceAccountAssociationStep from '../lander/EnforceAccountAssociation/EnforceAccountAssociationStep'
import AccountAssociationOtpStep from '../lander/EnforceAccountAssociation/OtpStep'
import LoggedIn from '../lander/signin-v2/LoggedIn'
import { verifyCode } from 'common/processes/token'

const steps = {
  AccountIdentifierPrompt: 'AccountIdentifierPrompt',
  LoggedIn: 'LoggedIn',
}

const EnforceAccountAssociationWizard = ({ initialData }) => {
  const preserveQueryArguments = true

  const pages = {
    EnforceAccountAssociationStep: 'EnforceAccountAssociationStep',
    AccountAssociationOtpStep: 'AccountAssociationOtpStep',
    LoggedIn: 'LoggedIn',
  }

  const pageOrder = [
    pages.EnforceAccountAssociationStep,
    pages.AccountAssociationOtpStep,
    pages.LoggedIn,
  ]

  const getPageIndex = pageName =>
    findIndex(pageOrder, page => page === pageName)

  const sharedProps = {
    steps,
    pages,
    getPageIndex,
  }

  const pageComponents = {
    [pages.EnforceAccountAssociationStep]: (
      <EnforceAccountAssociationStep
        {...sharedProps}
        initialData={initialData}
        preserveQueryArguments={preserveQueryArguments}
      />
    ),
    [pages.AccountAssociationOtpStep]: (
      <AccountAssociationOtpStep
        verifyCode={verifyCode}
        {...sharedProps}
        preserveQueryArguments={preserveQueryArguments}
      />
    ),
    [pages.LoggedIn]: (
      <LoggedIn preserveQueryArguments={preserveQueryArguments} />
    ),
  }

  return <Wizard>{pageOrder.map(page => pageComponents[page])}</Wizard>
}

export default EnforceAccountAssociationWizard
