import React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { get } from 'lodash'

import Snackbar from '@mui/material/Snackbar'
import withStyles from '@mui/styles/withStyles'

import {
  addNotification,
  dismissNotification,
} from 'common/actions/notification'

const anchorOrigin = {
  vertical: 'bottom',
  horizontal: 'left',
}

const NOTIFICATION_DURATION_MS = 3000

const styles = theme => ({
  content: {
    [theme.breakpoints.down('sm')]: {
      borderRadius: '0!important',
    },
  },
})

class WizardOnlyNotification extends React.Component {
  static propTypes = {
    dismissNotification: PropTypes.func,
    notification: PropTypes.object,
    classes: PropTypes.object,
  }

  state = {
    open: false,
  }

  componentWillReceiveProps(nextProps, nextState) {
    const nextKey = get(nextProps, 'notification.key', null)

    // Immediately begin dismissing current message to start showing new one
    if (nextKey) {
      this.setState({ open: !nextState.open })
    }
  }

  handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }
    this.setState({ open: false })
  }

  render() {
    const { notification, dismissNotification, classes } = this.props
    const { key, message } = notification || {}

    return (
      <Snackbar
        key={key}
        anchorOrigin={anchorOrigin}
        open={this.state.open}
        autoHideDuration={NOTIFICATION_DURATION_MS}
        onClose={this.handleClose}
        ContentProps={{
          'aria-describedby': 'message-id',
          classes: {
            root: classes.content,
          },
        }}
        message={<span id="message-id">{message}</span>}
        TransitionProps={{
          onExited: dismissNotification,
        }}
      />
    )
  }
}

const mapState = ({ notification }) => ({ notification })
const mapDispatch = {
  addNotification,
  dismissNotification,
}
const connectedProps = connect(mapState, mapDispatch)

export default compose(
  connectedProps,
  withStyles(styles),
)(WizardOnlyNotification)
