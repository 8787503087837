import React from 'react'
import PropTypes from 'prop-types'
import { useIntl } from 'react-intl'

import { UserButton } from '@admin-ui-common/base-user'

const LinkAccountButton = ({ onClick, disabled = false }) => {
  const intl = useIntl()

  return (
    <UserButton
      disableElevation
      disableRipple
      onClick={onClick}
      disabled={disabled}
    >
      {intl.formatMessage({ id: 'accounts.link-accounts.button' })}
    </UserButton>
  )
}

LinkAccountButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
}

export default LinkAccountButton
