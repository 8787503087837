import { Component } from 'react'
import PropTypes from 'prop-types'
import { get } from 'lodash'
import { injectIntl } from 'react-intl'
import { MobileNumberInput } from '@admin-ui-common/base-user'
import * as UXPDialog from 'common/components/dialog/UXPDialog'
import {
  TextPrimaryFillButton,
  TextSecondaryButton,
  Button,
} from 'common/components/button/Button'
import TextField from 'common/components/textfield/TextField'
import isEmail from 'common/utils/isEmail'
import isPhone from 'common/utils/isPhone'

import {
  addOrUpdateAuthnIdentifier,
  removeUserAttribute,
} from 'common/processes/userManagement'
import { analyticsProvider } from '../../../App'
import Box from '@mui/material/Box'
import { Body2, Caption } from '../../../common/components/Typography'
import { loadUser } from '../../../common/actions/user'
import { addNotification } from '../../../common/actions/notification'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { BackendErrorNotification } from 'common/components/backendErrorNotification/BackendErrorNotification'
import { Collapse } from '@mui/material'
import getCountryCode from 'common/utils/getCountryCode'
import { hasUIFeatureFlag } from 'common/services/FeatureFlagsService'
import {
  formatErrorMessage,
  getErrorCodes,
  fallbackId,
} from 'common/utils/processBackendErrors'
import stripIdentity from 'common/utils/stripIdentity'
import formatNumber from 'common/utils/formatNumber'

const messagesMap = {
  email: {
    add: {
      title: 'profile.detail.email.add-email',
      description: 'profile.detail.email.make-sure-it-is-your-email',
    },
    update: {
      title: 'profile.detail.email.update-email',
      description: 'profile.detail.email.make-sure-it-is-your-email',
      hint: 'profile.detail.email.email-will-be-updated',
    },
    delete: {
      title: 'profile.detail.email.remove-email',
      description: 'profile.detail.email.email-will-be-removed',
    },
    owner: 'profile.label.email',
    minMFADeleteError: {
      title: 'profile.detail.email.cant-remove-email',
      description: 'profile.detail.email.email-cant-be-removed',
    },
    minMFAUpdateError: {
      title: 'profile.detail.email.cant-update-email',
      description: 'profile.detail.email.email-cant-be-updated',
    },
  },
  mobile: {
    add: {
      title: 'profile.detail.mobile.add-mobile',
      description: 'profile.detail.mobile.make-sure-it-is-your-mobile',
    },
    update: {
      title: 'profile.detail.mobile.update-mobile',
      description: 'profile.detail.mobile.make-sure-it-is-your-mobile',
      hint: 'profile.detail.mobile.mobile-will-be-updated',
    },
    delete: {
      title: 'profile.detail.mobile.remove-mobile',
      description: 'profile.detail.mobile.mobile-will-be-removed',
    },
    owner: 'profile.detail.mobile.title',
    minMFADeleteError: {
      title: 'profile.detail.mobile.cant-remove-number',
      description: 'profile.detail.mobile.number-cant-be-removed',
    },
    minMFAUpdateError: {
      title: 'profile.detail.mobile.cant-update-number',
      description: 'profile.detail.mobile.number-cant-be-updated',
    },
  },
}
const validatorMap = {
  email: isEmail,
  mobile: isPhone,
}

class ChangeAuthPage extends Component {
  static propTypes = {
    auth: PropTypes.string,
    wizard: PropTypes.object,
    pendingAuth: PropTypes.string,
    activatingAuth: PropTypes.string,
    showVerify: PropTypes.bool,
    mode: PropTypes.oneOf(['add', 'update', 'delete']),
    owner: PropTypes.oneOf(['email', 'mobile']),
    loadUser: PropTypes.func,
    addNotification: PropTypes.func,
  }

  constructor(props) {
    super(props)

    const { auth, activatingAuth, pendingAuth } = props
    const { iso2Code: defaultIso2Code, dialCode } = getCountryCode()
    const iso2Code = defaultIso2Code

    this.state = {
      errorDictionaryId: '',
      errorResponse: null,
      auth: activatingAuth || pendingAuth || auth,
      inProgress: false,
      minMFACountError: false,
      countryCode: dialCode,
      mobileNumber: '',
      iso2Code: iso2Code,
    }

    this.flowType = 'wizard'
    this.pageTitle = 'changeAuth'
    this.baseEventTag = `${this.flowType}.${this.pageTitle}`
  }

  componentDidMount() {
    analyticsProvider.sendAnalytics({
      type: 'page_view',
      page_title: this.pageTitle,
      page_path: `/${this.flowType}#${this.pageTitle}`,
    })
  }

  onValidateMobileNumber = event => {
    const mobileNumber = event.target.value.trim()

    this.isMobileNumberValid(mobileNumber)
  }

  onValidateAuth = event => {
    const auth = event.target.value.trim()

    this.isAuthValid(auth)
  }

  onUpdateAuth = () => {
    analyticsProvider.sendAnalytics({
      type: 'event',
      action: 'click',
      event_category: 'button',
      event_label: `${this.baseEventTag}.update-auth-next`,
      value: 0,
    })

    const { mode, auth, activatingAuth, pendingAuth, wizard } = this.props
    const { iso2Code } = this.state
    const newAuth = this.state.auth

    if (!this.isAuthValid(newAuth)) {
      return
    }

    this.setState({
      inProgress: true,
      errorDictionaryId: '',
      errorResponse: null,
    })

    const payload = { newAuthnIdentifier: stripIdentity(newAuth) }
    if (mode === 'update') {
      // Case when user is trying to update unverified auth method
      payload.oldAuthnIdentifier = auth || activatingAuth || pendingAuth
    }

    addOrUpdateAuthnIdentifier(payload)
      .then(res => {
        const pkat = get(res, 'body.output.pkat')

        this.setState({ inProgress: false })

        wizard.setPageState({
          pkat,
          auth,
          newAuth,
          iso2Code,
        })

        wizard.toNextPage()
      })
      .catch(err => {
        analyticsProvider.sendAnalytics({
          type: 'event',
          action: 'click',
          event_category: 'button',
          event_label: `${this.baseEventTag}.update-auth-next`,
          value: 1,
        })
        const errorCodes = getErrorCodes(err.body)
        const minMFACountError = errorCodes.includes('min-mfa-count')
        this.setState({
          inProgress: false,
          errorDictionaryId: `error.${errorCodes[0] ?? fallbackId}`,
          minMFACountError,
        })
      })
  }

  onDelete = () => {
    analyticsProvider.sendAnalytics({
      type: 'event',
      action: 'click',
      event_category: 'button',
      event_label: `${this.baseEventTag}.delete`,
      value: 0,
    })

    this.setState({ inProgress: true })
    const { auth, intl, loadUser, addNotification, onClose } = this.props

    const attributeValue = auth
    const attributeProperty = isEmail(attributeValue) ? 'email' : 'number'
    const attributeName = isEmail(attributeValue) ? 'emails' : 'mobiles'

    const payload = { attributeValue, attributeProperty, attributeName }

    removeUserAttribute(payload)
      .then(loadUser)
      .then(() => {
        this.setState(
          {
            inProgress: false,
            errorDictionaryId: '',
          },
          () => {
            const entity = intl.formatMessage({
              id: `notification.${attributeProperty}`,
            })
            const actionId = 'notification.deleted-successfully'
            const message = intl.formatMessage({ id: actionId }, { entity })

            addNotification({ message, variant: 'success' })

            onClose()
          },
        )
      })
      .catch(err => {
        analyticsProvider.sendAnalytics({
          type: 'event',
          action: 'click',
          event_category: 'button',
          event_label: `${this.baseEventTag}.delete`,
          value: 1,
        })

        const minMFACountError = getErrorCodes(err.body).includes(
          'min-mfa-count',
        )

        this.setState({
          inProgress: false,
          errorResponse: {
            body: err.body,
            action: 'delete',
          },
          minMFACountError,
        })
      })
  }

  handleAuthChange = event => {
    this.setState({ auth: event.target.value })
  }

  handleMobileNumberChange = event => {
    const excludeCountryCode = hasUIFeatureFlag('excludeCountryCode')
    const conditionalCountryCode = excludeCountryCode
      ? ''
      : this.state.countryCode

    this.setState({
      mobileNumber: formatNumber(this.state.iso2Code, event.target.value),
      auth: conditionalCountryCode + event.target.value,
    })
  }

  handleCountryCodeChange = (event, countryCode, isoCode) => {
    const excludeCountryCode = hasUIFeatureFlag('excludeCountryCode')
    const conditionalCountryCode = excludeCountryCode ? '' : countryCode

    this.setState({
      countryCode: countryCode,
      auth: conditionalCountryCode + this.state.mobileNumber,
      mobileNumber: formatNumber(isoCode, this.state.mobileNumber),
      iso2Code: isoCode,
    })
  }

  isAuthValid = auth => {
    if (!auth) {
      this.setState({ errorDictionaryId: 'profile.detail.error.required' })
      return false
    }

    const validator = validatorMap[this.props.owner]

    if (!validator(auth)) {
      this.setState({ errorDictionaryId: 'profile.detail.error.not-valid' })
      return false
    }

    return true
  }

  isMobileNumberValid = mobileNumber => {
    if (!mobileNumber) {
      this.setState({ errorDictionaryId: 'profile.detail.error.required' })
      return false
    }

    if (!isPhone(mobileNumber)) {
      this.setState({ errorDictionaryId: 'profile.detail.error.not-valid' })
      return false
    }

    this.setState({ errorDictionaryId: '' })
    return true
  }

  render() {
    const {
      onClose,
      pendingOrActivatingAuth,
      wizard,
      showVerify,
      mode,
      owner,
      intl,
      auth: originalAuth,
    } = this.props
    const { auth, inProgress, errorDictionaryId, errorResponse } = this.state

    const newAuth = pendingOrActivatingAuth
    const availableCountryCodes = hasUIFeatureFlag('availableCountryCodes')

    if (showVerify && newAuth) {
      // We have to send code resend request to able to get pkat
      // which will be used for code verification
      wizard.setPageState({
        pkat: '',
        auth,
        newAuth,
        forceResend: true,
      })

      wizard.toNextPage()

      return null
    }

    const isUpdate = mode === 'update'
    const isDelete = mode === 'delete'
    const minMFACountError = this.state.minMFACountError
    const messages = get(
      messagesMap,
      [
        owner,
        minMFACountError
          ? isUpdate
            ? 'minMFAUpdateError'
            : 'minMFADeleteError'
          : mode,
      ],
      {},
    )
    const ownerIntlId = get(messagesMap, [owner, 'owner'], '')
    const ownerName = intl.formatMessage({ id: ownerIntlId })
    const onCancelUpdateAuth = () => {
      analyticsProvider.sendAnalytics({
        type: 'event',
        action: 'click',
        event_category: 'button',
        event_label: `${this.baseEventTag}.update-auth-cancel`,
        value: 0,
      })
      onClose()
    }

    return (
      <UXPDialog.Dialog open fullScreen={!isDelete} fullWidth maxWidth="xs">
        <UXPDialog.Title
          onClose={onClose}
          title={intl.formatMessage({ id: messages.title })}
        />
        {minMFACountError ? (
          <>
            <UXPDialog.Content>
              <Box data-testid={'change-auth-min-mfa-modal-description'}>
                <Body2 mb={0}>
                  {intl.formatMessage({ id: messages.description })}
                </Body2>
              </Box>
            </UXPDialog.Content>
            <UXPDialog.Actions mt={4}>
              <Button onClick={onCancelUpdateAuth}>
                {intl.formatMessage({ id: 'common.button.ok' })}
              </Button>
            </UXPDialog.Actions>
          </>
        ) : (
          <>
            <UXPDialog.Content>
              {!isDelete ? (
                owner === 'mobile' ? (
                  <MobileNumberInput
                    countryCode={this.state.countryCode}
                    textFieldProps={{
                      onBlur: this.onValidateMobileNumber,
                      value: this.state.mobileNumber,
                      onChange: this.handleMobileNumberChange,
                      inputProps: {
                        autoCapitalize: 'none',
                        autoComplete: 'off',
                        'data-testid': 'username-mobile',
                      },
                    }}
                    label={ownerName}
                    countryList={availableCountryCodes}
                    selectProps={{
                      defaultValue: this.state.iso2Code,
                      onChange: this.handleCountryCodeChange,
                      autoFocus: true,
                      inputProps: {
                        autoCapitalize: 'none',
                        autoComplete: 'off',
                        'data-testid': 'country-code',
                      },
                    }}
                    error={
                      errorDictionaryId
                        ? formatErrorMessage({
                            id: errorDictionaryId,
                            intl,
                            params: { entity: ownerName },
                          })
                        : undefined
                    }
                  />
                ) : (
                  <TextField
                    data-testid={'change-auth-input-email'}
                    errorText={
                      errorDictionaryId
                        ? formatErrorMessage({
                            id: errorDictionaryId,
                            intl,
                            params: { entity: ownerName },
                          })
                        : undefined
                    }
                    label={ownerName}
                    onChange={this.handleAuthChange}
                    value={auth}
                    onBlur={this.onValidateAuth}
                  />
                )
              ) : null}
              <Box>
                {isUpdate && (
                  <Caption px="11px" color="text.secondary">
                    {intl.formatMessage({ id: messages.hint })}
                  </Caption>
                )}
                <Body2 mt={!isDelete && 3} mb={0}>
                  {intl.formatMessage({ id: messages.description })}
                </Body2>
              </Box>
              {isDelete && (
                <Collapse in={!!errorResponse?.body}>
                  <Box my={2}>
                    <BackendErrorNotification
                      intl={intl}
                      error={errorResponse?.body}
                      hideClose
                      context={
                        errorResponse?.action === 'update'
                          ? isEmail(auth)
                            ? 'email'
                            : 'phone' // error after update action
                          : isEmail(originalAuth)
                          ? 'email'
                          : 'phone' // error after delete action
                      }
                    />
                  </Box>
                </Collapse>
              )}
            </UXPDialog.Content>
            <UXPDialog.Actions mt={isDelete ? 4 : '28px'}>
              <TextSecondaryButton onClick={onCancelUpdateAuth}>
                Cancel
              </TextSecondaryButton>
              {!isDelete && (
                <TextPrimaryFillButton
                  data-testid={'change-auth-button-next'}
                  onClick={this.onUpdateAuth}
                  disabled={inProgress}
                >
                  {intl.formatMessage({ id: 'profile.update-auth-next' })}
                </TextPrimaryFillButton>
              )}
              {isDelete && (
                <TextPrimaryFillButton
                  data-testid={'change-auth-button-remove'}
                  onClick={this.onDelete}
                  disabled={inProgress}
                  destructive
                >
                  {intl.formatMessage({ id: 'profile.update-auth-remove' })}
                </TextPrimaryFillButton>
              )}
            </UXPDialog.Actions>
          </>
        )}
      </UXPDialog.Dialog>
    )
  }
}

const mapDispatchToProps = {
  loadUser,
  addNotification,
}

const mapStateToProps = () => ({})

const connectedProps = connect(mapStateToProps, mapDispatchToProps)

export default compose(connectedProps, injectIntl)(ChangeAuthPage)
