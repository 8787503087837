import conf from 'conf'
import i from 'common/utils/i'
import get from 'lodash/get'

const urlRoot = `${conf.apiRoot}/session`
const urlStep = `${conf.oidcRoot}/device`

export const CHECK_DEVICE_REQUEST = 'CHECK_DEVICE_REQUEST'
const checkDeviceRequest = () => ({
  type: CHECK_DEVICE_REQUEST,
})

export const START_DEVICE_SESSION = 'START_DEVICE_SESSION'
const startDeviceSession = (userCode, tokenValue) => ({
  type: START_DEVICE_SESSION,
  userCode,
  tokenValue,
})

export const START_DEVICE_APPROVAL = 'START_DEVICE_APPROVAL'
const startDeviceApproval = () => ({
  type: START_DEVICE_APPROVAL,
})

export const DEVICE_AUTH_ERROR = 'DEVICE_AUTH_ERROR'
export const deviceAuthError = error => ({
  type: DEVICE_AUTH_ERROR,
  error,
})

export const DEVICE_AUTH_SUCCESS = 'DEVICE_AUTH_SUCCESS'
const deviceApprovalSuccess = () => ({
  type: DEVICE_AUTH_SUCCESS,
})

export const checkDevice = () => dispatch => {
  dispatch(checkDeviceRequest())

  const tokenValue = window.location.search.match(/token_value=([^&]*)/)[1]

  const url = `${urlRoot}/token?value=${tokenValue}`

  return i.get(url, { ignoreErrorRedirect: true }).then(({ body }) => {
    const userCode = get(body, 'output.userApprovalURL').match(
      /user_code=([^&]*)/,
    )[1]
    dispatch(startDeviceSession(userCode, tokenValue))
  })
}

export const approveDevice = userCode => async dispatch => {
  dispatch(startDeviceApproval())

  const urlVerify = `${urlStep}/verify?user_code=${userCode}`
  const urlApprove = `${urlStep}/approve?user_code=${userCode}&user_oauth_approval=true`

  await i.post(urlVerify)
  await i.post(urlApprove)

  dispatch(deviceApprovalSuccess())
}
