import { List, UserButton } from '@admin-ui-common/base-user'
import { process } from '@admin-ui-common/utils'
import PhoneAndroidIcon from '@mui/icons-material/PhoneAndroid'
import MessageIcon from '@mui/icons-material/TextsmsOutlined'
import { Box, Divider } from '@mui/material'
import FlowLayout from 'common/layouts/FlowLayout'
import { get } from 'lodash'
import React from 'react'
import { useIntl } from 'react-intl'

const dataContainsEmail = dataArray =>
  dataArray.some(method => method.includes('@'))
const dataContainsTOTP = dataArray =>
  dataArray.some(method => method.includes('TOTP'))
const dataContainsMobile = dataArray => {
  const filteredData = dataArray.filter(
    method => !method.includes('@') && !method.includes('TOTP'),
  )
  return filteredData.length >= 1
}

const getOptionIDForTOTP = data =>
  Object.entries(data).find(([_, value]) => value === 'TOTP')[0]

const getMobilesFromData = data =>
  Object.entries(data)
    .filter(([_, value]) => !value.includes('@') && !value.includes('TOTP'))
    .map(([id, value]) => ({ id: id, value: value }))

const getEmailsFromData = data =>
  Object.entries(data)
    .filter(([_, value]) => value.includes('@'))
    .map(([id, value]) => ({ id: id, value: value }))

const TwoStepTypeSelect = ({
  wizard,
  getPageIndex,
  pages,
  response,
  handleSelectEmailStep,
  handleSelectMobileStep,
  handleSelectQRScanStep,
}) => {
  const intl = useIntl()
  let mfaData
  let wizardState

  if (wizard) {
    wizardState = wizard.getPageState()
    mfaData = get(wizard.getPageState(), 'output.mFASetupMethods')
  } else {
    wizardState = {
      processId: get(response, 'body.processId'),
    }
    mfaData = get(response, 'body.output.mFASetupMethods')
  }
  const mfaValueArray = mfaData ? Object.values(mfaData) : []

  const canUseTOTP = dataContainsTOTP(mfaValueArray)
  const canUseEmail = dataContainsEmail(mfaValueArray)
  const canUseText = dataContainsMobile(mfaValueArray)

  return (
    <FlowLayout
      title={intl.formatMessage({
        id: 'separatedMultiStep.twoStepMethodTypeSelect.title',
      })}
    >
      <List sx={{ paddingTop: 0, paddingBottom: 0 }}>
        {canUseTOTP && (
          <>
            <List.ListItem>
              <List.ListItemIcon sx={{ alignSelf: 'flex-start' }}>
                <PhoneAndroidIcon
                  sx={{ fontSize: '40px', color: 'common.black' }}
                />
              </List.ListItemIcon>
              <List.ListItemText
                primary={
                  <Box sx={{ typography: 'body1', fontWeight: 'bold' }}>
                    {intl.formatMessage({
                      id: 'profile.label.authenticator-app',
                    })}
                  </Box>
                }
                secondary={
                  <div>
                    <span style={{ fontWeight: 'bold' }}>
                      {intl.formatMessage({
                        id: 'profile.detail.authenticator-app.recommended',
                      })}
                    </span>
                    {intl.formatMessage({
                      id: 'profile.detail.authenticator-app.use-auth-app',
                    })}
                  </div>
                }
              />
            </List.ListItem>
            <Box mt={1} mb={4} ml={9}>
              <UserButton
                size="small"
                onClick={async e => {
                  e.preventDefault()
                  try {
                    const res = await process.step({
                      processId: wizardState.processId,
                      parameters: {
                        twoFASetupMethodOptionId: getOptionIDForTOTP(mfaData),
                      },
                    })
                    const data = get(res, 'data')
                    if (wizard) {
                      wizard.setPageState({
                        processId: get(data, 'processId'),
                        qrUrl: get(data, 'output.qrUrl'),
                      })
                      wizard.toPage(getPageIndex(pages.QRCodeScanStep))
                    } else {
                      handleSelectQRScanStep({
                        processId: get(data, 'processId'),
                        qrUrl: get(data, 'output.qrUrl'),
                      })
                    }
                  } catch (e) {
                    console.error(e)
                  }
                }}
              >
                {intl.formatMessage({
                  id: 'profile.detail.authenticator-app.button.setup-auth-app',
                })}
              </UserButton>
            </Box>
          </>
        )}
        {canUseText && (
          <>
            {mfaValueArray.length > 1 && <Divider light variant="middle" />}
            <Box mt={1} />
            <List.ListItem>
              <List.ListItemIcon sx={{ alignSelf: 'flex-start' }}>
                <MessageIcon sx={{ fontSize: '40px', color: 'common.black' }} />
              </List.ListItemIcon>
              <List.ListItemText
                primary={
                  <Box sx={{ typography: 'body1', fontWeight: 'bold' }}>
                    {intl.formatMessage({
                      id:
                        'separatedMultiStep.twoStepMethodTypeSelect.type.text',
                    })}
                  </Box>
                }
                secondary={intl.formatMessage({
                  id:
                    'separatedMultiStep.twoStepMethodTypeSelect.type.text.description',
                })}
              />
            </List.ListItem>
            <Box mt={1} mb={4} ml={9}>
              <UserButton
                size="small"
                variant="outlined"
                onClick={e => {
                  e.preventDefault()
                  if (wizard) {
                    wizard.setPageState({
                      processId: wizardState.processId,
                      mobiles: getMobilesFromData(mfaData),
                    })
                    wizard.toPage(getPageIndex(pages.TwoFAMobileSelectStep))
                  } else {
                    handleSelectMobileStep({
                      processId: wizardState.processId,
                      mobiles: getMobilesFromData(mfaData),
                    })
                  }
                }}
              >
                {intl.formatMessage({
                  id:
                    'separatedMultiStep.twoStepMethodTypeSelect.type.text.button',
                })}
              </UserButton>
            </Box>
          </>
        )}
        {canUseEmail && (
          <>
            {mfaValueArray.length > 1 && <Divider light variant="middle" />}
            <Box mt={1} />
            <List.ListItem>
              <List.ListItemIcon sx={{ alignSelf: 'flex-start' }}>
                <MessageIcon sx={{ fontSize: '40px', color: 'common.black' }} />
              </List.ListItemIcon>
              <List.ListItemText
                primary={
                  <Box sx={{ typography: 'body1', fontWeight: 'bold' }}>
                    {intl.formatMessage({
                      id:
                        'separatedMultiStep.twoStepMethodTypeSelect.type.email',
                    })}
                  </Box>
                }
                secondary={intl.formatMessage({
                  id:
                    'separatedMultiStep.twoStepMethodTypeSelect.type.email.description',
                })}
              />
            </List.ListItem>
            <Box mt={1} ml={9}>
              <UserButton
                size="small"
                variant="outlined"
                onClick={e => {
                  e.preventDefault()
                  if (wizard) {
                    wizard.setPageState({
                      processId: wizardState.processId,
                      emails: getEmailsFromData(mfaData),
                    })
                    wizard.toPage(getPageIndex(pages.TwoFAEmailSelectStep))
                  } else {
                    handleSelectEmailStep({
                      processId: wizardState.processId,
                      emails: getEmailsFromData(mfaData),
                    })
                  }
                }}
              >
                {intl.formatMessage({
                  id:
                    'separatedMultiStep.twoStepMethodTypeSelect.type.email.button',
                })}
              </UserButton>
            </Box>
          </>
        )}
      </List>
    </FlowLayout>
  )
}

export default TwoStepTypeSelect
