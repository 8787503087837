import React from 'react'
import classNames from 'classnames'
import { injectIntl } from 'react-intl'
import Typography from '@mui/material/Typography'
import styles from './styles.module.css'
import { Link } from 'react-router-dom'
import { TextField } from '@admin-ui-common/base-user'

class PasswordField extends React.Component {
  render() {
    const {
      isShowingForgotLink,
      authIdentifier,
      label,
      intl,
      errorText,
      ...props
    } = this.props

    return (
      <div className="relative">
        <TextField
          {...props}
          errorProps={errorText ? { errorMessage: errorText } : {}}
          label={label || intl.formatMessage({ id: 'common.label.password' })}
          inputType="password"
        />
        <div className={classNames(styles.passwordControls, 'flex')}>
          {isShowingForgotLink && (
            <Link to={{ pathname: '/forgot', state: { authIdentifier } }}>
              <Typography
                variant="div"
                color="primary"
                className={styles.passwordForgotNavLink}
              >
                {intl.formatMessage({ id: 'common.link.forgot-password' })}
              </Typography>
            </Link>
          )}
        </div>
      </div>
    )
  }
}

export default injectIntl(PasswordField)
