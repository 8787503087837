import React, { useState, useEffect } from 'react'
import { find, get, isEmpty } from 'lodash'
import { useIntl } from 'react-intl'

import RadioGroup from '@mui/material/RadioGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import Radio from '@mui/material/Radio'
import Collapse from '@mui/material/Collapse'
import makeStyles from '@mui/styles/makeStyles'
import Box from '@mui/material/Box'
import { BackendErrorNotification } from 'common/components/backendErrorNotification/BackendErrorNotification'
import { loadUser } from 'common/actions/user'
import { addNotification } from 'common/actions/notification'

import { Body2 } from 'common/components/Typography'
import { useSelector, useDispatch } from 'react-redux'
import * as UXPDialog from 'common/components/dialog/UXPDialog'
import { TextPrimaryButton, Button } from 'common/components/button/Button'
import Page from 'common/components/Page'
import { analyticsProvider } from '../../../../App'
import {
  registerMFAVerificationStep,
  updateSelectedIdentity,
} from 'common/actions/twoStepVerification'
import {
  AUTHN_IDENTIFIER_PROMPT,
  OTP_PROMPT,
} from 'common/constants/twoStepVerification'
import { getErrorCodes } from 'common/utils/processBackendErrors'
import { displayIdentifier } from 'common/utils/formatNumber'

const useStyles = makeStyles(() => ({
  height: {
    height: 56,
  },
  bold: {
    fontWeight: 'bold',
    fontSize: 16,
  },
  paddingBottom: {
    paddingBottom: 10,
  },
}))

const SelectIdentityDialog = ({ onClose, open, nextCallBack, flowType }) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const AuthnIdentifierPrompt = useSelector(
    state => state.registerMFA.emailPhone.AuthnIdentifierPrompt,
  )
  const appId = useSelector(state => state.configuration.config.id)
  const [process, setProcess] = useState('')
  const [identifier, setIdentifier] = useState('')
  const [authIdentifierList, setAuthIdentifierList] = useState([])
  const [inProgress, setInProgress] = useState(false)
  const [errorResponseBody, setErrorResponseBody] = useState(null)
  const intl = useIntl()

  const pageTitle = 'setup_email_or_mobile'
  const baseEventTag = `${flowType}.${pageTitle}`

  useEffect(() => {
    if (!isEmpty(AuthnIdentifierPrompt)) {
      const { processId, authnIdentifiers } = AuthnIdentifierPrompt
      let authnIdentifiersList = []
      if (!isEmpty(authnIdentifiers)) {
        for (var obj in authnIdentifiers) {
          authnIdentifiersList.push({
            key: obj,
            value: Object.keys(authnIdentifiers[obj])[0],
            type: Object.values(authnIdentifiers[obj])[0],
          })
        }
      }
      setProcess(processId)
      setAuthIdentifierList(authnIdentifiersList)
    }
  }, [AuthnIdentifierPrompt])

  const onNext = () => {
    analyticsProvider.sendAnalytics({
      type: 'event',
      action: 'click',
      event_category: 'button',
      event_label: `${baseEventTag}.next`,
      value: 0,
    })

    setInProgress(true)
    setErrorResponseBody(null)
    const payload = {
      processId: process,
      parameters: {
        authnIdentifierId: identifier,
      },
    }

    registerMFAVerificationStep(payload)(dispatch)
      .then(res => {
        const stepData = res.body
        analyticsProvider.sendAnalytics({
          type: 'event',
          action: 'click',
          event_category: 'button',
          event_label: `${baseEventTag}.identity_selected`,
          value: 0,
        })
        onClose(AUTHN_IDENTIFIER_PROMPT)

        const isLastStep = get(stepData, 'lastStep')
        if (!isLastStep) {
          nextCallBack(OTP_PROMPT)
          setIdentifier('')
        } else {
          const selectedIdentifier = find(
            authIdentifierList,
            obj => obj.key === identifier,
          )
          dispatch(
            addNotification({
              message: intl.formatMessage(
                {
                  id:
                    'user.management.register.mobile-or-email-success-message',
                },
                {
                  username:
                    selectedIdentifier?.type === 'EMAIL'
                      ? 'Email'
                      : 'Mobile Number',
                },
              ),
              variant: 'success',
            }),
          )
          dispatch(loadUser())
        }
      })
      .catch(err => {
        setErrorResponseBody(err.body)

        analyticsProvider.sendAnalytics({
          type: 'event',
          action: 'exception',
          description: `${baseEventTag}.screen: ${getErrorCodes(err.body).join(
            ',',
          )}`,
        })
      })
  }

  const closeView = () => {
    analyticsProvider.sendAnalytics({
      type: 'event',
      action: 'click',
      event_category: 'button',
      event_label: `${baseEventTag}.close`,
      value: 0,
    })
    setIdentifier('')
    onClose(AUTHN_IDENTIFIER_PROMPT)
  }

  const onChangeDiscoveryType = e => {
    const identity = e.target.value
    setIdentifier(identity)
    if (!isEmpty(authIdentifierList)) {
      const data = authIdentifierList.filter(val => val.key === identity)
      if (!isEmpty(data)) {
        dispatch(updateSelectedIdentity(data))
      }
    }
  }

  return (
    <UXPDialog.Dialog open={open} fullScreen fullWidth maxWidth="sm">
      <UXPDialog.Title
        title={intl.formatMessage({
          id: 'user.management.register.mobile-or-email-select-identity-title',
        })}
        onClose={closeView}
      />
      <UXPDialog.Content>
        <Box mb={3}>
          <Page.Description className={classes.paddingBottom}>
            {intl.formatMessage({
              id:
                'user.management.register.mobile-or-email-select-identity-message',
            })}
          </Page.Description>
          <Page.Description>
            {intl.formatMessage({
              id:
                'user.management.register.mobile-or-email-select-identity-message1',
            })}
          </Page.Description>
        </Box>
        <RadioGroup value={identifier} onChange={onChangeDiscoveryType}>
          {authIdentifierList.map(type => (
            <FormControlLabel
              key={type.key}
              value={type.key}
              control={<Radio color="primary" />}
              className={classes.height}
              label={
                <Body2 mb={0} className={classes.bold}>
                  {displayIdentifier(type.value, type.type, appId)}
                </Body2>
              }
            />
          ))}
        </RadioGroup>
        <Collapse in={!!errorResponseBody}>
          <Box mt={2}>
            <BackendErrorNotification
              intl={intl}
              error={errorResponseBody}
              hideClose
            />
          </Box>
        </Collapse>
      </UXPDialog.Content>
      <UXPDialog.Actions disabled={inProgress}>
        <TextPrimaryButton onClick={closeView} size="small" variant="outlined">
          {intl.formatMessage({ id: 'common.button.cancel' })}
        </TextPrimaryButton>
        <Button onClick={onNext} disabled={!identifier} size="small">
          {intl.formatMessage({ id: 'common.button.next' })}
        </Button>
      </UXPDialog.Actions>
    </UXPDialog.Dialog>
  )
}

export default SelectIdentityDialog
