import React, { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { get } from 'lodash'
import { useIntl } from 'react-intl'

import PasswordField from 'common/components/textfield/PasswordField'
import Collapse from '@mui/material/Collapse'
import Box from '@mui/material/Box'

import * as UXPDialog from 'common/components/dialog/UXPDialog'
import { registerMFAVerificationStep } from 'common/actions/twoStepVerification'
import { TextPrimaryButton, Button } from 'common/components/button/Button'
import Page from 'common/components/Page'
import { analyticsProvider } from '../../../../App'

import { mfaDialogTypes } from '../TwoStepVerificationDetailView'
import {
  QR_CODE_PROMPT,
  TOTP_VERIFICATION_PROMPT,
} from 'common/constants/twoStepVerification'
import {
  getErrorCodes,
  resolveErrorEntity,
} from 'common/utils/processBackendErrors'
import { BackendErrorNotification } from 'common/components/backendErrorNotification/BackendErrorNotification'

const getNextStepName = res =>
  get(res, 'body.stepName') === TOTP_VERIFICATION_PROMPT
    ? QR_CODE_PROMPT
    : get(res, 'body.stepName')

const errorContext = 'register-mfa'

const PasswordDialog = ({
  onClose,
  open,
  nextCallBack,
  flowType,
  errorResponseBody,
  mfaDialogType,
}) => {
  const dispatch = useDispatch()
  const registerMFA = useSelector(state => state.registerMFA)

  const getMFAResponse = () => {
    switch (mfaDialogType) {
      case mfaDialogTypes.EMAIL_OR_PHONE:
        return registerMFA.emailPhone
      case mfaDialogTypes.TOTP_APP:
        return registerMFA.totpApp
      default:
        return
    }
  }

  const [password, setPassword] = useState('')
  const [inProgress, setInProgress] = useState(registerMFA.inProgress)
  const [fieldErrorMessage, setFieldErrorMessage] = useState(null)
  const intl = useIntl()

  const pageTitle = 'verify_password'
  const baseEventTag = `${flowType}.${pageTitle}`

  const passwordFieldLabel = intl.formatMessage({
    id: 'user.management.register.mobile-or-email-enterPassword',
  })

  const onNext = () => {
    analyticsProvider.sendAnalytics({
      type: 'event',
      action: 'click',
      event_category: 'button',
      event_label: `${baseEventTag}.next`,
      value: 0,
    })

    if (!password) {
      setFieldErrorMessage(
        intl.formatMessage({
          id: 'error.credential',
        }),
      )
      return
    }

    setInProgress(true)

    const registerMFAStateSlice = getMFAResponse()

    const payload = {
      processId: registerMFAStateSlice?.password?.processId ?? '',
      parameters: {
        password,
      },
    }

    registerMFAVerificationStep(payload)(dispatch)
      .then(res => {
        analyticsProvider.sendAnalytics({
          type: 'event',
          action: 'click',
          event_category: 'button',
          event_label: `${baseEventTag}.password_verify`,
          value: 0,
        })
        onClose('PasswordPrompt')
        nextCallBack(getNextStepName(res))
        setPassword('')
      })
      .catch(err => {
        setFieldErrorMessage(
          resolveErrorEntity({
            intl,
            error: err.body,
            context: errorContext,
          }),
        )

        analyticsProvider.sendAnalytics({
          type: 'event',
          action: 'exception',
          description: `${baseEventTag}.screen: ${getErrorCodes(err.body).join(
            ',',
          )}`,
        })
      })
  }

  const closeDialog = () => {
    analyticsProvider.sendAnalytics({
      type: 'event',
      action: 'click',
      event_category: 'button',
      event_label: `${baseEventTag}.close`,
      value: 0,
    })
    setPassword('')
    setFieldErrorMessage(null)
    onClose('PasswordPrompt')
  }

  return (
    <UXPDialog.Dialog open={open} fullScreen fullWidth maxWidth="sm">
      <UXPDialog.Title title={passwordFieldLabel} onClose={closeDialog} />
      <UXPDialog.Content>
        <Box mb={3}>
          <Page.Description>
            {intl.formatMessage({
              id:
                'user.management.register.mobile-or-email-enterPassword-body-text',
            })}
          </Page.Description>
        </Box>
        <PasswordField
          label={passwordFieldLabel}
          onChange={e => setPassword(e.target.value)}
          value={password}
          errorText={fieldErrorMessage ?? undefined}
        />
        <Collapse in={!!errorResponseBody}>
          <Box mt={2}>
            <BackendErrorNotification
              intl={intl}
              error={errorResponseBody}
              context={errorContext}
              hideClose
            />
          </Box>
        </Collapse>
      </UXPDialog.Content>
      <UXPDialog.Actions disabled={inProgress}>
        <TextPrimaryButton
          onClick={closeDialog}
          size="small"
          variant="outlined"
        >
          {intl.formatMessage({ id: 'common.button.cancel' })}
        </TextPrimaryButton>
        <Button onClick={onNext} size="small">
          {intl.formatMessage({ id: 'common.button.next' })}
        </Button>
      </UXPDialog.Actions>
    </UXPDialog.Dialog>
  )
}

export default PasswordDialog
