import { filter, includes, isEqual } from 'lodash'
import {
  getEmails,
  getMobiles,
  getPreferredContact,
  getSocialConnection,
} from '../../protected/profile/selectors'

import { get } from 'lodash'

export const usernameIdentifiers = ['email', 'phone']

export function getOnlyUsernameIdentifiers(allowedIdentifiers) {
  return filter(allowedIdentifiers, identifer =>
    includes(usernameIdentifiers, identifer),
  )
}

export function isEmailOnly(allowedIdentifiers) {
  return isEqual(getOnlyUsernameIdentifiers(allowedIdentifiers), ['email'])
}

export function isPhoneOnly(allowedIdentifiers) {
  return isEqual(getOnlyUsernameIdentifiers(allowedIdentifiers), ['phone'])
}

export function getIdentifierMode(allowedIdentifiers) {
  if (isEmailOnly(allowedIdentifiers)) return 'email'
  if (isPhoneOnly(allowedIdentifiers)) return 'phone'
  return 'default'
}

function hasAnotherActivatedIdentifier(identifier, state) {
  const otherActivatedIdentifiers = [
    ...getEmails(state, 'activated'),
    ...getMobiles(state, 'activated'),
  ].filter(activatedId => {
    const email = get(activatedId, 'email')
    const mobile = get(activatedId, 'mobile')
    return identifier !== email || mobile
  })

  return otherActivatedIdentifiers.length > 0
}

function hasAssociatedSocialAccount(state) {
  return getSocialConnection(state).length > 0
}

function isNotPreferredNotificationChannel(identifier, state) {
  const preferredContact = get(getPreferredContact(state), 'value')
  return preferredContact !== identifier
}

export function isIdentifierDeleteable(identifier, state) {
  return (
    (hasAnotherActivatedIdentifier(identifier, state) ||
      hasAssociatedSocialAccount(state)) &&
    isNotPreferredNotificationChannel(identifier, state)
  )
}
