import React, { useState } from 'react'
import { useIntl } from 'react-intl'

import Box from '@mui/material/Box'

import * as UXPDialog from 'common/components/dialog/UXPDialog'
import { TextPrimaryButton } from 'common/components/button/Button'
import { analyticsProvider } from '../../../../App'
import { Body } from '../../../../common/components/Typography'
import { TextPrimaryFillButton } from '../../../../common/components/button/Button'
import {
  getTrustedDevices,
  revokeAllTrustedDevices,
} from '../../../../common/actions/twoStepVerification'
import { useDispatch } from 'react-redux'
import { addNotification } from '../../../../common/actions/notification'
import BackdropLoader from '../../../../common/components/BackdropLoader'

const RevokeTrustedDevicesDialog = ({ onClose, open }) => {
  const [inProgress, setInProgress] = useState(false)
  const intl = useIntl()

  const pageTitle = 'revoke_trusted_devices'
  const baseEventTag = `dialog.${pageTitle}`

  const dispatch = useDispatch()

  const onRevoke = () => {
    analyticsProvider.sendAnalytics({
      type: 'event',
      action: 'click',
      event_category: 'button',
      event_label: `${baseEventTag}.revoke`,
      value: 0,
    })

    setInProgress(true)

    revokeAllTrustedDevices()(dispatch)
      .then(() => getTrustedDevices()(dispatch))
      .then(() =>
        dispatch(
          addNotification({
            message: intl.formatMessage({
              id: 'notification.trusted-devices-revoke.success',
            }),
            variant: 'success',
          }),
        ),
      )
      .catch(() => {
        dispatch(
          addNotification({
            message: intl.formatMessage({
              id: 'error.try-again',
            }),
            variant: 'error',
          }),
        )
      })
      .finally(() => {
        onClose()
        setInProgress(false)
      })
  }

  const closeView = () => {
    analyticsProvider.sendAnalytics({
      type: 'event',
      action: 'click',
      event_category: 'button',
      event_label: `${baseEventTag}.cancel`,
      value: 0,
    })
    onClose()
  }

  return (
    <UXPDialog.Dialog open={open} fullScreen fullWidth maxWidth="sm">
      <UXPDialog.Title
        title={intl.formatMessage({
          id: 'profile.detail.authenticator-app.trusted-devices.dialog.title',
        })}
        onClose={closeView}
      />
      <UXPDialog.Content>
        <Box>
          <Body mb={0}>
            {intl.formatMessage({
              id:
                'profile.detail.authenticator-app.trusted-devices.dialog.message',
            })}
          </Body>
        </Box>
      </UXPDialog.Content>
      <UXPDialog.Actions>
        <TextPrimaryButton
          disabled={inProgress}
          onClick={closeView}
          size="small"
          variant="outlined"
        >
          {intl.formatMessage({ id: 'common.button.cancel' })}
        </TextPrimaryButton>
        <TextPrimaryFillButton
          disabled={inProgress}
          destructive
          onClick={onRevoke}
          size="small"
        >
          {intl.formatMessage({ id: 'common.button.revoke' })}
        </TextPrimaryFillButton>
      </UXPDialog.Actions>
      <BackdropLoader open={inProgress} />
    </UXPDialog.Dialog>
  )
}

export default RevokeTrustedDevicesDialog
