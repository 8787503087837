import React from 'react'
import classNames from 'classnames'

import { Button } from 'common/components/button/Button'

import styles from '../../styles.module.css'
import { analyticsProvider } from '../../../App'
import { Body } from '../../../common/components/Typography'

import { injectIntl } from 'react-intl'
import { UserTextLink } from '@admin-ui-common/base-user'

const ResultVerifyUsername = ({
  verified,
  onConfirm,
  intl,
  userAuthenticated,
}) => {
  const isComplete = userAuthenticated

  const pageTitle = 'resultVerifyUsername'
  const baseEventTag = `signup.${pageTitle}`

  const getText = () => {
    if (isComplete && !verified) {
      return intl.formatMessage({ id: 'verify-result.confirm-your-email' })
    }

    return (
      <React.Fragment>
        <Body>
          {intl.formatMessage(
            { id: 'verify-result.progressive.message' },
            {
              entity: (
                <UserTextLink underline="always" onClick={onConfirm}>
                  My NXT ID
                </UserTextLink>
              ),
            },
          )}
        </Body>
        {verified === false && (
          <React.Fragment>
            <br />
            <br />
            {intl.formatMessage({ id: 'verify-result.message-unverified' })}
          </React.Fragment>
        )}
      </React.Fragment>
    )
  }

  return (
    <>
      <p className={styles.resultMessage}>{getText()}</p>
      <div className={classNames(styles.formButton)}>
        <Button
          onClick={() => {
            analyticsProvider.sendAnalytics({
              type: 'event',
              action: 'click',
              event_category: 'button',
              event_label: `${baseEventTag}.confirm`,
              value: 0,
            })
            onConfirm()
          }}
          size="large"
        >
          {intl.formatMessage({ id: 'common.button.ok' })}
        </Button>
      </div>
    </>
  )
}

export default injectIntl(ResultVerifyUsername)
