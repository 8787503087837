const featureName = 'NOTIFICATION'

export const ADD_NOTIFICATION = `${featureName}/ADD_NOTIFICATION`
export const DISMISS_NOTIFICATION = `${featureName}/DISMISS_NOTIFICATION`

export const addNotification = notification => ({
  type: ADD_NOTIFICATION,
  payload: {
    ...notification,
    key: new Date().getTime(),
  },
})

export const dismissNotification = () => ({
  type: DISMISS_NOTIFICATION,
})
