import React, { useState, useCallback, useEffect } from 'react'
import { useIntl, FormattedMessage } from 'react-intl'
import { get, isEmpty } from 'lodash'

import Box from '@mui/material/Box'

import * as UXPCard from 'common/components/Card'
import { Title, Body } from 'common/components/Typography'
import TextField from 'common/components/textfield/TextField'
import { TextPrimaryButton, Button } from 'common/components/button/Button'
import ResendCode from 'common/components/ResendCode'
import ErrorBox from 'common/components/ErrorBox'
import SubscribeContentDialog from 'common/components/SubscribeContentDialog'
import TermsAndConditions from 'common/components/TermsAndConditions'
import { hasUIFeatureFlag } from 'common/services/FeatureFlagsService'
import ActionLabel from 'common/components/ActionLabel'
import { TOKEN_MAX_RESEND_REACHED } from 'common/constants'

import useMainRedirect from 'common/components/hooks/useMainRedirect'

import { labelFormatter } from 'protected/profile/constants'

import {
  ALREADY_SUBSCRIBED,
  NUMBER_IS_PORTABLE,
  PASSWORD_PROMPT,
  getFlowTypes,
} from 'common/constants'
import { analyticsProvider } from 'App.js'
import { useSelector } from 'react-redux'
import {
  isDisableVerifyPinError,
  getErrorCodes,
  isNotEligibleError,
  getOperationErrorMessage,
  resolveErrorEntity,
  isVerifyOTPError,
  isTokenMaxResendReached,
  isInvalidActConsumption,
  formatErrorMessage,
  isInvalidPkat,
} from 'common/utils/processBackendErrors'
import { verifyCode } from 'common/processes/token'
import { VerifyOTPErrors } from 'common/components/VerifyOTPErros'

const verifyCodeContext = 'verifyCode'
const verifyUsernameContext = 'verifyUsername'

const VerificationCode = ({ wizard, flowType, wizardIndex }) => {
  const showTermsAndConditions = hasUIFeatureFlag(
    'wizardShowTermsAndConditions',
  )
  const [code, setCode] = useState('')
  const [errors, setErrors] = useState({})
  const [errorResponseBody, setErrorResponseBody] = useState(null)
  const [terms, setTerms] = useState(showTermsAndConditions ? false : true)
  const [progress, setProgress] = useState(false)
  const [productData, setProductData] = useState({})
  const [verificationResponse, setVerificationResponse] = useState({})
  const [disableVerify, setDisableVerify] = useState(false)
  const [disableTextfield, setDisabledTextfield] = useState(false)
  const [isResendCode, setIsResendCode] = useState(false)
  const [disabledSendNewCode, setDisabledSendNewCode] = useState()

  const intl = useIntl()
  const mainRedirect = useMainRedirect(`/${flowType}`)

  const pageState = wizard.getPageState()

  const initialPkat = get(pageState, 'output.pkat')
  const [pkat, setPkat] = useState(initialPkat)

  const tokenId = get(pageState, 'output.tokenId')
  const { mobile, termsOfServiceURL } = pageState
  const FLOW_TYPES = getFlowTypes()
  const pageTitle = 'verify_code'
  const baseEventTag = `${flowType}.verify_code`
  const codeFieldLabel = intl.formatMessage({ id: 'onboard.common.enter-code' })

  const appId = useSelector(state => state.configuration.config.id)

  useEffect(() => {
    analyticsProvider.sendAnalytics({
      type: 'page_view',
      page_title: pageTitle,
      page_path: `/${flowType}#${pageTitle}`,
    })
  }, [flowType])

  const redirect = metadata => {
    const isStaticRedirect = mainRedirect(metadata)
    if (isStaticRedirect) {
      wizard.setPageState({})
      wizard.toPage(0)
    }
  }

  const onCancel = () => {
    analyticsProvider.sendAnalytics({
      type: 'event',
      action: 'click',
      event_category: 'button',
      event_label: `${baseEventTag}.cancel`,
      value: 0,
    })

    redirect()
  }

  const purchaseDisabledRedirect = metadata => {
    analyticsProvider.sendAnalytics({
      type: 'event',
      action: 'redirect',
      description: `${baseEventTag}.screen: purchase prompt disabled`,
    })

    redirect(metadata)
  }

  // //////////////////////////////////////////////////////////

  const redirectSignIn = () => {
    const { mobile } = wizard.getPageState()
    wizard.setPageState({
      origin: { viewId: 'CodeExpire' },
      mobile,
    })
    wizard.toPage(0)
  }

  const onVerifyCode = async () => {
    const nextErrors = {}
    setErrorResponseBody(null)
    setErrors({})
    if (!code) {
      const codeError = intl.formatMessage({
        id: 'error.verification-code-empty',
      })

      analyticsProvider.sendAnalytics({
        type: 'event',
        action: 'exception',
        description: `${baseEventTag}.code: ${codeError}`,
      })

      nextErrors.code = codeError
    }

    setErrors(nextErrors)
    if (!isEmpty(nextErrors)) {
      return
    }

    setProgress(true)

    try {
      const res = await verifyCode(code, pkat)
      setVerificationResponse(res)
      const stepName = get(res, 'body.stepName')

      const output = get(res, 'body.output', {})
      const showPurchasePrompt = get(output, 'purchasePromptFlag', false)

      setProgress(false)

      analyticsProvider.sendAnalytics({
        type: 'event',
        action: 'click',
        event_category: 'button',
        event_label: `${baseEventTag}.verify`,
        value: 0,
      })

      if (showPurchasePrompt) {
        const productInfo = get(res, 'body.output.productDetailsDTO', {})
        setProductData({
          productInfo,
          stepInfo: res.body,
        })
      } else {
        switch (stepName) {
          case PASSWORD_PROMPT: {
            const { processId, userAuthenticated } = res.body
            wizard.setPageState({
              ...wizard.getPageState(),
              processId,
              userAuthenticated,
              stepName,
              ...get(res, 'body.output', {}),
            })
            wizard.toPage(wizardIndex.createId)

            break
          }
          default: {
            const { metadata } = res
            const output = get(res, 'body.output', {})

            wizard.setPageState({
              ...wizard.getPageState(),
              ...output,
              metadata,
            })

            const processState = get(output, 'state')
            const nextPage =
              processState === ALREADY_SUBSCRIBED
                ? wizardIndex.alreadySubscribed
                : wizardIndex.thankYou

            if (flowType === FLOW_TYPES.LOGIN && !processState) {
              purchaseDisabledRedirect(metadata)
            } else {
              wizard.toPage(nextPage)
            }
          }
        }
      }
    } catch (err) {
      setProgress(false)

      analyticsProvider.sendAnalytics({
        type: 'event',
        action: 'click',
        event_category: 'button',
        event_label: `${baseEventTag}.verify`,
        value: 1,
      })

      handleOtpErrors(err)
      const errorCodes = getErrorCodes(err.body)

      if (errorCodes.some(isNotEligibleError)) {
        const portUrl = getOperationErrorMessage(err.body)
        const nextWizardPageState = {
          ...wizard.getPageState(),
          metadata: { headers: { get: () => '' } },
        }
        if (errorCodes.includes(NUMBER_IS_PORTABLE)) {
          nextWizardPageState.portUrl = portUrl
        }

        wizard.setPageState(nextWizardPageState)
        wizard.toPage(wizardIndex.notEligible)
        return
      }

      // side effects here
      if (errorCodes.some(isDisableVerifyPinError)) {
        setDisableVerify(true)
      }
      analyticsProvider.sendAnalytics({
        type: 'event',
        action: 'exception',
        description: `${baseEventTag}.screen: ${errorCodes.join(',')}`,
      })
    }
  }

  const handleOtpErrors = err => {
    if (isInvalidActConsumption(err.body)) {
      let errorMessage = formatErrorMessage({
        intl,
        id: 'error.verification-code-incorrect',
      })
      setErrors({ code: errorMessage })
      setDisableVerify(true)
      return
    } else if (isVerifyOTPError(err.body)) {
      setErrorResponseBody(err.body)
      setDisableVerify(true)
      setDisabledTextfield(true)
      if (isInvalidPkat(err.body)) {
        setDisabledSendNewCode(true)
      }
      return
    } else if (isTokenMaxResendReached(err.body)) {
      setErrorResponseBody(err.body)
      setDisabledSendNewCode(true)
    } else {
      setErrors({
        code: resolveErrorEntity({
          intl,
          error: err.body,
          context: verifyCodeContext,
        }),
      })
      return
    }
  }

  // //////////////////////////////////////////////////////////

  const handleResendError = useCallback(
    (resendErrorMessage, err) => {
      analyticsProvider.sendAnalytics({
        type: 'event',
        action: 'click',
        event_category: 'button',
        event_label: `${baseEventTag}.resend_code`,
        value: 1,
      })

      analyticsProvider.sendAnalytics({
        error: resendErrorMessage,
      })

      const errorData = err.body || err.data
      const errorCodes = getErrorCodes(errorData)
      if (errorCodes.includes(TOKEN_MAX_RESEND_REACHED)) {
        setErrorResponseBody(errorData)
        setDisabledSendNewCode(true)
      }
    },
    [baseEventTag],
  )

  // //////////////////////////////////////////////////////////

  const onResetError = useCallback(() => {
    setErrors({})
  }, [])

  // //////////////////////////////////////////////////////////

  const onCloseDialog = (stepName, isError) => {
    const { productInfo } = productData
    setProductData({})

    const {
      processId,
      userAuthenticated,
      output = {},
    } = verificationResponse.body
    wizard.setPageState({
      ...wizard.getPageState(),
      processId,
      userAuthenticated,
      stepName,
      productInfo: {
        ...productInfo,
      },
      ...output,
    })

    if (isError) {
      wizard.toPage(wizardIndex.notFound)
    } else {
      let nextView
      switch (stepName) {
        case PASSWORD_PROMPT:
          nextView = wizardIndex.thankYouNewUser
          break
        case 'NoThanks':
          nextView = wizardIndex.createId
          break
        default:
          nextView = wizardIndex.thankYou
      }

      wizard.toPage(nextView)
    }
  }

  // //////////////////////////////////////////////////////////

  const onResendFinish = newPkat => {
    setPkat(newPkat)
    setDisableVerify(false)
    setIsResendCode(false)
    setDisabledTextfield(false)

    analyticsProvider.sendAnalytics({
      type: 'event',
      action: 'click',
      event_category: 'button',
      event_label: `${baseEventTag}.resend_code`,
      value: 0,
    })
  }

  const handleResend = () => {
    setIsResendCode(true)
    setDisabledTextfield(false)
  }

  // //////////////////////////////////////////////////////////

  const confirmLabelId =
    flowType === FLOW_TYPES.SUBSCRIBE
      ? 'common.button.verify'
      : 'common.button.verify'
  const enterYourCodeTitleId =
    flowType === FLOW_TYPES.SUBSCRIBE
      ? 'onboard-subscribe.enter-your-code'
      : 'onboard.common.enter-your-code'

  const openDialog = !isEmpty(productData)
  const disableVerifyButton = disableVerify || !terms

  return (
    <React.Fragment>
      <UXPCard.Card>
        <UXPCard.Content light>
          <Title>{intl.formatMessage({ id: enterYourCodeTitleId })}</Title>
          {errors.common && <ErrorBox>{errors.common}</ErrorBox>}
          <Body mb={4}>
            <FormattedMessage
              id="onboard.verification-code.sms-was-sent"
              values={{
                number: <b>{labelFormatter.mobile(mobile, appId)}</b>,
              }}
            />
            <p>
              <FormattedMessage id="onboard.verification-code.do-not-share-pin" />
            </p>
          </Body>
          {!!errorResponseBody && (
            <Box mb={3}>
              <VerifyOTPErrors
                errors={errorResponseBody}
                errorContext={verifyUsernameContext}
                handleResend={handleResend}
                onClickRedirect={redirectSignIn}
              />
            </Box>
          )}
          <TextField
            label={codeFieldLabel}
            onChange={e => {
              setCode(e.target.value)
              setDisableVerify(false)
            }}
            value={code}
            errorText={errors.code}
            autoComplete="one-time-code"
            numeric
            disabled={disableTextfield}
          />
          {disabledSendNewCode ? (
            <ActionLabel
              disabled={disabledSendNewCode}
              variant="text"
              label={intl.formatMessage({ id: `common.countdown-complete` })}
            />
          ) : (
            <ResendCode
              pkat={pkat}
              tokenId={tokenId}
              onResendError={handleResendError}
              onResendStart={onResetError}
              onResendFinish={onResendFinish}
              isResendCode={isResendCode}
            />
          )}
          <Box mt={1} />
          {showTermsAndConditions && (
            <TermsAndConditions
              link={termsOfServiceURL}
              onChange={() => setTerms(term => !term)}
              checked={terms}
            />
          )}
        </UXPCard.Content>
        <UXPCard.Divider />
        <UXPCard.Actions>
          <TextPrimaryButton onClick={onCancel}>
            {intl.formatMessage({ id: 'common.button.cancel' })}
          </TextPrimaryButton>
          <Button
            color="primary"
            disabled={disableVerifyButton}
            isLoading={progress}
            onClick={onVerifyCode}
          >
            {intl.formatMessage({ id: confirmLabelId })}
          </Button>
        </UXPCard.Actions>
      </UXPCard.Card>
      <SubscribeContentDialog
        open={openDialog}
        onClose={onCloseDialog}
        flowType={flowType}
        {...productData}
      />
    </React.Fragment>
  )
}

export default VerificationCode
