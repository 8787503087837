import React, { useState } from 'react'

import { useLocation, useHistory } from 'react-router-dom'
import Box from '@mui/material/Box'
import * as UXPCard from 'common/components/Card'
import { useSelector, useDispatch } from 'react-redux'

import { isEmpty, get } from 'lodash'
import { BackendErrorNotification } from 'common/components/backendErrorNotification/BackendErrorNotification'
import Page from 'common/components/Page'
import { Button } from 'common/components/button/Button'
import PasswordField from 'common/components/textfield/PasswordField'
import styles from '../styles.module.css'

import process from 'common/utils/process'
import { checkSession } from 'common/actions/session'
import { analyticsProvider } from '../../App'
import FlowLayout from 'common/layouts/FlowLayout'
import { addNotification } from 'common/actions/notification'
import { useIntl } from 'react-intl'
import LegacyWrapper from '../../common/layouts/LegacyWrapper'
import EnforceAccountAssociationWizard from '../../social/EnforceAccountAssociationWizard'
import getCurrentSubRouteConfiguration from '../../common/utils/getCurrentSubRouteConfiguration'
import getParams from 'common/utils/getParams'
import {
  formatErrorMessage,
  resolveErrorEntity,
} from 'common/utils/processBackendErrors'
import useMainRedirect from 'common/components/hooks/useMainRedirect'

const ErrorView = ({ error, onError, intl }) => (
  <div
    style={{
      display: 'flex',
      flexDirection: 'column',
    }}
  >
    {error.newPassword ??
      resolveErrorEntity({
        intl,
        error: error,
      })}
    <div className="mt2 self-center">
      <Button onClick={onError}>
        {intl.formatMessage({ id: 'common.button.ok' })}
      </Button>
    </div>
  </div>
)

const PasswordExpireForm = ({ history, intl, setStepData }) => {
  const location = useLocation()
  const socialAccounts = useSelector(state => state.socialAccounts)
  const flowType = useSelector(state => state.configuration.config.type)
  const dispatch = useDispatch()
  const mainRedirect = useMainRedirect()

  const [newPassword, setNewPassword] = useState('')
  const [errors, setErrors] = useState({})
  const [errorResponseBody, setErrorResponseBody] = useState(null)
  const [isLoading, setIsLoading] = useState(false)
  const { state } = location

  const pageTitle = 'reset'
  const baseEventTag = `expire.${pageTitle}`

  const handleNewPasswordChange = event => {
    setNewPassword(event.target.value)
  }

  const submitForm = event => {
    event.preventDefault()
    analyticsProvider.sendAnalytics({
      type: 'event',
      action: 'click',
      event_category: 'button',
      event_label: `${baseEventTag}.change-password`,
      value: 0,
    })
    if (!state) {
      goHome()
      return
    }

    const { userAuthenticated, processId } = state
    const errors = {}

    if (!newPassword) {
      errors.newPassword = intl.formatMessage({ id: 'error.new-password' })
      setErrors(errors)
      return
    }

    setErrors({})
    setIsLoading(true)

    return process
      .step({
        userAuthenticated,
        processId,
        parameters: { newPassword },
      })
      .then(res => {
        dispatch(
          addNotification({
            message: intl.formatMessage({
              id: 'notification.password-changed',
            }),
            variant: 'success',
          }),
        )

        const stepName = get(res, 'body.stepName')
        const stepData = get(res, 'body')

        if (stepName) {
          setStepData(stepData)
          return
        }

        if (get(res, 'body.userAuthenticated') && get(res, 'body.lastStep')) {
          const isStaticRedirect = mainRedirect(res.metadata)
          if (!isStaticRedirect) {
            return
          }
        }

        if (process.isAuthComplete(res)) {
          dispatch(checkSession())
        }
      })
      .catch(err => {
        analyticsProvider.sendAnalytics({
          type: 'event',
          action: 'click',
          event_category: 'button',
          event_label: `${baseEventTag}.change-password`,
          value: 1,
        })

        setErrorResponseBody(err.body)
        setIsLoading(false)
      })
  }

  const goHome = () => {
    let returnPath = flowType !== 'combinedMultiPage' ? '/' : '/login'
    const params = getParams(window.location.search)
    const { currentSubRoute } = getCurrentSubRouteConfiguration()
    const pathName = currentSubRoute !== '/' ? currentSubRoute : ''
    if (pathName) {
      returnPath = `${pathName}?${params}`
    }
    history.push(returnPath)
  }

  if (!process.isProcess(state) || state.stepName !== 'UpdatePassword') {
    goHome()
  }

  const { error, hasFailed } = socialAccounts
  if (hasFailed && !isEmpty(error)) {
    return (
      <ErrorView
        error={error || errors}
        onError={() => history.push('/')}
        intl={intl}
      />
    )
  }
  const actionStyle =
    flowType !== 'combinedMultiPage' ? { justifyContent: 'flex-start' } : null

  return (
    <form>
      <Page.Body>
        {intl.formatMessage({ id: 'expire-password.message' })}
      </Page.Body>
      <p
        className={styles.formErrorMessage}
        style={{
          visibility:
            errors.newPassword || errorResponseBody ? 'visible' : 'hidden',
        }}
      >
        {!!errorResponseBody && (
          <Box mb={3}>
            <BackendErrorNotification
              intl={intl}
              subTitle={
                <div>
                  {formatErrorMessage({
                    intl,
                    id: 'error.credential-at-least',
                  })}
                </div>
              }
              error={errorResponseBody}
              handleClose={() => {
                setErrorResponseBody(null)
              }}
              prioritize={['NotReusedPasswordByTime', 'NotReusedPassword']}
              showAsSingle={['NotReusedPasswordByTime', 'NotReusedPassword']}
            />
          </Box>
        )}
      </p>
      <PasswordField
        errorText={errors.newPassword}
        label={intl.formatMessage({ id: 'common.label.new-password' })}
        onChange={handleNewPasswordChange}
        value={newPassword}
      />
      <UXPCard.Actions style={{ ...actionStyle, marginTop: '32px' }}>
        <Button onClick={submitForm} isLoading={isLoading} size="large">
          {intl.formatMessage({
            id: 'common.button.change-password',
          })}
        </Button>
        <Button onClick={goHome} variant="outlined" size="large">
          {intl.formatMessage({ id: 'common.button.cancel' })}
        </Button>
      </UXPCard.Actions>
    </form>
  )
}

const PasswordExpire = props => {
  const [stepData, setStepData] = useState(null)

  const stepName = get(stepData, 'stepName')

  const useLegacyLayout = useSelector(
    state => state.configuration.config.enableOnlyWizardRoutes,
  )

  const Layout = props =>
    useLegacyLayout ? <LegacyWrapper {...props} /> : <FlowLayout {...props} />

  const intl = useIntl()

  const history = useHistory()

  switch (stepName) {
    case 'AccountIdentifierPrompt': {
      return <EnforceAccountAssociationWizard initialData={stepData} />
    }
    default:
      break
  }

  return (
    <Layout
      title={intl.formatMessage({ id: 'expire-password.title' })}
      process={props.process}
      type={props.type}
    >
      <PasswordExpireForm
        history={history}
        intl={intl}
        {...props}
        setStepData={setStepData}
      />
    </Layout>
  )
}

export default PasswordExpire
