import { hasUIFeatureFlag } from 'common/services/FeatureFlagsService'

const getCurrentSubRouteConfiguration = () => {
  const defaultRoute = hasUIFeatureFlag('defaultUIRoute')
  const supportedConfigs = hasUIFeatureFlag('availableUIRouteConfigs')
  const supportedSubRoutes = Object.keys(supportedConfigs)
  const currentSubRoute = `/${window.location.pathname.split('/')[1]}`

  if (supportedSubRoutes.includes(currentSubRoute)) {
    return {
      currentSubRoute,
      config: supportedConfigs[currentSubRoute],
    }
  }

  return {
    currentSubRoute: defaultRoute,
    config: supportedConfigs[defaultRoute],
  }
}

export default getCurrentSubRouteConfiguration
