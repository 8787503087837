import React from 'react'
import classNames from 'classnames'
import { injectIntl } from 'react-intl'

import Page from 'common/components/Page'
import RwdPaper from 'common/components/RwdPaper'
import { Button } from 'common/components/button/Button'
import NameFields from 'common/components/nameField/NameFields'
import Checkbox from 'common/components/checkBox/CheckBox'
import FeatureFlagsService, {
  getNameFormat,
} from 'common/services/FeatureFlagsService'

import { constants } from 'common/components/Constants'
import { trim } from 'lodash'
import useSocialProviderIcon from 'common/components/hooks/useSocialProviderIcon'
import isEmail from 'common/utils/isEmail'

import styles from './styles.module.css'
import { ReCaptchaText } from 'common/components/ReCAPTCHA'
import { Link } from '@mui/material'
import { Body, Caption, Subtitle } from 'common/components/Typography'

const isFullName = () => getNameFormat() === constants.FULL_NAME
const validateCredentials = credentials =>
  isEmail(credentials) || trim(credentials) === ''
const hasFeatureFlag = feature =>
  FeatureFlagsService.get(`idpPortal.verifyUserInfo.${feature}`)

const getCombinedName = (firstName, lastName) => {
  let combinedName = ''
  if (firstName) combinedName = firstName
  if (lastName) combinedName = combinedName + ' ' + lastName

  return combinedName
}

const VerifyUserInfo = ({
  inputs,
  providerId,
  onInputChange,
  onConfirm,
  onCancel,
  intl,
  errors,
}) => {
  const {
    lastName = '',
    firstName = '',
    displayName = '',
    email,
    termsAndConditions,
  } = inputs
  const values = {
    firstName,
    lastName,
    displayName,
  }
  const { icon, width, height } = useSocialProviderIcon(providerId)
  const isValidCredentials = validateCredentials(email)
  const isNameEmpty = isFullName() ? !displayName : !firstName || !lastName
  const disabled = isNameEmpty || !isValidCredentials || !termsAndConditions
  const showTermsAndConditions = hasFeatureFlag('showTermsAndConditions')
  const showCaptchaText = FeatureFlagsService.get(
    'system.security.promptCaptcha',
  )

  const combinedName = getCombinedName(firstName, lastName)

  const subtitle = isFullName() ? displayName : combinedName

  return (
    <RwdPaper hideLogo>
      <div className={styles.panel}>
        <div className={styles.title}>
          <Page.Title>
            {intl.formatMessage({ id: 'social-end.title' })}
          </Page.Title>
        </div>
        {subtitle && (
          <div className={styles.providerArea}>
            <img src={icon} width={width} height={height} alt="provider-img" />
            <Subtitle>{subtitle}</Subtitle>
          </div>
        )}
        <p className={styles.verifyMessage}>
          <Body>{intl.formatMessage({ id: 'social-end.message' })}</Body>
        </p>
        <NameFields
          className={classNames({ [styles.nameFieldWidth]: !isFullName() })}
          responsiveGrid={styles.responsiveGrid}
          fullWidth={isFullName()}
          errorTexts={errors}
          onChange={onInputChange}
          values={values}
        />
        {showTermsAndConditions && (
          <div className="mt2">
            <Checkbox
              alignTop
              label={
                <Caption>
                  {intl.formatMessage(
                    { id: 'sign-up.progressive.terms-and-conditions' },
                    {
                      'terms-link': str => <Link href="#">{str}</Link>,
                      'privacy-link': str => <Link href="#">{str}</Link>,
                    },
                  )}
                </Caption>
              }
              checked={termsAndConditions}
              onChange={onInputChange('termsAndConditions')}
              value="termsAndConditions"
            />
          </div>
        )}
        {showCaptchaText && (
          <ReCaptchaText style={{ marginBottom: '0px', marginTop: '1.1rem' }} />
        )}
        <div className="flex justify-start mt3">
          <Button onClick={onConfirm} disabled={disabled}>
            {intl.formatMessage({ id: 'common.button.confirm' })}
          </Button>
          <div className="ml2">
            <Button variant="outlined" onClick={onCancel} uppercase>
              {intl.formatMessage({ id: 'common.button.cancel' })}
            </Button>
          </div>
        </div>
      </div>
    </RwdPaper>
  )
}

export default injectIntl(VerifyUserInfo)
