import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import { injectIntl } from 'react-intl'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { isEmpty } from 'lodash'

import makeStyles from '@mui/styles/makeStyles'
import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import CircularProgress from '@mui/material/CircularProgress'
import { List } from '@admin-ui-common/base-user'

import WrapperContainer from 'protected/common/WrapperContainer'
import Page from 'common/components/Page'
import Paper from 'protected/common/Paper'

import UserLine from './UserLine'
import ShareAccount from './ShareAccount'
import UnlinkAccountButton from './unlink/UnlinkAccountButton'
import Service from './Service'
import FeatureFlagsService from 'common/services/FeatureFlagsService'

import {
  getAccountUsersInProgress,
  getAccountUsers,
  getAccounts,
  getAccountById,
  getIsAccountsInProgress,
  getAccountSubscriptions,
  getAccountNameLabelById,
} from 'common/selectors/accounts'
import { loadAccountUsers, loadAccounts } from 'common/actions/accounts'

const useStyles = makeStyles({
  paper: {
    paddingBottom: 20,
  },
})

const hasFeatureFlag = feature =>
  FeatureFlagsService.get(`idpPortal.accountDetail.${feature}`)
const AccountDetail = ({
  intl,
  loadAccountUsers,
  loadAccounts,
  account,
  accountName,
  users,
  services,
  id: accountId,
  isAccountUsersInProgress,
  isAccountsInProgress,
}) => {
  const classes = useStyles()
  const showUnlinkButton = hasFeatureFlag('showUnlinkButton')

  useEffect(() => {
    if (isEmpty(account)) {
      loadAccounts()
      loadAccountUsers(accountId)
    }
  }, [account, accountId, loadAccountUsers, loadAccounts])

  if (isAccountsInProgress || isAccountUsersInProgress) {
    return <CircularProgress />
  }

  return (
    <WrapperContainer>
      <Grid item xs={12}>
        <Box
          my={3.5}
          display="flex"
          alignItems="center"
          className={classes.title}
        >
          <Box mr={1.75}>
            <Link to="/accounts">
              <ArrowBackIcon />
            </Link>
          </Box>
          <Page.Title>
            {intl.formatMessage(
              { id: 'accounts.account-name' },
              { name: accountName },
            )}
          </Page.Title>
        </Box>
      </Grid>

      <Paper>
        <Grid item xs={12}>
          <List
            elevation={2}
            subheader={
              <List.ListSubheader size="large" component="div">
                <Box fontSize={20} textAlign="left">
                  {intl.formatMessage({ id: 'accounts.invited-members' })}
                </Box>
              </List.ListSubheader>
            }
          >
            {users.map(user => (
              <UserLine key={user.id} user={user} />
            ))}
            <Box ml={2} alignSelf="flex-start">
              <ShareAccount account={account} />
              {showUnlinkButton && <UnlinkAccountButton account={account} />}
            </Box>
          </List>
        </Grid>
      </Paper>

      <Grid item xs={12}>
        <Paper className={classes.paper}>
          <Page.Title>
            <Box fontSize={20} textAlign="left">
              {intl.formatMessage({ id: 'accounts.services' })}
            </Box>
          </Page.Title>

          <Box mt={3} className="q-accountDetails-services">
            {services.map(service => (
              <Service key={service.id} service={service} />
            ))}
          </Box>
        </Paper>
      </Grid>
    </WrapperContainer>
  )
}

AccountDetail.propTypes = {
  intl: PropTypes.object,
  loadAccountUsers: PropTypes.func,
  users: PropTypes.array,
  services: PropTypes.array,
  isAccountUsersInProgress: PropTypes.bool,
  isAccountsInProgress: PropTypes.bool,
  id: PropTypes.number,
  accounts: PropTypes.array,
  account: PropTypes.object,
  accountName: PropTypes.string,
}

const mapState = (state, { id }) => ({
  users: getAccountUsers(state, id),
  services: getAccountSubscriptions(state, id),
  isAccountUsersInProgress: getAccountUsersInProgress(state, id),
  isAccountsInProgress: getIsAccountsInProgress(state),
  accounts: getAccounts(state),
  account: getAccountById(state, id),
  accountName: getAccountNameLabelById(state, id),
})

const mapDispatch = {
  loadAccountUsers,
  loadAccounts,
}

const connectedToProps = connect(mapState, mapDispatch)

export default compose(connectedToProps, injectIntl)(AccountDetail)
