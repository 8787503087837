import isEmpty from 'lodash/isEmpty'

const getParams = query => {
  if (!isEmpty(query)) {
    return query.substring(1)
  }
  return ''
}

export default getParams
