import React from 'react'
import { useIntl } from 'react-intl'
import { useSelector } from 'react-redux'
import makeStyles from '@mui/styles/makeStyles'
import { Box, Typography } from '@mui/material'
import DeleteIcon from '@mui/icons-material/Delete'
import MessageIcon from '@mui/icons-material/TextsmsOutlined'
import { TextPrimaryButton } from 'common/components/button/Button'
import { List } from '@admin-ui-common/base-user'
import {
  checkAllEmailMobileRegister,
  isBothEmailAndMobileRegistered,
  regsiterEmailMobileList,
} from '../../profile/selectors'
import { isEmpty } from 'lodash'
import { displayIdentifier } from 'common/utils/formatNumber'

const useStyles = makeStyles(theme => ({
  iconWrapper: {
    alignSelf: 'flex-start',
  },
  icon: {
    fontSize: '40px',
    color: theme.palette.text.primary,
  },
  bold: {
    fontWeight: 'bold',
  },
  fowardArrowIcon: {
    fontSize: 24,
    color: theme.palette.text.secondary,
    cursor: 'pointer',
  },
  textEmailList: {
    marginBottom: 16,
    marginTop: 8,
  },
}))

export const TextMessageOrEmail = ({
  hasMinMfaRegistered,
  registeredEmailPhone,
  setupTextMessageOrEmail,
  unRegisterMFA,
}) => {
  const classes = useStyles()
  const intl = useIntl()
  const state = useSelector(state => state)
  const appId = useSelector(state => state.configuration.config.id)
  const isAllEmailMobileRegister =
    checkAllEmailMobileRegister(state) || isBothEmailAndMobileRegistered(state)
  const registeredEmailMobile = regsiterEmailMobileList(state)
  const textEmailSubtitle = !isEmpty(registeredEmailMobile)
    ? 'profile.label.text-message-or-email-subtitle'
    : 'profile.detail.authenticator-app.get-text-or-email-verification'
  const btnText = !isEmpty(registeredEmailMobile)
    ? 'profile.label.add-message-or-email'
    : 'profile.detail.authenticator-app.setup-text-or-email'
  return (
    <>
      <List.ListItem className={classes.textEmailList}>
        <List.ListItemIcon className={classes.iconWrapper}>
          <MessageIcon className={classes.icon} />
        </List.ListItemIcon>
        <List.ListItemText
          primary={
            <Typography className={classes.bold} variant="body1">
              {intl.formatMessage({
                id: 'profile.detail.authenticator-app.text-or-email',
              })}
            </Typography>
          }
          secondary={intl.formatMessage({
            id: textEmailSubtitle,
          })}
        />
      </List.ListItem>
      {registeredEmailPhone &&
        registeredEmailPhone.map(value => {
          const identity = value.email ? value.email : value.number
          const formattedIdentity = value.email
            ? identity
            : displayIdentifier(identity, 'mobile', appId)
          return (
            <List.ListItem>
              <List.ListItemIcon className={classes.iconWrapper} />
              <List.ListItemText primary={formattedIdentity} />
              {!hasMinMfaRegistered && (
                <List.ListItemSecondaryAction>
                  <DeleteIcon
                    className={classes.fowardArrowIcon}
                    onClick={() => unRegisterMFA(identity, 'emailmobile')}
                  />
                </List.ListItemSecondaryAction>
              )}
            </List.ListItem>
          )
        })}
      {!isAllEmailMobileRegister && (
        <Box mt={2} ml={8}>
          <TextPrimaryButton onClick={setupTextMessageOrEmail}>
            {intl.formatMessage({
              id: btnText,
            })}
          </TextPrimaryButton>
        </Box>
      )}
    </>
  )
}
export default TextMessageOrEmail
