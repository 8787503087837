import { Redirect, Route, Switch } from 'react-router-dom'

import FeatureFlagsService from 'common/services/FeatureFlagsService'
import ForgotWizard from './forgot/ForgotWizard'
import SignInPage from './signin/SignInPage'
import SignUpSinglePage from './signup/SignUp'
import SignUpMultiPage from './signup/separatedMultiPage/SignUp'
import Reset from './forgot/Reset'
import PasswordExpire from './signin/PasswordExpire'
import MobileConnectEnd from '../social/MobileConnectEnd'
import GoogleEnd from '../social/GoogleEnd'
import SocialEnd from '../social/SocialEnd'
import UserConfirmPage from './invitation/UserConfirmPage'
import ConfirmUserByToken from './signup/ConfirmUserByToken'
import DeviceConnection from './deviceauthorization/DeviceConnection'

import SubscribeWizard from 'common/wizards/subscribe/SubscribeWizard'
import ForgotPasswordWizard from 'common/wizards/forgotPassword/ForgotPasswordWizard'
import CancelSubscribeWizard from 'common/wizards/cancelSubscribe/CancelSubscribeWizard'
import NotFound from 'common/wizards/common/NotFound'
import { useSelector } from 'react-redux'
import { useLocale } from '@admin-ui-common/utils'
import { getFlowTypes } from 'common/constants'
import { getAvailableLocales } from 'common/constants/locale'
import SignInV2 from './signin-v2/SignInV2'

import { SEPARATED_MULTI_PAGE_CONTEXT } from '../common/constants/configuration'
import NotLoaded from '../common/wizards/common/NotLoaded'
import { disableOnboardingFeatureFlag } from 'common/utils/disableOnboardingFeatureFlag'
import ConsentResultPage from './impersonation-consent/ConsentResultPage'
import { IMPERSONATING_USER_CONSENT_ROUTE } from 'common/constants/impersonation'

const Lander = ({ enableOnlyWizardRoutes }) => {
  const user = useSelector(state => state.user)

  const detectLocale = useSelector(
    state => state.configuration.config.detectLocale,
  )
  const defaultLocale = useSelector(
    state => state.configuration.config.defaultLocale,
  )

  const hasSession = useSelector(state => state.session.hasSession)
  const localeFromStore = useSelector(state => state.locale)
  const locale = useLocale(
    user,
    localeFromStore,
    hasSession,
    FeatureFlagsService.get('system.defaultLocale'),
    detectLocale,
    defaultLocale,
    getAvailableLocales(),
  )

  const configType = useSelector(state => state.configuration.config.type)

  const SignInPageComponent =
    configType === SEPARATED_MULTI_PAGE_CONTEXT ? SignInV2 : SignInPage

  const SignUp =
    configType === SEPARATED_MULTI_PAGE_CONTEXT
      ? SignUpMultiPage
      : SignUpSinglePage
  const FLOW_TYPES = getFlowTypes()

  return (
    <Switch>
      {!enableOnlyWizardRoutes && [
        <Route exact path="/" component={SignInPageComponent} key="root" />,
        !disableOnboardingFeatureFlag() && (
          <Route
            exact
            path="/signup"
            render={props => <SignUp {...props} locale={locale} />}
            key="signup"
          />
        ),
        <Route
          exact
          path="/onboardOrAuthenticate"
          component={SignInPageComponent}
          key="OnboardAuthenticate"
        />,
        <Route exact path="/forgot" component={ForgotWizard} key="forgot" />,
        <Route exact path="/reset" component={Reset} key="reset" />,
        <Route
          path={IMPERSONATING_USER_CONSENT_ROUTE}
          exact
          component={ConsentResultPage}
        />,
        <Route
          exact
          path="/password_expire"
          component={PasswordExpire}
          key="password_expire"
        />,
        <Route
          exact
          path="/social_end"
          render={() => <SocialEnd locale={locale} />}
          key="social_end"
        />,
        /**
         * This route is forced to always render without session
         * check FORCE_NOSESSION_ROUTES constant
         */
        <Route
          exact
          path="/device_authorize"
          component={DeviceConnection}
          key="device_authorize"
        />,
        <Route
          exact
          path="/mobileconnect_end"
          component={MobileConnectEnd}
          key="mobileconnect_end"
        />,
        <Route
          exact
          path="/google_end"
          component={GoogleEnd}
          key="google_end"
        />,
        <Route
          exact
          path="/household_invitation"
          component={UserConfirmPage}
          key="household_invitation"
        />,
        <Route
          exact
          path="/user_confirm"
          component={ConfirmUserByToken}
          key="user_confirm"
        />,
        <Route
          render={({ location }) => (
            <Redirect
              to={{
                pathname: '/',
                search: location.search,
              }}
            />
          )}
          key="default"
        />,
      ]}
      {enableOnlyWizardRoutes && [
        <Route
          exact
          path={`/${FLOW_TYPES.LOGIN}`}
          render={props => (
            <SubscribeWizard flowType={FLOW_TYPES.LOGIN} {...props} />
          )}
          key="login"
        />,
        <Route
          exact
          path={`/${FLOW_TYPES.CANCEL}`}
          render={() => <CancelSubscribeWizard flowType={FLOW_TYPES.CANCEL} />}
          key="cancel"
        />,
        <Route
          exact
          path="/forgot_password"
          render={() => <ForgotPasswordWizard flowType={FLOW_TYPES.FORGOT} />}
          key="forgot_password"
        />,
        <Route
          exact
          path="/password_expire"
          render={() => (
            <PasswordExpire flowType={FLOW_TYPES.PASSWORD_EXPIRE} />
          )}
          key="password_expire"
        />,
        <Route component={NotFound} key="not_found" />,
      ]}
      <Route path="/something-went-wrong" component={NotLoaded} />
    </Switch>
  )
}

export default Lander
