import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useDispatch } from 'react-redux'
import { useIntl } from 'react-intl'

import FormControl from '@mui/material/FormControl'
import Box from '@mui/material/Box'
import Radio from '@mui/material/Radio'
import RadioGroup from '@mui/material/RadioGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import { Subtitle } from 'common/components/Typography'

import * as UXPDialog from 'common/components/dialog/UXPDialog'
import { UserButton } from '@admin-ui-common/base-user'
import Page from 'common/components/Page'

import { addNotification } from 'common/actions/notification'
import { loadAccounts } from 'common/actions/accounts'
import process from 'common/utils/process'
import { resolveErrorEntity } from 'common/utils/processBackendErrors'
import { get } from 'lodash'
import { ASSOCIATION_PROMPT } from 'common/constants/account'
import { getAccountDisplay } from 'common/selectors/accounts'
import { analyticsProvider } from '../../App'

const getAccountNumberDisplayName = (displayName, obfuscatedAccountNumber) => {
  if (displayName) {
    return `${displayName} (${obfuscatedAccountNumber})`
  }

  return `Account # ${obfuscatedAccountNumber}`
}

const AssociateAccountDialog = ({
  onClose,
  open,
  discoveredAccounts,
  stepData,
}) => {
  const [currentAccountName, setCurrentAccountName] = useState(null)
  const [inProgress, setInProgress] = useState(false)
  const intl = useIntl()
  const dispatch = useDispatch()

  const onChangeAccount = e => setCurrentAccountName(e.target.value)

  const onLinkAccount = () => {
    analyticsProvider.sendAnalytics({
      type: 'event',
      action: 'click',
      event_category: 'button',
      event_label: `associateAccountDialog.link-account`,
      value: 0,
    })

    setInProgress(true)

    stepData.parameters = {}
    const nextStep = { ...stepData }
    nextStep.parameters = {
      accountKeys: [currentAccountName],
    }

    process
      .step(nextStep)
      .then(res => {
        setCurrentAccountName(null)

        const stepData = res.body
        const challengeQuestionEnabled =
          get(stepData, 'stepName') === ASSOCIATION_PROMPT.question

        if (challengeQuestionEnabled) {
          const payload = {
            challengeQuestionEnabled,
            accountNumber: currentAccountName,
            stepData,
          }

          onClose(payload)
        } else {
          dispatch(
            addNotification({
              message: intl.formatMessage(
                { id: 'notification.account-linked-display' },
                {
                  accountName: getAccountDisplay(discoveredAccounts[0]),
                },
              ),
              variant: 'success',
            }),
          )
          dispatch(loadAccounts())

          onClose()
        }
      })
      .catch(err => {
        analyticsProvider.sendAnalytics({
          type: 'event',
          action: 'click',
          event_category: 'button',
          event_label: `associateAccountDialog.link-account`,
          value: 1,
        })
        dispatch(
          addNotification({
            message: resolveErrorEntity({ intl, error: err.body }),
          }),
        )
        onClose()
      })
      .finally(() => setInProgress(false))
  }

  const closeView = () => {
    analyticsProvider.sendAnalytics({
      type: 'event',
      action: 'click',
      event_category: 'button',
      event_label: `associateAccountDialog.close`,
      value: 0,
    })
    setCurrentAccountName(null)
    onClose()
  }

  return (
    <UXPDialog.Dialog open={open} fullScreen fullWidth maxWidth="sm">
      <UXPDialog.Title title="Accounts discovered" onClose={closeView} />
      <UXPDialog.Content>
        <Box mb={3}>
          <Page.Description>
            We discovered multiple accounts that we think belong to you. Please
            select an account that you’d like to link to your Amdocs ID and
            continue.
          </Page.Description>
        </Box>

        <FormControl component="fieldset">
          <RadioGroup value={currentAccountName} onChange={onChangeAccount}>
            {discoveredAccounts.map(
              ({ displayName, accountName, obfuscatedAccountNumber }) => {
                return (
                  <FormControlLabel
                    key={displayName}
                    value={accountName}
                    control={<Radio color="primary" />}
                    label={
                      <Subtitle>
                        {getAccountNumberDisplayName(
                          displayName,
                          obfuscatedAccountNumber,
                        )}
                      </Subtitle>
                    }
                  />
                )
              },
            )}
          </RadioGroup>
        </FormControl>
      </UXPDialog.Content>

      <UXPDialog.Actions disabled={inProgress}>
        <UserButton
          color="secondary"
          disableRipple
          disableElevation
          onClick={closeView}
        >
          Cancel
        </UserButton>
        <UserButton
          onClick={onLinkAccount}
          disabled={!currentAccountName || inProgress}
          disableRipple
          disableElevation
        >
          Continue
        </UserButton>
      </UXPDialog.Actions>
    </UXPDialog.Dialog>
  )
}

AssociateAccountDialog.propTypes = {
  onClose: PropTypes.func,
  open: PropTypes.bool,
  stepData: PropTypes.object,
  discoveredAccounts: PropTypes.array,
}

export default AssociateAccountDialog
