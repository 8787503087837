import React, { useState } from 'react'
import { useIntl } from 'react-intl'
import { isEmpty } from 'lodash'
import { useSelector, useDispatch } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'

import { analyticsProvider } from '../../../App'
import PasswordDialog from './twoFactorRegisterEmailMobileModal/PasswordDialog'
import SelectIdentityDialog from './twoFactorRegisterEmailMobileModal/SelectIdentityDialog'
import VerifyEmailMobile from './twoFactorRegisterEmailMobileModal/VerifyEmailMobile'

import {
  RegisterAuthenticatorAppAsMFA,
  RegisterAuthnIdAsMFA,
} from 'common/actions/twoStepVerification'
import AddTwoStepMethodView from './AddTwoStepMethodView'
import AvailableTwoStepMethodsView from './AvailableTwoStepMethodsView'

import { regsiterEmailMobileList } from '../../profile/selectors'
import QRCodeDialog from './twoFactorAuthAppModal/QRCodeDialog'
import TotpVerificationDialog from './twoFactorAuthAppModal/TotpVerificationDialog'
import {
  AUTHN_IDENTIFIER_PROMPT,
  OTP_PROMPT,
  PASSWORD_PROMPT,
  QR_CODE_PROMPT,
  TOTP_VERIFICATION_PROMPT,
} from '../../../common/constants/twoStepVerification'

export const mfaDialogTypes = {
  EMAIL_OR_PHONE: 'EMAIL_OR_PHONE',
  TOTP_APP: 'TOTP_APP',
}

const TwoStepVerificationDetailView = props => {
  const dispatch = useDispatch()
  const location = useLocation()
  const history = useHistory()
  const { hash } = location

  const [passwordDialog, setPasswordDialog] = useState(false)
  const [selectIdentifierDialog, setSelectIdentifierDialog] = useState(false)
  const [qrCodeDialog, setQRCodeDialog] = useState(false)
  const [totpVerificationDialog, setTotpVerificationDialog] = useState(false)
  const [otpDialog, setOtpDialog] = useState(false)
  const [errorResponseBody, setErrorResponseBody] = useState(null)
  const state = useSelector(state => state)
  const flowType = 'detailView'
  const pageTitle = 'two_step_verification'
  const baseEventTag = `${flowType}.${pageTitle}`
  const { backLink } = props
  const intl = useIntl()
  const registeredEmailMobile = regsiterEmailMobileList(state)
  const secret2FATotp = state.user.attributes.secret2FATotp
  const [mfaDialogType, setMfaDialogType] = useState(null)

  const registerAuthnId = () => {
    RegisterAuthnIdAsMFA()(dispatch)
      .then(() => {
        setErrorResponseBody(null)
      })
      .catch(err => {
        setErrorResponseBody(err.body)
      })
  }

  const registerAuthenticatorApp = () => {
    RegisterAuthenticatorAppAsMFA()(dispatch)
      .then(() => {
        setErrorResponseBody(null)
      })
      .catch(err => {
        setErrorResponseBody(err.body)
      })
  }

  const openPasswordModal = mfaDialogType => {
    switch (mfaDialogType) {
      case mfaDialogTypes.EMAIL_OR_PHONE:
        registerAuthnId()
        break
      case mfaDialogTypes.TOTP_APP:
        registerAuthenticatorApp()
        break
      default:
        break
    }

    setMfaDialogType(mfaDialogType)
    setPasswordDialog(true)
  }

  if (hash === '#password' && !passwordDialog) {
    openPasswordModal(mfaDialogType)
  }

  const onUpdateStatus = action => {
    let pathname = `${location.pathname}/${action}`
    if (pathname.match(/\/{2,}/g)) {
      pathname = pathname.replace(/\/{2,}/g, '/')
    }
    history.push(pathname)
  }

  const setupAuthenticatorApp = () => {
    analyticsProvider.sendAnalytics({
      type: 'event',
      action: 'click',
      event_category: 'button',
      event_label: `${baseEventTag}.setup-authenticator-app`,
      value: 0,
    })
    openPasswordModal(mfaDialogTypes.TOTP_APP)
    onUpdateStatus('#password')
  }

  const setupTextMessageOrEmail = () => {
    analyticsProvider.sendAnalytics({
      type: 'event',
      action: 'click',
      event_category: 'button',
      event_label: `${baseEventTag}.setup-text-or-email`,
      value: 0,
    })
    openPasswordModal(mfaDialogTypes.EMAIL_OR_PHONE)
    onUpdateStatus('#password')
  }

  const nextCallBack = stepName => {
    if (stepName === AUTHN_IDENTIFIER_PROMPT) {
      setSelectIdentifierDialog(true)
    }
    if (stepName === OTP_PROMPT) {
      setOtpDialog(true)
    }

    if (stepName === QR_CODE_PROMPT) {
      setQRCodeDialog(true)
    }
    if (stepName === TOTP_VERIFICATION_PROMPT) {
      setTotpVerificationDialog(true)
    }
  }

  const onClose = stepName => {
    if (stepName === PASSWORD_PROMPT) {
      history.push(`${location.pathname}`)
      setPasswordDialog(false)
    } else if (stepName === AUTHN_IDENTIFIER_PROMPT) {
      setSelectIdentifierDialog(false)
    } else if (stepName === QR_CODE_PROMPT) {
      setQRCodeDialog(false)
    } else if (stepName === TOTP_VERIFICATION_PROMPT) {
      setTotpVerificationDialog(false)
    } else {
      setOtpDialog(false)
    }
  }

  return (
    <>
      {isEmpty(registeredEmailMobile) && isEmpty(secret2FATotp) && (
        <AvailableTwoStepMethodsView
          setupTextMessageOrEmail={setupTextMessageOrEmail}
          setupAuthenticatorApp={setupAuthenticatorApp}
          backLink={backLink}
        />
      )}

      {(!isEmpty(registeredEmailMobile) || !isEmpty(secret2FATotp)) && (
        <AddTwoStepMethodView
          title={intl.formatMessage({
            id: 'profile.detail.two-step-verification.title',
          })}
          description={intl.formatHTMLMessage({
            id: 'profile.detail.two-step-verification.description',
          })}
          backLink={backLink}
          setupTextMessageOrEmail={setupTextMessageOrEmail}
          setupAuthenticatorApp={setupAuthenticatorApp}
          registeredEmailPhone={registeredEmailMobile}
        />
      )}

      {passwordDialog && (
        <PasswordDialog
          open={passwordDialog}
          onClose={onClose}
          nextCallBack={nextCallBack}
          flowType={baseEventTag}
          errorResponseBody={errorResponseBody ?? undefined}
          mfaDialogType={mfaDialogType}
        />
      )}
      {selectIdentifierDialog && (
        <SelectIdentityDialog
          open={selectIdentifierDialog}
          onClose={onClose}
          nextCallBack={nextCallBack}
          flowType={baseEventTag}
        />
      )}
      {otpDialog && (
        <VerifyEmailMobile
          open={otpDialog}
          onClose={onClose}
          flowType={baseEventTag}
        />
      )}
      {qrCodeDialog && (
        <QRCodeDialog
          open={qrCodeDialog}
          onClose={onClose}
          nextCallBack={nextCallBack}
          flowType={baseEventTag}
        />
      )}
      {totpVerificationDialog && (
        <TotpVerificationDialog
          open={totpVerificationDialog}
          onClose={onClose}
          nextCallBack={nextCallBack}
          flowType={baseEventTag}
        />
      )}
    </>
  )
}

export default TwoStepVerificationDetailView
