import React from 'react'
import PropTypes from 'prop-types'
import { useIntl } from 'react-intl'

import makeStyles from '@mui/styles/makeStyles'
import { Grid, Box, Typography } from '@mui/material'

import LinkAccountButton from './LinkAccountButton'

const useStyles = makeStyles(theme => ({
  main: {
    margin: theme.spacing(8, 0),
    [theme.breakpoints.down('sm')]: {
      margin: theme.spacing(4, 2),
    },
  },
  link: {
    color: theme.palette.primary.main,
    fontWeight: 'bold',
  },
}))

const EmptyView = ({ onLinkAccount, disabled }) => {
  const intl = useIntl()
  const classes = useStyles()

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="flex-start"
      className={classes.main}
    >
      <Grid container>
        <Grid xs={12} md={8} item>
          <Typography variant="h6" paragraph>
            {intl.formatMessage({ id: 'accounts.no-link-accounts' })}
          </Typography>
          <Typography variant="body1" paragraph>
            {intl.formatMessage({ id: 'accounts.link-accounts-to-id' })}
          </Typography>
        </Grid>
      </Grid>
      <Box mt={2}>
        <LinkAccountButton onClick={onLinkAccount} disabled={disabled} />
      </Box>
    </Box>
  )
}

EmptyView.propTypes = {
  intl: PropTypes.object,
  onLinkAccount: PropTypes.func,
  disabled: PropTypes.bool,
}

export default EmptyView
