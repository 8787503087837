import * as UXPCard from '../components/Card'
import Box from '@mui/material/Box'
import Page from '../components/Page'
import React from 'react'

const LegacyWrapper = ({ children, title }) => {
  return (
    <UXPCard.Card>
      <Box mb={3}>
        <Page.Title>{title}</Page.Title>
      </Box>
      {children}
    </UXPCard.Card>
  )
}

export default LegacyWrapper
