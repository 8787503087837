import React from 'react'
import { FormattedMessage } from 'react-intl'

import Box from '@mui/material/Box'
import Link from '@mui/material/Link'
import Checkbox from 'common/components/checkBox/CheckBox'

const TermsAndConditions = ({
  link = 'https://www.hbomax.com/terms-of-use/es-latam',
  onChange,
  checked,
}) => (
  <Checkbox
    label={
      <FormattedMessage
        id="onboard.common.i-agree-privacy"
        values={{
          a: (...chunks) => (
            <Link underline="none" href={link} target="_blank" rel="noopener">
              <Box component="span" fontWeight="bold">
                {chunks}
              </Box>
            </Link>
          ),
        }}
      />
    }
    checked={checked}
    onChange={onChange}
  />
)

export default TermsAndConditions
