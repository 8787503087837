import {
  ADD_NOTIFICATION,
  DISMISS_NOTIFICATION,
} from 'common/actions/notification'

const initialState = null

const notificationReducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_NOTIFICATION:
      return action.payload
    case DISMISS_NOTIFICATION:
      return initialState
    default:
      return state
  }
}

export default notificationReducer
