const USER = {
  attribute: {
    minor: 'com.uxpsystems.mint.model.user.privacy.type.minor',
  },
  flags: {
    executive: 'mint.permission.execute',
    activating: 'mint.status.activating',
    role: 'mint.role',
    rolePrimary: 'mint.role.primary',
    roleAdmin: 'mint.role.admin',
    roleUser: 'mint.role.user',
  },
}

export default USER

export const PRIMARY = 'owner'
export const ADMIN = 'admin'
export const MEMBER = 'regular'
export const MINOR = 'minor'

export const USER_ROLE_MAP = {
  [USER.flags.rolePrimary]: PRIMARY,
  [USER.flags.roleAdmin]: ADMIN,
  [USER.flags.roleUser]: MEMBER,
}

export const ROLE_NAME_MAP = {
  [PRIMARY]: 'Admin (Owner)',
  [ADMIN]: 'Admin',
  [MEMBER]: 'Member',
  [MINOR]: 'Minor',
}
