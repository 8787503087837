import conf from 'conf'
import i from 'common/utils/i'

const featureName = 'SOCIAL_ACCOUNTS'
export const CHECK_TOKEN = {
  request: `${featureName}/CHECK_TOKEN_REQUEST`,
  success: `${featureName}/CHECK_TOKEN_SUCCESS`,
  error: `${featureName}/CHECK_TOKEN_ERROR`,
}
export const CREATE_PASSWORD = {
  request: `${featureName}/CREATE_PASSWORD_REQUEST`,
  success: `${featureName}/CREATE_PASSWORD_SUCCESS`,
  error: `${featureName}/CREATE_PASSWORD_ERROR`,
}
export const HOUSEHOLD_INVITATION = {
  request: `${featureName}/HOUSEHOLD_INVITATION_REQUEST`,
  success: `${featureName}/HOUSEHOLD_INVITATION_SUCCESS`,
  error: `${featureName}/HOUSEHOLD_INVITATION_ERROR`,
}

export const checkToken = query => dispatch => {
  dispatch({ type: CHECK_TOKEN.request })

  const tokenValue = query
    .replace('?', '')
    .split('&')
    .find(_ => _.includes('token_value='))
    .replace('token_value=', '')
  const registerUrl = `${conf.apiRoot}/session/token?value=${tokenValue}`

  return i
    .get(registerUrl, { ignoreErrorRedirect: true })
    .then(res =>
      dispatch({
        type: CHECK_TOKEN.success,
        data: {
          ...res.body,
          headers: res.metadata.headers,
          statusCode: res.metadata.status,
        },
      }),
    )
    .catch(err =>
      dispatch({
        type: CHECK_TOKEN.error,
        error: {
          ...err.body,
          statusCode: err.statusCode || err.status,
        },
      }),
    )
}

export const submitPassword = password => (dispatch, getState) => {
  dispatch({ type: CREATE_PASSWORD.request })

  const stepUrl = `${conf.apiRoot}/process/step`
  const currentProcessData = getState().socialAccounts.checkTokenData
  delete currentProcessData.statusCode

  return i
    .put(stepUrl, {
      data: {
        ...currentProcessData,
        parameters: {
          password,
        },
      },
    })
    .then(res => {
      dispatch({
        type: CREATE_PASSWORD.success,
        data: {
          ...res.body,
          statusCode: res.metadata.status,
        },
      })
    })
    .catch(err =>
      dispatch({
        type: CREATE_PASSWORD.error,
        error: {
          ...err.body,
          statusCode: err.statusCode || err.status,
        },
      }),
    )
}
