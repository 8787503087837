import { combineReducers } from 'redux'
import process from 'common/reducers/process'
import session from 'common/reducers/session'
import user from 'common/reducers/user'
import mobileConnect from 'common/reducers/mobileConnect'
import google from 'common/reducers/google'
import socialMedia from 'common/reducers/socialMedia'
import socialAccounts from 'common/reducers/socialAccounts'
import notification from 'common/reducers/notification'
import accounts from 'common/reducers/accounts'
import settings from 'common/reducers/settings'
import deviceAuth from 'common/reducers/deviceAuth'
import subscription from 'common/reducers/subscription'
import configuration from 'common/reducers/configuration'
import { locale } from '@admin-ui-common/utils'
import dictionary from './common/reducers/dictionary'
import registerMFA from './common/reducers/registerMFA'

export default combineReducers({
  process,
  session,
  user,
  mobileConnect,
  google,
  socialMedia,
  socialAccounts,
  notification,
  accounts,
  settings,
  deviceAuth,
  subscription,
  locale,
  configuration,
  dictionary,
  registerMFA,
})
