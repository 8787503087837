import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useDispatch } from 'react-redux'
import { useIntl } from 'react-intl'
import { pick } from 'lodash'

import Box from '@mui/material/Box'
import Collapse from '@mui/material/Collapse'
import ReceiptIcon from '@mui/icons-material/Receipt'
import InputAdornment from '@mui/material/InputAdornment'

import TextField from 'common/components/textfield/TextField'
import * as UXPDialog from 'common/components/dialog/UXPDialog'
import { UserButton } from '@admin-ui-common/base-user'
import Page from 'common/components/Page'
import { Subtitle } from 'common/components/Typography'
import { InlineNotification } from '@admin-ui-common/base-user'

import { addNotification } from 'common/actions/notification'
import { loadAccounts } from 'common/actions/accounts'
import process from 'common/utils/process'
import { resolveErrorEntity } from 'common/utils/processBackendErrors'
import { getAccountDisplay } from 'common/selectors/accounts'
import { analyticsProvider } from '../../App'

const ChallengeQuestionDialog = ({
  onClose,
  open,
  account,
  accountNumber,
  obfuscatedAccountNumber,
  stepData,
}) => {
  const [amount, setAmount] = useState('')
  const [inProgress, setInProgress] = useState(false)
  const [error, setError] = useState('')
  const intl = useIntl()
  const dispatch = useDispatch()

  useEffect(() => {
    if (open) {
      setError('')
    }
  }, [open])

  const onHandleAmount = e => {
    setAmount(e.target.value)
  }

  const onLinkAccount = () => {
    analyticsProvider.sendAnalytics({
      type: 'event',
      action: 'click',
      event_category: 'button',
      event_label: `challengeQuestionDialog.link-account`,
      value: 0,
    })
    setInProgress(true)
    setError('')

    const nextStep = pick({ ...stepData }, [
      'userAuthenticated',
      'processId',
      'stepName',
    ])
    nextStep.parameters = {
      answers: {
        [accountNumber]: amount,
      },
    }

    process
      .step(nextStep)
      .then(() => {
        setAmount('')

        dispatch(
          addNotification({
            message: intl.formatMessage(
              { id: 'notification.account-linked-display' },
              {
                accountName: getAccountDisplay(account),
              },
            ),
            variant: 'success',
          }),
        )
        dispatch(loadAccounts())

        onClose()
      })
      .catch(err => {
        analyticsProvider.sendAnalytics({
          type: 'event',
          action: 'click',
          event_category: 'button',
          event_label: `challengeQuestionDialog.link-account`,
          value: 1,
        })
        setError(resolveErrorEntity({ intl, error: err.body }))
      })
      .finally(() => setInProgress(false))
  }

  const closeView = () => {
    analyticsProvider.sendAnalytics({
      type: 'event',
      action: 'click',
      event_category: 'button',
      event_label: `challengeQuestionDialog.close`,
      value: 0,
    })
    setAmount('')
    onClose()
  }

  return (
    <UXPDialog.Dialog open={open} fullScreen fullWidth maxWidth="sm">
      <UXPDialog.Title title="Link account" onClose={closeView} />
      <UXPDialog.Content>
        <Box display="flex" alignItems="center" mb={2}>
          <ReceiptIcon />
          <Subtitle ml={1}>Acct# {obfuscatedAccountNumber}</Subtitle>
        </Box>
        <Box mb={3}>
          <Page.Description>
            In order to prove your ownership of this billing account, please
            answer the security question below.
          </Page.Description>
        </Box>

        <Box mb={2}>
          <Subtitle>Security question</Subtitle>
          <Page.Description>
            What is the amount owing on your last bill?
          </Page.Description>
        </Box>

        <TextField
          label="Amount owing"
          value={amount}
          onChange={onHandleAmount}
          startAdornment={<InputAdornment position="start">$</InputAdornment>}
        />

        <Collapse in={Boolean(error)}>
          <Box mt={2}>
            <InlineNotification
              title={intl.formatMessage({
                id: 'accounts.enter-otp.notification.incorrect-otp.title',
              })}
              message={intl.formatMessage({
                id: 'accounts.enter-otp.notification.incorrect-otp.message',
              })}
              variant="error"
              hideClose
            />
          </Box>
        </Collapse>
      </UXPDialog.Content>

      <UXPDialog.Actions disabled={inProgress}>
        <UserButton
          color="secondary"
          disableRipple
          disableElevation
          onClick={closeView}
        >
          Cancel
        </UserButton>
        <UserButton
          onClick={onLinkAccount}
          disableRipple
          disableElevation
          disabled={!amount || inProgress}
        >
          Link account
        </UserButton>
      </UXPDialog.Actions>
    </UXPDialog.Dialog>
  )
}

ChallengeQuestionDialog.propTypes = {
  onClose: PropTypes.func,
  open: PropTypes.bool,
  stepData: PropTypes.object,
  accountNumber: PropTypes.string,
}

export default ChallengeQuestionDialog
