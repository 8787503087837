const isDevelopmentEnv = process.env.NODE_ENV === 'development'

export const featureFlags = {
  'idpPortal.accountDetail.showUnlinkButton': true,
  'idpPortal.signUp.showTermsAndConditions': true,
  'idpPortal.verifyUserInfo.showTermsAndConditions': true,
  'idpPortal.wizard.createId.showTermsAndConditions': false,
  'idpPortal.reset.showConfirmPassword': false,
  'idpPortal.passwordDetailView.showConfirmPassword': false,
  'idpPortal.verifyStep.showVerifyStep': true,
  'system.security.promptCaptcha': false,
  'system.defaultLocale': 'en',
  'uiFeatureFlags.idp.availableLocales': ['en', 'es'],
  'uiFeatureFlags.idp.showLanguageSelector': true,
  'uiFeatureFlags.idp.displayNameFieldRequired': false,
  'uiFeatureFlags.idp.logoImage': {
    light: '',
    dark: '/logonxt-dark.png',
  },
  'uiFeatureFlags.idp.theme': 'default',
  'uiFeatureFlags.idp.theme.type': 'light',
  'uiFeatureFlags.idp.apiGatewayURL': '',
  'uiFeatureFlags.idp.showOperatorLogo': true,
  'uiFeatureFlags.idp.forceDisablePasswordPrompt': false,
  'uiFeatureFlags.idp.allowedOnboardingIdentifiers': ['email', 'phone'],
  'uiFeatureFlags.idp.allowedLoginIdentifiers': ['email', 'phone'],
  'uiFeatureFlags.idp.allowedPasswordResetIdentifiers': ['email', 'phone'],
  'process.userFields': 'email,firstName,lastName,lang,emailPrimary,phone,pin',
  'uiFeatureFlags.idp.defaultUIRoute': '/',
  'uiFeatureFlags.idp.enableOIDCLoginFlow': isDevelopmentEnv,
  'uiFeatureFlags.idp.hideMarkAsTrustedDevice': false,
  'uiFeatureFlags.idp.showSuccessScreen': false,
  'uiFeatureFlags.idp.showPersonalInfoSection': true,
  'uiFeatureFlags.idp.showDisplayNameField': true,
  'uiFeatureFlags.idp.showFullNameField': true,
  'uiFeatureFlags.idp.showContactInfoSection': true,
  'uiFeatureFlags.idp.showEmailField': true,
  'uiFeatureFlags.idp.showMobileNumberField': true,
  'uiFeatureFlags.idp.showPreferredContactField': true,
  'uiFeatureFlags.idp.showSecuritySection': true,
  'uiFeatureFlags.idp.showPasswordField': true,
  'uiFeatureFlags.idp.showMFAConfigurationPage': false,
  'uiFeatureFlags.idp.showPasscodeField': false,
  'uiFeatureFlags.idp.showPINField': true,
  'uiFeatureFlags.idp.showSocialSignInSection': true,
  'uiFeatureFlags.idp.showBillingAccountSection': true,
  'uiFeatureFlags.idp.show2FAUnregister': false,
  'uiFeatureFlags.idp.showDevicesYouTrust': true,
  'uiFeatureFlags.idp.showOnboardingPasswordField': true,
  'uiFeatureFlags.idp.disableLoginPasswordPrompt': false,
  'uiFeatureFlags.idp.disableOnboarding': false,
  'uiFeatureFlags.idp.defaultCountryCode': 'us', // default country code for Mobile number selector
  'uiFeatureFlags.idp.excludeCountryCode': false,
  'uiFeatureFlags.idp.availableCountryCodes': [],
  'uiFeatureFlags.idp.enableFIDO2': isDevelopmentEnv,
  'uiFeatureFlags.idp.wizardLoginFlowAsDefault': false,
  'uiFeatureFlags.idp.wizardShowTermsAndConditions': true,
  'uiFeatureFlags.idp.enableProductIdAlias': false,
  'uiFeatureFlags.idp.loginWithMagicLink': false,
  'uiFeatureFlags.idp.loginWithOTP': false,
  'uiFeatureFlags.idp.proceedNextOnLoginHint': false,
  'uiFeatureFlags.idp.trustedOrigins': '',
  'uiFeatureFlags.idp.favicons': [
    {
      rel: 'icon',
      type: 'image/png',
      sizes: '180x180',
      href: 'apple-touch-icon.png',
    },
    {
      rel: 'icon',
      type: 'image/png',
      sizes: '16x16',
      href: 'favicon-16x16.png',
    },
    {
      rel: 'icon',
      type: 'image/png',
      sizes: '32x32',
      href: 'favicon-32x32.png',
    },
  ],
  'uiFeatureFlags.idp.availableUIRouteConfigs': {
    '/': {
      id: 'idp',
      type: 'separatedMultiPage',
      appTitle: 'NXT IDP',
      detectLocale: true,
      defaultLocale: 'en',
      enableOnlyWizardRoutes: false,
    },
  },
}
