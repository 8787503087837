import { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { useIntl } from 'react-intl'
import { addNotification } from 'common/actions/notification'
import { login } from 'common/utils/fido2'

export const useFido2Login = (username, processId) => {
  const intl = useIntl()
  const dispatch = useDispatch()

  return useCallback(
    async function loginFido2(onComplete) {
      if (!username || !processId) {
        dispatch(
          addNotification({
            message: intl.formatMessage({
              id: processId
                ? 'error.MissingUsername'
                : 'error.MissingProcessId',
            }),
            variant: 'error',
          }),
        )
        if (onComplete) {
          onComplete()
        }
        return
      }
      try {
        await login({ username, processId })
      } catch (e) {
        dispatch(
          addNotification({
            message: intl.formatMessage({
              id: 'notification.fido-login-error',
            }),
            variant: 'error',
          }),
        )
      } finally {
        if (onComplete) {
          onComplete()
        }
      }
    },
    [intl, dispatch, username],
  )
}
