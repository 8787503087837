import { RESET_PROCESS_STEP, SET_PROCESS_STEP } from 'common/actions/process'

const initialState = {}

const processReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_PROCESS_STEP:
      return action.step
    case RESET_PROCESS_STEP:
      return initialState
    default:
      return state
  }
}

export default processReducer
