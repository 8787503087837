import React from 'react'
import { Button } from 'common/components/button/Button'
import PasswordField from 'common/components/textfield/PasswordField'

const ConfirmPassword = ({
  displayMessage,
  inputs,
  errors,
  handleChange,
  handleSubmit,
}) => (
  <div className="flex flex-column">
    <h3 style={{ maxWidth: '500px' }}>{displayMessage}</h3>

    <PasswordField
      errorText={errors.password}
      value={inputs.password}
      onChange={handleChange('password')}
    />

    <div className="mt2 self-end">
      <Button onClick={handleSubmit}>Verify</Button>
    </div>
  </div>
)

export default ConfirmPassword
