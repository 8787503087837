import React from 'react'
import { injectIntl } from 'react-intl'
import { Button } from 'common/components/button/Button'
import Title from './Title'
import Page from 'common/components/Page'
import userIcon from 'common/assets/user-avatar.svg'
import styles from './styles.module.css'

const UserAvatar = ({ userName }) => (
  <div className={styles['user-avatar']}>
    <img src={userIcon} alt={userName} />
    <div style={{ marginTop: '9px' }}>{userName}</div>
  </div>
)

const MoreAvatars = ({ lengthOfMore, intl }) => (
  <div className={styles['user-avatar']}>
    <div className={styles['number-of-mores']}>{lengthOfMore}</div>
    <div style={{ marginTop: '9px' }}>
      {intl.formatMessage({ id: 'invitation.join.more-avatars' })}
    </div>
  </div>
)

const JoinTheGroup = ({
  onAccept,
  onDecline,
  members,
  lengthOfMore,
  groupName,
  intl,
}) => (
  <div className={styles['join-the-group-container']}>
    <Title center>
      <div style={{ lineHeight: '1.45' }}>
        <div>{intl.formatMessage({ id: 'invitation.join.title-1' })}</div>
        <div>{groupName}</div>
        <div>{intl.formatMessage({ id: 'invitation.join.title-2' })}</div>
      </div>
    </Title>
    <div className="mt4 center">
      <Page.Body>
        {intl.formatMessage({ id: 'invitation.join.message' })}
      </Page.Body>
    </div>
    <div className={styles['members-container']}>
      {members.map((m, i) => (
        <UserAvatar key={i} userName={m} />
      ))}
      {lengthOfMore > 0 && (
        <MoreAvatars lengthOfMore={lengthOfMore} intl={intl} />
      )}
    </div>
    <div className={styles['accept-decline-button-container']}>
      <div className="mr2">
        <Button onClick={onAccept}>
          {intl.formatMessage({ id: 'common.button.accept' })}
        </Button>
      </div>
      <Button variant="outlined" onClick={onDecline}>
        {intl.formatMessage({ id: 'common.button.decline' })}
      </Button>
    </div>
  </div>
)

export default injectIntl(JoinTheGroup)
