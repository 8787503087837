import React from 'react'
import { connect } from 'react-redux'
import { Switch, Route, Link, Redirect } from 'react-router-dom'

import ProfileView from './ProfileView'
import { profileDetailData } from './constants'
import { addNotification } from 'common/actions/notification'
import BasicLayout from 'common/layouts/BasicLayout'
import { hasUIFeatureFlag } from 'common/services/FeatureFlagsService'

const profileDetails = Object.keys(profileDetailData).map(key => ({
  label: profileDetailData[key].label,
  path: profileDetailData[key].path,
  view: profileDetailData[key].view,
  featureFlag: profileDetailData[key].featureFlag,
}))

const ProfilePage = ({ match, addNotification }) => {
  return (
    <Switch>
      {profileDetails.map(({ label, path, view, featureFlag }) => {
        const shouldRender = hasUIFeatureFlag(featureFlag)
        if (!shouldRender) return
        return (
          <Route
            key={path}
            path={`${match.path}${path}`}
            render={() => {
              if (view) {
                const DetailView = view
                return (
                  <BasicLayout>
                    <DetailView addNotification={addNotification} />
                  </BasicLayout>
                )
              }
              return (
                <div>
                  <Link to="/profile">(Back)</Link> {label}
                </div>
              )
            }}
          />
        )
      })}
      <Route exact path={match.path} component={ProfileView} />
      <Redirect from="*" to="/profile"></Redirect>
    </Switch>
  )
}

const mapDispatchToProps = { addNotification }

export default connect(null, mapDispatchToProps)(ProfilePage)
