import React, { useState, useCallback, useEffect } from 'react'
import { useIntl, FormattedMessage } from 'react-intl'
import { get, isEmpty } from 'lodash'

import * as UXPCard from 'common/components/Card'
import { Title, Body } from 'common/components/Typography'
import TextField from 'common/components/textfield/TextField'
import { TextPrimaryButton, Button } from 'common/components/button/Button'
import ResendCode from 'common/components/ResendCode'
import ErrorBox from 'common/components/ErrorBox'

import useMainRedirect from 'common/components/hooks/useMainRedirect'

import { labelFormatter } from 'protected/profile/constants'
import { resolveErrorEntity } from 'common/utils/processBackendErrors'
import {
  NOT_ELIGIBLE,
  NUMBER_IS_PORTABLE,
  NO_SUBSCRIPTION_EXISTS,
} from 'common/constants'

import { analyticsProvider } from 'App.js'
import { useSelector } from 'react-redux'
import { verifyCode } from 'common/processes/token'

const errorContext = 'cancelSubscribe.VerificationCode'
const VerificationCode = ({ wizard, flowType, wizardIndex }) => {
  const [code, setCode] = useState('')
  const [errors, setErrors] = useState({})
  const [progress, setProgress] = useState(false)
  const intl = useIntl()
  const mainRedirect = useMainRedirect(`/${flowType}`)

  const pageState = wizard.getPageState()

  const initialPkat = get(pageState, 'output.pkat')
  const [pkat, setPkat] = useState(initialPkat)

  const tokenId = get(pageState, 'output.tokenId')
  const { mobile } = pageState

  const pageTitle = 'verify_code'
  const baseEventTag = `${flowType}.verify_code`
  const codeFieldLabel = intl.formatMessage({ id: 'onboard.common.enter-code' })

  const appId = useSelector(state => state.configuration.config.id)

  useEffect(() => {
    analyticsProvider.sendAnalytics({
      type: 'page_view',
      page_title: pageTitle,
      page_path: `/${flowType}#${pageTitle}`,
    })
  }, [flowType])

  const onQuit = () => {
    analyticsProvider.sendAnalytics({
      type: 'event',
      action: 'click',
      event_category: 'button',
      event_label: `${baseEventTag}.quit`,
      value: 0,
    })

    const isStaticRedirect = mainRedirect()
    if (isStaticRedirect) {
      wizard.setPageState({})
      wizard.toPage(0)
    }
  }

  // //////////////////////////////////////////////////////////

  const onCancelSubscription = async () => {
    const nextErrors = {}

    if (!code) {
      const codeError = intl.formatMessage({
        id: 'error.verification-code-empty',
      })

      nextErrors.code = codeError

      analyticsProvider.sendAnalytics({
        type: 'event',
        action: 'exception',
        description: `${baseEventTag}.code: ${codeError}`,
      })
    }

    setErrors(nextErrors)
    if (!isEmpty(nextErrors)) {
      return
    }

    setProgress(true)

    try {
      const res = await verifyCode(code, pkat)
      const stepName = get(res, 'body.stepName')
      setProgress(false)

      analyticsProvider.sendAnalytics({
        type: 'event',
        action: 'click',
        event_category: 'button',
        event_label: `${baseEventTag}.cancel_subscription`,
        value: 0,
      })

      switch (stepName) {
        default: {
          const { metadata } = res
          const output = get(res, 'body.output', {})

          const productDetails = get(output, 'productDetails')

          wizard.setPageState({
            ...wizard.getPageState(),
            productDetails,
            metadata,
          })

          wizard.toPage(wizardIndex.cancelSuccess)
        }
      }
    } catch (err) {
      setProgress(false)

      analyticsProvider.sendAnalytics({
        type: 'event',
        action: 'click',
        event_category: 'button',
        event_label: `${baseEventTag}.cancel_subscription`,
        value: 1,
      })

      const operationError = get(err, 'body.operationError[0]', {})
      const { code: errorCode, message: portUrl } = operationError

      switch (errorCode) {
        case NOT_ELIGIBLE: {
          wizard.setPageState({
            ...wizard.getPageState(),
          })
          wizard.toPage(wizardIndex.notEligible)
          break
        }
        case NUMBER_IS_PORTABLE: {
          wizard.setPageState({
            ...wizard.getPageState(),
            portUrl,
          })
          wizard.toPage(wizardIndex.notEligible)
          break
        }
        case NO_SUBSCRIPTION_EXISTS: {
          wizard.toPage(wizardIndex.alreadyUnsubscribed)
          break
        }
        default: {
          const message = resolveErrorEntity({
            intl,
            error: err.body,
            context: errorContext,
          })

          analyticsProvider.sendAnalytics({
            type: 'event',
            action: 'exception',
            description: `${baseEventTag}.screen: ${message}`,
          })

          setErrors({
            ...errors,
            code: message,
          })
        }
      }
    }
  }

  // //////////////////////////////////////////////////////////

  const handleResendError = useCallback(
    resendErrorMessage => {
      analyticsProvider.sendAnalytics({
        type: 'event',
        action: 'click',
        event_category: 'button',
        event_label: `${baseEventTag}.resend_code`,
        value: 1,
      })

      analyticsProvider.sendAnalytics({
        error: resendErrorMessage,
      })

      setErrors(errors => ({
        ...errors,
        common: resendErrorMessage,
      }))
    },
    [baseEventTag],
  )

  // //////////////////////////////////////////////////////////

  const onResetError = useCallback(() => {
    setErrors({})
  }, [])

  const onResendFinish = newPkat => {
    analyticsProvider.sendAnalytics({
      type: 'event',
      action: 'click',
      event_category: 'button',
      event_label: `${baseEventTag}.resend_code`,
      value: 0,
    })

    setPkat(newPkat)
  }

  // //////////////////////////////////////////////////////////

  return (
    <React.Fragment>
      <UXPCard.Card>
        <UXPCard.Content>
          <Title>
            {intl.formatMessage({ id: 'onboard.common.enter-your-code' })}
          </Title>
          {errors.common && <ErrorBox>{errors.common}</ErrorBox>}
          <Body mb={4}>
            <FormattedMessage
              id="onboard.verification-code.sms-was-sent"
              values={{
                number: <b>{labelFormatter.mobile(mobile, appId)}</b>,
              }}
            />
          </Body>
          <TextField
            label={codeFieldLabel}
            onChange={e => setCode(e.target.value)}
            value={code}
            errorText={errors.code}
            autoComplete="one-time-code"
            numeric
          />
          <ResendCode
            pkat={pkat}
            tokenId={tokenId}
            onResendError={handleResendError}
            onResendStart={onResetError}
            onResendFinish={onResendFinish}
          />
        </UXPCard.Content>
        <UXPCard.Divider />
        <UXPCard.Actions>
          <TextPrimaryButton onClick={onQuit}>
            {intl.formatMessage({ id: 'onboard.common.quit' })}
          </TextPrimaryButton>
          <Button
            color="primary"
            isLoading={progress}
            onClick={onCancelSubscription}
          >
            {intl.formatMessage({
              id: 'onboard-cancel.verify-code.cancel-subscription',
            })}
          </Button>
        </UXPCard.Actions>
      </UXPCard.Card>
    </React.Fragment>
  )
}

export default VerificationCode
