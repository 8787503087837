import React, { useEffect } from 'react'
import { useIntl } from 'react-intl'

import * as UXPCard from 'common/components/Card'
import { Title, Body } from 'common/components/Typography'
import { Button } from 'common/components/button/Button'

import useMainRedirect from 'common/components/hooks/useMainRedirect'

import { analyticsProvider } from 'App.js'
import BasicLayout from 'common/layouts/BasicLayout'

const NotFound = ({ flowType, wizard }) => {
  const intl = useIntl()
  const mainRedirect = useMainRedirect(`/${flowType}`)

  const pageTitle = 'not_found'
  const baseEventTag = `${flowType}.not_found`

  useEffect(() => {
    analyticsProvider.sendAnalytics({
      type: 'page_view',
      page_title: pageTitle,
      page_path: `/${flowType}#${pageTitle}`,
    })
  }, [flowType])

  const onOkay = () => {
    analyticsProvider.sendAnalytics({
      type: 'event',
      action: 'click',
      event_category: 'button',
      event_label: `${baseEventTag}.okay`,
      value: 0,
    })

    const isStaticRedirect = mainRedirect()
    if (isStaticRedirect) {
      if (wizard) {
        wizard.setPageState({})
        wizard.toPage(0)
      }
    }
  }

  return (
    <BasicLayout>
      <UXPCard.Card>
        <UXPCard.Content>
          <Title>
            {intl.formatMessage({
              id: 'onboard.not-found.title',
            })}
          </Title>
          <Body>
            {intl.formatMessage({
              id: 'onboard.not-found.page-does-not-exist',
            })}
          </Body>
        </UXPCard.Content>
        <UXPCard.Divider />
        <UXPCard.Actions>
          <Button onClick={onOkay}>
            {intl.formatMessage({ id: 'onboard.common.okay' })}
          </Button>
        </UXPCard.Actions>
      </UXPCard.Card>
    </BasicLayout>
  )
}

export default NotFound
