import { get, takeRight } from 'lodash'

import getAttribute from 'common/utils/getAttribute'
import ACCOUNT from 'common/constants/account'

export const getIsAccountsInProgress = state =>
  get(state, 'accounts.accounts.inProgress')

export const getAccounts = state => get(state, 'accounts.accounts.data', [])

export const getAccountById = (state, accountId) => {
  const accounts = getAccounts(state)

  return accounts.find(({ id }) => id === accountId)
}

export const getAccountUsersData = (state, accountId) =>
  get(state, ['accounts', 'usersByAccountId', accountId], {})

export const getAccountUsers = (state, accountId) => {
  const usersData = getAccountUsersData(state, accountId)
  return get(usersData, 'data', [])
}

export const getAccountUsersInProgress = (state, accountId) => {
  const userData = getAccountUsersData(state, accountId)
  return get(userData, 'inProgress')
}

export const getMaxUsersCount = account => getAttribute(account, 'maxUsers')

export const getAccountTotalUsers = (state, accountId) => {
  const userAccount = getAccountUsersData(state, accountId)
  return get(userAccount, 'totalUsers') || 1
}

export const getIsAccountSharable = (state, account = {}) => {
  const maxUsers = getMaxUsersCount(account)
  const totalUsers = getAccountTotalUsers(state, account.id)

  return maxUsers > totalUsers
}

export const getAccountRemainingShare = (state, account = {}) => {
  const totalUsers = getAccountTotalUsers(state, account.id)
  const maxUsers = getMaxUsersCount(account)

  return maxUsers - totalUsers
}

export const getAccountSubscriptions = (state, accountId) => {
  const account = getAccountById(state, accountId)

  return get(account, 'subscriptions', [])
}

export const getAccountNumber = account =>
  getAttribute(account, ACCOUNT.attribute.number)

export const getAccountNameLabel = (account = {}) => {
  const accountNumber = getAccountNumber(account)
  return account.displayName || accountNumber
}

export const getAccountDisplay = account => {
  if (account) {
    const accountNumber =
      account.obfuscatedAccountNumber || getAccountNumber(account)
    const hasNickName = account.displayName !== accountNumber
    const maskedNumber = ` (••••${takeRight(accountNumber, 4).join('')})`
    const accountDisplayName = hasNickName
      ? account.displayName
      : `Acct# ${accountNumber}`
    const accountDisplaySuffix = hasNickName ? maskedNumber : ''

    return `${accountDisplayName}${accountDisplaySuffix}`
  }
  return null
}

export const getAccountNameLabelById = (state, id) => {
  const account = getAccountById(state, id) || {}
  const accountNumber = getAccountNumber(account)
  return account.displayName || accountNumber
}
