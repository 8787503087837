import React from 'react'
import { get } from 'lodash'

import Box from '@mui/material/Box'

import FeatureFlagsService from 'common/services/FeatureFlagsService'
import isIOS from 'common/utils/isIOS'
import isAndroid from 'common/utils/isAndroid'
import isWebView from 'common/utils/isWebView'
import browserDetails from 'common/utils/getBrowserDetails'

import { STORE_LOGOS } from 'common/constants'
import { analyticsProvider } from 'App.js'
import { useSelector } from 'react-redux'
import { useLocale } from '@admin-ui-common/utils'
import { getAvailableLocales } from 'common/constants/locale'

const { isDesktop } = browserDetails
export const showDownloadLinks = !isWebView && (isDesktop || isIOS || isAndroid)

const styles = {
  apple: {
    margin: '12px 0',
    height: 38,
  },
  android: {
    height: 57,
    marginTop: 2,
  },
}
const StoreIcon = ({ logo, link, alt, type, onClick }) =>
  link ? (
    <Box onClick={onClick}>
      <Box component="img" src={logo} alt={alt} style={styles[type]} />
    </Box>
  ) : null

// The download links should be shown:
// Webview (iOS/Android) - no download link
// Desktop - both
// iOS mobile browser - Apple Play store link
// Android mobile browser - Google Play link

const AppLinks = ({ appleDownloadLink, androidDownloadLink, baseEventTag }) => {
  const user = useSelector(state => state.user)

  const detectLocale = useSelector(
    state => state.configuration.config.detectLocale,
  )
  const defaultLocale = useSelector(
    state => state.configuration.config.defaultLocale,
  )

  const hasSession = useSelector(state => state.session.hasSession)

  const localeFromStore = useSelector(state => state.locale)

  const locale = useLocale(
    user,
    localeFromStore,
    hasSession,
    FeatureFlagsService.get('system.defaultLocale'),
    detectLocale,
    defaultLocale,
    getAvailableLocales(),
  )

  if (!showDownloadLinks) {
    return null
  }

  const showAppleLink = isDesktop || isIOS
  const showAndroidLink = isDesktop || isAndroid

  const appleStoreLogo = get(STORE_LOGOS, [locale, 'apple'])
  const androidStoreLogo = get(STORE_LOGOS, [locale, 'google'])

  const sendAnalytics = (type, link) => () => {
    analyticsProvider.sendAnalytics({
      type: 'event',
      action: 'click',
      event_category: 'button',
      event_label: `${baseEventTag}.${type}`,
      value: 0,
      callback() {
        setTimeout(() => {
          window.location.href = link
        }, 500)
      },
    })
  }

  return (
    <Box
      mt={3}
      display="flex"
      flexDirection="row"
      alignItems="start"
      justifyContent="space-between"
      width="280px"
    >
      {showAppleLink && (
        <StoreIcon
          logo={appleStoreLogo}
          link={appleDownloadLink}
          alt="apple store"
          type="apple"
          onClick={sendAnalytics('app_store', appleDownloadLink)}
        />
      )}
      {showAndroidLink && (
        <StoreIcon
          logo={androidStoreLogo}
          link={androidDownloadLink}
          alt="android store"
          type="android"
          onClick={sendAnalytics('google_play', androidDownloadLink)}
        />
      )}
    </Box>
  )
}

export default AppLinks
