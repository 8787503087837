import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { get, omit, set } from 'lodash'
import { injectIntl } from 'react-intl'

import Page from 'common/components/Page'
import { Button } from 'common/components/button/Button'

import Radio from '@mui/material/Radio'
import RadioGroup from '@mui/material/RadioGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import FormControl from '@mui/material/FormControl'

import process from 'common/utils/process'
import { setProcessStep } from 'common/actions/process'
import { analyticsProvider } from '../../App'
import FlowLayout from '../../common/layouts/FlowLayout'
import { Box } from '@mui/material'

class Verification extends React.Component {
  static propTypes = {
    recoverProcess: PropTypes.object,
  }

  state = {
    verificationMethodId: '0',
    isLoading: false,
  }

  constructor(props) {
    super(props)

    const returnData = get(props.recoverProcess, 'output.recoveryOptions')
    this.recoveryOptions = Object.keys(returnData).map(id => ({
      id,
      value: returnData[id],
      type: returnData[id].includes('@') ? 'email' : 'phone',
    }))

    this.pageTitle = 'verification'
    this.baseEventTag = `forgot.${this.pageTitle}`
  }

  componentDidMount() {
    analyticsProvider.sendAnalytics({
      type: 'page_view',
      page_title: this.pageTitle,
      page_path: `/forgot#${this.pageTitle}`,
    })
  }

  onChangeVerificationMethod = (e, verificationMethodId) => {
    this.setState({ verificationMethodId })
  }

  onCancel = () => {
    analyticsProvider.sendAnalytics({
      type: 'event',
      action: 'click',
      event_category: 'button',
      event_label: `${this.baseEventTag}.cancel`,
      value: 0,
    })

    const isStaticRedirect = this.props.mainRedirect()
    if (isStaticRedirect) {
      this.props.wizard.setPageState({})
      this.props.wizard.toPage(0)
    }
  }

  submitForm = event => {
    event.preventDefault()

    analyticsProvider.sendAnalytics({
      type: 'event',
      action: 'click',
      event_category: 'button',
      event_label: `${this.baseEventTag}.next`,
      value: 0,
    })

    const { recoverProcess, wizard } = this.props
    const nextProcess = omit(recoverProcess, [
      'displayMessage',
      'stepName',
      'output',
      'returnParameters',
    ])
    const confirmationPayload = set(
      nextProcess,
      'parameters.recoveryOptionId',
      this.state.verificationMethodId,
    )

    this.setState({ isLoading: true })

    return process
      .step(confirmationPayload)
      .then(res => {
        const option = this.recoveryOptions.find(
          r => r.id === this.state.verificationMethodId,
        )
        const pkat = get(res, 'body.output.pkat')
        const tokenId = get(res, 'body.output.tokenId')
        wizard.setPageState({
          ...wizard.getPageState(),
          pkat,
          tokenId,
          usernameType: option.type,
          username: option.value,
        })
        wizard.toPage(2)
      })
      .catch(() => {
        analyticsProvider.sendAnalytics({
          type: 'event',
          action: 'click',
          event_category: 'button',
          event_label: `${this.baseEventTag}.send`,
          value: 1,
        })
      })
  }

  render() {
    const { verificationMethodId, isLoading } = this.state
    const { intl } = this.props

    const strings = {
      title: intl.formatMessage({
        id: 'forgot-password.verify-identity.title',
      }),
      message1: intl.formatHTMLMessage({
        id: 'forgot-password.verify-identity.message-1',
      }),
      message2: intl.formatHTMLMessage({
        id: 'forgot-password.verify-identity.message-2',
      }),
      prefixEmail: intl.formatMessage({ id: 'forgot-password.prefix.email' }),
      prefixPhone: intl.formatMessage({ id: 'forgot-password.prefix.phone' }),
    }

    return (
      <FlowLayout title={strings.title}>
        <form>
          <Page.Body>
            {strings.message1}
            <p>{strings.message2}</p>
          </Page.Body>

          <FormControl component="fieldset">
            <RadioGroup
              value={verificationMethodId}
              onChange={this.onChangeVerificationMethod}
            >
              {this.recoveryOptions.map(({ id, value }) => {
                // Hack to detect email/phone
                const prefix = value.includes('@')
                  ? strings.prefixEmail
                  : strings.prefixPhone
                return (
                  <FormControlLabel
                    key={id}
                    value={id}
                    control={<Radio color="primary" />}
                    label={`${prefix} ${value}`}
                  />
                )
              })}
            </RadioGroup>
          </FormControl>

          <Box display="flex" paddingTop={4}>
            <Box marginRight={3}>
              <Button
                size="large"
                onClick={this.submitForm}
                isLoading={isLoading}
              >
                {intl.formatMessage({ id: 'common.button.next' })}
              </Button>
            </Box>
            <Button size="large" variant="outlined" onClick={this.onCancel}>
              {intl.formatMessage({ id: 'common.button.cancel' })}
            </Button>
          </Box>
        </form>
      </FlowLayout>
    )
  }
}

function PageWrapper(props) {
  return <Verification {...props} />
}

const mapStateToProps = state => ({
  recoverProcess: state.process,
})
const mapDispatchToProps = { setProcessStep }

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(injectIntl(PageWrapper))
