import { isEmpty } from 'lodash'
import DetailView from '../../common/DetailView'
import { isIdentifierDeleteable } from '../../../common/utils/identifier-utils'
import React from 'react'
import formatMobile from '../../../common/utils/formatMobile'
import getCountryCode from 'common/utils/getCountryCode'
import { displayIdentifier } from 'common/utils/formatNumber'

export const processIdentifiers = identifiers => {
  const identifierIds = identifiers.map(identifier => identifier['id'])

  let processedIdentifiers
  processedIdentifiers = identifiers.filter(identifier => {
    const originalId = identifier['originalId']
    if (!identifierIds.includes(originalId)) return true
    return !Boolean(originalId)
  })

  const replacementIdentifiers = identifiers.filter(identifier =>
    Boolean(identifier['originalId']),
  )

  processedIdentifiers = processedIdentifiers.map(identifier => {
    const replacementIdentifier = replacementIdentifiers.filter(
      replacementIdentifier =>
        replacementIdentifier['originalId'] === identifier.id,
    )
    if (!isEmpty(replacementIdentifier))
      return { ...identifier, replacementIdentifier: replacementIdentifier[0] }
    return identifier
  })

  return processedIdentifiers
}

const IdentifierItem = props => {
  const {
    type,
    item,
    stateData,
    emptyMessage,
    pendingMessage,
    onChangeDialogOpen,
    deleteDialogOpen,
    addDialogOpen,
    onVerifyDialogOpen,
    setState,
    appId,
  } = props

  const itemIdentifier = type === 'email' ? item.email : item.number

  const formattedTitle =
    type === 'mobile'
      ? displayIdentifier(itemIdentifier, type, appId)
      : itemIdentifier

  return (
    <>
      <DetailView.Title
        canDelete={isIdentifierDeleteable(itemIdentifier, stateData)}
        title={formattedTitle}
        typography="body1"
        iconSize={24}
        emptyMessage={emptyMessage}
        onEditClick={() => {
          setState({
            selectedIdentifier: item,
            mode: 'update',
          })
          onChangeDialogOpen()
        }}
        onDeleteClick={() => {
          setState({
            selectedIdentifier: item,
            mode: 'delete',
          })
          deleteDialogOpen()
        }}
        onAddClick={addDialogOpen}
      />
      {item.status !== 'activated' && (
        <DetailView.Verification
          pendingOrActivatingEntity={item}
          pendingMessage={pendingMessage}
          onVerifyOpen={() => {
            setState({
              selectedIdentifier: item,
              pendingOrActivatingEntity: item,
              mode: 'update',
            })
            onVerifyDialogOpen()
          }}
        />
      )}
    </>
  )
}

export default IdentifierItem
