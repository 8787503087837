import React, { useState } from 'react'
import { useIntl } from 'react-intl'
import { useHistory } from 'react-router-dom'
import { get } from 'lodash'
import FlowLayout from 'common/layouts/FlowLayout'
import AuthenticatorCode from './TwoStepCode/AuthenticatorCode'
import { Box } from '@mui/material'
import {
  getErrorCodes,
  resolveErrorEntity,
  formatErrorMessage,
} from 'common/utils/processBackendErrors'
import { TOO_MANY_RETRIES } from 'common/constants'
import useMainRedirect from 'common/components/hooks/useMainRedirect'
import { getRedirectLocation } from 'common/utils/getRedirectUrl'

export default function TotpVerificationStep({
  send2FACode,
  wizard,
  getPageIndex,
  pages,
  steps,
}) {
  const intl = useIntl()
  const history = useHistory()
  const { location } = history
  const wizardState = wizard.getPageState()
  const mainRedirect = useMainRedirect()

  const [errorMessage, setErrorMessage] = useState(null)
  const [disabledNext, setDisabledNext] = useState(false)

  function cancel() {
    window.location.reload()
  }

  async function submitCode({ code, pkat, trustedDevice }) {
    setErrorMessage(null)

    const processId =
      get(wizardState, 'twoStepOtpData.processId') ||
      get(wizardState, 'processId')

    try {
      const res = await send2FACode(
        { processId },
        { code, pkat, trustedDevice },
      )

      if (get(res, 'body.stepName') === steps.AccountIdentifierPrompt) {
        wizard.setPageState(get(res, 'body'))
        wizard.toPage(getPageIndex(pages.EnforceAccountAssociationStep))
      }

      if (get(res, 'body.stepName') === steps.UpdatePassword) {
        history.push({
          pathname: '/password_expire',
          state: get(res, 'body'),
          search: location.search,
        })
      }

      if (get(res, 'body.userAuthenticated') === true) {
        if (Boolean(getRedirectLocation(res.metadata))) {
          mainRedirect(res.metadata)
          return
        }
        wizard.toPage(getPageIndex(pages.LoggedIn))
      }
    } catch (err) {
      const errorCodes = getErrorCodes(err.body)

      if (errorCodes.includes('invalid-act-consumption')) {
        setErrorMessage(formatErrorMessage({ intl, id: 'error.invalid-code' }))
      } else if (errorCodes.includes(TOO_MANY_RETRIES)) {
        setDisabledNext(true)
        setErrorMessage(
          formatErrorMessage({
            intl,
            id: 'error.process-terminated-with-too-many-retries',
          }),
        )
      } else {
        setErrorMessage(resolveErrorEntity({ intl, error: err.body }))
      }
    }
  }

  return (
    <FlowLayout
      title={intl.formatMessage({ id: 'sign-in.two-factor-code-title' })}
      subtitle={intl.formatMessage({
        id: 'sign-in.two-factor-code-subtitle',
      })}
    >
      <Box>
        <AuthenticatorCode
          submitCode={submitCode}
          cancel={cancel}
          error={errorMessage ?? undefined}
          disabledNext={disabledNext}
        />
      </Box>
    </FlowLayout>
  )
}
