import * as actionTypes from 'common/actions/settings'

const getSettingsState = () => ({
  publicSettings: {},
  tenantID: null,
  error: null,
  inProgress: false,
})

const settings = (state = getSettingsState(), action) => {
  switch (action.type) {
    case actionTypes.GET_PUBLIC_SETTINGS_REQUEST:
      return {
        ...state,
        error: null,
        inProgress: true,
      }
    case actionTypes.GET_PUBLIC_SETTINGS_SUCCESS: {
      const { publicSettings = {}, tenantID } = action
      return {
        ...state,
        publicSettings,
        tenantID,
        error: null,
        inProgress: false,
      }
    }
    case actionTypes.GET_PUBLIC_SETTINGS_FAILURE:
      return {
        ...state,
        error: action.error,
        inProgress: false,
      }
    default:
      return state
  }
}

export default settings
