import {
  getRedirectLocation,
  performRedirect,
} from 'common/utils/getRedirectUrl'
import { isString } from 'lodash'

/**
 * TODO: This is not actually a hook. It doesn't
 * do anything with react. We should move it out
 * of the hooks folder.
 **/
const useMainRedirect = fallback => metadata => {
  const isMetadataString = isString(metadata)
  const location = isMetadataString
    ? metadata
    : getRedirectLocation(metadata, fallback)
  const emptyRedirectLocation = location === fallback

  if (emptyRedirectLocation) {
    // This is an indicator that there is no any external location to redirect to
    // and we should go to first view in current wizard
    return true
  }

  performRedirect(location)
}

export default useMainRedirect
