import React from 'react'
import { injectIntl } from 'react-intl'
import { Button } from 'common/components/button/Button'
import TextField from 'common/components/textfield/TextField'
import PasswordField from 'common/components/textfield/PasswordField'
import Page from 'common/components/Page'
import Title from './Title'

import styles from './styles.module.css'

const ConfirmDetails = ({
  onCreate,
  firstName,
  name,
  email,
  password,
  errors,
  onInputChange,
  intl,
}) => (
  <div className={styles['create-user-container']}>
    <Title>{intl.formatMessage({ id: 'invitation.confirm.title' })}</Title>
    <div style={{ marginTop: '47px' }}>
      <Page.Body>
        <div>
          {intl.formatMessage(
            { id: 'invitation.confirm.message-with-name' },
            { firstName },
          )}
        </div>
        <div>{intl.formatMessage({ id: 'invitation.confirm.message' })}</div>
      </Page.Body>
    </div>
    <TextField
      required
      name="name"
      errorText={errors.name}
      label={intl.formatMessage({ id: 'common.label.name' })}
      onChange={onInputChange}
      value={name}
    />
    <div className="mb3" />
    <TextField
      inputType="email"
      disabled
      errorText={errors.email}
      label={intl.formatMessage({ id: 'common.label.email' })}
      onChange={onInputChange}
      value={email}
    />
    <div className="mb3" />
    <PasswordField
      name="password"
      errorText={errors.password}
      label={intl.formatMessage({ id: 'sign-up.label.password' })}
      onChange={onInputChange}
      value={password}
    />
    <div style={{ marginTop: '72px' }}>
      <Button onClick={onCreate}>
        {intl.formatMessage({ id: 'common.button.create' })}
      </Button>
    </div>
  </div>
)

export default injectIntl(ConfirmDetails)
