import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { get, identity } from 'lodash'
import { useIntl } from 'react-intl'

import { resolveErrorEntity } from 'common/utils/processBackendErrors'
import { addNotification } from 'common/actions/notification'
import Box from '@mui/material/Box'

import * as UXPDialog from 'common/components/dialog/UXPDialog'
import TextField from 'common/components/textfield/TextField'
import { TextSecondaryButton, Button } from 'common/components/button/Button'
import { loadUser } from 'common/actions/user'

import {
  registerMFAVerificationStep,
  clearEmailPhone,
} from 'common/actions/twoStepVerification'
import { analyticsProvider } from '../../../../App'
import { isEmpty } from 'lodash'
import { Body2 } from '../../../../common/components/Typography'
import {
  TOTP_VERIFICATION_PROMPT,
  QR_CODE_PROMPT,
} from '../../../../common/constants/twoStepVerification'

const thisPromptStepName = TOTP_VERIFICATION_PROMPT
const errorContext = 'register-mfa-app'

const TotpVerificationDialog = ({ onClose, open, flowType, nextCallBack }) => {
  const dispatch = useDispatch()
  const totpState = get(
    useSelector(state => state.registerMFA.totpApp),
    'TotpVerificationPrompt',
  )
  const [process, setProcess] = useState('')
  const [code, setCode] = useState('')
  const [error, setError] = useState({})
  const [inProgress, setInProgress] = useState(false)
  const intl = useIntl()

  const pageTitle = 'totp_verification_dialog'
  const baseEventTag = `${flowType}.${pageTitle}`

  useEffect(() => {
    if (!isEmpty(totpState)) {
      const processId = get(totpState, 'processId')
      setProcess(processId)
    }
  }, [totpState])

  const onNext = () => {
    analyticsProvider.sendAnalytics({
      type: 'event',
      action: 'click',
      event_category: 'button',
      event_label: `${baseEventTag}.verify_otp`,
      value: 0,
    })
    if (!code) {
      setError({
        message: intl.formatMessage({
          id: 'onboard.common.enter-your-code',
        }),
      })
      return
    }

    setInProgress(true)
    const payload = {
      processId: process,
      parameters: {
        code: code,
      },
    }

    registerMFAVerificationStep(payload)(dispatch)
      .then(() => {
        analyticsProvider.sendAnalytics({
          type: 'event',
          action: 'click',
          event_category: 'button',
          event_label: `${baseEventTag}.otp_verified`,
          value: 0,
        })
        dispatch(
          addNotification({
            message: intl.formatMessage({
              id: 'user.management.register.totpVerification-success-message',
            }),
          }),
        )
        onClose(thisPromptStepName)
        setCode('')
        dispatch(loadUser())
        dispatch(clearEmailPhone())
      })
      .catch(error => {
        analyticsProvider.sendAnalytics({
          type: 'event',
          action: 'click',
          event_category: 'button',
          event_label: `${baseEventTag}.otp_verified`,
          value: 1,
        })
        setError({
          message: resolveErrorEntity({
            intl,
            error: error.body,
            context: errorContext,
          }),
        })
      })
  }

  const closeView = () => {
    analyticsProvider.sendAnalytics({
      type: 'event',
      action: 'click',
      event_category: 'button',
      event_label: `${baseEventTag}.close`,
      value: 0,
    })
    setCode('')
    onClose(TOTP_VERIFICATION_PROMPT)
  }

  const back = () => {
    analyticsProvider.sendAnalytics({
      type: 'event',
      action: 'click',
      event_category: 'button',
      event_label: `${baseEventTag}.back`,
      value: 0,
    })
    setCode('')
    onClose(TOTP_VERIFICATION_PROMPT)
    nextCallBack(QR_CODE_PROMPT)
  }
  return (
    <UXPDialog.Dialog open={open} fullScreen fullWidth maxWidth="sm">
      <UXPDialog.Title
        title={intl.formatMessage({
          id: 'user.management.register.totpVerificationDialog.title',
        })}
        onClose={closeView}
      />
      <UXPDialog.Content>
        <Box mb={3}>
          <Body2>
            {intl.formatMessage({
              id: 'user.management.register.totpVerificationDialog.message',
            })}
          </Body2>
        </Box>
        <TextField
          label={intl.formatMessage({ id: 'common.enter-code' })}
          onChange={e => setCode(e.target.value)}
          value={code}
          errorText={get(error, 'message')}
          autoComplete="one-time-code"
          numeric
        />
      </UXPDialog.Content>
      <UXPDialog.Actions disabled={inProgress}>
        <TextSecondaryButton onClick={back} size="small" variant="outlined">
          {intl.formatMessage({ id: 'common.button.back' })}
        </TextSecondaryButton>
        <Button onClick={onNext} disabled={!identity} size="small">
          {intl.formatMessage({ id: 'common.button.verify' })}
        </Button>
      </UXPDialog.Actions>
    </UXPDialog.Dialog>
  )
}

export default TotpVerificationDialog
