import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { injectIntl } from 'react-intl'

import * as UXPDialog from 'common/components/dialog/UXPDialog'
import DetailView from 'protected/common/DetailView'
import TextField from 'common/components/textfield/TextField'
import {
  TextPrimaryFillButton,
  TextSecondaryButton,
} from 'common/components/button/Button'

import {
  getFullName,
  parseFullName,
} from 'common/components/nameField/NameFields'
import { updateAttribute } from 'common/actions/user'
import { resolveErrorEntity } from 'common/utils/processBackendErrors'
import { analyticsProvider } from '../../../App'

class FullNameDetailView extends React.PureComponent {
  static propTypes = {
    updateAttribute: PropTypes.func,
    fullName: PropTypes.object,
    addNotification: PropTypes.func,
  }

  constructor(props) {
    super(props)

    this.flowType = 'detailView'
    this.pageTitle = 'name_detail_view'
    this.baseEventTag = `${this.flowType}.${this.pageTitle}`
  }

  state = {
    fullName: this.props.fullName,
    firstName: this.props.fullName.firstName,
    lastName: this.props.fullName.lastName,
    inProgress: false,
    firstNameError: '',
    lastNameError: '',
    asyncError: '',
    changeDialogOpen: false,
    firstNameCanBeEmpty: true,
    lastNameCanBeEmpty: true,
  }

  onChangeDialogOpen = () => {
    analyticsProvider.sendAnalytics({
      type: 'event',
      action: 'click',
      event_category: 'button',
      event_label: `${this.baseEventTag}.change-dialog-open`,
      value: 0,
    })
    this.setState({ changeDialogOpen: true })
  }

  onChangeDialogClose = () => {
    analyticsProvider.sendAnalytics({
      type: 'event',
      action: 'click',
      event_category: 'button',
      event_label: `${this.baseEventTag}.change-dialog-close`,
      value: 0,
    })
    this.setState({
      changeDialogOpen: false,
      fullName: this.props.fullName,
    })
  }

  onFirstNameChange = e => this.setState({ firstName: e.target.value })

  onLastNameChange = e => this.setState({ lastName: e.target.value })

  getError = error => {
    const { asyncError } = this.state

    if (asyncError) {
      return asyncError
    }

    if (error) {
      return this.props.intl.formatMessage({ id: error })
    }

    return ''
  }

  showNotification = () => {
    const { addNotification, intl } = this.props
    const entity = intl.formatMessage({ id: 'notification.name' })

    addNotification({
      message: intl.formatMessage(
        {
          id: 'notification.updated-successfully',
        },
        { entity },
      ),
      variant: 'success',
    })
  }

  handleNameChange = () => {
    const { intl } = this.props
    analyticsProvider.sendAnalytics({
      type: 'event',
      action: 'click',
      event_category: 'button',
      event_label: `${this.baseEventTag}.change-name`,
      value: 0,
    })

    const {
      firstName,
      lastName,
      firstNameCanBeEmpty,
      lastNameCanBeEmpty,
    } = this.state

    const firstNameHasError = !firstName && !firstNameCanBeEmpty

    const lastNameHasError = !lastName && !lastNameCanBeEmpty

    if (firstNameHasError || lastNameHasError) {
      let firstNameError = ''
      let lastNameError = ''
      if (firstNameHasError) {
        firstNameError = 'profile.detail.firstName.cannot-be-empty'
      }

      if (lastNameHasError) {
        lastNameError = 'profile.detail.lastName.cannot-be-empty'
      }

      this.setState({
        firstNameError: firstNameError,
        lastNameError: lastNameError,
      })

      return
    }

    this.setState({
      firstNameError: '',
      lastNameError: '',
      asyncError: '',
      inProgress: true,
    })

    this.props
      .updateAttribute({
        attributes: {
          familyName: lastName,
          givenName: firstName,
        },
      })
      .then(() => {
        this.setState({ inProgress: false })
        this.onChangeDialogClose()
        this.showNotification()
      })
      .catch(err => {
        analyticsProvider.sendAnalytics({
          type: 'event',
          action: 'click',
          event_category: 'button',
          event_label: `${this.baseEventTag}.change-name`,
          value: 1,
        })

        const message = resolveErrorEntity({ intl, error: err.body })
        this.setState({
          inProgress: false,
          asyncError: message,
        })
      })
  }

  render() {
    const { fullName, intl } = this.props
    const {
      firstName,
      lastName,
      firstNameError,
      lastNameError,
      changeDialogOpen,
      inProgress,
    } = this.state
    const firstNameErrorText = this.getError(firstNameError)
    const lastNameErrorText = this.getError(lastNameError)

    if (fullName.firstName) this.setState({ firstNameCanBeEmpty: false })

    if (fullName.lastName) this.setState({ lastNameCanBeEmpty: false })

    return (
      <DetailView
        title={intl.formatMessage({ id: 'profile.detail.fullName.title' })}
        description={intl.formatMessage({
          id: 'profile.detail.name.description',
        })}
      >
        <DetailView.Title
          title={parseFullName(fullName)}
          onEditClick={this.onChangeDialogOpen}
        />

        <UXPDialog.Dialog
          open={changeDialogOpen}
          fullScreen
          fullWidth
          maxWidth="xs"
        >
          <UXPDialog.Title
            onClose={this.onChangeDialogClose}
            title={intl.formatMessage({
              id: 'profile.detail.name.update-full-name',
            })}
          />
          <UXPDialog.Content className="mt2">
            <TextField
              errorText={firstNameErrorText}
              label={intl.formatMessage({
                id: 'profile.detail.firstName.title',
              })}
              onChange={this.onFirstNameChange}
              value={firstName}
            />
            <div className="mt1" />
            <TextField
              errorText={lastNameErrorText}
              label={intl.formatMessage({
                id: 'profile.detail.lastName.title',
              })}
              onChange={this.onLastNameChange}
              value={lastName}
            />
          </UXPDialog.Content>
          <UXPDialog.Actions>
            <TextSecondaryButton onClick={this.onChangeDialogClose}>
              Cancel
            </TextSecondaryButton>
            <TextPrimaryFillButton
              onClick={this.handleNameChange}
              disabled={inProgress}
            >
              Save
            </TextPrimaryFillButton>
          </UXPDialog.Actions>
        </UXPDialog.Dialog>
      </DetailView>
    )
  }
}

const mapStateToProps = state => ({
  fullName: getFullName(state),
})

const mapDispatchToProps = { updateAttribute }

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(injectIntl(FullNameDetailView))
