import googleLogo from 'common/assets/google-logo-white.svg'
import facebookLogo from 'common/assets/facebook-logo-white.svg'
import azureLogo from 'common/assets/microsoft-logo-white.svg'
import appleLogo from 'common/assets/apple-logo-white.svg'
import linkedinLogo from 'common/assets/linked-in-logo-white.svg'
import transparentLogo from 'common/assets/transparent-icon.svg'

const getSocialProviderLogo = providerId => {
  switch (providerId) {
    case 'google':
      return googleLogo
    case 'facebook':
      return facebookLogo
    case 'azure':
    case 'microsoft':
      return azureLogo
    case 'apple':
      return appleLogo
    case 'linkedin':
      return linkedinLogo
    default:
      return transparentLogo
  }
}

export default getSocialProviderLogo
