import { hasUIFeatureFlag } from 'common/services/FeatureFlagsService'
import isObject from 'lodash/isObject'
import get from 'lodash/get'
const useLogoProvider = () => {
  let logoImage
  const logoUrlFeatureFlag = hasUIFeatureFlag('logoImage')
  const themeType = hasUIFeatureFlag('theme.type')

  logoImage = logoUrlFeatureFlag
  if (logoUrlFeatureFlag && isObject(logoUrlFeatureFlag)) {
    logoImage = get(logoUrlFeatureFlag, themeType, '')
  }

  return { logoImage }
}

export default useLogoProvider
