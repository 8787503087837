import React from 'react'
import { useTheme } from '@mui/material/styles'
import { useIntl } from 'react-intl'
import { UserTextLink } from '@admin-ui-common/base-user'
import { Link as RouterLink } from 'react-router-dom'

import { BackendErrorNotification } from 'common/components/backendErrorNotification/BackendErrorNotification'
import {
  ACTION_TOKEN_RETRIES_EXCEEDED,
  ACTION_TOKEN_EXPIRED,
  INVALID_PKAT,
  TOKEN_MAX_RESEND_REACHED,
  USER_NOT_ACTIVATED,
} from 'common/constants'

export const VerifyOTPErrors = ({
  errors,
  errorContext,
  handleResend,
  redirectTo,
  onClickRedirect,
}) => {
  const theme = useTheme()
  const intl = useIntl()
  const textColor = theme?.palette?.getContrastText(theme?.palette?.error?.main)
  const resend = str => {
    return (
      <UserTextLink
        onClick={handleResend}
        style={{ color: textColor }}
        underline="always"
        variant="text"
      >
        {str}
      </UserTextLink>
    )
  }

  const startOver = str => {
    return (
      <RouterLink
        to={redirectTo}
        onClick={onClickRedirect}
        style={{ color: textColor }}
      >
        <UserTextLink
          style={{ color: textColor }}
          underline="always"
          variant="text"
        >
          {str}
        </UserTextLink>
      </RouterLink>
    )
  }
  return (
    <BackendErrorNotification
      intl={intl}
      error={errors}
      prioritize={[
        ACTION_TOKEN_RETRIES_EXCEEDED,
        ACTION_TOKEN_EXPIRED,
        INVALID_PKAT,
        TOKEN_MAX_RESEND_REACHED,
        USER_NOT_ACTIVATED,
      ]}
      contextMap={{
        [ACTION_TOKEN_EXPIRED]: errorContext,
        [ACTION_TOKEN_RETRIES_EXCEEDED]: errorContext,
        [INVALID_PKAT]: errorContext,
        [TOKEN_MAX_RESEND_REACHED]: errorContext,
        [USER_NOT_ACTIVATED]: errorContext,
      }}
      showMessageInline
      hideClose
      codeParamMap={{
        [ACTION_TOKEN_EXPIRED]: {
          message: {
            resend: resend,
          },
        },
        [ACTION_TOKEN_RETRIES_EXCEEDED]: {
          message: {
            resend: resend,
          },
        },
        [INVALID_PKAT]: {
          message: {
            startover: startOver,
          },
        },
        [TOKEN_MAX_RESEND_REACHED]: {
          message: {
            startover: startOver,
          },
        },
      }}
    />
  )
}
