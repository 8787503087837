import React from 'react'
import LockTwoToneIcon from '@mui/icons-material/LockTwoTone'

const LockIcon = () => {
  return (
    <LockTwoToneIcon
      color="primary"
      fontSize="large"
      style={{
        fontSize: '96px',
      }}
    />
  )
}

export default LockIcon
