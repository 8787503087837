import { hasUIFeatureFlag } from 'common/services/FeatureFlagsService'

export const getSidebarMenu = sidebarMenu => {
  return sidebarMenu.reduce((acc, val, index) => {
    if (index === 0) {
      return [
        {
          ...val,
          enable:
            hasUIFeatureFlag('showPersonalInfoSection') ||
            hasUIFeatureFlag('showContactInfoSection'),
        },
      ]
    }
    if (index === 1) {
      return acc.concat({
        ...val,
        enable:
          hasUIFeatureFlag('showSecuritySection') ||
          hasUIFeatureFlag('showSocialSignInSection'),
      })
    }
    return acc.concat({
      ...val,
      enable: hasUIFeatureFlag('showBillingAccountSection'),
    })
  }, [])
}

export default getSidebarMenu
