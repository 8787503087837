import { TextField, Typography } from '@mui/material'
import { Box } from '@mui/system'
import { TextPrimaryButton } from 'common/components/button/Button'
import { useCallback } from 'react'
import { useIntl } from 'react-intl'

export default function EnterCode({ setCode }) {
  const intl = useIntl()

  const handleFormSubmit = useCallback(
    event => {
      event.preventDefault()
      const formData = new FormData(event.target)

      setCode(formData.get('code'))
    },
    [setCode],
  )

  return (
    <>
      <Box mb={3}>
        <Typography>
          {intl.formatMessage({ id: 'activate-device.enter-code.message' })}
        </Typography>
      </Box>
      <Box
        component="form"
        display="flex"
        flexDirection="column"
        alignItems="start"
        gap={4}
        onSubmit={handleFormSubmit}
        autoComplete="off"
      >
        <TextField
          name="code"
          fullWidth
          label={intl.formatMessage({
            id: 'activate-device.enter-code.enter-code-label',
          })}
        />
        <TextPrimaryButton type="submit" variant="contained" size="large">
          {intl.formatMessage({ id: 'common.button.continue' })}
        </TextPrimaryButton>
      </Box>
    </>
  )
}
