import { DEFAULT_ANALYTICS_PROVIDER } from 'common/constants/analytics'

const {
  server = window.location.origin + '/',
  regexEmail = /^\S+@\S+$/,
  regexPhone = /^(?:[0-9] ?){6,14}[0-9]$/,
  regexStripPhone = /\D+/g,
  recaptchaSiteKey = '6LdcmqIZAAAAAHFf4Zu7NW30IwJERJKRdYEORwh9',
  tagEvents = true,
  googleAnalyticsTrackingId = 'UA-160737991-4', // DEV end
  analyticsProvider = DEFAULT_ANALYTICS_PROVIDER,
  matomoAnalyticsSiteId = '5',
  matomoAnalyticsTrackingId = 'Zlf8WoHI', // DEV end
} = window.runtimeConf

const sidebarMenu = [
  {
    path: 'profile',
    displayName: 'profile.basic.section.title',
    defaultMessage: 'Profile',
  },
  {
    path: 'security-and-sign-in',
    displayName: 'security-and-sign-in.basic.section.title',
    defaultMessage: 'Security & sign-in',
  },
  {
    path: 'accounts',
    displayName: 'accounts.title',
    defaultMessage: 'Billing accounts',
  },
]

const configuration = {
  appName: 'IDP Portal',
  appVersion: '2022.22.x',
  apiRoot: `${server}platform/rest/v85`,
  oidcRoot: `${server}oidc`,
  regexEmail,
  regexPhone,
  regexStripPhone,
  recaptchaSiteKey,
  emailVerificationNonMandatory: false,
  resendOTPCountdownDuration: 30,
  sidebarMenu,
  dummyAccountAssociation: true,
  mockMultipleAccountAssociation: true,
  analyticsProvider,
  tagEvents,
  analyticsRetryTimeout: 500,
  googleAnalyticsTrackingId,
  googleAnalyticsMaxRetry: 5,
  matomoUrl: 'https://matomo.cicd.aws.vindicia.com',
  matomoAnalyticsMaxRetry: 500,
  matomoAnalyticsSiteId,
  matomoAnalyticsTrackingId,
}

export default configuration
