import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { injectIntl } from 'react-intl'
import Box from '@mui/material/Box'

import { isEmpty, get } from 'lodash'
import { InlineNotification } from '@admin-ui-common/base-user'
import { BackendErrorNotification } from 'common/components/backendErrorNotification/BackendErrorNotification'
import Page from 'common/components/Page'
import { Button } from 'common/components/button/Button'
import PasswordField from 'common/components/textfield/PasswordField'

import FeatureFlagsService from 'common/services/FeatureFlagsService'
import styles from '../styles.module.css'

import process from 'common/utils/process'
import { checkToken } from 'common/actions/socialAccounts'
import { checkSession, endSession } from 'common/actions/session'
import CheckMarkIcon from 'common/components/CheckMarkIcon'
import { analyticsProvider } from '../../App'
import FlowLayout from '../../common/layouts/FlowLayout'
import ResetResult from './ResetResult'
import {
  fallbackId,
  formatErrorMessage,
  getErrorCodes,
  isUlmBackendError,
  resolveErrorEntity,
} from '../../common/utils/processBackendErrors'
import {
  getRedirectLocation,
  performRedirect,
} from 'common/utils/getRedirectUrl'

const hasFeatureFlag = feature =>
  FeatureFlagsService.get(`idpPortal.reset.${feature}`)
const ErrorView = ({ error, onError, intl }) => {
  // judging from the code, error could be a backend error response
  // or a custom object with fields like 'wrongPassword' or 'newPassword'
  const ulmBackendError = isUlmBackendError(error)
  const errorCodes = ulmBackendError ? getErrorCodes(error) : []
  const isInvalidActConsumption = errorCodes.includes('invalid-act-consumption')
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      {!ulmBackendError &&
        (error.wrongPassword ||
          error.newPassword ||
          error.confirmPassword ||
          intl.formatMessage({ id: fallbackId }))}
      {ulmBackendError &&
        isInvalidActConsumption &&
        formatErrorMessage({
          intl,
          id: 'error.invalid-act-consumption.context.reset',
        })}
      {ulmBackendError &&
        !isInvalidActConsumption &&
        resolveErrorEntity({ intl, error })}
      <div className="mt2 self-center">
        <Button onClick={onError}>
          {intl.formatMessage({ id: 'common.button.ok' })}
        </Button>
      </div>
    </div>
  )
}

class Reset extends React.Component {
  state = {
    newPassword: '',
    confirmPassword: '',
    errors: {},
    isLoading: false,
    step: '',
    errorResponseBody: null,
  }

  constructor(props) {
    super(props)
    if (props.wizard) {
      const { processId, userAuthenticated } = props.wizard.getPageState()
      this.processId = processId
      this.userAuthenticated = userAuthenticated
    }

    this.pageTitle = 'reset'
    this.baseEventTag = `forgot.${this.pageTitle}`
  }

  componentDidMount() {
    analyticsProvider.sendAnalytics({
      type: 'page_view',
      page_title: this.pageTitle,
      page_path: `/forgot#${this.pageTitle}`,
    })

    const { socialAccounts, hasSession, wizard } = this.props

    if (hasSession) {
      this.props.endSession()
    } else if (!wizard) {
      if (isEmpty(socialAccounts.checkTokenData)) {
        const query = this.props.location.search

        if (query.includes('token_value=')) {
          this.props.checkToken(query)
        } else {
          this.props.history.push('/')
        }
      }
    }
  }

  handleNewPasswordChange = event => {
    this.setState({ newPassword: event.target.value })
  }

  handleConfirmPasswordChange = event => {
    this.setState({ confirmPassword: event.target.value })
  }

  submitForm = event => {
    event.preventDefault()
    this.setState({ errorResponseBody: null })
    analyticsProvider.sendAnalytics({
      type: 'event',
      action: 'click',
      event_category: 'button',
      event_label: `${this.baseEventTag}.change-password`,
      value: 0,
    })

    const { socialAccounts, intl, checkSession } = this.props
    const { userAuthenticated, processId } =
      socialAccounts.checkTokenData || this
    const { newPassword, confirmPassword } = this.state
    const showConfirmPassword = hasFeatureFlag('showConfirmPassword')

    const errors = {}

    if (showConfirmPassword) {
      if (!newPassword) {
        errors.newPassword = intl.formatMessage({ id: 'error.new-password' })
      } else if (!confirmPassword) {
        errors.confirmPassword = intl.formatMessage({
          id: 'error.confirm-password',
        })
      } else if (newPassword !== confirmPassword) {
        errors.wrongPassword = intl.formatMessage({
          id: 'error.not-match-new-password',
        })
      }
    } else {
      if (!newPassword) {
        errors.newPassword = intl.formatMessage({ id: 'error.new-password' })
      }
    }

    if (Object.keys(errors).length) {
      this.setState({ errors })
      return
    }

    this.setState({ errors: {} })

    this.setState({ isLoading: true })

    return process
      .step({
        userAuthenticated,
        processId,
        parameters: { newPassword },
      })
      .then(res => {
        const { metadata } = res
        if (get(res, 'body.lastStep')) {
          const location = getRedirectLocation(metadata, '')
          if (location) {
            return performRedirect(location)
          }
        }

        if (process.isAuthComplete(res)) {
          checkSession()
        } else if (process.isCompleteResponse(res)) {
          this.setState({ step: 'ResetResult' })
        }
      })
      .catch(err => {
        analyticsProvider.sendAnalytics({
          type: 'event',
          action: 'click',
          event_category: 'button',
          event_label: `${this.baseEventTag}.change-password`,
          value: 1,
        })

        this.setState({
          errorResponseBody: err.body,
          isLoading: false,
        })
      })
  }

  render() {
    const {
      error,
      isLoading,
      hasFailed,
      checkTokenData,
    } = this.props.socialAccounts

    const { intl } = this.props
    const { errors, step, errorResponseBody } = this.state
    const showConfirmPassword = hasFeatureFlag('showConfirmPassword')

    if (isLoading) {
      return <div>{intl.formatMessage({ id: 'common.loading' })}</div>
    }

    if (hasFailed && !isEmpty(error)) {
      return (
        <ErrorView
          error={error || errors}
          onError={() => this.props.history.push('/')}
          intl={intl}
        />
      )
    }

    if (isEmpty(checkTokenData) && !this.processId && !this.userAuthenticated) {
      return null
    }

    if (step === 'ResetResult') {
      let goHome
      const { wizard } = this.props

      if (wizard) {
        const { usernameEntered } = wizard.getPageState()
        goHome = () => {
          this.props.history.push('/', { usernameEntered })
        }
      } else {
        goHome = () => {
          this.props.history.push('/')
        }
      }

      return (
        <FlowLayout
          title={intl.formatMessage({ id: 'reset-password-result.title' })}
          image={CheckMarkIcon}
        >
          <ResetResult onConfirm={goHome} />
        </FlowLayout>
      )
    }

    return (
      <FlowLayout
        title={intl.formatMessage({ id: 'forgot-password.reset.title' })}
      >
        <form>
          <Page.Body>
            {intl.formatMessage({ id: 'forgot-password.reset.message' })}
          </Page.Body>
          <p
            className={styles.formErrorMessage}
            style={{
              visibility:
                errors.wrongPassword || errorResponseBody
                  ? 'visible'
                  : 'hidden',
            }}
          >
            {errors.wrongPassword && (
              <Box mb={3}>
                <InlineNotification
                  message={errors.wrongPassword}
                  variant="error"
                  handleClose={() => {
                    this.setState({ errors: {} })
                  }}
                />
              </Box>
            )}
            {!!errorResponseBody && (
              <Box mb={3}>
                <BackendErrorNotification
                  intl={intl}
                  subTitle={
                    <div>
                      {formatErrorMessage({
                        intl,
                        id: 'error.credential-at-least',
                      })}
                    </div>
                  }
                  error={errorResponseBody}
                  handleClose={() => {
                    this.setState({ errorResponseBody: null })
                  }}
                  prioritize={['NotReusedPasswordByTime', 'NotReusedPassword']}
                  showAsSingle={[
                    'NotReusedPasswordByTime',
                    'NotReusedPassword',
                  ]}
                />
              </Box>
            )}
          </p>
          <PasswordField
            errorText={this.state.errors.newPassword}
            label={intl.formatMessage({
              id: 'common.label.new-password',
            })}
            onChange={this.handleNewPasswordChange}
            value={this.state.newPassword}
          />
          <div className={styles.fieldMB} />
          {showConfirmPassword && (
            <PasswordField
              errorText={this.state.errors.confirmPassword}
              label={intl.formatMessage({
                id: 'common.label.confirm-new-password',
              })}
              onChange={this.handleConfirmPasswordChange}
              value={this.state.confirmPassword}
            />
          )}
          <div className={styles.formButton}>
            <Button
              size="large"
              onClick={this.submitForm}
              isLoading={this.state.isLoading}
            >
              {intl.formatMessage({
                id: 'forgot-password.set-new-password',
              })}
            </Button>
          </div>
        </form>
      </FlowLayout>
    )
  }
}

function PageWrapper(props) {
  return <Reset {...props} />
}

const mapStateToProps = ({ socialAccounts, session }) => ({
  socialAccounts,
  hasSession: session.hasSession,
})
const mapDispatchToProps = {
  checkToken,
  checkSession,
  endSession,
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(injectIntl(PageWrapper)),
)
