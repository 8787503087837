import React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { injectIntl } from 'react-intl'

import Box from '@mui/material/Box'

import { getSubscriptionNumber } from 'common/selectors/subscription'

const Service = ({ service, subscriptionNumber }) => (
  <Box component="li" mb={1} data-q={service.displayName || subscriptionNumber}>
    {service.displayName || subscriptionNumber}
  </Box>
)

Service.propTypes = {
  service: PropTypes.object,
  subscriptionNumber: PropTypes.string,
}

const mapState = (state, { service }) => ({
  subscriptionNumber: getSubscriptionNumber(service),
})

const connectedToProps = connect(mapState)

export default compose(connectedToProps, injectIntl)(Service)
