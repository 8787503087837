import process from 'common/utils/process'

const processId = 'recovery.PasswordRecovery.v1.0'

const recover = (authnIdentifier, extraProps = {}) =>
  process.start(processId).then(response => {
    if (process.isCompleteResponse(response)) {
      return response
    }

    return process.step({
      ...response.body,
      parameters: {
        authnIdentifier,
        ...extraProps,
      },
    })
  })

export default recover
