import React, { useState } from 'react'
import { useIntl } from 'react-intl'
import { get } from 'lodash'
import { useHistory } from 'react-router-dom'
import FlowLayout from 'common/layouts/FlowLayout'
import { Box, TextField, Typography } from '@mui/material'
import {
  getErrorCodes,
  resolveErrorEntity,
  formatErrorMessage,
} from 'common/utils/processBackendErrors'
import { TOO_MANY_RETRIES } from 'common/constants'
import useMainRedirect from 'common/components/hooks/useMainRedirect'
import { useForm } from 'react-hook-form'
import { UserButton } from '@admin-ui-common/base-user'
import { process } from '@admin-ui-common/utils'

export default function TOTPSetupVerificationStep({
  wizard,
  getPageIndex,
  pages,
  qrScanData,
  handleSelectQRScanStep,
  handleTwoFASetupSuccess,
}) {
  const intl = useIntl()
  const wizardState = wizard ? wizard.getPageState() : qrScanData
  const mainRedirect = useMainRedirect()
  const history = useHistory()
  const { location } = history

  const [errorMessage, setErrorMessage] = useState(null)
  const [disabledNext, setDisabledNext] = useState(false)

  function back(e) {
    e.preventDefault()
    if (wizard) {
      wizard.toPage(getPageIndex(pages.QRCodeScanStep))
    } else {
      handleSelectQRScanStep(qrScanData)
    }
  }

  async function submitCode({ code }) {
    setErrorMessage(null)

    const processId = get(wizardState, 'processId')

    try {
      const res = await process.step({
        processId: processId,
        parameters: {
          code: code,
        },
      })

      if (get(res, 'data.stepName') === 'UpdatePassword') {
        history.push({
          pathname: '/password_expire',
          state: get(res, 'data'),
          search: location.search,
        })
        return
      }

      if (get(res, 'data.userAuthenticated') === true) {
        const isStaticRedirect = mainRedirect(res)
        if (!isStaticRedirect) {
          return
        }
        if (wizard) {
          wizard.toPage(getPageIndex(pages.TwoFASetupSuccessStep))
        } else {
          handleTwoFASetupSuccess()
        }
      }
    } catch (err) {
      const errorData = err.body || err.data
      const errorCodes = getErrorCodes(errorData)

      if (errorCodes.includes('invalid-act-consumption')) {
        setErrorMessage(formatErrorMessage({ intl, id: 'error.invalid-code' }))
      } else if (errorCodes.includes(TOO_MANY_RETRIES)) {
        setDisabledNext(true)
        setErrorMessage(
          formatErrorMessage({
            intl,
            id: 'error.process-terminated-with-too-many-retries',
          }),
        )
      } else {
        setErrorMessage(resolveErrorEntity({ intl, error: errorData }))
      }
    }
  }

  const { handleSubmit, register } = useForm({
    defaultValues: {
      code: '',
    },
  })

  function onSubmit(values) {
    submitCode(values)
  }

  return (
    <FlowLayout
      title={intl.formatMessage({
        id: 'user.management.register.QRCodeSetup.title',
      })}
    >
      <Box component="form" onSubmit={handleSubmit(onSubmit)}>
        <Box marginBottom={3}>
          <Typography variant="body1">
            {intl.formatMessage({
              id: 'user.management.register.totpVerificationDialog.message',
            })}
          </Typography>
        </Box>
        <TextField
          inputMode="numeric"
          aria-required
          fullWidth
          autoFocus
          label={intl.formatMessage({ id: 'common.enter-code' })}
          autoComplete="one-time-code"
          {...register('code')}
          error={errorMessage ?? undefined}
          helperText={errorMessage ?? undefined}
        />
        <Box marginTop={4} display="flex" gap={3}>
          <UserButton type="submit" size="large" disabled={disabledNext}>
            {intl.formatMessage({ id: 'common.button.verify' })}
          </UserButton>
          <UserButton onClick={back} variant="outlined" size="large">
            {intl.formatMessage({ id: 'common.button.back' })}
          </UserButton>
        </Box>
      </Box>
    </FlowLayout>
  )
}
