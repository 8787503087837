import React, { useEffect } from 'react'
import { BrowserRouter as Router } from 'react-router-dom'
import { updateContext } from '../actions/configuration'
import getCurrentSubRouteConfiguration from 'common/utils/getCurrentSubRouteConfiguration'

const CustomRouter = props => {
  const {
    currentSubRoute: baseName,
    config,
  } = getCurrentSubRouteConfiguration()

  useEffect(() => {
    updateContext(baseName, config)
  }, [baseName, config])

  return <Router basename={baseName}>{props.children}</Router>
}

export default CustomRouter
