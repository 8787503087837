import React, { useState } from 'react'
import { useIntl } from 'react-intl'
import { Box, Typography } from '@mui/material'
import { TextField, UserButton } from '@admin-ui-common/base-user'
import FlowLayout from 'common/layouts/FlowLayout'
import { get } from 'lodash'
import { BackendErrorNotification } from 'common/components/backendErrorNotification/BackendErrorNotification'
import { formatErrorMessage } from 'common/utils/processBackendErrors'
import useMainRedirect from 'common/components/hooks/useMainRedirect'

const processStates = {
  PENDING: 'PENDING',
  BUSY: 'BUSY',
  SUCCEEDED: 'SUCCEEDED',
  FAILED: 'FAILED',
}

export default function SetPasswordStep({
  wizard,
  addPasswordStep,
  getPageIndex,
  pages,
  steps,
}) {
  const wizardState = wizard.getPageState()
  const intl = useIntl()
  const [validationError, setValidationError] = useState(null)
  const [errorResponseBody, setErrorResponseBody] = useState(null)
  const [processState, setProcessState] = useState(processStates.PENDING)
  const mainRedirect = useMainRedirect()

  function validatePassword(e) {
    if (e.target.value.trim().length === 0) {
      setValidationError(intl.formatMessage({ id: 'error.credential' }))
    } else {
      setValidationError(null)
    }
  }

  async function handleFormSubmit(e) {
    e.preventDefault()
    const formData = new FormData(e.target)
    const newPassword = formData.get('password')

    setProcessState(processStates.BUSY)

    try {
      const res = await addPasswordStep(
        {
          processId: wizardState.processId,
        },
        {
          password: newPassword,
        },
      )

      const stepName = get(res, 'body.stepName')
      const stepData = get(res, 'body')

      if (stepName === steps.TwoFASetupMethodPrompt) {
        wizard.setPageState(stepData)
        wizard.toPage(getPageIndex(pages.TwoStepSetupStep))
        return
      }

      if (stepName === steps.TwoFACodePrompt) {
        wizard.setPageState(stepData)
        wizard.toPage(getPageIndex(pages.TwoStepCode))
        return
      }

      if (stepName === steps.TwoFAMethodPrompt) {
        wizard.setPageState(stepData)
        wizard.toPage(getPageIndex(pages.TwoStepMethod))
        return
      }

      if (stepName === steps.TotpVerificationPrompt) {
        wizard.setPageState(stepData)
        wizard.toPage(getPageIndex(pages.TotpVerificationStep))
        return
      }

      if (get(res, 'body.userAuthenticated') === true) {
        const isStaticRedirect = mainRedirect(res.metadata)
        if (!isStaticRedirect) {
          return
        }
        setProcessState(processStates.SUCCEEDED)
        wizard.toPage(getPageIndex(pages.LoggedIn))
      }
    } catch (err) {
      setProcessState(processStates.FAILED)
      setValidationError(intl.formatMessage({ id: 'error.credential' }))
      setErrorResponseBody(err.body)
    }
  }

  const busy = processState === processStates.BUSY
  const cantSubmit =
    processState === processStates.BUSY || validationError !== null

  return (
    <FlowLayout
      title={intl.formatMessage({
        id: 'separatedMultiStep.authenticate-setPassword.title',
      })}
    >
      <form onSubmit={handleFormSubmit}>
        <Box mb={3}>
          <Typography>
            {intl.formatMessage({
              id: 'separatedMultiStep.authenticate-setPassword.body',
            })}
          </Typography>
        </Box>
        {!!errorResponseBody ? (
          <Box mb={3}>
            <BackendErrorNotification
              intl={intl}
              subTitle={
                <div>
                  {formatErrorMessage({
                    intl,
                    id: 'error.credential-at-least',
                  })}
                </div>
              }
              error={errorResponseBody}
              handleClose={() => {
                setErrorResponseBody(null)
              }}
              prioritize={['NotReusedPasswordByTime', 'NotReusedPassword']}
              showAsSingle={['NotReusedPasswordByTime', 'NotReusedPassword']}
            />
          </Box>
        ) : null}
        <Box>
          <TextField
            autoFocus
            id="password"
            name="password"
            fullWidth
            label={intl.formatMessage({
              id: 'separatedMultiStep.authenticate-setPassword.label.password',
            })}
            inputType="password"
            error={validationError !== null}
            helperText={validationError}
            onChange={validatePassword}
            onBlur={validatePassword}
            inputProps={{
              'data-testid': 'setPassword',
            }}
          />
        </Box>
        <Box mt={4}>
          <UserButton type="submit" isLoading={busy} disabled={cantSubmit}>
            {intl.formatMessage({
              id:
                'separatedMultiStep.authenticate-setPassword.button.setPassword',
            })}
          </UserButton>
        </Box>
      </form>
    </FlowLayout>
  )
}
