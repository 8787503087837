import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import {
  mobileConnectInitiateProcess,
  continueMobileConnect,
  clearState,
} from 'common/actions/mobileConnect'
import isEmpty from 'lodash/isEmpty'
import VerifyNameEmail from './VerifyNameEmail'
import ConfirmPassword from './ConfirmPassword'
import { Button } from 'common/components/button/Button'
import { checkSession } from 'common/actions/session'
import cloneDeep from 'lodash/cloneDeep'
import isEqual from 'lodash/isEqual'
import MergeConfirmation from './MergeConfirmation'
import BasicLayout from 'common/layouts/BasicLayout'

const initState = {
  step: null,
  inputs: {},
  errors: {},
}

class MobileConnectLogin extends React.Component {
  constructor(props) {
    super(props)

    // initial landing
    if (props.mobileConnect.data === null) {
      const query = props.location.search

      if (query.includes('code=')) {
        props.mobileConnectInitiateProcess(query)
      } else {
        this.props.history.push('/')
      }
    }

    this.state = cloneDeep(initState)
  }

  componentWillReceiveProps(nextProps) {
    if (
      !isEmpty(nextProps.mobileConnect.data) &&
      nextProps.mobileConnect.data.statusCode === 200
    ) {
      this.props.checkSession()
      this.props.history.push('/')
    } else if (
      !isEqual(this.props.mobileConnect.data, nextProps.mobileConnect.data) &&
      !isEmpty(nextProps.mobileConnect.data) &&
      nextProps.mobileConnect.data.statusCode === 202
    ) {
      const newState = cloneDeep(initState)
      const { data } = nextProps.mobileConnect

      if (data.output) {
        if ('displayName' in data.output && 'emailAddress' in data.output) {
          newState.step = 'verifyNameEmail'

          Object.keys(data.output).forEach(paramName => {
            newState.inputs[paramName] = data.output[paramName]
          })
        } else if ('mergeConfirmation' in data.output) {
          newState.step = 'mergeConfirmation'
        } else if ('password' in data.output) {
          newState.step = 'password'
          newState.inputs.password = ''
        }
      }

      this.setState(newState)
    }
  }

  handleChange = (inputField, event) => {
    const newInputs = this.state.inputs
    const { step } = this.state

    if (step === 'verifyNameEmail' || step === 'password') {
      newInputs[inputField] = event.target.value
    }

    this.setState({ inputs: newInputs })
  }

  handleSubmit = input => {
    // TODO: have key called step on state, submission changes based on step
    const { step, inputs } = this.state

    if (step === 'verifyNameEmail') {
      return this.props.continueMobileConnect({
        parameters: {
          name: inputs.displayName,
          email: inputs.emailAddress,
        },
      })
    } else if (step === 'mergeConfirmation') {
      return this.props.continueMobileConnect({
        output: {
          mergeConfirmation: {
            acceptedType: input,
          },
        },
      })
    } else if (step === 'password') {
      const errors = {}
      if (isEmpty(inputs.password)) {
        errors.password = 'Password cannot be empty'
      } else if (inputs.password.length < 6) {
        errors.password = 'Password must be at least 6 characters long'
      }

      if (!isEmpty(errors)) {
        return this.setState({ errors })
      }

      return this.props.continueMobileConnect({
        output: {
          password: {
            acceptedType: inputs.password,
          },
        },
      })
    }
  }

  goHome = () => {
    this.props.clearState()
    this.props.history.push('/')
  }

  formatErrorMessage = () => {
    const { error } = this.props.mobileConnect

    if (
      error &&
      error.operationError &&
      error.operationError.constructor === Array
    ) {
      return `An error has occurred: ${error.operationError[0].message}`
    } else if (
      error &&
      error.operationError &&
      error.operationError.constructor === Object
    ) {
      return `An error has occurred: ${error.operationError.code}`
    }

    return 'An unexpected error has occurred'
  }

  render() {
    const { data, error, isLoading, hasFailed } = this.props.mobileConnect
    const { inputs, step, errors } = this.state

    if (isLoading) {
      return <div>Loading Page...</div>
    }

    if (hasFailed && !isEmpty(error)) {
      return (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            maxWidth: '500px',
          }}
        >
          {this.formatErrorMessage()}

          <div className="mt2 self-end">
            <Button onClick={this.goHome}>Ok</Button>
          </div>
        </div>
      )
    }

    switch (step) {
      case 'verifyNameEmail':
        return (
          <VerifyNameEmail
            displayMessage={data.displayMessage}
            output={data.output}
            inputs={inputs}
            handleChange={this.handleChange}
            handleSubmit={this.handleSubmit}
          />
        )

      case 'mergeConfirmation':
        return (
          <MergeConfirmation
            displayMessage={data.displayMessage}
            handleSubmit={this.handleSubmit}
          />
        )

      case 'password':
        return (
          <ConfirmPassword
            displayMessage={data.displayMessage}
            inputs={inputs}
            errors={errors}
            handleChange={this.handleChange}
            handleSubmit={this.handleSubmit}
          />
        )

      default:
        return null
    }
  }
}

function PageWrapper(props) {
  return (
    <BasicLayout>
      <MobileConnectLogin {...props} />
    </BasicLayout>
  )
}

const mapState = ({ mobileConnect }) => ({
  mobileConnect,
})

const mapDispatch = {
  mobileConnectInitiateProcess,
  continueMobileConnect,
  checkSession,
  clearState,
}

export default withRouter(connect(mapState, mapDispatch)(PageWrapper))
