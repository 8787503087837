import conf from 'conf'
import i from 'common/utils/i'
import process from 'common/utils/process'
import { get, reduce } from 'lodash'

import { ASSOCIATION_PROMPT } from 'common/constants/account'

const model = 'accounts'

// //////////////////////////////////////////////////////////////////////////////////

export const LOAD_ACCOUNTS_REQUEST = `${model}/LOAD_ACCOUNTS`
export const LOAD_ACCOUNTS_SUCCESS = `${model}/LOAD_ACCOUNTS_SUCCESS`
export const LOAD_ACCOUNTS_FAILURE = `${model}/LOAD_ACCOUNTS_FAILURE`

const loadAccountsRequest = {
  type: LOAD_ACCOUNTS_REQUEST,
}

const loadAccountsSuccess = accounts => ({
  type: LOAD_ACCOUNTS_SUCCESS,
  accounts,
})

const loadAccountsFailure = error => ({
  type: LOAD_ACCOUNTS_FAILURE,
  error,
})

export const loadAccounts = () => dispatch => {
  const url = `${conf.apiRoot}/user/accounts`

  dispatch(loadAccountsRequest)

  return i
    .get(url)
    .then(({ body }) => dispatch(loadAccountsSuccess(body)))
    .catch(error => dispatch(loadAccountsFailure(error)))
}

export const getPaymentMethodId = () => {
  const url = `${conf.apiRoot}/user/accounts`

  try {
    return i
      .get(url)
      .then(({ body }) => {
        const attributes = get(body[0], 'attributes')
        const paymentDetails = get(attributes, 'paymentDetails')
        const paymentMethodId = get(paymentDetails[0], 'paymentMethodId')
        return paymentMethodId
      })
      .catch(() => {
        return null
      })
  } catch {
    return null
  }
}

// //////////////////////////////////////////////////////////////////////////////////

export const LOAD_USERS_REQUEST = `${model}/LOAD_USERS_REQUEST`
export const LOAD_USERS_SUCCESS = `${model}/LOAD_USERS_SUCCESS`
export const LOAD_USERS_FAILURE = `${model}/LOAD_USERS_FAILURE`

const loadAccountUsersRequest = accountId => ({
  type: LOAD_USERS_REQUEST,
  payload: {
    accountId,
  },
})

const loadAccountUsersSuccess = ({ users, accountId, totalUsers }) => ({
  type: LOAD_USERS_SUCCESS,
  payload: {
    users,
    accountId,
    totalUsers,
  },
})

const loadAccountUsersFailure = (error, accountId) => ({
  type: LOAD_USERS_FAILURE,
  payload: {
    error,
    accountId,
  },
})

// //////////////////////////////////////////////////////////////////////////////////

export const loadAccountUsers = accountId => dispatch => {
  const url = `${conf.apiRoot}/account/${accountId}/users`

  dispatch(loadAccountUsersRequest(accountId))

  return i
    .get(url)
    .then(({ body, metadata }) => {
      const totalUsers =
        metadata.headers['X-Total-Count'] || metadata.headers['x-total-count']

      return dispatch(
        loadAccountUsersSuccess({
          users: body,
          accountId,
          totalUsers,
        }),
      )
    })
    .catch(error => dispatch(loadAccountUsersFailure(error, accountId)))
}

// //////////////////////////////////////////////////////////////////////////////////

const singleAccountStepAssociation = async (discoveredAccounts, stepData) => {
  const accountNumber = get(discoveredAccounts, '[0].accountNumber')
  const nextStep = { ...stepData }
  nextStep.parameters = {
    accountKeys: [accountNumber],
  }

  let response = await process.step({
    ...nextStep,
  })
  stepData = response.body

  const challengeQuestionEnabled =
    get(response, 'body.stepName') === ASSOCIATION_PROMPT.question

  // If challenge question enabled show additional screen
  if (challengeQuestionEnabled) {
    return {
      challengeQuestionEnabled,
      accountNumber,
      stepData,
    }
  }
}

// //////////////////////////////////////////////////////////////////////////////////

export const identifierAccountStepAssociation = async stepData => {
  const discoveredAccounts = reduce(
    get(stepData, 'output.loadedAccounts', {}),
    (result, account, accountName) => {
      result.push({
        ...account,
        accountName,
      })
      return result
    },
    [],
  )

  // Only one account found - try to associate it right away
  if (discoveredAccounts.length === 1) {
    return singleAccountStepAssociation(discoveredAccounts, stepData)
  } else {
    // If more than one account - show selection dialog in UI
    return {
      discoveredAccounts,
      stepData,
      manual: false,
    }
  }
}

// //////////////////////////////////////////////////////////////////////////////////
const linkAccountProcessId = 'accountAssociation.AssociateAccount.v1.0'

export const linkAccount = async accountCount => {
  let response = await process.start(linkAccountProcessId)

  response.body.parameters = {}
  return Promise.resolve({
    manual: true,
    stepData: response.body,
  })
}

// //////////////////////////////////////////////////////////////////////////////////
const linkAccountV2ProcessId = 'accountAssociation.AssociateAccount.v2.0'

export const linkAccountV2 = async accountCount => {
  let response = await process.start(linkAccountV2ProcessId)

  response.body.parameters = {}
  return Promise.resolve({
    manual: true,
    stepData: response.body,
  })
}

// //////////////////////////////////////////////////////////////////////////////////

const unlinkAccountProcessId = 'accountAssociation.DissociateAccount.v1.0'
export const unlinkAccount = accountId =>
  process.start(unlinkAccountProcessId).then(response => {
    const payload = response.body

    delete payload.returnParameters
    delete payload.output
    delete payload.stepName
    delete payload.processName

    payload.parameters = {
      accounts: [accountId],
    }

    return process.step(payload)
  })
