import process from 'common/utils/process'

export const enrolAuthenticateWithSubscriptionProcessId =
  'authenticate.enrolAuthenticateWithSubscription.v2.0'

export const enrolAuthenticateWithSubscription = productId => {
  // TODO. Remove debug query param
  const params = {
    'x-process-debug': true,
    productId,
  }

  return process.startGet(enrolAuthenticateWithSubscriptionProcessId, params)
}

// //////////////////////////////////////////////////////////

const onboardUserWithServiceSubscriptionProcessId =
  'authenticate.enrolAuthenticateWithSubscription.v2.0'

export const onboardUserWithServiceSubscription = productId => {
  // TODO. Remove debug query param
  const params = {
    'x-process-debug': true,
    productId,
  }

  return process.startGet(onboardUserWithServiceSubscriptionProcessId, params)
}

// //////////////////////////////////////////////////////////

const cancelSubscriptionProcessId =
  'cloudSubscriptionManagement.CancelSubscription.v2.0'

export const attMexCancelSubscription = ({
  productId,
  identifier,
  ignoreErrorRedirect,
}) => {
  // TODO. Remove debug query param
  const params = {
    'x-process-debug': true,
    productId,
    identifier,
    ignoreErrorRedirect,
  }

  return process.startGet(cancelSubscriptionProcessId, params)
}
