import process from 'common/utils/process'

const id = 'authentication.OnboardAndAuthenticate.v2.0'

const enrollUserV2 = (identity, startParams, opts) =>
  process
    .startGet(id, startParams, opts)
    .then(response => process.step({ ...response.body, parameters: identity }))
    .then(process.complete)

export default enrollUserV2
