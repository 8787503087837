import React from 'react'
import get from 'lodash/get'
import { useTheme } from '@mui/material/styles'
import HighlightOffOutlinedIcon from '@mui/icons-material/HighlightOffOutlined'
const CrossIcon = (marginBottom = '32px') => {
  const theme = useTheme()
  const colorId =
    theme.palette.mode === 'light'
      ? 'palette.error.dark'
      : 'palette.error.light'
  const iconColor = get(theme, colorId) || get(theme, 'palette.error.dark')
  return (
    <HighlightOffOutlinedIcon
      fontSize="large"
      style={{
        color: iconColor,
        fontSize: '96px',
        marginBottom: marginBottom,
      }}
    />
  )
}
export default CrossIcon
