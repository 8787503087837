import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { Link } from 'react-router-dom'
import { injectIntl } from 'react-intl'
import { compose } from 'redux'

import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'
import ButtonBase from '@mui/material/ButtonBase'
import withStyles from '@mui/styles/withStyles'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import EditIcon from '@mui/icons-material/Edit'
import DeleteIcon from '@mui/icons-material/Delete'

import WrapperContainer from 'protected/common/WrapperContainer'
import Page from 'common/components/Page'
import Paper from '../profile/ProfilePaper'

import { analyticsProvider } from '../../App'
import { Caption, DetailTitle } from '../../common/components/Typography'

import { isEmpty, capitalize } from 'lodash'
import IconButton from '@mui/material/IconButton'
import { UserButton } from '@admin-ui-common/base-user'

const styles = theme => ({
  root: {
    flexGrow: 1,
    maxWidth: theme.breakpoints.values.sm,
  },
  actionIcon: {
    cursor: 'pointer',
  },
  emptyEntity: {
    textTransform: 'uppercase',
    fontWeight: 'bold',
  },
  header: {
    [theme.breakpoints.down('sm')]: {
      marginLeft: 16,
      marginRight: 16,
    },
  },
  textButton: {
    fontWeight: 'bold',
    fontSize: '14px',
    paddingTop: '4px',
    paddingBottom: '4px',
    marginBottom: '-6px',
    marginLeft: '-8px',
    textTransform: 'none',
  },
  listButton: {
    fontWeight: 'bold',
    textTransform: 'none',
    margin: '-16px',
    padding: '16px',
    display: 'flex',
    justifyContent: 'space-between',
    '& span': {
      width: 'unset',
      marginLeft: '8px',
    },
  },
})

class CoreDetailView extends React.PureComponent {
  static propTypes = {
    children: PropTypes.node,
    title: PropTypes.string,
    description: PropTypes.string,
    classes: PropTypes.object,
  }

  render() {
    const {
      children,
      title,
      description,
      contentPadding,
      classes,
      hideProfilePaperOnMobile,
      backLink = '/profile',
    } = this.props

    return (
      <WrapperContainer small>
        <Grid item xs={12}>
          <Box className={classes.header}>
            <Box
              display="flex"
              justifyContent="flex-start"
              alignItems="center"
              mt={3.5}
            >
              <Box mr={1.75}>
                <Link
                  to={backLink}
                  style={{ textDecoration: 'none', color: 'unset' }}
                >
                  <ArrowBackIcon />
                </Link>
              </Box>
              <Page.Title ml={2}>{title}</Page.Title>
            </Box>
            <Box my={3}>
              <Page.Description className="mt3">{description}</Page.Description>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} className="q-detailView-lineItem">
          <Paper
            padding={contentPadding}
            hideOnMobile={hideProfilePaperOnMobile}
          >
            <Box display="flex" flexDirection="column">
              {children}
            </Box>
          </Paper>
        </Grid>
      </WrapperContainer>
    )
  }
}

const DetailView = compose(withStyles(styles), injectIntl)(CoreDetailView)

const Title = ({
  title,
  typography,
  iconSize = 30,
  classes,
  emptyMessage,
  onEditClick,
  onAddClick,
  onDeleteClick,
  canDelete = true,
}) => {
  const emptyClasses = classNames(!title && classes.emptyEntity)

  const flowType = 'detailView'
  const baseEventTag = `${flowType}.${title}`

  const titleNotEmpty = !isEmpty(title)

  const Title = props =>
    typography === 'subtitle1' ? (
      <DetailTitle {...props} />
    ) : (
      <Page.Description {...props} />
    )

  return (
    <div className="flex justify-between items-center">
      {titleNotEmpty ? (
        <Title data-q={title}>{title}</Title>
      ) : (
        <ButtonBase
          disableRipple
          onClick={() => {
            analyticsProvider.sendAnalytics({
              type: 'event',
              action: 'click',
              event_category: 'button',
              event_label: `${baseEventTag}.add`,
              value: 0,
            })
            onAddClick()
          }}
        >
          <Page.Description className={emptyClasses} data-q={emptyMessage}>
            {emptyMessage}
          </Page.Description>
        </ButtonBase>
      )}

      <Box display="flex">
        {onEditClick && (
          <Box my={-1.5} mr={!canDelete && -1.5}>
            <IconButton
              aria-label="edit"
              onClick={() => {
                analyticsProvider.sendAnalytics({
                  type: 'event',
                  action: 'click',
                  event_category: 'button',
                  event_label: `${baseEventTag}.edit`,
                  value: 0,
                })
                onEditClick()
              }}
              size="large"
            >
              <EditIcon
                style={{ fontSize: iconSize }}
                className={classes.actionIcon}
              />
            </IconButton>
          </Box>
        )}

        {onDeleteClick && canDelete && (
          <Box mr={-1.5} my={-1.5}>
            <IconButton
              aria-label="delete"
              onClick={() => {
                analyticsProvider.sendAnalytics({
                  type: 'event',
                  action: 'click',
                  event_category: 'button',
                  event_label: `${baseEventTag}.delete`,
                  value: 0,
                })
                onDeleteClick()
              }}
              size="large"
            >
              <DeleteIcon
                style={{ fontSize: iconSize }}
                className={classes.actionIcon}
              />
            </IconButton>
          </Box>
        )}
      </Box>
    </div>
  )
}

const Verification = ({
  pendingOrActivatingEntity,
  pendingMessage,
  onVerifyOpen,
  verifyLabel,
  intl,
  classes,
}) => {
  const flowType = 'detailView'
  const baseEventTag = `${flowType}.${pendingOrActivatingEntity}`
  return (
    <div>
      {pendingOrActivatingEntity && (
        <div>
          <Caption mb="10px" color="text.secondary">
            {pendingMessage}
          </Caption>
          <UserButton
            variant="text"
            disableRipple
            className={classes.textButton}
            onClick={() => {
              analyticsProvider.sendAnalytics({
                type: 'event',
                action: 'click',
                event_category: 'button',
                event_label: `${baseEventTag}.verify`,
                value: 0,
              })
              onVerifyOpen()
            }}
          >
            {verifyLabel || intl.formatMessage({ id: 'common.button.verify' })}
          </UserButton>
        </div>
      )}
    </div>
  )
}

const AddIdentifier = ({ type, onVerifyOpen, classes, intl }) => {
  const flowType = 'detailView'
  const baseEventTag = `${flowType}.add${capitalize(type)}`
  return (
    <UserButton
      variant="text"
      disableRipple
      className={classes.listButton}
      alignment="left"
      onClick={() => {
        analyticsProvider.sendAnalytics({
          type: 'event',
          action: 'click',
          event_category: 'button',
          event_label: baseEventTag,
          value: 0,
        })
        onVerifyOpen()
      }}
    >
      {intl.formatMessage({ id: `profile.detail.${type}.add-${type}` })}
    </UserButton>
  )
}

DetailView.Title = withStyles(styles)(Title)
DetailView.Verification = compose(withStyles(styles), injectIntl)(Verification)
DetailView.AddIdentifier = compose(
  withStyles(styles),
  injectIntl,
)(AddIdentifier)

export default DetailView
