import React, { useReducer, useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { connect } from 'react-redux'
import classNames from 'classnames'
import { get, isEmpty } from 'lodash'
import { injectIntl } from 'react-intl'
import { useIntl } from 'react-intl'
import TextField from 'common/components/textfield/TextField'
import PasswordField from 'common/components/textfield/PasswordField'
import { Button } from 'common/components/button/Button'
import SocialMediaButtons from 'common/components/socialMediaButton'
import VerifyUsername from './VerifyUsername'
import ResultVerifyUsername from './ResultVerifyUsername'
import { getUsernameValidationErrorSmart } from 'common/utils/getUsernameValidationError'
import stripPhone from 'common/utils/stripPhone'
import { startSession, checkSession } from 'common/actions/session'
import { loginWithSocialMedia } from 'common/actions/socialMedia'
import enrollUser from 'common/processes/enrollUser'
import NameFields from 'common/components/nameField/NameFields'
import Checkbox from 'common/components/checkBox/CheckBox'
import CheckMarkIcon from 'common/components/CheckMarkIcon'
import { constants } from 'common/components/Constants'
import FeatureFlagsService, {
  hasUIFeatureFlag,
  getNameFormat,
  userFieldVisible,
} from 'common/services/FeatureFlagsService'

import styles from '../styles.module.css'
import Collapse from '@mui/material/Collapse'
import Box from '@mui/material/Box'
import Link from '@mui/material/Link'
import { Link as RouterLink } from 'react-router-dom'
import { UserTextLink } from '@admin-ui-common/base-user'
import { analyticsProvider } from '../../App'
import FlowLayout from 'common/layouts/FlowLayout'
import reducer from './reducer'
import ACTIONS from './actions'
import {
  useEmailValidator,
  usePhoneValidator,
} from 'common/components/hooks/validators'
import { BackendErrorNotification } from 'common/components/backendErrorNotification/BackendErrorNotification'
import { isEmailOnly, isPhoneOnly } from 'common/utils/identifier-utils'
import useMainRedirect from 'common/components/hooks/useMainRedirect'
import {
  getOperationErrorCodes,
  isAlreadyExistingError,
  formatErrorMessage,
} from 'common/utils/processBackendErrors'

const getInitialState = props => {
  return {
    step: null,
    verified: true,
    credential: '',
    firstName: '',
    lastName: '',
    displayName: '',
    username: '',
    usernameType: '',
    pkat: '',
    tokenId: null,
    response: null,
    errors: {},
    isLoading: false,
    errorMessages: {
      firstName: props.intl.formatMessage({ id: 'error.firstname' }),
      lastName: props.intl.formatMessage({ id: 'error.lastname' }),
      displayName: props.intl.formatMessage({
        id: 'error.display-name',
      }),
      username: props.intl.formatMessage({ id: 'error.username' }),
      credential: props.intl.formatMessage({ id: 'error.credential' }),
    },
    termsAndConditions: false,
    errorResponseBody: null,
  }
}

const hasFeatureFlag = feature =>
  FeatureFlagsService.get(`idpPortal.signUp.${feature}`)

const isDisplayNameRequired = () => {
  const isUserFieldVisible = userFieldVisible('displayName')
  const displayNameFieldRequired = hasUIFeatureFlag('displayNameFieldRequired')

  return displayNameFieldRequired && isUserFieldVisible
}

const SignUp = props => {
  const [state, dispatch] = useReducer(reducer, getInitialState(props))
  const isValidEmail = useEmailValidator()
  const isValidPhone = usePhoneValidator()
  const mainRedirect = useMainRedirect()
  const location = useLocation()
  const { hash } = location
  const currentPath = location.pathname
  const intl = useIntl()
  const pageTitle = 'signUp'

  const baseEventTag = `signup.${pageTitle}`

  const allowedIdentifiers = FeatureFlagsService.get(
    'uiFeatureFlags.idp.allowedOnboardingIdentifiers',
  )
  const termsOfServiceURL = FeatureFlagsService.get('system.termsOfServiceURL')
  const privacyPolicyURL = FeatureFlagsService.get('system.privacyPolicyURL')
  const promptCaptcha = FeatureFlagsService.get('system.security.promptCaptcha')

  const isEmailIdentifierOnly = isEmailOnly(allowedIdentifiers)
  const isPhoneIdentifierOnly = isPhoneOnly(allowedIdentifiers)

  const isSocialOnboardingAllowed = allowedIdentifiers.includes('social')

  const usernameLabelKey = (() => {
    if (isEmailIdentifierOnly) {
      return 'separatedSingleStep.onboard-enterDetails.label.email'
    } else if (isPhoneIdentifierOnly) {
      return 'separatedSingleStep.onboard-enterDetails.label.mobile'
    } else {
      return 'separatedSingleStep.onboard-enterDetails.label.emailOrMobile'
    }
  })()

  useEffect(() => {
    analyticsProvider.sendAnalytics({
      type: 'page_view',
      page_title: pageTitle,
      page_path: `/signup#${pageTitle}`,
    })
  }, [])

  const validate = fieldName => event => {
    let { errors } = state
    let value = event.target.value.trim()
    switch (fieldName) {
      case 'username':
        const usernameError = getUsernameValidationErrorSmart(
          intl,
          value,
          allowedIdentifiers,
          isValidEmail,
          isValidPhone,
        )
        errors[fieldName] = usernameError
        break

      case 'displayName':
      case 'firstName':
      case 'lastName':
        if (value === '' && isDisplayNameRequired()) {
          errors[fieldName] = state.errorMessages[fieldName]
        } else {
          errors[fieldName] = ''
        }
        break

      default:
        if (value === '') {
          errors[fieldName] = state.errorMessages[fieldName]
        } else {
          errors[fieldName] = ''
        }
        break
    }

    dispatch({
      type: ACTIONS.SIGN_UP_UPDATE_ERRORS,
      payload: {
        errors,
      },
    })
  }

  const handleNameChange = nameType => event => {
    dispatch({
      type: ACTIONS.SIGN_UP_UPDATE_NAME,
      payload: { nameType, value: event.target.value },
    })
  }

  const handleUsernameChange = event => {
    dispatch({
      type: ACTIONS.SIGN_UP_UPDATE_USERNAME,
      payload: { username: event.target.value },
    })
  }

  const handlePasswordChange = event => {
    dispatch({
      type: ACTIONS.SIGN_UP_UPDATE_PASSWORD,
      payload: { credential: event.target.value },
    })
  }

  const handleVerified = (verified, response) => {
    const state = {
      step: 'ResultVerifyUsername',
      verified,
    }

    if (!isEmpty(response)) {
      const userAuthenticated = get(response, 'body.userAuthenticated')
      state.response = response

      if (userAuthenticated) {
        state.userAuthenticated = userAuthenticated
      }
    }

    dispatch({
      type: ACTIONS.SIGN_UP_HANDLE_VERIFIED,
      payload: state,
    })
    history.push(`${currentPath}#ResultVerifyUsername`)
  }

  const handleRedirect = () => {
    const { metadata, body } = state.response
    const isAuthProcessComplete = Boolean(
      body.userId || body.lastStep || body.userAuthenticated,
    )

    if (isAuthProcessComplete) {
      const isStaticRedirect = mainRedirect(metadata)
      if (!isStaticRedirect) {
        return
      }
    }
    props.checkSession()
  }

  const goHome = () => props.history.push('/')

  const resetStep = () => {
    dispatch({
      type: ACTIONS.SIGN_UP_RESET_STEP,
      payload: getInitialState(props),
    })
  }

  const onError = err => {
    const errorCodes = getOperationErrorCodes(err.body)

    if (errorCodes.some(isAlreadyExistingError)) {
      dispatch({
        type: ACTIONS.SIGN_UP_UPDATE_STATE,
        payload: {
          errors: {
            username: intl.formatMessage(
              {
                id: 'sign-up.id-exists',
              },
              {
                'sign-in-link': str => (
                  <RouterLink
                    to={{
                      pathname: '/',
                      search: window.location.search,
                      state: { usernameEntered: username },
                    }}
                  >
                    <UserTextLink underline="always">{str}</UserTextLink>
                  </RouterLink>
                ),
              },
            ),
          },
          isLoading: false,
        },
      })
      return
    }
    dispatch({
      type: ACTIONS.SIGN_UP_UPDATE_STATE,
      payload: {
        errorResponseBody: err.body,
        isLoading: false,
      },
    })
  }
  const isFullName = () => getNameFormat() === constants.FULL_NAME

  const signUp = event => {
    event.preventDefault()

    analyticsProvider.sendAnalytics({
      type: 'event',
      action: 'click',
      event_category: 'button',
      event_label: `${baseEventTag}.sign-up-btn`,
      value: 0,
    })

    const errors = {}
    const { locale } = props

    const credential = state.credential.trim()
    if (!credential) {
      errors.credential = state.errorMessages.credential
    }

    const names = {}

    if (isFullName()) {
      names.displayName = state.displayName.trim()
    } else {
      names.firstName = state.firstName.trim()
      names.lastName = state.lastName.trim()
    }

    if (isDisplayNameRequired()) {
      if (isFullName()) {
        if (!names.displayName) {
          errors.displayName = state.errorMessages.displayName
        }
      } else {
        if (!names.firstName) {
          errors.firstName = state.errorMessages.firstName
        }
        if (!names.lastName) {
          errors.lastName = state.errorMessages.lastName
        }
      }
    }

    let username = state.username.trim()
    const usernameError = getUsernameValidationErrorSmart(
      intl,
      username,
      allowedIdentifiers,
      isValidEmail,
      isValidPhone,
    )
    if (!username) {
      errors.username = state.errorMessages.username
    } else if (!isEmpty(usernameError)) {
      errors.username = usernameError
    }

    if (Object.keys(errors).length) {
      analyticsProvider.sendAnalytics({
        type: 'event',
        action: 'click',
        event_category: 'button',
        event_label: `${baseEventTag}.sign-up-btn`,
        value: 1,
      })
      dispatch({ type: ACTIONS.SIGN_UP_UPDATE_ERRORS, payload: { errors } })
      return Promise.resolve()
    }

    dispatch({
      type: ACTIONS.SIGN_UP_UPDATE_STATE,
      payload: {
        errors: {},
        errorResponseBody: null,
        isLoading: true,
      },
    })

    let usernameType = allowedIdentifiers[0] ?? 'email'
    if (allowedIdentifiers?.includes?.('email') && isValidEmail(username)) {
      usernameType = 'email'
    } else if (
      allowedIdentifiers?.includes?.('phone') &&
      isValidPhone(username)
    ) {
      usernameType = 'phone'
      username = stripPhone(username)
    }

    const getDisplayName = () => {
      if (names.displayName) return names.displayName

      if (names.firstName && names.lastName)
        return `${names.firstName} ${names.lastName}`

      if (names.firstName) return names.firstName

      if (names.lastName) return names.lastName

      return null
    }

    enrollUser({
      credential,
      displayName: getDisplayName(),
      email: usernameType === 'email' ? username : '',
      phone: usernameType === 'phone' ? username : '',
      firstName: names.firstName,
      lastName: names.lastName,
      lang: locale,
    })
      .then(res => {
        const pkat = get(res, 'body.output.pkat')
        const tokenId = get(res, 'body.output.tokenId')
        const userAuthenticated = get(res, 'body.userAuthenticated')

        dispatch({
          type: ACTIONS.SIGN_UP_UPDATE_STATE,
          payload: {
            step: 'VerifyUsername',
            response: res,
            pkat,
            tokenId,
            userAuthenticated,
            isLoading: false,
          },
        })
        history.push(`${currentPath}#VerifyUsername`)
      })
      .catch(err => {
        analyticsProvider.sendAnalytics({
          type: 'event',
          action: 'click',
          event_category: 'button',
          event_label: `${baseEventTag}.sign-up-btn`,
          value: 1,
        })
        onError(err)
      })

    dispatch({
      type: ACTIONS.SIGN_UP_UPDATE_STATE,
      payload: {
        username,
        usernameType,
      },
    })
  }

  const handleCheck = event => {
    const termsAndConditions = event.target.checked
    dispatch({
      type: ACTIONS.SIGN_UP_UPDATE_STATE,
      payload: {
        termsAndConditions,
      },
    })
  }
  const usernameTypeField = (() => {
    if (isEmailIdentifierOnly) {
      return { type: 'email', autoComplete: 'email' }
    } else if (isPhoneIdentifierOnly) {
      return { type: 'tel', autoComplete: 'username' }
    } else {
      return { type: 'text', autoComplete: 'username' }
    }
  })()

  const {
    pkat,
    tokenId,
    username,
    usernameType,
    verified,
    termsAndConditions,
    firstName,
    lastName,
    displayName,
    errors,
    userAuthenticated,
  } = state
  const { history } = props

  const values = {
    firstName,
    lastName,
    displayName,
  }
  const socialTextKeys = {
    continue: 'separatedSingleStep.onboard-enterDetails.button.socialProvider',
    alternateOptions:
      'separatedSingleStep.onboard-enterDetails.label.socialProviders-onboard',
  }
  const showTermsAndConditions = hasFeatureFlag('showTermsAndConditions')
  const showNameFields =
    userFieldVisible('displayName') ||
    (userFieldVisible('firstName') && userFieldVisible('lastName'))
  const signInClasses = classNames('mt2', !showTermsAndConditions && 'mt3')
  const getVerifyUserFlowSubStep = usernameType => {
    switch (usernameType) {
      case 'email':
        return 'verifyEmailOTP'
      case 'phone':
        return 'verifyMobileOTP'
      default:
        return ''
    }
  }

  const verifyUserFlowSubStep = getVerifyUserFlowSubStep(usernameType)

  const onCancel = () => {
    analyticsProvider.sendAnalytics({
      type: 'event',
      action: 'click',
      event_category: 'button',
      event_label: `${state.step}.cancel`,
      value: 0,
    })

    resetStep()
  }

  if (
    (hash === '#VerifyUsername' || hash === '#ResultVerifyUsername') &&
    !pkat
  ) {
    props.history.push('/signup')
  }

  if (hash === '#VerifyUsername') {
    return (
      <FlowLayout
        title={intl.formatMessage({
          id: `separatedSingleStep.onboard-${verifyUserFlowSubStep}.title`,
        })}
      >
        <VerifyUsername
          inline
          reset={resetStep}
          process="sign-up"
          username={username}
          type={usernameType}
          actionName="onboardUser"
          pkat={pkat}
          tokenId={tokenId}
          onVerified={handleVerified}
          history={history}
          onCancel={onCancel}
        />
      </FlowLayout>
    )
  }
  if (hash === '#ResultVerifyUsername') {
    const type =
      usernameType === 'email'
        ? intl.formatMessage({ id: 'verify-result.email' })
        : intl.formatMessage({ id: 'verify-result.mobile' })
    return (
      <FlowLayout
        title={
          !verified
            ? intl.formatMessage({ id: 'verify-result.you-almost-set' })
            : intl.formatMessage(
                { id: 'separatedSingleStep.onboard-success.title' },
                { type },
              )
        }
        image={verified ? CheckMarkIcon : ''}
      >
        <ResultVerifyUsername
          verificationType={usernameType}
          verified={verified}
          onConfirm={userAuthenticated ? handleRedirect : goHome}
          userAuthenticated={userAuthenticated}
        />
      </FlowLayout>
    )
  }
  return (
    <FlowLayout
      title={intl.formatMessage({
        id: 'separatedSingleStep.onboard-enterDetails.title',
      })}
      subtitle={
        <span>
          {intl.formatMessage(
            { id: 'separatedSingleStep.onboard-enterDetails.subtitle' },
            {
              signin: str => (
                <RouterLink to="/">
                  <UserTextLink underline="always">{str}</UserTextLink>
                </RouterLink>
              ),
            },
          )}
        </span>
      }
      recaptchaTerms="separatedSingleStep.onboard-enterDetails.caption.reCAPTCHA"
      showCaptcha={promptCaptcha}
    >
      <form>
        <Collapse in={!!state.errorResponseBody}>
          <Box mb={3}>
            <BackendErrorNotification
              intl={intl}
              subTitle={
                <div>
                  {formatErrorMessage({
                    intl,
                    id: 'error.credential-at-least',
                  })}
                </div>
              }
              error={state.errorResponseBody}
              hideClose
              prioritize={['NotReusedPasswordByTime', 'NotReusedPassword']}
              showAsSingle={['NotReusedPasswordByTime', 'NotReusedPassword']}
            />
          </Box>
        </Collapse>
        <TextField
          errorText={state.errors.username}
          label={intl.formatMessage({ id: usernameLabelKey })}
          onChange={handleUsernameChange}
          value={state.username}
          onBlur={validate('username')}
          type={usernameTypeField.type}
          autoComplete={usernameTypeField.autoComplete}
        />
        <div className={styles.fieldMB} />
        {showNameFields && (
          <NameFields
            className={classNames(
              { [styles.nameFieldWidth]: !isFullName() },
              'mb2',
            )}
            nameFormat={getNameFormat()}
            labelKeys={{
              firstName:
                'separatedSingleStep.onboard-enterDetails.label.givenName',
              lastName:
                'separatedSingleStep.onboard-enterDetails.label.familyName',
            }}
            fullWidth={isFullName()}
            errorTexts={errors}
            onChange={handleNameChange}
            values={values}
            onBlur={validate}
          />
        )}
        <div className={styles.fieldMB} />
        <PasswordField
          label={intl.formatMessage({
            id: 'separatedSingleStep.onboard-enterDetails.label.password',
          })}
          errorText={state.errors.credential}
          onChange={handlePasswordChange}
          value={state.credential}
          onBlur={validate('credential')}
          autoComplete="new-password"
        />
        {showTermsAndConditions && (
          <div className="mt2">
            <Checkbox
              alignTop
              label={intl.formatMessage(
                {
                  id: 'separatedSingleStep.onboard-enterDetails.caption.legal',
                },
                {
                  'terms-link': str =>
                    termsOfServiceURL ? (
                      <Link href={termsOfServiceURL} target="_blank">
                        {str}
                      </Link>
                    ) : (
                      str
                    ),
                  'privacy-link': str =>
                    privacyPolicyURL ? (
                      <Link href={privacyPolicyURL} target="_blank">
                        {str}
                      </Link>
                    ) : (
                      str
                    ),
                },
              )}
              checked={termsAndConditions}
              onChange={handleCheck}
              value="termsAndConditions"
            />
          </div>
        )}
        <div className={signInClasses}>
          <Button
            size="large"
            isLoading={state.isLoading}
            onClick={signUp}
            disabled={!termsAndConditions}
          >
            {intl.formatMessage({
              id: 'separatedSingleStep.onboard-enterDetails.button.onboard',
            })}
          </Button>
        </div>
      </form>
      {isSocialOnboardingAllowed && (
        <SocialMediaButtons textKeys={socialTextKeys} intl={intl} />
      )}
    </FlowLayout>
  )
}

const mapDispatch = {
  startSession,
  checkSession,
  loginWithSocialMedia,
}

export default connect(null, mapDispatch)(injectIntl(SignUp))
