import get from 'lodash/get'

export const getProcessResponse = state => ({
  userAuthenticated: get(state, 'userAuthenticated'),
  processId: get(state, 'processId'),
})

export const getUser = state => ({
  name: get(state, 'output.displayName'),
  email: get(state, 'output.emailAddress'),
})

export const getMembers = state => get(state, 'output.usersInGroup') || []

export const getGroupName = state => get(state, 'output.groupName')
