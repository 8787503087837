import React from 'react'
import { isNull } from 'lodash'

const withReCaptcha = () => WrappedComponent =>
  class extends React.Component {
    state = {
      recaptcha: null,
      captchaRequired: true,
      recaptchaForceRerenderingKey: 0,
    }

    onChangeRecaptcha = recaptcha => this.setState({ recaptcha })

    toggleRecaptcha = () =>
      this.setState(prev => ({
        recaptchaForceRerenderingKey: prev.recaptchaForceRerenderingKey + 1,
      }))

    resetRecaptcha = ref => {
      if (isNull(ref)) {
        return
      }

      const recaptchaDom = ref.captcha

      while (recaptchaDom.firstChild) {
        recaptchaDom.removeChild(recaptchaDom.firstChild)
      }

      ref.reset()
    }

    render() {
      return (
        <WrappedComponent
          onChangeRecaptcha={this.onChangeRecaptcha}
          toggleRecaptcha={this.toggleRecaptcha}
          resetRecaptcha={this.resetRecaptcha}
          {...this.props}
          {...this.state}
        />
      )
    }
  }

export default withReCaptcha
