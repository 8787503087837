import React, { useState, useEffect, useCallback } from 'react'
import { useDispatch, connect } from 'react-redux'
import RwdPaper from 'common/components/RwdPaper'
import {
  checkDevice,
  approveDevice,
  deviceAuthError,
} from 'common/actions/deviceAuth'
import Page from 'common/components/Page'
import { Box } from '@mui/material'
import FailIcon from '@mui/icons-material/HighlightOff'
import checkedIcon from './assets/check-circle-outline.png'
import styled from 'styled-components'
import { injectIntl } from 'react-intl'
import { withRouter } from 'react-router-dom'
import {
  getErrorCodes,
  formatErrorTitle,
  formatErrorMessage,
} from 'common/utils/processBackendErrors'
import get from 'lodash/get'
import BasicLayout from 'common/layouts/BasicLayout'

const StyledIcon = styled(Box)`
  width: 96px;
`

const WrapperStyled = styled(RwdPaper)`
  padding: 32px 40px 24px;
`

const FailIconStyled = styled(FailIcon)`
  &.MuiSvgIcon-root {
    font-size: 112px;
    color: ${props => get(props, 'theme.palette.error.dark')};
  }
`

const DeviceConnection = ({
  intl,
  userCode,
  isDeviceLoading,
  deviceError,
  ...rest
}) => {
  // state to be replaced with BE wiring
  const [infoDegredation] = useState(true)
  const errorCodes = deviceError ? getErrorCodes(deviceError.body) : null

  const dispatch = useDispatch()

  const stepSession = useCallback(async () => {
    try {
      userCode
        ? await dispatch(approveDevice(userCode))
        : await dispatch(checkDevice())
    } catch (error) {
      dispatch(deviceAuthError(error))
    }
  }, [dispatch, userCode])

  useEffect(() => {
    stepSession()
  }, [userCode, stepSession])

  if (isDeviceLoading) {
    return null
  }

  if (!deviceError) {
    return (
      <WrapperStyled>
        <StyledIcon component="img" src={checkedIcon} />
        <Page.Title py={4}>
          {intl.formatMessage({
            id: 'device-authorization.success.title',
          })}
        </Page.Title>
        <Page.Body>
          {intl.formatMessage(
            {
              id: infoDegredation
                ? 'device-authorization.success.body-no-device'
                : 'device-authorization.success.body-with-device',
            },
            {
              deviceModel: 'Apple',
              serviceProviderName: 'AT&T Mexico',
            },
          )}
        </Page.Body>
        <Page.Body>
          {intl.formatMessage(
            {
              id: infoDegredation
                ? 'device-authorization.success.next-steps-no-device'
                : 'device-authorization.success.next-steps-with-device',
            },
            {
              deviceType: 'smartphone',
            },
          )}
        </Page.Body>
      </WrapperStyled>
    )
  }

  if (errorCodes.includes('invalid-act-consumption')) {
    return (
      <WrapperStyled>
        <FailIconStyled {...rest} />
        <Page.Title py={4}>
          {formatErrorTitle({
            intl,
            id: 'error.invalid-act-consumption.title.context.deviceConnection',
          })}
        </Page.Title>
        <Page.Body>
          {formatErrorMessage({
            intl,
            id: 'error.invalid-act-consumption.context.deviceConnection',
          })}
        </Page.Body>
      </WrapperStyled>
    )
  }

  return (
    <WrapperStyled>
      <FailIconStyled {...rest} />
      <Page.Title py={4}>
        {intl.formatMessage({
          id: 'device-authorization.failure.title',
        })}
      </Page.Title>
      <Page.Body>
        {intl.formatMessage({
          id: 'device-authorization.failure.body',
        })}
      </Page.Body>
    </WrapperStyled>
  )
}

function PageWrapper(props) {
  return (
    <BasicLayout>
      <DeviceConnection {...props} />
    </BasicLayout>
  )
}

const mapStateToProps = state => ({
  userCode: state.deviceAuth.userCode,
  isDeviceLoading: state.deviceAuth.isDeviceLoading,
  deviceError: state.deviceAuth.deviceError,
})
const mapDispatchToProps = {}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(injectIntl(PageWrapper)))
