import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import FlowLayout from 'common/layouts/FlowLayout'
import { UserButton } from '@admin-ui-common/base-user'
import { Box, Typography } from '@mui/material'
import Page from '../../common/components/Page'
import FormControl from '@mui/material/FormControl'
import RadioGroup from '@mui/material/RadioGroup'
import Collapse from '@mui/material/Collapse'
import { get, isEmpty, reduce } from 'lodash'
import BackdropLoader from '../../common/components/BackdropLoader'
import { useIntl } from 'react-intl'
import TextField from '../../common/components/textfield/TextField'
import FormControlLabel from '@mui/material/FormControlLabel'
import { DISCOVERY_TYPES } from '../../common/constants/account'
import Radio from '@mui/material/Radio'
import { BackendErrorNotification } from 'common/components/backendErrorNotification/BackendErrorNotification'
import { Body } from '../../common/components/Typography'
import { useSelector } from 'react-redux'
import { analyticsProvider } from '../../App'
import process from '../../common/utils/process'

const ControlLabel = ({
  value,
  onChange,
  discoveryType,
  selectedType,
  onlyLabel,
  intl,
}) => {
  const label = intl.formatMessage({
    id: `accounts.discovery-type.${discoveryType}`,
  })
  const labelPlaceholder = intl.formatMessage({
    id: `accounts.discovery-type.${discoveryType}.placeholder`,
  })

  if (onlyLabel)
    return (
      <>
        <TextField
          label={labelPlaceholder}
          value={value}
          onChange={onChange}
          fullWidth
          autoFocus
        />
        <Box mb={1} />
      </>
    )

  return (
    <>
      <FormControlLabel
        key={DISCOVERY_TYPES[discoveryType]}
        value={DISCOVERY_TYPES[discoveryType]}
        control={<Radio color="primary" />}
        label={
          <Body
            mb={0}
            color={
              discoveryType === selectedType ? 'textPrimary' : 'textSecondary'
            }
          >
            {label}
          </Body>
        }
      />
      <Collapse in={discoveryType === selectedType}>
        <TextField
          label={labelPlaceholder}
          value={value}
          onChange={onChange}
          fullWidth
          autoFocus={discoveryType === selectedType}
        />
        <Box mb={1} />
      </Collapse>
    </>
  )
}

const EnforceAccountAssociationStep = ({
  wizard,
  getPageIndex,
  pages,
  initialData,
  preserveQueryArguments = true,
}) => {
  const history = useHistory()

  const onCancel = () => {
    let signInRoute = '/signin'
    const params =
      history.location.search !== ''
        ? `/${history.location.search.split('&')[0]}`
        : ''
    if (preserveQueryArguments) signInRoute = signInRoute + params
    history.push(signInRoute)
  }

  const stepData = initialData || wizard.getPageState()

  const intl = useIntl()

  const [value, setValue] = useState('')
  const [errorResponseBody, setErrorResponseBody] = useState(null)

  const discoverAccountsBySetting = useSelector(
    state => state.settings.publicSettings['process.discoverAccountsBy'],
  )

  const discoverySettings = discoverAccountsBySetting
    .split(',')
    .map(identifier => identifier.trim())

  const [discoveryType, setDiscoveryType] = useState(discoverySettings[0])
  const [inProgress, setInProgress] = useState(false)

  const onHandleValue = e => setValue(e.target.value)

  const onChangeDiscoveryType = e => {
    setValue('')
    setDiscoveryType(e.target.value)
  }

  const performStep = payload => {
    wizard.setPageState(payload)
    wizard.toPage(getPageIndex(pages.AccountAssociationOtpStep))
  }

  const onDiscoverAccounts = event => {
    event.preventDefault()

    setErrorResponseBody(null)

    analyticsProvider.sendAnalytics({
      type: 'event',
      action: 'click',
      event_category: 'button',
      event_label: `enforce-account-association.discover-accounts`,
      value: 0,
    })

    setInProgress(true)

    const nextStep = { ...stepData }

    nextStep.parameters = {
      accountIdentifier: value,
    }

    process
      .step(nextStep)
      .then(res => {
        const stepData = res.body
        const discoveredAccounts = reduce(
          get(stepData, 'output.loadedAccounts', {}),
          (result, account, accountName) => {
            result.push({
              ...account,
              accountName,
            })
            return result
          },
          [],
        )
        const isLastStep = get(stepData, 'lastStep')

        if (isLastStep) {
          const payload = {
            enterOTPEnabled: true,
            discoveredAccounts,
            accountNumber: value,
            stepData,
          }

          performStep(payload)
          return
        }

        setDiscoveryType(null)

        const nextStep = res.body
        performStep({
          stepData: nextStep,
        })
      })
      .catch(err => {
        analyticsProvider.sendAnalytics({
          type: 'event',
          action: 'click',
          event_category: 'button',
          event_label: `enforce-account-association.discover-accounts`,
          value: 1,
        })
        setErrorResponseBody(err.body)
      })
      .finally(() => setInProgress(false))
  }

  return (
    <FlowLayout
      title={intl.formatMessage({ id: 'accounts.link-accounts.button' })}
    >
      <form onSubmit={onDiscoverAccounts}>
        <Box mb={1}>
          <Page.Description>
            {intl.formatMessage({ id: 'accounts.associate.must-be-customer' })}
          </Page.Description>
        </Box>
        <Box mb={4}>
          <Page.Description>
            {intl.formatMessage({ id: 'accounts.search-accounts' })}
          </Page.Description>
        </Box>
        {discoverySettings.length > 1 && (
          <Typography color="textSecondary" variant="subtitle2">
            {intl.formatMessage({ id: 'accounts.search-method' })}
          </Typography>
        )}
        <Box mb="7px" />
        <FormControl component="fieldset" fullWidth>
          <RadioGroup value={discoveryType} onChange={onChangeDiscoveryType}>
            {discoverySettings.map(type => (
              <ControlLabel
                value={value}
                onChange={onHandleValue}
                discoveryType={type}
                selectedType={discoveryType}
                onlyLabel={discoverySettings.length === 1}
                intl={intl}
              />
            ))}
          </RadioGroup>
        </FormControl>

        <Collapse in={!!errorResponseBody}>
          <Box mt={2}>
            <BackendErrorNotification
              intl={intl}
              error={errorResponseBody}
              hideClose
            />
          </Box>
        </Collapse>

        <Box display="inline-block" marginRight={2} marginTop={4}>
          <UserButton
            disableRipple
            disableElevation
            onClick={onDiscoverAccounts}
            disabled={inProgress || isEmpty(value)}
          >
            {intl.formatMessage({ id: 'common.button.continue' })}
          </UserButton>
        </Box>
        <Box display="inline-block" marginTop={4}>
          <UserButton
            color="secondary"
            variant="outlined"
            disableRipple
            disableElevation
            onClick={onCancel}
            disabled={inProgress}
          >
            {intl.formatMessage({ id: 'common.button.cancel' })}
          </UserButton>
        </Box>
      </form>
      <BackdropLoader
        open={inProgress}
        label={intl.formatMessage({ id: 'accounts.searching-for-accounts' })}
      />
    </FlowLayout>
  )
}

export default EnforceAccountAssociationStep
