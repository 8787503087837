import { get } from 'lodash'

export const getPublicSettings = state =>
  get(state, 'settings.publicSettings', {})

export const getSocialMediaInfo = state =>
  get(
    state,
    ['settings', 'publicSettings', 'system.security.socialProviders'],
    [],
  )
