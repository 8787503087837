import { useIntl } from 'react-intl'
import duration from 'common/utils/duration'

const useDuration = () => {
  const intl = useIntl()

  return durationString => {
    if (!durationString) {
      return ''
    }

    const [unit, amount] = duration(durationString)

    if (!unit || !amount) {
      return ''
    }

    const intlKey = amount > 1 ? 'common.duration-plural' : 'common.duration'

    return intl.formatMessage(
      { id: intlKey },
      {
        amount,
        unit,
      },
    )
  }
}

export default useDuration
