import { useLocation, useHistory } from 'react-router-dom'
import { get } from 'lodash'

/**
 * Redirect back to origin caller page from ForgotPassword flow
 * @returns {Function}
 */
const useOriginRedirect = () => {
  const history = useHistory()
  const location = useLocation()

  return () => {
    const { originalRedirect, viewId, mobile, originProcessState } = get(
      location,
      'state',
      {},
    )

    // User landed to Forgot Password view from another one (i.e. SignIn)
    if (viewId) {
      history.push(
        {
          pathname: originalRedirect,
          search: location.search,
        },
        {
          // We don't really need originProcessState because origin view will initiate
          // process again
          originProcessState,
          viewId,
          mobile,
        },
      )
    } else {
      // Forgot Password view is static
      // This is an indicator that this is static redirect to first view in that flow
      return true
    }
  }
}

export default useOriginRedirect
