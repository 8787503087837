import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import { Snackbar } from '@admin-ui-common/base-user'

import {
  addNotification,
  dismissNotification,
} from 'common/actions/notification'

const anchorOrigin = {
  vertical: 'top',
  horizontal: 'right',
}

const NOTIFICATION_DURATION_MS = 3000

class IdpNotification extends React.Component {
  static propTypes = {
    dismissNotification: PropTypes.func,
    notification: PropTypes.object,
  }

  render() {
    const { notification, dismissNotification } = this.props
    const { key, message, variant } = notification || {}

    return (
      <Snackbar
        key={key}
        anchorOrigin={anchorOrigin}
        autoHideDuration={NOTIFICATION_DURATION_MS}
        message={{ key, message }}
        variant={variant}
        TransitionProps={{
          onExited: dismissNotification,
        }}
      />
    )
  }
}

const mapState = ({ notification }) => ({ notification })
const mapDispatch = {
  addNotification,
  dismissNotification,
}
const connectedProps = connect(mapState, mapDispatch)

export default connectedProps(IdpNotification)
