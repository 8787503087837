import {
  REGISTER,
  ENROLL_OR_LOGIN,
  CLEAR_STATE,
} from 'common/actions/socialMedia'

const initState = {
  data: null,
  isLoading: false,
  hasFailed: false,
  error: null,
}

const socialMediaReducer = (state = { ...initState }, action) => {
  switch (action.type) {
    case ENROLL_OR_LOGIN.request:
    case REGISTER.request:
      return { ...state, isLoading: true, hasFailed: false }

    case ENROLL_OR_LOGIN.success:
    case REGISTER.success:
      return {
        ...state,
        isLoading: false,
        hasFailed: false,
        data: action.data,
      }

    case ENROLL_OR_LOGIN.error:
    case REGISTER.error:
      return {
        ...state,
        isLoading: false,
        hasFailed: true,
        error: action.error,
      }

    case CLEAR_STATE:
      return {
        ...initState,
      }

    default:
      return state
  }
}

export default socialMediaReducer
