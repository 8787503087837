import React from 'react'
import classNames from 'classnames'

import withStyles from '@mui/styles/withStyles'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'

const styles = theme => ({
  page: {
    color: theme.palette.text.primary,
    maxWidth: theme.breakpoints.values.md,
    alignSelf: 'center',
    padding: 0,
    margin: 'auto',
  },
  title: {
    fontSize: '2rem',
    [theme.breakpoints.down('sm')]: {
      fontSize: '1.71rem',
    },
  },
  description: {
    fontSize: 16,
  },
  headerTitle: {
    fontSize: '1.14rem',
  },
  body: {
    marginBottom: 24,
    lineHeight: '20px',
    '&.stack': {
      marginBottom: 9,
    },
  },
})

const PageCore = ({ className, classes, ...props }) => (
  <Box
    className={classNames(classes.page, className)}
    classes={{ root: className }}
    {...props}
  />
)
const Page = withStyles(styles)(PageCore)

const Title = ({ classes, className, ...props }) => (
  <Box
    component={Typography}
    variant="h4"
    className={classNames(className)}
    {...props}
  />
)

const Description = ({ className, classes, ...props }) => (
  <Box
    component={Typography}
    variant="body1"
    className={classNames(className)}
    {...props}
  />
)

const HeaderTitle = ({ className, classes, ...props }) => (
  <Box
    component={Typography}
    variant="subtitle1"
    className={classNames(classes.headerTitle, className)}
    {...props}
  />
)

// If paragraphs are stack on of each other, only last one will should
// have full margin bottom - for all paragraphs above should be set prop stack
const Body = ({ className, classes, stack, ...props }) => (
  <Box
    component={Typography}
    variant="body1"
    className={classNames(classes.description, classes.body, className, {
      stack,
    })}
    {...props}
  />
)

Page.Title = withStyles(styles)(Title)
Page.Description = withStyles(styles)(Description)
Page.HeaderTitle = withStyles(styles)(HeaderTitle)
Page.Body = withStyles(styles)(Body)

export default Page
