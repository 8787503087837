import googleIcon from 'common/assets/google-logo.svg'
import googleIconWhite from 'common/assets/google-logo-white.svg'
import facebookIcon from 'common/assets/facebook-logo.svg'
import facebookIconWhite from 'common/assets/facebook-logo-white.svg'
import azureIcon from 'common/assets/microsoft-logo.svg'
import azureIconWhite from 'common/assets/microsoft-logo-white.svg'
import appleIcon from 'common/assets/apple-logo.svg'
import appleIconWhite from 'common/assets/apple-logo-white.svg'
import linkedinIcon from 'common/assets/linked-in-logo.svg'
import linkedinIconWhite from 'common/assets/linked-in-logo-white.svg'
import transparentIcon from 'common/assets/transparent-icon.svg'

import { useTheme } from '@mui/material/styles'

const useSocialProviderIcon = providerId => {
  const theme = useTheme()
  const isLightMode = theme.palette.mode === 'light'
  switch (providerId) {
    case 'google':
      return {
        icon: isLightMode ? googleIcon : googleIconWhite,
        width: '24',
        height: '24',
      }
    case 'facebook':
      return {
        icon: isLightMode ? facebookIcon : facebookIconWhite,
        width: '24',
        height: '24',
      }
    case 'azure':
    case 'microsoft':
      return {
        icon: isLightMode ? azureIcon : azureIconWhite,
        width: '24',
        height: '24',
      }
    case 'apple':
      return {
        icon: isLightMode ? appleIcon : appleIconWhite,
        width: '24',
        height: '24',
      }
    case 'linkedin':
      return {
        icon: isLightMode ? linkedinIcon : linkedinIconWhite,
        width: '24',
        height: '24',
      }
    default:
      return { icon: transparentIcon, width: '24', height: '24' }
  }
}

export default useSocialProviderIcon
