import 'es6-shim'
import 'core-js/stable'
import 'regenerator-runtime/runtime'

import React from 'react'
import ReactDOM from 'react-dom'

import 'sanitize.css'
import './basscss.css'
import './App.css'

import App from './App'
import conf from 'conf'
import isIOS from 'common/utils/isIOS'
import { disableIOSTextFieldZoom } from 'common/utils/disableIOSTextFieldZoom'

import store from './store'
import { checkSession } from './common/actions/session'
import { Provider } from 'react-redux'

console.log(`Launching ${conf.appName} (v${conf.appVersion})`)
window.originalLocationSearch = window.location.search

if (isIOS) {
  disableIOSTextFieldZoom()
}

if (!window.location.href.match('protocol=oidc')) {
  store.dispatch(checkSession())
}

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById('root'),
)

if (window.Cypress) {
  window.store = store
}
