import React from 'react'
import classNames from 'classnames'

import withStyles from '@mui/styles/withStyles'
import Grid from '@mui/material/Grid'
import Fade from '@mui/material/Fade'
import Page from 'common/components/Page'

const styles = theme => ({
  root: {
    flexGrow: 1,
  },
  container: {
    paddingLeft: 24,
    paddingRight: 24,
    [theme.breakpoints.down('sm')]: {
      paddingLeft: 0,
      paddingRight: 0,
    },
  },
  page: {
    maxWidth: 860,
    marginTop: 20,
    [theme.breakpoints.down('md')]: {
      marginTop: 5,
    },
  },
  small: {
    maxWidth: theme.breakpoints.values.sm,
  },
  wrapper: {
    overflowX: 'hidden',
    width: '100%',
  },
})

// https://material-ui.com/components/grid/#limitations
const WrapperContainer = ({ classes, small, children }) => (
  <div className={classes.wrapper}>
    <Grid
      container
      classes={{ container: classes.container }}
      className={classes.root}
    >
      <Grid item lg={2} />
      <Grid item xs={12} lg={8}>
        <Fade in>
          <div>
            <Page className={classNames(classes.page, small && classes.small)}>
              {children}
            </Page>
          </div>
        </Fade>
      </Grid>
      <Grid item lg={2} />
    </Grid>
  </div>
)

export default withStyles(styles)(WrapperContainer)
