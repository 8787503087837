import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { injectIntl } from 'react-intl'
import classNames from 'classnames'
import { withRouter } from 'react-router-dom'
import { compose } from 'redux'

import withStyles from '@mui/styles/withStyles'
import { InlineNotification } from '@admin-ui-common/base-user'

import DetailView from 'protected/common/DetailView'
import { isPasscodeSet } from 'protected/profile/selectors'
import { loadUser, updateAttribute } from 'common/actions/user'

import Box from '@mui/material/Box'
import Collapse from '@mui/material/Collapse'
import { DialogTitle } from 'common/components/Typography'
import { BackendErrorNotification } from 'common/components/backendErrorNotification/BackendErrorNotification'
import { Button, TextSecondaryButton } from 'common/components/button/Button'
import { analyticsProvider } from '../../../App'

import TextField from 'common/components/textfield/TextField'
import { addNotification } from 'common/actions/notification'
import {
  formatErrorTitle,
  formatErrorMessage,
} from 'common/utils/processBackendErrors'

import { withWidth } from 'common/components/hoc/withWidth'
import { performRedirect } from 'common/utils/getRedirectUrl'
import getCurrentSubRouteConfiguration from 'common/utils/getCurrentSubRouteConfiguration'

const styles = () => ({
  passcode: {
    marginTop: 16,
  },
})

class PasscodeDetailView extends React.PureComponent {
  static propTypes = {
    addNotification: PropTypes.func,
    isPasscodeSet: PropTypes.bool,
  }

  constructor(props) {
    super(props)

    this.flowType = 'detailView'
    this.pageTitle = 'passcode_detail_view'
    this.baseEventTag = `${this.flowType}.${this.pageTitle}`
  }

  state = {
    newPasscode: '',
    errors: {
      newPasscode: '',
    },
    inProgress: false,
    errorResponseBody: null,
  }

  sendUpdatePasscodeFailedAnalytics = () => {
    analyticsProvider.sendAnalytics({
      type: 'event',
      action: 'click',
      event_category: 'button',
      event_label: `${this.baseEventTag}.update-passcode`,
      value: 1,
    })
  }

  onPasscodeUpdate = isPasscodeSet => {
    analyticsProvider.sendAnalytics({
      type: 'event',
      action: 'click',
      event_category: 'button',
      event_label: `${this.baseEventTag}.${
        isPasscodeSet ? 'update' : 'set'
      }-passcode`,
      value: 0,
    })

    const { newPasscode } = this.state
    const { intl, addNotification, updateAttribute } = this.props

    this.setState({ errors: {}, errorResponseBody: null })

    if (!newPasscode) {
      this.sendUpdatePasscodeFailedAnalytics()
      this.setState({
        errors: {
          newPasscode: formatErrorTitle({
            intl,
            id: 'error.passcode-not-empty',
          }),
        },
      })
      return
    }

    const requestParams = {
      passcode: newPasscode,
    }

    this.setState({ inProgress: true })

    updateAttribute(requestParams)
      .then(() => {
        this.setState({ inProgress: false }, () => {
          addNotification({
            message: intl.formatMessage({
              id: `notification.passcode-${isPasscodeSet ? 'changed' : 'set'}`,
            }),
            variant: 'success',
          })
        })
      })
      .catch(err => {
        this.sendUpdatePasscodeFailedAnalytics()
        if (err.body?.fieldErrors) {
          err.body.fieldErrors.map(error => {
            if (error.code_v2 === 'max-consecutive-digits') {
              error.code_v2 = 'passcode-max-consecutive-digits'
            }
            return error
          })
        }
        this.setState({
          inProgress: false,
          errorResponseBody: err.body,
        })
      })
  }

  onCancel = () => {
    analyticsProvider.sendAnalytics({
      type: 'event',
      action: 'click',
      event_category: 'button',
      event_label: `${this.baseEventTag}.cancel-passcode`,
      value: 0,
    })
    const { currentSubRoute } = getCurrentSubRouteConfiguration()
    const redirectPath =
      currentSubRoute !== '/'
        ? currentSubRoute + '/security-and-sign-in'
        : '/security-and-sign-in'
    performRedirect(redirectPath)
  }

  handlePasscodeChange = type => e => this.setState({ [type]: e.target.value })

  render() {
    const { intl, classes, isPasscodeSet, backLink } = this.props
    const { newPasscode, errors, inProgress, errorResponseBody } = this.state

    const classesWrapper = classNames('flex flex-column')

    return (
      <DetailView
        title={intl.formatMessage({
          id: 'security-and-sign-in.passcode.title',
        })}
        description={intl.formatHTMLMessage({
          id: 'security-and-sign-in.passcode.description',
        })}
        backLink={backLink}
      >
        <Box mb={2}>
          <DialogTitle>
            {isPasscodeSet
              ? intl.formatMessage({
                  id: 'security-and-sign-in.passcode.label.update-passcode',
                })
              : intl.formatMessage({
                  id: 'security-and-sign-in.passcode.label.set-passcode',
                })}
          </DialogTitle>
        </Box>
        <Collapse in={errors.newPasscode}>
          <Box mb={3}>
            <InlineNotification
              title={errors.newPasscode}
              variant="error"
              handleClose={() => {
                this.setState({ errors: {} })
              }}
            />
          </Box>
        </Collapse>
        <Collapse in={!!errorResponseBody}>
          <Box mb={3}>
            <BackendErrorNotification
              intl={intl}
              subTitle={
                <div>
                  {formatErrorMessage({
                    intl,
                    id: 'error.passcode-at-least.context.change-passcode',
                  })}
                </div>
              }
              error={errorResponseBody}
              handleClose={() => {
                this.setState({
                  errorResponseBody: null,
                })
              }}
              context="change-passcode"
            />
          </Box>
        </Collapse>

        <div className={classesWrapper}>
          <TextField
            className={classes.passcode}
            label={intl.formatMessage({
              id: 'security-and-sign-in.passcode.label.new-passcode',
            })}
            onChange={this.handlePasscodeChange('newPasscode')}
            value={newPasscode}
            helperText={intl.formatMessage({
              id: 'security-and-sign-in.passcode.helperText',
            })}
            fullWidth
            inputType="password"
            numeric
          />
        </div>

        <div className="flex mt3">
          <Box mr={3}>
            <Button
              size="large"
              onClick={() => {
                this.onPasscodeUpdate(isPasscodeSet)
              }}
              isLoading={inProgress}
            >
              {isPasscodeSet
                ? intl.formatMessage({
                    id: 'security-and-sign-in.passcode.button.change-passcode',
                  })
                : intl.formatMessage({
                    id: 'security-and-sign-in.passcode.button.set-passcode',
                  })}
            </Button>
          </Box>
          <Box>
            <TextSecondaryButton
              onClick={this.onCancel}
              size="large"
              variant="outlined"
            >
              {intl.formatMessage({ id: 'common.button.cancel' })}
            </TextSecondaryButton>
          </Box>
        </div>
      </DetailView>
    )
  }
}

const mapStateToProps = state => ({
  user: state.user,
  isPasscodeSet: isPasscodeSet(state),
})

const mapDispatchToProps = {
  loadUser,
  addNotification,
  updateAttribute,
}

const connectedToProps = connect(mapStateToProps, mapDispatchToProps)

export default compose(
  connectedToProps,
  injectIntl,
  withWidth(),
  withRouter,
  withStyles(styles),
)(PasscodeDetailView)
