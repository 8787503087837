import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useIntl } from 'react-intl'

import { UserButton } from '@admin-ui-common/base-user'
import UnlinkAccountDialog from './UnlinkAccountDialog'
import { analyticsProvider } from '../../../App'

const UnlinkAccountButton = ({ account }) => {
  const [open, setOpen] = useState(false)
  const intl = useIntl()

  if (!account) {
    return null
  }

  const toggleDialog = e => {
    e.stopPropagation()

    const dialogState = open ? 'close' : 'open'

    analyticsProvider.sendAnalytics({
      type: 'event',
      action: 'click',
      event_category: 'button',
      event_label: `unlinkAccount.${dialogState}`,
      value: 0,
    })

    setOpen(!open)
  }

  return (
    <>
      <UserButton
        onClick={toggleDialog}
        color="secondary"
        variant="outlined"
        disableElevation
        disableRipple
      >
        {intl.formatMessage({ id: 'accounts.unlink' })}
      </UserButton>

      <UnlinkAccountDialog
        open={open}
        toggleDialog={toggleDialog}
        accountId={account.id}
      />
    </>
  )
}

UnlinkAccountButton.propTypes = {
  account: PropTypes.object.isRequired,
}

export default UnlinkAccountButton
