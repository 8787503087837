import PasswordView from './detailView/PasswordDetailView'
import PasscodeView from './detailView/PasscodeDetailView'
import PinView from './detailView/PinDetailView'
import Fido2View from './detailView/Fido2RegistrationView'
import TwoStepVerificationDetailView from './detailView/TwoStepVerificationDetailView'

export const routes = {
  CHANGE_PASSWORD: {
    label: 'profile.label.change-password',
    path: '/change_password',
    placeholder: 'profile.placeholder.password',
    view: PasswordView,
    featureFlag: 'showPasswordField',
  },
  TWO_STEP_VERIFICATION: {
    label: 'profile.label.two-step-verification',
    path: '/two_step_verification',
    placeholder: 'profile.placeholder.two-step-verification',
    view: TwoStepVerificationDetailView,
    featureFlag: 'showMFAConfigurationPage',
  },
  TWO_STEP_VERIFICATION_PASSWORD: {
    label: 'profile.label.two-step-verification-password',
    path: `/two_step_verification/:modalName`,
    placeholder: 'profile.placeholder.two-step-verification-password',
    view: TwoStepVerificationDetailView,
    featureFlag: 'showMFAConfigurationPage',
  },
  PASSCODE: {
    label: 'profile.label.change-passcode',
    path: '/passcode',
    placeholder: 'profile.placeholder.passcode',
    view: PasscodeView,
    featureFlag: 'showPasscodeField',
  },
  PIN: {
    label: 'profile.label.change-pin',
    path: '/pin',
    placeholder: 'profile.placeholder.pin',
    view: PinView,
    featureFlag: 'showPINField',
  },
  FIDO2: {
    label: 'profile.label.fido2',
    path: '/fido2',
    placeholder: 'profile.placeholder.fido2',
    view: Fido2View,
    featureFlag: 'enableFIDO2',
  },
}
