import { capitalize } from 'lodash'

const getSocialProviderName = providerName => {
  switch (providerName) {
    case 'azure':
      return 'Microsoft'
    default:
      return capitalize(providerName)
  }
}

export default getSocialProviderName
