import { Box } from '@mui/material'
import { useDispatch } from 'react-redux'
import { Button } from 'common/components/button/Button'
import CheckMarkIcon from 'common/components/CheckMarkIcon'
import { Body } from 'common/components/Typography'
import { get } from 'lodash'

import FlowLayout from 'common/layouts/FlowLayout'
import React from 'react'
import { useIntl } from 'react-intl'
import useMainRedirect from 'common/components/hooks/useMainRedirect'

import { checkSession } from 'common/actions/session'

const TwoFASetupSuccessStep = ({
  wizard,
  getPageIndex,
  pages,
  lastStepData,
}) => {
  const intl = useIntl()
  const mainRedirect = useMainRedirect()
  const dispatch = useDispatch()

  const handleContinueClicked = e => {
    e.preventDefault()

    let isLastStep = false
    let metadata = null

    if (wizard) {
      const wizardState = wizard?.getPageState()
      isLastStep =
        get(wizardState, 'data.lastStep') || get(wizardState, 'body.lastStep')
      metadata = wizardState?.metadata || wizardState
    } else {
      isLastStep =
        get(lastStepData, 'data.lastStep') || get(lastStepData, 'body.lastStep')
      metadata = lastStepData?.metadata || lastStepData
    }

    if (isLastStep) {
      const isStaticRedirect = mainRedirect(metadata)
      if (!isStaticRedirect) {
        return
      }
    }
    if (wizard) {
      wizard.toPage(getPageIndex(pages?.LoggedIn))
    } else {
      dispatch(checkSession())
    }
  }

  return (
    <FlowLayout
      title={intl.formatMessage({
        id: 'separatedMultiStep.twoStepSetupSuccess.title',
      })}
      image={<CheckMarkIcon marginBottom={0} />}
    >
      <Body>
        {intl.formatMessage({
          id: 'separatedMultiStep.twoStepSetupSuccess.body',
        })}
      </Body>
      <Box mt={4}>
        <Button size="large" onClick={handleContinueClicked}>
          {intl.formatMessage({
            id: 'common.button.continue',
          })}
        </Button>
      </Box>
    </FlowLayout>
  )
}

export default TwoFASetupSuccessStep
