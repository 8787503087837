import process from 'common/utils/process'

const id = 'onboard.OnboardUserWithEmailMobile.v1.0'

const enrollUser = identity =>
  process
    .start(id)
    .then(response => process.step({ ...response.body, parameters: identity }))
    .then(process.complete)

export default enrollUser
