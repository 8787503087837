import axios from 'axios'
import { get, create } from '@github/webauthn-json'

/**
 * Api calls
 */
// TODO: possibly integrate this with i.js
const getCustomHeaders = accessToken => ({
  headers: {
    'X-Forwarded-Host': window.location.host,
    ...(accessToken
      ? {
          Authorization: `Bearer ${accessToken}`,
        }
      : {}),
  },
})

const startFido2Registration = (accessToken, username) => {
  return axios.post(
    '/webauthn/registration/registrationOptions',
    { username },
    getCustomHeaders(accessToken),
  )
}

const endFido2Registration = (accessToken, registrationId, payload) => {
  return axios.post(
    `/webauthn/registration/credential?registrationId=${registrationId}`,
    payload,
    getCustomHeaders(accessToken),
  )
}

export const register = async ({ accessToken, username }) => {
  const response = await startFido2Registration(accessToken, username)

  const { publicKey, registrationId } = response.data
  const endRegistrationPayload = await create({ publicKey })
  await endFido2Registration(
    accessToken,
    registrationId,
    endRegistrationPayload,
  )
}

const startFido2Login = (assertionId, payload) => {
  return axios.post(
    `/webauthn/assertion/challenge?assertionId=${assertionId}`,
    payload,
    getCustomHeaders(),
  )
}

export const endFido2Login = (assertionId, payload) => {
  return axios.post(
    `/webauthn/assertion/assertionResult?assertionId=${assertionId}`,
    payload,
    getCustomHeaders(),
  )
}

export const login = async ({ username, processId }) => {
  const response = await startFido2Login(processId, { username })

  const { publicKey, assertionId } = response.data
  const endLoginPayload = await get({ publicKey })
  await endFido2Login(assertionId, endLoginPayload)
}
