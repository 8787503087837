import React from 'react'
import classNames from 'classnames'

import styles from './styles.module.css'

const Title = ({ center, children, ...restProps }) => (
  <div className="flex flex-column" {...restProps}>
    <div className={classNames('flex', { 'justify-center': Boolean(center) })}>
      <div className={styles['brand-line']} />
    </div>
    <h1
      style={
        Boolean(center)
          ? {
              textAlign: 'center',
              margin: '11px 41px 0 41px',
            }
          : {}
      }
      className={styles['title-header']}
    >
      {children}
    </h1>
  </div>
)

export default Title
