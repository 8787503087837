import isPhone from 'common/utils/isPhone'
import isEmail from 'common/utils/isEmail'

const maskIdentity = identity => {
  if (isPhone(identity)) {
    const mobile = `(${identity[0]}**)***-***${identity[identity.length - 1]}`
    return mobile
  } else if (isEmail(identity)) {
    const [name, domain] = identity.split('@')
    const maskedName = name[0] + '****'
    const maskedEmail = maskedName + '@' + domain
    return maskedEmail
  }
  return identity
}

export default maskIdentity
