import { Spinner } from '@admin-ui-common/base-user'
import CheckMarkIcon from 'common/components/CheckMarkIcon'
import CrossIcon from 'common/components/CrossIcon'
import { Body } from 'common/components/Typography'

import { Box } from '@mui/material'

import FlowLayout from 'common/layouts/FlowLayout'
import i, { killUserSession } from 'common/utils/i'
import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { useIntl } from 'react-intl'

import conf from 'conf'

const ConsentResultPage = () => {
  const intl = useIntl()

  const location = useLocation()
  const params = new URLSearchParams(location.search)

  const [success, setSuccess] = useState(null)

  const confirmConsentUrl = `${conf.apiRoot}/user/impersonation/response`
  const actionToken = params.get('token_value')

  const data = {
    tokenValue: actionToken,
    approved: true,
  }

  useEffect(() => {
    i.post(confirmConsentUrl, { data, ignoreErrorRedirect: true })
      .then(res => {
        if (res?.metadata?.status === 204) {
          setSuccess(true)
        } else {
          setSuccess(false)
          killUserSession()
        }
      })
      .catch(() => {
        setSuccess(false)
        killUserSession()
      })
  }, [])

  const getTitle = () => {
    if (success === null) return null
    return intl.formatMessage({
      id:
        success === true
          ? 'impersonation-consent.approved-successfully.title'
          : 'impersonation-consent.failed-to-approve.title',
    })
  }

  const getImage = () => {
    if (success === null) return null
    return success === true ? <CheckMarkIcon marginBottom={0} /> : <CrossIcon />
  }

  return (
    <FlowLayout title={getTitle()} image={getImage()}>
      {success === null && (
        <Box display="flex" justifyContent="center">
          <Spinner />
        </Box>
      )}
      {success !== null && (
        <Body>
          {intl.formatMessage({
            id:
              success === true
                ? 'impersonation-consent.approved-successfully.description'
                : 'impersonation-consent.failed-to-approve.description',
          })}
        </Body>
      )}
    </FlowLayout>
  )
}

export default ConsentResultPage
