import conf from 'conf'
import i from 'common/utils/i'
import isPrivacyAndConsentFlow from 'common/utils/isPrivacyAndConsentFlow'

const model = 'settings'

export const GET_PUBLIC_SETTINGS_REQUEST = `${model}/GET_PUBLIC_SETTINGS_REQUEST`
export const GET_PUBLIC_SETTINGS_SUCCESS = `${model}/GET_PUBLIC_SETTINGS_SUCCESS`
export const GET_PUBLIC_SETTINGS_FAILURE = `${model}/GET_PUBLIC_SETTINGS_FAILURE`

const loadPublicSettingRequest = {
  type: GET_PUBLIC_SETTINGS_REQUEST,
}

const loadPublicSettingSuccess = (publicSettings, metadata) => ({
  type: GET_PUBLIC_SETTINGS_SUCCESS,
  publicSettings,
  tenantID: metadata.headers['X-Tenant-ID'] || metadata.headers['x-tenant-id'],
})

const loadPublicSettingFailure = error => ({
  type: GET_PUBLIC_SETTINGS_FAILURE,
  error,
})

export const getPublicSettings = () => dispatch => {
  const url = `${conf.apiRoot}/application/settings/public`

  dispatch(loadPublicSettingRequest)

  return i
    .get(url, {
      ignoreErrorRedirect: true,
      omitAccessToken: true,
      ...(isPrivacyAndConsentFlow
        ? { headers: { 'x-idp-authentication': true } }
        : {}),
    })
    .then(({ body, metadata }) =>
      dispatch(loadPublicSettingSuccess(body, metadata)),
    )
    .catch(error => dispatch(loadPublicSettingFailure(error)))
}
