import React from 'react'
import { useIntl } from 'react-intl'

import Box from '@mui/material/Box'

import { Body } from 'common/components/Typography'
import CircularProgress from 'common/components/CircularProgress'
import * as UXPCard from 'common/components/Card'

const Loader = () => {
  const intl = useIntl()

  return (
    <UXPCard.Card>
      <UXPCard.Content>
        <Box diplay="flex" flexDirection="column" textAlign="center">
          <CircularProgress size={40} thickness={5} />
          <Body mt={3}>
            {intl.formatMessage({ id: 'onboard.common.loading' })}
          </Body>
        </Box>
      </UXPCard.Content>
    </UXPCard.Card>
  )
}

export default Loader
