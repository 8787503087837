import process from 'common/utils/process'
import i from 'common/utils/i'
import conf from 'conf'

const addOrUpdateProcessId = 'userManagement.AddOrUpdateAuthnIdentifier.v1.0'
const sendVerificationProcessId = 'userManagement.SendVerification.v1.0'
const updatePasswordId = 'userManagement.UpdatePassword.v1.0'
const removeUserAttributeId = 'userManagement.RemoveUserAttribute.v1.0'
const updateNotificationChannelId =
  'userManagement.UpdateNotificationChannel.v1.0'

export const addOrUpdateAuthnIdentifier = ({
  oldAuthnIdentifier,
  newAuthnIdentifier,
}) =>
  process.start(addOrUpdateProcessId).then(response =>
    process.step({
      ...response.body,
      parameters: {
        oldAuthnIdentifier,
        newAuthnIdentifier,
      },
    }),
  )

export const removeUserAttribute = ({
  attributeValue,
  attributeProperty,
  attributeName,
}) =>
  process.start(removeUserAttributeId).then(response =>
    process.step({
      ...response.body,
      parameters: {
        attributeValue,
        attributeProperty,
        attributeName,
      },
    }),
  )

export const sendVerification = authnIdentifier => {
  const sendUrl = `${conf.apiRoot}/process/start/${sendVerificationProcessId}?authnIdentifier=${authnIdentifier}`
  return i.get(sendUrl)
}

export const updatePassword = ({ oldPassword, newPassword }) =>
  process.start(updatePasswordId).then(response =>
    process.step({
      ...response.body,
      parameters: {
        oldPassword,
        newPassword,
      },
    }),
  )

export const updateNotificationChannel = authnIdentifier => {
  const sendUrl = `${conf.apiRoot}/process/start/${updateNotificationChannelId}?updatedContactChannel=${authnIdentifier}`
  return i.post(sendUrl)
}
