import { hasUIFeatureFlag } from 'common/services/FeatureFlagsService'

import Chrome from 'common/assets/browsers/Chrome.png'
import Firefox from 'common/assets/browsers/Firefox.png'
import Opera from 'common/assets/browsers/Opera.png'
import Edge from 'common/assets/browsers/InternetExplorer.png'

import appleEnLogo from 'common/assets/stores/en/apple.svg'
import googleEnLogo from 'common/assets/stores/en/google.png'

import appleEsLogo from 'common/assets/stores/es/apple.svg'
import googleEsLogo from 'common/assets/stores/es/google.png'

export const CAPTCHA_RESPONSE = 'captchaResponse'

export const CAPTCHA_NEVER_SHOW = -1
export const CAPTCHA_ALWAYS_SHOW = 0
export const CAPTCHA_SHOW_WHEN_PROMPTED = 1 // Anything that is not -1 or 0
export const FORCE_NOSESSION_ROUTES = ['/device_authorize']

export const browserData = [
  {
    browserName: 'Chrome',
    companyName: 'Google',
    link: 'https://www.google.com/chrome/',
    logo: Chrome,
  },
  {
    browserName: 'Firefox',
    companyName: 'Mozilla',
    link: 'https://www.mozilla.org/firefox/',
    logo: Firefox,
  },
  {
    browserName: 'Edge',
    companyName: 'Microsoft',
    link: 'https://www.microsoft.com/windows/microsoft-edge',
    logo: Edge,
  },
  {
    browserName: 'Opera',
    companyName: 'Opera',
    link: 'https://www.opera.com/',
    logo: Opera,
  },
]

// Bowser constants: https://github.com/lancedikson/bowser/blob/HEAD/src/constants.js
export const browsersMinVersion = {
  chrome: '60',
  edge: '6',
  firefox: '40',
  'internet explorer': '11',
  opera: '63',
  safari: '10',
}

export const osMinVersions = {
  ios: '10',
}

export const PLATFORM_TYPES = {
  DESKTOP: 'desktop',
  MOBILE: 'mobile',
}

export const unsupportedBrowserList = ['internet explorer']

export const FORGOT_PASSWORD_ROUTE = '/forgot_password'

export const INVALID_CREDENTIALS = 'invalid-credential'
export const NOT_ELIGIBLE = 'mobile-not-eligible'
export const INVALID_PRODUCT = 'invalid-product-code'
export const MISSING_MOBILE = 'missing-mobileNumber'
export const MISSING_PRODUCT_ID = 'missing-productId'
export const NO_SUBSCRIPTION_EXISTS = 'no-subscription-exists'
export const NUMBER_IS_PORTABLE = 'number-is-portable'
export const TOO_MANY_RETRIES = 'process-terminated-with-too-many-retries'
export const ACTION_TOKEN_RETRIES_EXCEEDED = 'action-token-retries-exceeded'
export const ACTION_TOKEN_EXPIRED = 'action-token-expired'
export const INVALID_PKAT = 'invalid-pkat'
export const TOKEN_MAX_RESEND_REACHED = 'token-max-resend-reached'
export const INVALID_ACT_CONSUMPTION = 'invalid-act-consumption'
export const USER_NOT_ACTIVATED = 'user-not-activated'

export const ALREADY_SUBSCRIBED = 'Already Purchased'
export const PASSWORD_PROMPT = 'PasswordPrompt'
export const PRODUCT_PROMPT = 'ProductIdentifierPrompt'

export const getFlowTypes = () => ({
  SUBSCRIBE: 'subscribe',
  LOGIN: hasUIFeatureFlag('wizardLoginFlowAsDefault') ? '/' : 'login',
  CANCEL: 'cancel',
  FORGOT: 'forgot_password',
  PASSWORD_EXPIRE: 'password_expire',
})

export const STORE_LOGOS = {
  en: {
    apple: appleEnLogo,
    google: googleEnLogo,
  },
  es: {
    apple: appleEsLogo,
    google: googleEsLogo,
  },
}

export const DISABLE_VEFIRY_PIN_ERRORS = new Set([
  TOO_MANY_RETRIES,
  ACTION_TOKEN_RETRIES_EXCEEDED,
])

export const ACCESS_TOKEN_KEY = '__token__'
export const ERROR_AUTHENTICATION_REQUIRED = 'authentication-required'
