export const SET_PROCESS_STEP = 'SET_PROCESS_STEP'
export const setProcessStep = step => ({
  type: SET_PROCESS_STEP,
  step,
})

export const RESET_PROCESS_STEP = 'RESET_PROCESS_STEP'
export const resetProcessStep = () => ({
  type: RESET_PROCESS_STEP,
})
