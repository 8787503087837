import formatNumber from 'common/utils/formatNumber'
import isPhone from 'common/utils/isPhone'
import isEmail from 'common/utils/isEmail'
import { isEmailOnly, isPhoneOnly } from 'common/utils/identifier-utils'
import { hasUIFeatureFlag } from 'common/services/FeatureFlagsService'
import { getNationalNumberFromNumber } from 'common/utils/googlePhoneUtils'

const getDefaultIdentifier = (identifier, allowedIdentifiers, iso2Code) => {
  if (isEmailOnly(allowedIdentifiers) && isPhone(identifier)) {
    return ''
  }
  if (isPhoneOnly(allowedIdentifiers) && isEmail(identifier)) {
    return ''
  }

  if (isPhone(identifier) && !hasUIFeatureFlag('excludeCountryCode')) {
    return formatNumber(
      iso2Code,
      String(getNationalNumberFromNumber(identifier)),
    )
  }
  return identifier
}

export default getDefaultIdentifier
