import React, { useEffect, useState, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useIntl } from 'react-intl'
import { useLocale } from '@admin-ui-common/utils'

import { updateAttribute } from 'common/actions/user'
import { get, isEmpty } from 'lodash'

import * as UXPDialog from 'common/components/dialog/UXPDialog'
import DetailView from 'protected/common/DetailView'
import {
  TextSecondaryButton,
  TextPrimaryFillButton,
} from 'common/components/button/Button'
import { getAvailableLocales } from 'common/constants/locale'
import FeatureFlagsService from 'common/services/FeatureFlagsService'
import { Select } from '@admin-ui-common/base-user'

import styled from 'styled-components'
import { analyticsProvider } from '../../../App'

const StyledUXPDialogContent = styled(UXPDialog.Content)`
  && {
    margin-bottom: 24px;
    .MuiFormControl-root {
      width: 100%;
    }
  }
`

function LanguageView(props) {
  const user = useSelector(state => state.user)
  const unAuthUserLocale = useSelector(state => state.locale)
  const hasSession = useSelector(state => state.session.hasSession)
  const detectLocale = useSelector(
    state => state.configuration.config.detectLocale,
  )
  const configDefaultLocale = useSelector(
    state => state.configuration.config.defaultLocale,
  )

  const defaultLocale = useLocale(
    user,
    unAuthUserLocale,
    hasSession,
    FeatureFlagsService.get('system.defaultLocale'),
    detectLocale,
    configDefaultLocale,
    getAvailableLocales(),
  )

  const remoteLocale = get(user, [
    'attributes',
    'com.uxpsystems.mint.user.Language',
  ])
  const userLocale = getAvailableLocales().has(remoteLocale)
    ? remoteLocale
    : null

  const [locale, setLocale] = useState(userLocale)
  const languageChanged = useRef(false)
  const [changeDialogOpen, setChangeDialogOpen] = useState(false)
  const [inProgress, setInProgress] = useState(false)
  const { addNotification } = props

  const intl = useIntl()

  const dispatch = useDispatch()

  const flowType = 'detailView'
  const pageTitle = 'language_view'
  const baseEventTag = `${flowType}.${pageTitle}`

  const languageNotification = intl.formatMessage({
    id: 'notification.language',
  })
  const notificationMessage = intl.formatMessage(
    {
      id: 'notification.updated-successfully',
    },
    {
      entity: languageNotification,
    },
  )

  useEffect(() => {
    // prevent toast appearing unless the user has in fact changed language
    if (!languageChanged.current) {
      return
    }

    addNotification({
      message: notificationMessage,
      variant: 'success',
    })
  }, [notificationMessage, addNotification])

  const onLanguageChange = e => {
    const newLocale = e.target.value
    setLocale(newLocale)
  }

  const onChangeDialogOpen = () => {
    analyticsProvider.sendAnalytics({
      type: 'event',
      action: 'click',
      event_category: 'button',
      event_label: `${baseEventTag}.change-dialog-open`,
      value: 0,
    })
    setChangeDialogOpen(true)
    if (isEmpty(locale)) {
      setLocale(defaultLocale)
    }
  }

  const onChangeDialogClose = () => {
    analyticsProvider.sendAnalytics({
      type: 'event',
      action: 'click',
      event_category: 'button',
      event_label: `${baseEventTag}.change-dialog-close`,
      value: 0,
    })
    setChangeDialogOpen(false)
    setLocale(userLocale)
  }

  const handleLanguageChange = () => {
    analyticsProvider.sendAnalytics({
      type: 'event',
      action: 'click',
      event_category: 'button',
      event_label: `${baseEventTag}.change-language`,
      value: 0,
    })

    setInProgress(true)

    updateAttribute({
      attributes: { 'com.uxpsystems.mint.user.Language': locale },
    })(dispatch)
      .then(() => {
        setInProgress(false)
        onChangeDialogClose()
        languageChanged.current = true
      })
      .catch(() => {
        analyticsProvider.sendAnalytics({
          type: 'event',
          action: 'click',
          event_category: 'button',
          event_label: `${baseEventTag}.change-language`,
          value: 1,
        })
        setInProgress(false)
      })
  }

  return (
    <DetailView
      title={intl.formatMessage({ id: 'profile.detail.language.title' })}
      description={intl.formatMessage({
        id: 'profile.detail.language.description',
      })}
    >
      <DetailView.Title
        title={intl.formatMessage({
          id: userLocale
            ? `language.${userLocale}`
            : 'profile.label.language.none',
        })}
        onEditClick={onChangeDialogOpen}
      />

      <UXPDialog.Dialog
        open={changeDialogOpen}
        fullScreen
        fullWidth
        maxWidth="xs"
      >
        <UXPDialog.Title
          onClose={onChangeDialogClose}
          title={intl.formatMessage({
            id: 'profile.detail.language.update-language',
          })}
        />
        <StyledUXPDialogContent className="mt1">
          <Select
            inputLabel={intl.formatMessage({
              id: 'profile.detail.language.preferred-language',
            })}
            hideEmptyValue
            onChange={onLanguageChange}
            value={locale}
          >
            {[...getAvailableLocales()].map(availableLocale => (
              <Select.Option key={availableLocale} value={availableLocale}>
                {intl.formatMessage({ id: `language.${availableLocale}` })}
              </Select.Option>
            ))}
          </Select>
        </StyledUXPDialogContent>
        <UXPDialog.Actions>
          <TextSecondaryButton onClick={onChangeDialogClose}>
            Cancel
          </TextSecondaryButton>
          <TextPrimaryFillButton
            onClick={handleLanguageChange}
            disabled={inProgress}
          >
            Save
          </TextPrimaryFillButton>
        </UXPDialog.Actions>
      </UXPDialog.Dialog>
    </DetailView>
  )
}

export default LanguageView
