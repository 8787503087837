import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { injectIntl } from 'react-intl'

import Page from 'common/components/Page'
import RwdPaper from 'common/components/RwdPaper'
import { Button } from 'common/components/button/Button'
import TextField from 'common/components/textfield/TextField'
import PasswordField from 'common/components/textfield/PasswordField'
import NameFields from 'common/components/nameField/NameFields'
import ResultVerifyUsername from './ResultVerifyUsername'
import { get, isEmpty } from 'lodash'

import { constants } from 'common/components/Constants'
import process from 'common/utils/process'
import { resolveErrorEntity } from 'common/utils/processBackendErrors'
import { getNameFormat } from 'common/services/FeatureFlagsService'

import styles from '../styles.module.css'
import { analyticsProvider } from '../../App'

const ErrorView = injectIntl(({ errorMessage, onError, intl }) => (
  <div className="flex flex-column">
    {errorMessage}
    <div className="mt2 self-center">
      <Button onClick={onError}>
        {intl.formatMessage({ id: 'common.button.ok' })}
      </Button>
    </div>
  </div>
))

class ConfirmYourDetails extends React.Component {
  static propTypes = {
    stepResponse: PropTypes.object.isRequired,
    displayName: PropTypes.string,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    username: PropTypes.string.isRequired,
    usernameType: PropTypes.oneOf(['email', 'phone']).isRequired,
    checkSession: PropTypes.func.isRequired,
  }

  constructor(props) {
    super(props)

    this.pageTitle = 'createPassword'
    this.baseEventTag = `signup.${this.pageTitle}`
  }

  state = {
    step: null,
    verified: true,
    response: this.props.stepResponse,
    username: this.props.username,
    credential: '',
    firstName: this.props.firstName,
    lastName: this.props.lastName,
    displayName: this.props.displayName,
    errors: {},
    errorMessages: {
      credential: this.props.intl.formatMessage({ id: 'error.credential' }),
    },
    isLoading: false,
  }

  componentDidMount() {
    analyticsProvider.sendAnalytics({
      type: 'page_view',
      page_title: this.pageTitle,
      page_path: `/signup#${this.pageTitle}`,
    })
  }

  handleInputChange = name => event =>
    this.setState({ [name]: event.target.value })

  isFullName = () => getNameFormat() === constants.FULL_NAME

  handleRedirect = () => {
    const { userId, lastStep, userAuthenticated } = this.state.response
    const isProcessComplete = Boolean(userId || lastStep || userAuthenticated)

    if (isProcessComplete) {
      this.goHome()
      this.props.checkSession()
    }
  }

  goHome = () => {
    analyticsProvider.sendAnalytics({
      type: 'event',
      action: 'click',
      event_category: 'button',
      event_label: `${this.baseEventTag}.cancel`,
      value: 0,
    })
    this.props.history.push('/')
  }

  validate = fieldName => event => {
    let { errors } = this.state
    let value = event.target.value.trim()

    if (value === '') {
      errors[fieldName] = this.state.errorMessages[fieldName]
    } else {
      errors[fieldName] = ''
    }

    this.setState({
      errors,
    })
  }

  onConfirm = event => {
    event.preventDefault()

    analyticsProvider.sendAnalytics({
      type: 'event',
      action: 'click',
      event_category: 'button',
      event_label: `${this.baseEventTag}.confirm`,
      value: 0,
    })

    const { userAuthenticated, processId, parameters } = this.state.response
    parameters.credential = this.state.credential

    this.setState({ isLoading: true })

    process
      .step({ userAuthenticated, processId, parameters })
      .then(({ body }) => {
        this.setState({
          step: 'ResultCreatePassowrd',
          response: body,
          verified: get(body, 'userAuthenticated') === true,
          isLoading: false,
        })
      })
      .catch(err => {
        analyticsProvider.sendAnalytics({
          type: 'event',
          action: 'click',
          event_category: 'button',
          event_label: `${this.baseEventTag}.confirm`,
          value: 1,
        })

        let errors = {}
        const intl = this.props.intl
        const errorMessage = resolveErrorEntity({ intl, error: err.body })

        if (!isEmpty(err.body.operationError)) {
          errors.unknown = errorMessage
        } else if (!isEmpty(err.body.fieldErrors)) {
          errors.credential = errorMessage
        }

        this.setState({
          errors,
          isLoading: false,
        })
      })
  }

  render() {
    const { intl, usernameType } = this.props
    const {
      step,
      response,
      verified,
      firstName,
      lastName,
      displayName,
      credential,
      errors,
      isLoading,
    } = this.state

    const values = { firstName, lastName, displayName }

    const disabled = credential === ''

    if (isLoading) {
      return <div>{intl.formatMessage({ id: 'common.loading' })}</div>
    }

    if (!isEmpty(errors.unknown)) {
      return <ErrorView errorMessage={errors.unknown} onError={this.goHome} />
    }

    if (step === 'ResultCreatePassowrd') {
      return (
        <ResultVerifyUsername
          verificationType={usernameType}
          verified={verified}
          onConfirm={verified ? this.handleRedirect : this.goHome}
          userAuthenticated={response.userAuthenticated}
        />
      )
    }

    return (
      <RwdPaper>
        <div className={styles.panel}>
          <div
            className={classNames(styles.title, styles.withLargeBottomMargin)}
          >
            <Page.Title>
              {intl.formatMessage({ id: 'sign-up.create-password.title' })}
            </Page.Title>
          </div>
          <Page.Body>
            {intl.formatMessage({ id: 'sign-up.create-password.message' })}
          </Page.Body>
          <NameFields
            disabled
            className={classNames(
              { [styles.nameFieldWidth]: !this.isFullName() },
              'mb2',
            )}
            fullWidth={this.isFullName()}
            errorTexts={errors}
            onChange={this.handleInputChange}
            values={values}
            onBlur={this.validate}
          />
          <div className={styles.fieldMB} />
          <TextField
            disabled
            errorText={this.state.errors.username}
            label={intl.formatMessage({
              id: `sign-up.create-password.label.${usernameType}`,
            })}
            value={this.state.username}
          />
          <div className={styles.fieldMB} />
          <PasswordField
            label={intl.formatMessage({
              id: 'sign-up.create-password.label.password',
            })}
            errorText={this.state.errors.credential}
            onChange={this.handleInputChange('credential')}
            value={this.state.credential}
            onBlur={this.validate('credential')}
          />
          <div className="flex justify-start mt3">
            <Button onClick={this.onConfirm} disabled={disabled}>
              {intl.formatMessage({ id: 'common.button.confirm' })}
            </Button>
            <div className="ml2">
              <Button variant="outlined" onClick={this.goHome}>
                {intl.formatMessage({ id: 'common.button.cancel' })}
              </Button>
            </div>
          </div>
        </div>
      </RwdPaper>
    )
  }
}

export default injectIntl(ConfirmYourDetails)
