import getAttribute from 'common/utils/getAttribute'
import { get, isEmpty } from 'lodash'

const channelKeyTypeMap = {
  mobiles: 'mobile',
  emails: 'email',
}

const getJsonAttributes = (entity, attribute) =>
  getAttribute(entity, attribute) || []

export const getDisplayName = ({ user = {} }) => {
  const lastName = getAttribute(user, 'com.uxpsystems.mint.user.FamilyName')
  const firstName = getAttribute(user, 'com.uxpsystems.mint.user.GivenName')

  return user.displayName || `${firstName} ${lastName}`
}

export const getEmails = ({ user = {} }, status = null) => {
  let emails = getJsonAttributes(user, 'emails')

  if (status) emails = emails.filter(e => e.status === status)

  return emails
}

export const getMobiles = ({ user = {} }, status = null) => {
  let mobiles = getJsonAttributes(user, 'mobiles')

  if (status) mobiles = mobiles.filter(e => e.status === status)

  return mobiles
}

export const getPreferredContact = ({ user = {} }) => {
  const preferredAttribute = getAttribute(
    user,
    'preferredNotificationChannel',
    '',
  )

  if (isEmpty(preferredAttribute)) {
    return {}
  }

  const [selector, id] = preferredAttribute.split(',')

  const [key, type] = selector && selector.split('.')

  if (isEmpty(key)) {
    return {}
  }

  const preferredContact = getJsonAttributes(user, key).find(
    ch => String(ch.id) === String(id),
  )

  if (preferredContact) {
    preferredContact.type = channelKeyTypeMap[key]

    preferredContact.value =
      preferredContact[
        type || preferredContact.type === 'mobile'
          ? 'number'
          : preferredContact.type
      ]
  }

  return preferredContact
}

export const getPreferredContactList = state => {
  const emails = getEmails(state)
    .filter(email => {
      return email.status === 'activated'
    })
    .map(email => {
      email.value = email.email
      email.type = 'email'

      return email
    })
  const mobiles = getMobiles(state)
    .filter(item => {
      return item.status === 'activated'
    })
    .map(mobile => {
      mobile.value = mobile.number
      mobile.type = 'mobile'

      return mobile
    })

  return [...emails, ...mobiles]
}

export const getSocialConnection = ({ user = {} }) =>
  getJsonAttributes(user, 'socialConnections').reduce((a, p) => {
    a[p.providerId.toUpperCase()] = { ...p }
    return a
  }, {})

export const getConnection = (state, type) =>
  get(getSocialConnection(state), type.toUpperCase())

export const isCredentialSet = ({ user } = {}) => Boolean(user.credentialSet)

export const isPinSet = ({ user } = {}) => Boolean(user.pinSet)
export const isPasscodeSet = ({ user } = {}) => Boolean(user.passcodeSet)

export const twoStepVerificationMethodsCount = ({ user } = {}) => {
  const emails = get(user, 'attributes.emails', [])
  const mobiles = get(user, 'attributes.mobiles', [])

  const mfaEmailCount = emails.filter(email => get(email, 'mfaoption') === true)
    .length
  const mfaMobileCount = mobiles.filter(
    mobile => get(mobile, 'mfaoption') === true,
  ).length

  const isMfaAppSet = get(user, 'attributes.secret2FATotp', false)

  const twoStepVerificationMethodsCount = isMfaAppSet
    ? mfaEmailCount + mfaMobileCount + 1
    : mfaEmailCount + mfaMobileCount

  return twoStepVerificationMethodsCount
}

export const checkAllEmailMobileRegister = ({ user } = {}) => {
  const emails = get(user, 'attributes.emails', [])
  const mobiles = get(user, 'attributes.mobiles', [])

  const notRegisterEmailCount = emails.filter(
    email => get(email, 'mfaoption') === false,
  ).length
  const notRegisterMobileCount = mobiles.filter(
    mobile => get(mobile, 'mfaoption') === false,
  ).length
  const total = notRegisterEmailCount + notRegisterMobileCount

  if (total > 0) {
    return false
  }
  return true
}

export const isBothEmailAndMobileRegistered = ({ user } = {}) => {
  const emails = get(user, 'attributes.emails', [])
  const mobiles = get(user, 'attributes.mobiles', [])

  const isEmailRegistered =
    emails.filter(email => get(email, 'mfaoption', false)).length > 0
  const isMobileRegistered =
    mobiles.filter(mobile => get(mobile, 'mfaoption', false)).length > 0
  return isEmailRegistered && isMobileRegistered
}

export const regsiterEmailMobileList = ({ user } = {}) => {
  let registerMFAList = []
  const emails = get(user, 'attributes.emails', [])
  const mobiles = get(user, 'attributes.mobiles', [])

  const registerEmail = emails.filter(email => email.mfaoption === true)
  const registerMobile = mobiles.filter(mobile => mobile.mfaoption === true)
  if (!isEmpty(registerEmail)) {
    registerMFAList = [...registerMFAList, ...registerEmail]
  }
  if (!isEmpty(registerMobile)) {
    registerMFAList = [...registerMFAList, ...registerMobile]
  }
  return registerMFAList
}
