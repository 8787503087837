import React, { useState, useRef, useEffect, useCallback } from 'react'
import { get, noop } from 'lodash'
import { useIntl } from 'react-intl'
import qs from 'qs'

import conf from 'conf'
import i from 'common/utils/i'

import ActionLabel from 'common/components/ActionLabel'
import {
  getErrorCodes,
  isTokenResendError,
  formatErrorMessage,
} from 'common/utils/processBackendErrors'
import { ACTION_TOKEN_EXPIRED } from 'common/constants'

const ResendCode = ({
  tokenId,
  pkat,
  actionName,
  labelKeys,
  countdown: propCountdown = conf.resendOTPCountdownDuration,
  onResendError = noop,
  onResendStart = noop,
  onResendFinish = noop,
  isResendCode,
  ...rest
}) => {
  const [countdown, setCountdown] = useState(propCountdown)
  const intl = useIntl()
  const interval = useRef(null)

  // //////////////////////////////////////////////////////////

  const runCountDown = useCallback(() => {
    interval.current = setInterval(() => {
      setCountdown(c => {
        if (c === 1) {
          clearInterval(interval.current)
        }
        return c - 1
      })
    }, 1000)

    return () => clearInterval(interval.current)
  }, [])

  // //////////////////////////////////////////////////////////

  useEffect(() => {
    runCountDown()
    return () => clearInterval(interval.current)
  }, [pkat, runCountDown])

  useEffect(() => {
    if (isResendCode) {
      setCountdown(0)
      clearInterval(interval.current)
      handleResend()
    }
  }, [isResendCode])

  const handleResend = async event => {
    if (event?.preventDefault) {
      event.preventDefault()
    }

    if (countdown && !isResendCode) {
      return
    }

    if (!pkat) {
      setCountdown(propCountdown)
      return
    }

    onResendStart()

    try {
      const queryParams = {
        tokenId,
        pkat,
      }

      if (actionName) {
        queryParams.actionName = actionName
      }

      const resendUrl = `${conf.apiRoot}/session/token?${qs.stringify(
        queryParams,
      )}`
      const res = await i.put(resendUrl, { ignoreErrorRedirect: true })

      setCountdown(propCountdown)

      const newPkat = get(res, 'body.pkat')
      onResendFinish(newPkat)
    } catch (err) {
      if (!typeof onResendError === 'function') {
        return
      }
      const errorCodes = getErrorCodes(err.body)
      const resendErrorCode = errorCodes.find(isTokenResendError)

      if (!resendErrorCode) {
        // TODO: handle other error types?
        return
      }
      const resendErrorMessage = formatErrorMessage({
        intl,
        id:
          resendErrorCode === ACTION_TOKEN_EXPIRED
            ? 'error.action-token-expired'
            : 'error.token-max-resend-reached',
      })
      onResendError(resendErrorMessage, err)
    }
  }

  // //////////////////////////////////////////////////////////

  const isCountdown = Boolean(countdown)
  const countdownLabelKey = labelKeys?.countdown || 'common.countdown'
  const countdownCompleteLabelKey =
    labelKeys?.countdownComplete || 'common.countdown-complete'
  const label = isCountdown
    ? intl.formatMessage({ id: countdownLabelKey }, { countdown })
    : intl.formatMessage({ id: countdownCompleteLabelKey })

  return (
    <ActionLabel
      label={label}
      disabled={isCountdown}
      onClick={handleResend}
      {...rest}
    />
  )
}

export default ResendCode
