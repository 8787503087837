import React, { useEffect } from 'react'
import { useIntl } from 'react-intl'

import * as UXPCard from 'common/components/Card'
import { Title, Body } from 'common/components/Typography'
import { Button } from 'common/components/button/Button'

import useMainRedirect from 'common/components/hooks/useMainRedirect'

import { analyticsProvider } from 'App.js'

const AlreadyUnsubscribed = ({ flowType, wizard }) => {
  const intl = useIntl()
  const mainRedirect = useMainRedirect(`/${flowType}`)

  const pageTitle = 'already_unsubscribed'
  const baseEventTag = `${flowType}.already_unsubscribed`

  useEffect(() => {
    analyticsProvider.sendAnalytics({
      type: 'page_view',
      page_title: pageTitle,
      page_path: `/${flowType}#${pageTitle}`,
    })
  }, [flowType])

  const onOkay = () => {
    analyticsProvider.sendAnalytics({
      type: 'event',
      action: 'click',
      event_category: 'button',
      event_label: `${baseEventTag}.okay`,
      value: 0,
    })

    const isStaticRedirect = mainRedirect()
    if (isStaticRedirect) {
      wizard.setPageState({})
      wizard.toPage(0)
    }
  }

  return (
    <UXPCard.Card>
      <UXPCard.Content>
        <Title>
          {intl.formatMessage({
            id: 'onboard-cancel.already-unsubscribed.title',
          })}
        </Title>
        <Body>
          {intl.formatMessage({
            id: 'onboard-cancel.already-unsubscribed.look-like-you',
          })}
        </Body>
      </UXPCard.Content>
      <UXPCard.Divider />
      <UXPCard.Actions>
        <Button onClick={onOkay}>
          {intl.formatMessage({ id: 'onboard.common.okay' })}
        </Button>
      </UXPCard.Actions>
    </UXPCard.Card>
  )
}

export default AlreadyUnsubscribed
