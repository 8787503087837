/* eslint-disable eqeqeq */
import { Box } from '@mui/material'
import { Button } from 'common/components/button/Button'
import { Body } from 'common/components/Typography'
import { useHistory } from 'react-router-dom'

import FlowLayout from 'common/layouts/FlowLayout'
import React, { useState } from 'react'
import { useIntl } from 'react-intl'

import Radio from '@mui/material/Radio'
import RadioGroup from '@mui/material/RadioGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import FormControl from '@mui/material/FormControl'
import { process } from '@admin-ui-common/utils'
import { get } from 'lodash'
import useMainRedirect from 'common/components/hooks/useMainRedirect'

const SelectEmailStep = ({
  wizard,
  getPageIndex,
  pages,
  emailSetup,
  handleOtpStep,
  handleTwoFASetupSuccess,
}) => {
  const intl = useIntl()
  const mainRedirect = useMainRedirect()
  const history = useHistory()
  const { location } = history

  const { emails, processId } = wizard ? wizard.getPageState() : emailSetup
  const firstEmailId = emails ? emails[0].id : null

  const [selectedEmailIndex, setSelectedEmailIndex] = useState(firstEmailId)
  const selectedEmail = emails?.find(({ id }) => id == selectedEmailIndex)
    ?.value

  const handleNextClicked = async e => {
    e.preventDefault()
    try {
      const res = await process.step({
        processId: processId,
        parameters: {
          twoFASetupMethodOptionId: selectedEmailIndex,
        },
      })

      if (get(res, 'data.stepName') === 'UpdatePassword') {
        history.push({
          pathname: '/password_expire',
          state: get(res, 'data'),
          search: location.search,
        })
        return
      }

      if (get(res, 'data.userAuthenticated') === true) {
        const isStaticRedirect = mainRedirect(res)
        if (!isStaticRedirect) {
          return
        }
        if (wizard) {
          wizard.toPage(getPageIndex(pages.TwoFASetupSuccessStep))
        } else {
          handleTwoFASetupSuccess()
        }
      } else {
        if (wizard) {
          wizard.setPageState({
            username: selectedEmail,
            usernameType: 'email-setup',
            processId: processId,
            pkat: get(res, 'data.output.pkat'),
          })
          wizard.toPage(getPageIndex(pages.OtpStep))
        } else {
          handleOtpStep({
            username: selectedEmail,
            type: 'email-setup',
            processId: processId,
            pkat: get(res, 'data.output.pkat'),
          })
        }
      }
    } catch (e) {
      console.error(e)
    }
  }

  return (
    <FlowLayout
      title={intl.formatMessage({
        id: 'separatedMultiStep.authenticate-setupEmailOTP.title',
      })}
    >
      <Body>
        {intl.formatMessage({
          id: 'separatedMultiStep.authenticate-setupEmailOTP.select-an-email',
        })}
        <Box mt={1}>
          {intl.formatMessage({
            id:
              'separatedMultiStep.authenticate-setupEmailOTP.will-send-verification',
          })}
        </Box>
      </Body>
      <Box mt={3}>
        <FormControl component="fieldset">
          <RadioGroup
            value={selectedEmailIndex}
            onChange={event => {
              setSelectedEmailIndex(event.target.value)
            }}
          >
            {emails?.map(({ id, value }) => {
              return (
                <FormControlLabel
                  key={id}
                  value={id}
                  control={<Radio color="primary" />}
                  label={
                    <Box sx={{ fontWeight: 'bold', letterSpacing: '0.15px' }}>
                      {value}
                    </Box>
                  }
                />
              )
            })}
          </RadioGroup>
        </FormControl>
      </Box>
      <Box mt={4}>
        <Button size="large" onClick={handleNextClicked}>
          {intl.formatMessage({
            id: 'common.button.next',
          })}
        </Button>
      </Box>
    </FlowLayout>
  )
}

export default SelectEmailStep
