import conf from 'conf'
import i from 'common/utils/i'
import process from 'common/utils/process'
import cloneDeep from 'lodash/cloneDeep'
import getRedirectUrl, {
  getRedirectLocation,
} from 'common/utils/getRedirectUrl'

const featureName = 'GOOGLE'
export const REGISTER = {
  request: `${featureName}/REGISTER_REQUEST`,
  success: `${featureName}/REGISTER_SUCCESS`,
  error: `${featureName}/REGISTER_ERROR`,
}
export const ENROLL_OR_LOGIN = {
  request: `${featureName}/ENROLL_OR_LOGIN_REQUEST`,
  success: `${featureName}/ENROLL_OR_LOGIN_SUCCESS`,
  error: `${featureName}/ENROLL_OR_LOGIN_ERROR`,
}
export const CLEAR_STATE = `${featureName}/CLEAR_STATE`

export const googleInitiateProcess = query => dispatch => {
  dispatch({ type: REGISTER.request })
  const registerUrl = `${conf.apiRoot}/process/step${query}`

  return i
    .get(registerUrl)
    .then(res => {
      if (res.metadata.status === 200) {
        const redirectUrl = getRedirectUrl('google', res)
        const location = getRedirectLocation(res.metadata, redirectUrl || '')
        return (window.location = location)
      }

      dispatch({
        type: REGISTER.success,
        data: {
          ...res.body,
          statusCode: res.metadata.status,
        },
      })
    })
    .catch(err =>
      dispatch({
        type: REGISTER.error,
        error: {
          ...err.body,
          statusCode: err.statusCode || err.status,
        },
      }),
    )
}

export const loginWithGoogle = (param = '') => dispatch => {
  dispatch({ type: ENROLL_OR_LOGIN.request })
  const query = '?social=google-connect' + (param && '&' + param)
  const requestUrl = `${conf.apiRoot}/session/start${query}`

  return i
    .post(requestUrl, {})
    .then(res => {
      delete res.body.returnParameters
      delete res.body.output
      return process.step({
        ...res.body,
        parameters: {
          social_providerId: 'google-connect',
        },
      })
    })
    .then(res => {
      delete res.body.returnParameters
      delete res.body.output
      return process.step({
        ...res.body,
        parameters: {
          redirectUrl: '',
        },
      })
    })
    .then(res => {
      dispatch({ type: ENROLL_OR_LOGIN.success })

      if (res.body && res.body.requestUrl) {
        window.location.href = res.body.requestUrl
      }
    })
    .catch(err =>
      dispatch({
        type: ENROLL_OR_LOGIN.error,
        error: {
          ...err.body,
          statusCode: err.statusCode || err.status,
        },
      }),
    )
}

export const continueGoogle = confirmedData => (dispatch, getState) => {
  dispatch({ type: ENROLL_OR_LOGIN.request })
  const submissionData = {
    ...cloneDeep(getState().google.data),
    ...confirmedData,
  }
  delete submissionData.statusCode

  return process
    .step(submissionData)
    .then(res => {
      if (res.metadata.status === 200) {
        const location = getRedirectLocation(res.metadata, '')
        return (window.location = location)
      }

      dispatch({
        type: ENROLL_OR_LOGIN.success,
        data: {
          ...res.body,
          statusCode: res.metadata.status,
        },
      })
    })
    .catch(err =>
      dispatch({
        type: ENROLL_OR_LOGIN.error,
        error: {
          ...err.body,
          statusCode: err.statusCode || err.status,
        },
      }),
    )
}

export const clearState = () => ({
  type: CLEAR_STATE,
})
