import React from 'react'

import makeStyles from '@mui/styles/makeStyles'
import Box from '@mui/material/Box'
import Fade from '@mui/material/Fade'
import Hidden from '@mui/material/Hidden'
import MuiDivider from '@mui/material/Divider'

import { hasUIFeatureFlag } from 'common/services/FeatureFlagsService'
import getLogoImage from '../utils/getLogoImage'
import logo from '../assets/logos/logo-attmx.svg'

import Paper from '@mui/material/Paper'
import useLogoProvider from 'common/components/hooks/useLogoProvider'

const useStyles = makeStyles(theme => ({
  root: {
    [theme.breakpoints.down('sm')]: {
      height: '100%',
      width: '100%',
      minWidth: '100%',
      flex: 1,
      padding: theme.spacing(2),
      borderRadius: 0,
    },
    padding: theme.spacing(5),
    width: 500,
    borderRadius: 4,
  },
  content: {
    marginBottom: props => (props.light ? theme.spacing(3) : theme.spacing(4)),
    [theme.breakpoints.down('sm')]: {
      marginBottom: props => theme.spacing(3),
    },
  },
  actions: {
    '& > :not(:first-child)': {
      marginLeft: theme.spacing(2),
    },
  },
}))

export const Card = ({ children, ...rest }) => {
  const classes = useStyles()
  const showOperatorLogo = hasUIFeatureFlag('showOperatorLogo')
  const { logoImage } = useLogoProvider()

  return (
    <Fade in>
      <div>
        <Paper
          display="flex"
          flexDirection="column"
          className={classes.root}
          {...rest}
        >
          <Hidden smUp>
            <Box
              display="flex"
              pb={2}
              mb={2}
              justifyContent="space-between"
              alignItems="center"
            >
              <Box
                component="img"
                src={getLogoImage(logoImage)}
                alt="logo"
                height={36}
              />
              {showOperatorLogo && (
                <Box component="img" src={logo} alt="logo" height={36} />
              )}
            </Box>
          </Hidden>
          {children}
        </Paper>
      </div>
    </Fade>
  )
}

export const Content = ({ light, ...rest }) => {
  const classes = useStyles({ light })
  return <Box className={classes.content} {...rest} />
}

export const Divider = props => {
  const classes = useStyles()
  return (
    <Box className={classes.content} {...props}>
      <MuiDivider />
    </Box>
  )
}

export const Actions = props => {
  const classes = useStyles()
  return (
    <Box
      display="flex"
      alignSelf="flex-end"
      justifyContent="flex-end"
      className={classes.actions}
      {...props}
    />
  )
}
