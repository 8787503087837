import { isEmpty } from 'lodash'
import isEmail from 'common/utils/isEmail'
const loginHint = location => {
  const login_hint =
    new URLSearchParams(location?.search).get('login_hint') || ''
  if (!isEmail(login_hint)) {
    return
  }
  const encodedHint = encodeURIComponent(login_hint)
  if (!isEmpty(login_hint)) {
    const decoded = encodedHint.replace(/%20/g, '+')
    return decodeURIComponent(decoded)
  }
  return login_hint
}

export default loginHint
