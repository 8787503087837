import conf from 'conf'
import stripPhone from 'common/utils/stripPhone'
import FeatureFlagService from 'common/services/FeatureFlagsService'

const tryParseRegex = patternStr => {
  try {
    return new RegExp(patternStr)
  } catch (e) {
    // log to analytics
    console.warn(
      'could not parse phone pattern from public settings ' + patternStr,
    )
    return null
  }
}

const isPhone = (phone, regex) => {
  const settingPattern = FeatureFlagService.get('process.validPhonePattern')
  const defaultRegexPhone = settingPattern
    ? tryParseRegex(settingPattern) ?? conf.regexPhone
    : conf.regexPhone
  return (regex ?? defaultRegexPhone).test(stripPhone(phone))
}

export default isPhone
