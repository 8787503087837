import { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useIntl } from 'react-intl'
import { getAccessToken } from 'common/utils/accessToken'
import { addNotification } from 'common/actions/notification'
import { register } from 'common/utils/fido2'

export const useFido2Register = () => {
  const intl = useIntl()
  const dispatch = useDispatch()
  const { emails, mobiles } = useSelector(state => state.user?.attributes) ?? {}
  // please show better way of getting username
  const username = emails?.[0]?.email ?? mobiles?.[0]?.number ?? ''
  const accessToken = getAccessToken()

  return useCallback(
    async function registerFido2(onComplete) {
      if (!accessToken || !username) {
        dispatch(
          addNotification({
            message: intl.formatMessage({
              id: accessToken ? 'error.MissingUsername' : 'error.MissingToken',
            }),
            variant: 'error',
          }),
        )
        if (onComplete) {
          onComplete()
        }
        return
      }
      try {
        await register({ accessToken, username })

        dispatch(
          addNotification({
            message: intl.formatMessage({
              id: 'notification.fido-register-success',
            }),
            variant: 'success',
          }),
        )
      } catch (e) {
        dispatch(
          addNotification({
            message: intl.formatMessage({
              id: 'notification.fido-register-error',
            }),
            variant: 'error',
          }),
        )
      } finally {
        if (onComplete) {
          onComplete()
        }
      }
    },
    [intl, dispatch, username, accessToken],
  )
}
