const accessTokenKey = '__token__'

export function storeAccessToken(accessToken) {
  window.localStorage.setItem(accessTokenKey, accessToken)
}

export function clearAccessToken() {
  window.localStorage.removeItem(accessTokenKey)
}

export function getAccessToken() {
  return window.localStorage.getItem(accessTokenKey)
}
