import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Switch, Route, Link, Redirect } from 'react-router-dom'

import { routes } from './constants'
import { addNotification } from 'common/actions/notification'
import BasicLayout from 'common/layouts/BasicLayout'
import SecurityAndSignInView from './SecurityAndSignInView'
import SocialMediaDetailView from '../profile/detailview/SocialMediaDetailView'
import { getSocialMediaInfo } from '../../common/selectors/settings'
import { lowerCase } from 'lodash'
import { hasUIFeatureFlag } from 'common/services/FeatureFlagsService'

const BACK_LINK = '/security-and-sign-in'

const getSocialMediaRouteDetails = providers =>
  providers.map(providerId => ({
    label: 'profile.label.social',
    path: `/${providerId}`,
    view: () => (
      <SocialMediaDetailView type={providerId} backLink={BACK_LINK} />
    ),
  }))

const routeDetails = Object.keys(routes).map(key => ({
  label: routes[key].label,
  path: routes[key].path,
  view: routes[key].view,
  featureFlag: routes[key].featureFlag,
}))

const SecurityAndSignInPage = ({ match }) => {
  const socialMediaInfo = useSelector(getSocialMediaInfo).map(lowerCase)
  const socialMediaRouteDetails = getSocialMediaRouteDetails(socialMediaInfo)

  const allRouteDetails = [...socialMediaRouteDetails, ...routeDetails]

  const dispatch = useDispatch()

  const dispatchAddNotification = props => dispatch(addNotification(props))
  return (
    <Switch>
      {allRouteDetails.map(({ label, path, view, featureFlag }) => {
        const shouldRender = hasUIFeatureFlag(featureFlag)
        if (!shouldRender) return
        return (
          <Route
            key={path}
            path={`${match.path}${path}`}
            render={() => {
              if (view) {
                const DetailView = view
                return (
                  <BasicLayout>
                    <DetailView
                      addNotification={dispatchAddNotification}
                      backLink={BACK_LINK}
                    />
                  </BasicLayout>
                )
              }

              return (
                <div>
                  <Link to={BACK_LINK}>(Back)</Link>
                  {label}
                </div>
              )
            }}
          />
        )
      })}
      <Route exact path={match.path} component={SecurityAndSignInView} />
      <Redirect from="*" to="/security-and-sign-in"></Redirect>
    </Switch>
  )
}

export default SecurityAndSignInPage
