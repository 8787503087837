const ACCOUNT = {
  attribute: {
    number: 'com.uxpsystems.mint.account.AccountNumber',
  },
}

export const ASSOCIATION_PROMPT = {
  identifier: 'AccountIdentifierPrompt',
  selection: 'AccountSelectionPrompt',
  question: 'ChallengeQuestionPrompt',
}

export const DISCOVERY_TYPES = {
  email: 'email',
  mobile: 'mobile',
  account: 'account',
}

export const CANNOT_FIND_ACCOUNT = 'cannot-find-account'

export default ACCOUNT
