import React, { forwardRef } from 'react'
import get from 'lodash/get'
import PropTypes from 'prop-types'
import GoogleRecaptcha from 'react-google-recaptcha'
import styled from 'styled-components'
import { Box, Link } from '@mui/material'
import { useIntl } from 'react-intl'

import conf from 'conf'

const StyledGoogleReCaptcha = styled(GoogleRecaptcha)`
  .grecaptcha-badge {
    visibility: hidden;
  }
`

const StyledRecaptchaText = styled(Box)`
  max-width: 420px;
  font-size: ${props => get(props, 'theme.typography.caption.fontSize')};
  line-height: 1.33;
  letter-spacing: 0.4px;
  margin-bottom: 16px;
  opacity: 0.87;
  a {
    font-size: ${props => get(props, 'theme.typography.caption.fontSize')};
    text-decoration: none;
    color: ${props => get(props, 'theme.palette.primary.main')};
    font-weight: bold;
  }
`

const ReCAPTCHA = forwardRef((props, ref) => (
  <StyledGoogleReCaptcha
    sitekey={conf.recaptchaSiteKey}
    size="invisible"
    ref={ref}
    {...props}
  />
))

export const ReCaptchaText = props => {
  const intl = useIntl()
  const { recaptchaTerms } = props
  const recaptchaTermsId = recaptchaTerms || 'recaptcha.terms'
  return (
    <StyledRecaptchaText style={props.style}>
      {intl.formatMessage(
        { id: recaptchaTermsId },
        {
          'privacy-link': msg => (
            <Link href="https://policies.google.com/privacy">{msg}</Link>
          ),
          'terms-link': msg => (
            <Link href="https://policies.google.com/terms">{msg}</Link>
          ),
        },
      )}
    </StyledRecaptchaText>
  )
}

ReCAPTCHA.propTypes = {
  onChange: PropTypes.func,
}

export default ReCAPTCHA
