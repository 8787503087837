import get from 'lodash/get'
import qs from 'qs'
import { hasUIFeatureFlag } from 'common/services/FeatureFlagsService'

export const getProductId = () => {
  const queryString = qs.parse(window.location.search, {
    ignoreQueryPrefix: true,
  })

  if (hasUIFeatureFlag('enableProductIdAlias')) {
    return get(queryString, 'product_id', get(queryString, 'productId', ''))
  }

  return get(queryString, 'product_id', '')
}

export default getProductId
