import { useState, useEffect, useCallback } from 'react'
import PropTypes from 'prop-types'
import { useDispatch } from 'react-redux'
import { useIntl } from 'react-intl'
import { get } from 'lodash'

import Box from '@mui/material/Box'

import TextField from 'common/components/textfield/TextField'
import * as UXPDialog from 'common/components/dialog/UXPDialog'
import { UserButton } from '@admin-ui-common/base-user'
import Page from 'common/components/Page'
import { Subtitle } from 'common/components/Typography'

import { addNotification } from 'common/actions/notification'
import { loadAccounts } from 'common/actions/accounts'
import { analyticsProvider } from '../../App'
import makeStyles from '@mui/styles/makeStyles'
import ResendCode from '../../common/components/ResendCode'
import { verifyCode } from 'common/processes/token'
import {
  resolveErrorEntity,
  isVerifyOTPError,
  formatErrorMessage,
  isTokenMaxResendReached,
  isInvalidActConsumption,
  isInvalidPkat,
  getErrorCodes,
} from 'common/utils/processBackendErrors'
import { VerifyOTPErrors } from 'common/components/VerifyOTPErros'
import ActionLabel from 'common/components/ActionLabel'
import { TOKEN_MAX_RESEND_REACHED } from 'common/constants'

const errorContext = 'verifyUsername'
const verifyCodeContext = 'verifyCode'

const useStyles = makeStyles(() => ({
  accountNumber: {
    fontWeight: 'bold',
  },
}))

const EnterOTPDialog = ({ onClose, open, accountNumber, stepData }) => {
  const baseEventTag = `enter_otp_dialog.verify_code`

  const [otp, setOtp] = useState('')
  const [inProgress, setInProgress] = useState(false)
  const [errorResponseBody, setErrorResponseBody] = useState()
  const [error, setError] = useState('')
  const [isResendCode, setIsResendCode] = useState(false)
  const [disableTextfield, setDisabledTextfield] = useState(false)
  const [disabledSendNewCode, setDisabledSendNewCode] = useState()
  const [disableVerify, setDisableVerify] = useState(false)

  const intl = useIntl()
  const dispatch = useDispatch()

  const classes = useStyles()

  const [pkat, setPkat] = useState('')
  const tokenId = get(stepData, 'output.tokenSeries')

  useEffect(() => {
    if (open) {
      setError('')
      setErrorResponseBody()
      setDisabledTextfield(false)
      setDisabledSendNewCode(false)
      setDisableVerify(false)
    }
    const initialPkat = get(stepData, 'output.pkat')
    setPkat(initialPkat)
  }, [open, stepData])

  const onHandleOTP = e => {
    setOtp(e.target.value)
    setDisableVerify(false)
  }

  const onResetError = useCallback(() => {
    setError('')
    setErrorResponseBody('')
  }, [])

  const onResendFinish = newPkat => {
    setPkat(newPkat)
    setInProgress(false)
    setIsResendCode(false)

    analyticsProvider.sendAnalytics({
      type: 'event',
      action: 'click',
      event_category: 'button',
      event_label: `${baseEventTag}.resend_code`,
      value: 0,
    })
  }

  const onLinkAccount = () => {
    analyticsProvider.sendAnalytics({
      type: 'event',
      action: 'click',
      event_category: 'button',
      event_label: `enterOTPDialog.link-account`,
      value: 0,
    })
    setInProgress(true)
    setErrorResponseBody(null)
    setError('')
    verifyCode(otp, pkat)
      .then(() => {
        setOtp('')

        dispatch(
          addNotification({
            message: intl.formatMessage(
              { id: 'notification.account-linked-display' },
              {
                accountName: accountNumberPrefix + ' ' + accountNumber,
              },
            ),
            variant: 'success',
          }),
        )
        dispatch(loadAccounts())

        onClose()
      })
      .catch(err => {
        analyticsProvider.sendAnalytics({
          type: 'event',
          action: 'click',
          event_category: 'button',
          event_label: `enterOTPDialog.link-account`,
          value: 1,
        })
        handleOtpErrors(err)
      })
      .finally(() => setInProgress(false))
  }

  const handleOtpErrors = err => {
    if (isInvalidActConsumption(err.body)) {
      let errorMessage = formatErrorMessage({
        intl,
        id: 'error.verification-code-incorrect',
      })
      setError(errorMessage)
      setDisableVerify(true)
      return
    } else if (isVerifyOTPError(err.body)) {
      setErrorResponseBody(err.body)
      setDisableVerify(true)
      setDisabledTextfield(true)
      if (isInvalidPkat(err.body)) {
        setDisabledSendNewCode(true)
      }
      return
    } else if (isTokenMaxResendReached(err.body)) {
      setErrorResponseBody(err.body)
      setDisabledSendNewCode(true)
    } else {
      setError(
        resolveErrorEntity({
          intl,
          error: err.body,
          context: verifyCodeContext,
        }),
      )
      return
    }
  }

  const closeView = () => {
    analyticsProvider.sendAnalytics({
      type: 'event',
      action: 'click',
      event_category: 'button',
      event_label: `enterOTPDialog.close`,
      value: 0,
    })
    setOtp('')
    onClose()
    setError('')
    setErrorResponseBody()
    setDisableVerify(false)
  }

  const accountNumberPrefix = intl.formatMessage({
    id: 'accounts.enter-otp.accounts-prefix',
  })

  const handleResend = () => {
    setIsResendCode(true)
    setDisabledTextfield(false)
    setErrorResponseBody(null)
    setError('')
    setOtp('')
  }

  const handleResendError = useCallback(
    (resendErrorMessage, err) => {
      analyticsProvider.sendAnalytics({
        type: 'event',
        action: 'click',
        event_category: 'button',
        event_label: `${baseEventTag}.resend_code`,
        value: 1,
      })

      analyticsProvider.sendAnalytics({
        error: resendErrorMessage,
      })

      const errorData = err.body || err.data
      const errorCodes = getErrorCodes(errorData)
      if (errorCodes.includes(TOKEN_MAX_RESEND_REACHED)) {
        setErrorResponseBody(errorData)
        setDisabledSendNewCode(true)
      }
    },
    [baseEventTag],
  )

  return (
    <UXPDialog.Dialog open={open} fullScreen fullWidth maxWidth="sm">
      <UXPDialog.Title
        title={intl.formatMessage({ id: 'common.enter-code' })}
        onClose={closeView}
      />
      <UXPDialog.Content>
        <Box display="flex" alignItems="center" mb={2}>
          <Subtitle>{accountNumberPrefix + ' ' + accountNumber}</Subtitle>
        </Box>
        <Box mb={1}>
          <Page.Description>
            {intl.formatMessage(
              { id: 'accounts.enter-otp.description-1' },
              {
                accountNumber: (
                  <span className={classes.accountNumber}>{accountNumber}</span>
                ),
              },
            )}
          </Page.Description>
        </Box>

        <Box mb={3}>
          <Page.Description>
            {intl.formatMessage({ id: 'accounts.enter-otp.description-2' })}
          </Page.Description>
        </Box>
        {!!errorResponseBody && (
          <Box mb={3}>
            <VerifyOTPErrors
              errors={errorResponseBody}
              errorContext={errorContext}
              handleResend={handleResend}
              onClickRedirect={closeView}
            />
          </Box>
        )}
        <TextField
          label="Enter code"
          value={otp}
          onChange={onHandleOTP}
          errorText={error}
          disabled={disableTextfield}
        />

        {disabledSendNewCode ? (
          <ActionLabel
            disabled={disabledSendNewCode}
            variant="text"
            label={intl.formatMessage({ id: `common.countdown-complete` })}
          />
        ) : (
          <ResendCode
            pkat={pkat}
            tokenId={tokenId}
            onResendError={handleResendError}
            onResendStart={onResetError}
            onResendFinish={onResendFinish}
            isResendCode={isResendCode}
          />
        )}
      </UXPDialog.Content>

      <UXPDialog.Actions disabled={inProgress}>
        <UserButton
          color="secondary"
          disableRipple
          disableElevation
          onClick={closeView}
          size="small"
          variant="outlined"
        >
          {intl.formatMessage({ id: 'common.button.cancel' })}
        </UserButton>
        <UserButton
          onClick={onLinkAccount}
          disableRipple
          disableElevation
          disabled={!otp || inProgress || disableVerify}
          size="small"
        >
          {intl.formatMessage({ id: 'accounts.link-account' })}
        </UserButton>
      </UXPDialog.Actions>
    </UXPDialog.Dialog>
  )
}

EnterOTPDialog.propTypes = {
  onClose: PropTypes.func,
  open: PropTypes.bool,
  stepData: PropTypes.object,
  accountNumber: PropTypes.string,
}

export default EnterOTPDialog
