import React, { useState, useEffect } from 'react'
import { useIntl } from 'react-intl'
import makeStyles from '@mui/styles/makeStyles'
import { get } from 'lodash'

import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import Box from '@mui/material/Box'

import { Body2 } from 'common/components/Typography'
import { TextPrimaryButton, Button } from 'common/components/button/Button'

import useDuration from 'common/components/hooks/useDuration'
import { analyticsProvider } from 'App.js'
import useMainRedirect from './hooks/useMainRedirect'
import {
  LOAD_PURCHASE_ERROR,
  purchaseSubscription,
} from '../actions/subscription'
import { useLocation } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { getPaymentMethodId } from '../actions/accounts'
import { getProductId } from 'common/utils/getProductId'

const useStyles = makeStyles(theme => ({
  root: {
    width: '288px',
  },
  title: {
    height: 144,
    objectFit: 'cover',
    width: '100%',
  },
  actions: {
    padding: theme.spacing(2, 3),
  },
}))

const SubscribeContentDialog = ({
  open,
  onClose,
  productInfo = {},
  flowType,
  onSubscribeError = () => {},
}) => {
  const location = useLocation()

  const dispatch = useDispatch()

  const intl = useIntl()
  const [loading, setLoading] = useState(false)
  const formatDuration = useDuration()
  const classes = useStyles()

  const pageTitle = 'subscribe-dialog'
  const baseEventTag = `${flowType}.subscribe-dialog`

  const productId = getProductId()

  useEffect(() => {
    analyticsProvider.sendAnalytics({
      type: 'page_view',
      page_title: pageTitle,
      page_path: `/${flowType}#${pageTitle}`,
    })
  }, [flowType])

  // //////////////////////////////////////////////////////////

  const confirmPurchase = async isConfirmed => {
    setLoading(true)

    const paymentMethodId = await getPaymentMethodId()

    purchaseSubscription({ paymentMethodId, productId })(dispatch)
      .then(res => {
        if (res.type === LOAD_PURCHASE_ERROR) throw new Error('Purchase Error')

        analyticsProvider.sendAnalytics({
          type: 'event',
          action: 'click',
          event_category: 'button',
          event_label: `${baseEventTag}.purchase`,
          value: 0,
        })

        const stepName = isConfirmed ? get(res, 'body.stepName') : 'NoThanks'
        onClose(stepName, null)
      })
      .catch(err => {
        analyticsProvider.sendAnalytics({
          type: 'event',
          action: 'click',
          event_category: 'button',
          event_label: `${baseEventTag}.purchase`,
          value: 1,
        })

        console.error(err)
        onClose(null, true)
        onSubscribeError()
      })
      .finally(() => {
        setLoading(false)
      })
  }

  // //////////////////////////////////////////////////////////

  const onCancel = () => {
    analyticsProvider.sendAnalytics({
      type: 'event',
      action: 'click',
      event_category: 'button',
      event_label: `${baseEventTag}.no-thanks`,
      value: 0,
    })
    confirmPurchase(false)
  }

  // //////////////////////////////////////////////////////////

  const {
    productBillingCycle,
    productCurrency,
    productPrice,
    productTrialPeriod,
    productName,
    serviceDescription,
    shortServiceName,
    appHeroImage,
    purchaseUrl,
  } = productInfo

  const trialPeriod = formatDuration(productTrialPeriod)
  const billingPeriod = formatDuration(productBillingCycle)
  const hideSubscriptionInfo = Boolean(get(productInfo, 'purchaseUrl', false))

  const mainRedirect = useMainRedirect()

  const onPurchase = purchaseUrl => {
    if (purchaseUrl) mainRedirect(purchaseUrl)
    else confirmPurchase(true)
  }

  const HeroImage = ({ image }) => {
    return (
      <Box component="img" src={image} className={classes.title} alt="Logo" />
    )
  }

  return (
    <Dialog open={open} PaperProps={{ classes: { root: classes.root } }}>
      {appHeroImage && <HeroImage image={appHeroImage} />}

      <DialogContent className="mt2">
        <Body2>
          {intl.formatMessage(
            { id: 'onboard.subscribe.content.description' },
            {
              serviceDescription,
            },
          )}
        </Body2>
        {hideSubscriptionInfo ? null : (
          <Body2>
            {intl.formatMessage(
              { id: 'onboard.subscribe.content' },
              {
                product: productName,
                trialPeriod,
                price: productPrice,
                currency: productCurrency,
                billingPeriod,
                shortServiceName,
              },
            )}
          </Body2>
        )}
      </DialogContent>
      <DialogActions className={classes.actions}>
        <TextPrimaryButton onClick={onCancel}>
          {intl.formatMessage({ id: 'onboard.common.no-thanks' })}
        </TextPrimaryButton>
        <Button
          color="primary"
          onClick={() => onPurchase(purchaseUrl)}
          isLoading={loading}
        >
          {intl.formatMessage({ id: 'onboard.common.purchase' })}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default SubscribeContentDialog
