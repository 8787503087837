import React from 'react'
import { injectIntl } from 'react-intl'
import noop from 'lodash/noop'
import TextField from 'common/components/textfield/TextField'
import getAttribute from 'common/utils/getAttribute'
import { constants } from '../Constants'
import { Grid } from '@mui/material'
import { getNameFormat } from 'common/services/FeatureFlagsService'

export const parseFullName = fullName => {
  const { firstName, lastName } = fullName

  if (!lastName && firstName) return firstName
  if (!firstName && lastName) return lastName
  if (firstName && lastName) return `${firstName} ${lastName}`
  return null
}

export const getFullName = ({ user = {} }) => {
  const lastName = getAttribute(user, 'com.uxpsystems.mint.user.FamilyName')
  const firstName = getAttribute(user, 'com.uxpsystems.mint.user.GivenName')

  return {
    firstName: firstName,
    lastName: lastName,
  }
}

export const getDisplayName = ({ user = {} }) => {
  const { displayName } = user

  return displayName
}

const properties = {
  firstName: 'firstName',
  lastName: 'lastName',
  displayName: 'displayName',
}

class NameFields extends React.Component {
  render = () => {
    const {
      className,
      responsiveGrid,
      fullWidth,
      errorTexts,
      onChange,
      onBlur = noop,
      values,
      disabled,
      nameFormat,
      labelKeys,
      intl,
    } = this.props

    const format = nameFormat || getNameFormat().toLowerCase()
    const firstName = values[properties.firstName] || ''
    const lastName = values[properties.lastName] || ''
    const displayName = values[properties.displayName] || ''
    const fistNameLabelId = labelKeys?.firstName || 'sign-up.label.firstname'
    const lastNameLabelId = labelKeys?.lastName || 'sign-up.label.lastname'
    switch (format) {
      case constants.FIRST_LAST_NAME:
        return (
          <Grid container className={responsiveGrid} spacing={2}>
            <Grid item>
              <TextField
                disabled={disabled}
                className={className}
                fullWidth={fullWidth}
                errorText={errorTexts[properties.firstName]}
                label={intl.formatMessage({
                  id: `${fistNameLabelId}`,
                })}
                onChange={onChange(properties.firstName)}
                value={firstName}
                onBlur={onBlur(properties.firstName)}
              />
            </Grid>
            <Grid item>
              <TextField
                disabled={disabled}
                className={className}
                fullWidth={fullWidth}
                errorText={errorTexts[properties.lastName]}
                label={intl.formatMessage({
                  id: `${lastNameLabelId}`,
                })}
                onChange={onChange(properties.lastName)}
                value={lastName}
                onBlur={onBlur(properties.lastName)}
              />
            </Grid>
          </Grid>
        )

      case constants.FULL_NAME: {
        let fullName = displayName
        if (!fullName && firstName && lastName) {
          fullName = `${firstName} ${lastName}`
        }

        return (
          <TextField
            disabled={disabled}
            errorText={errorTexts[properties.displayName]}
            label={intl.formatMessage({
              id: 'sign-up.label.display-name',
            })}
            onChange={onChange(properties.displayName)}
            value={fullName}
            onBlur={onBlur(properties.displayName)}
          />
        )
      }

      default:
        throw Error('Name Format is not configured properly')
    }
  }
}

export default injectIntl(NameFields)
