import React, { useEffect } from 'react'

import VerifyUsername from 'lander/signup/VerifyUsername'

import useOriginRedirect from 'common/components/hooks/useOriginRedirect'
import { analyticsProvider } from 'App.js'

const VerifyMobile = ({ wizard, flowType }) => {
  const { mobile, pkat, tokenId, origin } = wizard.getPageState()
  const originRedirect = useOriginRedirect()

  const pageTitle = 'verify_mobile'
  const baseEventTag = `${flowType}.verify_mobile`

  useEffect(() => {
    analyticsProvider.sendAnalytics({
      type: 'page_view',
      page_title: pageTitle,
      page_path: `/${flowType}#${pageTitle}`,
    })
  }, [flowType])

  const handleVerified = (verified, res) => {
    const { processId, userAuthenticated } = res.body

    analyticsProvider.sendAnalytics({
      type: 'event',
      action: 'click',
      event_category: 'button',
      event_label: `${baseEventTag}.verify`,
      value: 0,
    })

    wizard.setPageState({
      processId,
      userAuthenticated,
      origin,
      mobile,
    })
    wizard.toNextPage()
  }

  const onErrorVerified = errorMessage => {
    analyticsProvider.sendAnalytics({
      type: 'event',
      action: 'click',
      event_category: 'button',
      event_label: `${baseEventTag}.verify`,
      value: 1,
    })

    analyticsProvider.sendAnalytics({
      type: 'event',
      action: 'exception',
      description: `${baseEventTag}.code: ${errorMessage}`,
    })
  }

  const onCancel = () => {
    analyticsProvider.sendAnalytics({
      type: 'event',
      action: 'click',
      event_category: 'button',
      event_label: `${baseEventTag}.cancel`,
      value: 0,
    })

    const isStaticRedirect = originRedirect()
    if (isStaticRedirect) {
      wizard.setPageState({})
      wizard.toPage(0)
    }
  }

  return (
    <VerifyUsername
      process="password-reset"
      username={mobile}
      type="phone"
      actionName="passworRecovery"
      pkat={pkat}
      tokenId={tokenId}
      onVerified={handleVerified}
      onErrorVerified={onErrorVerified}
      reset={onCancel}
      forcedUsername
      wizard={wizard}
    />
  )
}

export default VerifyMobile
