import React from 'react'
import MUPaper from '@mui/material/Paper'
import { useMediaQuery, useTheme } from '@mui/material'

import withStyles from '@mui/styles/withStyles'

const muiPaperStyles = theme => ({
  root: {
    [theme.breakpoints.down('sm')]: {
      borderRadius: 'unset',
      backgroundColor: props =>
        props.hideOnMobile && theme.palette.background.default,
    },
    borderRadius: 8,
    padding: props => props.padding || 20,
  },
})

const Paper = ({
  classes,
  children,
  padding,
  hideOnMobile = false,
  ...rest
}) => {
  const theme = useTheme()
  const matches = useMediaQuery(theme.breakpoints.down('sm')) && hideOnMobile

  return (
    <MUPaper elevation={matches ? 0 : undefined} classes={classes} {...rest}>
      {children}
    </MUPaper>
  )
}

export default withStyles(muiPaperStyles)(Paper)
