import React from 'react'

import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'

import styled from 'styled-components'

export const DialogTitle = props => (
  <Box {...props}>
    <Typography variant="h6">{props.children}</Typography>
  </Box>
)

export const Title = props => (
  <Box mb={4} {...props}>
    <Typography variant="h4">{props.children}</Typography>
  </Box>
)

export const ListSubheader = props => (
  <Box pt={2} mx={2} mb={2} {...props}>
    <Typography variant="h6">{props.children}</Typography>
  </Box>
)

const SubtitleTypography = styled(Typography)`
  font-weight: 700;
`
export const Subtitle = props => (
  <Box {...props}>
    <SubtitleTypography variant="subtitle1">
      {props.children}
    </SubtitleTypography>
  </Box>
)

export const DetailTitle = props => (
  <Box {...props}>
    <Typography variant="subtitle1">{props.children}</Typography>
  </Box>
)

export const Body = props => (
  <Box mb={props.mb || 1} {...props}>
    <Typography
      color={props.color}
      variant="body1"
      style={{
        fontWeight: props.fontWeight,
        letterSpacing: props.letterSpacing,
      }}
    >
      {props.children}
    </Typography>
  </Box>
)

export const Body2 = props => (
  <Box mb={1} {...props}>
    <Typography className={props.className} variant="body2">
      {props.children}
    </Typography>
  </Box>
)

const CaptionTypography = styled(Typography)`
  font-weight: 500;
`
export const Caption = props => (
  <Box {...props}>
    <CaptionTypography variant="caption">{props.children}</CaptionTypography>
  </Box>
)

export const Sidenote = props => (
  <Box {...props}>
    <Typography variant="caption">{props.children}</Typography>
  </Box>
)
