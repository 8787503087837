import { useState } from 'react'
import { TopHeader } from '@admin-ui-common/base-user'
import UnauthenticatedFooter from 'common/components/UnauthenticatedFooter'
import { FormattedMessage } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import clsx from 'clsx'

import { getDisplayName } from 'common/components/nameField/NameFields'
import { endSession as endSessionBase } from 'common/actions/session'
import { Box, useMediaQuery } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import SideBar from 'protected/common/SideBar'
import { useTheme } from '@mui/material/styles'
import useLogoProvider from 'common/components/hooks/useLogoProvider'
import { clearAccessToken } from 'common/utils/accessToken'

const useStyles = makeStyles(theme => ({
  page: {
    background: theme.palette.background.default,
    display: 'flex',
    flex: 1,
    flexDirection: props => (props.hasSession ? 'row' : 'column'),
    alignItems: 'stretch',
    justifyContent: 'start',
    [theme.breakpoints.down('sm')]: {
      paddingTop: 0,
      paddingBottom: 0,
    },
  },
  hasSession: {
    paddingTop: 0,
    paddingBottom: 0,
  },
  header: {
    zIndex: theme.zIndex.drawer + 1,
  },
}))

function BasicLayout({ children }) {
  const hasSession = useSelector(state => state.session.hasSession)
  const displayName = useSelector(getDisplayName)
  const dispatch = useDispatch()
  const endSession = () => {
    clearAccessToken()
    endSessionBase()(dispatch)
  }
  const classes = useStyles({ hasSession })
  const { logoImage } = useLogoProvider()

  const enableOnlyWizardRoutes = useSelector(
    state => state.configuration.config.enableOnlyWizardRoutes,
  )

  const [isSidebarOpen, setSidebarOpen] = useState(false)
  const sidebarToggle = () => setSidebarOpen(prev => !prev)

  const theme = useTheme()
  const shouldIntegrateNavbar = useMediaQuery(
    `@media (max-width: ${theme.breakpoints.values.lg}px)`,
  )

  if (enableOnlyWizardRoutes)
    return (
      <Box
        display="flex"
        flex={1}
        justifyContent="center"
        alignItems="center"
        flexDirection="column"
      >
        {children}
      </Box>
    )

  return (
    <>
      <TopHeader
        position={hasSession ? 'sticky' : 'relative'}
        showSidebarIcon={hasSession && shouldIntegrateNavbar}
        user={{
          displayName: displayName,
        }}
        logoUrl={logoImage}
        sidebarToggle={sidebarToggle}
        profileMenuOptions={
          hasSession
            ? [
                {
                  label: (
                    <FormattedMessage
                      id="common.button.sign-out"
                      defaultMessage={`Sign Out`}
                    />
                  ),
                  action: endSession,
                },
              ]
            : []
        }
      />

      <div
        className={clsx(
          classes.page,
          'flex justify-center',
          hasSession && !enableOnlyWizardRoutes && classes.hasSession,
          { 'items-center': !hasSession || enableOnlyWizardRoutes },
          { 'flex-column': !hasSession },
        )}
      >
        {hasSession && !enableOnlyWizardRoutes && (
          <Box
            display={{
              xs: 'none',
              lg: 'block',
            }}
          >
            <SideBar open={isSidebarOpen} sidebarToggle={sidebarToggle} />
          </Box>
        )}

        {children}
      </div>
      <UnauthenticatedFooter />
    </>
  )
}

export default BasicLayout
