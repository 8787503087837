import React from 'react'

import Wizard from 'common/components/wizard/Wizard'
import VerifyMobile from './VerificationMobile'
import SignIn from '../common/SignIn'
import VerifyCode from './VerificationCode'
import CancelSuccess from './CancelSuccess'
import NotEligible from '../common/NotEligible'
import AlreadyUnsubscribed from './AlreadyUnsubscribed'
import NotFound from '../common/NotFound'

export const WIZARD_VIEW_INDEX = {
  verifyMobile: 0,
  signIn: 1,
  verifyCode: 2,
  cancelSuccess: 3,
  notEligible: 4,
  alreadyUnsubscribed: 5,
  notFound: 6,
}

const Views = [
  VerifyMobile,
  SignIn,
  VerifyCode,
  CancelSuccess,
  NotEligible,
  AlreadyUnsubscribed,
  NotFound,
]

const CancelSubscribeWizard = props => (
  <Wizard>
    {Views.map((View, i) => (
      // eslint-disable-next-line react/no-array-index-key
      <View key={i} {...props} wizardIndex={WIZARD_VIEW_INDEX} />
    ))}
  </Wizard>
)

export default CancelSubscribeWizard
