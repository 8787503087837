import React, { useReducer } from 'react'
import { connect } from 'react-redux'
import classNames from 'classnames'
import { get, isEmpty } from 'lodash'
import { injectIntl } from 'react-intl'
import { Button } from 'common/components/button/Button'
import SocialMediaButtons from 'common/components/socialMediaButton'
import { getUsernameValidationErrorSmart } from 'common/utils/getUsernameValidationError'
import isEmail from 'common/utils/isEmail'
import { startSession, checkSession } from 'common/actions/session'
import { loginWithSocialMedia } from 'common/actions/socialMedia'
import enrollUser from 'common/processes/enrollUserV2'
import getCountryCode from 'common/utils/getCountryCode'

import FeatureFlagsService, {
  hasUIFeatureFlag,
} from 'common/services/FeatureFlagsService'

import styles from '../../styles.module.css'
import Collapse from '@mui/material/Collapse'
import Box from '@mui/material/Box'
import { EmailOrMobile, UserTextLink } from '@admin-ui-common/base-user'
import { BackendErrorNotification } from 'common/components/backendErrorNotification/BackendErrorNotification'

import FlowLayout from 'common/layouts/FlowLayout'
import reducer from '.././reducer'
import ACTIONS from '.././actions'
import { analyticsProvider } from '../../../App'
import VerifyUsername from './VerifyUsername'
import ResultVerifyUsername from './ResultVerifyUsername'
import { Link, useLocation } from 'react-router-dom'

import ProvideOnboardingDetails from './ProvideOnboardingDetails'
import {
  useEmailValidator,
  usePhoneValidator,
} from 'common/components/hooks/validators'

import CheckMarkIcon from 'common/components/CheckMarkIcon'
import LinkAccount from './LinkAccount'
import EnterCode from './EnterCode'
import useMainRedirect from 'common/components/hooks/useMainRedirect'
import { getIdentifierMode } from 'common/utils/identifier-utils'
import { getOperationErrorCodes } from 'common/utils/processBackendErrors'
import stripIdentity from 'common/utils/stripIdentity'
import isPhone from 'common/utils/isPhone'
import formatNumber from 'common/utils/formatNumber'
import getDefaultIdentifier from 'common/utils/getDefaultIdentifier'

const getInitialState = ({ intl, username }) => {
  const { dialCode, iso2Code } = getCountryCode()
  return {
    step: null,
    verified: true,
    username: username || '',
    countryCode: dialCode,
    isoCode: iso2Code,
    usernameType: '',
    pkat: '',
    code: '',
    verificationResponse: {},
    tokenId: null,
    response: null,
    errors: {},
    isLoading: false,
    errorMessages: {
      username: intl.formatMessage({ id: 'error.username' }),
    },
    termsAndConditions: true,
    errorResponseBody: null,
  }
}

const enableOIDCLoginFlow = FeatureFlagsService.get(
  'uiFeatureFlags.idp.enableOIDCLoginFlow',
)

const SignUp = props => {
  const isValidEmail = useEmailValidator()
  const isValidPhone = usePhoneValidator()

  const { intl, locale } = props

  const baseEventTag = `signup.signUp`

  const location = useLocation()
  const { hash } = location
  const currentPath = location.pathname
  const allowedIdentifiers = hasUIFeatureFlag('allowedOnboardingIdentifiers')
  const { iso2Code } = getCountryCode()

  const [state, dispatch] = useReducer(
    reducer,
    getInitialState({
      intl: props.intl,
      username: getDefaultIdentifier(
        location.state?.identifier,
        allowedIdentifiers,
        iso2Code,
      ),
    }),
  )

  const identifierMode = getIdentifierMode(allowedIdentifiers)

  const promptCaptcha = FeatureFlagsService.get('system.security.promptCaptcha')
  const showSuccessScreen = hasUIFeatureFlag('showSuccessScreen')
  const isEmailIdentifierOnly = identifierMode === 'email'
  const isPhoneIdentifierOnly = identifierMode === 'phone'
  const availableCountryCodes = hasUIFeatureFlag('availableCountryCodes')
  const excludeCountryCode = hasUIFeatureFlag('excludeCountryCode')

  const isSocialOnboardingAllowed = allowedIdentifiers?.includes('social')
  const mainRedirect = useMainRedirect()

  const usernameLabelKey = (() => {
    if (isEmailIdentifierOnly) {
      return 'separatedMultiStep.onboard-enterIdentifier.label.email'
    } else if (isPhoneIdentifierOnly) {
      return 'separatedMultiStep.onboard-enterIdentifier.label.mobile'
    } else {
      return 'separatedMultiStep.onboard-enterIdentifier.label.emailOrMobile'
    }
  })()

  const validate = fieldName => event => {
    let { errors } = state
    let value = event.target.value.trim()
    switch (fieldName) {
      case 'username':
        const usernameError = getUsernameValidationErrorSmart(
          intl,
          value,
          allowedIdentifiers,
          isValidEmail,
          isValidPhone,
        )
        errors[fieldName] = usernameError
        break

      default:
        if (value === '') {
          errors[fieldName] = state.errorMessages[fieldName]
        } else {
          errors[fieldName] = ''
        }
        break
    }

    dispatch({
      type: ACTIONS.SIGN_UP_UPDATE_ERRORS,
      payload: {
        errors,
      },
    })
  }

  const handleUsernameChange = value => {
    if (state.errors) {
      dispatch({
        type: ACTIONS.SIGN_UP_UPDATE_STATE,
        payload: {
          errors: {},
          errorResponseBody: null,
        },
      })
    }
    dispatch({
      type: ACTIONS.SIGN_UP_UPDATE_USERNAME,
      payload: { username: formatNumber(state.isoCode, value) },
    })
  }

  const handleProvideOnboardingDetailsCompletion = (
    verified,
    response,
    hasError,
  ) => {
    const state = {
      step: showSuccessScreen ? 'ResultVerifyUsername' : null,
      verified,
    }
    let userAuthenticated
    if (!isEmpty(response)) {
      userAuthenticated = get(response, 'body.userAuthenticated')
      state.response = response

      if (userAuthenticated) {
        state.userAuthenticated = userAuthenticated
      }
    }

    if (!hasError) {
      dispatch({
        type: ACTIONS.SIGN_UP_HANDLE_VERIFIED,
        payload: state,
      })
      if (!showSuccessScreen) {
        userAuthenticated ? handleRedirect(state) : goHome()
      }
    }
    if (showSuccessScreen) {
      history.push(`${currentPath}#ResultVerifyUsername`)
    }
  }

  const handleVerified = (verified, response, code, pkat) => {
    const state = {
      step: 'ProvideOnboardingDetails',
      code,
      pkat,
      verified,
      verificationResponse: response,
    }
    const isLastStep = get(response, 'body.lastStep')

    if (!isEmpty(response)) {
      const userAuthenticated = get(response, 'body.userAuthenticated')
      state.response = response

      if (userAuthenticated) {
        state.userAuthenticated = userAuthenticated
      }
    }

    dispatch({
      type: ACTIONS.SIGN_UP_HANDLE_VERIFIED,
      payload: state,
    })
    if (isLastStep) {
      handleProvideOnboardingDetailsCompletion(true, response, false)
    } else {
      history.push(`${currentPath}#ProvideOnboardingDetails`)
    }
  }

  const handleRedirect = recentState => {
    const { metadata, body } = get(recentState, 'response', state.response)
    const isAuthProcessComplete = Boolean(
      body.userId || body.lastStep || body.userAuthenticated,
    )

    if (isAuthProcessComplete) {
      const isStaticRedirect = mainRedirect(metadata)
      if (!isStaticRedirect) {
        return
      }
    }
    props.checkSession()
  }

  const goHome = () => props.history.push('/')

  const resetStep = () => {
    dispatch({
      type: ACTIONS.SIGN_UP_RESET_STEP,
      payload: getInitialState(props),
    })
  }

  const handleOtpCode = () => {
    window.location.reload()
  }

  const handleAccountIdentiferPrompt = payload => {
    dispatch({ type: ACTIONS.SIGN_UP_UPDATE_STATE, payload: payload })
  }

  const handleLinkAccount = data => {
    dispatch({
      type: ACTIONS.SIGN_UP_UPDATE_STATE,
      payload: { step: 'EnterOtpCodeStep', ...data },
    })
  }

  const onError = err => {
    const operationErrorCodes = getOperationErrorCodes(err.body)

    if (!operationErrorCodes.includes('already-exist-email')) {
      // generic error path (NOT already-exist-email)
      dispatch({
        type: ACTIONS.SIGN_UP_UPDATE_STATE,
        payload: {
          errorResponseBody: err.body,
          isLoading: false,
        },
      })
      return
    }

    // if we get here then it is an `already-exist-email` error

    dispatch({
      type: ACTIONS.SIGN_UP_UPDATE_STATE,
      payload: {
        errors: {
          username: intl.formatMessage(
            {
              id: 'sign-up.id-exists',
            },
            {
              'sign-in-link': str => (
                <Link
                  to={{
                    pathname: '/',
                    search: window.location.search,
                    state: { usernameEntered: username },
                  }}
                >
                  <UserTextLink underline="always">{str}</UserTextLink>
                </Link>
              ),
            },
          ),
        },
        isLoading: false,
      },
    })
  }

  const signUp = event => {
    event.preventDefault()

    analyticsProvider.sendAnalytics({
      type: 'event',
      action: 'click',
      event_category: 'button',
      event_label: `${baseEventTag}.next-btn`,
      value: 0,
    })

    const errors = {}

    let username = state.username.trim()
    let countryCode = state.countryCode

    let usernameType = 'email'
    if (isPhone(username)) {
      usernameType = 'phone'
    }

    const usernameError = getUsernameValidationErrorSmart(
      intl,
      username,
      allowedIdentifiers,
      isValidEmail,
      isValidPhone,
    )
    if (!username && isEmpty(usernameError)) {
      errors.username = state.errorMessages.username
    } else if (!isEmpty(usernameError)) {
      errors.username = usernameError
    }

    if (Object.keys(errors).length) {
      analyticsProvider.sendAnalytics({
        type: 'event',
        action: 'click',
        event_category: 'button',
        event_label: `${baseEventTag}.next-btn`,
        value: 1,
      })
      dispatch({ type: ACTIONS.SIGN_UP_UPDATE_ERRORS, payload: { errors } })
      return Promise.resolve()
    }

    dispatch({
      type: ACTIONS.SIGN_UP_UPDATE_STATE,
      payload: {
        errors: {},
        errorResponseBody: null,
        isLoading: true,
      },
    })
    const conditionalCountryCode = excludeCountryCode ? '' : countryCode
    const onboardIdentifier =
      usernameType === 'phone' ? conditionalCountryCode + username : username

    enrollUser(
      {
        identifier: stripIdentity(onboardIdentifier),
      },
      {
        'ui-flow-type': 'onboard',
      },
      enableOIDCLoginFlow ?? {
        headers: {
          'x-idp-authentication': true,
        },
      },
    )
      .then(res => {
        const pkat = get(res, 'body.output.pkat')
        const tokenId = get(res, 'body.output.tokenId')
        const userAuthenticated = get(res, 'body.userAuthenticated')

        dispatch({
          type: ACTIONS.SIGN_UP_UPDATE_STATE,
          payload: {
            step: 'VerifyUsername',
            response: res,
            pkat,
            tokenId,
            userAuthenticated,
            isLoading: false,
          },
        })
        history.push(`${currentPath}#VerifyUsername`)
      })
      .catch(err => {
        analyticsProvider.sendAnalytics({
          type: 'event',
          action: 'click',
          event_category: 'button',
          event_label: `${baseEventTag}.sign-up-btn`,
          value: 1,
        })
        onError(err)
      })

    dispatch({
      type: ACTIONS.SIGN_UP_UPDATE_STATE,
      payload: {
        username,
        usernameType,
      },
    })
  }

  const {
    step,
    pkat,
    verificationResponse,
    tokenId,
    username,
    countryCode,
    verified,
    termsAndConditions,
    errors,
    userAuthenticated,
    response,
  } = state
  const { history } = props

  const signInClasses = classNames('mt2')
  const socialTextKeys = {
    continue:
      'separatedMultiStep.onboard-enterIdentifier.button.socialProvider',
    alternateOptions:
      'separatedMultiStep.onboard-enterIdentifier.label.socialProviders-onboard',
  }

  let usernameType = ''
  if (isEmail(username)) {
    usernameType = 'email'
  } else if (isPhone(username)) {
    usernameType = 'phone'
  }

  const getInputType = usernameType => {
    switch (usernameType) {
      case 'email':
        return 'email'
      case 'phone':
        return 'tel'
      default:
        return 'text'
    }
  }

  const getVerifyUserFlowSubStep = usernameType => {
    switch (usernameType) {
      case 'email':
        return 'verifyEmailOTP'
      case 'phone':
        return 'verifyMobileOTP'
      default:
        return ''
    }
  }

  const inputType = getInputType(usernameType)
  const verifyUserFlowSubStep = getVerifyUserFlowSubStep(usernameType)

  const onCancel = () => {
    analyticsProvider.sendAnalytics({
      type: 'event',
      action: 'click',
      event_category: 'button',
      event_label: `${state.step}.cancel`,
      value: 0,
    })

    resetStep()
  }

  if (
    !pkat &&
    [
      '#VerifyUsername',
      '#ResultVerifyUsername',
      '#ProvideOnboardingDetails',
    ].includes(hash)
  ) {
    props.history.push('/signup')
  }

  if (step === 'EnterOtpCodeStep') {
    return <EnterCode handleOtpCode={handleOtpCode} signUpState={state} />
  }
  if (step === 'AccountIdentifierPrompt') {
    return <LinkAccount onLinkAccount={handleLinkAccount} response={response} />
  }

  if (hash === '#ProvideOnboardingDetails') {
    return (
      <ProvideOnboardingDetails
        onAccountIdentiferPrompt={handleAccountIdentiferPrompt}
        verificationResponse={verificationResponse}
        onProcessCompletion={handleProvideOnboardingDetailsCompletion}
        locale={locale}
      />
    )
  }

  if (hash === '#VerifyUsername') {
    return (
      <FlowLayout
        title={intl.formatMessage({
          id: `separatedMultiStep.onboard-${verifyUserFlowSubStep}.title`,
        })}
      >
        <VerifyUsername
          reset={resetStep}
          process="sign-up"
          countryCode={countryCode}
          username={username}
          type={usernameType}
          actionName="onboardUser"
          pkat={pkat}
          tokenId={tokenId}
          onVerified={handleVerified}
          history={history}
          onCancel={onCancel}
        />
      </FlowLayout>
    )
  }
  if (hash === '#ResultVerifyUsername') {
    const type =
      usernameType === 'email'
        ? intl.formatMessage({ id: 'verify-result.email' })
        : intl.formatMessage({ id: 'verify-result.mobile' })
    return (
      <FlowLayout
        title={
          !verified
            ? intl.formatMessage({ id: 'verify-result.you-almost-set' })
            : intl.formatMessage(
                { id: 'verify-result.progressive.title' },
                { type },
              )
        }
        image={CheckMarkIcon}
      >
        <ResultVerifyUsername
          verificationType={usernameType}
          verified={verified}
          onConfirm={userAuthenticated ? handleRedirect : goHome}
          userAuthenticated={userAuthenticated}
        />
      </FlowLayout>
    )
  }
  return (
    <FlowLayout
      title={intl.formatMessage({
        id: 'separatedMultiStep.onboard-enterIdentifier.title',
      })}
      subtitle={
        <span>
          {intl.formatMessage(
            { id: 'separatedMultiStep.onboard-enterIdentifier.subtitle' },
            {
              signin: str => (
                <Link to={{ pathname: '/', search: location.search }}>
                  <UserTextLink underline="always">{str}</UserTextLink>
                </Link>
              ),
            },
          )}
        </span>
      }
      showCaptcha={promptCaptcha}
    >
      <form onSubmit={signUp}>
        <Collapse in={state.errorResponseBody !== null}>
          <Box mb={3}>
            <BackendErrorNotification
              intl={intl}
              error={state.errorResponseBody}
              handleClose={() => {
                dispatch({
                  type: ACTIONS.SIGN_UP_UPDATE_STATE,
                  payload: { errorResponseBody: null },
                })
              }}
            />
          </Box>
        </Collapse>
        <EmailOrMobile
          mode={identifierMode}
          onChange={handleUsernameChange}
          defaultValue={state.username}
          defaultMobileNumber={isPhone(username)}
          textFieldProps={{
            value: state.username,
            autoFocus: true,
            inputProps: {
              'data-testid': 'username-textfield',
              autoCapitalize: 'none',
              autoComplete: 'off',
            },
            errorProps: errors.username
              ? { errorMessage: errors.username }
              : undefined,
            type: inputType,
            label: intl.formatMessage({ id: usernameLabelKey }),
            onBlur: validate('username'),
          }}
          countryCode={state.isoCode}
          mobileNumberInputProps={{
            textFieldProps: {
              value: state.username,
              autoFocus: true,
              inputProps: {
                'data-testid': 'username-textfield',
                autoCapitalize: 'none',
                autoComplete: 'off',
              },
              errorProps: errors.username
                ? { errorMessage: errors.username }
                : undefined,
              type: inputType,
            },
            selectProps: {
              inputProps: {
                'data-testid': 'country-code',
              },
            },
            label: intl.formatMessage({
              id:
                'separatedMultiStep.authenticate-enterIdentifier.label.mobile',
            }),
            countryList: availableCountryCodes,
          }}
          onCountryCodeChange={(value, isoCode) => {
            dispatch({
              type: ACTIONS.SIGN_UP_UPDATE_COUNTRY_CODE,
              payload: {
                countryCode: value,
              },
            })
            dispatch({
              type: ACTIONS.SIGN_UP_UPDATE_ISO_CODE,
              payload: {
                isoCode: isoCode,
              },
            })
            dispatch({
              type: ACTIONS.SIGN_UP_UPDATE_USERNAME,
              payload: { username: formatNumber(isoCode, state.username) },
            })
          }}
        />
        <div className={styles.fieldMB} />
        <div className={signInClasses}>
          <Button
            size="large"
            isLoading={state.isLoading}
            onClick={signUp}
            data-testid="signup-button-next"
            disabled={!termsAndConditions}
          >
            {intl.formatMessage({ id: 'common.button.next' })}
          </Button>
        </div>
      </form>
      {isSocialOnboardingAllowed && (
        <>
          <SocialMediaButtons textKeys={socialTextKeys} intl={intl} />
        </>
      )}
    </FlowLayout>
  )
}

const mapDispatch = {
  startSession,
  checkSession,
  loginWithSocialMedia,
}

export default connect(null, mapDispatch)(injectIntl(SignUp))
