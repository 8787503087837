import React from 'react'
import PropTypes from 'prop-types'
import { get, isEmpty, noop } from 'lodash'
import { FormattedMessage, injectIntl } from 'react-intl'

import conf from 'conf'
import i from 'common/utils/i'
import { labelFormatter } from 'protected/profile/constants'
import TextField from 'common/components/textfield/TextField'
import { Button } from 'common/components/button/Button'
import * as UXPCard from 'common/components/Card'
import styles from '../styles.module.css'
import { Typography } from '@mui/material'
import Box from '@mui/material/Box'
import { UserTextLink } from '@admin-ui-common/base-user'

import withTheme from '@mui/styles/withTheme'
import ActionLabel from '../../common/components/ActionLabel'
import { analyticsProvider } from '../../App'
import { connect, useSelector } from 'react-redux'
import { compose } from 'redux'
import Page from 'common/components/Page'
import FlowLayout from 'common/layouts/FlowLayout'
import {
  formatErrorMessage,
  isVerifyOTPError,
  resolveErrorEntity,
  isTokenMaxResendReached,
  isInvalidActConsumption,
  isInvalidPkat,
  isTooManyRetries,
} from 'common/utils/processBackendErrors'
import { verifyCode } from 'common/processes/token'
import { displayIdentifier } from 'common/utils/formatNumber'
import { VerifyOTPErrors } from 'common/components/VerifyOTPErros'

const errorContext = 'verifyUsername'

class VerifyUsername extends React.Component {
  static propTypes = {
    process: PropTypes.oneOf(['sign-up', 'forgot-password', 'password-reset']),
    username: PropTypes.string,
    type: PropTypes.oneOf(['email', 'phone']),
    actionName: PropTypes.oneOf(['onboardUser', 'passworRecovery']),
    pkat: PropTypes.string,
    tokenId: PropTypes.number,
    onVerified: PropTypes.func,
    onErrorVerified: PropTypes.func,
    forcedUsername: PropTypes.bool,
  }

  static defaultProps = {
    onErrorVerified: noop,
  }

  constructor(props) {
    super(props)
    this.state = {
      code: '',
      error: '',
      countdown: conf.resendOTPCountdownDuration,
      pkat: this.props.pkat,
      disableOTPField: false,
      maxWrongAttemptsReached: false,
      disableVerify: false,
      errors: '',
      errorResponseBody: null,
      errorTitle: '',
      disableCodeField: false,
    }
    this.pageTitle = 'verifyUsername'
    this.baseEventTag = `signup.${this.pageTitle}`
  }

  componentDidMount() {
    analyticsProvider.sendAnalytics({
      type: 'page_view',
      page_title: this.pageTitle,
      page_path: `/signup#${this.pageTitle}`,
    })
    this.runCountDown()
  }

  componentWillUnmount() {
    clearInterval(this.interval)
  }

  runCountDown() {
    this.interval = setInterval(() => {
      const { countdown } = this.state
      if (countdown === 1) {
        clearInterval(this.interval)
        this.setState({ disableOTPField: false })
      }
      this.setState({ countdown: countdown - 1 })
    }, 1000)
  }

  handleCodeChange = event => {
    if (!isEmpty(event.target.value) && !isEmpty(this.state.error)) {
      this.setState({ error: '' })
    }
    this.setState({ code: event.target.value, disableVerify: false })
  }

  getLabelKey = () => {
    return this.props.type === 'email' ? 'verifyEmailOTP' : 'verifyMobileOTP'
  }

  handleVerify = event => {
    event.preventDefault()
    analyticsProvider.sendAnalytics({
      type: 'event',
      action: 'click',
      event_category: 'button',
      event_label: `${this.baseEventTag}.verify`,
      value: 0,
    })
    const { code, pkat } = this.state
    const { actionName, intl, onVerified, onErrorVerified } = this.props
    let error = ''

    if (isEmpty(code)) {
      error = intl.formatMessage({ id: 'error.verification-code-incorrect' })
    }

    if (!isEmpty(error)) {
      analyticsProvider.sendAnalytics({
        type: 'event',
        action: 'click',
        event_category: 'button',
        event_label: `${this.baseEventTag}.verify`,
        value: 1,
      })
      this.setState({ error })
      return
    }

    verifyCode(code, pkat, actionName)
      .then(res => {
        onVerified(true, res)
      })
      .catch(err => {
        analyticsProvider.sendAnalytics({
          type: 'event',
          action: 'click',
          event_category: 'button',
          event_label: `${this.baseEventTag}.verify`,
          value: 1,
        })
        this.handleOtpErrors(err)
      })
  }

  handleVerifyLater = event => {
    event.preventDefault()
    analyticsProvider.sendAnalytics({
      type: 'event',
      action: 'click',
      event_category: 'button',
      event_label: `${this.baseEventTag}.verify-later`,
      value: 0,
    })
    this.props.onVerified(false)
  }

  handleResend = event => {
    analyticsProvider.sendAnalytics({
      type: 'event',
      action: 'click',
      event_category: 'button',
      event_label: `${this.baseEventTag}.resend`,
      value: 0,
    })

    const { countdown } = this.state

    event.preventDefault()
    if (countdown === 0) {
      const { pkat } = this.state
      const { tokenId, actionName } = this.props
      const resendUrl = `${conf.apiRoot}/session/token?tokenId=${tokenId}&pkat=${pkat}&actionName=${actionName}`

      if (pkat) {
        i.put(resendUrl, { ignoreErrorRedirect: true })
          .then(res => {
            const pkat = get(res, 'body.pkat')
            this.setState(
              {
                countdown: conf.resendOTPCountdownDuration,
                pkat,
                disableOTPField: true,
                disableVerify: false,
                maxWrongAttemptsReached: false,
                errorResponseBody: null,
                disableCodeField: false,
                error: '',
              },
              this.runCountDown,
            )
          })
          .catch(err => {
            analyticsProvider.sendAnalytics({
              type: 'event',
              action: 'click',
              event_category: 'button',
              event_label: `${this.baseEventTag}.resend`,
              value: 1,
            })
            this.handleOtpErrors(err)
          })
      } else {
        this.setState(
          {
            countdown: conf.resendOTPCountdownDuration,
          },
          this.runCountDown,
        )
      }
    }
  }

  handleOtpErrors = err => {
    const { intl } = this.props

    if (isInvalidActConsumption(err.body)) {
      let errorMessage = formatErrorMessage({
        intl,
        id: 'error.verification-code-incorrect',
      })
      this.setState({ error: errorMessage, disableVerify: true })
      return
    } else if (isVerifyOTPError(err.body)) {
      this.setState({
        errorResponseBody: err.body,
        disableVerify: true,
        disableCodeField: true,
        countdown: 0,
        code: '',
      })
      clearInterval(this.interval)
      if (isInvalidPkat(err.body)) {
        this.setState({ countdown: true })
      }
      return
    } else if (isTokenMaxResendReached(err.body)) {
      clearInterval(this.interval)
      this.setState({
        errorResponseBody: err.body,
        code: '',
        countdown: true,
      })
    } else if (isTooManyRetries(err.body)) {
      this.setState({
        maxWrongAttemptsReached: true,
        error: formatErrorMessage({
          id: 'error.process-terminated-with-too-many-retries.context.sign-in',
          intl,
        }),
        disableVerify: true,
      })
    } else {
      this.setState({ error: resolveErrorEntity({ intl, error: err.body }) })
      return
    }
  }

  getCountdown() {
    const { intl } = this.props
    const { countdown } = this.state

    if (countdown === 0) {
      return intl.formatMessage({
        id: `separatedSingleStep.onboard-${this.getLabelKey()}.textLink.resendCode-countdownComplete`,
      })
    }

    return intl.formatMessage(
      {
        id: `separatedSingleStep.onboard-${this.getLabelKey()}.textLink.resendCode-countdown`,
      },
      { countdown },
    )
  }

  getMessageID = () => {
    const { type, forcedUsername, process } = this.props

    return forcedUsername
      ? `${process}.verify-username.${type}.forced.message`
      : `separatedSingleStep.onboard-${this.getLabelKey()}.body`
  }

  onCancel = () => {
    const { wizard, reset } = this.props
    if (wizard) {
      wizard.toPage(0)
    } else {
      reset()
    }
  }

  getFormattedMessage = () => {
    const { type, isoCode, username, appId } = this.props
    return username?.includes('***')
      ? username
      : displayIdentifier(username, type, appId, isoCode)
  }

  resend = str => {
    const { theme } = this.props
    return (
      <UserTextLink
        onClick={this.handleResend}
        style={{ color: theme.palette.text.primary }}
        underline="always"
        variant="text"
      >
        {str}
      </UserTextLink>
    )
  }

  render() {
    const {
      username,
      type,
      process,
      intl,
      appId,
      flowType,
      wizard,
      history,
    } = this.props
    const {
      code,
      countdown,
      error,
      maxWrongAttemptsReached,
      isLoading,
      disableVerify,
      errorResponseBody,
      disableCodeField,
    } = this.state

    const resetDisabled = Boolean(countdown) || maxWrongAttemptsReached
    const formattedMessage =
      type === 'phone' || type === 'sms'
        ? labelFormatter.mobile(username, appId)
        : username
    const disableVerifyButton = disableVerify || isEmpty(code)
    const actionStyle =
      flowType !== 'combinedMultiPage' ? { justifyContent: 'flex-start' } : null
    return (
      <>
        <Box className={styles.verifyMessage}>
          <Typography variant="body1">
            <FormattedMessage
              id={this.getMessageID()}
              values={{
                username: (
                  <span className={styles.username}>
                    {this.getFormattedMessage()}
                  </span>
                ),
              }}
            />
          </Typography>
        </Box>
        {!!errorResponseBody && (
          <Box mb={3}>
            <VerifyOTPErrors
              errors={errorResponseBody}
              errorContext={errorContext}
              handleResend={this.handleResend}
              onClickRedirect={() => (wizard ? wizard.toPage(0) : '')}
              redirectTo={
                !wizard
                  ? {
                      pathname: history?.location?.pathname,
                    }
                  : null
              }
            />
          </Box>
        )}
        <TextField
          fullWidth
          errorText={error}
          label={intl.formatMessage({
            id: `separatedSingleStep.onboard-${this.getLabelKey()}.label.enterCode`,
          })}
          onChange={this.handleCodeChange}
          value={code}
          disabled={maxWrongAttemptsReached || disableCodeField}
          autoComplete="one-time-code"
          numeric
        />
        <ActionLabel
          underline="always"
          label={this.getCountdown()}
          disabled={resetDisabled}
          onClick={this.handleResend}
        />
        <UXPCard.Actions style={{ ...actionStyle, marginTop: '24px' }}>
          <Button
            isLoading={isLoading}
            onClick={this.handleVerify}
            disabled={disableVerifyButton}
            style={{ marginRight: '8px' }}
            size="large"
          >
            {intl.formatMessage({
              id: `separatedSingleStep.onboard-${this.getLabelKey()}.button.verify`,
            })}
          </Button>
          <Button onClick={this.onCancel} variant="outlined" size="large">
            {intl.formatMessage({
              id: `separatedSingleStep.onboard-${this.getLabelKey()}.button.cancel`,
            })}
          </Button>
          <div
            style={{
              padding: 'unset',
              marginLeft: '16px',
              display:
                type === 'phone' || process === 'forgot-password'
                  ? 'none'
                  : 'display',
            }}
          >
            {conf.emailVerificationNonMandatory && (
              <Button
                variant="outlined"
                className={styles.formButton}
                isLoading={isLoading}
                onClick={this.handleVerifyLater}
              >
                {intl.formatMessage({
                  id: `separatedSingleStep.onboard-${this.getLabelKey()}.button.verifyLater`,
                })}
              </Button>
            )}
          </div>
        </UXPCard.Actions>
      </>
    )
  }
}

function WizardCard({ children, intl, process, type }) {
  return (
    <UXPCard.Card>
      <Box mb={3}>
        <Page.Title>
          {intl.formatMessage({
            id: `${process}.verify-username.${type}.title`,
          })}
        </Page.Title>
      </Box>
      {children}
    </UXPCard.Card>
  )
}

function IdpCard({ children, intl, process, type }) {
  return (
    <FlowLayout
      title={intl.formatMessage({
        id: `${process}.verify-username.${type}.title`,
      })}
    >
      {children}
    </FlowLayout>
  )
}

function ContentWrapper(props) {
  const enableOnlyWizardRoutes = useSelector(
    state => state.configuration.config.enableOnlyWizardRoutes,
  )

  function getWrapper() {
    if (props.inline) {
      return React.Fragment
    }
    if (enableOnlyWizardRoutes) {
      return WizardCard
    }
    if (!enableOnlyWizardRoutes) {
      return IdpCard
    }
  }

  const Wrapper = getWrapper()

  return (
    <Wrapper intl={props.intl} process={props.process} type={props.type}>
      <VerifyUsername {...props} />
    </Wrapper>
  )
}

const mapStateToProps = state => ({
  appId: state.configuration.config.id,
  flowType: state.configuration.config.type,
})

const connectedToProps = connect(mapStateToProps)

export default compose(connectedToProps)(withTheme(injectIntl(ContentWrapper)))
