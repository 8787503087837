import { UPDATE_CONTEXT } from 'common/actions/configuration'

const initialState = {
  context: null,
  config: {},
}

const configurationReducer = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_CONTEXT:
      return {
        ...state,
        context: action.payload.context,
        config: action.payload.config,
      }
    default:
      return state
  }
}

export default configurationReducer
