import React, { useState } from 'react'
import { useIntl } from 'react-intl'
import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { get } from 'lodash'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import Page from 'common/components/Page'
import makeStyles from '@mui/styles/makeStyles'
import { Box, Grid, Typography } from '@mui/material'
import DevicesIcon from '@mui/icons-material/Devices'
import { List } from '@admin-ui-common/base-user'
import WrapperContainer from '../../common/WrapperContainer'
import TwoStepVerificationWrapper from './TwoStepVerificationWrapper'
import { TextMessageOrEmail } from './TextMessageOrEmail'
import { AuthenticatorApp } from './AuthenticatorApp'
import { regsiterEmailMobileList } from '../../profile/selectors'
import { isEmpty } from 'lodash'
import Divider from '@mui/material/Divider'
import FeatureFlagsService, {
  hasUIFeatureFlag,
} from '../../../common/services/FeatureFlagsService'
import UnregisterMFADialog from './twoFactorRegisterEmailMobileModal/UnregisterMFADialog'
import { unregisterMFAIntialRequest } from '../../../common/actions/twoStepVerification'
import { TextPrimaryButton } from '../../../common/components/button/Button'
import { Body } from '../../../common/components/Typography'
import RevokeTrustedDevicesDialog from './revokeTrustedDevicesModal/RevokeTrustedDevicesDialog'
import ManageBUCView from './backupCodes/ManageBUCView'
import moment from 'moment'

const useStyles = makeStyles(theme => ({
  iconWrapper: {
    alignSelf: 'flex-start',
  },
  icon: {
    fontSize: '40px',
    color: theme.palette.text.primary,
  },
  bold: {
    fontWeight: 'bold',
  },
  header: {
    [theme.breakpoints.down('sm')]: {
      marginLeft: 16,
      marginRight: 16,
    },
  },
  fowardArrowIcon: {
    fontSize: 24,
    color: theme.palette.text.secondary,
    cursor: 'pointer',
  },
}))

const AddTwoStepMethodView = props => {
  const {
    title,
    description,
    backLink,
    setupTextMessageOrEmail,
    setupAuthenticatorApp,
    registeredEmailPhone,
  } = props
  const [unregisterIdentity, setUnregisterIdentity] = useState('')
  const [unregisterModal, setUnregisterModal] = useState(false)
  const [revokeTrustedDevicesModal, setRevokeTrustedDeviceModal] = useState(
    false,
  )
  const [processId, setProcessId] = useState('')
  const classes = useStyles()
  const intl = useIntl()
  const state = useSelector(state => state)
  const registeredEmailMobile = regsiterEmailMobileList(state)
  const secret2FATotp = useSelector(
    state => state.user.attributes.secret2FATotp,
  )

  const registeredMFACount =
    registeredEmailMobile.length + Number(!!secret2FATotp)
  const minMfaCount = FeatureFlagsService.get('process.minMfaCount')
  const hasMinMfaRegistered =
    minMfaCount > 0 && registeredMFACount <= minMfaCount
  const trustedDevicesExist =
    useSelector(state => state.registerMFA.trustedDevices).length > 0

  const backupCodeCount = state.user?.backupCodeCount
  const backupCodeCreatedDate = state.user?.backupCodeCreatedDate
  const formattedBUCCreatedDate = moment(backupCodeCreatedDate).format(
    'LL, h:mm a',
  )

  const unRegisterMFA = (identity, type = null) => {
    unregisterMFAIntialRequest(identity, type)
      .then(result => {
        const pid = get(result, 'body.processId')
        setUnregisterIdentity(identity)
        setProcessId(pid)
        setUnregisterModal(true)
      })
      .catch(() => {})
  }

  const openRevokeDevicesModal = () => {
    setRevokeTrustedDeviceModal(true)
  }

  const closeRevokeDevicesModal = () => {
    setRevokeTrustedDeviceModal(false)
  }

  let availableSecondStep = null
  let addBackup = null
  const emailText = (
    <TextMessageOrEmail
      registeredEmailPhone={registeredEmailPhone}
      hasMinMfaRegistered={hasMinMfaRegistered}
      setupTextMessageOrEmail={setupTextMessageOrEmail}
      unRegisterMFA={unRegisterMFA}
    />
  )
  const authApp = (
    <AuthenticatorApp
      hasMinMfaRegistered={hasMinMfaRegistered}
      setupAuthenticatorApp={setupAuthenticatorApp}
      unRegisterMFA={unRegisterMFA}
    />
  )
  if (!isEmpty(registeredEmailMobile) && isEmpty(secret2FATotp)) {
    availableSecondStep = emailText
    addBackup = authApp
  }
  if (!isEmpty(secret2FATotp) && isEmpty(registeredEmailMobile)) {
    availableSecondStep = authApp
    addBackup = emailText
  }
  if (!isEmpty(secret2FATotp) && !isEmpty(registeredEmailMobile)) {
    availableSecondStep = (
      <>
        {authApp}
        <Divider light />
        {emailText}
      </>
    )
  }

  const onCloseModal = () => {
    setUnregisterModal(false)
  }

  return (
    <>
      {revokeTrustedDevicesModal && (
        <RevokeTrustedDevicesDialog
          open={revokeTrustedDevicesModal}
          onClose={closeRevokeDevicesModal}
        />
      )}
      {unregisterModal && (
        <UnregisterMFADialog
          open={unregisterModal}
          onClose={onCloseModal}
          identity={unregisterIdentity}
          processId={processId}
        />
      )}
      <WrapperContainer small>
        <Grid item xs={12}>
          <Box className={classes.header}>
            <Box
              display="flex"
              justifyContent="flex-start"
              alignItems="center"
              mt={3.5}
            >
              <Box mr={1.75}>
                <Link
                  to={backLink}
                  style={{ textDecoration: 'none', color: 'unset' }}
                >
                  <ArrowBackIcon />
                </Link>
              </Box>
              <Page.Title ml={2}>{title}</Page.Title>
            </Box>
            <Box my={3}>
              <Page.Description className="mt3">{description}</Page.Description>
            </Box>
          </Box>
        </Grid>
        <TwoStepVerificationWrapper
          title={intl.formatMessage({
            id: 'profile.label.available-second-step-title',
          })}
          subtitle={intl.formatMessage({
            id: 'profile.label.available-second-step-subtitle',
          })}
        >
          <List>{availableSecondStep}</List>
        </TwoStepVerificationWrapper>
        {addBackup && (
          <TwoStepVerificationWrapper
            title={intl.formatMessage({
              id: 'profile.detail.authenticator-app.add-back-up',
            })}
            subtitle={intl.formatMessage({
              id: 'profile.detail.authenticator-app.add-back-up-subtitle',
            })}
          >
            <List>{addBackup}</List>
          </TwoStepVerificationWrapper>
        )}

        <ManageBUCView
          backupCodeCount={backupCodeCount}
          backupCodeCreatedDate={formattedBUCCreatedDate}
        />

        {hasUIFeatureFlag('showDevicesYouTrust') && (
          <TwoStepVerificationWrapper
            title={intl.formatMessage({
              id: 'profile.detail.authenticator-app.device-you-trust',
            })}
            subtitle={intl.formatMessage({
              id: 'profile.detail.authenticator-app.device-you-trust-subtitle',
            })}
          >
            <List>
              <List.ListItem>
                <List.ListItemIcon className={classes.iconWrapper}>
                  <DevicesIcon className={classes.icon} />
                </List.ListItemIcon>
                <List.ListItemText
                  primary={
                    <Typography className={classes.bold} variant="body1">
                      {intl.formatMessage({
                        id: 'profile.detail.authenticator-app.trusted-device',
                      })}
                    </Typography>
                  }
                  secondary={
                    <div>
                      {intl.formatMessage({
                        id:
                          'profile.detail.authenticator-app.trusted-device-subtitle',
                      })}
                    </div>
                  }
                />
              </List.ListItem>
              {!trustedDevicesExist && (
                <Box mt={3} ml={9} mr={3}>
                  <Body mb={0} color="text.secondary">
                    {intl.formatMessage({
                      id:
                        'profile.detail.authenticator-app.trusted-devices-no-devices',
                    })}
                  </Body>
                </Box>
              )}
              {trustedDevicesExist && (
                <Box mt={2} ml={8}>
                  <TextPrimaryButton onClick={openRevokeDevicesModal}>
                    {intl.formatMessage({
                      id:
                        'profile.detail.authenticator-app.trusted-devices-revoke',
                    })}
                  </TextPrimaryButton>
                </Box>
              )}
            </List>
          </TwoStepVerificationWrapper>
        )}
      </WrapperContainer>
    </>
  )
}

export default AddTwoStepMethodView
