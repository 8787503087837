import React from 'react'
import MaskedInput from 'react-text-mask'

import TextField from './TextField'

const phoneMask = [
  '(',
  /[1-9]/,
  /\d/,
  ')',
  ' ',
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  '-',
  /\d/,
  /\d/,
  /\d/,
  /\d/,
]

const MobileMask = React.forwardRef(function MobileMask(props, ref) {
  const inputRef = ref
  return (
    <MaskedInput
      {...props}
      ref={ref => {
        inputRef(ref ? ref.inputElement : null)
      }}
      mask={phoneMask}
      placeholderChar={'\u2000'}
      showMask
      guide={false}
    />
  )
})

const MobileNumberField = props => (
  <TextField
    inputProps={{
      pattern: '[0-9() -]*',
      inputMode: 'numeric',
    }}
    InputProps={{
      inputComponent: MobileMask,
    }}
    {...props}
  />
)

export default MobileNumberField

export const mobileRegex = /\D/gi
