import React, { createRef } from 'react'
import { Link, withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { injectIntl } from 'react-intl'
import get from 'lodash/get'
import { compose } from 'redux'

import { EmailOrMobile, MobileNumberInput } from '@admin-ui-common/base-user'
import {
  getUsernameValidationErrorSmart,
  getEmailPhoneOnlyValidationError,
} from 'common/utils/getUsernameValidationError'
import stripPhone from 'common/utils/stripPhone'
import { Button } from 'common/components/button/Button'
import TextField from 'common/components/textfield/TextField'
import process from 'common/utils/process'
import { setProcessStep } from 'common/actions/process'
import Page from 'common/components/Page'
import passwordRecover from 'common/processes/recover'

import ReCAPTCHA, { ReCaptchaText } from 'common/components/ReCAPTCHA'
import withReCaptcha from 'common/components/hoc/withReCaptcha'
import { CAPTCHA_RESPONSE } from 'common/constants'
import FeatureFlagsService, {
  hasUIFeatureFlag,
} from 'common/services/FeatureFlagsService'
import { analyticsProvider } from '../../App'
import FlowLayout from '../../common/layouts/FlowLayout'
import Box from '@mui/material/Box'
import { isEmailOnly, isPhoneOnly } from 'common/utils/identifier-utils'
import getCountryCode from 'common/utils/getCountryCode'
import isPhone from 'common/utils/isPhone'
import formatNumber from 'common/utils/formatNumber'
import getDefaultIdentifier from 'common/utils/getDefaultIdentifier'

class Forgot extends React.Component {
  constructor(props) {
    super(props)

    this.recaptchaRef = createRef()
    this.promptCaptcha = FeatureFlagsService.get(
      'system.security.promptCaptcha',
    )

    this.pageTitle = 'enterEmailOrMobile'
    this.baseEventTag = `forgot.${this.pageTitle}`
  }

  state = {
    credentials: getDefaultIdentifier(
      get(this.props.location, 'state.authIdentifier') ||
        get(this.props.wizard.getPageState(), 'usernameEntered') ||
        '',
      hasUIFeatureFlag('allowedPasswordResetIdentifiers'),
      getCountryCode().iso2Code,
    ),
    countryCode: getCountryCode().dialCode,
    errors: {},
    isLoading: false,
    isoCode: getCountryCode().iso2Code,
    isDefaultMobile: isPhone(
      get(this.props.wizard.getPageState(), 'usernameEntered') || '',
    ),
  }

  componentDidMount() {
    analyticsProvider.sendAnalytics({
      type: 'page_view',
      page_title: this.pageTitle,
      page_path: `/forgot#${this.pageTitle}`,
    })
  }

  componentWillUnmount() {
    this.props.resetRecaptcha(this.recaptchaRef.current)
  }

  isEmail(string) {
    const validator = new RegExp(this.props.validEmailPattern)
    return validator.test(string)
  }

  handleEmailChange = event => {
    const { isoCode } = this.state
    this.setState({ credentials: formatNumber(isoCode, event.target.value) })
  }

  onValidateSubmit = async e => {
    e.preventDefault()

    if (this.promptCaptcha) {
      try {
        const recaptcha = await this.recaptchaRef.current.executeAsync()
        this.recaptchaRef.current.reset()
        this.submitForm(recaptcha)
      } catch (e) {
        this.recaptchaRef.current.reset()
      }
    } else {
      this.submitForm()
    }
  }

  onReturnToSignIn = () => {
    analyticsProvider.sendAnalytics({
      type: 'event',
      action: 'click',
      event_category: 'button',
      event_label: `${this.baseEventTag}.return-to-sign-in`,
      value: 0,
    })
  }

  submitForm = recaptchaToken => {
    const { wizard, setProcessStep, intl } = this.props
    let { credentials, countryCode, isoCode } = this.state
    const errors = {}
    const allowedIdentifiers = hasUIFeatureFlag(
      'allowedPasswordResetIdentifiers',
    )

    const credentialsError =
      getUsernameValidationErrorSmart(
        intl,
        credentials,
        allowedIdentifiers,
        this.isEmail.bind(this),
        isPhone,
      ) || getEmailPhoneOnlyValidationError(intl, credentials)

    analyticsProvider.sendAnalytics({
      type: 'event',
      action: 'click',
      event_category: 'button',
      event_label: `${this.baseEventTag}.next`,
      value: 0,
    })

    this.setState({ errors: {} })

    if (credentialsError) {
      errors.credentials = credentialsError
    }

    if (Object.keys(errors).length) {
      this.setState({ errors })
      return
    }

    this.setState({ errors: {} })

    this.setState({ isLoading: true })

    const passwordRecoverExtraParams = {}
    if (this.promptCaptcha && recaptchaToken) {
      passwordRecoverExtraParams[CAPTCHA_RESPONSE] = recaptchaToken
    }

    this.setState({ credentials }, () => {
      let usernameType = ''
      if (this.isEmail(credentials)) {
        usernameType = 'email'
      } else if (isPhone(credentials)) {
        usernameType = 'phone'

        const conditionalCountryCode = hasUIFeatureFlag('excludeCountryCode')
          ? ''
          : countryCode
        credentials = conditionalCountryCode + stripPhone(credentials)
      }
      passwordRecover(credentials, passwordRecoverExtraParams)
        .then(res => {
          wizard.setPageState({ usernameEntered: credentials })
          if (process.isCompleteResponse(res)) {
            const pkat = get(res, 'body.output.pkat')
            const tokenId = get(res, 'body.output.tokenId')
            const selectedRecoveryOption = get(
              res,
              'body.output.selectedRecoveryOption',
              credentials,
            )
            const selectedRecoveryOptionType = get(
              res,
              'body.output.selectedRecoveryOptionType',
              usernameType,
            ).toLowerCase()
            wizard.setPageState({
              ...wizard.getPageState(),
              pkat,
              tokenId,
              usernameType: selectedRecoveryOptionType,
              username: selectedRecoveryOption,
              isoCode: isoCode,
            })
            wizard.toPage(2)
          } else {
            setProcessStep(res.body)
            wizard.toNextPage()
          }
        })
        .catch(() => {
          analyticsProvider.sendAnalytics({
            type: 'event',
            action: 'click',
            event_category: 'button',
            event_label: `${this.baseEventTag}.next`,
            value: 1,
          })
          errors.credentials = intl.formatMessage({ id: 'error.try-again' })
          this.setState({ errors, isLoading: false })
        })
    })
  }

  getInputType = (isEmailIdentifierOnly, isPhoneIdentifierOnly) => {
    if (isEmailIdentifierOnly) {
      return 'email'
    } else if (isPhoneIdentifierOnly) {
      return 'tel'
    } else {
      return 'text'
    }
  }

  handleEmailOrMobileChange = value => {
    const { isoCode } = this.state
    this.setState({ credentials: formatNumber(isoCode, value) })
  }

  render() {
    const { intl } = this.props
    const {
      errors,
      credentials,
      isLoading,
      isoCode,
      isDefaultMobile,
    } = this.state

    const allowedIdentifiers = hasUIFeatureFlag(
      'allowedPasswordResetIdentifiers',
    )

    const availableCountryCodes = hasUIFeatureFlag('availableCountryCodes')
    const isEmailIdentifierOnly = isEmailOnly(allowedIdentifiers)
    const isPhoneIdentifierOnly = isPhoneOnly(allowedIdentifiers)
    const inputType = this.getInputType(
      isEmailIdentifierOnly,
      isPhoneIdentifierOnly,
    )

    const recoveryMessageKey = (() => {
      if (isEmailIdentifierOnly) {
        return 'forgot-password.id-recovery-email-only.message'
      } else if (isPhoneIdentifierOnly) {
        return 'forgot-password.id-recovery-phone-only.message'
      } else {
        return 'forgot-password.id-recovery.message'
      }
    })()

    const inputLabelKey = (() => {
      if (isEmailIdentifierOnly) {
        return 'sign-in.label.username-email-only'
      } else if (isPhoneIdentifierOnly) {
        return 'sign-in.label.username-phone-only'
      } else {
        return 'sign-in.label.username'
      }
    })()

    return (
      <FlowLayout
        title={intl.formatMessage({ id: 'forgot-password.id-recovery.title' })}
      >
        <form
          style={{ display: 'flex', flexDirection: 'column' }}
          onSubmit={this.onValidateSubmit}
        >
          <Page.Body>
            {intl.formatMessage({
              id: recoveryMessageKey,
            })}
          </Page.Body>
          {isEmailIdentifierOnly && (
            <TextField
              inputProps={{
                autoCapitalize: 'none',
                autoComplete: 'off',
                'data-testid': 'username-email',
              }}
              errorText={errors.credentials}
              label={intl.formatMessage({
                id: inputLabelKey,
              })}
              onChange={this.handleEmailChange}
              value={credentials}
            />
          )}
          {isPhoneIdentifierOnly && (
            <MobileNumberInput
              textFieldProps={{
                value: credentials,
                autoFocus: true,
                onChange: this.handleEmailChange,
                inputProps: {
                  'data-testid': 'username-mobile',
                  autoCapitalize: 'none',
                  autoComplete: 'off',
                },
                type: inputType,
              }}
              label={intl.formatMessage({ id: inputLabelKey })}
              countryList={availableCountryCodes}
              selectProps={{
                defaultValue: isoCode,
                onChange: (e, countryCode, isoCode) =>
                  this.setState({
                    countryCode: countryCode,
                    isoCode,
                    credentials: formatNumber(isoCode, credentials),
                  }),
                inputProps: {
                  'data-testid': 'country-code',
                },
              }}
              error={errors.credentials}
            />
          )}
          {!isEmailIdentifierOnly && !isPhoneIdentifierOnly && (
            <EmailOrMobile
              onChange={this.handleEmailOrMobileChange}
              defaultValue={credentials}
              error={errors.credentials}
              countryCode={isoCode}
              defaultMobileNumber={isDefaultMobile}
              textFieldProps={{
                value: credentials,
                autoFocus: true,
                inputProps: {
                  'data-testid': 'username-email',
                  autoCapitalize: 'none',
                  autoComplete: 'off',
                },
                errorProps: errors
                  ? { errorMessage: errors.credentials }
                  : undefined,
                type: inputType,
                label: intl.formatMessage({ id: inputLabelKey }),
              }}
              mobileNumberInputProps={{
                textFieldProps: {
                  value: credentials,
                  autoFocus: true,
                  inputProps: {
                    'data-testid': 'username-mobile',
                    autoCapitalize: 'none',
                    autoComplete: 'off',
                  },
                  type: inputType,
                  errorProps: errors
                    ? { errorMessage: errors.credentials }
                    : undefined,
                },
                selectProps: {
                  inputProps: {
                    'data-testid': 'country-code',
                  },
                },
                label: intl.formatMessage({
                  id: 'sign-in.label.username-phone-only',
                }),
                countryList: availableCountryCodes,
              }}
              onCountryCodeChange={(value, isoCode) => {
                this.setState({
                  countryCode: value,
                  isoCode: isoCode,
                  credentials: formatNumber(isoCode, credentials),
                })
              }}
            />
          )}
          {this.promptCaptcha && (
            <ReCaptchaText
              style={{ marginBottom: '0px', marginTop: '1.1rem' }}
            />
          )}
          <Box display="flex" paddingTop={4}>
            <Box marginRight={3}>
              <Button
                size="large"
                data-testid="reset-password-button-next"
                onClick={this.onValidateSubmit}
                isLoading={isLoading}
              >
                {intl.formatMessage({ id: 'common.button.next' })}
              </Button>
            </Box>
            <Link to="/" onClick={this.onReturnToSignIn}>
              <Button size="large" variant="outlined">
                {intl.formatMessage({ id: 'common.button.return-sign-in' })}
              </Button>
            </Link>
          </Box>
          {this.promptCaptcha && <ReCAPTCHA ref={this.recaptchaRef} />}
        </form>
      </FlowLayout>
    )
  }
}

function PageWrapper(props) {
  return <Forgot {...props} />
}

const mapStateToProps = state => ({
  validEmailPattern: state.settings.publicSettings['process.validEmailPattern'],
  validPhonePattern: state.settings.publicSettings['process.validPhonePattern'],
})
const mapDispatchToProps = { setProcessStep }
const connectedToProps = connect(mapStateToProps, mapDispatchToProps)

export default compose(
  withRouter,
  connectedToProps,
  injectIntl,
  withReCaptcha(),
)(PageWrapper)
