import React from 'react'

import MUDialog from '@mui/material/Dialog'
import MUDialogActions from '@mui/material/DialogActions'
import MUDialogContent from '@mui/material/DialogContent'
import CloseIcon from '@mui/icons-material/Close'
import withStyles from '@mui/styles/withStyles'

import { DialogTitle as DialogTitleTypography } from '../Typography'

import { useTheme } from '@mui/styles'
import { useMediaQuery } from '@mui/material'

import { withWidth } from '../hoc/withWidth'

const processMarginOrPadding = (theme, value) => {
  if (!value) return null
  return value.toString().includes('px') ? value : theme.spacing(value)
}

const styles = theme => ({
  titleContainer: {
    padding: '0px',
    display: 'flex',
  },
  title: {
    padding: '16px',
    flex: 1,
  },
  closeIcon: {
    cursor: 'pointer',
    height: '48px',
    width: '48px',
    padding: '10px',
  },
  dialog: {
    minWidth: theme.breakpoints.values.xs,
    [theme.breakpoints.down('sm')]: {
      borderRadius: '0!important',
      width: '100%',
      margin: '0px',
      position: 'absolute',
      bottom: '0px',
      minWidth: '100%',
    },
  },
  content: {
    padding: theme.spacing(2, 2),
  },
  actions: {
    padding: theme.spacing(2),
    marginTop: props =>
      processMarginOrPadding(theme, props.mt) || theme.spacing(4),
    whiteSpace: 'pre-line',
    '& > :not(:first-child)': {
      marginLeft: theme.spacing(2),
    },
  },
})

const DialogTitle = ({ classes, title, onClose, style }) => {
  return (
    <div className={classes.titleContainer}>
      <DialogTitleTypography className={classes.title} style={style}>
        {title}
      </DialogTitleTypography>
      {onClose && <CloseIcon className={classes.closeIcon} onClick={onClose} />}
    </div>
  )
}
export const Title = withStyles(styles)(DialogTitle)

const DialogCore = ({ classes, fullScreen, width, open, ...props }) => {
  // TODO Ivan. Make width optional?

  const theme = useTheme()
  const isWidthLessThanXS = useMediaQuery(theme.breakpoints.down('xs'))
  const isFullScreen = fullScreen && isWidthLessThanXS

  return (
    <MUDialog
      PaperProps={{ classes: { root: classes.dialog } }}
      fullScreen={isFullScreen}
      open={open}
      {...props}
    />
  )
}
export const Dialog = withStyles(styles)(withWidth()(DialogCore))

const DialogContent = ({ classes, ...props }) => (
  <MUDialogContent classes={{ root: classes.content }} {...props} />
)
export const Content = withStyles(styles)(DialogContent)

const DialogActions = ({ classes, ...props }) => (
  <MUDialogActions classes={{ root: classes.actions }} {...props} />
)
export const Actions = withStyles(styles)(DialogActions)
