import i from 'common/utils/i'
import conf from 'conf'
import FeatureFlagsService from 'common/services/FeatureFlagsService'

/**
 *
 * @param {string} code User's code
 * @param {string} pkat
 * @param {string=} actionName
 * @returns {Promise}
 */
export async function verifyCode(code, pkat, actionName) {
  const enableOIDCLoginFlow = FeatureFlagsService.get(
    'uiFeatureFlags.idp.enableOIDCLoginFlow',
  )
  const searchParams = new URLSearchParams()
  searchParams.set('customToken', code)
  searchParams.set('pkat', pkat)
  if (actionName) {
    searchParams.set('actionName', actionName)
  }
  const url = `${conf.apiRoot}/session/token?${searchParams.toString()}`
  return i.get(url, {
    ignoreErrorRedirect: true,
    ...(enableOIDCLoginFlow
      ? { headers: { 'x-idp-authentication': true } }
      : {}),
  })
}
