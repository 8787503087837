import conf from 'conf'

const { regexStripPhone = /\D+/g } = conf

const stripPhone = phone => {
  //Don't strip phone if email
  //TODO : Remove this check and include more robust stripping regex, which doesn't strip the phone if it is an email
  if (phone?.includes('@')) {
    return phone
  }
  const stripped = phone?.replace(regexStripPhone, '')
  return stripped
}

export default stripPhone
