import { Route } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { urlSearchFromObject } from 'common/utils/searchParams'
import getCurrentSubRouteConfiguration from 'common/utils/getCurrentSubRouteConfiguration'

function AuthenticatedComponent({
  component: Component,
  redirectBack = false,
  ...props
}) {
  const isAuthenticated = useSelector(state => state.session.hasSession)
  const newUrl = new URL('/', window.location)

  function buildSearch() {
    const url = new URL(props.match.url, window.location)
    url.search = props.location.search

    return urlSearchFromObject({
      redirect_to: url.toString(),
    })
  }

  newUrl.search = buildSearch()

  const { currentSubRoute } = getCurrentSubRouteConfiguration()

  if (!isAuthenticated) {
    window.location = redirectBack ? newUrl : currentSubRoute
    return
  }

  return <Component {...props} />
}

function AuthenticatedRoute({ component: Component, redirectBack, ...props }) {
  return (
    <Route
      {...props}
      render={routeProps => {
        return (
          <AuthenticatedComponent
            redirectBack={redirectBack}
            component={Component}
            {...routeProps}
          />
        )
      }}
    />
  )
}
export default AuthenticatedRoute
