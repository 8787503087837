import conf from 'conf'
import FeatureFlagService from 'common/services/FeatureFlagsService'

const { regexEmail = /^\S+@\S+$/ } = conf

const isEmail = email => {
  const validEmailPattern = FeatureFlagService.get('process.validEmailPattern')
  return validEmailPattern
    ? new RegExp(validEmailPattern).test(email)
    : regexEmail.test(email)
}

export default isEmail
