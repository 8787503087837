import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { injectIntl } from 'react-intl'
import { compose } from 'redux'

import FormControl from '@mui/material/FormControl'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import makeStyles from '@mui/styles/makeStyles'
import { Select } from '@admin-ui-common/base-user'

import * as UXPDialog from 'common/components/dialog/UXPDialog'
import TextField from 'common/components/textfield/TextField'
import {
  TextPrimaryButton,
  TextSecondaryButton,
} from 'common/components/button/Button'
import Page from 'common/components/Page'

import { addNotification } from 'common/actions/notification'
import { linkAccount, loadAccounts } from 'common/actions/accounts'
import { getAccounts } from 'common/selectors/accounts'
import { resolveErrorEntity } from 'common/utils/processBackendErrors'
import { analyticsProvider } from '../../App'

const useStyles = makeStyles({
  formControl: {
    width: '100%',
  },
})

const LinkAccountDialog = ({
  onClose,
  open,
  addNotification,
  loadAccounts,
  intl,
  accounts,
}) => {
  const [accountNumber, setAccountNumber] = useState('')
  const [inProgress, setInProgress] = useState(false)
  const [identifier, setIdentifier] = useState('mine')
  const [kad, setKad] = useState('')

  const onHandleAccountChange = e => setAccountNumber(e.target.value)
  const handleIdentifierChange = e => setIdentifier(e.target.value)
  const handleKadChange = e => setKad(e.target.value)

  const onLinkAccount = () => {
    analyticsProvider.sendAnalytics({
      type: 'event',
      action: 'click',
      event_category: 'button',
      event_label: `linkAccountDialog.link-account`,
      value: 0,
    })

    setInProgress(true)
    linkAccount(accounts.length)
      .then(loadAccounts)
      .then(() => {
        setAccountNumber('')

        addNotification({
          message: intl.formatMessage({ id: 'notification.account-linked' }),
          variant: 'success',
        })

        onClose()
      })
      .catch(err => {
        analyticsProvider.sendAnalytics({
          type: 'event',
          action: 'click',
          event_category: 'button',
          event_label: `linkAccountDialog.link-account`,
          value: 1,
        })
        addNotification({
          message: resolveErrorEntity({ intl, error: err.body }),
        })
      })
      .finally(() => setInProgress(false))
  }

  const closeView = () => {
    analyticsProvider.sendAnalytics({
      type: 'event',
      action: 'click',
      event_category: 'button',
      event_label: `linkAccountDialog.close`,
      value: 0,
    })
    setAccountNumber('')
    onClose()
  }

  const classes = useStyles()

  return (
    <UXPDialog.Dialog open={open} fullScreen fullWidth maxWidth="sm">
      <UXPDialog.Title title="Link Account" onClose={closeView} />
      <UXPDialog.Content>
        <Box mb={3}>
          <Page.Description>
            We noticed that you have not linked your Astro account yet. Please
            link your account now in order to enjoy this service.
          </Page.Description>
        </Box>

        <TextField
          label="Account number"
          variant="outlined"
          value={accountNumber}
          onChange={onHandleAccountChange}
        />

        <Box my={4} />
        <Box display="flex" justifyContent="space-between" mb={2}>
          <Typography>ID Number</Typography>
          <Typography>Why do I need this?</Typography>
        </Box>

        <FormControl variant="outlined" className={classes.formControl}>
          <Select
            inputLabel="Select"
            value={identifier}
            onChange={handleIdentifierChange}
          >
            <Select.Option value="mine">MyKad</Select.Option>
            <Select.Option value="yours">YourKad</Select.Option>
          </Select>
        </FormControl>

        <Box mt={2}>
          <TextField
            label="MyKad Number"
            variant="outlined"
            value={kad}
            onChange={handleKadChange}
          />
        </Box>
      </UXPDialog.Content>

      <UXPDialog.Actions disabled={inProgress}>
        <TextSecondaryButton size="medium" onClick={closeView}>
          Cancel
        </TextSecondaryButton>
        <TextPrimaryButton onClick={onLinkAccount} disabled={inProgress}>
          Done
        </TextPrimaryButton>
      </UXPDialog.Actions>
    </UXPDialog.Dialog>
  )
}

LinkAccountDialog.propTypes = {
  onClose: PropTypes.func,
  open: PropTypes.bool,
  addNotification: PropTypes.func,
  loadAccounts: PropTypes.func,
  intl: PropTypes.object,
  accounts: PropTypes.array,
}

const mapState = state => ({
  accounts: getAccounts(state),
})

const mapDispatch = {
  addNotification,
  loadAccounts,
}

export default compose(
  connect(mapState, mapDispatch),
  injectIntl,
)(LinkAccountDialog)
