import React from 'react'
import classNames from 'classnames'

import { Button } from 'common/components/button/Button'

import styles from '../styles.module.css'
import { analyticsProvider } from '../../App'
import { Body } from '../../common/components/Typography'

import { injectIntl } from 'react-intl'

const ResetResult = ({ onConfirm, intl }) => {
  const pageTitle = 'resultResetPassword'
  const baseEventTag = `signup.${pageTitle}`

  const getText = () => {
    return (
      <React.Fragment>
        <Body>
          {intl.formatMessage({ id: 'reset-password-result.message' })}
        </Body>
      </React.Fragment>
    )
  }

  return (
    <>
      <p className={styles.resultMessage}>{getText()}</p>
      <div className={classNames(styles.formButton)}>
        <Button
          onClick={() => {
            analyticsProvider.sendAnalytics({
              type: 'event',
              action: 'click',
              event_category: 'button',
              event_label: `${baseEventTag}.confirm`,
              value: 0,
            })
            onConfirm()
          }}
          size="large"
        >
          {intl.formatMessage({ id: 'common.button.return-sign-in' })}
        </Button>
      </div>
    </>
  )
}

export default injectIntl(ResetResult)
