import React from 'react'
import Wizard from 'common/components/wizard/Wizard'
import VerifyMobile from './VerificationMobile'
import VerifyCode from './VerificationCode'
import ThankYou from './ThankYou'
import SignIn from '../common/SignIn'
import NotEligible from '../common/NotEligible'
import AlreadySubscribed from './AlreadySubscribed'
import CreateId from './CreateId'
import ThankYouNewUser from './ThankYouNewUser'
import NotFound from '../common/NotFound'

export const WIZARD_VIEW_INDEX = {
  verifyMobile: 0,
  verifyCode: 1,
  thankYou: 2,
  signIn: 3,
  notEligible: 4,
  alreadySubscribed: 5,
  createId: 6,
  thankYouNewUser: 7,
  notFound: 8,
}

const Views = [
  VerifyMobile,
  VerifyCode,
  ThankYou,
  SignIn,
  NotEligible,
  AlreadySubscribed,
  CreateId,
  ThankYouNewUser,
  NotFound,
]

const SubscribeWizard = props => (
  <Wizard>
    {Views.map((View, i) => (
      // eslint-disable-next-line react/no-array-index-key
      <View key={i} {...props} wizardIndex={WIZARD_VIEW_INDEX} />
    ))}
  </Wizard>
)

export default SubscribeWizard
