import React, { useEffect } from 'react'

import * as UXPCard from 'common/components/Card'
import { Title, Body } from 'common/components/Typography'
import { Button } from 'common/components/button/Button'
import getCurrentSubRouteConfiguration from 'common/utils/getCurrentSubRouteConfiguration'

import { analyticsProvider } from 'App.js'
import Paper from '@mui/material/Paper'
import makeStyles from '@mui/styles/makeStyles'

import { useHistory } from 'react-router-dom'

const useStyles = makeStyles(theme => ({
  root: {
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      backgroundColor: 'transparent',
      boxShadow: 'none',
      paddingLeft: '16px',
      paddingRight: '16px',
      paddingBottom: '16px',
      marginTop: 'unset',
      marginBottom: 'unset',
    },
    display: 'flex',
    flexDirection: 'column',
    width: '500px',
    padding: '40px',
    paddingTop: '32px',
    alignSelf: 'center',
    marginTop: 'auto',
    marginBottom: 'auto',
  },
}))

const NotLoaded = () => {
  const pageTitle = 'not_loaded'
  const baseEventTag = 'not_loaded'

  const classes = useStyles()

  const history = useHistory()

  useEffect(() => {
    analyticsProvider.sendAnalytics({
      type: 'page_view',
      page_title: pageTitle,
      page_path: `/${pageTitle}`,
    })
  }, [])

  const onRefresh = () => {
    const { currentSubRoute } = getCurrentSubRouteConfiguration()
    analyticsProvider.sendAnalytics({
      type: 'event',
      action: 'click',
      event_category: 'button',
      event_label: `${baseEventTag}.refresh`,
      value: 0,
    })

    history.push(currentSubRoute)
  }

  return (
    <Paper className={classes.root}>
      <UXPCard.Content>
        <Title>Page could not be loaded</Title>
        <Body>
          Something went wrong and the page could not be loaded. Please try
          again later.
        </Body>
      </UXPCard.Content>
      <Button onClick={onRefresh} size="large">
        Refresh the page
      </Button>
    </Paper>
  )
}

export default NotLoaded
