import React, { useState, useEffect } from 'react'
import { useIntl } from 'react-intl'
import { get, isEmpty, snakeCase } from 'lodash'

import Box from '@mui/material/Box'

import * as UXPCard from 'common/components/Card'
import { Title, Body } from 'common/components/Typography'
import MobileNumberField from 'common/components/textfield/MobileNumberField'
import PasswordField from 'common/components/textfield/PasswordField'
import { TextPrimaryButton, Button } from 'common/components/button/Button'
import ForgotPasswordLabel from 'common/components/ForgotPasswordLabel'
import SubscribeContentDialog from 'common/components/SubscribeContentDialog'

import useForgotRedirect from 'common/components/hooks/useForgotRedirect'
import useMainRedirect from 'common/components/hooks/useMainRedirect'

import {
  INVALID_CREDENTIALS,
  ALREADY_SUBSCRIBED,
  NOT_ELIGIBLE,
  NUMBER_IS_PORTABLE,
  NO_SUBSCRIPTION_EXISTS,
  getFlowTypes,
  TOO_MANY_RETRIES,
} from 'common/constants'
import { resolveErrorEntity } from 'common/utils/processBackendErrors'
import process from 'common/utils/process'
import { enrolAuthenticateWithSubscriptionProcessId } from 'common/processes/subscriptionManagement'

import { analyticsProvider } from 'App.js'

import { Typography } from '@mui/material'
import { useSelector } from 'react-redux'

const errorContext = 'sign-in'

const SignIn = ({ wizard, flowType, wizardIndex, history }) => {
  const isWizardOnly = useSelector(
    state => state.configuration.config.enableOnlyWizardRoutes,
  )
  const FLOW_TYPES = getFlowTypes()

  const labelIds = {
    [FLOW_TYPES.SUBSCRIBE]: {
      title: 'onboard.sign-in.title',
      body: 'onboard.sign-in.to-continue-to-subscribe',
      cancel: 'common.button.cancel',
      confirm: 'onboard.sign-in.sign-in-and-purchase',
    },
    [FLOW_TYPES.LOGIN]: {
      title: 'onboard.sign-in.title',
      body: 'onboard.sign-in.enter-your-password',
      cancel: 'common.button.cancel',
      confirm: 'onboard.sign-in.sign-in-button',
    },
    [FLOW_TYPES.CANCEL]: {
      title: 'onboard-cancel.sign-in.title',
      body: 'onboard-cancel.sign-in.to-continue-to-cancel',
      cancel: 'onboard.common.back',
      confirm: isWizardOnly
        ? 'onboard-cancel.sign-in.cancel-subscription'
        : 'common.button.next',
    },
  }

  const [locked, setLocked] = useState(false)
  const [password, setPassword] = useState('')
  const [error, setError] = useState(null)
  const [subscribeError, setSubscribeError] = useState(false)
  const [loading, setLoading] = useState(false)
  const [productData, setProductData] = useState({})
  const [signInResponse, setSignInResponse] = useState({})
  const intl = useIntl()
  const mainRedirect = useMainRedirect(`/${flowType}`)

  const {
    mobile,
    userAuthenticated,
    stepName,
    processId,
  } = wizard.getPageState()
  const isCancelFlow = flowType === FLOW_TYPES.CANCEL

  const pageTitle = 'sign_in'
  const baseEventTag = `${flowType}.sign_in`
  const passwordFieldLabel = intl.formatMessage({
    id: 'onboard.common.password',
  })

  useEffect(() => {
    analyticsProvider.sendAnalytics({
      type: 'page_view',
      page_title: pageTitle,
      page_path: `/${flowType}#${pageTitle}`,
    })
  }, [flowType])

  // //////////////////////////////////////////////////////////

  const { redirect } = useForgotRedirect({
    url: `/${flowType}`,
    id: wizardIndex.signIn,
    mobile,
    originProcessState: wizard.getPageState(),
  })

  // //////////////////////////////////////////////////////////

  const onCancel = () => {
    analyticsProvider.sendAnalytics({
      type: 'event',
      action: 'click',
      event_category: 'button',
      event_label: `${baseEventTag}.${snakeCase(labelIds[flowType].cancel)}`,
      value: 0,
    })

    const isStaticRedirect = mainRedirect()
    if (isStaticRedirect) {
      wizard.setPageState({})
      wizard.toPage(0)
    }
  }

  // //////////////////////////////////////////////////////////

  const onSignIn = async () => {
    let nextErrors = null

    if (!password) {
      nextErrors = intl.formatMessage({
        id: 'onboard.error.please-enter-password',
      })

      analyticsProvider.sendAnalytics({
        type: 'event',
        action: 'exception',
        description: `${baseEventTag}.password: ${nextErrors}`,
      })
    }

    setError(nextErrors)
    setSubscribeError(false)

    if (!password) {
      return
    }

    try {
      setLoading(true)
      const stepPayload = {
        userAuthenticated,
        stepName,
        processId,
        parameters: {
          password,
        },
      }

      const res = await process.step(stepPayload, true)
      setSignInResponse(res)
      const { metadata } = res
      const processName = get(res, 'body.processName')
      const body = get(res, 'body')
      const currentStepName = get(res, 'body.stepName')
      setLoading(false)

      const output = get(res, 'body.output', {})
      const productDetails = get(output, 'productDetails')

      const showPurchasePrompt = get(output, 'purchasePromptFlag', false)

      if (process.isProcess(body) && currentStepName === 'UpdatePassword') {
        history.push({
          pathname: '/password_expire',
          state: body,
        })
      }

      analyticsProvider.sendAnalytics({
        type: 'event',
        action: 'click',
        event_category: 'button',
        event_label: `${baseEventTag}.${snakeCase(labelIds[flowType].confirm)}`,
        value: 0,
      })

      if (isCancelFlow) {
        wizard.setPageState({
          ...res.body,
          productDetails,
          metadata,
          mobile,
        })
        if (isWizardOnly) wizard.toPage(wizardIndex.cancelSuccess)
        else wizard.toPage(wizardIndex.verifyCode)
      } else {
        // eslint-disable-next-line no-lonely-if
        if (showPurchasePrompt) {
          const productInfo = get(output, 'productDetailsDTO', {})
          setProductData({
            productInfo,
            stepInfo: res.body,
          })
        } else if (processName === enrolAuthenticateWithSubscriptionProcessId) {
          mainRedirect(res.metadata)
        } else {
          wizard.setPageState({
            mobile,
            ...output,
            metadata,
          })

          const nextPage =
            get(output, 'state') === ALREADY_SUBSCRIBED
              ? wizardIndex.alreadySubscribed
              : wizardIndex.thankYou

          wizard.toPage(nextPage)
        }
      }
    } catch (err) {
      setLoading(false)

      analyticsProvider.sendAnalytics({
        type: 'event',
        action: 'click',
        event_category: 'button',
        event_label: `${baseEventTag}.${snakeCase(labelIds[flowType].confirm)}`,
        value: 1,
      })

      const operationError = get(err, 'body.operationError[0]', {})
      const { code: errorCode, message: portUrl } = operationError
      const errorMessage = resolveErrorEntity({
        intl,
        error: err.body,
        context: errorContext,
      })

      switch (errorCode) {
        case INVALID_CREDENTIALS: {
          setError(errorMessage)

          analyticsProvider.sendAnalytics({
            error: errorMessage,
          })
          break
        }
        case NOT_ELIGIBLE: {
          wizard.setPageState({
            ...wizard.getPageState(),
          })
          wizard.toPage(wizardIndex.notEligible)
          break
        }
        case NUMBER_IS_PORTABLE: {
          wizard.setPageState({
            ...wizard.getPageState(),
            portUrl,
          })
          wizard.toPage(wizardIndex.notEligible)
          break
        }
        case NO_SUBSCRIPTION_EXISTS: {
          wizard.toPage(wizardIndex.alreadyUnsubscribed)
          break
        }
        case TOO_MANY_RETRIES: {
          setLocked(true)

          setError(errorMessage)

          analyticsProvider.sendAnalytics({
            error: errorMessage,
          })
          break
        }
        default: {
          analyticsProvider.sendAnalytics({
            error: errorMessage,
          })

          setError(errorMessage)
        }
      }
    }
  }

  // //////////////////////////////////////////////////////////

  // eslint-disable-next-line no-shadow
  const onCloseDialog = (stepName, isError) => {
    const { productInfo } = productData
    setProductData({})
    // eslint-disable-next-line no-shadow
    const { processId, userAuthenticated, output = {} } = signInResponse.body
    const { metadata } = signInResponse

    wizard.setPageState({
      ...wizard.getPageState(),
      processId,
      userAuthenticated,
      stepName,
      productInfo: {
        ...productInfo,
      },
      ...output,
      metadata,
    })

    if (isError) {
      wizard.toPage(wizardIndex.notFound)
    } else {
      if (stepName === 'NoThanks') {
        mainRedirect(signInResponse.metadata)
      } else {
        wizard.toPage(wizardIndex.thankYou)
      }
    }
  }

  // //////////////////////////////////////////////////////////

  const openDialog = !isEmpty(productData)
  const messageIds = labelIds[flowType]
  const onSubscribeError = () => setSubscribeError(true)

  return (
    <React.Fragment>
      <UXPCard.Card>
        <UXPCard.Content>
          <Title>{intl.formatMessage({ id: messageIds.title })}</Title>
          <Body mb={4}>{intl.formatMessage({ id: messageIds.body })}</Body>

          <MobileNumberField
            label={intl.formatMessage({ id: 'onboard.common.mobile-number' })}
            value={mobile}
            disabled
          />
          <Box mb={3} />
          <PasswordField
            required
            label={passwordFieldLabel}
            onChange={e => setPassword(e.target.value)}
            value={password}
            errorText={error}
          />
          <ForgotPasswordLabel mt={0.5} onClick={redirect} />
          {subscribeError ? (
            <Typography color="error">
              {intl.formatMessage({ id: 'error.unexpected' })}
            </Typography>
          ) : null}
        </UXPCard.Content>
        <UXPCard.Divider />
        <UXPCard.Actions>
          <TextPrimaryButton onClick={onCancel}>
            {intl.formatMessage({ id: messageIds.cancel })}
          </TextPrimaryButton>
          <Button
            color="primary"
            onClick={onSignIn}
            isLoading={loading}
            disabled={locked}
          >
            {intl.formatMessage({ id: messageIds.confirm })}
          </Button>
        </UXPCard.Actions>
      </UXPCard.Card>
      <SubscribeContentDialog
        open={openDialog}
        onClose={onCloseDialog}
        onSubscribeError={onSubscribeError}
        flowType={flowType}
        {...productData}
      />
    </React.Fragment>
  )
}

export default SignIn
