import React from 'react'
import { ThemeProvider as StyledComponentsThemeProvider } from 'styled-components'
import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles'

// TODO: refactor out styled components.  for now we need this otherwise theme is empty object
const Theme = ({ theme, children, ...rest }) => (
  <StyledEngineProvider injectFirst>
    <StyledComponentsThemeProvider theme={theme}>
      <ThemeProvider theme={theme} {...rest}>
        {children}
      </ThemeProvider>
    </StyledComponentsThemeProvider>
  </StyledEngineProvider>
)

export default Theme
