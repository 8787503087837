import React from 'react'
import PropTypes from 'prop-types'

import TextField from 'common/components/textfield/TextField'
import { Button } from 'common/components/button/Button'
import head from 'lodash/head'

const Header = ({ title, subtitle }) => (
  <div style={{ maxWidth: '500px' }}>
    <h2 style={{ color: '#004c91' }}>{title}</h2>
    <h4 className="pt1 pb2 regular">{subtitle}</h4>
  </div>
)

export default class VerifyNameEmail extends React.Component {
  static propTypes = {
    displayMessage: PropTypes.string,
    output: PropTypes.object,
    inputs: PropTypes.object,
    handleChange: PropTypes.func,
    handleSubmit: PropTypes.func,
  }

  // ------------------------------------------------------------------------ //

  constructor(props) {
    super(props)
    this._displayName = this.props.inputs.displayName
  }

  // ------------------------------------------------------------------------ //

  state = {
    step: 'displayName',
  }

  // ------------------------------------------------------------------------ //

  _onHandleChange = paramName => event => this.props.handleChange(paramName)

  // ------------------------------------------------------------------------ //

  _nextStep = step => () => this.setState({ step })

  // ------------------------------------------------------------------------ //

  _getCurrentInput = () => this.props.inputs[this.state.step]

  // ------------------------------------------------------------------------ //

  _nameConfirmation = () => {
    const displayName = this._getCurrentInput()
    const disabled = !displayName
    const errorText = disabled ? 'Name cannot be empty' : ''

    return (
      <div>
        <TextField
          label="Full name"
          errorText={errorText}
          value={displayName}
          onChange={this._onHandleChange(this.state.step)}
        />

        <div className="mt3 self-end">
          <Button disabled={disabled} onClick={this._nextStep('emailAddress')}>
            Continue
          </Button>
        </div>
      </div>
    )
  }

  // ------------------------------------------------------------------------ //

  _onSkipEmail = () => {
    const { handleChange, handleSubmit } = this.props

    handleChange(this.state.step)
    handleSubmit()
  }

  // ------------------------------------------------------------------------ //

  _emailConfirmation = () => {
    const email = this._getCurrentInput()
    return (
      <div>
        <TextField
          inputType="email"
          label="Email"
          value={email}
          onChange={this._onHandleChange(this.state.step)}
        />

        <div className="flex justify-between mt3">
          <Button onClick={this.props.handleSubmit}>Continue</Button>
          <Button variant="outlined" onClick={this._onSkipEmail}>
            Skip
          </Button>
        </div>
      </div>
    )
  }

  // ------------------------------------------------------------------------ //

  _getHeadline = () => {
    const params = {
      displayName: () => ({
        title: `Welcome, ${this._displayName}!`,
        subtitle: 'We hope we got your name right – if not, correct it below.',
      }),
      emailAddress: () => ({
        title: `Thanks, ${head(this.props.inputs.displayName.split(' '))}`,
        subtitle:
          'We’d like to keep this email address as an secondary way to sign in to your account. You can change this email to another one if you’d like.',
      }),
    }[this.state.step]()

    return <Header {...params} />
  }

  // ------------------------------------------------------------------------ //

  _getVerificationView = () =>
    ({
      displayName: this._nameConfirmation,
      emailAddress: this._emailConfirmation,
    }[this.state.step]())

  // ------------------------------------------------------------------------ //

  render() {
    return (
      <div className="flex flex-column">
        {this._getHeadline()}
        {this._getVerificationView()}
      </div>
    )
  }
}
