import React, { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { get } from 'lodash'
import { useIntl } from 'react-intl'

import { resolveErrorEntity } from 'common/utils/processBackendErrors'
import { addNotification } from 'common/actions/notification'

import PasswordField from 'common/components/textfield/PasswordField'
import Box from '@mui/material/Box'

import * as UXPDialog from 'common/components/dialog/UXPDialog'
import { TextPrimaryButton, Button } from 'common/components/button/Button'
import Page from 'common/components/Page'
import { Body2 } from 'common/components/Typography'
import { analyticsProvider } from '../../../../App'
import { unregisterMFAStep } from 'common/actions/twoStepVerification'
import { loadUser } from 'common/actions/user'
import { useTheme } from '@mui/material/styles'
import { isEmail } from '@admin-ui-common/utils'
import { displayIdentifier } from 'common/utils/formatNumber'

const errorContext = 'register-mfa'

const UnregisterMFADialog = ({
  onClose,
  open,
  identity,
  processId,
  flowType,
}) => {
  const dispatch = useDispatch()
  const registerMFA = useSelector(state => state.registerMFA)
  const [password, setPassword] = useState('')
  const [inProgress, setInProgress] = useState(registerMFA.inProgress)
  const [error, setError] = useState(false)
  const intl = useIntl()
  const theme = useTheme()
  const pageTitle = 'Unregister_MFA'
  const baseEventTag = `${flowType}.${pageTitle}`

  const onRemove = () => {
    analyticsProvider.sendAnalytics({
      type: 'event',
      action: 'click',
      event_category: 'button',
      event_label: `${baseEventTag}.next`,
      value: 0,
    })

    if (!password) {
      setError({
        message: intl.formatMessage({
          id: 'error.credential',
        }),
      })
      return
    }

    setInProgress(true)
    if (!password) return

    const payload = {
      processId: processId,
      parameters: {
        password: password,
      },
    }

    unregisterMFAStep(payload)
      .then(res => {
        analyticsProvider.sendAnalytics({
          type: 'event',
          action: 'click',
          event_category: 'button',
          event_label: `${baseEventTag}.password_verify`,
          value: 0,
        })
        onClose()
        setPassword('')
        dispatch(loadUser())
        dispatch(
          addNotification({
            message: intl.formatMessage({
              id: 'user.management.unregister.success-message',
            }),
          }),
        )
      })
      .catch(error => {
        const errorMessage = resolveErrorEntity({
          intl,
          error: error.body,
          context: errorContext,
        })
        setError({
          message: errorMessage,
        })
        analyticsProvider.sendAnalytics({
          type: 'event',
          action: 'exception',
          description: `${baseEventTag}.screen: ${errorMessage}`,
        })
      })
  }

  const closeDialog = () => {
    analyticsProvider.sendAnalytics({
      type: 'event',
      action: 'click',
      event_category: 'button',
      event_label: `${baseEventTag}.close`,
      value: 0,
    })
    setPassword('')
    setError('')
    onClose()
  }

  const formattedIdentity = isEmail(identity)
    ? identity
    : displayIdentifier(identity)

  return (
    <UXPDialog.Dialog open={open} fullScreen fullWidth maxWidth="sm">
      <UXPDialog.Title
        title={intl.formatMessage({
          id: 'user.management.unregister.title',
        })}
        onClose={closeDialog}
      />
      <UXPDialog.Content>
        <Box mb={3}>
          <Page.Description>{formattedIdentity}</Page.Description>
        </Box>
        <Box mb={3}>
          <Body2>
            {intl.formatMessage({
              id: 'user.management.unregister.message',
            })}
          </Body2>
        </Box>
        <PasswordField
          label={intl.formatMessage({
            id: 'user.management.register.mobile-or-email-enterPassword',
          })}
          onChange={e => setPassword(e.target.value)}
          value={password}
          errorText={get(error, 'message')}
        />
      </UXPDialog.Content>
      <UXPDialog.Actions disabled={inProgress}>
        <TextPrimaryButton
          onClick={closeDialog}
          size="small"
          variant="outlined"
        >
          {intl.formatMessage({ id: 'common.button.cancel' })}
        </TextPrimaryButton>
        <Button
          onClick={onRemove}
          size="small"
          style={{ background: theme.palette.secondary.main }}
        >
          {intl.formatMessage({ id: 'profile.update-auth-remove' })}
        </Button>
      </UXPDialog.Actions>
    </UXPDialog.Dialog>
  )
}

export default UnregisterMFADialog
