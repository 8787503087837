import Paper from '@mui/material/Paper'
import React from 'react'
import makeStyles from '@mui/styles/makeStyles'

const useStyles = makeStyles(theme => ({
  root: {
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      backgroundColor: 'transparent',
      boxShadow: 'none',
    },
    display: 'flex',
    flexDirection: 'column',
    width: '500px',
    padding: '16px',
  },
}))

const SocialPaper = props => {
  const classes = useStyles()
  return (
    <Paper className={classes.root} {...props}>
      {props.children}
    </Paper>
  )
}

export default SocialPaper
