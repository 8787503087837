import React from 'react'
import {
  Box,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
  useTheme,
} from '@mui/material'
import { UserButton } from '@admin-ui-common/base-user'
import SmartphoneIcon from '@mui/icons-material/Smartphone'
import TextsmsIcon from '@mui/icons-material/Textsms'
import EmailIcon from '@mui/icons-material/Email'
import SettingsBackupRestoreIcon from '@mui/icons-material/SettingsBackupRestore'
import { useIntl } from 'react-intl'
import FlowLayout from 'common/layouts/FlowLayout'
import { get } from 'lodash'
import makeStyles from '@mui/styles/makeStyles'

const useStyles = makeStyles(theme => ({
  root: {
    [theme.breakpoints.down('md')]: {
      paddingLeft: '1rem',
      paddingRight: '1rem',
    },
    [theme.breakpoints.up('sm')]: {
      paddingLeft: '2.5rem',
      paddingRight: '2.5rem',
    },
  },
}))

export default function TwoStepMethod({
  twoStepMethodToType,
  twoStepTypes,
  choose2FAMethod,
  wizard,
  getPageIndex,
  pages,
  showOtpCode,
  showTotpCode,
  data,
}) {
  const intl = useIntl()
  const theme = useTheme()

  const wizardState = wizard?.getPageState()

  const stepData = wizardState || data

  const methodTypeIcon = {
    [twoStepTypes.TOTP]: SmartphoneIcon,
    [twoStepTypes.EMAIL]: EmailIcon,
    [twoStepTypes.PHONE]: TextsmsIcon,
    [twoStepTypes.BUC]: SettingsBackupRestoreIcon,
  }

  function cancel() {
    window.location.reload()
  }

  function methodTypeLabel(type, value) {
    switch (type) {
      case twoStepTypes.TOTP:
        return intl.formatMessage({ id: 'sign-in.two-factor-method-otp-label' })
      case twoStepTypes.EMAIL:
        return intl.formatMessage(
          { id: 'sign-in.two-factor-method-email-label' },
          { email: value },
        )
      case twoStepTypes.BUC:
        return intl.formatMessage({
          id: 'sign-in.two-factor-method-buc-label',
        })
      default:
        return intl.formatMessage(
          { id: 'sign-in.two-factor-method-sms-label' },
          { phone: value },
        )
    }
  }

  const methodTypeSecondaryLabel = {
    [twoStepTypes.PHONE]: intl.formatMessage({
      id: 'sign-in.two-factor-method-sms-sublabel',
    }),
  }

  const processTwoFAMethodData = twoFAMethods =>
    twoFAMethods ? Object.entries(twoFAMethods) : null

  const twoFAMethods =
    get(stepData, 'returnParameters.twoFAMethods.returnData') ||
    processTwoFAMethodData(get(stepData, 'output.twoFAMethods'))

  const methods = [
    ...twoFAMethods.map(([id, value]) => ({
      id,
      value,
      type: twoStepMethodToType(value),
    })),
  ]

  async function selectMethod(method) {
    const processId = get(stepData, 'processId')
    const twoFAMethodOptionId = method.id
    const userId = get(stepData, 'userId')

    try {
      const res = await choose2FAMethod(
        { processId },
        { twoFAMethodOptionId, userId },
      )

      const body = get(res, 'body')

      if (wizard) {
        wizard.setPageState({
          ...wizardState,
          selectedMethod: method,
          twoStepOtpData: body,
          pkat: get(res, 'body.output.pkat'),
        })
        wizard.toPage(getPageIndex(pages.TwoStepCode))
      } else {
        if (
          method.type === twoStepTypes.EMAIL ||
          method.type === twoStepTypes.PHONE
        ) {
          showOtpCode(body, method.type)
        } else if (method.type === twoStepTypes.TOTP) {
          body.selectedMethod = method
          showTotpCode(body, null)
        }
      }
    } catch (err) {
      console.error(err)
    }
  }

  const classes = useStyles()

  return (
    <FlowLayout hideXPadding>
      <Box paddingX={{ xs: 2, sm: 5 }}>
        <Typography variant="h4">
          {intl.formatMessage({ id: 'sign-in.two-factor-method-title' })}
        </Typography>

        <Box marginTop={1}>
          <Typography variant="body1">
            {intl.formatMessage({
              id: 'sign-in.two-factor-method-subtitle',
            })}
          </Typography>
        </Box>

        <Box marginTop={4}>
          <Typography>
            {intl.formatMessage({
              id: 'sign-in.two-factor-method-description',
            })}
          </Typography>
        </Box>
      </Box>
      <Box marginTop={3}>
        <List>
          {methods.map(method => (
            <ListItem
              button
              key={method.id}
              onClick={() => selectMethod(method)}
              className={classes.root}
              data-testid={method.id}
            >
              <ListItemIcon>
                <Box
                  component={methodTypeIcon[method.type]}
                  color={theme.palette.text.primary}
                />
              </ListItemIcon>
              <ListItemText
                primary={methodTypeLabel(method.type, method.value)}
                secondary={methodTypeSecondaryLabel[method.type]}
              />
            </ListItem>
          ))}
        </List>
      </Box>
      <Box marginTop={4} paddingX={{ xs: 2, sm: 5 }}>
        <UserButton variant="outlined" size="large" onClick={cancel}>
          {intl.formatMessage({ id: 'common.button.cancel' })}
        </UserButton>
      </Box>
    </FlowLayout>
  )
}
