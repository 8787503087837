export const POST_REGISTER_MFA_EMAIL_PHONE_REQUEST =
  'POST_REGISTER_MFA_EMAIL_PHONE_REQUEST'
export const POST_REGISTER_MFA_EMAIL_PHONE_SUCCESS =
  'POST_REGISTER_MFA_EMAIL_PHONE_SUCCESS'
export const POST_REGISTER_MFA_EMAIL_PHONE_FAILURE =
  'POST_REGISTER_MFA_EMAIL_PHONE_FAILURE'

export const POST_REGISTER_MFA_STEP_REQUEST = 'POST_REGISTER_MFA_STEP_REQUEST'
export const POST_REGISTER_MFA_STEP_SUCCESS = 'POST_REGISTER_MFA_STEP_SUCCESS'
export const POST_REGISTER_MFA_STEP_FAILURE = 'POST_REGISTER_MFA_STEP_FAILURE'
export const UPDATE_SELECTED_IDENTITY = 'UPDATE_SELECTED_IDENTITY'
export const CLEAR_EMAIL_PHONE = 'CLEAR_EMAIL_PHONE'

export const POST_REGISTER_MFA_APP_REQUEST = 'POST_REGISTER_MFA_APP_REQUEST'
export const POST_REGISTER_MFA_APP_SUCCESS = 'POST_REGISTER_MFA_APP_SUCCESS'
export const POST_REGISTER_MFA_APP_FAILURE = 'POST_REGISTER_MFA_APP_FAILURE'

export const POST_REGISTER_MFA_APP_STEP_REQUEST =
  'POST_REGISTER_MFA_APP_STEP_REQUEST'
export const POST_REGISTER_MFA_APP_STEP_SUCCESS =
  'POST_REGISTER_MFA_APP_STEP_SUCCESS'
export const POST_REGISTER_MFA_APP_STEP_FAILURE =
  'POST_REGISTER_MFA_APP_STEP_FAILURE'

export const PASSWORD_PROMPT = 'PasswordPrompt'

export const AUTHN_IDENTIFIER_PROMPT = 'AuthnIdentifierPrompt'
export const OTP_PROMPT = 'OTPPrompt'

export const QR_CODE_PROMPT = 'QRCodePrompt'
export const TOTP_VERIFICATION_PROMPT = 'TotpVerificationPrompt'

export const GET_TRUSTED_DEVICES_RESPONSE = 'GET_TRUSTED_DEVICES_RESPONSE'
export const GET_TRUSTED_DEVICES_ERROR = 'GET_TRUSTED_DEVICES_ERROR'
