import conf from 'conf'
import i from 'common/utils/i'

export const LOAD_USER_RESPONSE = 'LOAD_USER_RESPONSE'
const loadUserResponse = user => ({
  type: LOAD_USER_RESPONSE,
  user,
})

export const LOAD_USER_ERROR = 'LOAD_USER_ERROR'
const loadUserError = error => ({
  type: LOAD_USER_ERROR,
  error,
})

export const loadUser = () => dispatch => {
  const url = `${conf.apiRoot}/user`

  return i
    .get(url)
    .then(({ body }) => dispatch(loadUserResponse(body)))
    .catch(error => dispatch(loadUserError(error)))
}

export const updateAttribute = payload => dispatch => {
  const url = `${conf.apiRoot}/user`

  return i
    .put(url, { data: payload })
    .then(({ body }) => dispatch(loadUserResponse(body)))
    .catch(error => {
      dispatch(loadUserError(error))
      throw error
    })
}
