import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { injectIntl } from 'react-intl'

import ManageAuthWizard from 'protected/profile/authWizard/ManageAuthWizard'
import DetailView from 'protected/common/DetailView'
import withAuthDialog from './withAuthDialog'

import * as selectors from '../selectors'
import MuiDivider from '@mui/material/Divider'
import { Box } from '@mui/material'

import withStyles from '@mui/styles/withStyles'

import { get } from 'lodash'
import FeatureFlagsService from '../../../common/services/FeatureFlagsService'
import IdentifierItem, { processIdentifiers } from './IdentifierItem'

const contentPadding = 16

const muStyles = theme => ({
  divider: {
    marginLeft: `-${contentPadding}px`,
    marginRight: `-${contentPadding}px`,
    [theme.breakpoints.down('sm')]: {
      marginLeft: 'unset',
      marginRight: 'unset',
    },
  },
})

class EmailDetailView extends React.PureComponent {
  static propTypes = {
    onChangeDialogOpen: PropTypes.func,
    onVerifyDialogOpen: PropTypes.func,
    onChangeDialogClose: PropTypes.func,
    addDialogOpen: PropTypes.func,
    deleteDialogOpen: PropTypes.func,
    emails: PropTypes.arrayOf(PropTypes.string),
    dialogOpen: PropTypes.bool,
    showVerify: PropTypes.bool,
    stateData: PropTypes.object,
  }

  state = {
    selectedIdentifier: null,
  }

  render() {
    const {
      onChangeDialogOpen,
      onVerifyDialogOpen,
      onChangeDialogClose,
      addDialogOpen,
      deleteDialogOpen,
      emails,
      dialogOpen,
      showVerify,
      intl,
      classes,
      stateData,
    } = this.props

    const processedEmails = processIdentifiers(emails)

    const emptyMessage = intl.formatMessage({
      id: 'profile.detail.email.add-recovery-email',
    })
    const pendingMessage = intl.formatMessage({
      id: 'profile.common.pending-verification',
    })

    const maximumAllowedEmails = FeatureFlagsService.get('process.maxEmails')
    const allowPostponedVerification = FeatureFlagsService.get(
      'process.userManagement.AddOrUpdateAuthnIdentifier.allowPostponedVerification',
    )
    const canAddEmails = maximumAllowedEmails
      ? processedEmails.length < maximumAllowedEmails
      : true

    const Divider = () => (
      <Box my={2} className={classes.divider}>
        <MuiDivider />
      </Box>
    )

    return (
      <DetailView
        title={intl.formatMessage({ id: 'profile.detail.email.title' })}
        description={intl.formatMessage({
          id: 'profile.detail.email.description',
        })}
        contentPadding={contentPadding}
        hideProfilePaperOnMobile
      >
        {Array.isArray(processedEmails) &&
          processedEmails.map((item, index) => (
            <>
              <IdentifierItem
                type="email"
                item={item}
                stateData={stateData}
                emptyMessage={emptyMessage}
                pendingMessage={pendingMessage}
                onChangeDialogOpen={onChangeDialogOpen}
                onVerifyDialogOpen={onVerifyDialogOpen}
                deleteDialogOpen={deleteDialogOpen}
                setState={data => this.setState(data)}
              />
              {item.replacementIdentifier && (
                <>
                  <Box mx={2}>
                    <Divider />
                  </Box>
                  <IdentifierItem
                    type="email"
                    item={item.replacementIdentifier}
                    stateData={stateData}
                    emptyMessage={emptyMessage}
                    pendingMessage={pendingMessage}
                    onChangeDialogOpen={onChangeDialogOpen}
                    onVerifyDialogOpen={onVerifyDialogOpen}
                    deleteDialogOpen={deleteDialogOpen}
                    setState={data => this.setState(data)}
                  />
                </>
              )}
              {index !== processedEmails.length - 1 && <Divider />}
            </>
          ))}

        {canAddEmails && (
          <>
            {processedEmails.length > 0 && <Divider />}
            <DetailView.AddIdentifier
              type="email"
              onVerifyOpen={() => {
                this.setState({
                  selectedIdentifier: '',
                  mode: 'add',
                })
                addDialogOpen()
              }}
            />
          </>
        )}

        {dialogOpen && (
          <ManageAuthWizard
            onClose={onChangeDialogClose}
            auth={get(this.state.selectedIdentifier, 'email')}
            pendingOrActivatingAuth={get(
              this.state.pendingOrActivatingEntity,
              'email',
            )}
            allowPostponedVerification={allowPostponedVerification}
            showVerify={showVerify}
            mode={this.state.mode}
            owner="email"
          />
        )}
      </DetailView>
    )
  }
}

const mapStateToProps = state => ({
  emails: selectors.getEmails(state),
  stateData: state,
})

export default connect(mapStateToProps)(
  withStyles(muStyles)(withAuthDialog(injectIntl(EmailDetailView))),
)
