import React from 'react'
import { withRouter } from 'react-router-dom'
import Box from '@mui/material/Box'
import createStyles from '@mui/styles/createStyles'
import makeStyles from '@mui/styles/makeStyles'

import styled from 'styled-components'

import Typography from '@mui/material/Typography'
import ArrowForwardRounded from '@mui/icons-material/ArrowForwardRounded'

import { List, UserButton } from '@admin-ui-common/base-user'
import { analyticsProvider } from '../../App'
import { useIntl } from 'react-intl'

import { isEmpty } from 'lodash'

const useStyles = makeStyles(theme =>
  createStyles({
    root: {
      paddingTop: '16px',
      paddingBottom: '16px',
    },
    icon: {
      color: theme.palette.text.secondary,
      cursor: 'pointer',
    },
    body1: {
      color: theme.palette.text.secondary,
    },
    listWrapper: {
      display: 'flex',
      flexDirection: 'column',
      gap: '8px',
    },
  }),
)

const PrimaryWrapper = ({ children, value, placeholderValue, classes }) =>
  !isEmpty(value) ? (
    <Box data-q={value}>
      {Array.isArray(value) ? (
        <div className={classes.listWrapper}>
          {value.map(listItem => (
            <li>{listItem}</li>
          ))}
        </div>
      ) : (
        children
      )}
    </Box>
  ) : (
    <Typography
      className={classes.body1}
      variant="body1"
      data-q={placeholderValue}
    >
      {children}
    </Typography>
  )

const StyledListItem = styled(List.ListItem)`
  z-index: 1;
  & > .MuiListItemAvatar-root {
    width: 160px;
  }
  ${props => {
    return (
      props.buttonText &&
      `&&.MuiListItem-secondaryAction {
                padding-right: calc(32px + ${props.secondaryItemWidth}px);
              }`
    )
  }}
`

const ProfileItem = ({
  name,
  value,
  placeholder,
  path,
  history,
  interactive,
  buttonText,
}) => {
  const classes = useStyles()

  const intl = useIntl()

  const showDetail = () => {
    analyticsProvider.sendAnalytics({
      type: 'event',
      action: 'click',
      event_category: 'button',
      event_label: `${baseEventTag}.show-detail`,
      value: 0,
    })
    history.push(path)
  }

  const isValueEmpty = isEmpty(value)

  const placeholderValue = isValueEmpty ? placeholder : ''

  const flowType = 'profileItem'
  const baseEventTag = `${flowType}.${name}`

  const [secondaryItemWidth, setSecondaryItemWidth] = React.useState(0)

  const secondaryItemRef = React.useCallback(node => {
    if (node !== null) {
      const width = node.getBoundingClientRect().width
      setSecondaryItemWidth(width)
    }
  }, [])

  return (
    <StyledListItem
      className={`q-profileItem-lineItem ${classes.root}`}
      onClick={showDetail}
      button={interactive}
      buttonText
      secondaryItemWidth={secondaryItemWidth}
    >
      <List.ListItemLabel
        hideOnMobile
        secondary={<Typography variant="caption">{name}</Typography>}
        data-q={name}
      />
      <List.ListItemText
        showOnMobile
        secondaryFirst
        primary={
          <PrimaryWrapper
            classes={classes}
            value={value}
            placeholderValue={placeholderValue}
          >
            {isValueEmpty ? placeholderValue : value}
          </PrimaryWrapper>
        }
        secondary={<Typography variant="caption">{name}</Typography>}
      />

      <List.ListItemSecondaryAction>
        {buttonText ? (
          <UserButton
            variant="outlined"
            size="small"
            color="primary"
            aria-label={intl.formatMessage(
              { id: 'profile-item.aria-label.view-name-details' },
              { name: name },
            )}
            onClick={showDetail}
            forwardedRef={secondaryItemRef}
          >
            {buttonText}
          </UserButton>
        ) : (
          <ArrowForwardRounded
            className={classes.icon}
            aria-label={intl.formatMessage(
              { id: 'profile-item.aria-label.view-name-details' },
              { name: name },
            )}
            onClick={showDetail}
          />
        )}
      </List.ListItemSecondaryAction>
    </StyledListItem>
  )
}

export default withRouter(ProfileItem)
