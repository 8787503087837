import isPhone from 'common/utils/isPhone'
import { getCountryCodeFromNumber } from './googlePhoneUtils'
import { hasUIFeatureFlag } from 'common/services/FeatureFlagsService'

const formatMobile = (mobile, appId, isMobileFormat = false) => {
  const isNopal = appId === 'nopal' || appId === 'hbomax'

  if (!mobile) return ''
  if (!isPhone(mobile)) return mobile
  /**
   * Nopal/HBOMax should always have mobile formatting
   * we should ignore isMobileFormat flag in this case
   */
  if (isNopal) {
    return `(${mobile.slice(0, 2)}) ${mobile.slice(2, 6)}-${mobile.slice(6)}`
  }
  if (!isMobileFormat) {
    const excludeCountryCode = hasUIFeatureFlag('excludeCountryCode')
    if (getCountryCodeFromNumber(mobile) && !excludeCountryCode)
      return '+' + mobile
    return mobile
  }

  return `(${mobile.slice(0, 3)}) ${mobile.slice(3, 6)}-${mobile.slice(6)}`
}

export const prependPlusSignIfMobile = identifier => {
  const excludeCountryCode = hasUIFeatureFlag('excludeCountryCode')

  if (excludeCountryCode) {
    return identifier
  }

  if (!identifier?.includes('@') && getCountryCodeFromNumber(identifier)) {
    return '+' + identifier
  }

  return identifier
}

export default formatMobile
