import React from 'react'
import PropTypes from 'prop-types'
import { noop, compact } from 'lodash'
import { withRouter } from 'react-router-dom'
import { FormattedMessage } from 'react-intl'
import { Box } from '@mui/material/'
import getSidebarMenu from 'common/utils/getSidebarMenu'
import Hidden from '@mui/material/Hidden'

import conf from 'conf'

import { Sidebar } from '@admin-ui-common/base-user'

const SideBar = ({ open = false, sidebarToggle = noop, location }) => {
  const ComponentDecorator = ({ mobile, topGutter, location }) => {
    const [routePath] = compact(location.pathname.split('/'))

    const { sidebarMenu } = conf

    if (sidebarMenu.length < 2) {
      return null
    }

    const sidebarMenuList = getSidebarMenu(sidebarMenu)
    return (
      <Box display="flex">
        <Sidebar
          open={open}
          mobile={mobile}
          topGutter={topGutter}
          onClose={sidebarToggle}
          onOpen={sidebarToggle}
        >
          <Sidebar.List>
            {sidebarMenuList.flatMap(
              ({ path, displayName, defaultMessage, enable }) => {
                const isActive = path === routePath
                if (enable) {
                  return [
                    <Sidebar.ListItem
                      active={isActive}
                      key={displayName}
                      to={path}
                      onClick={sidebarToggle}
                    >
                      <FormattedMessage
                        id={displayName}
                        defaultMessage={defaultMessage}
                      />
                    </Sidebar.ListItem>,
                  ]
                }
                return []
              },
            )}
          </Sidebar.List>
        </Sidebar>
      </Box>
    )
  }

  return (
    <>
      <Hidden lgUp>
        <ComponentDecorator topGutter={0} mobile location={location} />
      </Hidden>
      <Hidden lgDown>
        <ComponentDecorator topGutter="56px" location={location} />
      </Hidden>
    </>
  )
}

SideBar.propTypes = {
  container: PropTypes.object,
  location: PropTypes.object,
  open: PropTypes.bool,
  sidebarToggle: PropTypes.func,
}

export default withRouter(SideBar)
