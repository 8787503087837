import React from 'react'
import Box from '@mui/material/Box'

import CircularProgress from 'common/components/CircularProgress'
import { ImpulseSpinner } from 'react-spinners-kit'

import { UserButton } from '@admin-ui-common/base-user'

export const TextPrimaryButton = ({ className, classes, ...props }) => (
  <UserButton
    className={className}
    disableRipple
    disableElevation
    variant="text"
    {...props}
  />
)

export const TextPrimaryFillButton = ({
  className,
  classes,
  size = 'small',
  ...props
}) => <UserButton disableRipple size={size} disableElevation {...props} />

export const TextSecondaryButton = ({
  className,
  classes,
  size = 'small',
  ...props
}) => (
  <UserButton
    disableRipple
    disableElevation
    size={size}
    variant="outlined"
    {...props}
  />
)

export const TextWarningButton = ({ className, classes, ...props }) => (
  <UserButton
    disableRipple
    disableElevation
    destructive
    variant="text"
    {...props}
  />
)

const ButtonBase = ({
  children,
  disabled,
  isLoading,
  classes,
  className,
  uppercase,
  variant = 'contained',
  color = 'primary',
  spinnerType = 'impulse',
  size = 'medium',
  fullWidth,
  ...props
}) => {
  const isWarning = variant === 'warning'

  return (
    <Box position="relative">
      <UserButton
        color={color}
        {...props}
        size={size}
        variant={variant}
        destructive={isWarning}
        disabled={isLoading || disabled}
        disableElevation
        disableRipple
        fullWidth={fullWidth}
      >
        {isLoading ? (
          spinnerType === 'impulse' ? (
            <ImpulseSpinner
              frontColor="#fff"
              backColor="transparent"
              size={35}
            />
          ) : (
            <CircularProgress />
          )
        ) : (
          children
        )}
      </UserButton>
    </Box>
  )
}

export const Button = ButtonBase
