import React, { useState } from 'react'
import { Menu } from '@admin-ui-common/base-user'
import ArrowDropUp from '@mui/icons-material/ArrowDropUp'
import ArrowDropDown from '@mui/icons-material/ArrowDropDown'
import { useDispatch, useSelector } from 'react-redux'
import { setLocale } from '@admin-ui-common/utils'
import { useIntl } from 'react-intl'
import { UNAUTHORIZED_USER_LOCALE } from '@admin-ui-common/utils'
import FeatureFlagsService from 'common/services/FeatureFlagsService'
import { getAvailableLocales } from 'common/constants/locale'
import { useLocale } from '@admin-ui-common/utils'
import { Typography } from '@mui/material'
import { TextPrimaryButton } from 'common/components/button/Button'

function LocaleSelect() {
  const [anchorEl, setAnchorEl] = useState(null)
  const dispatch = useDispatch()
  const intl = useIntl()
  const user = useSelector(state => state.user)
  const hasSession = useSelector(state => state.session.hasSession)
  const localeFromStore = useSelector(state => state.locale)

  const detectLocale = useSelector(
    state => state.configuration.config.detectLocale,
  )
  const defaultLocale = useSelector(
    state => state.configuration.config.defaultLocale,
  )

  const locale = useLocale(
    user,
    localeFromStore,
    hasSession,
    FeatureFlagsService.get('system.defaultLocale'),
    detectLocale,
    defaultLocale,
    getAvailableLocales(),
  )

  function handleMenuButtonClick(e) {
    setAnchorEl(e.currentTarget)
  }

  function handleMenuClose() {
    setAnchorEl(null)
  }

  function updateLocale(newLocale) {
    localStorage.setItem(UNAUTHORIZED_USER_LOCALE, newLocale)
    dispatch(setLocale(newLocale))
    handleMenuClose()
  }

  return (
    <>
      <div>
        <TextPrimaryButton
          aria-haspopup="true"
          onClick={handleMenuButtonClick}
          size="small"
          endIcon={
            Boolean(anchorEl) ? (
              <ArrowDropUp color="default" fontSize="24px" />
            ) : (
              <ArrowDropDown color="default" fontSize="24px" />
            )
          }
        >
          <Typography variant="body2">
            {intl.formatMessage({
              id: `language.${locale}`,
            })}
          </Typography>
        </TextPrimaryButton>
      </div>
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        {[...getAvailableLocales()].map(locale => (
          <Menu.MenuItem key={locale} onClick={() => updateLocale(locale)}>
            {intl.formatMessage({ id: `language.${locale}` })}
          </Menu.MenuItem>
        ))}
      </Menu>
    </>
  )
}

export default LocaleSelect
