import conf from 'conf'
import i from 'common/utils/i'
import process from 'common/utils/process'
import { getRedirectLocation } from 'common/utils/getRedirectUrl'
import isPrivacyAndConsentFlow from 'common/utils/isPrivacyAndConsentFlow'

const urlRoot = `${conf.apiRoot}/session`
const urlStep = `${conf.apiRoot}/process/step`

export const CHECK_SESSION_REQUEST = 'CHECK_SESSION_REQUEST'
const checkSessionRequest = () => ({
  type: CHECK_SESSION_REQUEST,
})

export const CHECK_SESSION_SUCCESS = 'CHECK_SESSION_SUCCESS'
const checkSessionSuccess = user => ({
  type: CHECK_SESSION_SUCCESS,
  user,
})

export const CHECK_SESSION_ERROR = 'CHECK_SESSION_ERROR'
const checkSessionError = error => ({
  type: CHECK_SESSION_ERROR,
  error,
})

export const checkSession = () => dispatch => {
  dispatch(checkSessionRequest())

  const url = `${conf.apiRoot}/user`

  return i
    .get(url, {
      ignoreErrorRedirect: true,
      ...(isPrivacyAndConsentFlow
        ? { headers: { 'x-idp-authentication': true } }
        : {}),
    })
    .then(({ body }) => dispatch(checkSessionSuccess(body)))
    .catch(error => dispatch(checkSessionError(error)))
}

export const START_SESSION_REQUEST = 'START_SESSION_REQUEST'
const startSessionRequest = () => ({
  type: START_SESSION_REQUEST,
})

export const START_SESSION_RESPONSE = 'START_SESSION_RESPONSE'
const startSessionResponse = () => ({
  type: START_SESSION_RESPONSE,
})

export const START_SESSION_SUCCESS = 'START_SESSION_SUCCESS'
const startSessionSuccess = () => ({
  type: START_SESSION_SUCCESS,
})

export const START_SESSION_ERROR = 'START_SESSION_ERROR'
const startSessionError = error => ({
  type: START_SESSION_ERROR,
  error,
})

export const handleProcess = (dispatch, response) => {
  const isProcessComplete =
    !!response.body.userId || process.isCompleteResponse(response)

  const location = getRedirectLocation(response.metadata, '')
  if (isProcessComplete && location !== '') {
    window.location = location
    return { beingRedirected: true }
  } else if (isProcessComplete && location === '') {
    return i
      .get(`${conf.apiRoot}/user`)
      .then(({ body }) => dispatch(checkSessionSuccess(body)))
      .then(() => dispatch(startSessionSuccess()))
  } else if (!isProcessComplete) {
    dispatch(startSessionResponse())
    return response
  }
}

export const startSession = data => dispatch => {
  dispatch(startSessionRequest())

  const url = `${urlRoot}/start${window.location.search ||
    window.originalLocationSearch}`

  return i
    .post(url, { data, ignoreErrorRedirect: true })
    .then(response => handleProcess(dispatch, response))
    .catch(error => dispatch(startSessionError(error)))
}

export const startSessionStep = data => dispatch => {
  dispatch(startSessionRequest())

  const url = `${urlStep}${window.location.search ||
    window.originalLocationSearch}`

  return i
    .put(url, { data, ignoreErrorRedirect: true })
    .then(response => handleProcess(dispatch, response))
    .catch(error => dispatch(startSessionError(error)))
}

export const START_SESSION_ERROR_RESET = 'START_SESSION_ERROR_RESET'
const startSessionErrorReset = () => ({
  type: START_SESSION_ERROR_RESET,
})

export const resetSessionStartError = () => dispatch =>
  dispatch(startSessionErrorReset())

export const END_SESSION_SUCCESS = 'END_SESSION_SUCCESS'
const endSessionSuccess = () => ({
  type: END_SESSION_SUCCESS,
})

export const endSession = () => dispatch => {
  const url = `${urlRoot}/end`

  return i.delete(url).then(() => dispatch(endSessionSuccess()))
}
