import React, { useEffect } from 'react'
import { useIntl, FormattedMessage } from 'react-intl'
import moment from 'moment'

import * as UXPCard from 'common/components/Card'
import { Title, Body } from 'common/components/Typography'
import { Button } from 'common/components/button/Button'

import { labelFormatter } from 'protected/profile/constants'
import useMainRedirect from 'common/components/hooks/useMainRedirect'

import { analyticsProvider } from 'App.js'
import { useSelector } from 'react-redux'

const CancelSuccess = ({ wizard, flowType }) => {
  const intl = useIntl()
  const mainRedirect = useMainRedirect(`/${flowType}`)

  const { mobile, productDetails = {}, metadata } = wizard.getPageState()
  const { nextBillingDate } = productDetails

  const pageTitle = 'cancel_success'
  const baseEventTag = `${flowType}.cancel_success`

  const appId = useSelector(state => state.configuration.config.id)

  useEffect(() => {
    analyticsProvider.sendAnalytics({
      type: 'page_view',
      page_title: pageTitle,
      page_path: `/${flowType}#${pageTitle}`,
    })
  }, [flowType])

  const onOkay = () => {
    analyticsProvider.sendAnalytics({
      type: 'event',
      action: 'click',
      event_category: 'button',
      event_label: `${baseEventTag}.okay`,
      value: 0,
    })

    const isStaticRedirect = mainRedirect(metadata)
    if (isStaticRedirect) {
      wizard.setPageState({})
      wizard.toPage(0)
    }
  }

  return (
    <UXPCard.Card>
      <UXPCard.Content>
        <Title>
          {intl.formatMessage({
            id: 'onboard-cancel.cancel-success.title',
          })}
        </Title>
        <Body>
          {
            <FormattedMessage
              id="onboard-cancel.cancel-success.subscription-was-canceled"
              values={{
                mobileNumber: <b>{labelFormatter.mobile(mobile, appId)}</b>,
                nextBillingDate: moment(nextBillingDate).format('LL'),
              }}
            />
          }
        </Body>
      </UXPCard.Content>
      <UXPCard.Divider />
      <UXPCard.Actions>
        <Button onClick={onOkay}>
          {intl.formatMessage({ id: 'onboard.common.okay' })}
        </Button>
      </UXPCard.Actions>
    </UXPCard.Card>
  )
}

export default CancelSuccess
