import {
  LOAD_PURCHASE_RESPONSE,
  LOAD_PURCHASE_ERROR,
} from 'common/actions/subscription'

const subscriptionReducer = (state = {}, action) => {
  switch (action.type) {
    case LOAD_PURCHASE_RESPONSE:
      return action.response
    case LOAD_PURCHASE_ERROR:
      return action.error
    default:
      return state
  }
}

export default subscriptionReducer
