import React from 'react'
import PropTypes from 'prop-types'
import { isEmpty, noop, get } from 'lodash'
import { injectIntl, FormattedMessage } from 'react-intl'

import conf from 'conf'
import i from 'common/utils/i'
import { UserTextLink } from '@admin-ui-common/base-user'
import TextField from 'common/components/textfield/TextField'
import { Button } from 'common/components/button/Button'
import styles from '../../styles.module.css'
import { Typography } from '@mui/material'
import Box from '@mui/material/Box'

import withTheme from '@mui/styles/withTheme'
import ActionLabel from '../../../common/components/ActionLabel'
import { analyticsProvider } from '../../../App'
import { connect } from 'react-redux'
import { compose } from 'redux'
import {
  formatErrorMessage,
  isVerifyOTPError,
  resolveErrorEntity,
  isTokenMaxResendReached,
  isInvalidActConsumption,
  isInvalidPkat,
  isTooManyRetries,
} from 'common/utils/processBackendErrors'
import { hasUIFeatureFlag } from 'common/services/FeatureFlagsService'
import { verifyCode } from 'common/processes/token'

import { VerifyOTPErrors } from 'common/components/VerifyOTPErros'

import { process as utilProcess } from '@admin-ui-common/utils'
import { displayIdentifier } from 'common/utils/formatNumber'

const errorContext = 'verifyUsername'

class VerifyUsername extends React.Component {
  static propTypes = {
    process: PropTypes.oneOf(['sign-up', 'forgot-password', 'password-reset']),
    username: PropTypes.string,
    countryCode: PropTypes.string,
    type: PropTypes.oneOf(['email', 'phone']),
    actionName: PropTypes.oneOf(['onboardUser', 'passworRecovery']),
    pkat: PropTypes.string,
    tokenId: PropTypes.number,
    onVerified: PropTypes.func,
    onErrorVerified: PropTypes.func,
    forcedUsername: PropTypes.bool,
  }

  static defaultProps = {
    onErrorVerified: noop,
  }

  constructor(props) {
    super(props)
    this.state = {
      code: '',
      error: '',
      countdown: conf.resendOTPCountdownDuration,
      pkat: this.props.pkat,
      disableOTPField: false,
      maxWrongAttemptsReached: false,
      disableCodeField: false,
      disableVerify: false,
      errors: '',
    }
    this.pageTitle = 'verifyUsername'
    this.baseEventTag = `signup.${this.pageTitle}`
  }

  getLabelKey = () => {
    return this.props.type === 'email' ? 'verifyEmailOTP' : 'verifyMobileOTP'
  }

  componentDidMount() {
    analyticsProvider.sendAnalytics({
      type: 'page_view',
      page_title: this.pageTitle,
      page_path: `/signup#${this.pageTitle}`,
    })
    this.runCountDown()
  }

  componentWillUnmount() {
    clearInterval(this.interval)
  }

  runCountDown() {
    this.interval = setInterval(() => {
      const { countdown } = this.state
      if (countdown === 1) {
        clearInterval(this.interval)
        this.setState({ disableOTPField: false })
      }
      this.setState({ countdown: countdown - 1 })
    }, 1000)
  }

  handleCodeChange = event => {
    if (!isEmpty(event.target.value) && !isEmpty(this.state.error)) {
      this.setState({ error: '' })
    }
    this.setState({ code: event.target.value, disableVerify: false })
  }

  getFlowSubStep = () => {
    switch (this.props.type) {
      case 'email':
        return 'authenticateEmailOTP'
      case 'phone':
        return 'authenticateMobileOTP'
      case 'email-setup':
        return 'setupEmailOTP'
      case 'phone-setup':
        return 'setupPhoneOTP'
      default:
        return ''
    }
  }

  handleVerify = async event => {
    event.preventDefault()
    analyticsProvider.sendAnalytics({
      type: 'event',
      action: 'click',
      event_category: 'button',
      event_label: `${this.baseEventTag}.verify`,
      value: 0,
    })
    const { code, pkat } = this.state
    const { actionName, intl, onVerified, onErrorVerified } = this.props
    let error = ''

    if (isEmpty(code)) {
      error = intl.formatMessage({ id: 'error.verification-code-incorrect' })
    }

    if (!isEmpty(error)) {
      analyticsProvider.sendAnalytics({
        type: 'event',
        action: 'click',
        event_category: 'button',
        event_label: `${this.baseEventTag}.verify`,
        value: 1,
      })
      this.setState({ error })
      return
    }

    const flowSubStep = this.getFlowSubStep(this.props.type.includes(''))
    try {
      const respo = flowSubStep.includes('setup')
        ? await utilProcess.step({
            processId: this.props.processId,
            parameters: {
              code: code,
              pkat: pkat,
            },
          })
        : await verifyCode(code, pkat, actionName)
      onVerified(true, respo, code, pkat)
    } catch (err) {
      analyticsProvider.sendAnalytics({
        type: 'event',
        action: 'click',
        event_category: 'button',
        event_label: `${this.baseEventTag}.verify`,
        value: 1,
      })

      this.handleOtpErrors(err)
    }
  }

  handleResend = event => {
    analyticsProvider.sendAnalytics({
      type: 'event',
      action: 'click',
      event_category: 'button',
      event_label: `${this.baseEventTag}.resend`,
      value: 0,
    })

    const { countdown } = this.state

    event.preventDefault()
    if (countdown === 0) {
      const { pkat } = this.state
      const { tokenId, actionName } = this.props
      const resendUrl = `${conf.apiRoot}/session/token?tokenId=${tokenId}&pkat=${pkat}&actionName=${actionName}`

      if (pkat) {
        i.put(resendUrl, { ignoreErrorRedirect: true })
          .then(res => {
            const pkat = get(res, 'body.pkat')
            this.setState(
              {
                countdown: conf.resendOTPCountdownDuration,
                pkat,
                disableOTPField: true,
                disableVerify: false,
                disableVerifyButton: false,
                maxWrongAttemptsReached: false,
                disableCodeField: false,
                errorResponseBody: null,
                error: '',
              },
              this.runCountDown,
            )
          })
          .catch(err => {
            analyticsProvider.sendAnalytics({
              type: 'event',
              action: 'click',
              event_category: 'button',
              event_label: `${this.baseEventTag}.resend`,
              value: 1,
            })
            this.handleOtpErrors(err)
          })
      } else {
        this.setState(
          {
            countdown: conf.resendOTPCountdownDuration,
          },
          this.runCountDown,
        )
      }
    }
  }

  handleOtpErrors = err => {
    const { intl } = this.props

    if (isInvalidActConsumption(err.body)) {
      let errorMessage = formatErrorMessage({
        intl,
        id: 'error.verification-code-incorrect',
      })
      this.setState({ error: errorMessage, disableVerify: true })
      return
    } else if (isVerifyOTPError(err.body)) {
      this.setState({
        errorResponseBody: err.body,
        disableVerify: true,
        disableCodeField: true,
        countdown: 0,
        code: '',
      })
      clearInterval(this.interval)
      if (isInvalidPkat(err.body)) {
        this.setState({ countdown: true })
      }
      return
    } else if (isTokenMaxResendReached(err.body)) {
      clearInterval(this.interval)
      this.setState({
        errorResponseBody: err.body,
        code: '',
        countdown: true,
      })
    } else if (isTooManyRetries(err.body)) {
      this.setState({
        maxWrongAttemptsReached: true,
        error: formatErrorMessage({
          id: 'error.process-terminated-with-too-many-retries.context.sign-in',
          intl,
        }),
        disableVerify: true,
      })
    } else {
      this.setState({ error: resolveErrorEntity({ intl, error: err.body }) })
      return
    }
  }

  getCountdown() {
    const { intl } = this.props
    const { countdown } = this.state

    if (countdown === 0) {
      return intl.formatMessage({
        id: `separatedMultiStep.onboard-${this.getLabelKey()}.textLink.resendCode-countdownComplete`,
      })
    }

    return intl.formatMessage(
      {
        id: `separatedMultiStep.onboard-${this.getLabelKey()}.textLink.resendCode-countdown`,
      },
      { countdown },
    )
  }

  getMessageID = () => {
    const { type, forcedUsername, process } = this.props

    return forcedUsername
      ? `${process}.progressive.verify-username.${type}.forced.message`
      : `separatedMultiStep.onboard-${this.getLabelKey()}.body`
  }

  handleKeyDown = e => {
    if (e.keyCode === 13) {
      this.handleVerify(e)
    }
  }

  getFormattedMessage = (type, countryCodePrefix, username) => {
    let identifier = type === 'phone' ? countryCodePrefix + username : username
    return displayIdentifier(identifier, type, noop)
  }

  resend = str => {
    const { theme } = this.props
    return (
      <UserTextLink
        onClick={this.handleResend}
        style={{ color: theme.palette.text.primary }}
        underline="always"
        variant="text"
      >
        {str}
      </UserTextLink>
    )
  }

  render() {
    const { username, countryCode, intl, type, history } = this.props
    const {
      code,
      countdown,
      error,
      maxWrongAttemptsReached,
      isLoading,
      disableVerify,
      errorResponseBody,
      disableCodeField,
    } = this.state

    const resetDisabled = Boolean(countdown) || maxWrongAttemptsReached

    const excludeCountryCode = hasUIFeatureFlag('excludeCountryCode')

    const countryCodePrefix = excludeCountryCode ? '' : countryCode

    const formattedMessage = this.getFormattedMessage(
      type,
      countryCodePrefix,
      username,
    )

    const disableVerifyButton = disableVerify || isEmpty(code)

    return (
      <>
        <Box className={styles.verifyMessage}>
          <Typography variant="body1">
            <FormattedMessage
              id={this.getMessageID()}
              values={{
                username: (
                  <span data-testid="username-span" className={styles.username}>
                    {formattedMessage}
                  </span>
                ),
              }}
            />
          </Typography>
        </Box>
        {!!errorResponseBody && (
          <Box mb={3}>
            <VerifyOTPErrors
              errors={errorResponseBody}
              errorContext={errorContext}
              handleResend={this.handleResend}
              redirectTo={{
                pathname: history?.location?.pathname,
                search: history?.location?.search,
                state: {
                  identifier: username,
                },
              }}
            />
          </Box>
        )}
        <TextField
          fullWidth
          errorText={error}
          label={intl.formatMessage({
            id: `separatedMultiStep.onboard-${this.getLabelKey()}.label.enterCode`,
          })}
          onChange={this.handleCodeChange}
          value={code}
          disabled={maxWrongAttemptsReached || disableCodeField}
          autoComplete="one-time-code"
          numeric
          onKeyDown={this.handleKeyDown}
        />
        <ActionLabel
          underline="always"
          label={this.getCountdown()}
          disabled={resetDisabled}
          onClick={this.handleResend}
        />
        <div className="mt2">
          <Button
            isLoading={isLoading}
            onClick={this.handleVerify}
            size="large"
            disabled={disableVerifyButton}
          >
            {intl.formatMessage({
              id: `separatedMultiStep.onboard-${this.getLabelKey()}.button.verify`,
            })}
          </Button>
        </div>
      </>
    )
  }
}

const mapStateToProps = state => ({
  appId: state.configuration.config.id,
})

const connectedToProps = connect(mapStateToProps)

export default compose(connectedToProps)(withTheme(injectIntl(VerifyUsername)))
