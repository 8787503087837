import { InlineErrorNotification } from '@admin-ui-common/base-user'
import { getErrorCodeMap } from '@admin-ui-common/utils'
import {
  titleFallbackOption,
  messageFallbackOption,
  getFeatureFlagParamMap,
  getContextMapFromContext,
  singleContextErrorCodes,
} from 'common/utils/processBackendErrors'

// this is not good but all similarly not good things can go in one place (here) for now
// until we get another crack at getting rid of this
const fieldErrorPatchRules = [
  {
    oldCode: 'NotReusedPassword',
    codeV2Regex: /by-time$/,
    newCode: 'NotReusedPasswordByTime',
  },
]

// mutate the error object to get the correct error messages
function patchError(error) {
  if (Array.isArray(error?.fieldErrors)) {
    error.fieldErrors = error.fieldErrors.map(fieldError => {
      const { code, code_v2, ...rest } = fieldError
      const patchRule = fieldErrorPatchRules.find(
        rule => rule.oldCode === code && rule.codeV2Regex.test(code_v2),
      )
      return patchRule
        ? {
            code: patchRule.newCode,
            code_v2,
            ...rest,
          }
        : fieldError
    })
  }
  return error
}

// a wrapper around InlineErrorNotification but with
// default title and message fallback options
// and with featureflags code params passed by default,
// in lieu of getParams or codeParamMap props

// the context prop, if passed, will provide a correct contextMap automatically to InlineNotificationError
// if passing context prop, do not also pass in contextMap, as contextMap has priority

// added errorOverride prop out of desperation - mutate the error body however you like
export const BackendErrorNotification = ({
  error,
  titleFallback = titleFallbackOption,
  messageFallback = messageFallbackOption,
  getParams,
  codeParamMap,
  context,
  contextMap,
  errorOverride = patchError,
  showAsSingle,
  ...rest
}) => {
  if (!error) {
    return null
  }

  if (typeof errorOverride === 'function') {
    patchError(error)
  }

  let onlyDisplayTitle = false

  // updating showAsSingle to also mean only show the title, no message
  if (Array.isArray(showAsSingle)) {
    const errorCodes = Object.keys(getErrorCodeMap(error))
    onlyDisplayTitle = showAsSingle.some(code => errorCodes.includes(code))
  }

  const defaultContextMap = contextMap
    ? undefined
    : {
        ...singleContextErrorCodes,
        ...(context ? getContextMapFromContext(context) : {}),
      }

  return (
    <InlineErrorNotification
      {...rest}
      error={error}
      titleFallback={titleFallback}
      message={onlyDisplayTitle ? <span aria-hidden /> : undefined}
      messageFallback={messageFallback}
      codeParamMap={
        codeParamMap ?? (getParams ? undefined : getFeatureFlagParamMap())
      }
      getParams={getParams}
      contextMap={contextMap ?? defaultContextMap}
      showAsSingle={showAsSingle}
    />
  )
}
