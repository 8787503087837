import {
  CHECK_DEVICE_REQUEST,
  START_DEVICE_SESSION,
  START_DEVICE_APPROVAL,
  DEVICE_AUTH_ERROR,
  DEVICE_AUTH_SUCCESS,
} from 'common/actions/deviceAuth'

const initialState = {
  hasDevice: false,
  isDeviceLoading: false,
  deviceError: null,
  userCode: null,
  tokenValue: null,
}

const deviceAuthReducer = (state = initialState, action) => {
  switch (action.type) {
    case CHECK_DEVICE_REQUEST:
    case START_DEVICE_APPROVAL:
      return { ...state, isDeviceLoading: true }
    case START_DEVICE_SESSION:
      return {
        ...state,
        userCode: action.userCode,
        tokenValue: action.tokenValue,
      }
    case DEVICE_AUTH_ERROR:
      return {
        ...state,
        isDeviceLoading: false,
        deviceError: action.error,
      }
    case DEVICE_AUTH_SUCCESS:
      return {
        ...state,
        hasDevice: true,
        isDeviceLoading: false,
        deviceError: null,
      }
    default:
      return state
  }
}

export default deviceAuthReducer
