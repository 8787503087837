import React, { useState, useEffect, useContext } from 'react'
import { useIntl } from 'react-intl'
import { get, has } from 'lodash'
import { useLocation } from 'react-router-dom'
import { Helmet } from 'react-helmet'

import * as UXPCard from 'common/components/Card'
import { Title, Body } from 'common/components/Typography'
import MobileNumberField, {
  mobileRegex,
} from 'common/components/textfield/MobileNumberField'
import { TextPrimaryButton, Button } from 'common/components/button/Button'
import Loader from 'common/components/Loader'

import useForgotRedirect from 'common/components/hooks/useForgotRedirect'
import useMainRedirect from 'common/components/hooks/useMainRedirect'

import { attMexCancelSubscription } from 'common/processes/subscriptionManagement'

import {
  INVALID_PRODUCT,
  NO_SUBSCRIPTION_EXISTS,
  NOT_ELIGIBLE,
  MISSING_PRODUCT_ID,
  NUMBER_IS_PORTABLE,
} from 'common/constants'

import { LogoContext, analyticsProvider } from 'App.js'
import { resolveErrorEntity } from 'common/utils/processBackendErrors'
import { getProductId } from 'common/utils/getProductId'

const notEligibleCodes = new Set([NOT_ELIGIBLE, NUMBER_IS_PORTABLE])
const notFoundCodes = new Set([INVALID_PRODUCT, MISSING_PRODUCT_ID])

const VerificationMobile = ({ wizard, flowType, wizardIndex }) => {
  const { viewId, mobile: locationMobile } = useForgotRedirect()
  const location = useLocation()

  const [mobile, setMobile] = useState('')
  const [errorText, setErrorText] = useState('')
  const [appTitle, setAppTitle] = useState('')
  const intl = useIntl()
  const [loading, setLoading] = useState(false)
  const [innerLoading, setInnerLoading] = useState(false)
  const mainRedirect = useMainRedirect(`/${flowType}`)

  const pageTitle = 'verify_mobile'
  const baseEventTag = `${flowType}.verify_mobile`

  const productId = getProductId()

  const mobileFieldLabel = intl.formatMessage({
    id: 'onboard.common.mobile-number',
  })

  const logoCtx = useContext(LogoContext)

  useEffect(() => {
    if (viewId) {
      setMobile(locationMobile)
    }
  }, [viewId, locationMobile])

  // //////////////////////////////////////////////////////////

  useEffect(() => {
    analyticsProvider.sendAnalytics({
      type: 'page_view',
      page_title: pageTitle,
      page_path: `/${flowType}#${pageTitle}`,
    })

    setLoading(true)

    const checkProductId = async () => {
      try {
        const response = await attMexCancelSubscription({
          productId,
          ignoreErrorRedirect: true,
        })
        const productDetailsDTO = get(response, 'body.output.productDetailsDTO')

        const appName = get(productDetailsDTO, 'appName', '')
        const appLogoImage = get(
          productDetailsDTO,
          'appLogoImage',
          '/transparent.svg',
        )

        setAppTitle(appName)
        logoCtx.onLogoUrl(appLogoImage)

        setLoading(false)
      } catch (err) {
        setLoading(false)

        const errorCode = get(err, 'body.operationError[0].code')
        if (notFoundCodes.has(errorCode)) {
          wizard.toPage(wizardIndex.notFound)
        }
      }
    }

    checkProductId()
  }, [flowType, productId, wizard, wizardIndex.notFound])

  // //////////////////////////////////////////////////////////

  const setMobileCallback = e => {
    setMobile(e.target.value.replace(mobileRegex, ''))
  }

  // //////////////////////////////////////////////////////////

  const onNextPage = async () => {
    if (!mobile) {
      const errorText = intl.formatMessage({
        id: 'onboard.error.please-enter-mobile',
      })

      setErrorText(errorText)

      analyticsProvider.sendAnalytics({
        type: 'event',
        action: 'exception',
        description: `${baseEventTag}.phoneNumber: ${errorText}`,
      })

      return
    }

    setInnerLoading(true)

    try {
      const res = await attMexCancelSubscription({
        productId,
        identifier: mobile,
        ignoreErrorRedirect: true,
      })

      analyticsProvider.sendAnalytics({
        type: 'event',
        action: 'click',
        event_category: 'button',
        event_label: `${baseEventTag}.next`,
        value: 0,
      })

      setInnerLoading(false)

      const output = get(res, 'body.output', {})
      const isPin = has(output, 'pkat')

      const nextPage = isPin ? 'verifyCode' : 'signIn'
      wizard.setPageState({
        ...wizard.getPageState(),
        ...res.body,
        mobile,
      })

      wizard.toPage(wizardIndex[nextPage])
    } catch (err) {
      setInnerLoading(false)

      analyticsProvider.sendAnalytics({
        type: 'event',
        action: 'click',
        event_category: 'button',
        event_label: `${baseEventTag}.next`,
        value: 1,
      })

      const operationError = get(err, 'body.operationError[0]', {})
      const { code: errorCode, message: portUrl } = operationError

      if (notEligibleCodes.has(errorCode)) {
        const nextWizardPageState = {
          ...wizard.getPageState(),
        }
        if (errorCode === NUMBER_IS_PORTABLE) {
          nextWizardPageState.portUrl = portUrl
        }

        wizard.setPageState(nextWizardPageState)
        wizard.toPage(wizardIndex.notEligible)
      } else if (errorCode === NO_SUBSCRIPTION_EXISTS) {
        wizard.toPage(wizardIndex.alreadyUnsubscribed)
      } else if (notFoundCodes.has(errorCode)) {
        wizard.toPage(wizardIndex.notFound)
      } else {
        const errorText = resolveErrorEntity({
          intl,
          error: err.body,
        })

        setErrorText(errorText)

        analyticsProvider.sendAnalytics({
          type: 'event',
          action: 'exception',
          description: `${baseEventTag}.screen: ${errorText}`,
        })
      }
    }
  }

  // //////////////////////////////////////////////////////////

  const onCancel = () => {
    analyticsProvider.sendAnalytics({
      type: 'event',
      action: 'click',
      event_category: 'button',
      event_label: `${baseEventTag}.back`,
      value: 0,
    })

    const isStaticRedirect = mainRedirect()
    if (isStaticRedirect) {
      setMobile('')
      setErrorText('')
    }
  }

  // //////////////////////////////////////////////////////////

  if (loading) {
    return <Loader />
  }

  return (
    <UXPCard.Card>
      <UXPCard.Content>
        <Helmet>
          <title>{appTitle}</title>
        </Helmet>
        <Title>
          {intl.formatMessage({
            id: 'onboard-cancel.cancel.title',
          })}
        </Title>

        <Body mb={4}>
          {intl.formatMessage({
            id: 'onboard-cancel.cancel.we-will-need-to-verify',
          })}
        </Body>
        <MobileNumberField
          label={mobileFieldLabel}
          onChange={setMobileCallback}
          value={mobile}
          errorText={errorText}
        />
      </UXPCard.Content>
      <UXPCard.Actions>
        <TextPrimaryButton onClick={onCancel}>
          {intl.formatMessage({ id: 'onboard.common.back' })}
        </TextPrimaryButton>
        <Button onClick={onNextPage} isLoading={innerLoading}>
          {intl.formatMessage({ id: 'common.button.next' })}
        </Button>
      </UXPCard.Actions>
    </UXPCard.Card>
  )
}

export default VerificationMobile
