import React, { useEffect, useState } from 'react'
import { analyticsProvider } from '../../../App'
import { useIntl } from 'react-intl'
import FlowLayout from '../../../common/layouts/FlowLayout'
import Collapse from '@mui/material/Collapse'
import Box from '@mui/material/Box'
import TextField from '../../../common/components/textfield/TextField'
import styles from '../../styles.module.css'

import { BackendErrorNotification } from 'common/components/backendErrorNotification/BackendErrorNotification'
import { Button } from 'common/components/button/Button'
import classNames from 'classnames'
import Grid from '@mui/material/Grid'
import Checkbox from '../../../common/components/checkBox/CheckBox'
import Link from '@mui/material/Link'

import process from '../../../common/utils/process'
import FeatureFlagsService, {
  userFieldVisible,
  hasUIFeatureFlag,
} from '../../../common/services/FeatureFlagsService'
import { get } from 'lodash'
import { formatErrorMessage } from 'common/utils/processBackendErrors'
import MfaSetup from './MfaSetup'

const ProvideOnboardingDetails = props => {
  const {
    verificationResponse,
    onProcessCompletion,
    onAccountIdentiferPrompt,
  } = props

  const intl = useIntl()
  const pageTitle = 'provideDetails'

  const baseEventTag = `signup.${pageTitle}`

  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [displayName, setDisplayName] = useState('')
  const [password, setPassword] = useState('')
  const [step, setStep] = useState()
  const [response, setResponse] = useState()

  const allowEmptyPassword = false

  const [isLoading, setIsLoading] = useState(false)

  const [termsAndConditionsChecked, setTermsAndConditionsChecked] = useState(
    false,
  )
  const promptCaptcha = FeatureFlagsService.get('system.security.promptCaptcha')
  const showFirstName = userFieldVisible('firstName')
  const showLastName = userFieldVisible('lastName')
  const showDisplayName = userFieldVisible('displayName')

  const termsOfServiceURL = FeatureFlagsService.get('system.termsOfServiceURL')
  const privacyPolicyURL = FeatureFlagsService.get('system.privacyPolicyURL')

  const errorMap = {
    displayName:
      showDisplayName && intl.formatMessage({ id: 'error.display-name' }),
    firstName: showFirstName && intl.formatMessage({ id: 'error.firstname' }),
    lastName: showLastName && intl.formatMessage({ id: 'error.lastname' }),
    password:
      !allowEmptyPassword && intl.formatMessage({ id: 'error.credential' }),
  }

  const initialErrors = {
    title: '',
    firstName: '',
    lastName: '',
    password: '',
    displayName: '',
  }

  const [errors, setErrors] = useState(initialErrors)
  const [errorResponseBody, setErrorResponseBody] = useState(null)

  const signInClasses = classNames('mt2')

  useEffect(() => {
    analyticsProvider.sendAnalytics({
      type: 'page_view',
      page_title: pageTitle,
      page_path: `/signup#${pageTitle}`,
    })
  }, [])

  const handleFirstNameChange = event => {
    setFirstName(event.target.value)
  }

  const handleLastNameChange = event => {
    setLastName(event.target.value)
  }

  const handleDisplayNameChange = event => {
    setDisplayName(event.target.value)
  }

  const handlePasswordChange = event => {
    setPassword(event.target.value)
  }

  const handleCheck = event => {
    setTermsAndConditionsChecked(event.target.checked)
  }

  const validate = fieldName => event => {
    let validationErrors = errors
    let value = event.target.value.trim()

    const processValue = value => {
      if (value === '') {
        validationErrors[fieldName] = errorMap[fieldName]
      } else {
        validationErrors[fieldName] = ''
      }
    }

    switch (fieldName) {
      case 'firstName':
        if (showFirstName) {
          processValue(value)
        }
        break
      case 'lastName':
        if (showLastName) {
          processValue(value)
        }
        break
      case 'displayName':
        if (showDisplayName) {
          processValue(value)
        }
        break
      case 'password':
        if (!allowEmptyPassword) {
          processValue(value)
        }
        break

      default:
        if (value === '') {
          validationErrors[fieldName] = errorMap[fieldName]
        } else {
          validationErrors[fieldName] = ''
        }
        break
    }

    setErrors({ ...errors, ...validationErrors })
  }

  const processDetails = (response, parameters) => {
    const stepName = get(response, 'body.stepName')
    const processId = get(response, 'body.processId')
    const processName = get(response, 'body.processName')

    let requestBody = {}

    if (stepName === 'PasswordPrompt') {
      requestBody = {
        processId: processId,
        processName: processName,
        parameters: parameters,
      }
    }

    process
      .step(requestBody)
      .then(res => {
        if (get(res, 'body.lastStep')) {
          onProcessCompletion(true, res, false)
        }
        if (get(res, 'body.stepName') === 'AccountIdentifierPrompt') {
          onAccountIdentiferPrompt({
            step: 'AccountIdentifierPrompt',
            response: res,
          })
        }

        if (get(res, 'body.stepName') === 'TwoFASetupMethodPrompt') {
          setStep('TwoFASetupMethodPrompt')
          setResponse(res)
        }
      })
      .catch(err => {
        analyticsProvider.sendAnalytics({
          type: 'event',
          action: 'click',
          event_category: 'button',
          event_label: `${baseEventTag}.sign-up-btn`,
          value: 1,
        })

        setErrorResponseBody(err.body)

        onProcessCompletion(false, err, true)
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  const createID = event => {
    event.preventDefault()

    analyticsProvider.sendAnalytics({
      type: 'event',
      action: 'click',
      event_category: 'button',
      event_label: `${baseEventTag}.sign-up-btn`,
      value: 0,
    })

    const validationErrors = {}
    const { locale } = props

    const names = {}

    if (showFirstName) {
      names.firstName = firstName.trim()
      if (!names.firstName) {
        validationErrors.firstName = errorMap.firstName
      }
    }

    if (showLastName) {
      names.lastName = lastName.trim()
      if (!names.lastName) {
        validationErrors.lastName = errorMap.lastName
      }
    }

    if (showDisplayName) {
      names.displayName = displayName.trim()
      if (!names.displayName) {
        validationErrors.displayName = errorMap.displayName
      }
    }

    const credential = password.trim()
    if (
      hasUIFeatureFlag('showOnboardingPasswordField') &&
      !credential &&
      !allowEmptyPassword
    ) {
      validationErrors.password = errorMap.password
    }

    if (Object.keys(validationErrors).length) {
      analyticsProvider.sendAnalytics({
        type: 'event',
        action: 'click',
        event_category: 'button',
        event_label: `${baseEventTag}.sign-up-btn`,
        value: 1,
      })
      setErrors(validationErrors)
      return Promise.resolve()
    }

    setErrors(initialErrors)
    setIsLoading(true)

    const parameters = {
      password,
    }

    if (showFirstName) parameters.firstName = firstName
    if (showLastName) parameters.lastName = lastName
    if (showDisplayName) parameters.displayName = displayName
    if (locale) parameters.lang = locale

    processDetails(verificationResponse, parameters)
  }

  if (step === 'TwoFASetupMethodPrompt') {
    return <MfaSetup response={response} />
  }

  return (
    <FlowLayout
      title={intl.formatMessage({
        id: 'separatedMultiStep.onboard-enterDetails.title',
      })}
      showCaptcha={promptCaptcha}
    >
      <form onSubmit={createID}>
        <Collapse in={!!errorResponseBody}>
          <Box mb={3}>
            <BackendErrorNotification
              intl={intl}
              subTitle={
                <div>
                  {formatErrorMessage({
                    intl,
                    id: 'error.credential-at-least',
                  })}
                </div>
              }
              error={errorResponseBody}
              handleClose={() => {
                setErrorResponseBody(null)
              }}
              prioritize={['NotReusedPasswordByTime', 'NotReusedPassword']}
              showAsSingle={['NotReusedPasswordByTime', 'NotReusedPassword']}
            />
          </Box>
        </Collapse>
        <Grid container spacing={2}>
          {showFirstName && (
            <Grid item xs={6} className="mb2">
              <TextField
                errorText={errors.firstName}
                label={intl.formatMessage({
                  id: 'separatedMultiStep.onboard-enterDetails.label.givenName',
                })}
                onChange={handleFirstNameChange}
                value={firstName}
                onBlur={validate('firstName')}
              />
            </Grid>
          )}
          {showLastName && (
            <Grid item xs={6} className="mb2">
              <TextField
                errorText={errors.lastName}
                label={intl.formatMessage({
                  id:
                    'separatedMultiStep.onboard-enterDetails.label.familyName',
                })}
                onChange={handleLastNameChange}
                value={lastName}
                onBlur={validate('lastName')}
              />
            </Grid>
          )}
          {showDisplayName && (
            <Grid item xs={12} className="mb2">
              <TextField
                errorText={errors.displayName}
                label={intl.formatMessage({
                  id: 'profile.label.displayName',
                })}
                onChange={handleDisplayNameChange}
                value={displayName}
                onBlur={validate('displayName')}
              />
            </Grid>
          )}
          {hasUIFeatureFlag('showOnboardingPasswordField') && (
            <Grid item xs={12}>
              <TextField
                fullWidth
                label={intl.formatMessage({
                  id: 'separatedMultiStep.onboard-enterDetails.label.password',
                })}
                errorText={!allowEmptyPassword && errors.password}
                onChange={handlePasswordChange}
                value={password}
                inputType="password"
                onBlur={validate('password')}
              />
            </Grid>
          )}
        </Grid>
        <div className={styles.fieldMB} />
        <Box marginTop="18px">
          <Checkbox
            alignTop
            label={intl.formatMessage(
              {
                id: 'separatedMultiStep.onboard-enterDetails.caption.legal',
              },
              {
                'terms-link': str =>
                  termsOfServiceURL ? (
                    <Link href={termsOfServiceURL} target="_blank">
                      {str}
                    </Link>
                  ) : (
                    str
                  ),
                'privacy-link': str =>
                  privacyPolicyURL ? (
                    <Link href={privacyPolicyURL} target="_blank">
                      {str}
                    </Link>
                  ) : (
                    str
                  ),
              },
            )}
            checked={termsAndConditionsChecked}
            onChange={handleCheck}
            value="termsAndConditions"
          />
        </Box>
        <div className={signInClasses}>
          <Button
            size="large"
            isLoading={isLoading}
            onClick={createID}
            disabled={!termsAndConditionsChecked}
          >
            {intl.formatMessage({
              id: 'separatedMultiStep.onboard-enterDetails.button.onboard',
            })}
          </Button>
        </div>
      </form>
    </FlowLayout>
  )
}

export default ProvideOnboardingDetails
