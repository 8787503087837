import React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { injectIntl } from 'react-intl'

import makeStyles from '@mui/styles/makeStyles'

import Avatar from 'common/components/Avatar'

import IconButton from '@mui/material/IconButton'
import ArrowForwardRounded from '@mui/icons-material/ArrowForwardRounded'
import { List } from '@admin-ui-common/base-user'

import {
  getRoleLabel,
  getIsCurrentUser,
  getIsUserPending,
} from 'common/selectors/user'
import Typography from '@mui/material/Typography'

const useStyles = makeStyles({
  title: {
    fontSize: 16,
    fontWeight: 300,
  },
  // TODO. Investigate why outer className doesn't override Avatar's styles
  avatar: {
    fontSize: '1.3rem!important',
    width: '40px!important',
    height: '40px!important',
  },
  role: {
    color: '#7f798a',
  },
})

const UserLine = ({ user, role, isYou, intl, isPending }) => {
  const classes = useStyles()
  const { id, displayName } = user

  const getNameLabel = () => {
    const youLabel = isYou
      ? `(${intl.formatMessage({ id: 'accounts.you' })})`
      : ''
    return `${displayName} ${youLabel}`
  }

  const getRoleLabel = () => {
    return `${role}${
      isPending ? ` (${intl.formatMessage({ id: 'accounts.pending' })})` : ''
    }`
  }

  return (
    <List.ListItem
      listType="two-line"
      button
      className="q-userLine-userDetails"
    >
      <List.ListItemAvatar>
        <Avatar
          displayName={displayName}
          id={id}
          size={40}
          className={classes.avatar}
        />
      </List.ListItemAvatar>
      <List.ListItemText
        data-q={getNameLabel()}
        primary={getNameLabel()}
        secondary={<Typography variant="caption">{getRoleLabel()} </Typography>}
      />
      <List.ListItemSecondaryAction>
        <IconButton
          disableRipple
          edge="end"
          aria-label="userDetails"
          size="large"
        >
          <ArrowForwardRounded />
        </IconButton>
      </List.ListItemSecondaryAction>
    </List.ListItem>
  )
}

UserLine.propTypes = {
  user: PropTypes.object,
  role: PropTypes.string,
  isYou: PropTypes.bool,
  isPending: PropTypes.bool,
  intl: PropTypes.object,
}

const mapState = (state, { user }) => ({
  role: getRoleLabel(user),
  isYou: getIsCurrentUser(state, user),
  isPending: getIsUserPending(user),
})

const connectedToProps = connect(mapState)

export default compose(connectedToProps, withRouter, injectIntl)(UserLine)
