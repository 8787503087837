import React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { injectIntl } from 'react-intl'

import makeStyles from '@mui/styles/makeStyles'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'

import { TextPrimaryButton } from 'common/components/button/Button'

import {
  getMaxUsersCount,
  getIsAccountSharable,
  getAccountRemainingShare,
} from 'common/selectors/accounts'

const useStyles = makeStyles({
  remaining: {
    color: '#7f798a',
    fontWeight: 'bold',
  },
})

const ShareAccount = ({ account, intl, isSharable, remainingShareCount }) => {
  const classes = useStyles()

  if (!isSharable) {
    return null
  }

  return (
    <Box
      mt={2}
      display="flex"
      justifyContent="space-between"
      alignItems="center"
    >
      <TextPrimaryButton>
        {intl.formatMessage({ id: 'accounts.share-account' })}
      </TextPrimaryButton>
      <Typography className={classes.remaining}>
        {intl.formatMessage(
          { id: 'accounts.share-remaining' },
          { count: remainingShareCount },
        )}
      </Typography>
    </Box>
  )
}

ShareAccount.propTypes = {
  account: PropTypes.object,
  intl: PropTypes.object,
  isSharable: PropTypes.bool,
  remainingShareCount: PropTypes.number,
}

const mapState = (state, { account }) => ({
  maxUsers: getMaxUsersCount(account),
  isSharable: getIsAccountSharable(state, account),
  remainingShareCount: getAccountRemainingShare(state, account),
})

const connectedToProps = connect(mapState)

export default compose(connectedToProps, injectIntl)(ShareAccount)
