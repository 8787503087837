import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { useIntl } from 'react-intl'
import { get, reduce, isEmpty } from 'lodash'

import FormControl from '@mui/material/FormControl'
import Collapse from '@mui/material/Collapse'
import Box from '@mui/material/Box'
import Radio from '@mui/material/Radio'
import RadioGroup from '@mui/material/RadioGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import { BackendErrorNotification } from 'common/components/backendErrorNotification/BackendErrorNotification'
import { Body } from 'common/components/Typography'

import * as UXPDialog from 'common/components/dialog/UXPDialog'
import { UserButton } from '@admin-ui-common/base-user'
import Page from 'common/components/Page'
import TextField from 'common/components/textfield/TextField'
import BackdropLoader from 'common/components/BackdropLoader'

import process from 'common/utils/process'
import { DISCOVERY_TYPES } from 'common/constants/account'
import { Typography } from '@mui/material'
import { analyticsProvider } from '../../App'

const ControlLabel = ({
  value,
  onChange,
  discoveryType,
  selectedType,
  onlyLabel,
  intl,
}) => {
  const label = intl.formatMessage({
    id: `accounts.discovery-type.${discoveryType}`,
  })
  const labelPlaceholder = intl.formatMessage({
    id: `accounts.discovery-type.${discoveryType}.placeholder`,
  })
  const helperText =
    discoveryType === 'mobile'
      ? intl.formatMessage({
          id: `accounts.discovery-type.${discoveryType}.helperText`,
        })
      : null

  if (onlyLabel)
    return (
      <>
        <TextField
          label={labelPlaceholder}
          value={value}
          onChange={onChange}
          helperText={helperText}
          fullWidth
        />
        <Box mb={1} />
      </>
    )

  return (
    <>
      <FormControlLabel
        key={DISCOVERY_TYPES[discoveryType]}
        value={DISCOVERY_TYPES[discoveryType]}
        control={<Radio color="primary" />}
        label={
          <Body
            mb={0}
            color={
              discoveryType === selectedType ? 'textPrimary' : 'textSecondary'
            }
          >
            {label}
          </Body>
        }
      />
      <Collapse in={discoveryType === selectedType}>
        <TextField
          label={labelPlaceholder}
          value={value}
          onChange={onChange}
          fullWidth
        />
        <Box mb={1} />
      </Collapse>
    </>
  )
}

const ManualDiscoveryDialog = ({ onClose, open, stepData }) => {
  const [value, setValue] = useState('')
  const [errorResponseBody, setErrorResponseBody] = useState(null)

  const discoverAccountsBySetting = useSelector(
    state => state.settings.publicSettings['process.discoverAccountsBy'],
  )

  const discoverySettings = discoverAccountsBySetting
    .split(',')
    .map(identifier => identifier.trim())

  const [discoveryType, setDiscoveryType] = useState(discoverySettings[0])
  const [inProgress, setInProgress] = useState(false)
  const intl = useIntl()

  const onHandleValue = e => setValue(e.target.value)

  const onChangeDiscoveryType = e => {
    setValue('')
    setDiscoveryType(e.target.value)
  }

  const onDiscoverAccounts = () => {
    analyticsProvider.sendAnalytics({
      type: 'event',
      action: 'click',
      event_category: 'button',
      event_label: `manualDiscoveryDialog.discover-accounts`,
      value: 0,
    })

    setInProgress(true)

    const nextStep = { ...stepData }

    nextStep.parameters = {
      identifier: value,
    }

    process
      .step(nextStep)
      .then(res => {
        const stepData = res.body
        const discoveredAccounts = reduce(
          get(stepData, 'output.loadedAccounts', {}),
          (result, account, accountName) => {
            result.push({
              ...account,
              accountName,
            })
            return result
          },
          [],
        )
        const isLastStep = get(stepData, 'lastStep')

        if (isLastStep) {
          const payload = {
            enterOTPEnabled: true,
            discoveredAccounts,
            accountNumber: value,
            stepData,
          }

          closeView(payload)
          return
        }

        setDiscoveryType(null)

        const nextStep = res.body
        closeView({
          stepData: nextStep,
        })
      })
      .catch(err => {
        analyticsProvider.sendAnalytics({
          type: 'event',
          action: 'click',
          event_category: 'button',
          event_label: `manualDiscoveryDialog.discover-accounts`,
          value: 1,
        })
        setErrorResponseBody(err.body)
      })
      .finally(() => setInProgress(false))
  }

  const closeView = payload => {
    analyticsProvider.sendAnalytics({
      type: 'event',
      action: 'click',
      event_category: 'button',
      event_label: `manualDiscoveryDialog.close`,
      value: 0,
    })
    setValue('')
    setErrorResponseBody(null)
    setDiscoveryType(discoverySettings[0])
    onClose(payload)
  }

  return (
    <>
      <UXPDialog.Dialog open={open} fullScreen fullWidth maxWidth="sm">
        <UXPDialog.Title
          title={intl.formatMessage({ id: 'accounts.link-accounts.button' })}
          onClose={closeView}
        />
        <UXPDialog.Content>
          <Box mb={3}>
            <Page.Description>
              {intl.formatMessage({ id: 'accounts.search-accounts' })}
            </Page.Description>
          </Box>
          {discoverySettings.length > 1 && (
            <Typography variant="body2">
              {intl.formatMessage({ id: 'accounts.search-method' })}
            </Typography>
          )}
          <Box mb="7px" />
          <FormControl component="fieldset" fullWidth>
            <RadioGroup value={discoveryType} onChange={onChangeDiscoveryType}>
              {discoverySettings.map(type => (
                <ControlLabel
                  value={value}
                  onChange={onHandleValue}
                  discoveryType={type}
                  selectedType={discoveryType}
                  onlyLabel={discoverySettings.length === 1}
                  intl={intl}
                />
              ))}
            </RadioGroup>
          </FormControl>

          <Collapse in={!!errorResponseBody}>
            <Box mt={2}>
              <BackendErrorNotification
                intl={intl}
                error={errorResponseBody}
                hideClose
              />
            </Box>
          </Collapse>
        </UXPDialog.Content>

        <UXPDialog.Actions disabled={inProgress}>
          <UserButton
            color="secondary"
            variant="outlined"
            disableRipple
            disableElevation
            onClick={closeView}
            size="small"
          >
            {intl.formatMessage({ id: 'common.button.cancel' })}
          </UserButton>
          <UserButton
            disableRipple
            disableElevation
            onClick={onDiscoverAccounts}
            disabled={inProgress || isEmpty(value)}
            size="small"
          >
            {intl.formatMessage({ id: 'common.button.continue' })}
          </UserButton>
        </UXPDialog.Actions>
      </UXPDialog.Dialog>
      <BackdropLoader
        open={inProgress}
        label={intl.formatMessage({ id: 'accounts.searching-for-accounts' })}
      />
    </>
  )
}

ManualDiscoveryDialog.propTypes = {
  onClose: PropTypes.func,
  open: PropTypes.bool,
  stepData: PropTypes.object,
}

export default ManualDiscoveryDialog
