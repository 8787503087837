import { Box } from '@mui/material'
import { Button } from 'common/components/button/Button'
import { Body, Body2 } from 'common/components/Typography'
import FlowLayout from 'common/layouts/FlowLayout'
import parseQRCode from 'common/utils/parseQRCode'
import React from 'react'
import { useIntl } from 'react-intl'
import QRCode from 'react-qr-code'

const QRScanStep = ({
  wizard,
  getPageIndex,
  pages,
  qrScanData,
  handleTOTRedirectStep,
}) => {
  const intl = useIntl()
  const { qrUrl } = wizard ? wizard.getPageState() : qrScanData
  const qrCode = qrUrl && parseQRCode(qrUrl)

  return (
    <FlowLayout
      title={intl.formatMessage({
        id: 'user.management.register.QRCodeSetup.title',
      })}
    >
      <Box mb="24px">
        <Box>
          {intl.formatMessage({
            id: 'user.management.register.QRCodeDialog.message',
          })}
        </Box>
      </Box>
      <Box height={240} width={240} mx="auto">
        <QRCode size={240} value={qrUrl} />
      </Box>
      <Box mt={3}>
        <Body2 textAlign="center">
          {intl.formatMessage({
            id: 'user.management.register.QRCodeSetup.enter-code-in-app',
          })}
          <br />
          {intl.formatMessage({
            id: 'user.management.register.QRCodeSetup.keep-code-for-later',
          })}
        </Body2>
        <Body
          fontWeight="bold"
          letterSpacing="0.15px"
          mb={0}
          textAlign="center"
        >
          {qrCode}
        </Body>
      </Box>
      <Box mt={4}>
        <Button
          size="large"
          onClick={e => {
            e.preventDefault()
            if (wizard) {
              wizard.toPage(getPageIndex(pages.TOTPSetupVerificationStep))
            } else {
              handleTOTRedirectStep(qrScanData)
            }
          }}
        >
          {intl.formatMessage({ id: 'common.button.next' })}
        </Button>
      </Box>
    </FlowLayout>
  )
}

export default QRScanStep
