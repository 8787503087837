import isEmail from 'common/utils/isEmail'
import isPhone from 'common/utils/isPhone'
import { isEmailOnly, isPhoneOnly } from './identifier-utils'
import FeatureFlagsService from 'common/services/FeatureFlagsService'

const SANITIZE_PHONE_NUMBER_REGEX = /[^+\d]+/g
const sanitizeIfMobileNumber = val => {
  // this will ignore any spaces, parenthesis, dashes and dots
  return /^[0-9.+() -]+$/.test(val)
    ? val.replace(SANITIZE_PHONE_NUMBER_REGEX, '')
    : val
}

const isMobileNumberInput = val =>
  /^(\d{2}|\+\d{3})\d*$/.test(sanitizeIfMobileNumber(val))

export function getUsernameValidationErrorSmart(
  intl,
  username,
  allowedIdentifiers,
  isEmailValid,
  isPhoneValid,
) {
  const isEmailIdentifierOnly = isEmailOnly(allowedIdentifiers)
  const isPhoneIdentifierOnly = isPhoneOnly(allowedIdentifiers)

  const usernameMissingKey = (() => {
    if (isEmailIdentifierOnly) {
      return 'error.username-email-only'
    } else if (isPhoneIdentifierOnly) {
      return 'error.username-phone-only'
    } else {
      return 'error.username'
    }
  })()

  if (!username) {
    return intl.formatMessage({ id: usernameMissingKey })
  }

  if (isEmailIdentifierOnly && !isEmailValid(username)) {
    return intl.formatMessage({ id: 'error.username-email-invalid' })
  }

  if (isPhoneIdentifierOnly && !isPhoneValid(username)) {
    return intl.formatMessage({ id: 'error.username-phone-invalid' })
  }

  if (
    allowedIdentifiers?.includes?.('phone') &&
    isMobileNumberInput(username) &&
    !isPhoneValid(username)
  ) {
    return intl.formatMessage({ id: 'error.username-phone-invalid' })
  } else if (
    allowedIdentifiers?.includes?.('email') &&
    !isPhoneValid(username) &&
    !isEmailValid(username)
  ) {
    return intl.formatMessage({ id: 'error.username-email-invalid' })
  }

  return ''
}

const getUsernameValidationError = (intl, username) => {
  if (!username) {
    return intl.formatMessage({ id: 'error.username' })
  }

  const testAsEmail = !isMobileNumberInput(username)
  if (testAsEmail && !isEmail(username)) {
    return intl.formatMessage({ id: 'error.username-email-invalid' })
  } else if (!testAsEmail && !isPhone(username)) {
    return intl.formatMessage({ id: 'error.username-phone-invalid' })
  }

  return ''
}

export const getEmailPhoneOnlyValidationError = (intl, username) => {
  const allowedIdentifiers = FeatureFlagsService.get(
    'uiFeatureFlags.idp.allowedPasswordResetIdentifiers',
  )

  const isEmailIdentifierOnly = isEmailOnly(allowedIdentifiers)
  const isPhoneIdentifierOnly = isPhoneOnly(allowedIdentifiers)

  if (isEmailIdentifierOnly && !isEmail(username)) {
    return intl.formatMessage({ id: 'error.username-only-email-valid' })
  }

  if (isPhoneIdentifierOnly && !isPhone(username)) {
    return intl.formatMessage({ id: 'error.username-only-phone-valid' })
  }

  return ''
}

export default getUsernameValidationError
