// Adds Maximum Scale To Meta Viewport
// https://stackoverflow.com/questions/2989263/disable-auto-zoom-in-input-text-tag-safari-on-iphone
export const disableIOSTextFieldZoom = () => {
  const el = document.querySelector('meta[name=viewport]')

  if (el !== null) {
    let content = el.getAttribute('content')
    const re = /maximum-scale=[0-9.]+/g

    if (re.test(content)) {
      content = content.replace(re, 'maximum-scale=1.0')
    } else {
      content = [content, 'maximum-scale=1.0'].join(', ')
    }

    el.setAttribute('content', content)
  }
}

export default disableIOSTextFieldZoom
