import React from 'react'
import { useIntl } from 'react-intl'

import Box from '@mui/material/Box'
import ArrowRightAltRoundedIcon from '@mui/icons-material/ArrowRightAltRounded'
import ActionLabel from 'common/components/ActionLabel'

const ForgotPasswordLabel = ({ onClick, ...rest }) => {
  const intl = useIntl()

  return (
    <ActionLabel
      onClick={onClick}
      label={intl.formatMessage({
        id: 'onboard.common.forgot-password',
      })}
      icon={
        <Box ml={0.5} fontSize="1.8rem" display="flex" alignItems="center">
          <ArrowRightAltRoundedIcon color="primary" fontSize="inherit" />
        </Box>
      }
      {...rest}
    />
  )
}

export default ForgotPasswordLabel
