import React from 'react'
import classNames from 'classnames'
import { injectIntl } from 'react-intl'

import { Button } from 'common/components/button/Button'

import styles from '../styles.module.css'
import { analyticsProvider } from '../../App'

const ResultVerifyUsername = ({
  verified,
  onConfirm,
  intl,
  userAuthenticated,
}) => {
  const isComplete = userAuthenticated

  const pageTitle = 'resultVerifyUsername'
  const baseEventTag = `signup.${pageTitle}`

  const getText = () => {
    if (isComplete && !verified) {
      return intl.formatMessage({ id: 'verify-result.confirm-your-email' })
    }

    return (
      <React.Fragment>
        {intl.formatMessage({ id: 'separatedSingleStep.onboard-success.body' })}
        {verified === false && (
          <React.Fragment>
            <br />
            <br />
            {intl.formatMessage({ id: 'verify-result.message-unverified' })}
          </React.Fragment>
        )}
      </React.Fragment>
    )
  }

  return (
    <>
      <p className={styles.resultMessage}>{getText()}</p>
      <div className={classNames(styles.formButton)}>
        <Button
          onClick={() => {
            analyticsProvider.sendAnalytics({
              type: 'event',
              action: 'click',
              event_category: 'button',
              event_label: `${baseEventTag}.confirm`,
              value: 0,
            })
            onConfirm()
          }}
        >
          {intl.formatMessage({
            id: 'separatedSingleStep.onboard-success.button.OK',
          })}
        </Button>
      </div>
    </>
  )
}

export default injectIntl(ResultVerifyUsername)
