import FeatureFlagsService from './FeatureFlagsService'

class ServiceInitializer {
  constructor() {
    if (!ServiceInitializer.instance) {
      ServiceInitializer.instance = this
    }

    return ServiceInitializer.instance
  }

  init = externalServices => {
    const services = [FeatureFlagsService.init()]
    return Promise.all([...services, ...externalServices])
  }
}

const instance = new ServiceInitializer()
Object.freeze(instance)

export default instance
