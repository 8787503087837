import * as actionTypes from 'common/actions/dictionary'

const getDictionaryState = () => ({
  messages: {},
})

const dictionary = (state = getDictionaryState(), action) => {
  switch (action.type) {
    case actionTypes.GET_DICTIONARY_REQUEST:
      return {
        ...state,
        error: null,
        inProgress: true,
      }
    case actionTypes.GET_DICTIONARY_SUCCESS: {
      const { dictionary = {} } = action
      return {
        ...state,
        messages: dictionary,
        error: null,
        inProgress: false,
      }
    }
    case actionTypes.GET_DICTIONARY_FAILURE:
      return {
        ...state,
        messages: {},
        error: action.payload,
        inProgress: false,
      }
    default:
      return state
  }
}

export default dictionary
