import { upperCase, lowerCase } from 'lodash'
import { getCountryCodeFromNumber } from './googlePhoneUtils'
import { hasUIFeatureFlag } from 'common/services/FeatureFlagsService'
import { countryList } from '@admin-ui-common/base-user'

const AsYouTypeFormatter = require('google-libphonenumber').AsYouTypeFormatter

const formatNumber = (isoCode, number) => {
  if (hasUIFeatureFlag('excludeCountryCode') || /[a-z]/i.test(number)) {
    return number
  }

  const formatter = new AsYouTypeFormatter(upperCase(isoCode))
  let formatted = ''
  formatter.clear()
  for (let c of number.replace(/[^0-9\.]+/g, '')) {
    formatted = formatter.inputDigit(c)
  }
  return formatted
}

export const displayIdentifier = (identifier, type, appId, isoCode) => {
  const excludeCountryCode = hasUIFeatureFlag('excludeCountryCode')
  const defaultCountryCode = hasUIFeatureFlag('defaultCountryCode')
  if (lowerCase(type) === 'email' || identifier?.includes('@')) {
    return identifier
  } else {
    const isNopal = appId === 'nopal' || appId === 'hbomax'
    if (isNopal) {
      return `(${identifier.slice(0, 2)}) ${identifier.slice(
        2,
        6,
      )}-${identifier.slice(6)}`
    }

    if (excludeCountryCode) {
      return identifier
    }

    const countryCode = getCountryCodeFromNumber(identifier)
    if (!isoCode) {
      const selectedCountry = countryList?.find(val => val[3] == countryCode)
      isoCode = selectedCountry?.[2] || defaultCountryCode
    }
    const mobile = formatNumber(
      isoCode,
      identifier.slice(String(countryCode).length),
    )
    return '+' + countryCode + ' ' + mobile
  }
}

export default formatNumber
