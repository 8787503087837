import React, { useState, useReducer, useMemo } from 'react'
import { useIntl } from 'react-intl'
import { useHistory } from 'react-router-dom'
import { Box, TextField, Typography } from '@mui/material'
import FlowLayout from 'common/layouts/FlowLayout'
import { UserButton } from '@admin-ui-common/base-user'
import ActionLabel from 'common/components/ActionLabel'
import ResendCode from 'common/components/ResendCode'
import { get, has } from 'lodash'
import stripPhone from 'common/utils/stripPhone'
import {
  getErrorCodes,
  isVerifyOTPError,
  resolveErrorEntity,
  isTokenMaxResendReached,
  isInvalidActConsumption,
  isInvalidPkat,
} from 'common/utils/processBackendErrors'
import { hasUIFeatureFlag } from '../../common/services/FeatureFlagsService'

import { getRedirectLocation } from 'common/utils/getRedirectUrl'
import mainRedirectFactory from 'common/components/hooks/useMainRedirect'
import { VerifyOTPErrors } from 'common/components/VerifyOTPErros'

import { TOKEN_MAX_RESEND_REACHED, USER_NOT_ACTIVATED } from 'common/constants'
import process from 'common/utils/process'
import { displayIdentifier } from 'common/utils/formatNumber'
const errorContext = 'verifyUsername'

const actions = {
  USER_ENTERED_CODE_FOR_FIRST_TIME: 'USER_ENTERED_CODE_FOR_FIRST_TIME',
  USER_FIXED_CODE: 'USER_FIXED_CODE',
  GOT_NEW_PKAT: 'GOT_NEW_PKAT',
  SENT_INVALID_CODE: 'SENT_INVALID_CODE',
  GOT_INVALID_CODE: 'GOT_INVALID_CODE',
}

function otpStepReducer(state, action) {
  switch (action.type) {
    case actions.GOT_NEW_PKAT:
      return { ...state, pkat: action.payload }
    case actions.GOT_INVALID_CODE:
      return {
        ...state,
        errors: action.payload.errors,
        userHasEnteredCode: false,
      }
    case actions.SENT_INVALID_CODE:
      return {
        ...state,
        errors: action.payload.errors,
        userHasEnteredCode: false,
      }
    case actions.USER_FIXED_CODE:
      return { ...state, errors: {}, userHasEnteredCode: false }
    case actions.USER_ENTERED_CODE_FOR_FIRST_TIME:
      return { ...state, userHasEnteredCode: true }
    default:
      return state
  }
}

const codeValidationStatus = {
  PENDING: 'PENDING',
  SENDING: 'SENDING',
  SUCCEEDED: 'SUCCEEDED',
  ERROR_SENT_INVALID: 'ERROR_SENT_INVALID',
  ERROR_GENERIC: 'ERROR_GENERIC',
}

export default function OtpStep({
  steps,
  pages,
  getPageIndex,
  wizard,
  verifyCode,
}) {
  const intl = useIntl()
  const wizardState = wizard.getPageState()
  const mainRedirect = mainRedirectFactory()
  const history = useHistory()
  const { location } = history

  const otpStepIntialState = {
    pkat: wizardState.pkat,
    codeValidationStatus: codeValidationStatus.PENDING,
    userHasEnteredCode: false,
    errors: {},
  }

  const [state, dispatch] = useReducer(otpStepReducer, otpStepIntialState)
  const [errorResponseBody, setErrorResponseBody] = useState()
  const [disabledSignInBtn, setDisabledSignInBtn] = useState(false)
  const [isResendCode, setIsResendCode] = useState(false)
  const [disabledSendNewCode, setDisabledSendNewCode] = useState()

  function gotNewPkat(newPkat) {
    dispatch({ type: actions.GOT_NEW_PKAT, payload: newPkat })
    setDisabledSignInBtn(false)
    setErrorResponseBody(null)
    setIsResendCode(false)
  }

  async function handleSubmit(e) {
    e.preventDefault()
    const formData = new FormData(e.target)
    const code = formData.get('code')

    try {
      let res = flowSubStep.includes('setup')
        ? await process.step({
            processId: wizardState.processId,
            parameters: {
              code: code,
              pkat: state.pkat,
            },
          })
        : await verifyCode(code, state.pkat)

      let stepName = get(res, 'body.stepName')
      if (
        stepName === steps.PasswordPrompt &&
        hasUIFeatureFlag('loginWithOTP')
      ) {
        res = await process.step({ processId: res.body.processId })
        stepName = res?.body.stepName
      }

      if (stepName === steps.PasswordPrompt) {
        wizard.setPageState({
          ...wizardState,
          previousPage: pages.OtpStep,
          lastStep: get(res, 'body.lastStep', false),
          processId: res.body.processId,
        })

        wizard.toPage(getPageIndex(pages.SetPasswordStep))
      }

      if (stepName === steps.AccountIdentifierPrompt) {
        const stepData = get(res, 'body')
        wizard.setPageState(stepData)
        wizard.toPage(getPageIndex(pages.EnforceAccountAssociationStep))
      }

      if (stepName === steps.TwoFASetupMethodPrompt) {
        const stepData = get(res, 'body')
        wizard.setPageState(stepData)
        wizard.toPage(getPageIndex(pages.TwoStepSetupStep))
        return
      }

      if (stepName === steps.TwoFACodePrompt) {
        const stepData = get(res, 'body')
        wizard.setPageState(stepData)
        wizard.toPage(getPageIndex(pages.TwoStepCode))
        return
      }

      if (stepName === steps.TotpVerificationPrompt) {
        const stepData = get(res, 'body')
        wizard.setPageState(stepData)
        wizard.toPage(getPageIndex(pages.TotpVerificationStep))
        return
      }

      if (stepName === steps.TwoFAMethodPrompt) {
        const stepData = get(res, 'body')
        wizard.setPageState(stepData)
        wizard.toPage(getPageIndex(pages.TwoStepMethod))
        return
      }

      if (get(res, 'body.stepName') === steps.UpdatePassword) {
        history.push({
          pathname: '/password_expire',
          state: get(res, 'body'),
          search: location.search,
        })
        return
      }

      if (get(res, 'body.userAuthenticated')) {
        if (flowSubStep.includes('setup')) {
          wizard.setPageState(res)
          wizard.toPage(getPageIndex(pages.TwoFASetupSuccessStep))
          return
        }
        if (Boolean(getRedirectLocation(res.metadata))) {
          mainRedirect(res.metadata)
          return
        }

        wizard.toPage(getPageIndex(pages.LoggedIn))
      }
    } catch (err) {
      const errorData = err.body
      const errorCodes = getErrorCodes(errorData)
      if (
        isInvalidActConsumption(errorData) ||
        errorCodes.includes('A token value or a custom token is required.')
      ) {
        dispatch({
          type: actions.SENT_INVALID_CODE,
          payload: {
            errors: {
              code: intl.formatMessage({
                id: 'error.verification-code-incorrect',
              }),
            },
          },
        })
      } else if (errorCodes.includes(USER_NOT_ACTIVATED)) {
        setErrorResponseBody(errorData)
      } else if (isVerifyOTPError(errorData)) {
        setErrorResponseBody(errorData)
        setDisabledSignInBtn(true)
        e.target.reset()
        if (isInvalidPkat(errorData)) {
          setDisabledSendNewCode(true)
        }
      } else if (isTokenMaxResendReached(errorData)) {
        setErrorResponseBody(errorData)
        setDisabledSendNewCode(true)
        setDisabledSignInBtn(false)
      } else {
        dispatch({
          type: actions.SENT_INVALID_CODE,
          payload: {
            errors: {
              code: resolveErrorEntity({ intl, error: errorData }),
            },
          },
        })
      }
    }
  }

  function validateCode(e) {
    const code = e.target.value.trim()

    let newErrors = {}
    if (code.length === 0) {
      newErrors.code = intl.formatMessage({ id: 'error.invalid-code' })
    }

    const previousStateHadErrors = Object.keys(state.errors).length > 0
    const hasErrorsNow = Object.keys(newErrors).length > 0

    if (!state.userHasEnteredCode && code.length > 0) {
      dispatch({ type: actions.USER_ENTERED_CODE_FOR_FIRST_TIME })
    }
    if (!state.userHasEnteredCode && previousStateHadErrors && !hasErrorsNow) {
      dispatch({ type: actions.USER_FIXED_CODE })
    }

    if (hasErrorsNow) {
      dispatch({
        type: actions.GOT_INVALID_CODE,
        payload: { errors: newErrors },
      })
    }
  }

  const cantSubmit =
    !state.userHasEnteredCode ||
    state.codeValidationStatus === codeValidationStatus.SENDING ||
    has(state, 'errors.code')

  const sendingCode =
    state.codeValidationStatus === codeValidationStatus.SENDING

  const getFlowSubStep = () => {
    switch (wizardState.usernameType) {
      case 'email':
        return 'authenticateEmailOTP'
      case 'phone':
        return 'authenticateMobileOTP'
      case 'email-setup':
        return 'setupEmailOTP'
      case 'phone-setup':
        return 'setupPhoneOTP'
      default:
        return ''
    }
  }

  const handleResend = () => {
    setIsResendCode(true)
  }

  const formatMessag = () => {
    if (wizardState?.username) {
      return intl.formatMessage(
        {
          id: `separatedMultiStep.authenticate-${flowSubStep}.body`,
        },
        {
          identifier: wizardState?.username?.includes('***')
            ? wizardState?.username
            : displayIdentifier(
                stripPhone(wizardState?.username),
                wizardState?.usernameType,
              ),
          'text-strong': str => <strong>{str}</strong>,
        },
      )
    }
  }

  const onResendError = (message, err) => {
    const errorData = err.body || err.data
    const errorCodes = getErrorCodes(errorData)
    if (errorCodes.includes(TOKEN_MAX_RESEND_REACHED)) {
      setErrorResponseBody(errorData)
      setDisabledSendNewCode(true)
    }
  }
  const flowSubStep = useMemo(getFlowSubStep, [])

  return (
    <FlowLayout
      title={intl.formatMessage({
        id: `separatedMultiStep.authenticate-${flowSubStep}.title`,
      })}
    >
      <Typography>{formatMessag()}</Typography>
      <form onSubmit={handleSubmit}>
        {!!errorResponseBody && (
          <Box mt={2}>
            <VerifyOTPErrors
              errors={errorResponseBody}
              errorContext={errorContext}
              handleResend={handleResend}
              onClickRedirect={() =>
                wizard.toPage(getPageIndex(pages.UsernameStep))
              }
            />
          </Box>
        )}
        <Box mt={3}>
          <TextField
            inputMode="numeric"
            aria-required
            fullWidth
            error={has(state, 'errors.code')}
            helperText={get(state, 'errors.code', null)}
            id="code"
            name="code"
            onBlur={validateCode}
            onChange={validateCode}
            autoFocus
            label={intl.formatMessage({
              id: `separatedMultiStep.authenticate-${flowSubStep}.label.enterCode`,
            })}
            disabled={disabledSignInBtn}
            inputProps={{ 'data-testid': 'otp-textfield' }}
          />
        </Box>
        <Box sx={{ fontSize: '14px' }} mt={0.5} mb={3}>
          {disabledSendNewCode ? (
            <ActionLabel
              disabled={disabledSendNewCode}
              variant="text"
              label={intl.formatMessage({ id: `common.countdown-complete` })}
            />
          ) : (
            <ResendCode
              autoFocus
              tokenId=""
              labelKeys={{
                countdown: `separatedMultiStep.authenticate-${flowSubStep}.textLink.resendCode-countdown`,
                countdownComplete: `separatedMultiStep.authenticate-${flowSubStep}.textLink.resendCode-countdownComplete`,
              }}
              pkat={state.pkat}
              onResendFinish={gotNewPkat}
              isResendCode={isResendCode}
              onResendError={onResendError}
            />
          )}
        </Box>
        <UserButton
          isLoading={sendingCode}
          disabled={cantSubmit || disabledSignInBtn}
          type="submit"
          size="large"
        >
          {intl.formatMessage({
            id: `separatedMultiStep.authenticate-${flowSubStep}.label.button.authenticate`,
          })}
        </UserButton>
      </form>
    </FlowLayout>
  )
}
