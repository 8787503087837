import React, { useState, useEffect } from 'react'
import { useIntl } from 'react-intl'
import { useHistory, useLocation } from 'react-router-dom'

import Box from '@mui/material/Box'

import * as UXPCard from 'common/components/Card'
import { BackendErrorNotification } from 'common/components/backendErrorNotification/BackendErrorNotification'
import { Title, Body } from 'common/components/Typography'
import PasswordField from 'common/components/textfield/PasswordField'
import { Button } from 'common/components/button/Button'
import {
  getErrorCodes,
  formatErrorMessage,
} from '../../utils/processBackendErrors'

import process from 'common/utils/process'

import { analyticsProvider } from 'App.js'
import useMainRedirect from 'common/components/hooks/useMainRedirect'

const PasswordReset = ({ wizard, flowType }) => {
  const [password, setPassword] = useState('')
  const [error, setError] = useState()
  const [errorResponseBody, setErrorResponseBody] = useState(null)
  const [isLoading, setIsLoading] = useState(false)
  const intl = useIntl()
  const history = useHistory()
  const location = useLocation()
  const mainRedirect = useMainRedirect()

  const pageTitle = 'password_reset'
  const baseEventTag = `${flowType}.password_reset`
  const passwordFieldLabel = intl.formatMessage({
    id: 'password-reset.password-reset.new-password',
  })

  useEffect(() => {
    analyticsProvider.sendAnalytics({
      type: 'page_view',
      page_title: pageTitle,
      page_path: `/${flowType}#${pageTitle}`,
    })
  }, [flowType])

  const resetPassword = async () => {
    if (!password) {
      const errorMessage = intl.formatMessage({
        id: 'onboard.error.please-enter-password',
      })

      setError(errorMessage)

      analyticsProvider.sendAnalytics({
        type: 'event',
        action: 'exception',
        description: `${baseEventTag}.password: ${errorMessage}`,
      })
      return
    }

    const {
      userAuthenticated,
      processId,
      origin,
      mobile,
    } = wizard.getPageState()
    setIsLoading(true)

    process
      .step({
        userAuthenticated,
        processId,
        parameters: { newPassword: password },
      })
      .then(() => {
        setIsLoading(false)

        analyticsProvider.sendAnalytics({
          type: 'event',
          action: 'click',
          event_category: 'button',
          event_label: `${baseEventTag}.change_password`,
          value: 0,
        })

        const { originalRedirect, viewId, originProcessState } = origin

        if (originalRedirect) {
          history.push(
            {
              pathname: originalRedirect,
              search: location.search,
            },
            {
              originProcessState,
              viewId,
              mobile,
            },
          )
        } else {
          mainRedirect(`/login${location.search}`)
        }
      })
      .catch(err => {
        setIsLoading(false)
        const errorCodes = getErrorCodes(err.body)

        analyticsProvider.sendAnalytics({
          type: 'event',
          action: 'click',
          event_category: 'button',
          event_label: `${baseEventTag}.change_password`,
          value: 1,
        })

        analyticsProvider.sendAnalytics({
          type: 'event',
          action: 'exception',
          description: `${baseEventTag}.screen: ${errorCodes.join(',')}`,
        })

        setErrorResponseBody(err.body)
      })
  }

  return (
    <UXPCard.Card>
      <UXPCard.Content>
        <Title>
          {intl.formatMessage({ id: 'password-reset.password-reset.title' })}
        </Title>
        <Body mb={4}>
          {intl.formatMessage({
            id: 'password-reset.password-reset.resetting-your-password',
          })}
        </Body>
        {!!errorResponseBody && (
          <Box mb={3}>
            <BackendErrorNotification
              intl={intl}
              subTitle={
                <div>
                  {formatErrorMessage({
                    intl,
                    id: 'error.credential-at-least',
                  })}
                </div>
              }
              error={errorResponseBody}
              handleClose={() => {
                setErrorResponseBody(null)
              }}
              prioritize={['NotReusedPasswordByTime', 'NotReusedPassword']}
              showAsSingle={['NotReusedPasswordByTime', 'NotReusedPassword']}
            />
          </Box>
        )}
        <PasswordField
          required
          label={passwordFieldLabel}
          onChange={e => setPassword(e.target.value)}
          value={password}
          errorText={error}
        />
      </UXPCard.Content>
      <Box mb={3} />
      <UXPCard.Actions>
        <Button color="primary" onClick={resetPassword} isLoading={isLoading}>
          {intl.formatMessage({
            id: 'password-reset.password-reset.change-password',
          })}
        </Button>
      </UXPCard.Actions>
    </UXPCard.Card>
  )
}

export default PasswordReset
