import React, { createRef, useReducer } from 'react'
import { connect } from 'react-redux'
import classNames from 'classnames'
import { get, has, isEmpty } from 'lodash'
import { compose } from 'redux'

import { injectIntl } from 'react-intl'
import { Link } from 'react-router-dom'
import { TextField, UserButton, UserTextLink } from '@admin-ui-common/base-user'
import ReCAPTCHA from 'common/components/ReCAPTCHA'
import SocialMediaButtons from 'common/components/socialMediaButton'
import VerifyUsername from '../signup/VerifyUsername'
import ResultVerifyUsername from '../signup/ResultVerifyUsername'
import withReCaptcha from 'common/components/hoc/withReCaptcha'
import { getUsernameValidationErrorSmart } from 'common/utils/getUsernameValidationError'

import getFingerprint from 'common/utils/getFingerprint'
import isPhone from 'common/utils/isPhone'
import stripPhone from 'common/utils/stripPhone'
import {
  resetSessionStartError,
  START_SESSION_SUCCESS,
  startSession,
  startSessionStep,
} from 'common/actions/session'
import { loginWithSocialMedia } from 'common/actions/socialMedia'
import process from 'common/utils/process'

import { CAPTCHA_RESPONSE, TOO_MANY_RETRIES } from 'common/constants'
import { getRedirectLocation } from 'common/utils/getRedirectUrl'
import { addNotification } from 'common/actions/notification'
import FeatureFlagsService from 'common/services/FeatureFlagsService'

import styles from '../styles.module.css'
import { setLocale } from '@admin-ui-common/utils'
import { BackendErrorNotification } from 'common/components/backendErrorNotification/BackendErrorNotification'
import { TextPrimaryButton } from 'common/components/button/Button'
import { analyticsProvider } from '../../App'
import FlowLayout from 'common/layouts/FlowLayout'
import withValidators from 'common/components/hoc/withValidators'
import { isEmailOnly, isPhoneOnly } from 'common/utils/identifier-utils'
import TwoStepMethod from '../signin-v2/TwoStepMethod'
import TwoStepCode from '../signin-v2/TwoStepCode'
import { steps } from '../signin-v2/SignInV2'
import LoggedIn from './LoggedIn'
import CheckMarkIcon from 'common/components/CheckMarkIcon'
import {
  formatErrorTitle,
  getErrorCodes,
  isUserProfileLocked,
} from 'common/utils/processBackendErrors'
import { disableOnboardingFeatureFlag } from 'common/utils/disableOnboardingFeatureFlag'
import { twoStepTypes } from 'lander/constants'

const getInitialState = props => {
  return {
    step: null,
    pkat: null,
    tokenId: null,
    response: null,
    credential: '',
    username: get(props.location, 'state.usernameEntered') || '',
    usernameType: '',
    errors: {},
    errorMessages: {
      username: props.intl.formatMessage({ id: 'error.username' }),
      credential: props.intl.formatMessage({ id: 'error.credential' }),
    },
    errorResponseBody: null,
    profileLocked: false,
  }
}

const generateStepPayload = (responseBody, parameters) => {
  const processId = get(responseBody, 'processId')
  return {
    processId,
    parameters,
  }
}

const fallbackAuthErrorBody = {
  operationError: {
    code: 'authentication-required',
    message: '',
  },
}

class SignIn extends React.Component {
  constructor(props) {
    super(props)

    this.recaptchaRef = createRef()
    this.promptCaptcha = FeatureFlagsService.get(
      'system.security.promptCaptcha',
    )
    this.allowedIdentifiers = FeatureFlagsService.get(
      'uiFeatureFlags.idp.allowedLoginIdentifiers',
    )

    this.pageTitle = 'sign-in'
    this.baseEventTag = `${this.pageTitle}`
  }

  state = getInitialState(this.props)

  componentDidMount() {
    analyticsProvider.sendAnalytics({
      type: 'page_view',
      page_title: this.pageTitle,
      page_path: `/`,
    })

    const { addNotification, intl } = this.props

    this.goBaseRoute()

    if (JSON.parse(window.localStorage.getItem('__logoutPhase__'))) {
      window.localStorage.setItem('__logoutPhase__', false)

      addNotification({
        message: intl.formatMessage({
          id: 'notification.you-have-been-signed-out',
        }),
        variant: 'success',
      })
    }
  }

  componentDidUpdate(prevProps) {
    const { step } = this.state
    const { location } = this.props
    if (
      get(location, 'state.code') === 'codeExpired' &&
      step === 'VerifyUsername'
    ) {
      this.setState({
        step: null,
        username: get(location, 'state.usernameEntered'),
        credential: '',
      })
    }
  }

  componentWillUnmount() {
    this.props.resetSessionStartError()
    this.props.resetRecaptcha(this.recaptchaRef.current)
  }

  handleUsernameChange = event => {
    this.setState({ username: event.target.value })
  }
  handlePasswordChange = event => {
    this.setState({ credential: event.target.value })
  }

  handleVerified = (verified, response) => {
    this.setState({
      step: 'ResultVerifyUsername',
      verified,
      response,
    })
  }

  handleRedirect = () => {
    const { metadata, body } = this.state.response
    const location = getRedirectLocation(metadata, '')
    const isProcessComplete =
      !!body.userId || !!body.lastStep || !!body.userAuthenticated

    if (isProcessComplete) {
      window.location = location
    }
  }

  goHome = () => this.props.history.push('/signin')

  goBaseRoute = () => this.props.history.push('/')

  resetStep = () => {
    this.setState(getInitialState(this.props))
  }

  validate = fieldName => event => {
    const { errors, errorMessages } = this.state
    const { intl, isValidEmail, isValidPhone } = this.props
    const value = event.target.value.trim()

    switch (fieldName) {
      case 'username':
        const usernameError = getUsernameValidationErrorSmart(
          intl,
          value,
          this.allowedIdentifiers,
          isValidEmail,
          isValidPhone,
        )
        errors[fieldName] = value ? usernameError : errorMessages.username
        break
      default:
        errors[fieldName] = value === '' ? errorMessages[fieldName] : ''
        break
    }

    this.setState({
      errors,
    })
  }

  startLoginSession = sessionStartPayload => {
    const { startSession, startSessionStep } = this.props

    const { processStepPayload } = this.state
    if (processStepPayload) {
      const parameters = {
        credential: sessionStartPayload.credential,
        authnIdentifier: sessionStartPayload.username,
      }

      const recaptcha = sessionStartPayload[CAPTCHA_RESPONSE]
      if (recaptcha) {
        parameters[CAPTCHA_RESPONSE] = recaptcha
      }

      const stepPayload = generateStepPayload(processStepPayload, {
        ...parameters,
      })
      return startSessionStep(stepPayload)
    }

    return startSession(sessionStartPayload)
  }

  onValidateSubmit = async e => {
    e.preventDefault()

    analyticsProvider.sendAnalytics({
      type: 'event',
      action: 'click',
      event_category: 'button',
      event_label: `${this.baseEventTag}.sign-in`,
      value: 0,
    })

    if (this.promptCaptcha) {
      const recaptcha = await this.recaptchaRef.current.executeAsync()
      this.recaptchaRef.current.reset()
      this.signIn(recaptcha)
    } else {
      this.signIn()
    }
  }

  signIn = recaptchaToken => {
    const errors = {}

    const { intl, isValidEmail, isValidPhone } = this.props
    const { credential, errorMessages } = this.state
    if (!credential) {
      errors.credential = errorMessages.credential
    }

    let { username } = this.state
    const usernameError = getUsernameValidationErrorSmart(
      intl,
      username,
      this.allowedIdentifiers,
      isValidEmail,
      isValidPhone,
    )
    if (!username) {
      errors.username = errorMessages.username
    } else if (!isEmpty(usernameError)) {
      errors.username = usernameError
    }

    if (Object.keys(errors).length) {
      this.setState({ errors })
      return Promise.resolve()
    }

    this.setState({
      errors: {},
      errorResponseBody: null,
      profileLocked: false,
    })

    let usernameType = 'email'
    if (isPhone(username)) {
      username = stripPhone(username)
      usernameType = 'phone'
    }

    return getFingerprint().then(fingerprint => {
      const sessionStartPayload = {
        credential,
        username,
        guid: fingerprint,
      }

      if (this.promptCaptcha && recaptchaToken) {
        sessionStartPayload[CAPTCHA_RESPONSE] = recaptchaToken
      }

      this.startLoginSession(sessionStartPayload)
        .then(res => {
          let stepName = get(res, 'body.stepName')
          let stepPayload = null
          const { error, body, type, beingRedirected } = res

          if (error) {
            const errorCodes = getErrorCodes(error.body)
            this.setState({
              profileLocked: errorCodes.some(isUserProfileLocked),
            })
            if (errorCodes.includes(TOO_MANY_RETRIES)) {
              error.body = fallbackAuthErrorBody
            }

            stepName = get(error, 'body.lastFailedStepAction.stepName')
            if (stepName === 'ReEnterPrompt') {
              stepPayload = generateStepPayload(
                get(error, 'body.lastFailedStepAction'),
                {
                  credential,
                  authnIdentifier: username,
                },
              )
            }
            this.setState({
              processStepPayload: stepPayload,
            })
          }

          if (process.isProcess(body) && stepName === 'UpdatePassword') {
            this.props.history.push({
              pathname: '/password_expire',
              state: body,
            })
          }

          if (process.isProcess(body) && stepName === 'VerificationPrompt') {
            stepPayload = generateStepPayload(body, {
              confirm: true,
            })
            return process.step(stepPayload)
          }

          if (type === START_SESSION_SUCCESS || beingRedirected) {
            return Promise.resolve()
          }

          if (stepName === 'TwoFAMethodPrompt') {
            this.props.showTwoStepMethod(body)
            return
          }

          if (stepName === 'TotpVerificationPrompt') {
            const payload = {
              ...body,
              selectedMethod: {
                type: 'TOTP',
              },
            }
            this.props.showTotpCode(payload)
          }

          if (stepName === 'TwoFACodePrompt') {
            const payload = {
              ...body,
            }
            this.props.showTotpCode(payload)
          }

          return Promise.reject(
            error || {
              body: {
                operationError: {
                  code: 'unexpected',
                },
              },
            },
          )
        })
        .then(res => {
          if (res) {
            const pkat = get(res, 'body.output.pkat')
            const tokenId = get(res, 'body.output.tokenId')
            this.setState({
              step: 'VerifyUsername',
              pkat,
              tokenId,
              usernameType,
              processStepPayload: null,
            })
          }
        })
        .catch(err => {
          analyticsProvider.sendAnalytics({
            type: 'event',
            action: 'click',
            event_category: 'button',
            event_label: `${this.baseEventTag}.sign-in`,
            value: 1,
          })
          const { toggleRecaptcha } = this.props

          const captchaResponse = has(
            err,
            'body.lastFailedStepAction.parameters.captchaResponse',
          )
          toggleRecaptcha(captchaResponse)

          this.setState({
            errorResponseBody: err.body,
            isLoading: false,
          })
        })
    })
  }

  render() {
    const { isSessionStartLoading, intl } = this.props
    const {
      errors,
      username,
      credential,
      step,
      pkat,
      tokenId,
      usernameType,
      verified,
      errorResponseBody,
      profileLocked,
    } = this.state

    const isEmailIdentifierOnly = isEmailOnly(this.allowedIdentifiers)
    const isPhoneIdentifierOnly = isPhoneOnly(this.allowedIdentifiers)

    const usernameLabelKey = (() => {
      if (isEmailIdentifierOnly) {
        return 'separatedSingleStep.authenticate-enterIdentifierAndPassword.label.email'
      } else if (isPhoneIdentifierOnly) {
        return 'separatedSingleStep.authenticate-enterIdentifierAndPassword.label.mobile'
      } else {
        return 'separatedSingleStep.authenticate-enterIdentifierAndPassword.label.emailOrMobile'
      }
    })()

    if (step === 'VerifyUsername') {
      return (
        <VerifyUsername
          reset={this.resetStep}
          process={'sign-up'}
          username={username}
          type={usernameType}
          actionName="onboardUser"
          pkat={pkat}
          tokenId={tokenId}
          onVerified={this.handleVerified}
        />
      )
    }

    if (step === 'ResultVerifyUsername') {
      const type =
        usernameType === 'email'
          ? intl.formatMessage({ id: 'verify-result.email' })
          : intl.formatMessage({ id: 'verify-result.mobile' })
      return (
        <FlowLayout
          title={
            !verified
              ? intl.formatMessage({ id: 'verify-result.you-almost-set' })
              : intl.formatMessage({ id: 'verify-result.title' }, { type })
          }
          image={verified ? CheckMarkIcon : ''}
        >
          <ResultVerifyUsername
            verificationType={usernameType}
            verified={verified}
            onConfirm={verified ? this.handleRedirect : this.goHome}
          />
        </FlowLayout>
      )
    }

    const socialTextKeys = {
      continue:
        'separatedSingleStep.authenticate-enterIdentifierAndPassword.button.socialProvider',
      alternateOptions:
        'separatedSingleStep.authenticate-enterIdentifierAndPassword.label.socialProviders-authenticate',
    }

    return (
      <FlowLayout
        title={intl.formatMessage({
          id:
            'separatedSingleStep.authenticate-enterIdentifierAndPassword.title',
        })}
        subtitle={
          // when disableOnboarding feature flag is true, hide the signup option
          !disableOnboardingFeatureFlag() && (
            <span>
              {intl.formatMessage({
                id:
                  'separatedSingleStep.authenticate-enterIdentifierAndPassword.subtitle',
              })}{' '}
              <Link to="/signup">
                <UserTextLink underline="always">
                  {intl.formatMessage({
                    id:
                      'separatedSingleStep.authenticate-enterIdentifierAndPasswords.button.signUp',
                  })}
                </UserTextLink>
              </Link>
            </span>
          )
        }
        recaptchaTerms="separatedSingleStep.authenticate-enterIdentifierAndPassword.caption.reCAPTCHA"
        showCaptcha={this.promptCaptcha}
      >
        <form onSubmit={this.onValidateSubmit}>
          {!!errorResponseBody && (
            <div className={styles.signInFormErrorMessage}>
              <BackendErrorNotification
                title={
                  profileLocked
                    ? formatErrorTitle({
                        intl,
                        id: 'error.please-try-again-later',
                      })
                    : undefined
                }
                intl={intl}
                error={errorResponseBody}
                hideClose
                context="sign-in"
              />
            </div>
          )}
          <TextField
            errorProps={errors.username && { errorMessage: errors.username }}
            inputProps={{
              autoCapitalize: 'none',
              autoComplete: 'off',
            }}
            label={intl.formatMessage({ id: usernameLabelKey })}
            onChange={this.handleUsernameChange}
            value={username}
            onBlur={this.validate('username')}
          />
          <div className={styles.fieldMB} />
          <div className={classNames('relative', 'mb-32')}>
            <TextField
              errorProps={
                errors.credential && { errorMessage: errors.credential }
              }
              onChange={this.handlePasswordChange}
              value={credential}
              onBlur={this.validate('credential')}
              label={intl.formatMessage({
                id:
                  'separatedSingleStep.authenticate-enterIdentifierAndPassword.label.password',
              })}
              inputType="password"
            />
          </div>
          <div className={classNames('flex', 'alignBaseline')}>
            <div>
              <UserButton
                fullWidth
                size="large"
                isLoading={isSessionStartLoading}
                type="submit"
              >
                {intl.formatMessage({
                  id:
                    'separatedSingleStep.authenticate-enterIdentifierAndPassword.button.authenticate',
                })}
              </UserButton>
            </div>
            <div
              className={classNames(styles.passwordControls, 'flex', 'pl-24')}
            >
              <Link
                to={{
                  pathname: '/forgot',
                  state: { authIdentifier: username },
                }}
                tabIndex={-1}
              >
                <TextPrimaryButton size="large">
                  {intl.formatMessage({
                    id:
                      'separatedSingleStep.authenticate-enterIdentifierAndPasswords.button.resetPassword',
                  })}
                </TextPrimaryButton>
              </Link>
            </div>
          </div>
        </form>
        <SocialMediaButtons textKeys={socialTextKeys} intl={intl} />
        {this.promptCaptcha && <ReCAPTCHA ref={this.recaptchaRef} />}
      </FlowLayout>
    )
  }
}

async function choose2FAMethod({ processId }, { twoFAMethodOptionId, userId }) {
  const data = { processId, parameters: { twoFAMethodOptionId, userId } }
  return process.step(data, true)
}

function twoStepMethodToType(method) {
  if (method === 'TOTP') return twoStepTypes.TOTP
  if (method?.includes('@')) return twoStepTypes.EMAIL
  return twoStepTypes.PHONE
}

async function send2FACode(
  { processId },
  { code, pkat, trustedDevice = false, userId },
) {
  const data = { processId, parameters: { code, pkat, trustedDevice, userId } }
  return process.step(data, true)
}

const signInReducer = (state, action) => {
  switch (action.type) {
    case 'REQUEST_TWO_STEP_METHOD':
      return {
        view: 'TwoStepMethod',
        data: action.payload,
        method: null,
      }
    case 'REQUEST_OTP_CODE':
      return {
        view: 'OneTimeCode',
        method: action.method,
        data: action.payload,
      }
    case 'REQUEST_TOTP_CODE':
      return {
        view: 'AuthenticatorCode',
        data: action.payload,
        method: null,
      }
    case 'LOG_IN':
      return {
        view: 'LoggedIn',
        data: null,
        method: null,
      }
    default:
      return state
  }
}

const initialSignInState = {
  view: null,
  method: null,
  payload: null,
}

function PageWrapper(props) {
  const [state, dispatch] = useReducer(signInReducer, initialSignInState)

  function showTwoStepMethod(data) {
    dispatch({ type: 'REQUEST_TWO_STEP_METHOD', payload: data })
  }

  function showOtpCode(data, method) {
    dispatch({ type: 'REQUEST_OTP_CODE', payload: data, method: method })
  }

  function showTotpCode(data) {
    dispatch({ type: 'REQUEST_TOTP_CODE', payload: data })
  }

  function logIn() {
    dispatch({ type: 'LOG_IN' })
  }

  if (state.view === 'TwoStepMethod') {
    return (
      <TwoStepMethod
        data={state.data}
        choose2FAMethod={choose2FAMethod}
        twoStepTypes={twoStepTypes}
        twoStepMethodToType={twoStepMethodToType}
        showOtpCode={showOtpCode}
        showTotpCode={showTotpCode}
      />
    )
  }

  if (state.view === 'OneTimeCode' || state.view === 'AuthenticatorCode') {
    const fakeWizard = {
      getPageState: () => ({ ...state.data }),
      toPage: () => {},
    }
    const fakeGetPageIndex = callback => callback()
    const fakePages = {
      LoggedIn: logIn,
      EnforceAccountAssociationStep: () => new Error('Not supported'),
    }

    return (
      <TwoStepCode
        wizard={fakeWizard}
        getPageIndex={fakeGetPageIndex}
        pages={fakePages}
        twoStepTypes={twoStepTypes}
        twoStepMethodToType={twoStepMethodToType}
        steps={steps}
        send2FACode={send2FACode}
        logIn={logIn}
      />
    )
  }

  if (state.view === 'LoggedIn') {
    return <LoggedIn />
  }

  return (
    <SignIn
      {...props}
      showTwoStepMethod={showTwoStepMethod}
      showTotpCode={showTotpCode}
    />
  )
}

const mapStateToProps = state => ({
  isSessionStartLoading: state.session.isSessionStartLoading,
})

const mapDispatchToProps = {
  startSession,
  startSessionStep,
  resetSessionStartError,
  loginWithSocialMedia,
  addNotification,
  setLocale,
}

const connectedToProps = connect(mapStateToProps, mapDispatchToProps)

export default compose(
  connectedToProps,
  injectIntl,
  withValidators,
  withReCaptcha(),
)(PageWrapper)
