import React, {
  useEffect,
  useReducer,
  useRef,
  useCallback,
  useState,
} from 'react'
import FlowLayout from 'common/layouts/FlowLayout'
import { useIntl } from 'react-intl'
import { get, has, isEmpty } from 'lodash'
import {
  EmailOrMobile,
  MobileNumberInput,
  TextField,
  UserButton,
  UserTextLink,
} from '@admin-ui-common/base-user'
import FeatureFlagsService, {
  hasUIFeatureFlag,
} from 'common/services/FeatureFlagsService'
import SocialMediaButtons from 'common/components/socialMediaButton'
import { analyticsProvider } from 'App'
import getFingerprint from 'common/utils/getFingerprint'
import { getUsernameValidationErrorSmart } from 'common/utils/getUsernameValidationError'

import getCountryCode from 'common/utils/getCountryCode'
import { Box } from '@mui/material'
import { Link } from 'react-router-dom'
import {
  ACCESS_TOKEN_KEY,
  CAPTCHA_RESPONSE,
  ERROR_AUTHENTICATION_REQUIRED,
} from 'common/constants'
import ReCAPTCHA from 'common/components/ReCAPTCHA'
import { BackendErrorNotification } from 'common/components/backendErrorNotification/BackendErrorNotification'
import useRecaptcha from 'common/components/hooks/useRecaptcha'
import {
  useEmailValidator,
  usePhoneValidator,
} from 'common/components/hooks/validators'
import { isEmailOnly, isPhoneOnly } from 'common/utils/identifier-utils'
import { getOperationErrorCodes } from 'common/utils/processBackendErrors'
import { disableOnboardingFeatureFlag } from 'common/utils/disableOnboardingFeatureFlag'
import isPhone from 'common/utils/isPhone'
import stripIdentity from 'common/utils/stripIdentity'
import formatNumber from 'common/utils/formatNumber'
import getDefaultIdentifier from 'common/utils/getDefaultIdentifier'
import loginHint from 'common/utils/loginHint'
import isEmail from 'common/utils/isEmail'
import Loader from 'common/components/Loader'
import { clearAccessToken } from 'common/utils/accessToken'

function usernameReducer(state, action) {
  switch (action.type) {
    case 'START_PROCESS':
      return {
        ...state,
        processBusy: true,
      }
    case 'PROCESS_ERRORED_INVALID_CREDENTIALS':
      return {
        ...state,
        processBusy: false,
        errors: action.payload.errors,
      }
    case 'PROCESS_ERRORED_OTHER':
      return {
        ...state,
        processBusy: false,
        ...action.payload,
      }
    case 'PROCESS_COMPLETED':
      return {
        ...state,
        processBusy: false,
        errors: {},
      }
    case 'UPDATE_USERNAME':
      return {
        ...state,
        username: action.payload,
      }
    case 'UPDATE_COUNTRY_CODE':
      return {
        ...state,
        countryCode: action.payload,
      }
    case 'UPDATE_ISO_CODE':
      return {
        ...state,
        isoCode: action.payload,
      }
    case 'UPDATE_MOBILE_NUMBER':
      return {
        ...state,
        mobileNumber: action.payload,
      }
    case 'GOT_ERRORS':
      return {
        ...state,
        errors: action.payload.errors,
      }
    case 'RESET_ERRORS':
      return {
        ...state,
        errors: {},
        errorResponseBody: null,
      }
    default:
      return state
  }
}
const SANITIZE_PHONE_NUMBER_REGEX = /[^+\d]+/g

const sanitizeIfMobileNumber = val => {
  // this will ignore any spaces, parenthesis, dashes and dots
  return /^[0-9.+() -]+$/.test(val)
    ? val.replace(SANITIZE_PHONE_NUMBER_REGEX, '')
    : val
}

export default function UsernameStep({
  wizard,
  startLoginProcess,
  addUsernameStep,
  steps,
  getPageIndex,
  pages,
  location,
}) {
  const wizardState = wizard.getPageState()
  const intl = useIntl()
  const isValidEmail = useEmailValidator()
  const isValidPhone = usePhoneValidator()
  const login_hint = loginHint(location)
  const [isSSOLogin, setIsSSOLogin] = useState(false)
  const magicLinkToken =
    new URLSearchParams(location.search).get('token_value') || ''
  const allowedIdentifiers = hasUIFeatureFlag('allowedLoginIdentifiers')
  const excludeCountryCode = hasUIFeatureFlag('excludeCountryCode')
  const { dialCode, iso2Code } = getCountryCode()
  const identifier =
    location?.state?.usernameEntered || wizardState.username || login_hint || ''
  const usernameInitialState = {
    tokenId: null,
    response: null,
    username: getDefaultIdentifier(identifier, allowedIdentifiers, iso2Code),
    countryCode: dialCode,
    mobileNumber: getDefaultIdentifier(
      identifier,
      allowedIdentifiers,
      iso2Code,
    ),
    errors: {},
    processBusy: false,
    processStepPayload: null,
    errorMessages: {
      username: intl.formatMessage({ id: 'error.username' }),
    },
    errorResponseBody: null,
    isDefaultMobile: isPhone(identifier),
    isoCode: iso2Code,
    disabledIdentity: !isEmpty(login_hint),
  }

  const promptCaptcha = FeatureFlagsService.get('system.security.promptCaptcha')

  const availableCountryCodes = hasUIFeatureFlag('availableCountryCodes')

  const enableOIDCLoginFlow = FeatureFlagsService.get(
    'uiFeatureFlags.idp.enableOIDCLoginFlow',
  )
  const disableLoginPasswordPrompt = FeatureFlagsService.get(
    'uiFeatureFlags.idp.disableLoginPasswordPrompt',
  )

  const socialTextKeys = {
    continue:
      'separatedMultiStep.authenticate-enterIdentifier.button.socialProvider',
    alternateOptions:
      'separatedMultiStep.authenticate-enterIdentifier.label.socialProviders-authenticate',
  }

  const isEmailIdentifierOnly = isEmailOnly(allowedIdentifiers)
  const isPhoneIdentifierOnly = isPhoneOnly(allowedIdentifiers)

  const usernameLabelKey = (() => {
    if (isEmailIdentifierOnly) {
      return 'separatedMultiStep.authenticate-enterIdentifier.label.email'
    } else if (isPhoneIdentifierOnly) {
      return 'separatedMultiStep.authenticate-enterIdentifier.label.mobile'
    } else {
      return 'separatedMultiStep.authenticate-enterIdentifier.label.emailOrMobile'
    }
  })()

  const getInputType = () => {
    if (isPhoneIdentifierOnly) {
      return 'tel'
    } else {
      return 'text'
    }
  }

  const recaptchaRef = useRef()
  const { toggleRecaptcha, resetRecaptcha } = useRecaptcha()

  const [state, dispatch] = useReducer(usernameReducer, usernameInitialState)

  const handleLinkAuthentication = useCallback(
    async token => {
      if (token) {
        wizard.setPageState({
          ...wizardState,
          token_value: token,
        })
        wizard.toPage(getPageIndex(pages.VerifyMagicLinkStep))
      }
    },
    [magicLinkToken],
  )

  useEffect(() => {
    if (
      hasUIFeatureFlag('proceedNextOnLoginHint') &&
      login_hint &&
      isEmail(login_hint)
    ) {
      setIsSSOLogin(true)
      handleFormSubmit()
    }
  }, [login_hint])

  useEffect(() => {
    handleLinkAuthentication(magicLinkToken)
  }, [magicLinkToken])

  useEffect(() => {
    return () => {
      if (recaptchaRef) {
        resetRecaptcha(recaptchaRef)
      }
    }
  }, [resetRecaptcha])

  const locationStateCode = location.state?.code
  const locationStateUsername = location.state?.username
  useEffect(() => {
    if (locationStateCode === 'codeExpired') {
      dispatch({
        type: 'UPDATE_USERNAME',
        payload: locationStateUsername,
      })
    }
  }, [locationStateCode, locationStateUsername])

  function onProcessError(err) {
    toggleRecaptcha()
    const errorCodes = getOperationErrorCodes(err.body)
    if (errorCodes.includes('invalid-credential')) {
      dispatch({
        type: 'PROCESS_ERRORED_INVALID_CREDENTIALS',
        payload: {
          errors: {
            username: !disableOnboardingFeatureFlag()
              ? intl.formatMessage(
                  { id: 'sign-in.user-exists' },
                  {
                    'create-id-link': str => (
                      <Link
                        onClick={() =>
                          analyticsProvider.sendAnalytics({
                            type: 'page_view',
                            page_title: 'signUp',
                            page_path: `/signup#signUp`,
                          })
                        }
                        to={{
                          pathname: '/signup',
                          search: window.location.search,
                          state: {
                            identifier: state.username,
                          },
                        }}
                      >
                        <UserTextLink underline="always">{str}</UserTextLink>
                      </Link>
                    ),
                  },
                )
              : intl.formatMessage({
                  id: 'sign-in.user-exists-disableOnboarding',
                }),
          },
        },
      })
      return
    }

    dispatch({
      type: 'PROCESS_ERRORED_OTHER',
      payload: {
        errorResponseBody: err.body,
      },
    })
  }

  const isAccessTokenInvalidError = error => {
    const isTokenInvalid =
      error.status === 401 &&
      getOperationErrorCodes(error.body).includes(ERROR_AUTHENTICATION_REQUIRED)
    return isTokenInvalid
  }

  async function signIn(recaptchaToken) {
    const errors = {}

    let { username, countryCode, mobileNumber } = state
    const usernameError = getUsernameValidationErrorSmart(
      intl,
      username,
      allowedIdentifiers,
      isValidEmail,
      isValidPhone,
    )

    if (!isEmpty(usernameError)) {
      errors.username = usernameError
    }

    if (Object.keys(errors).length) {
      dispatch({
        type: 'GOT_ERRORS',
        payload: { errors },
      })
      return Promise.resolve()
    }

    dispatch({ type: 'RESET_ERRORS' })

    const getIdentifierType = identifier =>
      isPhone(identifier) ? 'phone' : 'email'

    const usernameType = getIdentifierType(username)

    const fingerprint = getFingerprint()
    const sessionStartPayload = {
      username,
      guid: fingerprint,
    }

    if (promptCaptcha && recaptchaToken) {
      sessionStartPayload[CAPTCHA_RESPONSE] = recaptchaToken
    }

    const conditionalCountryCode = excludeCountryCode ? '' : countryCode

    const onboardIdentifier =
      usernameType === 'phone'
        ? !isEmailIdentifierOnly && !isPhoneIdentifierOnly
          ? // this condition is for email or mobile component where mobile number can come in username field as well
            conditionalCountryCode + username
          : conditionalCountryCode + mobileNumber
        : username

    try {
      const loginProcess = await startLoginProcess(
        enableOIDCLoginFlow,
        disableLoginPasswordPrompt,
      )
      dispatch({ type: 'START_PROCESS' })

      if (get(loginProcess, 'body.stepName') === steps.IdentifierPrompt) {
        const res = await addUsernameStep(
          loginProcess.body,
          {
            identifier: stripIdentity(onboardIdentifier),
            captchaResponse: recaptchaToken,
          },
          true,
        )

        if (get(res, 'body.stepName') === steps.PasswordPrompt) {
          wizard.setPageState({
            previousPage: pages.UsernameStep,
            username: onboardIdentifier,
            usernameType,
            lastStep: get(loginProcess, 'body.lastStep', false),
            processId: res.body.processId,
          })

          wizard.toPage(getPageIndex(pages.PasswordStep))
        }

        if (get(res, 'body.stepName') === steps.LoginOptionsPrompt) {
          wizard.setPageState({
            previousPage: pages.UsernameStep,
            isLoginOptionsStep: true,
            username: onboardIdentifier,
            usernameType,
            lastStep: get(loginProcess, 'body.lastStep', false),
            processId: res.body.processId,
            loginOptions: res.body.output?.loginOptions ?? {},
            output: get(res, 'body.output', {}),
          })

          wizard.toPage(getPageIndex(pages.LoginOptionsStep))
        }

        if (has(res, 'body.output.pkat')) {
          wizard.setPageState({
            previousPage: pages.UsernameStep,
            username: onboardIdentifier,
            usernameType,
            lastStep: get(res, 'body.lastStep', false),
            pkat: get(res, 'body.output.pkat'),
          })

          wizard.toPage(getPageIndex(pages.OtpStep))
        }
      }

      dispatch({
        type: 'PROCESS_COMPLETED',
      })
      setIsSSOLogin(false)
    } catch (err) {
      analyticsProvider.sendAnalytics({
        type: 'event',
        action: 'click',
        event_category: 'button',
        event_label: 'sign-in.username-submit',
        value: 1,
      })

      const resubmitForm = isAccessTokenInvalidError(err)

      if (resubmitForm) {
        clearAccessToken(ACCESS_TOKEN_KEY)
        handleFormSubmit()
      } else {
        onProcessError(err)
        setIsSSOLogin(false)
      }
    }
  }

  function handleUsernameChange(e) {
    e.preventDefault()

    if (state.errors) {
      dispatch({ type: 'RESET_ERRORS' })
    }

    dispatch({
      type: 'UPDATE_USERNAME',
      payload: e.target.value,
    })
  }

  function handleCountryCodeChange(e, countryCode, isoCode) {
    e.preventDefault()

    if (state.errors) {
      dispatch({ type: 'RESET_ERRORS' })
    }

    dispatch({
      type: 'UPDATE_COUNTRY_CODE',
      payload: countryCode,
    })

    dispatch({
      type: 'UPDATE_USERNAME',
      payload: countryCode + formatNumber(isoCode, state.mobileNumber),
    })

    dispatch({
      type: 'UPDATE_MOBILE_NUMBER',
      payload: formatNumber(isoCode, state.mobileNumber),
    })

    dispatch({
      type: 'UPDATE_ISO_CODE',
      payload: isoCode,
    })
  }

  function handleMobileNumberChange(e) {
    e.preventDefault()
    const { isoCode } = state
    if (state.errors) {
      dispatch({ type: 'RESET_ERRORS' })
    }

    dispatch({
      type: 'UPDATE_MOBILE_NUMBER',
      payload: formatNumber(isoCode, e.target.value),
    })

    dispatch({
      type: 'UPDATE_USERNAME',
      payload: state.countryCode + formatNumber(isoCode, e.target.value),
    })
  }

  function handleEmailOrMobileChange(value) {
    if (state.errors) {
      dispatch({ type: 'RESET_ERRORS' })
    }

    dispatch({
      type: 'UPDATE_USERNAME',
      payload: formatNumber(state.isoCode, value),
    })

    if (isPhone(value)) {
      dispatch({
        type: 'UPDATE_MOBILE_NUMBER',
        payload: formatNumber(state.isoCode, value),
      })
    }
  }

  async function handleFormSubmit(e) {
    if (e) e.preventDefault()

    validate('username')

    if (state.processBusy) return

    analyticsProvider.sendAnalytics({
      type: 'event',
      action: 'click',
      event_category: 'button',
      event_label: 'sign-in.username-submit',
      value: 0,
    })

    if (promptCaptcha) {
      const recaptcha = await recaptchaRef.current.executeAsync()
      recaptchaRef.current.reset()
      signIn(recaptcha)
    } else {
      signIn()
    }
  }

  const validate = fieldName => {
    const { errors, errorMessages } = state
    const value = state.username.trim()

    switch (fieldName) {
      case 'username':
        errors.username = getUsernameValidationErrorSmart(
          intl,
          sanitizeIfMobileNumber(value),
          allowedIdentifiers,
          isValidEmail,
          isValidPhone,
        )
        break
      default:
        errors[fieldName] = value === '' ? errorMessages[fieldName] : ''
        break
    }

    dispatch({
      type: 'GOT_ERRORS',
      payload: {
        errors,
      },
    })
  }

  const inputType = getInputType()

  if (isSSOLogin) {
    return <Loader />
  }

  return (
    <FlowLayout
      title={intl.formatMessage({
        id: 'separatedMultiStep.authenticate-enterIdentifier.title',
      })}
      subtitle={
        !disableOnboardingFeatureFlag() && (
          <span>
            {intl.formatMessage({ id: 'sign-up.description' })}{' '}
            <Link
              onMouseDown={e => {
                e.preventDefault()
              }}
              to={{ pathname: '/signup', search: location.search }}
            >
              <UserTextLink underline="always">
                {intl.formatMessage({
                  id:
                    'separatedMultiStep.authenticate-enterIdentifier.textLink.createOne',
                })}
              </UserTextLink>
            </Link>
          </span>
        )
      }
      recaptchaTerms="separatedMultiStep.authenticate-enterIdentifier.caption.reCAPTCHA"
      showCaptcha={promptCaptcha}
    >
      <form onSubmit={handleFormSubmit}>
        {!!state.errorResponseBody && (
          <Box mb={2}>
            <BackendErrorNotification
              intl={intl}
              error={state.errorResponseBody}
              hideClose
            />
          </Box>
        )}
        <Box mb={4}>
          {isEmailIdentifierOnly ? (
            <TextField
              type={inputType}
              labelPlacement="outside"
              errorProps={
                state.errors.username
                  ? { errorMessage: state.errors.username }
                  : undefined
              }
              autoFocus
              label={intl.formatMessage({ id: usernameLabelKey })}
              onChange={handleUsernameChange}
              value={state.username}
              onBlur={() => validate('username')}
              inputProps={{
                'data-testid': 'username-textfield',
                autoCapitalize: 'none',
                autoComplete: 'off',
              }}
              disabled={state.disabledIdentity}
            />
          ) : isPhoneIdentifierOnly ? (
            <MobileNumberInput
              textFieldProps={{
                value: state.mobileNumber,
                autoFocus: true,
                onChange: handleMobileNumberChange,
                inputProps: {
                  'data-testid': 'username-mobile',
                  autoCapitalize: 'none',
                  autoComplete: 'off',
                },
                type: inputType,
                onBlur: () => validate('username'),
                disabled: state.disabledIdentity,
              }}
              label={intl.formatMessage({ id: usernameLabelKey })}
              countryList={availableCountryCodes}
              selectProps={{
                defaultValue: iso2Code,
                inputProps: {
                  'data-testid': 'country-code',
                },
                onChange: handleCountryCodeChange,
              }}
              error={state.errors.username || undefined}
            />
          ) : (
            <EmailOrMobile
              onChange={handleEmailOrMobileChange}
              defaultValue={state.username}
              countryCode={iso2Code}
              defaultMobileNumber={state.isDefaultMobile}
              textFieldProps={{
                value: state.username,
                autoFocus: true,
                onBlur: () => validate('username'),
                inputProps: {
                  'data-testid': 'username-mobile',
                  autoCapitalize: 'none',
                  autoComplete: 'off',
                },
                errorProps: state.errors.username
                  ? { errorMessage: state.errors.username }
                  : undefined,
                type: inputType,
                label: intl.formatMessage({ id: usernameLabelKey }),
                disabled: state.disabledIdentity,
              }}
              mobileNumberInputProps={{
                textFieldProps: {
                  value: state.username,
                  autoFocus: true,
                  inputProps: {
                    'data-testid': 'username-mobile',
                    autoCapitalize: 'none',
                    autoComplete: 'off',
                  },
                  errorProps: state.errors.username
                    ? { errorMessage: state.errors.username }
                    : undefined,
                  type: inputType,
                  onBlur: () => validate('username'),
                  disabled: state.disabledIdentity,
                },
                countryList: availableCountryCodes,
                selectProps: {
                  inputProps: {
                    'data-testid': 'country-code',
                  },
                },
                label: intl.formatMessage({
                  id:
                    'separatedMultiStep.authenticate-enterIdentifier.label.mobile',
                }),
              }}
              onCountryCodeChange={(value, isoCode) => {
                dispatch({
                  type: 'UPDATE_COUNTRY_CODE',
                  payload: value,
                })
                dispatch({
                  type: 'UPDATE_ISO_CODE',
                  payload: isoCode,
                })
                dispatch({
                  type: 'UPDATE_USERNAME',
                  payload: formatNumber(isoCode, state.username),
                })
              }}
            />
          )}
        </Box>
        <UserButton
          type="submit"
          size="large"
          isLoading={state.processBusy}
          disabled={state.processBusy}
          data-testid="signin-button-next"
        >
          {intl.formatMessage({
            id:
              'separatedMultiStep.authenticate-enterIdentifier.button.authenticate',
          })}
        </UserButton>
      </form>
      <SocialMediaButtons textKeys={socialTextKeys} intl={intl} />
      {promptCaptcha ? <ReCAPTCHA ref={recaptchaRef} /> : null}
    </FlowLayout>
  )
}
