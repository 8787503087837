import { getRedirectUrlFromSearch } from 'common/utils/getRedirectUrl'
import { useSelector } from 'react-redux'

export default function Redirector({ location }) {
  const isAuthenticated = useSelector(state => state.session.hasSession)
  const newUrl = getRedirectUrlFromSearch(location.search)
  if (isAuthenticated && newUrl) {
    window.location = newUrl
  }
  return null
}
