import React from 'react'

import Box from '@mui/material/Box'

import * as UXPDialog from 'common/components/dialog/UXPDialog'
import { TextPrimaryButton } from 'common/components/button/Button'
import { analyticsProvider } from '../../../../App'
import { Body } from '../../../../common/components/Typography'
import { Grid } from '@mui/material'
import { useIntl } from 'react-intl'

const GeneratedBackupCodesDialog = ({ backupCodes = [], onClose, open }) => {
  const pageTitle = 'show_backup_codes'
  const baseEventTag = `dialog.${pageTitle}`

  const closeView = () => {
    analyticsProvider.sendAnalytics({
      type: 'event',
      action: 'click',
      event_category: 'button',
      event_label: `${baseEventTag}.cancel`,
      value: 0,
    })
    onClose()
  }

  const intl = useIntl()

  return (
    <UXPDialog.Dialog open={open} fullScreen fullWidth maxWidth="sm">
      <UXPDialog.Title
        style={{ textAlign: 'center' }}
        title={intl.formatMessage({ id: 'security.label-generated-buc-title' })}
      />
      <UXPDialog.Content>
        <Box>
          <Body mb={0}>
            {intl.formatMessage(
              {
                id: 'security.label-generated-buc-body',
              },
              { br: <br /> },
            )}
          </Body>
          <Box mt={4}>
            <Grid
              container
              spacing={{ xs: 2, md: 3 }}
              columns={{ xs: 4, sm: 8, md: 12 }}
            >
              {backupCodes.map((code, index) => (
                <Grid item xs={2} sm={4} md={4} key={index}>
                  <Box fontWeight="bold" textAlign="center">
                    {code}
                  </Box>
                </Grid>
              ))}
            </Grid>
          </Box>
        </Box>
      </UXPDialog.Content>
      <UXPDialog.Actions
        style={{
          justifyContent: 'center',
          marginBottom: '16px',
          marginTop: '0px',
        }}
      >
        <TextPrimaryButton onClick={closeView} size="small" variant="outlined">
          {intl.formatMessage({ id: 'common.button.close' })}
        </TextPrimaryButton>
      </UXPDialog.Actions>
    </UXPDialog.Dialog>
  )
}

export default GeneratedBackupCodesDialog
