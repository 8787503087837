import React from 'react'
import { injectIntl } from 'react-intl'
import { Button } from 'common/components/button/Button'
import Title from './Title'
import Page from 'common/components/Page'
import styles from './styles.module.css'

const ResultInvitation = props => {
  const { isAccept, groupName, onMove, intl } = props
  let title
  let firstMessage
  let secondMessage
  let label

  if (isAccept) {
    title = intl.formatMessage({ id: 'invitation.result.accept-title' })
    firstMessage = intl.formatMessage(
      { id: 'invitation.result.accept-message-with-groupname' },
      { groupName },
    )
    secondMessage = intl.formatMessage({
      id: 'invitation.result.accept-message',
    })
    label = intl.formatMessage({ id: 'invitation.result.accept.button' })
  } else {
    title = intl.formatMessage({ id: 'invitation.result.decline-title' })
    firstMessage = intl.formatMessage(
      { id: 'invitation.result.decline-message-with-groupname' },
      { groupName },
    )
    secondMessage = intl.formatMessage({
      id: 'invitation.result.decline-message',
    })
    label = intl.formatMessage({ id: 'invitation.result.decline.button' })
  }

  return (
    <div className={styles['create-user-container']}>
      <Title>{title}</Title>
      <div className="mt3">
        <Page.Body>
          <div>{firstMessage}</div>
          <div>{secondMessage}</div>
        </Page.Body>
      </div>
      <div>
        <Button onClick={onMove}>{label}</Button>
      </div>
    </div>
  )
}

export default injectIntl(ResultInvitation)
