import React from 'react'
import { connect, useSelector } from 'react-redux'
import { compose } from 'redux'
import classNames from 'classnames'
import { injectIntl } from 'react-intl'
import withStyles from '@mui/styles/withStyles'
import { get, isEmpty, lowerCase } from 'lodash'

import { loginWithSocialMedia } from 'common/actions/socialMedia'

import getParams from 'common/utils/getParams'

import styles from './styles.module.css'

import { UserButton } from '@admin-ui-common/base-user'
import { configurableTheme } from '@admin-ui-common/user-theme'
import { Box, Divider, Typography } from '@mui/material'

import { hasUIFeatureFlag } from 'common/services/FeatureFlagsService'
import { getSocialMediaInfo } from 'common/selectors/settings'
import getSocialProviderLogo from 'common/utils/getSocialProviderLogo'
import getSocialProviderName from 'common/utils/getSocialProviderName'

const muStyles = () => ({
  social: {
    paddingLeft: 14,
    paddingRight: 28,
    fontSize: 14,
  },
})

const Social = ({
  providerId,
  btnTextKey,
  theme,
  themeType,
  loginWithSocialMedia,
  intl,
  classes,
  ...props
}) => {
  const socialIcon = getSocialProviderLogo(providerId)
  const imageAlt = `${providerId} icon`
  const logoClass = `${providerId}Logo`

  return (
    <UserButton
      disableRipple
      disableElevation
      fullWidth
      color="primary"
      bgColor={get(
        configurableTheme(theme, themeType),
        `palette.social.${providerId}`,
      )}
      onClick={() =>
        loginWithSocialMedia(providerId, getParams(window.location.search))
      }
      {...props}
    >
      <Box component="span" className={styles.logoContainer}>
        <Box
          component="img"
          className={styles[logoClass]}
          src={socialIcon}
          alt={imageAlt}
        />
      </Box>
      <Box>
        {intl.formatMessage(
          { id: `${btnTextKey}` },
          { providerTitle: getSocialProviderName(providerId) },
        )}
      </Box>
    </UserButton>
  )
}

const SocialMediaButtons = ({ intl, textKeys }) => {
  const socialMediaInfo = useSelector(getSocialMediaInfo).map(lowerCase)
  const theme = hasUIFeatureFlag('theme')
  const themeType = hasUIFeatureFlag('theme.type')
  const alternateOptionsTextKey =
    (textKeys && textKeys.alternateOptions) || 'sign-in.alternate-options'
  const btnTextKey = (textKeys && textKeys.continue) || 'social-media.continue'

  if (isEmpty(socialMediaInfo)) {
    return null
  }

  return (
    <Box marginTop={5}>
      <Divider />
      <div className={classNames('mb-24')} />
      <Typography variant={'body2'}>
        {intl.formatMessage({ id: `${alternateOptionsTextKey}` })}
      </Typography>
      {socialMediaInfo.length !== 0 &&
        socialMediaInfo.map((providerId, index) => {
          return (
            <React.Fragment key={providerId}>
              {socialMediaInfo.length > index && (
                <div className={classNames('mb-16')} />
              )}
              {providerId &&
                socialMediaInfo.includes(lowerCase(providerId)) && (
                  <SocialButton
                    btnTextKey={btnTextKey}
                    providerId={lowerCase(providerId)}
                    theme={theme}
                    themeType={themeType}
                  />
                )}
            </React.Fragment>
          )
        })}
    </Box>
  )
}

const mapDispatchToProps = { loginWithSocialMedia }
const connectedToProps = connect(null, mapDispatchToProps)

export const SocialButton = compose(
  connectedToProps,
  injectIntl,
  withStyles(muStyles),
)(Social)

export default SocialMediaButtons
