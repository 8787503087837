import conf from 'conf'
import i from 'common/utils/i'
import process from 'common/utils/process'
import cloneDeep from 'lodash/cloneDeep'
import get from 'lodash/get'
import { getRedirectLocation } from 'common/utils/getRedirectUrl'
import { updateAttribute } from './user'
import getCurrentSubRouteConfiguration from '../utils/getCurrentSubRouteConfiguration'
import getParams from 'common/utils/getParams'
import { hasUIFeatureFlag } from 'common/services/FeatureFlagsService'

const featureName = 'SOCIAL_MEDIA'
export const REGISTER = {
  request: `${featureName}/REGISTER_REQUEST`,
  success: `${featureName}/REGISTER_SUCCESS`,
  error: `${featureName}/REGISTER_ERROR`,
}
export const ENROLL_OR_LOGIN = {
  request: `${featureName}/ENROLL_OR_LOGIN_REQUEST`,
  success: `${featureName}/ENROLL_OR_LOGIN_SUCCESS`,
  error: `${featureName}/ENROLL_OR_LOGIN_ERROR`,
}
export const CLEAR_STATE = `${featureName}/CLEAR_STATE`

export const socialMediaInitiateProcess = query => dispatch => {
  dispatch({ type: REGISTER.request })
  const registerUrl = `${conf.apiRoot}/process/step${query}`

  return i
    .get(registerUrl)
    .then(res => {
      if (res.metadata.status === 200) {
        const location = getRedirectLocation(res.metadata, '')
        if (location !== '') {
          return (window.location = location)
        }
      }

      dispatch({
        type: REGISTER.success,
        data: {
          ...res.body,
          statusCode: res.metadata.status,
        },
      })
    })
    .catch(err => {
      dispatch({
        type: REGISTER.error,
        error: {
          ...err.body,
          statusCode: err.statusCode || err.status,
        },
      })
    })
}

export const loginWithSocialMedia = providerId => dispatch => {
  const params = getParams(window.location.search)
  const { currentSubRoute } = getCurrentSubRouteConfiguration()
  const pathName = currentSubRoute !== '/' ? currentSubRoute : ''
  const redirectUrl = `?provider=${providerId}&final-redirect-uri=${encodeURIComponent(
    `${pathName}/social_end?${params}`,
  )}`
  dispatch({ type: ENROLL_OR_LOGIN.request })
  // const query = '?social=google-connect' + (param && '&' + param)
  const processName = `/socialFederation.SocialMediaAssistedOnboarding.v1.0${redirectUrl}`
  const requestUrl = `${conf.apiRoot}/process/start${processName}`

  return i
    .post(
      requestUrl,
      hasUIFeatureFlag('enableOIDCLoginFlow')
        ? { headers: { 'x-idp-authentication': true } }
        : {},
    )
    .then(res => {
      delete res.body.returnParameters
      delete res.body.output
      delete res.body.displayMessage

      return process.step({
        ...res.body,
        parameters: {
          providerId,
        },
      })
    })
    .then(res => {
      dispatch({ type: ENROLL_OR_LOGIN.success })
      const requestUrl = get(res, 'body.output.requestUrl')
      if (requestUrl) {
        window.location.href = requestUrl
      }
    })
    .catch(err =>
      dispatch({
        type: ENROLL_OR_LOGIN.error,
        error: {
          ...err.body,
          statusCode: err.statusCode || err.status,
        },
      }),
    )
}

export const continueSocialMedia = (confirmedData, locale) => (
  dispatch,
  getState,
) => {
  dispatch({ type: ENROLL_OR_LOGIN.request })
  const submissionData = {
    ...cloneDeep(getState().socialMedia.data),
    ...confirmedData,
  }
  delete submissionData.statusCode
  delete submissionData.displayMessage
  delete submissionData.returnParameters
  delete submissionData.output

  return process
    .step(submissionData, true)
    .then(async res => {
      const { userAuthenticated } = get(res, 'body')

      if (userAuthenticated) {
        await updateAttribute({
          attributes: { 'com.uxpsystems.mint.user.Language': locale },
        })(dispatch)
      }

      if (res.metadata.status === 200) {
        const location = getRedirectLocation(res.metadata, '')
        if (location !== '') {
          return (window.location = location)
        }
      }

      dispatch({
        type: ENROLL_OR_LOGIN.success,
        data: {
          ...res.body,
          statusCode: res.metadata.status,
        },
      })
    })
    .catch(err => {
      dispatch({
        type: ENROLL_OR_LOGIN.error,
        error: {
          ...err.body,
          statusCode: err.statusCode || err.status,
        },
      })
      return Promise.reject(err)
    })
}

export const clearState = () => ({
  type: CLEAR_STATE,
})
