import { hasUIFeatureFlag } from 'common/services/FeatureFlagsService'
import { countryList } from '@admin-ui-common/base-user'

const getCountryCode = () => {
  const defaultCountryCode = hasUIFeatureFlag('defaultCountryCode')
  if (!defaultCountryCode) {
    return {
      dialCode: null,
      iso2Code: null,
    }
  }
  const selectedCountry = countryList?.find(
    val => val[2] === defaultCountryCode,
  )
  return {
    dialCode: selectedCountry?.[3] ?? null,
    iso2Code: selectedCountry?.[2] ?? null,
  }
}

export const getRegionCodeFromCountryCode = countryCode => {
  const selectedCountry = countryList?.find(
    val => val[3].toString() === countryCode.toString(),
  )
  return selectedCountry?.[2] ?? null
}
export default getCountryCode
