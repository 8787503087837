import moment from 'moment'

const duration = duration => {
  if (!duration) {
    return []
  }

  const info = moment.duration(duration)._data
  const [unit, amount] = Object.entries(info).find(([, val]) => val > 0)

  return [unit, amount]
}

export default duration
