import React from 'react'
import Wizard from 'common/components/wizard/Wizard'
import Forgot from './Forgot'
import Verification from './Verification'
import Confirmation from './Confirmation'
import Reset from './Reset'
import useMainRedirect from 'common/components/hooks/useMainRedirect'

const ForgotWizard = () => {
  const mainRedirect = useMainRedirect()

  return (
    <Wizard>
      <Forgot />
      <Verification mainRedirect={mainRedirect} />
      <Confirmation />
      <Reset />
    </Wizard>
  )
}

export default ForgotWizard
