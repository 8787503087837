import React from 'react'

import Wizard from 'common/components/wizard/Wizard'
import ForgotPassword from './ForgotPassword'
import VerifyMobile from './VerifyMobile'
import PasswordReset from './PasswordReset'

const Views = [ForgotPassword, VerifyMobile, PasswordReset]

const ForgotPasswordWizard = props => (
  <Wizard>
    {Views.map((View, i) => (
      // eslint-disable-next-line react/no-array-index-key
      <View key={i} {...props} />
    ))}
  </Wizard>
)

export default ForgotPasswordWizard
