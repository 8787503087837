import process from 'common/utils/process'
import { get } from 'lodash'

const addOrUpdateProcessId = 'socialFederation.AssociateSocialMedia.v1.0'

export const associateSocialMedia = providerId =>
  process.start(`${addOrUpdateProcessId}?providerId=${providerId}`)

const removeProcessId = 'socialFederation.DissociateSocialMedia.v1.0'

export const dissociateSocialMedia = providerId =>
  process.start(removeProcessId).then(response => {
    if (get(response, 'body.stepName') !== 'SocialConnectionPrompt') {
      return Promise.resolve(response)
    }
    const socialConnections = get(response, 'body.output.socialConnections')
    const { userAuthenticated, processId } = get(response, 'body')
    const parameters = {
      socialConnection: socialConnections.find(sc => sc.includes(providerId)),
    }
    const stepPayload = { userAuthenticated, processId, parameters }

    return process.step(stepPayload)
  })
