import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import moment from 'moment'

import Typography from '@mui/material/Typography'
import { List } from '@admin-ui-common/base-user'
import UnlinkAccountButton from './unlink/UnlinkAccountButton'

import {
  getAccountNameLabel,
  getAccountDisplay,
} from 'common/selectors/accounts'

const AccountView = ({ account, accountName }) => {
  const accountDisplay = getAccountDisplay(account)
  const createdDate =
    account && account.createdDate && moment(account.createdDate).format('LL')

  return (
    <List.ListItem className="q-accountView-lineItem">
      <List.ListItemText
        data-q={accountName}
        primary={accountDisplay}
        secondary={
          <Typography variant="caption">Linked {createdDate}</Typography>
        }
      />
      <UnlinkAccountButton account={account} />
    </List.ListItem>
  )
}

AccountView.propTypes = {
  account: PropTypes.object,
  history: PropTypes.object,
  accountName: PropTypes.string,
}

const mapState = (state, { account }) => ({
  accountName: getAccountNameLabel(account),
})

export default connect(mapState)(AccountView)
