import React from 'react'

import Wizard from 'common/components/wizard/Wizard'
import ChangeAuthPage from './ChangeAuthPage'
import VerifyAuthPage from './VerifyAuthPage'

const ManageAuthWizard = props => (
  <Wizard>
    <ChangeAuthPage {...props} />
    <VerifyAuthPage {...props} />
  </Wizard>
)

export default ManageAuthWizard
