import {
  TopHeader,
  FlowLayout as FlowLayoutBase,
} from '@admin-ui-common/base-user'
import {
  Box,
  Link,
  Paper,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import PropTypes from 'prop-types'
import { useIntl } from 'react-intl'
import { useSelector } from 'react-redux'
import useLogoProvider from 'common/components/hooks/useLogoProvider'
import Footer from 'common/components/footer/Footer'
import { hasUIFeatureFlag } from 'common/services/FeatureFlagsService'

function FlowLayout({
  title,
  subtitle,
  children,
  showCaptcha,
  image,
  recaptchaTerms = 'recaptcha.terms',
  hideXPadding,
  hideContent,
}) {
  const intl = useIntl()
  const hasSession = useSelector(state => state?.session?.hasSession)
  const theme = useTheme()
  const matches = useMediaQuery(theme.breakpoints.up('sm'))
  const { logoImage } = useLogoProvider()

  let paperElevation = 0
  if (matches) paperElevation = undefined

  const pageBackgroundDesktopUrl = hasUIFeatureFlag(
    'flowLayoutPageBackgroundDesktop',
  )
  const pageBackgroundMobileUrl = hasUIFeatureFlag(
    'flowLayoutPageBackgroundMobile',
  )
  const cardBackgroundUrl = hasUIFeatureFlag('flowLayoutCardBackground')

  const hideDivider = hasUIFeatureFlag('hideFlowLayoutHeaderBackground')
  const transparentHeader = hasUIFeatureFlag('hideFlowLayoutHeaderBackground')

  return (
    <Box width="100%">
      <FlowLayoutBase
        navbarSlot={
          <TopHeader
            position={hasSession ? 'sticky' : 'relative'}
            showSidebarIcon={false}
            logoUrl={logoImage}
            profileMenuOptions={[]}
            hideDivider={hideDivider}
            transparentHeader={transparentHeader}
          />
        }
        footerSlot={<Footer />}
        backgroundImage={`url(${
          matches ? pageBackgroundDesktopUrl : pageBackgroundMobileUrl
        })`}
        backgroundSize="100% 100%"
        backgroundPosition="center"
      >
        <Paper
          sx={{
            display: hideContent ? 'none' : 'initial',
            backgroundImage: `url(${cardBackgroundUrl})`,
            backgroundSize: '100% 100%',
            backgroundPosition: 'center',
            ...(!matches && { background: 'none' }),
          }}
          elevation={paperElevation}
        >
          <Box
            paddingX={{ xs: hideXPadding ? 0 : 2, sm: hideXPadding ? 0 : 5 }}
            paddingY={5}
          >
            {image ? <Box marginBottom={3}>{image}</Box> : null}
            <Typography variant="h4">{title}</Typography>
            {subtitle ? (
              <Box marginTop={1}>
                <Typography variant="body1">{subtitle}</Typography>
              </Box>
            ) : null}
            <Box marginTop={hideXPadding ? 0 : 4}>{children}</Box>
          </Box>
        </Paper>
        {showCaptcha ? (
          <Box marginTop={{ xs: 0, sm: 2 }} marginX={{ xs: 2, sm: 0 }}>
            <Typography variant="caption">
              {intl.formatMessage(
                { id: recaptchaTerms },
                {
                  'privacy-link': msg => (
                    <Link href="https://policies.google.com/privacy">
                      {msg}
                    </Link>
                  ),
                  'terms-link': msg => (
                    <Link href="https://policies.google.com/terms">{msg}</Link>
                  ),
                },
              )}
            </Typography>
          </Box>
        ) : null}
      </FlowLayoutBase>
    </Box>
  )
}

FlowLayout.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.node,
  showCaptcha: PropTypes.bool,
  image: PropTypes.node,
}

export default FlowLayout
