import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { injectIntl } from 'react-intl'
import { get, isEmpty } from 'lodash'
import { withRouter } from 'react-router-dom'
import Typography from '@mui/material/Typography'

import DetailView from 'protected/common/DetailView'
import { TextWarningButton, Button } from 'common/components/button/Button'
import { getConnection } from '../selectors'
import { loadUser } from 'common/actions/user'
import getSocialProviderName from 'common/utils/getSocialProviderName'
import {
  getRedirectLocation,
  performRedirect,
} from 'common/utils/getRedirectUrl'

import {
  associateSocialMedia,
  dissociateSocialMedia,
} from 'common/processes/socialMedia'
import { addNotification } from 'common/actions/notification'
import { analyticsProvider } from '../../../App'

class SocialMediaDetailView extends React.PureComponent {
  static propTypes = {
    type: PropTypes.string,
    addNotification: PropTypes.func,
  }

  constructor(props) {
    super(props)

    this.flowType = 'detailView'
    this.pageTitle = 'social_media_detail_view'
    this.baseEventTag = `${this.flowType}.${this.pageTitle}`
  }

  state = {
    asyncError: '',
    inProgress: false,
  }

  onAssociate = () => {
    analyticsProvider.sendAnalytics({
      type: 'event',
      action: 'click',
      event_category: 'button',
      event_label: `${this.baseEventTag}.connect`,
      value: 0,
    })
    this.setState({ inProgress: true })
    associateSocialMedia(this.props.type)
      .then(res => {
        this.setState({ inProgress: false })

        const requestUrl = get(res, 'body.output.requestUrl')
        const xRedirect = getRedirectLocation(res.metadata)
        const socialRedirectUrl = requestUrl || xRedirect

        if (socialRedirectUrl) {
          performRedirect(socialRedirectUrl)
        }
      })
      .catch(err => {
        analyticsProvider.sendAnalytics({
          type: 'event',
          action: 'click',
          event_category: 'button',
          event_label: `${this.baseEventTag}.connect`,
          value: 1,
        })
        console.error(err)
      })
      .finally(() => {
        this.setState({ inProgress: false })
      })
  }

  onDissociate = () => {
    analyticsProvider.sendAnalytics({
      type: 'event',
      action: 'click',
      event_category: 'button',
      event_label: `${this.baseEventTag}.disconnect`,
      value: 0,
    })
    const { loadUser, type, addNotification, intl } = this.props
    const socialAccountType = getSocialProviderName(type)
    this.setState({ inProgress: true })
    dissociateSocialMedia(type)
      .then(loadUser)
      .then(() => {
        this.setState({ inProgress: false })
        addNotification({
          message: intl.formatMessage(
            { id: 'notification.account-disconnected' },
            { socialAccountType },
          ),
          variant: 'success',
        })
      })
      .catch(err => {
        analyticsProvider.sendAnalytics({
          type: 'event',
          action: 'click',
          event_category: 'button',
          event_label: `${this.baseEventTag}.disconnect`,
          value: 1,
        })
        const message = get(err, 'body.operationError[0].message')
        this.setState({
          inProgress: false,
          asyncError: message,
        })
      })
  }

  componentDidMount() {
    const { intl, addNotification, location, type } = this.props
    const socialAccountType = getSocialProviderName(type)

    if (location.showNotification) {
      addNotification({
        message: intl.formatMessage(
          { id: 'notification.account-connected' },
          { socialAccountType },
        ),
        variant: 'success',
      })
    }
  }

  render() {
    const { intl, type, socialConnection, backLink } = this.props
    const { asyncError } = this.state

    const isConnected = !isEmpty(socialConnection)

    const socialMedia = isConnected
      ? intl.formatMessage({ id: 'profile.detail.social-media.connected' })
      : intl.formatMessage(
          {
            id: 'profile.placeholder.social-media.not-connected',
          },
          {
            socialProvider: getSocialProviderName(type),
          },
        )

    return (
      <DetailView
        title={intl.formatMessage(
          { id: 'profile.detail.social.title' },
          { providerTitle: getSocialProviderName(type) },
        )}
        description={intl.formatMessage(
          {
            id: `profile.detail.social.description`,
          },
          {
            providerTitle: getSocialProviderName(type),
          },
        )}
        backLink={backLink}
      >
        <DetailView.Title title={socialMedia} />

        {asyncError && (
          <div className="mt2">
            <Typography color="error" variant="body1">
              {asyncError}
            </Typography>
          </div>
        )}

        <div className="mt3">
          {isConnected ? (
            <TextWarningButton
              style={{ padding: '0' }}
              onClick={this.onDissociate}
              disabled={this.state.inProgress}
            >
              {intl.formatMessage({ id: 'common.button.disconnect' })}
            </TextWarningButton>
          ) : (
            <Button onClick={this.onAssociate}>
              {intl.formatMessage({ id: 'common.button.connect' })}
            </Button>
          )}
        </div>
      </DetailView>
    )
  }
}

const mapStateToProps = (state, { type }) => ({
  socialConnection: getConnection(state, type),
})

const mapDispatchToProps = { loadUser, addNotification }

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(injectIntl(SocialMediaDetailView)))
