import FeatureFlagsService from '../services/FeatureFlagsService'
import { getErrorCodeMap, getSafeFormatMessage } from '@admin-ui-common/utils'
import {
  ACTION_TOKEN_EXPIRED,
  ACTION_TOKEN_RETRIES_EXCEEDED,
  NOT_ELIGIBLE,
  NUMBER_IS_PORTABLE,
  TOO_MANY_RETRIES,
  INVALID_PKAT,
  TOKEN_MAX_RESEND_REACHED,
  INVALID_ACT_CONSUMPTION,
} from 'common/constants'
import { pick } from 'lodash'

export const fallbackId = 'error.unexpected'

// fallback option for error message formatting
// Error: {original-error-code}
export const messageFallbackOption = {
  id: 'error.fallback-with-code',
  getParams: id => {
    return {
      code: id.split('.')[1],
    }
  },
  defaultMessage: {
    id: fallbackId,
  },
}

export const titleFallbackOption = {
  id: fallbackId,
}

export const formatErrorTitle = getSafeFormatMessage(titleFallbackOption)
export const formatErrorMessage = getSafeFormatMessage(messageFallbackOption)
export const formatNoFallback = getSafeFormatMessage()

export const getLengthParam = (rules, matchKey) => {
  const lengthRule = FeatureFlagsService.get(rules)?.length
  if (!lengthRule) {
    return undefined
  }

  const minLength = (lengthRule.match(matchKey) ?? [])[1]
  if (!minLength) {
    return undefined
  }
  return {
    number: parseInt(minLength, 10),
  }
}

export const getFeatureFlagParamMap = () => ({
  NotReusedPassword: {
    title: {
      passwordHistoricalCheckingCount:
        FeatureFlagsService.get(
          'system.security.passwordHistoricalCheckingCount',
        ) ?? 0,
    },
  },
  NotReusedPasswordByTime: {
    title: {
      passwordHistoricalCheckingPeriodSeconds: Math.ceil(
        (FeatureFlagsService.get(
          'system.security.passwordHistoricalCheckingPeriodSeconds',
        ) ?? 0) / 86500,
      ),
    },
  },
  'password-regex-rule-violation-length': {
    message: getLengthParam('process.passwordComplexityRules', /^\.\{(\d+)/),
  },
  'passcode-regex-rule-violation-length': {
    message: getLengthParam(
      'system.security.passcodeComplexityRules',
      /^\^.\{(\d+)/,
    ),
  },
  'passcode-max-consecutive-digits': {
    message: {
      number: FeatureFlagsService.get(
        'system.security.passcodeMaxConsecutiveNumbers',
      ),
    },
  },
})

const invalidActConsumption = ['invalid-act-consumption']

// every key in this object is a valid dictionary context
// values are error codes that use this context
const contextKeyMap = {
  socialEnd: ['unexpected', 'process-expired', 'invalid-captcha,'],
  phone: ['already-exist-authn-identifier', 'ValidAuthnIdentifier'],
  email: ['already-exist-authn-identifier', 'ValidAuthnIdentifier'],
  verifyAuthPage: invalidActConsumption,
  userConfirmPage: invalidActConsumption,
  confirmUser: invalidActConsumption,
  reset: invalidActConsumption,
  verifyCode: invalidActConsumption,
  deviceConnection: invalidActConsumption,
  'cancelSubscribe.VerificationCode': invalidActConsumption,
  'register-mfa-app': ['invalid-code'],
  'change-password': ['invalid-credential'],
  'sign-in': [
    'user-not-active',
    'authentication-required',
    'user-profile-locked',
    'invalid-captcha',
    'process-terminated-with-too-many-retries',
  ],
}

// a list of error codes that _need_ one particular context to be found in the dictionary
// we can provided this automatically to avoid mistakes
// ValidAuthnIdentifier and invalid-captcha both need a context to be resolved
// but it could be one of multiple contexts
export const singleContextErrorCodes = {
  'user-not-active': 'sign-in',
  'authentication-required': 'sign-in',
  'user-profile-locked': 'sign-in',
}

export const getContextMapFromContext = context => {
  const codes = contextKeyMap[context]
  if (!codes) {
    return {}
  }

  return codes.reduce((acc, code) => {
    return {
      ...acc,
      [code]: context,
    }
  }, {})
}

// TODO: export this from utils, this is duplicated logic
export const mapErrorCodeToDictionaryId = ({ code, type, context }) => {
  if (type === 'title') {
    return `error.${code}.title${context ? `.context.${context}` : ''}`
  }

  return `error.${code}${context ? `.context.${context}` : ''}`
}

// TODO: export this from common-utils
export const isUlmBackendError = mysteryObj => {
  return (
    mysteryObj.fieldErrors ||
    mysteryObj.operationErrors ||
    mysteryObj.operationError
  )
}

// TODO: put this in common-utils
export const getOperationErrorMessage = error => {
  let errorObj = Array.isArray(error.operationErrors)
    ? error.operationErrors
    : error.operationError

  if (Array.isArray(errorObj)) {
    return errorObj[0]?.message ?? null
  }

  return errorObj?.message ?? null
}

// TODO: put this in common-utils, make more efficient
// get flat array of code_v2 or code
export const getErrorCodes = error => {
  if (!error) {
    return []
  }
  return Object.values(getErrorCodeMap(error) ?? {}).reduce((acc, codes) => {
    return acc.concat(codes)
  }, [])
}

export const getOperationErrorCodes = error => {
  const operationError = pick(error, 'operationErrors')

  return getErrorCodes(operationError)
}

export const resolveErrorEntity = ({
  intl,
  error,
  context,
  types = ['title', 'message'],
}) => {
  const contextMap = context ? getContextMapFromContext(context) : null
  const errorCodeMap = getErrorCodeMap(error)
  if (!errorCodeMap) {
    return intl.formatMessage({ id: fallbackId })
  }

  const uniqCodes = Array.from(
    new Set(
      Object.values(errorCodeMap)
        .reduce((acc, codes) => acc.concat(codes), [])
        .concat(Object.keys(errorCodeMap)),
    ),
  )
  if (!uniqCodes.length) {
    return intl.formatMessage({ id: fallbackId })
  }

  if (types.includes('message')) {
    for (let i = 0; i < uniqCodes.length; i += 1) {
      const resolvedMessage = formatNoFallback({
        intl,
        id: mapErrorCodeToDictionaryId({
          code: uniqCodes[i],
          context: contextMap?.[uniqCodes[i]],
        }),
      })
      if (resolvedMessage) {
        return resolvedMessage
      }
    }

    if (!types.includes('title')) {
      return intl.formatMessage({ id: fallbackId })
    }
  }

  for (let i = 0; i < uniqCodes.length; i += 1) {
    const resolvedTitle = formatNoFallback({
      intl,
      id: mapErrorCodeToDictionaryId({
        code: uniqCodes[i],
        context: contextMap?.[uniqCodes[i]],
        type: 'title',
      }),
    })
    if (resolvedTitle) {
      return resolvedTitle
    }
  }

  return intl.formatMessage({ id: fallbackId })
}

export const resolveErrorMessage = args =>
  resolveErrorEntity({ ...args, type: ['message'] })

export const resolveErrorTitle = args =>
  resolveErrorEntity({ ...args, types: ['title'] })

export const isDisableVerifyPinError = errorCode =>
  errorCode === TOO_MANY_RETRIES || errorCode === ACTION_TOKEN_RETRIES_EXCEEDED

export const isNotEligibleError = errorCode =>
  errorCode === NOT_ELIGIBLE || errorCode === NUMBER_IS_PORTABLE

export const isUserProfileLocked = errorCode =>
  errorCode === 'user-profile-locked' || errorCode === TOO_MANY_RETRIES

export const isAlreadyExistingError = errorCode =>
  errorCode === 'already-exist-email' || errorCode === 'already-exist-phone'

export const isTokenResendError = errorCode =>
  errorCode === 'token-max-resend-reached' || errorCode === ACTION_TOKEN_EXPIRED

export const otpError = errorCode =>
  errorCode === ACTION_TOKEN_EXPIRED ||
  errorCode === ACTION_TOKEN_RETRIES_EXCEEDED ||
  errorCode === INVALID_PKAT

export const isVerifyOTPStartover = errorCode =>
  errorCode === INVALID_PKAT || errorCode === TOKEN_MAX_RESEND_REACHED

export const isVerifyOTPError = error => getErrorCodes(error).find(otpError)

export const isInvalidPkat = error =>
  getErrorCodes(error).find(error => error === INVALID_PKAT)
export const isTokenMaxResendReached = error =>
  getErrorCodes(error).find(error => error === TOKEN_MAX_RESEND_REACHED)

export const isInvalidActConsumption = error =>
  getErrorCodes(error).find(error => error === INVALID_ACT_CONSUMPTION)
export const isTooManyRetries = error =>
  getErrorCodes(error).find(error => error === TOO_MANY_RETRIES)
