import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { injectIntl } from 'react-intl'
import { compose } from 'redux'

import ManageAuthWizard from 'protected/profile/authWizard/ManageAuthWizard'
import DetailView from 'protected/common/DetailView'
import withAuthDialog from './withAuthDialog'

import * as selectors from '../selectors'
import { Box } from '@mui/material'
import withStyles from '@mui/styles/withStyles'
import MuiDivider from '@mui/material/Divider'
import { get } from 'lodash'
import FeatureFlagsService from '../../../common/services/FeatureFlagsService'
import IdentifierItem, { processIdentifiers } from './IdentifierItem'

const contentPadding = 16

const muStyles = theme => ({
  divider: {
    marginLeft: `-${contentPadding}px`,
    marginRight: `-${contentPadding}px`,
    [theme.breakpoints.down('sm')]: {
      marginLeft: 'unset',
      marginRight: 'unset',
    },
  },
})

class MobileDetailView extends React.PureComponent {
  static propTypes = {
    onChangeDialogOpen: PropTypes.func,
    onVerifyDialogOpen: PropTypes.func,
    onChangeDialogClose: PropTypes.func,
    addDialogOpen: PropTypes.func,
    deleteDialogOpen: PropTypes.func,
    mobiles: PropTypes.arrayOf(PropTypes.string),
    dialogOpen: PropTypes.bool,
    showVerify: PropTypes.bool,
    mode: PropTypes.string,
    stateData: PropTypes.object,
  }

  state = {
    selectedIdentifier: null,
  }

  render() {
    const {
      onChangeDialogOpen,
      onVerifyDialogOpen,
      onChangeDialogClose,
      addDialogOpen,
      deleteDialogOpen,
      mobiles,
      dialogOpen,
      showVerify,
      intl,
      appId,
      classes,
      stateData,
    } = this.props

    const processedMobiles = processIdentifiers(mobiles)

    const emptyMessage = intl.formatMessage({
      id: 'profile.detail.mobile.add-recovery-mobile',
    })
    const pendingMessage = intl.formatMessage({
      id: 'profile.common.pending-verification',
    })

    const maximumAllowedMobiles = FeatureFlagsService.get('process.maxMobiles')
    const allowPostponedVerification = FeatureFlagsService.get(
      'process.userManagement.AddOrUpdateAuthnIdentifier.allowPostponedVerification',
    )
    const canAddMobiles = maximumAllowedMobiles
      ? processedMobiles.length < maximumAllowedMobiles
      : true

    const Divider = () => (
      <Box my={2} className={classes.divider}>
        <MuiDivider />
      </Box>
    )

    return (
      <DetailView
        title={intl.formatMessage({ id: 'profile.detail.mobile.title' })}
        description={intl.formatMessage({
          id: 'profile.detail.mobile.description',
        })}
        contentPadding={contentPadding}
        hideProfilePaperOnMobile
      >
        {Array.isArray(processedMobiles) &&
          processedMobiles.map((item, index) => (
            <>
              <IdentifierItem
                type="mobile"
                item={item}
                stateData={stateData}
                emptyMessage={emptyMessage}
                pendingMessage={pendingMessage}
                onChangeDialogOpen={onChangeDialogOpen}
                onVerifyDialogOpen={onVerifyDialogOpen}
                deleteDialogOpen={deleteDialogOpen}
                setState={data => this.setState(data)}
                appId={appId}
              />
              {item.replacementIdentifier && (
                <>
                  <Box mx={2}>
                    <Divider />
                  </Box>
                  <IdentifierItem
                    type="mobile"
                    item={item.replacementIdentifier}
                    stateData={stateData}
                    emptyMessage={emptyMessage}
                    pendingMessage={pendingMessage}
                    onChangeDialogOpen={onChangeDialogOpen}
                    onVerifyDialogOpen={onVerifyDialogOpen}
                    deleteDialogOpen={deleteDialogOpen}
                    setState={data => this.setState(data)}
                    appId={appId}
                  />
                </>
              )}
              {index !== processedMobiles.length - 1 && <Divider />}
            </>
          ))}

        {canAddMobiles && (
          <>
            {processedMobiles.length > 0 && <Divider />}
            <DetailView.AddIdentifier
              type="mobile"
              onVerifyOpen={() => {
                this.setState({
                  selectedIdentifier: '',
                  mode: 'add',
                })
                addDialogOpen()
              }}
            />
          </>
        )}

        {dialogOpen && (
          <ManageAuthWizard
            onClose={onChangeDialogClose}
            auth={get(this.state.selectedIdentifier, 'number')}
            pendingOrActivatingAuth={get(
              this.state.pendingOrActivatingEntity,
              'number',
            )}
            allowPostponedVerification={allowPostponedVerification}
            showVerify={showVerify}
            mode={this.state.mode}
            owner="mobile"
          />
        )}
      </DetailView>
    )
  }
}

const mapStateToProps = state => ({
  mobiles: selectors.getMobiles(state),
  appId: state.configuration.config.id,
  stateData: state,
})
const connectedProps = connect(mapStateToProps)

export default compose(
  connectedProps,
  withAuthDialog,
  injectIntl,
)(withStyles(muStyles)(MobileDetailView))
