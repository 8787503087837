import { createGlobalStyle } from 'styled-components'

export const GlobalStyle = createGlobalStyle`
  
  * {
    box-sizing: border-box;
  }

  :root {
    --space-1: 0.5rem;
    --space-2: 1rem;
    --space-3: 2rem;
    --space-4: 4rem;
  }

  a {
    color: #041e42;
    text-decoration: none;
  }

  header {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
  }

  #root {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
  }
`
