import { Paper, Typography } from '@mui/material'
import { Box } from '@mui/system'
import { TextPrimaryButton } from 'common/components/button/Button'
import { useCallback, useEffect } from 'react'
import { useIntl } from 'react-intl'
import { useLocation } from 'react-router-dom'

export default function Prefilled({ approving, verifyCode, approveCode }) {
  const intl = useIntl()
  const location = useLocation()
  const searchParams = new URLSearchParams(location.search.split('?')[1])
  const userCode = searchParams.get('user_code')

  const handleContinue = useCallback(() => {
    approveCode(userCode)
  }, [approveCode, userCode])

  useEffect(() => {
    verifyCode(userCode)
  }, [userCode, verifyCode])

  return (
    <>
      <Typography sx={{ mb: 3 }}>
        {intl.formatMessage({ id: 'activate-device.prefilled.message' })}
      </Typography>
      <Paper
        elevation={0}
        variant="outlined"
        sx={{
          width: '100%',
          display: 'grid',
          placeItems: 'center',
          py: '14px',
          mb: 4,
        }}
      >
        <Typography variant="h6">{userCode}</Typography>
      </Paper>
      <Box display="flex" gap={3}>
        <TextPrimaryButton
          isLoading={approving}
          size="large"
          variant="contained"
          onClick={handleContinue}
        >
          {intl.formatMessage({ id: 'common.button.confirm' })}
        </TextPrimaryButton>
      </Box>
    </>
  )
}
