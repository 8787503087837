import isEmpty from 'lodash/isEmpty'
import { hasUIFeatureFlag } from 'common/services/FeatureFlagsService'

export const isTrustedRedirect = url => {
  const trustedRedirects = hasUIFeatureFlag('trustedOrigins')
  const trustedRedirectsArray = trustedRedirects && trustedRedirects.split(',')

  if (!trustedRedirects) {
    return true
  }

  try {
    return trustedRedirectsArray.some(trustedURL => {
      const trustedOrigin = new URL(trustedURL.trim()).origin
      const urlOrigin = new URL(url.trim()).origin

      return trustedOrigin === urlOrigin
    })
  } catch {
    return false
  }
}

export const getRedirectUrlFromSearch = search => {
  const params = new URLSearchParams(search)
  const url = params.get('redirect_uri') || params.get('redirect')

  if (isTrustedRedirect(url)) {
    return url
  }

  return ''
}

export const getDecodedSearchUrl = () => {
  const url = ((window.location.search || window.originalLocationSearch).match(
    /(redirect|redirect_uri)=([^&]+)/i,
  ) || [])[2]

  if (isTrustedRedirect(url)) {
    return url
  }

  return ''
}

export const extractUrlFromHeaders = (
  metadata = { headers: { get: () => '' } },
) =>
  metadata.headers &&
  (metadata.headers['X-Redirect-Location'] ||
    metadata.headers['x-redirect-location'] ||
    metadata.headers['Location'])

export const getRedirectLocation = (metadata, defaultLocation) =>
  extractUrlFromHeaders(metadata) || getDecodedSearchUrl() || defaultLocation

export const performRedirect = url => {
  window.location.href = url
}

const getRedirectUrl = (accountType, response) => {
  if (isEmpty(response.body.operationError)) {
    return window.location.origin
      .slice()
      .replace('://id.', '://myaccount.')
      .concat(`/settings#conn_success=${accountType}`)
  }
  const { code, type } = response.body.operationError[0]
  return `${type}#conn_fail=${code}`
}

export default getRedirectUrl
