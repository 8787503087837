import React, { useState, useEffect } from 'react'
import { useIntl } from 'react-intl'
import { isEmpty } from 'lodash'
import Collapse from '@mui/material/Collapse'
import { BackendErrorNotification } from 'common/components/backendErrorNotification/BackendErrorNotification'
import Box from '@mui/material/Box'

import * as UXPCard from 'common/components/Card'
import { Title, Body } from 'common/components/Typography'
import MobileNumberField from 'common/components/textfield/MobileNumberField'
import PasswordField from 'common/components/textfield/PasswordField'
import { TextPrimaryButton, Button } from 'common/components/button/Button'
import TermsAndConditions from 'common/components/TermsAndConditions'

import process from 'common/utils/process'
import useMainRedirect from 'common/components/hooks/useMainRedirect'

import FeatureFlagsService from 'common/services/FeatureFlagsService'
import { analyticsProvider } from 'App.js'
import {
  getErrorCodes,
  formatErrorMessage,
} from 'common/utils/processBackendErrors'

const hasFeatureFlag = feature =>
  FeatureFlagsService.get(`idpPortal.wizard.createId.${feature}`)

const CreateId = ({ wizard, flowType }) => {
  const {
    mobile,
    userAuthenticated,
    stepName,
    processId,
    termsOfServiceURL,
  } = wizard.getPageState()

  const [loading, setLoading] = useState(false)
  const [password, setPassword] = useState('')
  const [error, setError] = useState('')
  const [errorResponseBody, setErrorResponseBody] = useState(null)
  const [terms, setTerms] = useState(false)
  const intl = useIntl()
  const mainRedirect = useMainRedirect(`/${flowType}`)
  const showTermsAndConditions = hasFeatureFlag('showTermsAndConditions')

  const pageTitle = 'create_id'
  const baseEventTag = `${flowType}.create_id`
  const passwordFieldLabel = intl.formatMessage({
    id: 'onboard.common.password',
  })
  const mobileFieldLabel = intl.formatMessage({
    id: 'onboard.common.mobile-number',
  })

  useEffect(() => {
    analyticsProvider.sendAnalytics({
      type: 'page_view',
      page_title: pageTitle,
      page_path: `/${flowType}#${pageTitle}`,
    })
  }, [flowType])

  const createAccount = async noPassword => {
    const nextErrors = {}
    const tagLabel = noPassword ? 'skip' : 'create'
    setErrorResponseBody(null)

    if (!mobile) {
      const errorMessage = intl.formatMessage({
        id: 'onboard.error.please-enter-mobile',
      })

      nextErrors.mobile = errorMessage

      analyticsProvider.sendAnalytics({
        type: 'event',
        action: 'exception',
        description: `${baseEventTag}.phoneNumber: ${errorMessage}`,
      })
    }

    if (!password && !noPassword) {
      const errorMessage = intl.formatMessage({
        id: 'onboard.error.please-enter-password',
      })

      nextErrors.password = errorMessage

      analyticsProvider.sendAnalytics({
        type: 'event',
        action: 'exception',
        description: `${baseEventTag}.screen: ${errorMessage}`,
      })
    }

    setError(nextErrors)

    if (!isEmpty(nextErrors)) {
      return
    }

    try {
      setLoading(true)
      const stepPayload = {
        userAuthenticated,
        stepName,
        processId,
        parameters: {
          password: noPassword ? '' : password,
        },
      }

      setLoading(false)
      const res = await process.step(stepPayload, true)

      analyticsProvider.sendAnalytics({
        type: 'event',
        action: 'click',
        event_category: 'button',
        event_label: `${baseEventTag}.${tagLabel}`,
        value: 0,
      })

      const isStaticRedirect = mainRedirect(res.metadata)
      if (isStaticRedirect) {
        wizard.setPageState({})
        wizard.toPage(0)
      }
    } catch (err) {
      setLoading(false)
      const errorCodes = getErrorCodes(err.body)

      setErrorResponseBody(err.body)
      analyticsProvider.sendAnalytics({
        type: 'event',
        action: 'click',
        event_category: 'button',
        event_label: `${baseEventTag}.${tagLabel}`,
        value: 1,
      })

      analyticsProvider.sendAnalytics({
        error: errorCodes.join(','),
      })
    }
  }

  const onCreate = () => {
    createAccount(false)
  }

  const onSkip = () => {
    createAccount(true)
  }

  return (
    <UXPCard.Card>
      <UXPCard.Content light>
        <Title>
          {intl.formatMessage({
            id: 'onboard-subscribe.thank-you.create-an-id',
          })}
        </Title>
        <Body mb={3}>
          {intl.formatMessage({
            id: 'onboard-subscribe.thank-you.create-a-password',
          })}
        </Body>
        <Collapse in={!!errorResponseBody}>
          <Box mb={3}>
            <BackendErrorNotification
              intl={intl}
              subTitle={
                <div>
                  {formatErrorMessage({
                    intl,
                    id: 'error.credential-at-least',
                  })}
                </div>
              }
              error={errorResponseBody}
              handleClose={() => {
                setErrorResponseBody(null)
              }}
              prioritize={['NotReusedPasswordByTime', 'NotReusedPassword']}
              showAsSingle={['NotReusedPasswordByTime', 'NotReusedPassword']}
            />
          </Box>
        </Collapse>
        <MobileNumberField label={mobileFieldLabel} value={mobile} disabled />
        <Box mb={3} />
        <PasswordField
          required
          label={passwordFieldLabel}
          onChange={e => setPassword(e.target.value)}
          value={password}
          errorText={error.password || error.mobile}
        />
        {showTermsAndConditions && (
          <Box my={2}>
            <TermsAndConditions
              link={termsOfServiceURL}
              onChange={() => setTerms(term => !term)}
              checked={terms}
            />
          </Box>
        )}
      </UXPCard.Content>
      <UXPCard.Divider />
      <UXPCard.Actions>
        <TextPrimaryButton onClick={onSkip}>
          {intl.formatMessage({ id: 'onboard.common.skip' })}
        </TextPrimaryButton>
        <Button
          color="primary"
          onClick={onCreate}
          isLoading={loading}
          disabled={!terms && showTermsAndConditions}
        >
          {intl.formatMessage({
            id: 'onboard.common.create',
          })}
        </Button>
      </UXPCard.Actions>
    </UXPCard.Card>
  )
}

export default CreateId
