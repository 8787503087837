import React from 'react'

import { TextField as UserTextField } from '@admin-ui-common/base-user'

const MAX_VALUE_LENGTH = 50

const numericProps = {
  pattern: '[0-9]*',
  inputMode: 'numeric',
}
const TextField = props => {
  const {
    className,
    errorText,
    helperText,
    onChange,
    numeric,
    inputType,
    ...rest
  } = props

  const validateInput = e => {
    if (e.target.value.length <= MAX_VALUE_LENGTH) {
      onChange(e)
    }
  }

  return (
    <UserTextField
      error={!!errorText}
      className={className}
      errorProps={errorText && { errorMessage: errorText }}
      helperProps={helperText && { helperMessage: helperText }}
      onChange={validateInput}
      inputType={inputType}
      inputProps={numeric && numericProps}
      {...rest}
    />
  )
}

export default TextField
