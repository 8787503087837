import Wizard from 'common/components/wizard/Wizard'
import process from 'common/utils/process'
import UsernameStep from './UsernameStep'
import PasswordStep from './PasswordStep'
import { findIndex } from 'lodash'
import LoggedIn from './LoggedIn'
import OtpStep from './OtpStep'
import LoginOptionsStep from './LoginOptionsStep'
import OnboardAuthentication from './OnboardAuthentication'
import MagicLinkStep from './MagicLinkStep'
import SetPasswordStep from './SetPasswordStep'
import EnforceAccountAssociationStep from '../EnforceAccountAssociation/EnforceAccountAssociationStep'
import AccountAssociationOtpStep from '../EnforceAccountAssociation/OtpStep'
import TwoStepMethod from './TwoStepMethod'
import TwoStepCode from './TwoStepCode'
import TotpVerificationStep from './TotpVerificationStep'
import TwoStepSetup from './2FASteps/TwoStepSetup'
import TwoStepTypeSelect from './2FASteps/TwoStepTypeSelect'
import QRScanStep from './2FASteps/TOTP/QRScanStep'
import TOTPSetupVerificationStep from './2FASteps/TOTP/TOTPSetupVerificationStep'
import TwoFASetupSuccessStep from './2FASteps/TwoFASetupSuccessStep'
import SelectEmailStep from './2FASteps/Email/SelectEmailStep'
import SelectMobileStep from './2FASteps/Mobile/SelectMobileStep'
import { verifyCode } from 'common/processes/token'
import { twoStepTypes } from 'lander/constants'

export async function startLoginProcess(
  enableOIDCLoginFlow = false,
  disableLoginPasswordPrompt = false,
) {
  const res = await process.startGet(
    'authentication.OnboardAndAuthenticate.v2.0',
    {
      'ui-flow-type': 'login',
      ...(disableLoginPasswordPrompt && { 'create-password-prompt': false }),
      ignoreErrorRedirect: true,
    },
    enableOIDCLoginFlow
      ? {
          headers: {
            'x-idp-authentication': true,
          },
        }
      : {},
  )

  return res
}

export async function addUsernameStep(
  { processId },
  { identifier, identifierType, captchaResponse },
  ignoreRedirect = false,
) {
  const data = {
    processId,
    parameters: {
      identifier,
      identifierType,
      captchaResponse,
    },
  }
  const res = await process.step(data, ignoreRedirect)

  return res
}

async function addPasswordStep({ processId }, { password, captchaResponse }) {
  const data = {
    processId,
    parameters: {
      password,
      captchaResponse,
    },
  }

  const res = await process.step(data, true)

  return res
}

async function choose2FAMethod({ processId }, { twoFAMethodOptionId, userId }) {
  const data = { processId, parameters: { twoFAMethodOptionId, userId } }
  return process.step(data, true)
}

async function send2FACode(
  { processId },
  { code, pkat, trustedDevice = false, userId },
) {
  const data = { processId, parameters: { code, pkat, trustedDevice, userId } }
  return process.step(data, true)
}

export async function sendLoginOption(processId, loginOptionId) {
  return process.step({
    processId,
    parameters: { loginOptionId },
  })
}

export async function sendFido2Finish(processId) {
  return process.step({
    processId,
    parameters: { confirm: true },
  })
}

export function twoStepMethodToType(method) {
  if (method === 'TOTP') return twoStepTypes.TOTP
  if (method === 'BUC') return twoStepTypes.BUC
  if (method?.includes('@')) return twoStepTypes.EMAIL
  return twoStepTypes.PHONE
}

export const steps = {
  IdentifierPrompt: 'IdentifierPrompt',
  LoginOptionsPrompt: 'LoginOptionsPrompt',
  MagicLinkStep: 'MagicLinkStep',
  PasswordPrompt: 'PasswordPrompt',
  AccountIdentifierPrompt: 'AccountIdentifierPrompt',
  TwoFASetupMethodPrompt: 'TwoFASetupMethodPrompt',
  TwoFAMethodPrompt: 'TwoFAMethodPrompt',
  TwoFACodePrompt: 'TwoFACodePrompt',
  TotpVerificationPrompt: 'TotpVerificationPrompt',
  UpdatePassword: 'UpdatePassword',
  LoggedIn: 'LoggedIn',
}

export default function SignInV2(props) {
  const pages = {
    UsernameStep: 'UsernameStep',
    LoginOptionsStep: 'LoginOptionsStep',
    MagicLinkStep: 'MagicLinkStep',
    VerifyMagicLinkStep: 'VerifyMagicLinkStep',
    PasswordOrFido2Step: 'PasswordOrFido2Step',
    PasswordStep: 'PasswordStep',
    OtpStep: 'OtpStep',
    SetPasswordStep: 'SetPasswordStep',
    EnforceAccountAssociationStep: 'EnforceAccountAssociationStep',
    AccountAssociationOtpStep: 'AccountAssociationOtpStep',
    TotpVerificationStep: 'TotpVerificationStep',
    TwoStepSetupStep: 'TwoStepSetupStep',
    TwoStepTypeSelect: 'TwoStepTypeSelect',
    QRCodeScanStep: 'QRCodeScanStep',
    TOTPSetupVerificationStep: 'TOTPSetupVerificationStep',
    TwoStepMethod: 'TwoStepMethod',
    TwoStepCode: 'TwoStepCode',
    TwoFAEmailSelectStep: 'TwoFAEmailSelectStep',
    TwoFAEmailVerifyStep: 'TwoFAEmailVerifyStep',
    TwoFAMobileSelectStep: 'TwoFAMobileSelectStep',
    TwoFAMobileVerifyStep: 'TwoFAMobileVerifyStep',
    TwoFASetupSuccessStep: 'TwoFASetupSuccessStep',
    LoggedIn: 'LoggedIn',
  }

  const pageOrder = [
    pages.UsernameStep,
    pages.LoginOptionsStep,
    pages.MagicLinkStep,
    pages.VerifyMagicLinkStep,
    pages.PasswordOrFido2Step,
    pages.PasswordOrFido2Step,
    pages.PasswordStep,
    pages.TotpVerificationStep,
    pages.OtpStep,
    pages.SetPasswordStep,
    pages.EnforceAccountAssociationStep,
    pages.AccountAssociationOtpStep,
    pages.TwoStepSetupStep,
    pages.TwoStepTypeSelect,
    pages.QRCodeScanStep,
    pages.TOTPSetupVerificationStep,
    pages.TwoFAEmailSelectStep,
    pages.TwoFAMobileSelectStep,
    pages.TwoStepMethod,
    pages.TwoStepCode,
    pages.TwoFASetupSuccessStep,
    pages.LoggedIn,
  ]

  const getPageIndex = pageName =>
    findIndex(pageOrder, page => page === pageName)

  const sharedProps = {
    ...props,
    steps,
    pages,
    getPageIndex,
  }
  const pageComponents = {
    [pages.UsernameStep]: (
      <UsernameStep
        startLoginProcess={startLoginProcess}
        addUsernameStep={addUsernameStep}
        {...sharedProps}
      />
    ),
    [pages.LoginOptionsStep]: <LoginOptionsStep {...sharedProps} />,
    [pages.PasswordOrFido2Step]: (
      <PasswordStep addPasswordStep={addPasswordStep} {...sharedProps} />
    ),
    [pages.MagicLinkStep]: <MagicLinkStep {...sharedProps} />,
    [pages.VerifyMagicLinkStep]: <OnboardAuthentication {...sharedProps} />,
    [pages.PasswordStep]: (
      <PasswordStep addPasswordStep={addPasswordStep} {...sharedProps} />
    ),
    [pages.TotpVerificationStep]: (
      <TotpVerificationStep send2FACode={send2FACode} {...sharedProps} />
    ),
    [pages.OtpStep]: <OtpStep verifyCode={verifyCode} {...sharedProps} />,
    [pages.SetPasswordStep]: (
      <SetPasswordStep addPasswordStep={addPasswordStep} {...sharedProps} />
    ),
    [pages.EnforceAccountAssociationStep]: (
      <EnforceAccountAssociationStep {...sharedProps} />
    ),
    [pages.AccountAssociationOtpStep]: (
      <AccountAssociationOtpStep verifyCode={verifyCode} {...sharedProps} />
    ),
    [pages.TwoStepSetupStep]: <TwoStepSetup {...sharedProps} />,
    [pages.TwoStepTypeSelect]: <TwoStepTypeSelect {...sharedProps} />,
    [pages.QRCodeScanStep]: <QRScanStep {...sharedProps} />,
    [pages.TOTPSetupVerificationStep]: (
      <TOTPSetupVerificationStep send2FACode={send2FACode} {...sharedProps} />
    ),
    [pages.TwoStepMethod]: (
      <TwoStepMethod
        choose2FAMethod={choose2FAMethod}
        twoStepMethodToType={twoStepMethodToType}
        twoStepTypes={twoStepTypes}
        {...sharedProps}
      />
    ),
    [pages.TwoStepCode]: (
      <TwoStepCode
        send2FACode={send2FACode}
        twoStepMethodToType={twoStepMethodToType}
        twoStepTypes={twoStepTypes}
        {...sharedProps}
      />
    ),
    [pages.TwoFAEmailSelectStep]: <SelectEmailStep {...sharedProps} />,
    [pages.TwoFAMobileSelectStep]: <SelectMobileStep {...sharedProps} />,
    [pages.TwoFASetupSuccessStep]: <TwoFASetupSuccessStep {...sharedProps} />,
    [pages.LoggedIn]: <LoggedIn />,
  }

  return <Wizard>{pageOrder.map(page => pageComponents[page])}</Wizard>
}
