import Bowser from 'bowser'
import compareVersions from 'compare-versions'

import {
  browsersMinVersion,
  osMinVersions,
  unsupportedBrowserList,
  PLATFORM_TYPES,
} from 'common/constants'

const bowser = Bowser.getParser(window.navigator.userAgent)
const name = bowser.getBrowserName().toLowerCase()
const version = bowser.getBrowserVersion() || '0'
const minVersion = browsersMinVersion[name] || '0'
const platformType = bowser.getPlatformType()
const isDesktop = platformType === PLATFORM_TYPES.DESKTOP
const osName = bowser.getOSName(true)
const minOSVersion = osMinVersions[osName] || '0'
const osVersion = bowser.getOSVersion() || '0'

export const isUnsupportedBrowser = () => {
  if (unsupportedBrowserList.includes(name)) {
    return true
  }

  if (version && version !== '0') {
    return compareVersions(version, minVersion) === -1
  }

  if (osName === 'ios' && osVersion && osVersion !== '0') {
    return compareVersions(osVersion, minOSVersion) === -1
  }

  return false
}

const browserDetails = {
  name,
  version,
  minVersion,
  platformType,
  isDesktop,
}

export default browserDetails
