import React from 'react'

import Box from '@mui/material/Box'
import MuiCircularProgress from '@mui/material/CircularProgress'
import makeStyles from '@mui/styles/makeStyles'

const useStyles = makeStyles({
  top: {
    color: props => props.primary,
  },
  bottom: {
    color: props => props.secondary,
    animation: '$spin 800ms linear infinite',
    position: 'absolute',
    left: 0,
  },
  '@keyframes spin': {
    '0%': { transform: 'rotate(0deg)' },
    '100%': { transform: 'rotate(360deg)' },
  },
})

const CircularProgress = ({
  primary = '#c2c2c2',
  secondary = '#0c5ab5',
  gap = 55,
  ...rest
}) => {
  const classes = useStyles({
    primary,
    secondary,
  })

  return (
    <Box position="relative" display="inline">
      <MuiCircularProgress
        variant="determinate"
        value={100}
        className={classes.top}
        size={24}
        thickness={4}
        {...rest}
      />
      <MuiCircularProgress
        variant="determinate"
        value={gap}
        className={classes.bottom}
        size={24}
        thickness={4}
        {...rest}
      />
    </Box>
  )
}

export default CircularProgress
