import React, { Component } from 'react'
import { connect } from 'react-redux'
import { injectIntl } from 'react-intl'
import { withRouter } from 'react-router-dom'
import { checkToken } from 'common/actions/socialAccounts'
import { endSession } from 'common/actions/session'
import isEmpty from 'lodash/isEmpty'
import {
  getProcessResponse,
  getUser,
  getMembers,
  getGroupName,
} from './selectors'
import ConfirmDetails from './ConfirmDetails'
import JoinTheGroup from './JoinTheGroup'
import ResultInvitation from './ResultInvitation'
import { Button } from 'common/components/button/Button'
import process from 'common/utils/process'
import { resolveErrorEntity } from 'common/utils/processBackendErrors'
import BasicLayout from 'common/layouts/BasicLayout'

const errorContext = 'userConfirmPage'
const ErrorView = ({ error, onError, intl }) => (
  <div
    style={{
      display: 'flex',
      flexDirection: 'column',
    }}
  >
    {resolveErrorEntity({ intl, error: error, context: errorContext })}
    <div className="mt2 self-center">
      <Button onClick={onError}>
        {intl.formatMessage({ id: 'common.button.ok' })}
      </Button>
    </div>
  </div>
)

class UserConfirmPage extends Component {
  state = {
    step: null,
    name: '',
    password: '',
    accept: false,
    busy: false,
    errors: {},
  }

  componentDidMount() {
    const { socialAccounts, hasSession } = this.props
    if (hasSession) {
      this.props.endSession()
    } else {
      if (isEmpty(socialAccounts.checkTokenData)) {
        const query = this.props.location.search

        if (query.includes('token_value=')) {
          this.props.checkToken(query)
        } else {
          this.props.history.push('/')
        }
      }
    }
  }

  componentWillReceiveProps(nextProps) {
    const { socialAccounts, user } = nextProps
    if (!isEmpty(socialAccounts.checkTokenData)) {
      const { name } = user
      this.setState({
        step: 'ConfirmDetails',
        name,
      })
    }
  }

  handleCreatePassword = event => {
    event.preventDefault()
    const { name, password } = this.state
    const { intl } = this.props

    const errors = {}

    if (isEmpty(name)) {
      errors.name = intl.formatMessage({ id: 'error.name' })
    }

    if (isEmpty(password)) {
      errors.password = intl.formatMessage({ id: 'error.credential' })
    }

    if (Object.keys(errors).length) {
      this.setState({ errors })
      return
    }

    const data = {
      ...this.props.processResponse,
      parameters: {
        name,
        password,
      },
    }

    this.setState({ busy: true }, () => {
      process
        .step(data)
        .then(res => {
          this.setState({
            step: 'JoinTheGroup',
            busy: false,
          })
        })
        .catch(err => {
          const errors = { process: err.body }
          this.setState({
            errors,
            busy: false,
          })
        })
    })
  }

  handleInputChange = event => {
    const { name, value } = event.target
    const errors = {}
    this.setState({
      [name]: value,
      errors,
    })
  }

  handleAccept = confirm => {
    const data = {
      ...this.props.processResponse,
      parameters: { confirm },
    }

    this.setState({ busy: true }, () => {
      process
        .step(data)
        .then(res => {
          this.setState({
            step: 'ResultInvitation',
            busy: false,
            accept: confirm === 'yes' ? true : false,
          })
        })
        .catch(err => {
          const errors = { process: err.body }
          this.setState({
            errors,
            busy: false,
          })
        })
    })
  }

  handleMoveTo = event => {
    event.preventDefault()
    const redirectUrl =
      window.location.origin
        .slice()
        .replace('://id.', '://myaccount.')
        .concat('/signin') || ''
    window.location = redirectUrl
  }

  handleError = event => {
    event.preventDefault()
    this.props.history.push('/')
  }

  render() {
    const {
      error,
      isLoading,
      hasFailed,
      checkTokenData,
    } = this.props.socialAccounts
    const { step, name, password, errors, busy, accept } = this.state
    const { members, user, groupName, intl } = this.props

    if (isLoading || busy) {
      return <div>{intl.formatMessage({ id: 'common.loading' })}</div>
    }

    if ((hasFailed && !isEmpty(error)) || !isEmpty(errors.process)) {
      return (
        <ErrorView
          error={error || errors}
          onError={this.handleError}
          intl={intl}
        />
      )
    }

    if (isEmpty(checkTokenData)) {
      return null
    }

    const getMembersAndMoreLength = returnData => {
      const selectedMembers = returnData.slice(0, 4)
      return {
        selectedMembers,
        lengthOfMore: returnData.length - 4,
      }
    }

    const { selectedMembers, lengthOfMore } = getMembersAndMoreLength(members)

    const steps = {
      ConfirmDetails: (
        <ConfirmDetails
          onCreate={this.handleCreatePassword}
          onInputChange={this.handleInputChange}
          firstName={name.split(' ').shift()}
          password={password}
          name={name}
          email={user.email}
          errors={errors}
        />
      ),
      JoinTheGroup: (
        <JoinTheGroup
          members={selectedMembers}
          lengthOfMore={lengthOfMore}
          onAccept={event => {
            event.preventDefault()
            this.handleAccept('yes')
          }}
          onDecline={event => {
            event.preventDefault()
            this.handleAccept('no')
          }}
          groupName={groupName}
        />
      ),
      ResultInvitation: (
        <ResultInvitation
          isAccept={accept}
          groupName={groupName}
          onMove={this.handleMoveTo}
        />
      ),
    }

    return steps[step] || null
  }
}

function PageWrapper(props) {
  return (
    <BasicLayout>
      <UserConfirmPage {...props} />
    </BasicLayout>
  )
}

const mapState = ({ socialAccounts, session }) => ({
  hasSession: session.hasSession,
  socialAccounts,
  processResponse: getProcessResponse(socialAccounts.checkTokenData),
  user: getUser(socialAccounts.checkTokenData),
  members: getMembers(socialAccounts.checkTokenData),
  groupName: getGroupName(socialAccounts.checkTokenData),
})

const mapDispatch = {
  checkToken,
  endSession,
}

export default withRouter(
  connect(mapState, mapDispatch)(injectIntl(PageWrapper)),
)
