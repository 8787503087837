import React from 'react'
import { Box, Grid, Paper, Typography } from '@mui/material'
import { DialogTitle } from '../../../common/components/Typography'
import makeStyles from '@mui/styles/makeStyles'
const useStyles = makeStyles(theme => ({
  textColor: {
    color: theme.palette.text.secondary,
  },
}))

const TwoStepVerificationWrapper = ({ title, subtitle, children }) => {
  const classes = useStyles()

  return (
    <Grid
      item
      xs={12}
      className="q-detailView-lineItem"
      style={{ marginBottom: '24px' }}
    >
      <Paper style={{ padding: '16px' }}>
        <Box display="flex" flexDirection="column">
          <Box mb={2}>
            <DialogTitle mb={1}>{title}</DialogTitle>
            <Typography variant="p" className={classes.textColor}>
              {subtitle}
            </Typography>
          </Box>
          <Box mx="-16px">{children}</Box>
        </Box>
      </Paper>
    </Grid>
  )
}

export default TwoStepVerificationWrapper
