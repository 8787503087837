import React, { useState } from 'react'
import {
  Box,
  Collapse,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from '@mui/material'
import FlowLayout from 'common/layouts/FlowLayout'
import { Button } from 'common/components/button/Button'
import { UserButton } from '@admin-ui-common/base-user'
import { useIntl } from 'react-intl'
import { useForm, Controller } from 'react-hook-form'
import { BackendErrorNotification } from 'common/components/backendErrorNotification/BackendErrorNotification'
import { usePhoneValidator } from 'common/components/hooks/validators'
import { get } from 'lodash'
import { useSelector } from 'react-redux'
import process from 'common/utils/process'

function RadioFormControlLabel({
  discoverables,
  errors,
  selected,
  textFieldProps,
  ...props
}) {
  const fieldErrors = errors[props.value]

  return (
    <FormControl error={fieldErrors}>
      {discoverables.length > 1 ? <FormControlLabel {...props} /> : null}
      <Collapse in={selected}>
        <TextField error={fieldErrors} fullWidth {...textFieldProps} />
        {fieldErrors?.type === 'required' ? (
          <FormHelperText>Required</FormHelperText>
        ) : null}
        {fieldErrors?.type === 'validFormat' ? (
          <FormHelperText>Invalid format</FormHelperText>
        ) : null}
      </Collapse>
    </FormControl>
  )
}

export default function LinkAccount({ response, onLinkAccount }) {
  const intl = useIntl()
  const isPhoneValid = usePhoneValidator()
  const [errorResponseBody, setErrorResponseBody] = useState(null)

  const discoverAccountsBySetting = useSelector(state =>
    get(state, ['settings', 'publicSettings', 'process.discoverAccountsBy']),
  )
  const discoverables = discoverAccountsBySetting.split(',').map(s => s.trim())

  const {
    formState: { isDirty, dirtyFields, errors, isSubmitting },
    register,
    getValues,
    control,
    handleSubmit,
    watch,
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      searchMethod: discoverables[0],
    },
  })

  function onCancel() {
    window.location.reload()
  }

  async function handleFormSubmit(data) {
    const method = get(data, 'searchMethod')
    const value = get(data, [method])

    setErrorResponseBody(null)

    try {
      const stepData = { ...response.body }
      stepData.parameters = { accountIdentifier: value }
      const res = await process.step(stepData)
      const isLastStep = get(res, 'body.lastStep')
      if (isLastStep) {
        const payload = {
          enterOTPEnabled: true,
          accountNumber: value,
          stepData: res.body,
        }
        onLinkAccount(payload)
      } else {
        onLinkAccount({
          stepData: res.body,
        })
      }
    } catch (err) {
      setErrorResponseBody(err.body)
    }
  }

  const searchMethod = watch('searchMethod')

  const validators = {
    mobile: {
      validFormat: str => {
        if (getValues('searchMethod') === 'mobile') return isPhoneValid(str)
        else return true
      },
    },
  }

  const createField = name =>
    register(name, {
      shouldUnregister: true,
      required: {
        value: searchMethod === name,
        message: 'field required',
      },
      validate: get(validators, [name]),
    })

  const cannotSubmitForm =
    isSubmitting ||
    !isDirty ||
    !get(dirtyFields, searchMethod) ||
    Object.keys(errors).length > 0

  return (
    <FlowLayout
      title={intl.formatMessage({ id: 'accounts.link-accounts.button' })}
    >
      <Box mb={4}>
        <Typography gutterBottom>
          {intl.formatMessage({ id: 'accounts.associate.must-be-customer' })}
        </Typography>
        <Typography>
          {intl.formatMessage({ id: 'accounts.search-accounts' })}
        </Typography>
      </Box>
      <Box component="form" onSubmit={handleSubmit(handleFormSubmit)}>
        <Box>
          {discoverables.length > 1 ? (
            <Typography color="textSecondary" variant="subtitle2">
              {intl.formatMessage({ id: 'accounts.search-method' })}
            </Typography>
          ) : null}
          <Controller
            control={control}
            name="searchMethod"
            rules={{
              required: true,
            }}
            render={({ field }) => {
              return (
                <RadioGroup {...field}>
                  {discoverables.map(discoverable => {
                    return (
                      <RadioFormControlLabel
                        discoverables={discoverables}
                        errors={errors}
                        selected={searchMethod === discoverable}
                        value={discoverable}
                        control={<Radio color="primary" />}
                        textFieldProps={{
                          ...createField(discoverable),
                          label: intl.formatMessage({
                            id: `accounts.discovery-type.${discoverable}.placeholder`,
                          }),
                        }}
                        label={intl.formatMessage({
                          id: `accounts.discovery-type.${discoverable}`,
                        })}
                      />
                    )
                  })}
                </RadioGroup>
              )
            }}
          />
        </Box>

        <Collapse in={!!errorResponseBody}>
          <Box my={2}>
            <BackendErrorNotification
              intl={intl}
              error={errorResponseBody}
              hideClose
            />
          </Box>
        </Collapse>

        <Box mt={4} display="flex" gap={4}>
          <Button
            disabled={cannotSubmitForm}
            type="submit"
            variant="contained"
            color="primary"
            size="large"
          >
            {intl.formatMessage({ id: 'common.button.continue' })}
          </Button>
          <UserButton variant="outlined" size="large" onClick={onCancel}>
            {intl.formatMessage({ id: 'common.button.cancel' })}
          </UserButton>
        </Box>
      </Box>
    </FlowLayout>
  )
}
