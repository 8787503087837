const { useReducer, useCallback } = require('react')

const actions = {
  UPDATE_RECAPTCHA: 'UPDATE_RECAPTCHA',
  INCREMENT_RENDERING_KEY: 'INCREMENT_RENDERING_KEY',
}

function stateReducer(state, action) {
  switch (action.type) {
    case actions.UPDATE_RECAPTCHA:
      return { ...state, recaptcha: action.payload }
    case actions.INCREMENT_RENDERING_KEY:
      return {
        ...state,
        recaptchaForceRerenderingKey: state.recaptchaForceRerenderingKey + 1,
      }
    default:
      return state
  }
}

const initialState = {
  recaptcha: null,
  captchaRequired: true,
  recaptchaForceRerenderingKey: 0,
}

export default function useRecaptcha() {
  const [state, dispatch] = useReducer(stateReducer, initialState)

  const resetRecaptcha = useCallback(function resetRecaptcha(ref) {
    if (!ref || !ref.current) {
      return
    }

    const recaptchaDom = ref?.current?.captcha

    while (recaptchaDom?.current?.firstChild) {
      recaptchaDom.removeChild(recaptchaDom?.current?.firstChild)
    }

    ref.reset()
  }, [])

  function toggleRecaptcha() {
    dispatch({ type: actions.INCREMENT_RENDERING_KEY })
  }

  function onChangeRecaptcha(recaptcha) {
    dispatch({ type: actions.UPDATE_RECAPTCHA, payload: recaptcha })
  }

  return {
    ...state,
    resetRecaptcha,
    toggleRecaptcha,
    onChangeRecaptcha,
  }
}
