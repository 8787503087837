import { usePhoneValidator, useEmailValidator } from '../hooks/validators'

const withValidators = WrappedComponent => props => {
  const isValidEmail = useEmailValidator()
  const isValidPhone = usePhoneValidator()

  return (
    <WrappedComponent
      {...props}
      isValidEmail={isValidEmail}
      isValidPhone={isValidPhone}
    />
  )
}

export default withValidators
