import React, { useEffect, useState, useCallback } from 'react'
import { useIntl } from 'react-intl'
import { get } from 'lodash'

import { useLocation, useHistory } from 'react-router-dom'
import { Box } from '@mui/material'
import { Button } from 'common/components/button/Button'
import process from 'common/utils/process'

import i from 'common/utils/i'
import conf from 'conf'
import { hasUIFeatureFlag } from 'common/services/FeatureFlagsService'

import useMainRedirect from 'common/components/hooks/useMainRedirect'
import {
  getErrorCodes,
  resolveErrorEntity,
} from 'common/utils/processBackendErrors'
import FlowLayout from 'common/layouts/FlowLayout'
import { Body } from 'common/components/Typography'
import CircularProgress from 'common/components/CircularProgress'
import CheckMarkIcon from 'common/components/CheckMarkIcon'
import CrossIcon from 'common/components/CrossIcon'

export default function OnboardAuthenticate({
  wizard,
  steps,
  getPageIndex,
  pages,
}) {
  const location = useLocation()
  const history = useHistory()
  const intl = useIntl()
  const mainRedirect = useMainRedirect()

  const [inPrgress, setInProgress] = useState(false)
  const [success, setSuccess] = useState()
  const [error, setError] = useState('')
  const [responseData, setResponseData] = useState({})
  const searchParams = new URLSearchParams(location.search.split('?')[1])
  const token_value = searchParams.get('token_value')

  const verifyToken = useCallback(async () => {
    setInProgress(true)
    try {
      const enableOIDCLoginFlow = hasUIFeatureFlag('enableOIDCLoginFlow')
      const url = `${conf.apiRoot}/session/token?value=${token_value}`
      const res = await i.get(url, {
        ignoreErrorRedirect: true,
        ...(enableOIDCLoginFlow
          ? { headers: { 'x-idp-authentication': true } }
          : {}),
      })
      setResponseData(res)
      setSuccess(true)
      setInProgress(false)
    } catch (error) {
      const errorCodes = getErrorCodes(error.body)
      if (errorCodes.includes('invalid-act-consumption')) {
        setError(
          intl.formatMessage({
            id: 'error.invalid-act-consumption.title.context.magicLink',
          }),
        )
      } else if (errorCodes.includes('action-token-expired')) {
        setError(
          intl.formatMessage({
            id: 'error.action-token-expired.title.context.magicLink',
          }),
        )
      } else {
        setError(resolveErrorEntity({ intl, error: error.body }))
      }
      setSuccess(false)
      setInProgress(false)
    }
  }, [token_value])

  useEffect(() => {
    if (token_value) {
      verifyToken()
    }
  }, [token_value])

  const handleContinueClicked = async e => {
    e.preventDefault()
    let metadata = responseData?.metadata
    let stepData = responseData?.body
    let stepName = responseData?.body.stepName
    if (stepName === steps.PasswordPrompt) {
      const response = await process
        .step({ processId: stepData.processId })
        .then(res => res)
      stepData = response?.body
      metadata = response?.metadata
      stepName = response?.body.stepName
    }

    if (stepName === 'AccountIdentifierPrompt') {
      wizard.setPageState(stepData)
      wizard.toPage(getPageIndex(pages.EnforceAccountAssociationStep))
      return
    }

    if (stepName === steps.TwoFASetupMethodPrompt) {
      wizard.setPageState(stepData)
      wizard.toPage(getPageIndex(pages.TwoStepSetupStep))
      return
    }

    if (stepName === steps.TwoFACodePrompt) {
      wizard.setPageState(stepData)
      wizard.toPage(getPageIndex(pages.TwoStepCode))
      return
    }

    if (stepName === steps.TwoFAMethodPrompt) {
      wizard.setPageState(stepData)
      wizard.toPage(getPageIndex(pages.TwoStepMethod))
      return
    }

    if (stepName === steps.TotpVerificationPrompt) {
      wizard.setPageState(stepData)
      wizard.toPage(getPageIndex(pages.TotpVerificationStep))
      return
    }

    if (stepName === steps.UpdatePassword) {
      history.push({
        pathname: '/password_expire',
        state: stepData,
      })
    }

    if (get(stepData, 'userAuthenticated')) {
      const isStaticRedirect = mainRedirect(metadata)
      if (!isStaticRedirect) {
        return
      }
      wizard.toPage(getPageIndex(pages.LoggedIn))
    }
  }

  const handleStartOver = () => {
    history.push('/')
  }

  if (inPrgress) {
    return <CircularProgress />
  }

  return (
    <FlowLayout
      title={intl.formatMessage({
        id: success
          ? 'separatedMultiStep.authenticate-magicLink-verified-title'
          : 'separatedMultiStep.authenticate-magicLink-verified-failed-title',
      })}
      image={
        success ? (
          <CheckMarkIcon marginBottom={0} />
        ) : (
          <CrossIcon marginBottom={0} />
        )
      }
    >
      <Body>
        {success &&
          intl.formatMessage({
            id:
              'separatedMultiStep.authenticate-magicLink-verified-description',
          })}
        {error ?? null}
      </Body>
      <Box mt={4}>
        <Button
          size="large"
          onClick={success ? handleContinueClicked : handleStartOver}
        >
          {intl.formatMessage({
            id: success ? 'common.button.continue' : 'common.button.start-over',
          })}
        </Button>
      </Box>
    </FlowLayout>
  )
}
