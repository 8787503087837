import React, { useState, useCallback, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { get, identity } from 'lodash'
import { FormattedHTMLMessage } from 'react-intl'
import { useIntl } from 'react-intl'

import { resolveErrorEntity } from 'common/utils/processBackendErrors'
import { addNotification } from 'common/actions/notification'
import Box from '@mui/material/Box'

import ResendCode from 'common/components/ResendCode'
import * as UXPDialog from 'common/components/dialog/UXPDialog'
import TextField from 'common/components/textfield/TextField'
import { TextSecondaryButton, Button } from 'common/components/button/Button'
import { loadUser } from 'common/actions/user'
import Page from 'common/components/Page'

import {
  registerMFAVerificationStep,
  clearEmailPhone,
} from 'common/actions/twoStepVerification'
import { analyticsProvider } from '../../../../App'
import { isEmpty } from 'lodash'
import { OTP_PROMPT } from '../../../../common/constants/twoStepVerification'

const errorContext = 'verifyEmailOrMobile.VerificationCode'

const SelectIdentityDialog = ({ onClose, open, flowType }) => {
  const dispatch = useDispatch()
  const OTPPromptState = useSelector(
    state => state.registerMFA.emailPhone.OTPPrompt,
  )
  const selectedIdentity = useSelector(
    state => state.registerMFA.emailPhone.selectedIdentity,
  )
  const [usernameType, setUsernameType] = useState('')
  const [process, setProcess] = useState('')
  const [username, setUsername] = useState('')
  const [code, setCode] = useState('')
  const [error, setError] = useState('')
  const [pkat, setPkat] = useState('')
  const [inProgress, setInProgress] = useState(false)
  const intl = useIntl()

  const pageTitle = 'verify_code'
  const baseEventTag = `${flowType}.${pageTitle}`

  useEffect(() => {
    if (!isEmpty(OTPPromptState)) {
      const { processId, pkat } = OTPPromptState
      setProcess(processId)
      setPkat(pkat)
    }

    if (!isEmpty(selectedIdentity)) {
      setUsernameType(selectedIdentity[0]['type'].toLowerCase())
      setUsername(selectedIdentity[0]['value'])
    }
  }, [OTPPromptState, selectedIdentity])

  const titleType = usernameType === 'sms' ? 'mobile number' : 'email'

  const onNext = () => {
    analyticsProvider.sendAnalytics({
      type: 'event',
      action: 'click',
      event_category: 'button',
      event_label: `${baseEventTag}.verify_otp`,
      value: 0,
    })
    if (!code) {
      setError({
        message: intl.formatMessage({
          id: 'onboard.common.enter-your-code',
        }),
      })
      return
    }

    setInProgress(true)
    const payload = {
      processId: process,
      parameters: {
        pkat: pkat,
        otp: code,
      },
    }

    registerMFAVerificationStep(payload)(dispatch)
      .then(() => {
        analyticsProvider.sendAnalytics({
          type: 'event',
          action: 'click',
          event_category: 'button',
          event_label: `${baseEventTag}.otp_verified`,
          value: 0,
        })
        dispatch(
          addNotification({
            message: intl.formatMessage(
              {
                id: 'user.management.register.mobile-or-email-success-message',
              },
              {
                username: titleType,
              },
            ),
            variant: 'success',
          }),
        )
        onClose(OTP_PROMPT)
        setCode('')
        dispatch(loadUser())
        dispatch(clearEmailPhone())
      })
      .catch(error => {
        setError({
          message: resolveErrorEntity({ intl, error: error.body }),
        })
      })
  }

  const closeView = () => {
    analyticsProvider.sendAnalytics({
      type: 'event',
      action: 'click',
      event_category: 'button',
      event_label: `${baseEventTag}.close`,
      value: 0,
    })
    setCode('')
    onClose(OTP_PROMPT)
  }

  const handleResendError = useCallback(
    resendErrorMessage => {
      analyticsProvider.sendAnalytics({
        type: 'event',
        action: 'click',
        event_category: 'button',
        event_label: `${baseEventTag}.resend_code`,
        value: 1,
      })
      analyticsProvider.sendAnalytics({
        error: resendErrorMessage,
      })
      setError(error => ({
        ...error,
        common: resendErrorMessage,
      }))
    },
    [baseEventTag],
  )

  const onResetError = useCallback(() => {
    setError({})
  }, [])

  const onResendFinish = newPkat => {
    analyticsProvider.sendAnalytics({
      type: 'event',
      action: 'click',
      event_category: 'button',
      event_label: `${baseEventTag}.resend_code`,
      value: 0,
    })
    setPkat(newPkat)
  }

  return (
    <UXPDialog.Dialog open={open} fullScreen fullWidth maxWidth="sm">
      <UXPDialog.Title
        title={intl.formatMessage(
          {
            id: 'user.management.register.mobile-or-email-enter-code-title',
          },
          { titleType },
        )}
        onClose={closeView}
      />
      <UXPDialog.Content>
        {usernameType && (
          <Box mb={3}>
            <Page.Description>
              <FormattedHTMLMessage
                id={`user.management.register.mobile-or-email-enter-code-${usernameType}-message`}
                values={{ username }}
              />
            </Page.Description>
          </Box>
        )}
        <TextField
          label={intl.formatMessage({ id: 'common.enter-code' })}
          onChange={e => setCode(e.target.value)}
          value={code}
          errorText={get(error, 'message')}
          autoComplete="one-time-code"
          numeric
        />
        <ResendCode
          pkat={pkat}
          tokenId={''}
          onResendError={handleResendError}
          errorContext={errorContext}
          onResendStart={onResetError}
          onResendFinish={onResendFinish}
          style={{ marginTop: '4px' }}
        />
      </UXPDialog.Content>
      <UXPDialog.Actions disabled={inProgress}>
        <TextSecondaryButton
          onClick={closeView}
          size="small"
          variant="outlined"
        >
          {intl.formatMessage({ id: 'common.button.cancel' })}
        </TextSecondaryButton>
        <Button onClick={onNext} disabled={!identity} size="small">
          {intl.formatMessage({ id: 'common.button.verify' })}
        </Button>
      </UXPDialog.Actions>
    </UXPDialog.Dialog>
  )
}

export default SelectIdentityDialog
