import React from 'react'

import withStyles from '@mui/styles/withStyles'
import Hidden from '@mui/material/Hidden'
import Paper from '@mui/material/Paper'
import Box from '@mui/material/Box'

import { hasUIFeatureFlag } from 'common/services/FeatureFlagsService'
import getLogoImage from '../utils/getLogoImage'
import logo from '../assets/logos/logo-attmx.svg'

import { connect } from 'react-redux'
import { compose } from 'redux'

const stylesDefault = theme => ({
  root: {
    [theme.breakpoints.down('sm')]: {
      height: '100%',
      width: '100vw',
      minWidth: '100%',
    },
    minWidth: 500,
    maxWidth: 560,
    width: '100%',
  },
})

const stylesWizardRoutesOnly = theme => ({
  root: {
    [theme.breakpoints.down('sm')]: {
      borderRadius: '0px',
      width: '100%',
    },
    width: 500,
  },
})

const styles = (theme, enableOnlyWizardRoutes) =>
  enableOnlyWizardRoutes ? stylesWizardRoutesOnly(theme) : stylesDefault(theme)
class RwdPaper extends React.Component {
  render() {
    const {
      classes,
      hideLogo,
      className,
      children,
      endContent = null,
      logoUrl,
      ...rest
    } = this.props
    const showOperatorLogo = hasUIFeatureFlag('showOperatorLogo')

    return (
      <Box
        flex={{ sm: 1 }}
        margin={{ sm: '24px auto' }}
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
      >
        <Paper classes={classes} {...rest} className={className}>
          {!hideLogo && (
            <Hidden xsUp>
              <Box display="flex" p={2} justifyContent="space-between">
                <Box
                  component="img"
                  src={getLogoImage(logoUrl)}
                  alt="logo"
                  height={36}
                />
                {showOperatorLogo && (
                  <Box component="img" src={logo} alt="logo" height={36} />
                )}
              </Box>
            </Hidden>
          )}
          {children}
        </Paper>
        {endContent}
      </Box>
    )
  }
}

const mapState = state => ({
  enableOnlyWizardRoutes: state.configuration.config.enableOnlyWizardRoutes,
})

const connectedProps = connect(mapState, null)

export default compose(connectedProps, withStyles(styles))(RwdPaper)
