import React, { useState } from 'react'
import { withRouter } from 'react-router-dom'
import createStyles from '@mui/styles/createStyles'
import makeStyles from '@mui/styles/makeStyles'

import styled from 'styled-components'

import ArrowForwardRounded from '@mui/icons-material/ArrowForwardRounded'

import { List } from '@admin-ui-common/base-user'
import { analyticsProvider } from '../../App'

import getSocialProviderLogo from '../../common/utils/getSocialProviderLogo'
import Avatar from '@mui/material/Avatar'
import {
  associateSocialMedia,
  dissociateSocialMedia,
} from '../../common/processes/socialMedia'
import { get } from 'lodash'
import getSocialProviderName from '../../common/utils/getSocialProviderName'
import { loadUser } from '../../common/actions/user'
import { addNotification } from '../../common/actions/notification'
import { useIntl } from 'react-intl'
import { useDispatch } from 'react-redux'
import { Button } from '../../common/components/button/Button'
import { resolveErrorEntity } from 'common/utils/processBackendErrors'
import {
  getRedirectLocation,
  performRedirect,
} from 'common/utils/getRedirectUrl'

const objectLowerCase = source =>
  Object.assign(
    ...Object.keys(source).map(key => ({ [key.toLowerCase()]: source[key] })),
  )

const useStyles = makeStyles(theme =>
  createStyles({
    root: {
      paddingTop: '16px',
      paddingBottom: '16px',
    },
    icon: {
      color: theme.palette.text.secondary,
      cursor: 'pointer',
    },
    body1: {
      color: theme.palette.text.secondary,
    },
    avatar: {
      padding: '8px',
      backgroundColor: props =>
        objectLowerCase(theme.palette.social)[props.providerId.toLowerCase()],
    },
  }),
)

const onConnect = (baseEventTag, providerId, setInProgress) => {
  analyticsProvider.sendAnalytics({
    type: 'event',
    action: 'click',
    event_category: 'button',
    event_label: `${baseEventTag}.connect`,
    value: 0,
  })
  setInProgress(true)
  associateSocialMedia(providerId)
    .then(res => {
      const requestUrl = get(res, 'body.output.requestUrl')
      const xRedirect = getRedirectLocation(res.metadata)
      const socialRedirectUrl = requestUrl || xRedirect

      if (socialRedirectUrl) {
        performRedirect(socialRedirectUrl)
      }
    })
    .catch(err => {
      analyticsProvider.sendAnalytics({
        type: 'event',
        action: 'click',
        event_category: 'button',
        event_label: `${baseEventTag}.connect`,
        value: 1,
      })
      console.error(err)
    })
    .finally(() => {
      setInProgress(false)
    })
}

const onDisconnect = async (
  baseEventTag,
  providerId,
  intl,
  dispatch,
  setInProgress,
  showError,
) => {
  analyticsProvider.sendAnalytics({
    type: 'event',
    action: 'click',
    event_category: 'button',
    event_label: `${baseEventTag}.disconnect`,
    value: 0,
  })
  const socialAccountType = getSocialProviderName(providerId)
  setInProgress(true)
  try {
    await dissociateSocialMedia(providerId)
    await loadUser()(dispatch).then(() => {
      setInProgress(false)
      dispatch(
        addNotification({
          message: intl.formatMessage(
            { id: 'notification.account-disconnected' },
            { socialAccountType },
          ),
          variant: 'success',
        }),
      )
    })
  } catch (err) {
    analyticsProvider.sendAnalytics({
      type: 'event',
      action: 'click',
      event_category: 'button',
      event_label: `${baseEventTag}.disconnect`,
      value: 1,
    })

    const message = resolveErrorEntity({ intl, error: get(err, 'body') })
    setInProgress(false)
    showError(message)
  }
}

const SocialListItem = ({
  name,
  providerId,
  value,
  placeholder,
  path,
  history,
  interactive,
  icon,
  setShowDialog,
  setDialogTitle,
  setDialogContent,
}) => {
  const intl = useIntl()

  const dispatch = useDispatch()

  const [inProgress, setInProgress] = useState(false)

  const classes = useStyles({ providerId: providerId })
  const showDetail = () => {
    analyticsProvider.sendAnalytics({
      type: 'event',
      action: 'click',
      event_category: 'button',
      event_label: `${baseEventTag}.show-detail`,
      value: 0,
    })
    history.push(path)
  }

  const flowType = 'socialItem'
  const baseEventTag = `${flowType}.${name}`

  const [secondaryItemWidth, setSecondaryItemWidth] = React.useState(0)

  const secondaryItemRef = React.useCallback(node => {
    if (node !== null) {
      const width = node.getBoundingClientRect().width
      setSecondaryItemWidth(width)
    }
  }, [])

  const StyledListItem = styled(List.ListItem)`
    & > .MuiListItemAvatar-root {
      ${props => !props.icon && `width: 160px;`}
    }
    ${props => {
      return (
        props.buttonText &&
        `&&.MuiListItem-secondaryAction {
                padding-right: calc(32px + ${secondaryItemWidth}px);
              }`
      )
    }}
  `
  const buttonText = value ? 'Disconnect' : 'Connect'

  const providerLogo = getSocialProviderLogo(name.toLowerCase())

  const showDialog = err => {
    setDialogTitle(intl.formatMessage({ id: 'error.social.disconnect-failed' }))
    setDialogContent(err)
    setShowDialog(true)
  }

  return (
    <StyledListItem
      className={`q-profileItem-lineItem ${classes.root}`}
      onClick={interactive && showDetail}
      button={interactive}
      buttonText
      icon
      secondaryItemWidth={secondaryItemWidth}
    >
      {icon && (
        <List.ListItemAvatar>
          <Avatar
            className={classes.avatar}
            variant="square"
            providerId={name}
            src={providerLogo}
          />
        </List.ListItemAvatar>
      )}
      <List.ListItemText primary={name} secondary={value || placeholder} />

      <List.ListItemSecondaryAction>
        {buttonText ? (
          <Button
            variant={value ? 'text' : 'outlined'}
            size="small"
            color="primary"
            aria-label={`View ${name} details`}
            onClick={
              value
                ? () =>
                    onDisconnect(
                      baseEventTag,
                      providerId,
                      intl,
                      dispatch,
                      setInProgress,
                      err => {
                        showDialog(err)
                      },
                    )
                : () => onConnect(baseEventTag, providerId, setInProgress)
            }
            forwardedRef={secondaryItemRef}
            isLoading={inProgress}
          >
            {buttonText}
          </Button>
        ) : (
          <ArrowForwardRounded
            className={classes.icon}
            aria-label={`View ${name} details`}
            onClick={showDetail}
          />
        )}
      </List.ListItemSecondaryAction>
    </StyledListItem>
  )
}

export default withRouter(SocialListItem)
