import formatMobile from 'common/utils/formatMobile'

import EmailView from './detailview/EmailDetailView'
import MobileView from './detailview/MobileDetailView'
import DisplayNameView from './detailview/DisplayNameDetailView'
import FullNameView from './detailview/FullNameDetailView'
import PreferredContactView from './detailview/PreferredContactDetailView'
import LanguageView from './detailview/LanguageView'

export const profileDetailData = {
  DISPLAY_NAME: {
    label: 'profile.label.displayName',
    path: '/display_name',
    placeholder: 'profile.placeholder.name',
    view: DisplayNameView,
    featureFlag: 'showDisplayNameField',
  },
  FULL_NAME: {
    label: 'profile.label.fullName',
    path: '/name',
    placeholder: 'profile.placeholder.fullName',
    view: FullNameView,
    featureFlag: 'showFullNameField',
  },
  EMAIL: {
    label: 'profile.label.email',
    path: '/email',
    placeholder: 'profile.placeholder.email',
    view: EmailView,
    featureFlag: 'showEmailField',
  },
  MOBILE_NUMBER: {
    label: 'profile.label.mobile-number',
    path: '/mobile_number',
    placeholder: 'profile.placeholder.mobile-number',
    view: MobileView,
    featureFlag: 'showMobileNumberField',
  },
  LANGUAGE: {
    label: 'profile.label.language',
    path: '/change_lanuage',
    placeholder: 'sign-in.label.language',
    view: LanguageView,
    featureFlag: 'showLanguageSelector',
  },
  PREFERRED_CONTACT: {
    label: 'profile.label.preferred-contact',
    path: '/preferred_contact',
    placeholder: 'profile.placeholder.preferred-contact',
    view: PreferredContactView,
    featureFlag: 'showPreferredContactField',
  },
}

export const labelFormatter = {
  email: email => email,
  mobile: (mobile, appId) => formatMobile(mobile, appId),
}
