import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { injectIntl } from 'react-intl'

import VerifyUsername from '../signup/VerifyUsername'
import { analyticsProvider } from '../../App'

class Confirmation extends React.Component {
  handleVerified = (_, { body }) => {
    const { wizard } = this.props
    const { processId, userAuthenticated } = body
    wizard.setPageState({
      ...wizard.getPageState(),
      processId,
      userAuthenticated,
    })
    wizard.toNextPage()
  }

  resetStep = () => {
    this.props.history.push('/')
  }

  recoveryOptionChanged = () => {
    const { usernameType, usernameEntered } = this.props.wizard.getPageState()
    const usernameEnteredType = usernameEntered.includes('@')
      ? 'email'
      : 'phone'

    return usernameEnteredType !== usernameType
  }

  getMessageID = () => {
    const { usernameType } = this.props.wizard.getPageState()

    return this.recoveryOptionChanged()
      ? `forgot-password.verify-username.${usernameType}.forced.message`
      : `forgot-password.verify-username.${usernameType}.message`
  }

  constructor(props) {
    super(props)

    this.pageTitle = 'confirmation'
    this.baseEventTag = `forgot.${this.pageTitle}`
  }

  componentDidMount() {
    analyticsProvider.sendAnalytics({
      type: 'page_view',
      page_title: this.pageTitle,
      page_path: `/forgot#${this.pageTitle}`,
    })
  }

  render() {
    const { wizard, history } = this.props
    const {
      username,
      pkat,
      tokenId,
      usernameType,
      isoCode,
    } = wizard.getPageState()

    return (
      <VerifyUsername
        reset={this.resetStep}
        process="forgot-password"
        username={username}
        type={usernameType}
        actionName="passworRecovery"
        pkat={pkat}
        tokenId={tokenId}
        onVerified={this.handleVerified}
        forcedUsername={this.recoveryOptionChanged()}
        wizard={wizard}
        isoCode={isoCode}
        history={history}
      />
    )
  }
}

function PageWrapper(props) {
  return <Confirmation {...props} />
}

const mapStateToProps = () => ({})
const mapDispatchToProps = {}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(injectIntl(PageWrapper)))
