import React, { useReducer } from 'react'
import { useIntl } from 'react-intl'
import { Box } from '@mui/material'
import FlowLayout from 'common/layouts/FlowLayout'
import { UserButton } from '@admin-ui-common/base-user'
import ResendCode from 'common/components/ResendCode'
import { get, has } from 'lodash'
import { getErrorCodes } from 'common/utils/processBackendErrors'
import Page from '../../common/components/Page'
import makeStyles from '@mui/styles/makeStyles'
import { Subtitle } from '../../common/components/Typography'
import { useHistory } from 'react-router-dom'
import TextField from '../../common/components/textfield/TextField'
import useMainRedirect from 'common/components/hooks/useMainRedirect'

const useStyles = makeStyles(() => ({
  accountNumber: {
    fontWeight: 'bold',
  },
}))

export const actions = {
  USER_ENTERED_CODE_FOR_FIRST_TIME: 'USER_ENTERED_CODE_FOR_FIRST_TIME',
  USER_FIXED_CODE: 'USER_FIXED_CODE',
  GOT_NEW_PKAT: 'GOT_NEW_PKAT',
  SENT_INVALID_CODE: 'SENT_INVALID_CODE',
  GOT_INVALID_CODE: 'GOT_INVALID_CODE',
}

function otpStepReducer(state, action) {
  switch (action.type) {
    case actions.GOT_NEW_PKAT:
      return { ...state, pkat: action.payload }
    case actions.GOT_INVALID_CODE:
      return {
        ...state,
        errors: action.payload.errors,
        userHasEnteredCode: false,
      }
    case actions.SENT_INVALID_CODE:
      return {
        ...state,
        errors: action.payload.errors,
        userHasEnteredCode: false,
      }
    case actions.USER_FIXED_CODE:
      return { ...state, errors: {}, userHasEnteredCode: false }
    case actions.USER_ENTERED_CODE_FOR_FIRST_TIME:
      return { ...state, userHasEnteredCode: true }
    default:
      return state
  }
}

const codeValidationStatus = {
  PENDING: 'PENDING',
  SENDING: 'SENDING',
  SUCCEEDED: 'SUCCEEDED',
  ERROR_SENT_INVALID: 'ERROR_SENT_INVALID',
  ERROR_GENERIC: 'ERROR_GENERIC',
}

export default function OtpStep({
  pages,
  getPageIndex,
  wizard,
  verifyCode,
  preserveQueryArguments = true,
}) {
  const history = useHistory()
  const mainRedirect = useMainRedirect()

  const onCancel = () => {
    let signInRoute = '/signin'

    if (preserveQueryArguments)
      signInRoute = signInRoute + history.location.search

    history.push(signInRoute)
  }

  const classes = useStyles()

  const intl = useIntl()
  const wizardState = wizard.getPageState()

  const { accountNumber } = wizardState

  const otpStepIntialState = {
    pkat: get(wizardState, 'stepData.output.pkat'),
    codeValidationStatus: codeValidationStatus.PENDING,
    userHasEnteredCode: false,
    errors: {},
  }

  const [state, dispatch] = useReducer(otpStepReducer, otpStepIntialState)

  function gotNewPkat(newPkat) {
    dispatch({ type: actions.GOT_NEW_PKAT, payload: newPkat })
  }

  async function handleSubmit(e) {
    e.preventDefault()
    const formData = new FormData(e.target)
    const code = formData.get('code')

    try {
      const res = await verifyCode(code, state.pkat)
      if (get(res, 'body.userAuthenticated') === true) {
        const isStaticRedirect = mainRedirect(res.metadata)
        if (!isStaticRedirect) {
          return
        }
        wizard.toPage(getPageIndex(pages.LoggedIn))
      }
    } catch (err) {
      const errorCodes = getErrorCodes(err.body)
      if (
        errorCodes.includes('invalid-act-consumption') ||
        errorCodes.includes('A token value or a custom token is required.')
      ) {
        dispatch({
          type: actions.SENT_INVALID_CODE,
          payload: {
            errors: { code: intl.formatMessage({ id: 'errors.invalid-code' }) },
          },
        })
      }
    }
  }

  function validateCode(e) {
    const code = e.target.value.trim()

    let newErrors = {}
    if (code.length === 0) {
      newErrors.code = intl.formatMessage({ id: 'error.invalid-code' })
    }

    const previousStateHadErrors = Object.keys(state.errors).length > 0
    const hasErrorsNow = Object.keys(newErrors).length > 0

    if (!state.userHasEnteredCode && code.length > 0) {
      dispatch({ type: actions.USER_ENTERED_CODE_FOR_FIRST_TIME })
    }
    if (!state.userHasEnteredCode && previousStateHadErrors && !hasErrorsNow) {
      dispatch({ type: actions.USER_FIXED_CODE })
    }

    if (hasErrorsNow) {
      dispatch({
        type: actions.GOT_INVALID_CODE,
        payload: { errors: newErrors },
      })
    }
  }

  const cantSubmit =
    !state.userHasEnteredCode ||
    state.codeValidationStatus === codeValidationStatus.SENDING ||
    has(state, 'errors.code')

  const sendingCode =
    state.codeValidationStatus === codeValidationStatus.SENDING

  const accountNumberPrefix = intl.formatMessage({
    id: 'accounts.enter-otp.accounts-prefix',
  })

  return (
    <FlowLayout title={intl.formatMessage({ id: 'common.enter-code' })}>
      <Box mb={2}>
        <Subtitle>{accountNumberPrefix + ' ' + accountNumber}</Subtitle>
      </Box>

      <Box mb={1}>
        <Page.Description>
          {intl.formatMessage(
            { id: 'accounts.enter-otp.description-1' },
            {
              accountNumber: (
                <span className={classes.accountNumber}>{accountNumber}</span>
              ),
            },
          )}
        </Page.Description>
      </Box>

      <Box mb={3}>
        <Page.Description>
          {intl.formatMessage({ id: 'accounts.enter-otp.description-2' })}
        </Page.Description>
      </Box>
      <form onSubmit={handleSubmit}>
        <Box mt={3}>
          <TextField
            inputMode="numeric"
            aria-required
            fullWidth
            error={has(state, 'errors.code')}
            errorText={
              has(state, 'errors.code')
                ? intl.formatMessage({ id: 'error.invalid-code' })
                : null
            }
            id="code"
            name="code"
            onBlur={validateCode}
            onChange={validateCode}
            autoFocus
            label={intl.formatMessage({ id: 'common.enter-code' })}
          />
        </Box>
        <Box mt={0.5} mb={3.5}>
          <ResendCode
            autoFocus
            tokenId=""
            pkat={state.pkat}
            onResendFinish={gotNewPkat}
          />
        </Box>
        <UserButton
          isLoading={sendingCode}
          disabled={cantSubmit}
          type="submit"
          size="large"
        >
          {intl.formatMessage({ id: 'accounts.link-account' })}
        </UserButton>
        <Box display="inline-block" marginLeft={3}>
          <UserButton size="large" variant="outlined" onClick={onCancel}>
            {intl.formatMessage({ id: 'common.button.cancel' })}
          </UserButton>
        </Box>
      </form>
    </FlowLayout>
  )
}
