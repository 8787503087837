import i from '../utils/i'

export const LOAD_PURCHASE_RESPONSE = 'LOAD_PURCHASE_RESPONSE'
const loadPurchaseResponse = response => ({
  type: LOAD_PURCHASE_RESPONSE,
  response,
})

export const LOAD_PURCHASE_ERROR = 'LOAD_PURCHASE_ERROR'
const loadPurchaseError = error => ({
  type: LOAD_PURCHASE_ERROR,
  error,
})

export const purchaseSubscription = payload => dispatch => {
  const url = `/storefront/subscription/purchase`

  return i
    .post(url, { data: payload })
    .then(({ body }) => dispatch(loadPurchaseResponse(body)))
    .catch(error => dispatch(loadPurchaseError(error)))
}
