import React, { useState } from 'react'
import { useIntl } from 'react-intl'
import { useDispatch } from 'react-redux'
import conf from 'conf'
import i from 'common/utils/i'

import FlowLayout from 'common/layouts/FlowLayout'
import ActionLabel from 'common/components/ActionLabel'
import { addNotification } from 'common/actions/notification'
import { resolveErrorEntity } from 'common/utils/processBackendErrors'

export default function MagicLinkStep({ wizard }) {
  const wizardState = wizard.getPageState()
  const [process, setProcess] = useState(false)
  const [pkat, setPkat] = useState(wizardState?.pkat)
  const intl = useIntl()
  const dispatch = useDispatch()

  const handleResendLink = async () => {
    setProcess(true)
    try {
      const resendUrl = `${conf.apiRoot}/session/token?pkat=${pkat}`
      const res = await i.put(resendUrl, { ignoreErrorRedirect: true })
      setPkat(res?.body.pkat)
      setProcess(false)
    } catch (error) {
      dispatch(
        addNotification({
          message: resolveErrorEntity({ intl, error: error.body }),
          variant: 'error',
        }),
      )
      setProcess(false)
    }
  }

  return (
    <FlowLayout
      title={intl.formatMessage({
        id: 'separatedMultiStep.authenticate-magicLink-title',
      })}
      subtitle={
        <span>
          {intl.formatMessage({
            id: `separatedMultiStep.authenticate-magicLink-description`,
          })}
        </span>
      }
    >
      <ActionLabel
        disabled={process}
        variant="text"
        onClick={handleResendLink}
        label={intl.formatMessage({
          id: `common.button.send-verification-link`,
        })}
      />
    </FlowLayout>
  )
}
