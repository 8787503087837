import { hasUIFeatureFlag } from 'common/services/FeatureFlagsService'

export const DEFAULT_FAVICONS = [
  {
    rel: 'icon',
    type: 'image/png',
    sizes: '180x180',
    href: `${process.env.PUBLIC_URL}/apple-touch-icon.png`,
  },
  {
    rel: 'icon',
    type: 'image/png',
    sizes: '16x16',
    href: `${process.env.PUBLIC_URL}/favicon-16x16.png`,
  },
  {
    rel: 'icon',
    type: 'image/png',
    sizes: '32x32',
    href: `${process.env.PUBLIC_URL}/favicon-32x32.png`,
  },
]

const getFavicons = () => {
  const faviconsList = hasUIFeatureFlag('favicons')
  if (!faviconsList) {
    return DEFAULT_FAVICONS
  }
  return faviconsList?.map(favicon => {
    return {
      ...favicon,
      href: `${process.env.PUBLIC_URL}/${favicon.href}`,
    }
  })
}

export default getFavicons
