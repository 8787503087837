import { get } from 'lodash'

import USER, {
  USER_ROLE_MAP,
  ROLE_NAME_MAP,
  MEMBER,
} from 'common/constants/user'

export const getFlag = (entity, flag) =>
  get(entity, ['association', 'flags', flag])

export const getSessionUser = state => get(state, 'user', {})

export const getRole = user => {
  const role = getFlag(user, USER.flags.role)

  return get(USER_ROLE_MAP, role, MEMBER)
}

export const getRoleLabel = user => get(ROLE_NAME_MAP, getRole(user))

export const getIsCurrentUser = (state, user = {}) => {
  const sessionUser = getSessionUser(state)

  return sessionUser.id === user.id
}

export const getIsUserPending = user =>
  getFlag(user, USER.flags.activating) === 'true'
