import ACTIONS from './actions'
import { get } from 'lodash'

function reducer(state, action) {
  switch (action.type) {
    case ACTIONS.SIGN_UP_UPDATE_USERNAME:
      return { ...state, username: get(action, 'payload.username') }
    case ACTIONS.SIGN_UP_UPDATE_COUNTRY_CODE:
      return { ...state, countryCode: get(action, 'payload.countryCode') }
    case ACTIONS.SIGN_UP_UPDATE_ISO_CODE:
      return { ...state, isoCode: get(action, 'payload.isoCode') }
    case ACTIONS.SIGN_UP_UPDATE_PASSWORD:
      return { ...state, credential: get(action, 'payload.credential') }
    case ACTIONS.SIGN_UP_UPDATE_ERRORS:
      return { ...state, errors: get(action, 'payload.errors') }
    case ACTIONS.SIGN_UP_HANDLE_VERIFIED:
    case ACTIONS.SIGN_UP_RESET_STEP:
    case ACTIONS.SIGN_UP_UPDATE_STATE:
      return { ...state, ...get(action, 'payload', {}) }
    case ACTIONS.SIGN_UP_UPDATE_NAME:
      return {
        ...state,
        [get(action, 'payload.nameType')]: get(action, 'payload.value'),
      }
    default:
      return state
  }
}

export default reducer
