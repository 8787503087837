import React from 'react'

import Fade from '@mui/material/Fade'
import { Body } from 'common/components/Typography'

import withStyles from '@mui/styles/withStyles'

const styles = theme => ({
  errorBox: {
    color:
      theme.palette.mode === 'light'
        ? theme.palette.error.dark
        : theme.palette.error.light,
  },
})

const ErrorBox = props => (
  <Fade in>
    <div>
      <Body className={props.classes.errorBox} mb={3} {...props} />
    </div>
  </Fade>
)

export default withStyles(styles)(ErrorBox)
