import { useLocation, useHistory } from 'react-router-dom'
import { get } from 'lodash'
import { FORGOT_PASSWORD_ROUTE } from 'common/constants'

const useForgotRedirect = ({ url, id, mobile, originProcessState } = {}) => {
  const history = useHistory()
  const location = useLocation()

  const locationState = get(location, 'state', {})
  const {
    viewId: locationViewId,
    mobile: locationMobile,
    originProcessState: locationOriginProcessState = {},
  } = locationState

  if (locationViewId) {
    const emptyState = {}
    history.replace({
      ...history.location,
      state: emptyState,
    })
  }

  const redirect = () =>
    history.push(
      {
        pathname: FORGOT_PASSWORD_ROUTE,
        search: location.search,
      },
      {
        originProcessState,
        originalRedirect: url,
        viewId: id,
        mobile,
      },
    )

  return {
    originProcessState: locationOriginProcessState,
    viewId: locationViewId,
    mobile: locationMobile,
    redirect,
  }
}

export default useForgotRedirect
