import { hasUIFeatureFlag } from 'common/services/FeatureFlagsService'

const typePathMap = {
  dictionary: 'language/idp',
  logo: 'logo/idp',
  theme: 'theme/idp',
}

const validateFileName = ({ type, fileName }) => {
  if (type === 'theme' || type === 'dictionary') {
    return /.*\.json$/.test(fileName)
  }
  return true // TODO: validate fileName for logo
}

const buildUrl = ({ baseUrl, path, tenantId, fileName }) => {
  return [baseUrl, path, tenantId, fileName].join('/')
}

const getGatewayUrl = ({ type, tenantId, fileName }) => {
  if (!tenantId || !fileName) {
    return null
  }

  if (!validateFileName({ type, fileName })) {
    return null
  }

  const path = typePathMap[type]
  if (!path) {
    return null
  }

  let baseUrl = hasUIFeatureFlag('apiGatewayURL')

  if (typeof baseUrl !== 'string' || !baseUrl) {
    return null
  }

  // remove trailing / from baseUrl if present
  if (/\/$/.test(baseUrl)) {
    baseUrl = baseUrl.slice(0, -1)
  }

  return buildUrl({
    baseUrl,
    path,
    tenantId,
    fileName,
  })
}

export default getGatewayUrl
