import React, { useCallback, useEffect } from 'react'
import { useIntl } from 'react-intl'

import Link from '@mui/material/Link'

import * as UXPCard from 'common/components/Card'
import { Title } from 'common/components/Typography'
import { Button, TextPrimaryButton } from 'common/components/button/Button'

import useMainRedirect from 'common/components/hooks/useMainRedirect'
import { getFlowTypes } from 'common/constants'

import { analyticsProvider } from 'App.js'

import styled from 'styled-components'

const StyledTitle = styled(Title)`
  text-align: center;
`

const getMessageIds = (flowType, isPortable) => {
  if (flowType === getFlowTypes().CANCEL) {
    return {
      titleMessageId: 'onboard-cancel.not-eligible.title',
      bodyMessageId: 'onboard-cancel.not-eligible.we-cannot-process',
    }
  }

  if (isPortable) {
    return {
      titleMessageId: 'onboard.not-eligible.title.switch-your-mobile',
      bodyMessageId: 'onboard.not-eligible.switch-your-mobile',
    }
  }

  return {
    titleMessageId: 'onboard.not-eligible.title',
    bodyMessageId: 'onboard.not-eligible.your-plan-does-not-support-content',
  }
}

const NotEligible = ({ flowType, wizard }) => {
  const intl = useIntl()
  const mainRedirect = useMainRedirect(`/${flowType}`)

  const { portUrl } = wizard.getPageState()
  const { titleMessageId, bodyMessageId } = getMessageIds(flowType, portUrl)

  const pageTitle = 'not_eligible'
  const baseEventTag = `${flowType}.not_eligible`

  useEffect(() => {
    analyticsProvider.sendAnalytics({
      type: 'page_view',
      page_title: pageTitle,
      page_path: `/${flowType}#${pageTitle}`,
    })
  }, [flowType])

  const onCancel = useCallback(
    label => {
      analyticsProvider.sendAnalytics({
        type: 'event',
        action: 'click',
        event_category: 'button',
        event_label: `${baseEventTag}.${label}`,
        value: 0,
      })

      const isStaticRedirect = mainRedirect()
      if (isStaticRedirect) {
        wizard.setPageState({})
        wizard.toPage(0)
      }
    },
    [baseEventTag, mainRedirect, wizard],
  )

  const onInterested = useCallback(() => {
    analyticsProvider.sendAnalytics({
      type: 'event',
      action: 'click',
      event_category: 'button',
      event_label: `${baseEventTag}.i_am_interested`,
      value: 0,
    })
  }, [baseEventTag])

  const getActions = useCallback(() => {
    if (portUrl && flowType !== getFlowTypes().CANCEL) {
      return (
        <React.Fragment>
          <TextPrimaryButton
            onClick={() => {
              onCancel('cancel')
            }}
          >
            {intl.formatMessage({ id: 'common.button.cancel' })}
          </TextPrimaryButton>
          <Link underline="none" href={portUrl} target="_blank" rel="noopener">
            <Button onClick={onInterested}>
              {intl.formatMessage({
                id: 'onboard.not-eligible.i-am-interested',
              })}
            </Button>
          </Link>
        </React.Fragment>
      )
    }

    return (
      <Button
        onClick={() => {
          onCancel('ok')
        }}
      >
        {intl.formatMessage({ id: 'onboard.not-eligible.button.ok' })}
      </Button>
    )
  }, [portUrl, flowType, intl, onCancel, onInterested])

  return (
    <UXPCard.Card>
      <UXPCard.Content>
        <StyledTitle>
          {intl.formatMessage({ id: titleMessageId })}
          <br />
          {intl.formatMessage({ id: bodyMessageId })}
        </StyledTitle>
      </UXPCard.Content>
      <UXPCard.Divider />
      <UXPCard.Actions>{getActions()}</UXPCard.Actions>
    </UXPCard.Card>
  )
}

export default NotEligible
