import { List } from '@admin-ui-common/base-user'
import { Box, Typography } from '@mui/material'

export default function ListSectionTitle({ title, subtitle }) {
  return (
    <List.ListSubheader size="large">
      <Box pb={2}>
        <Typography variant="h6" color="textPrimary">
          {title}
        </Typography>
        {subtitle ? (
          <Box mt={1}>
            <Typography variant="body2">{subtitle}</Typography>
          </Box>
        ) : null}
      </Box>
    </List.ListSubheader>
  )
}
