import isObject from 'lodash/isObject'
import isEmpty from 'lodash/isEmpty'

import i from '../utils/i'
import getGatewayUrl from 'common/utils/getGatewayUrl'

const model = 'dictionary'

export const GET_DICTIONARY_REQUEST = `${model}/GET_DICTIONARY_REQUEST`
export const GET_DICTIONARY_SUCCESS = `${model}/GET_DICTIONARY_SUCCESS`
export const GET_DICTIONARY_FAILURE = `${model}/GET_DICTIONARY_FAILURE`

const loadDictionaryRequest = {
  type: GET_DICTIONARY_REQUEST,
}

const loadDictionarySuccess = body => ({
  type: GET_DICTIONARY_SUCCESS,
  dictionary: body,
})

const loadDictionaryFailure = error => ({
  type: GET_DICTIONARY_FAILURE,
  error,
})

const loadDictionaryFromCache = async url => {
  let cache = {}

  try {
    const cachedValue = localStorage.getItem(url)
    if (!isEmpty(cachedValue)) {
      const parsedCachedValue = JSON.parse(cachedValue)
      cache = parsedCachedValue
    }
  } catch (error) {
    // Corrupted cache will be rewritten by API Call
  }

  // We want this function to be asynchronous, so it can be easy to replace with different type of
  // storage like IndexedDB in future
  return Promise.resolve(cache)
}

export const getDictionary = (tenantId, locale) => async dispatch => {
  const apiGatewayUrl = getGatewayUrl({
    type: model,
    tenantId,
    fileName: `${locale}.json`,
  })

  if (apiGatewayUrl) {
    dispatch(loadDictionaryRequest)
    const cachedDictionary = await loadDictionaryFromCache(apiGatewayUrl)

    const apiRequest = i
      .get(apiGatewayUrl, {
        omitCredentials: true,
        ignoreErrorRedirect: true,
        noXProcessDebug: true,
      })
      .then(({ body }) => {
        if (!isObject(body)) {
          throw Error(
            `Response body from ${apiGatewayUrl} not parseable as object`,
          )
        }
        dispatch(loadDictionarySuccess(body))
        localStorage.setItem(apiGatewayUrl, JSON.stringify(body))
        return body
      })
      .catch(error => {
        dispatch(loadDictionaryFailure(error))
      })

    if (!isEmpty(cachedDictionary)) {
      dispatch(loadDictionarySuccess(cachedDictionary))
      return Promise.resolve(cachedDictionary)
    }

    return apiRequest
  }

  return Promise.resolve({})
}
