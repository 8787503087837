import React from 'react'
import { Button } from 'common/components/button/Button'

const MergeConfirmation = ({ displayMessage, handleSubmit }) => (
  <div className="flex flex-column">
    <h3 style={{ maxWidth: '500px' }}>{displayMessage}</h3>

    <div className="flex justify-end mt2">
      <div className="mr2">
        <Button variant="outlined" onClick={() => handleSubmit('false')}>
          Do Not Merge Accounts
        </Button>
      </div>

      <div className="self-end">
        <Button onClick={() => handleSubmit('true')}>Verify</Button>
      </div>
    </div>
  </div>
)

export default MergeConfirmation
