import React from 'react'

export default class Wizard extends React.Component {
  constructor(props) {
    super(props)

    Object.getOwnPropertyNames(this.api).forEach(name => {
      this.api[name] = this.api[name].bind(this)
    })

    this.state = {
      activePageIndex: 0,
      pages: this.props.children,
      store: {},
    }
  }
  api = {
    toNextPage() {
      const nextIndex = this.state.activePageIndex + 1

      this.api.toPage(nextIndex)
    },
    toPreviousPage() {
      const previousIndex = this.state.activePageIndex - 1

      this.api.toPage(previousIndex)
    },
    toPage(index) {
      this.setState({ activePageIndex: index })
    },
    setPageState(state) {
      this.setState({ store: state })
    },
    getPageState() {
      return this.state.store
    },
  }
  render() {
    const page = this.state.pages[this.state.activePageIndex]
    return React.cloneElement(page, { wizard: this.api })
  }
}
