import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { isBoolean } from 'lodash'

import makeStyles from '@mui/styles/makeStyles'
import MuAvatar from '@mui/material/Avatar'
import Tooltip from '@mui/material/Tooltip'
import Box from '@mui/material/Box'

const userColors = ['#838DC8', '#EAB330', '#D5665F', '#CA0088', '#654EA3']
const bgColors = userColors.reduce((result, color, index) => {
  result[`color${index}`] = {
    backgroundColor: color,
  }
  return result
}, {})

const useStyles = position =>
  makeStyles(theme => ({
    avatar: {
      width: 32,
      height: 32,
      fontSize: '1.1rem',
      border: '2px solid white',
      color: theme.palette.common.white,
    },
    root: {
      transform: `translate(-${25 * position}%)`,
    },
    name: {
      textDecoration: 'uppercase',
    },
    tooltip: {
      fontSize: 11,
      backgroundColor: 'rgba(0, 0, 0, 0.67)',
    },
    ...bgColors,
  }))

const Avatar = ({
  displayName,
  id,
  className,
  initials,
  overlap,
  position,
  tooltip,
  ...props
}) => {
  const getDisplay = () => {
    if (initials) {
      const [firstName = '', lastName = ''] = displayName.split(' ')

      return `${firstName[0]}${lastName[0]}`
    }

    return displayName[0]
  }

  const avatarTranslation = overlap ? position : 0
  const classes = useStyles(avatarTranslation)()

  const isTooltip = Boolean(tooltip)
  const colorIndex = id % userColors.length
  const avatarClasses = classNames(
    classes.avatar,
    classes[`color${colorIndex}`],
    isBoolean(overlap) && classes.border,
    className,
  )

  return (
    <Box className={classes.root}>
      <Tooltip
        title={tooltip || ''}
        disableHoverListener={!isTooltip}
        disableFocusListener={!isTooltip}
        classes={{ tooltip: classes.tooltip }}
      >
        <MuAvatar
          className={avatarClasses}
          classes={{ root: className }}
          {...props}
          data-q={tooltip}
        >
          {getDisplay()}
        </MuAvatar>
      </Tooltip>
    </Box>
  )
}

Avatar.propTypes = {
  displayName: PropTypes.string,
  id: PropTypes.number,
  className: PropTypes.string,
  initials: PropTypes.bool,
  overlap: PropTypes.bool,
  tooltip: PropTypes.string,
}

export default Avatar
