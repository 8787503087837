import React from 'react'
import { useIntl } from 'react-intl'
import { useSelector } from 'react-redux'
import makeStyles from '@mui/styles/makeStyles'
import { Box, Typography } from '@mui/material'
import PhoneAndroidIcon from '@mui/icons-material/PhoneAndroid'
import DeleteIcon from '@mui/icons-material/Delete'
import { TextPrimaryButton } from 'common/components/button/Button'
import { List } from '@admin-ui-common/base-user'

const useStyles = makeStyles(theme => ({
  iconWrapper: {
    alignSelf: 'flex-start',
  },
  icon: {
    fontSize: '40px',
    color: theme.palette.text.primary,
  },
  bold: {
    fontWeight: 'bold',
  },
  textBtn: {
    marginTop: 36,
    paddingLeft: 0,
  },
  fowardArrowIcon: {
    fontSize: 24,
    color: theme.palette.text.secondary,
    cursor: 'pointer',
  },
  appList: {
    marginTop: 16,
    marginBottom: 16,
  },
}))

export const AuthenticatorApp = ({
  hasMinMfaRegistered,
  setupAuthenticatorApp,
  unRegisterMFA,
}) => {
  const classes = useStyles()
  const intl = useIntl()
  const secret2FATotp = useSelector(
    state => state.user.attributes.secret2FATotp,
  )
  const authAppSubtitle = secret2FATotp
    ? 'profile.detail.authenticator-app.use-auth-app-verification'
    : 'profile.detail.authenticator-app.use-auth-app'
  return (
    <>
      <List.ListItem>
        <List.ListItemIcon className={classes.iconWrapper}>
          <PhoneAndroidIcon className={classes.icon} />
        </List.ListItemIcon>
        <List.ListItemText
          primary={
            <Typography className={classes.bold} variant="body1">
              {intl.formatMessage({
                id: 'profile.label.authenticator-app',
              })}
            </Typography>
          }
          secondary={
            <div>
              {!secret2FATotp && (
                <span className={classes.bold}>
                  {intl.formatMessage({
                    id: 'profile.detail.authenticator-app.recommended',
                  })}
                </span>
              )}
              {intl.formatMessage({
                id: authAppSubtitle,
              })}
            </div>
          }
        />
      </List.ListItem>
      {secret2FATotp ? (
        <List.ListItem className={classes.appList}>
          <List.ListItemIcon className={classes.iconWrapper} />
          <List.ListItemText
            primary={intl.formatMessage({
              id: 'profile.label.authenticator-app',
            })}
          />
          {!hasMinMfaRegistered && (
            <List.ListItemSecondaryAction>
              <DeleteIcon
                className={classes.fowardArrowIcon}
                onClick={() =>
                  unRegisterMFA(
                    intl.formatMessage({
                      id: 'profile.label.authenticator-app',
                    }),
                  )
                }
              />
            </List.ListItemSecondaryAction>
          )}
        </List.ListItem>
      ) : (
        <Box mt={3} ml={8}>
          <TextPrimaryButton onClick={setupAuthenticatorApp}>
            {intl.formatMessage({
              id: 'profile.detail.authenticator-app.button.setup-auth-app',
            })}
          </TextPrimaryButton>
        </Box>
      )}
    </>
  )
}
export default AuthenticatorApp
