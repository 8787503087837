import React, { Component } from 'react'
import { connect } from 'react-redux'
import { injectIntl, FormattedMessage } from 'react-intl'
import { withRouter } from 'react-router-dom'
import { Button } from 'common/components/button/Button'
import { checkToken } from 'common/actions/socialAccounts'
import { checkSession, endSession } from 'common/actions/session'
import isEmpty from 'lodash/isEmpty'
import get from 'lodash/get'
import RwdPaper from 'common/components/RwdPaper'
import Page from 'common/components/Page'
import CreatePassword from './CreatePassword'
import { resolveErrorEntity } from 'common/utils/processBackendErrors'
import {
  getRedirectLocation,
  performRedirect,
} from 'common/utils/getRedirectUrl'

import styles from '../styles.module.css'
import BasicLayout from 'common/layouts/BasicLayout'
import { analyticsProvider } from '../../App'

const errorContext = 'confirmUser'
const ErrorView = ({ error, onError, intl }) => (
  <div
    style={{
      display: 'flex',
      flexDirection: 'column',
    }}
  >
    {resolveErrorEntity({ intl, error: error, context: errorContext })}
    <div className="mt2 self-center">
      <Button
        onClick={() => {
          analyticsProvider.sendAnalytics({
            type: 'event',
            action: 'click',
            event_category: 'button',
            event_label: `${this.baseEventTag}.error-ok`,
            value: 0,
          })
          onError()
        }}
      >
        {intl.formatMessage({ id: 'common.button.ok' })}
      </Button>
    </div>
  </div>
)

class ConfirmUserByToken extends Component {
  constructor(props) {
    super(props)

    this.pageTitle = 'confirmUserByToken'
    this.baseEventTag = `signup.${this.pageTitle}`
  }

  componentDidMount() {
    analyticsProvider.sendAnalytics({
      type: 'page_view',
      page_title: this.pageTitle,
      page_path: `/signup#${this.pageTitle}`,
    })

    const { socialAccounts, hasSession } = this.props
    if (hasSession) {
      this.props.endSession()
    } else if (isEmpty(socialAccounts.checkTokenData)) {
      const query = this.props.location.search

      if (query.includes('token_value=')) {
        this.props.checkToken(query)
      } else {
        this.props.history.push('/')
      }
    }
  }

  handleRedirect = () => {
    const {
      headers,
      userId,
      lastStep,
      userAuthenticated,
    } = this.props.socialAccounts.checkTokenData
    const metadata = {
      headers,
    }
    const location = getRedirectLocation(metadata)
    const isAuthProcessComplete = Boolean(
      userId || lastStep || userAuthenticated,
    )

    analyticsProvider.sendAnalytics({
      type: 'event',
      action: 'click',
      event_category: 'button',
      event_label: `${this.baseEventTag}.visit-profile`,
      value: 0,
    })

    if (isAuthProcessComplete && location !== '') {
      performRedirect(location)
    } else {
      this.props.history.push('/')
      this.props.checkSession()
    }
  }

  goSignIn = () => {
    analyticsProvider.sendAnalytics({
      type: 'event',
      action: 'click',
      event_category: 'button',
      event_label: `${this.baseEventTag}.sign-in`,
      value: 0,
    })
    this.props.history.push('/')
  }

  render() {
    const {
      error,
      isLoading,
      hasFailed,
      checkTokenData,
    } = this.props.socialAccounts

    const { intl, history, checkSession } = this.props
    const authenticationIdentifier = get(
      checkTokenData,
      'output.activatedAuthenticationIdentifier',
    )

    const isProcessComplete = Boolean(
      get(checkTokenData, 'userAuthenticated') === true,
    )

    if (isLoading) {
      return <div>{intl.formatMessage({ id: 'common.loading' })}</div>
    }

    if (hasFailed && !isEmpty(error)) {
      return (
        <ErrorView
          error={error}
          onError={() => this.props.history.push('/')}
          intl={intl}
        />
      )
    }

    if (isEmpty(checkTokenData)) {
      return null
    }

    if (get(checkTokenData, 'stepName') === 'CreateCredentialPrompt') {
      const usernameType =
        authenticationIdentifier.type.toLowerCase() === 'email'
          ? 'email'
          : 'phone'
      const userDisplayName = get(checkTokenData, 'output.userDisplayName')
      const [firstName, lastName] = userDisplayName.split(' ')
      return (
        <CreatePassword
          stepResponse={checkTokenData}
          username={authenticationIdentifier.value}
          usernameType={usernameType}
          displayName={userDisplayName}
          firstName={firstName}
          lastName={lastName}
          history={history}
          checkSession={checkSession}
        />
      )
    }

    return (
      <RwdPaper>
        <div className={styles.panel}>
          <div className={styles.title}>
            <Page.Title>
              {intl.formatMessage({
                id: `sign-up.user-confirm.${authenticationIdentifier.type.toLowerCase()}.title`,
              })}
            </Page.Title>
          </div>

          <Page.Body>
            <FormattedMessage
              id="sign-up.user-confirm.message"
              values={{
                authId: (
                  <span className={styles.username}>
                    {authenticationIdentifier.value}
                  </span>
                ),
              }}
            />
          </Page.Body>

          <div className={styles.formButton}>
            <Button
              onClick={isProcessComplete ? this.goSignIn : this.handleRedirect}
            >
              {intl.formatMessage({
                id: isProcessComplete
                  ? 'sign-up.link.sign-in'
                  : 'common.button.visit-my-profile',
              })}
            </Button>
          </div>
        </div>
      </RwdPaper>
    )
  }
}

function PageWrapper(props) {
  return (
    <BasicLayout>
      <ConfirmUserByToken {...props} />
    </BasicLayout>
  )
}

const mapState = ({ socialAccounts, session }) => ({
  socialAccounts,
  hasSession: session.hasSession,
})

const mapDispatch = {
  checkToken,
  checkSession,
  endSession,
}

export default withRouter(
  connect(mapState, mapDispatch)(injectIntl(PageWrapper)),
)
