import React, { useState, useEffect, useContext } from 'react'
import { useIntl } from 'react-intl'
import { get, omit } from 'lodash'

import * as UXPCard from 'common/components/Card'
import { Title, Body, Sidenote } from 'common/components/Typography'
import MobileNumberField, {
  mobileRegex,
} from 'common/components/textfield/MobileNumberField'
import { TextPrimaryButton, Button } from 'common/components/button/Button'
import Loader from 'common/components/Loader'

import useForgotRedirect from 'common/components/hooks/useForgotRedirect'
import useDuration from 'common/components/hooks/useDuration'
import useMainRedirect from 'common/components/hooks/useMainRedirect'

import {
  onboardUserWithServiceSubscription,
  enrolAuthenticateWithSubscription,
} from 'common/processes/subscriptionManagement'
import process from 'common/utils/process'
import { Helmet } from 'react-helmet'

import {
  NOT_ELIGIBLE,
  NUMBER_IS_PORTABLE,
  INVALID_PRODUCT,
  PRODUCT_PROMPT,
  getFlowTypes,
} from 'common/constants'
import { resolveErrorEntity } from 'common/utils/processBackendErrors'
import { getProductId } from 'common/utils/getProductId'
import { LogoContext, analyticsProvider } from 'App.js'

const notEligibleCodes = new Set([NOT_ELIGIBLE, NUMBER_IS_PORTABLE])

const phoneErrorContext = 'phone'

const VerificationMobile = ({ wizard, flowType, wizardIndex }) => {
  const { viewId, mobile: locationMobile } = useForgotRedirect()

  const [mobile, setMobile] = useState('')
  const [errorText, setErrorText] = useState('')
  const intl = useIntl()
  const [loading, setLoading] = useState(false)
  const [innerLoading, setInnerLoading] = useState(false)
  const [processData, setProcessData] = useState({})
  const [appTitle, setAppTitle] = useState('')
  const formatDuration = useDuration()

  const FLOW_TYPES = getFlowTypes()
  const isSubscribe = flowType === FLOW_TYPES.SUBSCRIBE
  const mainRedirect = useMainRedirect(`/${flowType}`)
  const processMap = {
    [FLOW_TYPES.SUBSCRIBE]: onboardUserWithServiceSubscription,
    [FLOW_TYPES.LOGIN]: enrolAuthenticateWithSubscription,
  }

  const productId = getProductId()

  const pageTitle = 'verify_mobile'
  const baseEventTag = `${flowType}.${pageTitle}`
  const mobileFieldLabel = intl.formatMessage({
    id: 'onboard.common.mobile-number',
  })

  const logoCtx = useContext(LogoContext)

  useEffect(() => {
    analyticsProvider.sendAnalytics({
      type: 'page_view',
      page_title: pageTitle,
      page_path: `/${flowType}#${pageTitle}`,
    })
  }, [flowType])

  useEffect(() => {
    if (viewId) {
      setMobile(locationMobile)
    }
  }, [viewId, locationMobile])

  useEffect(() => {
    const { mobile, origin: { viewId = '' } = {} } = wizard?.getPageState()
    if (viewId === 'CodeExpire') {
      setMobile(mobile)
    }
  }, [wizard])

  // //////////////////////////////////////////////////////////

  const setMobileCallback = e => {
    setMobile(e.target.value.replace(mobileRegex, ''))
  }

  // //////////////////////////////////////////////////////////

  useEffect(() => {
    setLoading(true)

    const requestProduct = async () => {
      const flowProcess = processMap[flowType]
      try {
        const res = await flowProcess(productId)
        const productDetailsDTO = get(res, 'body.output.productDetailsDTO')

        const appName = get(productDetailsDTO, 'appName', '')
        const appLogoImage = get(
          productDetailsDTO,
          'appLogoImage',
          '/transparent.svg',
        )

        setAppTitle(appName)
        logoCtx.onLogoUrl(appLogoImage)

        const stepName = get(res, 'body.stepName')

        if (stepName === PRODUCT_PROMPT) {
          wizard.toPage(wizardIndex.notFound)
        } else {
          setProcessData(res.body)
        }
      } catch (err) {
        const code = get(err, 'body.operationError[0].code')
        if (code === INVALID_PRODUCT) {
          wizard.toPage(wizardIndex.notFound)
        }
      } finally {
        setLoading(false)
      }
    }

    requestProduct()
  }, [flowType, productId, wizard, wizardIndex.notFound])

  // //////////////////////////////////////////////////////////

  const onNextPage = async () => {
    if (!mobile) {
      const errorText = intl.formatMessage({
        id: 'onboard.error.please-enter-mobile',
      })

      analyticsProvider.sendAnalytics({
        type: 'event',
        action: 'exception',
        description: `${baseEventTag}.phoneNumber: ${errorText}`,
      })

      setErrorText(errorText)

      return
    }

    const stepPayload = omit(processData, [
      'displayMessage',
      'lastStep',
      'output',
      'processName',
    ])
    stepPayload.parameters = { identifier: mobile }

    setInnerLoading(true)

    try {
      const res = await process.step(stepPayload, true)
      const output = get(res, 'body.output')
      const endStepName = get(output, 'endStepName')
      const pkat = get(output, 'pkat')
      const termsOfServiceURL = get(
        output,
        'productDetailsDTO.termsOfServiceURL',
      )

      analyticsProvider.sendAnalytics({
        type: 'event',
        action: 'click',
        event_category: 'button',
        event_label: `${baseEventTag}.next`,
        value: 0,
      })

      wizard.setPageState({
        ...wizard.getPageState(),
        ...res.body,
        mobile,
        termsOfServiceURL,
      })

      const nextPage =
        endStepName === 'EnterPinCode' || pkat ? 'verifyCode' : 'signIn'
      wizard.toPage(wizardIndex[nextPage])
    } catch (err) {
      setInnerLoading(false)

      analyticsProvider.sendAnalytics({
        type: 'event',
        action: 'click',
        event_category: 'button',
        event_label: `${baseEventTag}.next`,
        value: 1,
      })

      const operationError = get(err, 'body.operationError[0]', {})
      const { code: errorCode, message: portUrl } = operationError
      const metadata = { headers: { get: () => '' } }

      if (notEligibleCodes.has(errorCode)) {
        const nextWizardPageState = {
          ...wizard.getPageState(),
          metadata,
        }
        if (errorCode === NUMBER_IS_PORTABLE) {
          nextWizardPageState.portUrl = portUrl
        }

        wizard.setPageState(nextWizardPageState)
        wizard.toPage(wizardIndex.notEligible)
      } else {
        const errorText = resolveErrorEntity({
          intl,
          error: err.body,
          context: phoneErrorContext,
        })

        analyticsProvider.sendAnalytics({
          type: 'event',
          action: 'exception',
          description: `${baseEventTag}.screen: ${errorText}`,
        })

        setErrorText(errorText)
      }
      return
    }

    setInnerLoading(false)
  }

  // //////////////////////////////////////////////////////////

  const onCancel = () => {
    analyticsProvider.sendAnalytics({
      type: 'event',
      action: 'click',
      event_category: 'button',
      event_label: `${baseEventTag}.cancel`,
      value: 0,
    })

    const isStaticRedirect = mainRedirect()
    if (isStaticRedirect) {
      setMobile('')
      setErrorText('')
    }
  }

  // //////////////////////////////////////////////////////////

  if (loading) {
    return <Loader />
  }

  const {
    productBillingCycle,
    productCurrency,
    productPrice,
    productTrialPeriod,
    subscriptionName,
    shortServiceName,
  } = get(processData, 'output.productDetailsDTO', {})

  const trialPeriod = formatDuration(productTrialPeriod)
  const billingPeriod = formatDuration(productBillingCycle)
  const mobileVerificationTranslationId = isSubscribe
    ? 'onboard-subscribe.verification-mobile.verify-your-mobile'
    : 'onboard.verification-mobile.verify-your-mobile'

  return (
    <UXPCard.Card>
      <UXPCard.Content>
        <Helmet>
          <title>{appTitle}</title>
        </Helmet>
        <Title>
          {intl.formatMessage({
            id: mobileVerificationTranslationId,
          })}
        </Title>

        {isSubscribe && (
          <Body>
            {intl.formatMessage(
              { id: 'onboard.verification-mobile.trial-period' },
              {
                trialPeriod,
                price: productPrice,
                currency: productCurrency,
                billingPeriod,
                subscriptionName,
              },
            )}
          </Body>
        )}

        <Body mb={4}>
          {intl.formatMessage(
            {
              id: isSubscribe
                ? 'onboard.verification-mobile.you-need-to-verify'
                : 'onboard.verification-mobile.before-you-sign-in',
            },
            {
              subscriptionName,
            },
          )}
        </Body>
        <MobileNumberField
          label={mobileFieldLabel}
          onChange={setMobileCallback}
          value={mobile}
          errorText={errorText}
        />
        {isSubscribe && (
          <Sidenote mt={2}>
            {intl.formatMessage(
              { id: 'onboard.verification-mobile.note' },
              {
                price: productPrice,
                currency: productCurrency,
                billingPeriod,
                shortServiceName,
              },
            )}
          </Sidenote>
        )}
      </UXPCard.Content>
      <UXPCard.Actions>
        <TextPrimaryButton onClick={onCancel}>
          {intl.formatMessage({ id: 'common.button.cancel' })}
        </TextPrimaryButton>
        <Button onClick={onNextPage} isLoading={innerLoading}>
          {intl.formatMessage({ id: 'common.button.next' })}
        </Button>
      </UXPCard.Actions>
    </UXPCard.Card>
  )
}

export default VerificationMobile
