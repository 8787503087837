import { useEffect, useState, useMemo } from 'react'
import { useSelector } from 'react-redux'
import {
  configurableTheme,
  createThemeFromTemplate,
  supportedThemes,
} from '@admin-ui-common/user-theme'
import getGatewayUrl from 'common/utils/getGatewayUrl'
import { hasUIFeatureFlag } from 'common/services/FeatureFlagsService'
import i from 'common/utils/i'

const storageKey = 'cached-template'

const preExistingThemeKeys = supportedThemes

const useTheme = () => {
  const themeSetting = hasUIFeatureFlag('theme') // either a pre-existing theme or a json filename
  const themeType = hasUIFeatureFlag('theme.type') ?? 'light'

  // Note: relying on assumed behaviour here
  // if there is no custom theme for this flag this seemingly gets set to 'default' by FeatureFlagService
  const noCustomTheme = themeSetting === 'default'
  const isPreExistingTheme = preExistingThemeKeys.includes(
    (themeSetting ?? '').trim(),
  )

  const defaultTheme = useMemo(() => {
    if (isPreExistingTheme) {
      localStorage.removeItem(storageKey)
      return configurableTheme(themeSetting, themeType)
    }

    const cachedTemplate = localStorage.getItem(storageKey)

    if (cachedTemplate) {
      try {
        return createThemeFromTemplate(JSON.parse(cachedTemplate))
      } catch (e) {
        console.warn('Error creating theme from cached template', e)
        // since cache template is corrupted, remove it
        localStorage.removeItem(storageKey)
      }
    }
    return configurableTheme('default', themeType)
  }, [themeSetting, themeType, isPreExistingTheme])

  const tenantId = useSelector(state => state?.settings?.tenantID)

  const [theme, setTheme] = useState(defaultTheme)

  const apiGatewayUrl = getGatewayUrl({
    type: 'theme',
    tenantId,
    fileName: themeSetting,
  })

  useEffect(() => {
    if (noCustomTheme) {
      // if server comes back with no custom theme, but we had applied a cached theme
      // this will remove it so that on refresh we go back to the default theme
      localStorage.removeItem(storageKey)
      return
    }

    if (apiGatewayUrl !== null) {
      fetchCustomTheme()
      return
    }

    setTheme(defaultTheme)

    async function fetchCustomTheme() {
      try {
        const { body } = await i.get(apiGatewayUrl, {
          omitCredentials: true,
          ignoreErrorRedirect: true,
          noXProcessDebug: true,
        })

        const newTheme = createThemeFromTemplate({
          template: body,
          name: themeSetting,
        })

        localStorage.setItem(
          storageKey,
          JSON.stringify({
            template: body,
            name: themeSetting,
          }),
        )

        // override default theme with custom
        setTheme(newTheme)
      } catch (e) {
        console.warn(`Error fetching custom theme from ${apiGatewayUrl}`, e)
      }
    }
  }, [apiGatewayUrl, themeSetting, defaultTheme, setTheme, noCustomTheme])

  return {
    theme,
    themeSetting,
    themeType,
  }
}

export default useTheme
