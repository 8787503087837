import WizardOnlyNotification from './WizardOnlyNotification'
import IdpNotification from './IdpNotification'
import React from 'react'
import { useSelector } from 'react-redux'

const Notification = () => {
  const isWizardOnly = useSelector(
    state => state.configuration.config.enableOnlyWizardRoutes,
  )
  return isWizardOnly ? <WizardOnlyNotification /> : <IdpNotification />
}

export default Notification
