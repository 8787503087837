const ACTIONS = {
  SIGN_UP_UPDATE_USERNAME: 'SIGN_UP_UPDATE_USERNAME',
  SIGN_UP_UPDATE_PASSWORD: 'SIGN_UP_UPDATE_PASSWORD',
  SIGN_UP_UPDATE_ERRORS: 'SIGN_UP_UPDATE_ERRORS',
  SIGN_UP_UPDATE_NAME: 'SIGN_UP_UPDATE_NAME',
  SIGN_UP_HANDLE_VERIFIED: 'SIGN_UP_HANDLE_VERIFIED',
  SIGN_UP_RESET_STEP: 'SIGN_UP_RESET_STEP',
  SIGN_UP_UPDATE_STATE: 'SIGN_UP_UPDATE_STATE',
  SIGN_UP_UPDATE_COUNTRY_CODE: 'SIGN_UP_UPDATE_COUNTRY_CODE',
  SIGN_UP_UPDATE_ISO_CODE: 'SIGN_UP_UPDATE_ISO_CODE',
}

export default ACTIONS
