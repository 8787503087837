import {
  CHECK_SESSION_ERROR,
  CHECK_SESSION_REQUEST,
  CHECK_SESSION_SUCCESS,
  END_SESSION_SUCCESS,
  START_SESSION_ERROR,
  START_SESSION_ERROR_RESET,
  START_SESSION_REQUEST,
  START_SESSION_RESPONSE,
  START_SESSION_SUCCESS,
} from 'common/actions/session'

import { LOAD_USER_ERROR } from 'common/actions/user'
import { FORCE_NOSESSION_ROUTES } from 'common/constants'

const initialState = {
  hasSession: false,
  isSessionStartLoading: false,
  isSessionCheckLoading: false,
  sessionStartError: null,
  sessionCheckError: null,
}

const sessionReducer = (state = initialState, action) => {
  switch (action.type) {
    case CHECK_SESSION_REQUEST:
      return { ...state, sessionCheckError: null, isSessionCheckLoading: true }
    case CHECK_SESSION_SUCCESS:
      return {
        ...state,
        hasSession:
          true && !FORCE_NOSESSION_ROUTES.includes(window.location.pathname),
        isSessionCheckLoading: false,
      }
    case CHECK_SESSION_ERROR:
      return {
        hasSession: false,
        sessionCheckError: action.error,
        isSessionStartLoading: false,
      }
    case START_SESSION_REQUEST:
      return { ...state, sessionStartError: null, isSessionStartLoading: true }
    case START_SESSION_RESPONSE:
      return { ...state, sessionStartError: null, isSessionStartLoading: false }
    case START_SESSION_SUCCESS:
      return {
        ...state,
        sessionStartError: null,
        isSessionStartLoading: false,
      }
    case START_SESSION_ERROR:
    case LOAD_USER_ERROR:
      return {
        ...state,
        sessionStartError: action.error,
        isSessionStartLoading: false,
      }
    case START_SESSION_ERROR_RESET:
      return { ...state, sessionStartError: null }
    case END_SESSION_SUCCESS:
      return { ...state, hasSession: false }
    default:
      return state
  }
}

export default sessionReducer
