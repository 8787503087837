import React, { useState } from 'react'
import { useIntl } from 'react-intl'

import makeStyles from '@mui/styles/makeStyles'
import Box from '@mui/material/Box'

import * as UXPDialog from 'common/components/dialog/UXPDialog'
import { TextPrimaryButton, Button } from 'common/components/button/Button'
import Page from 'common/components/Page'
import { analyticsProvider } from 'App'

import { Divider, Typography } from '@mui/material'

const useStyles = makeStyles(() => ({
  height: {
    height: 56,
  },
  bold: {
    fontWeight: 'bold',
    fontSize: 16,
  },
  paddingBottom: {
    paddingBottom: 10,
  },
}))

const DeclineConsentDialog = ({
  onClose,
  open,
  onDeclineCallBack,
  flowType,
}) => {
  const classes = useStyles()

  const [inProgress, setInProgress] = useState(false)
  const intl = useIntl()

  const pageTitle = 'setup_totp_app'
  const baseEventTag = `${flowType}.${pageTitle}`

  const onDecline = () => {
    analyticsProvider.sendAnalytics({
      type: 'event',
      action: 'click',
      event_category: 'button',
      event_label: `${baseEventTag}.next`,
      value: 0,
    })

    setInProgress(true)

    onClose()
    onDeclineCallBack()
  }

  const closeView = () => {
    analyticsProvider.sendAnalytics({
      type: 'event',
      action: 'click',
      event_category: 'button',
      event_label: `${baseEventTag}.close`,
      value: 0,
    })
    onClose()
  }

  return (
    <UXPDialog.Dialog open={open} fullScreen fullWidth maxWidth="sm">
      <UXPDialog.Title
        title={
          <Typography variant="h5" fontWeight="bold">
            Decline Privacy Consent
          </Typography>
        }
        onClose={closeView}
      />
      <Divider />
      <UXPDialog.Content>
        <Box mb="40px">
          <Page.Description className={classes.paddingBottom}>
            <p>
              <Typography variant="body2">
                As a result of declining, you won’t be able to access your
                service.
              </Typography>
            </p>
            <p>
              <Typography variant="body2">
                Are you sure you want to proceed?
              </Typography>
            </p>
          </Page.Description>
        </Box>
      </UXPDialog.Content>
      <Divider />
      <UXPDialog.Actions mt="0px" disabled={inProgress}>
        <TextPrimaryButton onClick={closeView} variant="outlined">
          {intl.formatMessage({ id: 'common.button.cancel' })}
        </TextPrimaryButton>
        <Button onClick={onDecline} sx={{ height: '40px' }}>
          Decline
        </Button>
      </UXPDialog.Actions>
    </UXPDialog.Dialog>
  )
}

export default DeclineConsentDialog
