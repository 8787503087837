import React, { useEffect, useReducer, useRef, useCallback } from 'react'
import { useIntl } from 'react-intl'
import FlowLayout from 'common/layouts/FlowLayout'
import { addNotification } from 'common/actions/notification'
import { UserButton, TextField, UserTextLink } from '@admin-ui-common/base-user'
import { Link as RouterLink } from 'react-router-dom'
import { Box } from '@mui/material'
import { get, isEmpty } from 'lodash'
import { getOperationErrorCode } from '@admin-ui-common/utils'
import {
  formatErrorMessage,
  formatErrorTitle,
} from 'common/utils/processBackendErrors'
import { InlineNotification } from '@admin-ui-common/base-user'
import Collapse from '@mui/material/Collapse'
import FeatureFlagsService, {
  hasUIFeatureFlag,
} from '../../common/services/FeatureFlagsService'
import useRecaptcha from '../../common/components/hooks/useRecaptcha'
import ReCAPTCHA from '../../common/components/ReCAPTCHA'
import useMainRedirect from 'common/components/hooks/useMainRedirect'
import process from 'common/utils/process'
import { prependPlusSignIfMobile } from 'common/utils/formatMobile'
import { useFido2Login } from 'common/components/hooks/useFido2Login'
import { sendFido2Finish, sendLoginOption } from './SignInV2'

function ButtonLink(props) {
  const { to, ...rest } = props

  const renderLink = React.useMemo(
    () =>
      React.forwardRef((itemProps, ref) => (
        <RouterLink to={to} ref={ref} {...itemProps} />
      )),
    [to],
  )

  return <UserButton component={renderLink} {...rest} />
}

const passwordStepActions = {
  START_PROCESS: 'START_PROCESS',
  PROCESS_ERRORED_GENERIC: 'PROCESS_ERRORED_GENERIC',
  PROCESS_ERRORED_DEACTIVATED_USER: 'PROCESS_ERRORED_DEACTIVATED_USER',
  PROCESS_ERRORED_BAD_PASSWORD: 'PROCESS_ERRORED_BAD_PASSWORD',
  PROCESS_COMPLETED: 'PROCESS_COMPLETED',
  GOT_INVALID_PASSWORD: 'GOT_INVALID_PASSWORD',
  GOT_VALID_PASSWORD: 'GOT_VALID_PASSWORD',
  USER_PROFILE_LOCKED: 'USER_PROFILE_LOCKED',
}

const processStatus = {
  READY: 'READY',
  RUNNING: 'RUNNING',
  ERRORED: 'ERRORED',
  SUCCEEDED: 'SUCCEEDED',
}

const passwordStepInitialState = {
  errors: {},
  nextStep: null,
  processStatus: processStatus.READY,
}

function passwordStepReducer(state, action) {
  switch (action.type) {
    case passwordStepActions.START_PROCESS:
      return { ...state, processStatus: processStatus.RUNNING }
    case passwordStepActions.PROCESS_ERRORED_BAD_PASSWORD:
      return {
        ...state,
        processStatus: processStatus.ERRORED,
        errors: action.payload.errors,
      }
    case passwordStepActions.PROCESS_COMPLETED:
      return { ...state, processStatus: processStatus.SUCCEEDED }
    case passwordStepActions.GOT_INVALID_PASSWORD:
      return { ...state, errors: action.payload.errors }
    case passwordStepActions.USER_PROFILE_LOCKED:
      return { ...state, errors: action.payload.errors }
    case passwordStepActions.GOT_VALID_PASSWORD:
      return { ...state, errors: {} }
    case passwordStepActions.PROCESS_ERRORED_DEACTIVATED_USER:
      return {
        ...state,
        processStatus: processStatus.ERRORED,
        errors: action.payload.errors,
      }
    case passwordStepActions.PROCESS_ERRORED_GENERIC:
      return {
        ...state,
        processStatus: processStatus.ERRORED,
        errors: action.payload.errors,
      }
    default:
      return state
  }
}

export function PasswordStepView({
  intl,
  errors,
  handleFormSubmit,
  validate,
  identifier,
  waitingForResponse,
  cannotSubmit,
  showFido2,
  promptCaptcha,
  recaptchaRef,
  handleFido2Click,
  showOtp,
  handleOtpClick,
  showLink,
  handleLinkClick,
  showForgotPassword,
  showSubtitleLink,
}) {
  const loginWithOTP = hasUIFeatureFlag('loginWithOTP')
  const loginWithMagicLink = hasUIFeatureFlag('loginWithMagicLink')
  return (
    <FlowLayout
      title={intl.formatMessage({
        id: 'separatedMultiStep.authenticate-enterPassword.title',
      })}
      subtitle={
        showSubtitleLink && (
          <span>
            {intl.formatMessage(
              {
                id: 'separatedMultiStep.authenticate-enterPassword.subtitle',
              },
              {
                id: prependPlusSignIfMobile(identifier),
                'text-strong': str => <strong>{str}</strong>,
              },
            )}{' '}
            <RouterLink to="/signin">
              <UserTextLink underline="always">
                {intl.formatMessage({
                  id:
                    'separatedMultiStep.authenticate-enterPassword.textLink.notYou',
                })}
              </UserTextLink>
            </RouterLink>
          </span>
        )
      }
    >
      <form onSubmit={handleFormSubmit}>
        <Collapse in={!isEmpty(errors.message)}>
          <Box mb={3}>
            <InlineNotification
              title={errors.title}
              message={errors.message}
              variant="error"
              hideClose
              showMessageAsSpan
            />
          </Box>
        </Collapse>
        <Box mb={4}>
          <TextField
            fullWidth
            error={errors.password}
            helperText={errors.password}
            id="password"
            name="password"
            label={intl.formatMessage({
              id:
                'separatedMultiStep.authenticate-enterPassword.label.password',
            })}
            inputType="password"
            onChange={validate}
            aria-required
            autoFocus
            inputProps={{ 'data-testid': 'password-textfield' }}
          />
        </Box>
        <Box display="inline-block" mr={2}>
          <UserButton
            type="submit"
            size="large"
            disabled={cannotSubmit}
            isLoading={waitingForResponse}
          >
            {intl.formatMessage({
              id:
                'separatedMultiStep.authenticate-enterPassword.button.authenticate',
            })}
          </UserButton>
        </Box>
        {showForgotPassword && (
          <Box display="inline-block">
            <ButtonLink to="/forgot" variant="text" size="large">
              {intl.formatMessage({
                id:
                  'separatedMultiStep.authenticate-enterPassword.resetPassword',
              })}
            </ButtonLink>
          </Box>
        )}
      </form>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: '10px',
          paddingTop: '40px',
        }}
      >
        {showFido2 && (
          <UserButton onClick={handleFido2Click} fullWidth>
            {intl.formatMessage({ id: 'common.button.fido2-login' })}
          </UserButton>
        )}
        {loginWithOTP && showOtp && (
          <UserButton onClick={handleOtpClick} fullWidth>
            {intl.formatMessage({ id: 'common.button.otp-login' })}
          </UserButton>
        )}
        {loginWithMagicLink && showLink && (
          <UserButton onClick={handleLinkClick} fullWidth>
            {intl.formatMessage({ id: 'common.button.magic-link-login' })}
          </UserButton>
        )}
      </Box>
      {promptCaptcha ? (
        <ReCAPTCHA
          recaptchaTerms="separatedMultiStep.authenticate-enterPassword.caption.reCAPTCHA"
          ref={recaptchaRef}
        />
      ) : null}
    </FlowLayout>
  )
}

export default function PasswordStep({
  wizard,
  addPasswordStep,
  getPageIndex,
  pages,
  steps,
  history,
  showForgotPassword = true,
  showSubtitleLink = true,
  fallback = false,
}) {
  const intl = useIntl()
  const wizardState = wizard.getPageState()

  const link = wizardState?.loginOptions?.link
  const fido2 = wizardState?.loginOptions?.fido2
  const otp = wizardState?.loginOptions?.otp
  const username = wizardState.username
  const processId = wizardState.processId

  const [state, dispatch] = useReducer(
    passwordStepReducer,
    passwordStepInitialState,
  )

  const promptCaptcha = FeatureFlagsService.get('system.security.promptCaptcha')
  const mainRedirect = useMainRedirect()
  const recaptchaRef = useRef()
  const { toggleRecaptcha, resetRecaptcha } = useRecaptcha()
  const fido2Login = useFido2Login(username, processId)

  useEffect(() => {
    return () => {
      if (recaptchaRef) {
        resetRecaptcha(recaptchaRef)
      }
    }
  }, [resetRecaptcha])

  function validate(event) {
    const value = event.target.value.trim()

    const newErrors = {}

    if (value.length === 0) {
      newErrors.password = formatErrorTitle({ intl, id: 'error.credential' })
    }

    if (Object.keys(newErrors).length > 0) {
      dispatch({
        type: passwordStepActions.GOT_INVALID_PASSWORD,
        payload: {
          errors: newErrors,
        },
      })
    } else {
      dispatch({ type: passwordStepActions.GOT_VALID_PASSWORD })
    }
  }

  async function handleFormSubmit(e) {
    e.preventDefault()

    // if the current step is login options, an extra process call here
    if (wizardState.isLoginOptionsStep) {
      try {
        await sendLoginOption(wizardState.processId, 'password')
      } catch (e) {
        dispatch(
          addNotification({
            message: intl.formatMessage({
              id: 'error.unexpected',
            }),
            variant: 'error',
          }),
        )
      }
    }

    const formData = new FormData(e.target)
    const password = formData.get('password')

    if (password.length === 0) {
      const newErrors = {}
      newErrors.password = formatErrorTitle({ intl, id: 'error.credential' })

      if (Object.keys(newErrors).length > 0) {
        dispatch({
          type: passwordStepActions.GOT_INVALID_PASSWORD,
          payload: {
            errors: newErrors,
          },
        })
      }
      return
    }

    dispatch({ type: passwordStepActions.START_PROCESS })

    if (promptCaptcha) {
      const recaptcha = await recaptchaRef.current.executeAsync()
      recaptchaRef.current.reset()
      performPasswordStep(formData, recaptcha)
    } else {
      performPasswordStep(formData)
    }
  }

  const handleFido2Click = useCallback(async () => {
    // login prompt step. use data in callback for useFido2Login
    try {
      const res = await sendLoginOption(wizardState.processId, 'fido2')
      const stepData = res.body
      const stepName = stepData?.stepName

      fido2Login(async function onFido2LoginComplete() {
        if (stepName === 'Fido2FinishPrompt') {
          const res = await sendFido2Finish(wizardState.processId)

          if (get(res, 'body.userAuthenticated') === true) {
            wizard.toPage(getPageIndex(pages.LoggedIn))
          }
          return
        }
      })
    } catch (e) {
      dispatch(
        addNotification({
          message: intl.formatMessage({
            id: 'notification.fido-login-error',
          }),
          variant: 'error',
        }),
      )
    }
  }, [username, wizard, fido2Login, mainRedirect, steps])

  const handleOtpClick = useCallback(async () => {
    try {
      const res = await sendLoginOption(wizardState.processId, 'otp')
      wizard.setPageState({
        loginOptions: wizardState.loginOptions,
        previousPage: pages.PasswordStep,
        username: wizardState.username,
        usernameType: wizardState.usernameType,
        lastStep: get(res, 'body.lastStep', false),
        pkat: get(res, 'body.output.pkat'),
        processId: get(res, 'body.processId'),
      })
      wizard.toPage(getPageIndex(pages.OtpStep))
    } catch (e) {
      dispatch(
        addNotification({
          message: intl.formatMessage({
            id: 'notification.otp-login-error',
          }),
          variant: 'error',
        }),
      )
    }
  }, [username, wizard, sendLoginOption, mainRedirect, steps])

  const handleLinkClick = useCallback(async () => {
    try {
      const res = await sendLoginOption(wizardState.processId, 'link')
      const stepData = res.body

      wizard.setPageState({
        ...wizardState,
        loginOptions: wizardState.loginOptions,
        previousPage: pages.PasswordStep,
        lastStep: get(stepData, 'lastStep', false),
        pkat: get(stepData, 'output.pkat'),
        processId: get(stepData, 'processId'),
      })
      wizard.toPage(getPageIndex(pages.MagicLinkStep))
    } catch (e) {
      dispatch(
        addNotification({
          message: intl.formatMessage({
            id: 'notification.fido-login-error',
          }),
          variant: 'error',
        }),
      )
    }
  }, [username, wizard, mainRedirect, steps])

  const performPasswordStep = async (formData, recaptcha) => {
    const parameters = {
      password: formData.get('password'),
    }

    if (recaptcha) parameters.captchaResponse = recaptcha

    try {
      const res = await addPasswordStep(
        { processId: wizardState.processId },
        parameters,
      )

      dispatch({ type: passwordStepActions.PROCESS_COMPLETED })

      const stepName = get(res, 'body.stepName')
      const stepData = get(res, 'body')
      if (stepName) {
        if (process.isProcess(stepData) && stepName === 'UpdatePassword') {
          history.push({
            pathname: '/password_expire',
            state: stepData,
          })
        }

        if (stepName === 'AccountIdentifierPrompt') {
          wizard.setPageState(stepData)
          wizard.toPage(getPageIndex(pages.EnforceAccountAssociationStep))
          return
        }

        if (stepName === steps.TwoFASetupMethodPrompt) {
          wizard.setPageState(stepData)
          wizard.toPage(getPageIndex(pages.TwoStepSetupStep))
          return
        }

        if (stepName === steps.TwoFACodePrompt) {
          wizard.setPageState(stepData)
          wizard.toPage(getPageIndex(pages.TwoStepCode))
          return
        }

        if (stepName === steps.TwoFAMethodPrompt) {
          wizard.setPageState(stepData)
          wizard.toPage(getPageIndex(pages.TwoStepMethod))
          return
        }

        if (stepName === steps.TotpVerificationPrompt) {
          wizard.setPageState(stepData)
          wizard.toPage(getPageIndex(pages.TotpVerificationStep))
          return
        }
      }

      const isStaticRedirect = mainRedirect(res.metadata)
      if (!isStaticRedirect) {
        return
      }
      if (fallback) {
        history.push('/profile')
      }

      if (get(res, 'body.userAuthenticated') === true) {
        wizard.toPage(getPageIndex(pages.LoggedIn))
      }
    } catch (e) {
      toggleRecaptcha()

      if (
        get(e, 'body.lastFailedStepAction.stepName') === steps.PasswordPrompt
      ) {
        dispatch({
          type: passwordStepActions.PROCESS_ERRORED_BAD_PASSWORD,
          payload: {
            errors: {
              password: formatErrorMessage({
                intl,
                id: 'error.invalid-credential',
              }),
            },
          },
        })
        return
      }

      const errorCode = getOperationErrorCode(e.body)
      switch (errorCode) {
        case 'process-expired':
          history.push({
            pathname: '/signin',
          })
          break
        case 'user-not-activated':
          dispatch({
            type: passwordStepActions.PROCESS_ERRORED_DEACTIVATED_USER,
            payload: {
              errors: {
                title: formatErrorTitle({
                  intl,
                  id: 'error.user-not-activated.title',
                }),
                message: formatErrorMessage({
                  intl,
                  id: 'error.user-not-activated',
                }),
              },
            },
          })
          break
        case 'unverified-identifier':
          let username = get(wizardState, 'username').includes('@')
            ? 'email'
            : 'mobile number'
          dispatch({
            type: passwordStepActions.PROCESS_ERRORED_BAD_PASSWORD,
            payload: {
              errors: {
                title: formatErrorTitle({
                  intl,
                  id: 'error.unverified-identifier.title',
                  params: { username },
                }),
                message: formatErrorMessage({
                  intl,
                  id: 'error.unverified-identifier',
                  params: { username },
                }),
              },
            },
          })
          break
        case 'user-profile-locked':
        case 'proces-terminated-with-too-many-retries':
          dispatch({
            type: passwordStepActions.USER_PROFILE_LOCKED,
            payload: {
              errors: {
                title: formatErrorTitle({
                  intl,
                  id: 'error.user-profile-locked.context.sign-in',
                }),
                message: formatErrorMessage({
                  intl,
                  id: 'error.please-try-again-later',
                }),
              },
            },
          })
          break
        default:
          dispatch({
            type: passwordStepActions.PROCESS_ERRORED_GENERIC,
            payload: {
              errors: {
                password: formatErrorMessage({
                  intl,
                  id: 'error.invalid-credential',
                }),
              },
            },
          })
      }
    }
  }

  const waitingForResponse = state.processStatus === processStatus.RUNNING

  return (
    <PasswordStepView
      intl={intl}
      errors={state.errors}
      handleFormSubmit={handleFormSubmit}
      promptCaptcha={promptCaptcha}
      cannotSubmit={
        state.processStatus === state.errors.password || waitingForResponse
      }
      waitingForResponse={waitingForResponse}
      validate={validate}
      identifier={get(wizardState, 'username')}
      recaptchaRef={recaptchaRef}
      showFido2={fido2 && wizardState.isLoginOptionsStep}
      handleFido2Click={
        fido2 && wizardState.isLoginOptionsStep ? handleFido2Click : null
      }
      showOtp={otp && wizardState.isLoginOptionsStep}
      handleOtpClick={
        otp && wizardState.isLoginOptionsStep ? handleOtpClick : null
      }
      showLink={link && wizardState.isLoginOptionsStep}
      handleLinkClick={
        link && wizardState.isLoginOptionsStep ? handleLinkClick : null
      }
      showForgotPassword={showForgotPassword}
      showSubtitleLink={showSubtitleLink}
    />
  )
}
