import * as actionTypes from 'common/constants/twoStepVerification'

import { get } from 'lodash'

const getRegisterMFAState = () => ({
  emailPhone: {},
  totpApp: {},
  trustedDevices: [],
})

const RegisterMFA = (state = getRegisterMFAState(), action) => {
  switch (action.type) {
    case actionTypes.POST_REGISTER_MFA_EMAIL_PHONE_REQUEST:
      return {
        ...state,
        error: null,
        inProgress: true,
      }
    case actionTypes.POST_REGISTER_MFA_EMAIL_PHONE_SUCCESS: {
      return {
        ...state,
        emailPhone: {
          password: action.payload,
        },
        error: null,
        inProgress: false,
      }
    }
    case actionTypes.POST_REGISTER_MFA_EMAIL_PHONE_FAILURE:
      return {
        ...state,
        error: action.error,
        inProgress: false,
      }
    case actionTypes.POST_REGISTER_MFA_STEP_REQUEST:
      return {
        ...state,
        error: null,
        inProgress: true,
      }
    case actionTypes.POST_REGISTER_MFA_STEP_SUCCESS: {
      const { stepName } = action.payload
      return {
        ...state,
        ...(state.emailPhone && {
          emailPhone: {
            ...state.emailPhone,
            [stepName]: action.payload,
          },
        }),
        ...(state.totpApp && {
          totpApp: {
            ...state.totpApp,
            [stepName]: action.payload,
          },
        }),
        error: null,
        inProgress: false,
      }
    }
    case actionTypes.POST_REGISTER_MFA_STEP_FAILURE:
      return {
        ...state,
        error: action.error.body,
        inProgress: false,
      }
    case actionTypes.POST_REGISTER_MFA_APP_REQUEST:
      return {
        ...state,
        error: null,
        inProgress: true,
      }
    case actionTypes.POST_REGISTER_MFA_APP_SUCCESS: {
      return {
        ...state,
        totpApp: {
          password: action.payload,
        },
        error: null,
        inProgress: false,
      }
    }
    case actionTypes.POST_REGISTER_MFA_APP_FAILURE:
      return {
        ...state,
        error: action.error,
        inProgress: false,
      }
    case actionTypes.POST_REGISTER_MFA_APP_STEP_REQUEST:
      return {
        ...state,
        error: null,
        inProgress: true,
      }
    case actionTypes.POST_REGISTER_MFA_APP_STEP_SUCCESS: {
      const { stepName } = action.payload
      return {
        ...state,
        totpApp: {
          ...state.totpApp,
          [stepName]: action.payload,
        },
        error: null,
        inProgress: false,
      }
    }
    case actionTypes.POST_REGISTER_MFA_APP_STEP_FAILURE:
      return {
        ...state,
        error: action.error.body,
        inProgress: false,
      }
    case actionTypes.UPDATE_SELECTED_IDENTITY: {
      return {
        ...state,
        emailPhone: {
          ...state.emailPhone,
          selectedIdentity: action.payload,
        },
        error: null,
        inProgress: false,
      }
    }
    case actionTypes.CLEAR_EMAIL_PHONE: {
      return {
        ...state,
        emailPhone: {},
        error: null,
        inProgress: false,
      }
    }
    case actionTypes.GET_TRUSTED_DEVICES_RESPONSE: {
      return {
        ...state,
        trustedDevices: get(action.response, 'body').filter(deviceInfo => {
          return (
            get(deviceInfo, 'association.attributes.trustedDevice') === true
          )
        }),
      }
    }
    default:
      return state
  }
}

export default RegisterMFA
