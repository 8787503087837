import { useSelector } from 'react-redux'
import get from 'lodash/fp/get'
import isPhone from 'common/utils/isPhone'

export function useEmailValidator() {
  const emailValidator = useSelector(
    get(['settings', 'publicSettings', 'process.validEmailPattern']),
  )

  const emailRegex = new RegExp(emailValidator)

  return str => emailRegex.test(str)
}

export function usePhoneValidator() {
  const phoneValidator = useSelector(
    get(['settings', 'publicSettings', 'process.validPhonePattern']),
  )

  const phoneRegex = new RegExp(phoneValidator)
  return str => isPhone(str, phoneRegex)
}
