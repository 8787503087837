import React, { createRef } from 'react'
import { connect } from 'react-redux'
import { injectIntl } from 'react-intl'
import { withRouter, Redirect } from 'react-router-dom'
import { compose } from 'redux'
import {
  get,
  isEmpty,
  cloneDeep,
  isEqual,
  isObject,
  isString,
  has,
} from 'lodash'

import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import {
  socialMediaInitiateProcess,
  continueSocialMedia,
  clearState,
} from 'common/actions/socialMedia'
import VerifyUserInfo from './VerifyUserInfo'
import VerifyUsername from '../lander/signup/VerifyUsername'
import ResultVerifyUsername from '../lander/signup/ResultVerifyUsername'
import { Button } from 'common/components/button/Button'

import { checkSession } from 'common/actions/session'

import ExistingUser from './ExistingUser'

import { constants } from 'common/components/Constants'
import { CAPTCHA_RESPONSE } from 'common/constants'
import withReCaptcha from 'common/components/hoc/withReCaptcha'
import {
  getErrorCodes,
  formatErrorMessage,
  resolveErrorMessage,
  resolveErrorTitle,
} from 'common/utils/processBackendErrors'
import FeatureFlagsService, {
  getNameFormat,
} from 'common/services/FeatureFlagsService'
import SocialPaper from './SocialPaper'
import { Title, Body } from '../common/components/Typography'
import BasicLayout from 'common/layouts/BasicLayout'
import { Box } from '@mui/material'
import EnforceAccountAssociationWizard from './EnforceAccountAssociationWizard'

const errorContext = 'socialEnd'
const initState = {
  step: null,
  providerId: '',
  inputs: { termsAndConditions: false },
  errors: {},
}

class SocialMediaConnect extends React.Component {
  constructor(props) {
    super(props)

    this.state = cloneDeep(initState)
    this.recaptchaRef = createRef()
    this.promptCaptcha = FeatureFlagsService.get(
      'system.security.promptCaptcha',
    )
  }

  componentDidMount() {
    // initial landing
    if (this.props.socialMedia.data === null) {
      const query = this.props.location.search

      if (query.includes('code=')) {
        this.props.socialMediaInitiateProcess(query)
      } else {
        this.props.history.push('/')
      }
    } else {
      this.updateState(this.props, true)
    }
  }

  updateState(nextProps, fromConstructor) {
    const pkat = get(nextProps, 'socialMedia.data.output.pkats[0]')
    const tokenId = get(nextProps, 'socialMedia.data.output.tokenIds[0]')
    const statusCode = get(nextProps, 'socialMedia.data.statusCode')

    if (!isEmpty(nextProps.socialMedia.data) && statusCode === 200 && !pkat) {
      this.handleRedirect()
      return
    }

    if (
      (fromConstructor ||
        !isEqual(this.props.socialMedia.data, nextProps.socialMedia.data)) &&
      !isEmpty(nextProps.socialMedia.data) &&
      (statusCode === 202 || statusCode === 200)
    ) {
      const newState = this.generateNewStateData(
        nextProps.socialMedia.data,
        pkat,
        tokenId,
      )
      const { step } = newState

      if (!step && !pkat) {
        return
      }

      if (
        step === 'confirmAssociationWithSocialMedia' ||
        step === 'confirmAssociation'
      ) {
        this.setState(newState, this.handleSubmit)
        return
      }
      this.setState(newState)
    }
  }

  componentWillReceiveProps(nextProps) {
    this.updateState(nextProps)
  }

  componentWillUnmount() {
    this.props.resetRecaptcha(this.recaptchaRef.current)
  }

  onError = err => {
    const captchaResponse = has(
      err,
      'body.lastFailedStepAction.parameters.captchaResponse',
    )
    this.props.toggleRecaptcha(captchaResponse)
  }

  getInputs = inputData =>
    Object.keys(inputData)
      .filter(key => !['id', 'displayName'].includes(key))
      .reduce((obj, key) => {
        obj[key] = inputData[key]
        return obj
      }, {})

  generateNewStateData = (data, pkat, tokenId) => {
    const { inputs } = this.state
    let newState = cloneDeep(initState)
    let { stepName } = data

    if (pkat && tokenId) {
      stepName = 'VerifyUsername'
    }

    switch (stepName) {
      case 'AccountIdentifierPrompt':
        newState.step = 'accountIdentifierPrompt'
        newState.stepData = data
        break

      case 'SocialUserInfoPrompt':
        newState.step = 'verifyUserInfo'
        newState.providerId = get(data, 'output.providerId')
        newState.inputs = {
          ...newState.inputs,
          ...this.getInputs(data.output.socialUserInfo),
        }
        if (getNameFormat() === constants.FULL_NAME) {
          newState.inputs.displayName = `${newState.inputs.firstName} ${newState.inputs.lastName}`
          delete newState.inputs.firstName
          delete newState.inputs.lastName
        }
        break
      case 'CredentialPrompt':
        newState.step = 'credentialPrompt'
        newState.providerId = get(data, 'output.providerId')
        newState.inputs = {
          ...newState.inputs,
          ...this.getInputs(data.output.socialUserInfo),
        }
        break
      case 'VerifyUsername':
        newState.step = 'verifyUsername'
        newState.pkat = pkat
        newState.tokenId = tokenId
        newState.inputs = {
          ...newState.inputs,
          ...inputs,
        }

        break

      case 'ConfirmAssociation':
        newState.step = 'confirmAssociation'
        Object.keys(data.output.selectedUserId)
          .filter(key => key !== 'id' && key !== 'displayName')
          .forEach(paramName => {
            newState.inputs[paramName] = data.output.selectedUserId[paramName]
          })
        if (getNameFormat() === constants.FULL_NAME) {
          delete newState.inputs.firstName
          delete newState.inputs.lastName
        } else {
          delete newState.inputs.displayName
        }
        break
      case 'ConfirmAssociationPrompt':
        newState.step = 'confirmAssociationWithSocialMedia'
        newState.providerId = get(data, 'output.providerId')
        break
      default:
        newState = {}
    }

    return newState
  }

  handleChange = inputField => payload => {
    const newInputs = this.state.inputs
    const { step } = this.state

    let value
    if (payload.target.type === 'checkbox') {
      value = isObject(payload) ? payload.target.checked : payload
    } else {
      value = isObject(payload) ? payload.target.value : payload
    }

    if (step === 'verifyUserInfo' || step === 'credentialPrompt') {
      newInputs[inputField] = value
    }

    this.setState({ inputs: newInputs })
  }

  handleSubmit = recaptchaToken => {
    // TODO: have key called step on state, submission changes based on step
    const { step, inputs } = this.state
    const { intl } = this.props
    let recaptchaPayload = {}

    if (this.promptCaptcha && isString(recaptchaToken) && recaptchaToken) {
      recaptchaPayload = { [CAPTCHA_RESPONSE]: recaptchaToken }
    }

    if (step === 'verifyUserInfo') {
      const errors = {}

      if (getNameFormat() === constants.FULL_NAME) {
        if (isEmpty(inputs.displayName)) {
          errors.firstName = intl.formatMessage({ id: 'error.display-name' })
        }
      }

      if (getNameFormat() === constants.FIRST_LAST_NAME) {
        if (isEmpty(inputs.firstName)) {
          errors.firstName = intl.formatMessage({ id: 'error.firstname' })
        }
        if (isEmpty(inputs.lastName)) {
          errors.lastName = intl.formatMessage({ id: 'error.lastname' })
        }
      }

      if (!isEmpty(errors)) {
        return this.setState({ errors })
      }

      delete inputs.termsAndConditions
      return this.props
        .continueSocialMedia(
          {
            parameters: {
              ...inputs,
              confirm: 'true',
              ...recaptchaPayload,
            },
          },
          this.props.locale,
        )
        .catch(this.onError)
    }

    if (step === 'credentialPrompt') {
      const errors = {}

      if (isEmpty(inputs.password)) {
        errors.password = intl.formatMessage({ id: 'error.credential' })
      } else if (inputs.password.length < 6) {
        errors.password = intl.formatMessage({
          id: 'error.credential-at-least-six-characters',
        })
      }

      if (!isEmpty(errors)) {
        return this.setState({ errors })
      }

      return this.props
        .continueSocialMedia(
          {
            parameters: {
              credential: inputs.password,
              ...recaptchaPayload,
            },
          },
          this.props.locale,
        )
        .catch(this.onError)
    }

    if (step === 'confirmAssociation') {
      return this.props
        .continueSocialMedia(
          {
            parameters: {
              confirm: true,
              ...recaptchaPayload,
            },
          },
          this.props.locale,
        )
        .catch(this.onError)
    }

    if (step === 'confirmAssociationWithSocialMedia') {
      return this.props
        .continueSocialMedia(
          {
            parameters: {
              confirm: true,
              ...recaptchaPayload,
            },
          },
          this.props.locale,
        )
        .then(this.props.checkSession)
        .catch(this.onError)
    }
  }

  handleVerified = (verified, response) => {
    const state = {
      step: 'resultVerifyUsername',
      verified,
    }

    if (!isEmpty(response)) {
      const userAuthenticated = get(response, 'body.userAuthenticated')
      state.response = response

      if (userAuthenticated) {
        state.userAuthenticated = userAuthenticated
      }
    }

    this.setState(state)
  }

  goHome = () => {
    this.props.clearState()
    this.props.history.push('/')
  }

  handleRedirect = () => {
    this.props.checkSession()
    this.props.history.push('/')
  }

  isOIDCError = errorCode => {
    const regExp = /^oidc-/gi
    return regExp.test(errorCode)
  }

  getErrorTitle = () => {
    return resolveErrorTitle({
      intl: this.props.intl,
      error: this.props.socialMedia,
      context: errorContext,
    })
  }

  getErrorMessage = () => {
    const { socialMedia: { error } = {}, intl } = this.props
    const errorCodes = getErrorCodes(error)

    if (errorCodes.some(this.isOIDCError)) {
      return formatErrorMessage({
        intl,
        id: `error.unexpected.context.${errorContext}`,
      })
    }

    return resolveErrorMessage({
      intl,
      error,
      context: errorContext,
    })
  }

  onValidateSubmit = async e => {
    e.preventDefault()

    if (this.promptCaptcha) {
      const recaptcha = await this.recaptchaRef.current.executeAsync()
      this.recaptchaRef.current.reset()
      this.handleSubmit(recaptcha)
    } else {
      this.handleSubmit()
    }
  }

  render() {
    const { error, isLoading, hasFailed } = this.props.socialMedia
    const {
      inputs,
      step,
      errors,
      providerId,
      pkat,
      tokenId,
      verified,
      stepData,
    } = this.state

    const { intl } = this.props

    const usernameType = 'email'

    if (isLoading) {
      return (
        <BasicLayout>
          <div>{intl.formatMessage({ id: 'common.loading' })}</div>
        </BasicLayout>
      )
    }

    if (hasFailed && !isEmpty(error)) {
      if (error.stepName === 'CredentialPrompt' && error.statusCode === 401) {
        return (
          <BasicLayout>
            <ExistingUser
              errorMessage={intl.formatMessage({
                id: 'error.password-incorrect',
              })}
              inputs={inputs}
              providerId={providerId}
              onInputChange={this.handleChange}
              onCancel={this.goHome}
              onValidateSubmit={this.onValidateSubmit}
              recaptchaRef={this.recaptchaRef}
              promptCaptcha={this.promptCaptcha}
            />
          </BasicLayout>
        )
      } else if (error.stepName === 'CodePrompt' && error.statusCode === 400) {
        const { code } = get(error, 'operationError[0]')
        // FIX ME: UI should have providerId from the error (MINT-12199)
        const goProfile = () => this.props.history.push('/profile')
        return (
          <BasicLayout>
            <Dialog open={true}>
              <DialogTitle>
                {intl.formatMessage({
                  id: 'modal.social-media-association-fail.title',
                })}
              </DialogTitle>
              <DialogContent>
                {intl.formatMessage({ id: `error.${code}` })}
              </DialogContent>
              <DialogActions>
                <Button variant="outlined" onClick={goProfile}>
                  {intl.formatMessage({ id: 'common.button.ok' })}
                </Button>
              </DialogActions>
            </Dialog>
          </BasicLayout>
        )
      }

      return (
        <BasicLayout>
          <Box
            flex={1}
            display="flex"
            flexDirection="column"
            justifyContent="center"
          >
            <SocialPaper>
              <Title>{this.getErrorTitle()}</Title>
              <Body>{this.getErrorMessage()}</Body>
              <div className="mt2 self-end">
                <Button onClick={this.goHome}>
                  {intl.formatMessage({ id: 'common.button.ok' })}
                </Button>
              </div>
            </SocialPaper>
          </Box>
        </BasicLayout>
      )
    }

    switch (step) {
      case 'accountIdentifierPrompt':
        return <EnforceAccountAssociationWizard initialData={stepData} />

      case 'verifyUserInfo':
        return (
          <BasicLayout>
            <VerifyUserInfo
              inputs={inputs}
              providerId={providerId}
              onInputChange={this.handleChange}
              onConfirm={this.handleSubmit}
              onCancel={this.goHome}
              errors={errors}
            />
          </BasicLayout>
        )

      case 'credentialPrompt':
        return (
          <BasicLayout>
            <ExistingUser
              errorMessage={errors.password}
              inputs={inputs}
              providerId={providerId}
              onInputChange={this.handleChange}
              onValidateSubmit={this.onValidateSubmit}
              onCancel={this.goHome}
              recaptchaRef={this.recaptchaRef}
              promptCaptcha={this.promptCaptcha}
            />
          </BasicLayout>
        )

      case 'confirmAssociationWithSocialMedia':
        return (
          <BasicLayout>
            <Redirect
              to={{
                pathname: `/profile${providerId ? `/${providerId}` : ''}/`,
                showNotification: true,
              }}
            />
          </BasicLayout>
        )
      case 'verifyUsername':
        return (
          <BasicLayout>
            <VerifyUsername
              reset={this.handleRedirect}
              process="sign-up"
              username={inputs.email}
              type={usernameType}
              actionName="onboardUser"
              pkat={pkat}
              tokenId={tokenId}
              onVerified={this.handleVerified}
            />
          </BasicLayout>
        )

      case 'resultVerifyUsername':
        return (
          <BasicLayout>
            <ResultVerifyUsername
              verificationType={usernameType}
              verified={verified}
              onConfirm={this.handleRedirect}
            />
          </BasicLayout>
        )
      default:
        return null
    }
  }
}

function PageWrapper(props) {
  return <SocialMediaConnect {...props} />
}

const mapState = ({ socialMedia }) => ({
  socialMedia,
})

const mapDispatch = {
  socialMediaInitiateProcess,
  continueSocialMedia,
  checkSession,
  clearState,
}

const connectedToProps = connect(mapState, mapDispatch)

export default compose(
  connectedToProps,
  withRouter,
  injectIntl,
  withReCaptcha(),
)(PageWrapper)
