import { CHECK_TOKEN, CREATE_PASSWORD } from 'common/actions/socialAccounts'

const initState = {
  data: null,
  isLoading: false,
  hasFailed: false,
  error: null,
}

const socialAccountsReducer = (state = { ...initState }, action) => {
  switch (action.type) {
    case CHECK_TOKEN.request:
    case CREATE_PASSWORD.request:
      return { ...state, isLoading: true, hasFailed: false }

    case CHECK_TOKEN.success:
      return {
        ...state,
        isLoading: false,
        hasFailed: false,
        checkTokenData: action.data,
      }

    case CREATE_PASSWORD.success:
      return {
        ...state,
        isLoading: false,
        hasFailed: false,
        data: action.data,
      }

    case CHECK_TOKEN.error:
    case CREATE_PASSWORD.error:
      return {
        ...state,
        isLoading: false,
        hasFailed: true,
        error: action.error,
      }

    default:
      return state
  }
}

export default socialAccountsReducer
