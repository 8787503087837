import React from 'react'
import { noop } from 'lodash'

import Box from '@mui/material/Box'
import makeStyles from '@mui/styles/makeStyles'
import { UserTextLink } from '@admin-ui-common/base-user'

const useStyles = makeStyles(theme => ({
  root: {
    padding: `${theme.spacing(1)} 0`,
  },
}))

const ActionLabel = ({
  onClick,
  loading = false,
  label,
  disabled,
  icon,
  ...props
}) => {
  disabled = disabled || loading

  const classes = useStyles()

  return (
    <Box className={classes.root} {...props}>
      <Box display="flex" alignItems="center">
        <UserTextLink
          disabled={disabled}
          onClick={loading ? noop : onClick}
          variant="text"
          {...(disabled ? {} : { href: '#' })}
        >
          {label}
        </UserTextLink>
        {icon}
      </Box>
    </Box>
  )
}

export default ActionLabel
