import { Box } from '@mui/system'
import i from 'common/utils/i'
import { urlSearchFromObject } from 'common/utils/searchParams'
import { useCallback, useReducer } from 'react'
import EnterCode from './enter-code'
import Prefilled from './prefilled'
import conf from 'conf'
import { useIntl } from 'react-intl'
import {
  resolveErrorMessage,
  resolveErrorTitle,
} from 'common/utils/processBackendErrors'
import FlowLayout from 'common/layouts/FlowLayout'
import { Typography } from '@mui/material'
import SuccessIcon from '@mui/icons-material/CheckCircleOutlineRounded'
import ErrorIcon from '@mui/icons-material/HighlightOffRounded'

const statuses = {
  IDLE: 'IDLE',
  VERIFYING: 'VERIFYING',
  VERIFIED: 'VERIFIED',
  APPROVING: 'APPROVING',
  APPROVED: 'APPROVED',
  ERRORED: 'ERRORED',
}

const actions = {
  CODE_VERIFYING: 'CODE_VERIFYING',
  CODE_VERIFIED: 'CODE_VERIFIED',
  ACTIVATION_STARTED: 'ACTIVATION_STARTED',
  ACTIVATION_ERRORED: 'ACTIVATION_ERRORED',
  ACTIVATION_SUCCEEDED: 'ACTIVATION_SUCCEEDED',
}

function deviceActivateReducer(state, action) {
  switch (action.type) {
    case actions.ACTIVATION_STARTED:
      return { status: statuses.APPROVING, error: null }
    case actions.ACTIVATION_ERRORED:
      return { status: statuses.ERRORED, error: action.payload }
    case actions.CODE_VERIFIED:
      return { status: statuses.VERIFIED, error: null }
    case actions.ACTIVATION_SUCCEEDED:
      return { status: statuses.APPROVED, error: null }
    case actions.CODE_VERIFYING:
      return {
        status: statuses.VERIFYING,
        error: null,
      }
    default:
      return state
  }
}

const initialState = {
  status: statuses.IDLE,
  error: null,
}

export default function DeviceActivate({ location, history }) {
  const intl = useIntl()
  const [state, dispatch] = useReducer(deviceActivateReducer, initialState)
  const searchParams = new URLSearchParams(location.search.split('?')[1])
  const userCode = searchParams.get('user_code')
  const { status } = state

  const setCode = useCallback(
    code => {
      history.replace({
        pathname: history.location.pathname,
        search: urlSearchFromObject({ user_code: code }),
      })
    },
    [history],
  )

  const approveCode = useCallback(
    async code => {
      if (status === statuses.VERIFIED) {
        dispatch({
          type: actions.ACTIVATION_STARTED,
        })

        try {
          await i.post(`${conf.oidcRoot}/device/approve`, {
            params: {
              user_code: code,
              user_oauth_approval: true,
            },
          })
          dispatch({
            type: actions.ACTIVATION_SUCCEEDED,
          })
        } catch (err) {
          dispatch({
            type: actions.ACTIVATION_ERRORED,
            payload: {
              title: resolveErrorTitle({ intl, error: err.body }),
              description: resolveErrorMessage({
                intl,
                error: err.body,
              }),
            },
          })
        }
      }
    },
    [dispatch, intl, status],
  )

  const cancelActivation = useCallback(() => {
    dispatch({
      type: actions.ACTIVATION_ERRORED,
      payload: {
        title: 'Device authorization cancelled',
        description:
          'If you still wish to connect your device, return to your device and start the process again.',
      },
    })
  }, [dispatch])

  const verifyCode = useCallback(
    async code => {
      if (status === statuses.IDLE) {
        dispatch({ type: actions.CODE_VERIFYING })
        try {
          await i.post(`${conf.oidcRoot}/device/verify`, {
            params: { user_code: code },
          })

          dispatch({ type: actions.CODE_VERIFIED })
        } catch (err) {
          dispatch({
            type: actions.ACTIVATION_ERRORED,
            payload: {
              title: resolveErrorTitle({ intl, error: err.body }),
              description: resolveErrorMessage({
                intl,
                error: err.body,
              }),
            },
          })
        }
      }
    },
    [dispatch, intl, status],
  )

  if (state.status === statuses.ERRORED) {
    return (
      <FlowLayout
        image={
          <Box color="#c74f48" fontSize={96}>
            <ErrorIcon fontSize="inherit" strokeLinecap="round" />
          </Box>
        }
        title={state.error.title}
      >
        <Typography>{state.error.description}</Typography>
      </FlowLayout>
    )
  } else if (state.status === statuses.APPROVED) {
    return (
      <FlowLayout
        image={
          <Box color="#4C7B2C" fontSize={96}>
            <SuccessIcon fontSize="inherit" strokeLinecap="round" />
          </Box>
        }
        title={intl.formatMessage({ id: 'activate-device.success.title' })}
      >
        <Typography>
          {intl.formatMessage({ id: 'activate-device.success.message' })}
        </Typography>
      </FlowLayout>
    )
  } else if (userCode) {
    return (
      <FlowLayout
        title={intl.formatMessage({ id: 'activate-device.prefilled.title' })}
        hideContent={status !== statuses.VERIFIED}
      >
        <Prefilled
          approving={state.status === statuses.APPROVING}
          verifyCode={verifyCode}
          approveCode={approveCode}
          cancelActivation={cancelActivation}
        />
      </FlowLayout>
    )
  } else {
    return (
      <FlowLayout
        title={intl.formatMessage({ id: 'activate-device.enter-code.title' })}
      >
        <EnterCode status={state.status} setCode={setCode} />
      </FlowLayout>
    )
  }
}
