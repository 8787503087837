import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'

export default function LoggedIn({ preserveQueryArguments = true }) {
  const location = useLocation()
  const searchParams = new URLSearchParams(location.search)
  const redirectTo = searchParams.get('redirect_to')

  useEffect(() => {
    if (redirectTo) {
      window.location = window.decodeURIComponent(redirectTo)
    } else if (preserveQueryArguments) {
      window.location.reload()
    }
  }, [preserveQueryArguments, redirectTo])

  return null
}
