import { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { get } from 'lodash'
import { injectIntl } from 'react-intl'
import { UserTextLink } from '@admin-ui-common/base-user'
import Box from '@mui/material/Box'
import * as UXPDialog from 'common/components/dialog/UXPDialog'
import {
  TextPrimaryFillButton,
  TextSecondaryButton,
} from 'common/components/button/Button'
import TextField from 'common/components/textfield/TextField'
import ButtonBase from '@mui/material/ButtonBase'
import { Body, Body2 } from '../../../common/components/Typography'
import { INVALID_ACT_CONSUMPTION } from 'common/constants'
import conf from 'conf'
import i from 'common/utils/i'
import { loadUser } from 'common/actions/user'
import { addNotification } from 'common/actions/notification'
import { sendVerification } from 'common/processes/userManagement'
import { labelFormatter } from '../constants'
import withStyles from '@mui/styles/withStyles'
import {
  resolveErrorEntity,
  isVerifyOTPError,
  formatErrorMessage,
  isTokenMaxResendReached,
  isInvalidActConsumption,
  isInvalidPkat,
} from 'common/utils/processBackendErrors'
import { analyticsProvider } from '../../../App'
import qs from 'qs'
import { verifyCode } from 'common/processes/token'
import { displayIdentifier } from 'common/utils/formatNumber'
import { VerifyOTPErrors } from 'common/components/VerifyOTPErros'

const messagesMap = {
  email: {
    add: {
      title: 'profile.detail.email.verify-email',
      description: 'profile.detail.email.we-sent-verification-email',
    },
    update: {
      title: 'profile.detail.email.verify-email',
      description: 'profile.detail.email.we-sent-verification-email',
    },
  },
  mobile: {
    add: {
      title: 'profile.detail.mobile.verify-mobile',
      description: 'profile.detail.mobile.we-sent-verification-sms',
    },
    update: {
      title: 'profile.detail.mobile.verify-mobile',
      description: 'profile.detail.mobile.we-sent-verification-sms',
    },
  },
}

const styles = theme => ({
  activeCountdown: {
    color: theme.palette.primary.main,
  },
  unactiveCountdown: {
    color: theme.palette.text.hint,
  },
  linkFontColor: {
    color: theme.palette.text.primary,
  },
})
const errorContext = 'verifyUsername'

class VerifyAuthPage extends Component {
  static propTypes = {
    wizard: PropTypes.object,
    onClose: PropTypes.func,
    loadUser: PropTypes.func,
    mode: PropTypes.oneOf(['add', 'update']),
    owner: PropTypes.oneOf(['email', 'mobile']),
    addNotification: PropTypes.func,
  }

  constructor(props) {
    super(props)

    const { pkat } = props.wizard.getPageState()

    this.state = {
      error: '',
      asyncError: '',
      code: '',
      inProgress: false,
      pkat,
      countdown: conf.resendOTPCountdownDuration,
      errorResponseBody: '',
    }

    this.flowType = 'wizard'
    this.pageTitle = 'verifyAuth'
    this.baseEventTag = `${this.flowType}.${this.pageTitle}`
  }

  componentDidMount() {
    analyticsProvider.sendAnalytics({
      type: 'page_view',
      page_title: this.pageTitle,
      page_path: `/${this.flowType}#${this.pageTitle}`,
    })

    const { forceResend } = this.props.wizard.getPageState()

    if (forceResend) {
      this.resendCode().then(this.runCountDown)
    } else {
      this.runCountDown()
    }
  }

  componentWillUnmount() {
    clearInterval(this.interval)
  }

  getError = () => {
    const { error, asyncError } = this.state

    if (asyncError) {
      return asyncError
    }

    if (error) {
      return error
    }

    return ''
  }

  verifyAuth = () => {
    analyticsProvider.sendAnalytics({
      type: 'event',
      action: 'click',
      event_category: 'button',
      event_label: `${this.baseEventTag}.verify`,
      value: 0,
    })

    const { onClose, loadUser, addNotification, mode, owner, intl } = this.props
    const { code, pkat } = this.state
    const errorContext = 'verifyAuthPage'

    if (!code) {
      this.setState({
        error: intl.formatMessage({
          id: 'error.verification-code-empty',
        }),
      })
      return
    }

    this.setState(
      {
        error: '',
        inProgress: true,
      },
      () => {
        verifyCode(code, pkat)
          .then(loadUser)
          .then(() => {
            this.setState(
              {
                inProgress: false,
                error: '',
              },
              () => {
                const entity = intl.formatMessage({
                  id: `notification.${owner}`,
                })
                const actionId =
                  mode === 'update'
                    ? 'notification.updated-successfully'
                    : 'notification.added-successfully'
                const message = intl.formatMessage({ id: actionId }, { entity })

                addNotification({ message, variant: 'success' })

                onClose()
              },
            )
          })
          .catch(err => {
            analyticsProvider.sendAnalytics({
              type: 'event',
              action: 'click',
              event_category: 'button',
              event_label: `${this.baseEventTag}.verify`,
              value: 1,
            })
            this.handleOtpErrors(err)
          })
      },
    )
  }

  verifyLater = () => {
    const { loadUser, onClose, allowPostponedVerification } = this.props
    analyticsProvider.sendAnalytics({
      type: 'event',
      action: 'click',
      event_category: 'button',
      event_label: `${this.baseEventTag}.${
        allowPostponedVerification ? 'verify-later' : 'cancel'
      }`,
      value: 0,
    })

    if (!allowPostponedVerification) {
      onClose()
    } else {
      loadUser().then(onClose)
    }
  }

  cancel = () => {
    analyticsProvider.sendAnalytics({
      type: 'event',
      action: 'click',
      event_category: 'button',
      event_label: `${this.baseEventTag}.cancel`,
      value: 0,
    })

    const { onClose } = this.props

    onClose()
  }

  resendCode = ({ withCountdown } = {}) => {
    analyticsProvider.sendAnalytics({
      type: 'event',
      action: 'click',
      event_category: 'button',
      event_label: `${this.baseEventTag}.resend-code`,
      value: 0,
    })
    const { wizard } = this.props

    const { newAuth, pkat } = wizard.getPageState()
    this.setState({ inProgress: true })

    const promise = pkat
      ? i.put(`${conf.apiRoot}/session/token?${qs.stringify({ pkat })}`, {
          ignoreErrorRedirect: true,
        })
      : sendVerification(newAuth)

    return promise
      .then(res => {
        const pkat = get(res, 'body.output.pkat') || get(res, 'body.pkat')
        this.setState(
          {
            inProgress: false,
            pkat,
            countdown: conf.resendOTPCountdownDuration,
            disableVerifyButton: false,
            errorResponseBody: '',
          },
          () => {
            if (withCountdown) {
              this.runCountDown()
            }
          },
        )
        wizard.setPageState({
          ...wizard.getPageState(),
          pkat,
        })
      })
      .catch(err => {
        analyticsProvider.sendAnalytics({
          type: 'event',
          action: 'click',
          event_category: 'button',
          event_label: `${this.baseEventTag}.resend-code`,
          value: 1,
        })
        this.handleOtpErrors(err)
      })
  }

  runCountDown = () => {
    this.interval = setInterval(() => {
      const { countdown } = this.state
      if (countdown === 1) {
        clearInterval(this.interval)
      }
      this.setState({ countdown: countdown - 1 })
    }, 1000)
  }

  handleCodeChange = event => this.setState({ code: event.target.value })

  handleOtpErrors = err => {
    const { intl } = this.props

    if (isInvalidActConsumption(err.body)) {
      let errorMessage = formatErrorMessage({
        intl,
        id: 'error.verification-code-incorrect',
      })
      this.setState({
        error: errorMessage,
        disableVerify: true,
        inProgress: false,
      })
      return
    } else if (isVerifyOTPError(err.body)) {
      this.setState({
        errorResponseBody: err.body,
        disableVerifyButton: true,
        countdown: 0,
        code: '',
        inProgress: false,
      })
      clearInterval(this.interval)
      if (isInvalidPkat(err.body)) {
        this.setState({ countdown: true })
      }
      return
    } else if (isTokenMaxResendReached(err.body)) {
      clearInterval(this.interval)
      this.setState({
        errorResponseBody: err.body,
        code: '',
        countdown: true,
      })
    } else {
      this.setState({
        error: resolveErrorEntity({ intl, error: err.body }),
        inProgress: false,
      })
      return
    }
  }

  resend = str => {
    const { theme } = this.props
    return (
      <UserTextLink
        onClick={() => this.resendCode({ withCountdown: true })}
        style={{ color: theme.palette.text.primary }}
        underline="always"
        variant="text"
      >
        {str}
      </UserTextLink>
    )
  }

  render() {
    const {
      wizard,
      owner,
      mode,
      intl,
      classes,
      allowPostponedVerification,
      appId,
    } = this.props
    const {
      code,
      inProgress,
      countdown,
      errorResponseBody,
      disableVerifyButton,
    } = this.state
    const { iso2Code } = this.props.wizard.getPageState()

    const { newAuth } = wizard.getPageState()
    const newAuthLabel = displayIdentifier(newAuth, owner, appId, iso2Code)
    const errorText = this.getError()
    const countdownLabel = countdown
      ? intl.formatMessage({ id: 'common.countdown' }, { countdown })
      : intl.formatMessage({ id: 'common.countdown-complete' })

    const messages = get(messagesMap, [owner, mode], {})
    const countdownClasses = countdown
      ? classes.unactiveCountdown
      : classes.activeCountdown

    return (
      <UXPDialog.Dialog open fullScreen fullWidth maxWidth="xs">
        <UXPDialog.Title
          onClose={this.verifyLater}
          title={intl.formatMessage({ id: messages.title })}
        />
        <UXPDialog.Content>
          <Body>{newAuthLabel}</Body>
          <div className="mt2">
            <Body2>{intl.formatMessage({ id: messages.description })}</Body2>
          </div>
          <div className="pt2">
            {!!errorResponseBody && (
              <Box mb={3}>
                <VerifyOTPErrors
                  errors={errorResponseBody}
                  errorContext={errorContext}
                  handleResend={() => this.resendCode({ withCountdown: true })}
                  redirectTo={{
                    pathname: '/profile',
                  }}
                />
              </Box>
            )}
            <TextField
              errorText={errorText}
              label={intl.formatMessage({ id: 'profile.detail.enter-code' })}
              onChange={this.handleCodeChange}
              value={code}
              disabled={disableVerifyButton}
            />
          </div>
          <div className="mt1">
            <ButtonBase disableRipple disabled={Boolean(countdown)}>
              <Body2
                color={countdown ? 'textSecondary' : 'textPrimary'}
                onClick={() => this.resendCode({ withCountdown: true })}
              >
                <span className={countdownClasses}>{countdownLabel}</span>
              </Body2>
            </ButtonBase>
          </div>
        </UXPDialog.Content>
        <UXPDialog.Actions>
          <TextSecondaryButton
            data-testid={`verify-auth-button-${
              allowPostponedVerification ? 'verify-later' : 'cancel'
            }`}
            onClick={this.verifyLater}
          >
            {intl.formatMessage({
              id: allowPostponedVerification
                ? 'common.button.verify-later'
                : 'common.button.cancel',
            })}
          </TextSecondaryButton>
          <TextPrimaryFillButton
            onClick={this.verifyAuth}
            disabled={inProgress}
          >
            {intl.formatMessage({ id: 'common.button.verify' })}
          </TextPrimaryFillButton>
        </UXPDialog.Actions>
      </UXPDialog.Dialog>
    )
  }
}

const mapStateToProps = state => ({
  appId: state.configuration.config.id,
})
const mapDispatchToProps = {
  loadUser,
  addNotification,
}
const connectedProps = connect(mapStateToProps, mapDispatchToProps)

export default compose(
  connectedProps,
  injectIntl,
  withStyles(styles),
)(VerifyAuthPage)
