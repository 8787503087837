import { useEffect } from 'react'

export default function LoggedIn({ preserveQueryArguments = true }) {
  useEffect(() => {
    if (preserveQueryArguments) {
      window.location.reload()
    } else {
      window.location = window.location.pathname
    }
  }, [preserveQueryArguments])
  return null
}
