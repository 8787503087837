import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { injectIntl } from 'react-intl'

import * as UXPDialog from 'common/components/dialog/UXPDialog'
import DetailView from 'protected/common/DetailView'
import TextField from 'common/components/textfield/TextField'
import {
  TextPrimaryFillButton,
  TextSecondaryButton,
} from 'common/components/button/Button'

import { getDisplayName } from 'common/components/nameField/NameFields'
import { updateAttribute } from 'common/actions/user'
import { resolveErrorEntity } from 'common/utils/processBackendErrors'
import { analyticsProvider } from '../../../App'

class DisplayNameDetailView extends React.PureComponent {
  static propTypes = {
    updateAttribute: PropTypes.func,
    displayName: PropTypes.string,
    addNotification: PropTypes.func,
  }

  constructor(props) {
    super(props)

    this.flowType = 'detailView'
    this.pageTitle = 'name_detail_view'
    this.baseEventTag = `${this.flowType}.${this.pageTitle}`
  }

  state = {
    name: this.props.displayName,
    inProgress: false,
    error: '',
    asyncError: '',
    changeDialogOpen: false,
  }

  onChangeDialogOpen = () => {
    analyticsProvider.sendAnalytics({
      type: 'event',
      action: 'click',
      event_category: 'button',
      event_label: `${this.baseEventTag}.change-dialog-open`,
      value: 0,
    })
    this.setState({ changeDialogOpen: true })
  }

  onChangeDialogClose = () => {
    analyticsProvider.sendAnalytics({
      type: 'event',
      action: 'click',
      event_category: 'button',
      event_label: `${this.baseEventTag}.change-dialog-close`,
      value: 0,
    })
    this.setState({
      changeDialogOpen: false,
      name: this.props.displayName,
    })
  }

  onNameChange = e => this.setState({ name: e.target.value })

  getError = () => {
    const { error, asyncError } = this.state

    if (asyncError) {
      return asyncError
    }

    if (error) {
      return this.props.intl.formatMessage({ id: error })
    }

    return ''
  }

  showNotification = () => {
    const { addNotification, intl } = this.props
    const entity = intl.formatMessage({ id: 'notification.name' })

    addNotification({
      message: intl.formatMessage(
        {
          id: 'notification.updated-successfully',
        },
        { entity },
      ),
      variant: 'success',
    })
  }

  handleNameChange = () => {
    const { intl } = this.props
    analyticsProvider.sendAnalytics({
      type: 'event',
      action: 'click',
      event_category: 'button',
      event_label: `${this.baseEventTag}.change-name`,
      value: 0,
    })

    const { name } = this.state

    if (!name) {
      this.setState({ error: 'profile.detail.name.cannot-be-empty' })
      return
    }

    this.setState({
      error: '',
      asyncError: '',
      inProgress: true,
    })

    this.props
      .updateAttribute({ displayName: name })
      .then(() => {
        this.setState({ inProgress: false })
        this.onChangeDialogClose()
        this.showNotification()
      })
      .catch(err => {
        analyticsProvider.sendAnalytics({
          type: 'event',
          action: 'click',
          event_category: 'button',
          event_label: `${this.baseEventTag}.change-name`,
          value: 1,
        })
        const message = resolveErrorEntity({ intl, error: err.body })
        this.setState({
          inProgress: false,
          asyncError: message,
        })
      })
  }

  render() {
    const { displayName, intl } = this.props
    const { name, changeDialogOpen, inProgress } = this.state
    const errorText = this.getError()

    return (
      <DetailView
        title={intl.formatMessage({ id: 'profile.detail.displayName.title' })}
        description={intl.formatMessage({
          id: 'profile.detail.name.description',
        })}
      >
        <DetailView.Title
          title={displayName}
          onEditClick={this.onChangeDialogOpen}
        />

        <UXPDialog.Dialog
          open={changeDialogOpen}
          fullScreen
          fullWidth
          maxWidth="xs"
        >
          <UXPDialog.Title
            onClose={this.onChangeDialogClose}
            title={intl.formatMessage({
              id: 'profile.detail.name.update-display-name',
            })}
          />
          <UXPDialog.Content className="mt2">
            <TextField
              errorText={errorText}
              label={intl.formatMessage({
                id: 'profile.detail.displayName.title',
              })}
              onChange={this.onNameChange}
              value={name}
            />
          </UXPDialog.Content>
          <UXPDialog.Actions>
            <TextSecondaryButton onClick={this.onChangeDialogClose}>
              Cancel
            </TextSecondaryButton>
            <TextPrimaryFillButton
              onClick={this.handleNameChange}
              disabled={inProgress}
            >
              Save
            </TextPrimaryFillButton>
          </UXPDialog.Actions>
        </UXPDialog.Dialog>
      </DetailView>
    )
  }
}

const mapStateToProps = state => ({
  displayName: getDisplayName(state),
})

const mapDispatchToProps = { updateAttribute }

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(injectIntl(DisplayNameDetailView))
