import React, { useState, useEffect } from 'react'
import { useIntl } from 'react-intl'
import { get } from 'lodash'
import { Link, useLocation } from 'react-router-dom'
import { Helmet } from 'react-helmet'

import * as UXPCard from 'common/components/Card'
import { Title, Body } from 'common/components/Typography'
import MobileNumberField, {
  mobileRegex,
} from 'common/components/textfield/MobileNumberField'
import { Button, TextPrimaryButton } from 'common/components/button/Button'

import passwordRecover from 'common/processes/recover'
import process from 'common/utils/process'
import isPhone from 'common/utils/isPhone'
import { resolveErrorEntity } from 'common/utils/processBackendErrors'

import { analyticsProvider } from 'App.js'

const pkatPath = 'body.output.pkat'
const tokenIdPath = 'body.output.tokenId'

const ForgotPassword = ({ wizard, flowType }) => {
  const [error, setError] = useState('')
  const [progress, setProgress] = useState(false)
  const intl = useIntl()
  const location = useLocation()

  const pageTitle = 'forgot_password'
  const baseEventTag = `${flowType}.forgot_password`

  useEffect(() => {
    analyticsProvider.sendAnalytics({
      type: 'page_view',
      page_title: pageTitle,
      page_path: `/${flowType}#${pageTitle}`,
    })
  }, [flowType])

  const {
    originalRedirect,
    viewId,
    mobile: urlMobile,
    originProcessState,
  } = get(location, 'state', {})
  const [mobile, setMobile] = useState(urlMobile || '')

  const mobileFieldLabel = intl.formatMessage({
    id: 'onboard.common.mobile-number',
  })

  const setMobileCallback = e => {
    setMobile(e.target.value.replace(mobileRegex, ''))
  }

  const onCancel = () => {
    analyticsProvider.sendAnalytics({
      type: 'event',
      action: 'click',
      event_category: 'button',
      event_label: `${baseEventTag}.cancel`,
      value: 0,
    })
  }

  const onNextPage = async () => {
    if (!mobile) {
      const errorText = intl.formatMessage({
        id: 'onboard.error.please-enter-mobile',
      })

      setError(errorText)

      analyticsProvider.sendAnalytics({
        type: 'event',
        action: 'exception',
        description: `${baseEventTag}.phoneNumber: ${errorText}`,
      })

      return
    }

    if (!isPhone(mobile)) {
      const errorText = intl.formatMessage({
        id: 'onboard.error.not-valid-mobile',
      })

      setError(errorText)

      analyticsProvider.sendAnalytics({
        type: 'event',
        action: 'exception',
        description: `${baseEventTag}.phoneNumber: ${errorText}`,
      })

      return
    }

    setProgress(true)

    try {
      const res = await passwordRecover(mobile)

      analyticsProvider.sendAnalytics({
        type: 'event',
        action: 'click',
        event_category: 'button',
        event_label: `${baseEventTag}.next`,
        value: 0,
      })

      if (process.isCompleteResponse(res)) {
        const pkat = get(res, pkatPath)
        const tokenId = get(res, tokenIdPath)

        wizard.setPageState({
          ...wizard.getPageState(),
          pkat,
          tokenId,
          mobile,
          origin: {
            originalRedirect,
            viewId,
            originProcessState,
          },
        })
        wizard.toNextPage()
        return
      }

      const { body } = res
      const recoveryOptions = get(body, 'output.recoveryOptions')
      const [option] = Object.entries(recoveryOptions).find(recover => {
        const [, auth] = recover
        return auth.startsWith(`(${mobile[0]}`)
      })

      delete body.displayMessage
      delete body.parameters
      delete body.stepName
      delete body.returnParameters
      delete body.output

      body.parameters = {
        recoveryOptionId: String(option),
      }

      const confirmation = await process.step(body)
      const pkat = get(confirmation, pkatPath)
      const tokenId = get(confirmation, tokenIdPath)

      setProgress(false)

      wizard.setPageState({
        ...wizard.getPageState(),
        pkat,
        tokenId,
        mobile,
        origin: {
          originalRedirect,
          viewId,
        },
      })
      wizard.toNextPage()
    } catch (err) {
      const message = resolveErrorEntity({ intl, error: err.body })

      analyticsProvider.sendAnalytics({
        type: 'event',
        action: 'click',
        event_category: 'button',
        event_label: `${baseEventTag}.next`,
        value: 1,
      })

      analyticsProvider.sendAnalytics({
        type: 'event',
        action: 'exception',
        description: `${baseEventTag}.screen: ${message}`,
      })

      setError(message)
    }
  }

  return (
    <UXPCard.Card>
      <UXPCard.Content>
        <Helmet>
          <title>
            {intl.formatMessage({
              id: 'password-reset.title',
            })}
          </title>
        </Helmet>
        <Title>
          {intl.formatMessage({
            id: 'password-reset.title',
          })}
        </Title>
        <Body mb={4}>
          {intl.formatMessage({
            id: 'password-reset.enter-your-mobile',
          })}
        </Body>
        <MobileNumberField
          label={mobileFieldLabel}
          onChange={setMobileCallback}
          value={mobile}
          errorText={error}
        />
      </UXPCard.Content>
      <UXPCard.Actions>
        <Link to="/login" onClick={onCancel}>
          <TextPrimaryButton>
            {intl.formatMessage({ id: 'common.button.cancel' })}
          </TextPrimaryButton>
        </Link>
        <Button onClick={onNextPage} isLoading={progress}>
          {intl.formatMessage({ id: 'common.button.next' })}
        </Button>
      </UXPCard.Actions>
    </UXPCard.Card>
  )
}

export default ForgotPassword
