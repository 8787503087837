import React, { useState } from 'react'
import { useIntl } from 'react-intl'

import makeStyles from '@mui/styles/makeStyles'
import Box from '@mui/material/Box'

import * as UXPDialog from 'common/components/dialog/UXPDialog'
import { TextPrimaryButton, Button } from 'common/components/button/Button'
import Page from 'common/components/Page'
import { analyticsProvider } from '../../../../App'
import {
  QR_CODE_PROMPT,
  TOTP_VERIFICATION_PROMPT,
} from '../../../../common/constants/twoStepVerification'
import { Body, Body2 } from '../../../../common/components/Typography'
import { useSelector } from 'react-redux'

import { get } from 'lodash'

import QRCode from 'react-qr-code'

const useStyles = makeStyles(() => ({
  height: {
    height: 56,
  },
  bold: {
    fontWeight: 'bold',
    fontSize: 16,
  },
  paddingBottom: {
    paddingBottom: 10,
  },
}))

const thisPromptStepName = QR_CODE_PROMPT
const nextPromptStepName = TOTP_VERIFICATION_PROMPT

const parseCode = url => {
  const urlParams = new URLSearchParams(url.split('?')[1])
  return urlParams.get('secret')
}

const QRCodeDialog = ({ onClose, open, nextCallBack, flowType }) => {
  const totpState = useSelector(state => state.registerMFA.totpApp)

  const qrUrl = get(totpState, 'TotpVerificationPrompt.output.qrUrl')
  const appCode = parseCode(qrUrl)

  const classes = useStyles()

  const [inProgress, setInProgress] = useState(false)
  const intl = useIntl()

  const pageTitle = 'setup_totp_app'
  const baseEventTag = `${flowType}.${pageTitle}`

  const onNext = () => {
    analyticsProvider.sendAnalytics({
      type: 'event',
      action: 'click',
      event_category: 'button',
      event_label: `${baseEventTag}.next`,
      value: 0,
    })

    setInProgress(true)

    onClose(thisPromptStepName)
    nextCallBack(nextPromptStepName)
  }

  const closeView = () => {
    analyticsProvider.sendAnalytics({
      type: 'event',
      action: 'click',
      event_category: 'button',
      event_label: `${baseEventTag}.close`,
      value: 0,
    })
    onClose(thisPromptStepName)
  }

  return (
    <UXPDialog.Dialog open={open} fullScreen fullWidth maxWidth="sm">
      <UXPDialog.Title
        title={intl.formatMessage({
          id: 'user.management.register.QRCodeDialog.title',
        })}
        onClose={closeView}
      />
      <UXPDialog.Content>
        <Box mb="40px">
          <Page.Description className={classes.paddingBottom}>
            {intl.formatMessage({
              id: 'user.management.register.QRCodeDialog.message',
            })}
          </Page.Description>
        </Box>
        <Box height={240} width={240} mx="auto">
          <QRCode size={240} value={qrUrl} />
        </Box>
        {appCode && (
          <Box mt={3}>
            <Body2 textAlign="center">
              {intl.formatMessage({
                id: 'user.management.register.QRCodeDialog.enter-code-in-app',
              })}
            </Body2>
            <Body fontWeight="bold" mb={0} textAlign="center">
              {appCode}
            </Body>
          </Box>
        )}
      </UXPDialog.Content>
      <UXPDialog.Actions disabled={inProgress}>
        <TextPrimaryButton onClick={closeView} size="small" variant="outlined">
          {intl.formatMessage({ id: 'common.button.cancel' })}
        </TextPrimaryButton>
        <Button onClick={onNext} size="small">
          {intl.formatMessage({ id: 'common.button.next' })}
        </Button>
      </UXPDialog.Actions>
    </UXPDialog.Dialog>
  )
}

export default QRCodeDialog
