import React from 'react'

import Paper from '@mui/material/Paper'
import withStyles from '@mui/styles/withStyles'

const muiPaperStyles = theme => ({
  root: {
    [theme.breakpoints.down('sm')]: {
      borderRadius: 'unset !important',
      marginBottom: 16,
      paddingTop: 13,
      paddingLeft: 16,
      paddingRight: 16,
    },
    width: '100%',
    background: '#fff',
    borderRadius: 8,
    marginBottom: 24,
    paddingTop: 18,
    paddingLeft: 24,
    paddingRight: 24,
  },
})

const customPaper = ({ classes, children, ...rest }) => (
  <Paper classes={classes} {...rest}>
    {children}
  </Paper>
)

export default withStyles(muiPaperStyles)(customPaper)
