import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { compose } from 'redux'
import withStyles from '@mui/styles/withStyles'
import { get, noop, isEmpty } from 'lodash'
import { injectIntl } from 'react-intl'
import {
  getFullName,
  getDisplayName,
  parseFullName,
} from 'common/components/nameField/NameFields'
import ProfileItem from './ProfileItem'

import { loadUser } from 'common/actions/user'
import * as selectors from './selectors'
import { profileDetailData, labelFormatter } from './constants'
import formatMobile from 'common/utils/formatMobile'
import { getDecodedSearchUrl } from 'common/utils/getRedirectUrl'
import { isSectionVisible } from 'common/utils/getFeatureFlag'
import { hasUIFeatureFlag } from 'common/services/FeatureFlagsService'

import Grid from '@mui/material/Grid'
import Paper from '@mui/material/Paper'
import Box from '@mui/material/Box'
import Divider from '@mui/material/Divider'
import { List } from '@admin-ui-common/base-user'

import { getAvailableLocales } from 'common/constants/locale'
import { analyticsProvider } from '../../App'
import SidebarNavigationLayout from 'common/layouts/SidebarNavigationLayout'
import ListSectionTitle from './ListSectionTitle'
import { displayIdentifier } from 'common/utils/formatNumber'

const personalInfo = [
  'showDisplayNameField',
  'showFullNameField',
  'showLanguageSelector',
]
const contactInfo = [
  'showEmailField',
  'showMobileNumberField',
  'showPreferredContactField',
]

const muStyles = theme => ({
  title: {
    [theme.breakpoints.down('sm')]: {
      marginLeft: 16,
    },
  },
})

class ProfileView extends React.Component {
  static propTypes = {
    displayName: PropTypes.string,
    fullName: PropTypes.string,
    emails: PropTypes.arrayOf(PropTypes.object),
    pendingEmails: PropTypes.arrayOf(PropTypes.object),
    activatingEmails: PropTypes.arrayOf(PropTypes.object),
    mobiles: PropTypes.string,
    preferredContact: PropTypes.object,
    classes: PropTypes.object,
    addNotification: PropTypes.func,
    isCredentialSet: PropTypes.bool,
    isPinSet: PropTypes.bool,
  }

  constructor(props) {
    super(props)

    this.pageTitle = 'identity'
    this.baseEventTag = `profile.${this.pageTitle}`
    this.state = {
      showPage: false,
    }
  }

  componentDidMount() {
    analyticsProvider.sendAnalytics({
      type: 'page_view',
      page_title: this.pageTitle,
      page_path: `/profile#${this.pageTitle}`,
    })
    const signInAfterActivationRedirect = getDecodedSearchUrl() || ''
    if (signInAfterActivationRedirect) {
      window.location.href = signInAfterActivationRedirect
      return
    } else if (isEmpty(this.props.user)) {
      this.props.loadUser()
    }
    this.setState({ showPage: true })
  }

  getPreferredContactLabel = () => {
    const { preferredContact, appId } = this.props

    if (isEmpty(preferredContact)) {
      return null
    }

    return displayIdentifier(
      preferredContact.value,
      preferredContact.type,
      appId,
    )
  }

  render() {
    const {
      displayName,
      fullName,
      emails,
      mobiles,
      intl,
      addNotification,
      user,
      appId,
    } = this.props
    const {
      DISPLAY_NAME,
      FULL_NAME,
      LANGUAGE,
      EMAIL,
      MOBILE_NUMBER,
      PREFERRED_CONTACT,
    } = profileDetailData

    const preferredContactValue = this.getPreferredContactLabel()
    const remoteLocale = get(
      user,
      ['attributes', 'com.uxpsystems.mint.user.Language'],
      '',
    )
    const userLocale = getAvailableLocales().has(remoteLocale)
      ? remoteLocale
      : null

    return this.state.showPage ? (
      <SidebarNavigationLayout
        title={intl.formatMessage({ id: 'profile.title' })}
      >
        {isSectionVisible('showPersonalInfoSection', personalInfo) && (
          <Box component={Paper} marginBottom={3} py={2}>
            <Grid item xs={12}>
              <List
                subheader={
                  <ListSectionTitle
                    title={intl.formatMessage({
                      id: 'profile.personal-info.section.title',
                    })}
                  />
                }
              >
                {hasUIFeatureFlag('showDisplayNameField') && (
                  <>
                    <ProfileItem
                      name={intl.formatMessage({ id: DISPLAY_NAME.label })}
                      value={displayName}
                      path={`/profile${DISPLAY_NAME.path}`}
                      placeholder={intl.formatMessage({
                        id: DISPLAY_NAME.placeholder,
                      })}
                      interactive
                    />
                    <Divider light />
                  </>
                )}
                {hasUIFeatureFlag('showFullNameField') && (
                  <>
                    {' '}
                    <ProfileItem
                      name={intl.formatMessage({ id: FULL_NAME.label })}
                      value={fullName}
                      path={`/profile${FULL_NAME.path}`}
                      placeholder={intl.formatMessage({
                        id: FULL_NAME.placeholder,
                      })}
                      interactive
                    />
                    <Divider light />
                  </>
                )}
                {hasUIFeatureFlag('showLanguageSelector') && (
                  <>
                    <ProfileItem
                      name={intl.formatMessage({ id: LANGUAGE.label })}
                      value={intl.formatMessage({
                        id: userLocale
                          ? `language.${userLocale}`
                          : 'profile.label.language.none',
                      })}
                      path={`/profile${LANGUAGE.path}`}
                      placeholder={intl.formatMessage({
                        id: LANGUAGE.placeholder,
                      })}
                      interactive
                      addNotification={addNotification}
                    />
                  </>
                )}
              </List>
            </Grid>
          </Box>
        )}

        {isSectionVisible('showContactInfoSection', contactInfo) && (
          <Box component={Paper} marginBottom={3} py={2}>
            <Grid item xs={12}>
              <List
                subheader={
                  <ListSectionTitle
                    title={intl.formatMessage({
                      id: 'profile.contact-info.section.title',
                    })}
                    subtitle={intl.formatMessage({
                      id: 'profile.contact-info.section.message',
                    })}
                  />
                }
              >
                {hasUIFeatureFlag('showEmailField') && (
                  <>
                    {' '}
                    <ProfileItem
                      name={intl.formatMessage({ id: EMAIL.label })}
                      value={emails.map(item => item.email)}
                      path={`/profile${EMAIL.path}`}
                      placeholder={intl.formatMessage({
                        id: EMAIL.placeholder,
                      })}
                      interactive
                    />
                    <Divider light />
                  </>
                )}

                {hasUIFeatureFlag('showMobileNumberField') && (
                  <>
                    <ProfileItem
                      name={intl.formatMessage({ id: MOBILE_NUMBER.label })}
                      value={mobiles.map(item =>
                        displayIdentifier(item.number, 'mobile', appId),
                      )}
                      path={`/profile${MOBILE_NUMBER.path}`}
                      placeholder={intl.formatMessage({
                        id: MOBILE_NUMBER.placeholder,
                      })}
                      interactive
                    />
                    <Divider light />
                  </>
                )}

                {hasUIFeatureFlag('showPreferredContactField') && (
                  <>
                    <ProfileItem
                      name={intl.formatMessage({ id: PREFERRED_CONTACT.label })}
                      value={preferredContactValue}
                      path={`/profile${PREFERRED_CONTACT.path}`}
                      placeholder={intl.formatMessage({
                        id: PREFERRED_CONTACT.placeholder,
                      })}
                      interactive
                    />
                  </>
                )}
              </List>
            </Grid>
          </Box>
        )}
      </SidebarNavigationLayout>
    ) : null
  }
}

const mapStateToProps = state => ({
  user: state.user,
  displayName: getDisplayName(state),
  fullName: parseFullName(getFullName(state)),
  emails: selectors.getEmails(state, 'activated'),
  pendingEmails: selectors.getEmails(state, 'pending'),
  activatingEmails: selectors.getEmails(state, 'activating'),
  mobiles: selectors.getMobiles(state, 'activated'),
  preferredContact: selectors.getPreferredContact(state),
  state: state,
  appId: state.configuration.config.id,
})

const mapDispatchToProps = {
  loadUser,
}

const connectedToProps = connect(mapStateToProps, mapDispatchToProps)

export default compose(
  connectedToProps,
  injectIntl,
  withStyles(muStyles),
)(ProfileView)
