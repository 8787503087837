import React from 'react'
import { Switch, Route } from 'react-router-dom'
import { get } from 'lodash'

import AccountsView from './AccountsView'
import AccountDetail from './AccountDetail'

import { useSelector } from 'react-redux'

const AccountsPage = ({ match }) => {
  const isWizardOnly = useSelector(
    state => state.configuration.config.enableOnlyWizardRoutes,
  )
  return (
    <Switch>
      <Route exact path={match.path} component={AccountsView} />
      {isWizardOnly && (
        <Route
          path={`${match.path}/:id`}
          render={props => {
            const id = get(props, 'match.params.id')

            return <AccountDetail id={parseInt(id, 10)} />
          }}
        />
      )}
    </Switch>
  )
}

export default AccountsPage
