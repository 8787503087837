import React from 'react'
import {
  Box,
  Checkbox,
  FormControlLabel,
  TextField,
  Typography,
} from '@mui/material'
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank'
import CheckBoxIcon from '@mui/icons-material/CheckBox'
import { hasUIFeatureFlag } from 'common/services/FeatureFlagsService'
import { UserButton } from '@admin-ui-common/base-user'
import { useForm } from 'react-hook-form'
import { useIntl } from 'react-intl'

export default function AuthenticatorCode({
  submitCode,
  cancel,
  error,
  disabledNext,
}) {
  const intl = useIntl()

  const { handleSubmit, register } = useForm({
    defaultValues: {
      code: '',
      trustedDevice: false,
    },
  })

  const hideMarkAsTrustedDevice = hasUIFeatureFlag('hideMarkAsTrustedDevice')

  function onSubmit(values) {
    submitCode(values)
  }

  return (
    <Box component="form" onSubmit={handleSubmit(onSubmit)}>
      <Box marginBottom={3}>
        <Typography variant="body1">
          {intl.formatMessage({ id: 'sign-in-two-factor-code-otp-label' })}
        </Typography>
      </Box>
      <TextField
        inputMode="numeric"
        aria-required
        fullWidth
        autoFocus
        label={intl.formatMessage({ id: 'common.enter-code' })}
        autoComplete="one-time-code"
        {...register('code')}
        error={error}
        helperText={error}
      />
      {!hideMarkAsTrustedDevice && (
        <Box marginTop={4}>
          <FormControlLabel
            data-testid="authenticatorCode-markAsTrusted-control"
            {...register('trustedDevice')}
            control={
              <Checkbox
                color="primary"
                icon={<CheckBoxOutlineBlankIcon />}
                checkedIcon={<CheckBoxIcon />}
                {...register('trustedDevice')}
              />
            }
            label={intl.formatMessage({
              id: 'sign-in-two-factor-code-remember-device',
            })}
          />
        </Box>
      )}
      <Box marginTop={4} display="flex" gap={4}>
        <UserButton type="submit" size="large" disabled={disabledNext}>
          {intl.formatMessage({ id: 'common.button.next' })}
        </UserButton>
        <UserButton onClick={cancel} variant="outlined" size="large">
          {intl.formatMessage({ id: 'common.button.cancel' })}
        </UserButton>
      </Box>
    </Box>
  )
}
