// PLEASE NOTE:
// this will be a bit of a branching off point, same as username step
// we could get various combinations of login options here
// some options are not yet handled and they are highlighted below
// todo is to implement those

import React, { useEffect } from 'react'
import { Redirect } from 'react-router-dom'

export default function LoginOptionsStep({ wizard, pages, getPageIndex }) {
  const wizardState = wizard.getPageState()
  const { password, fido2, otp, link } = wizardState.loginOptions

  useEffect(() => {
    // as of now we assume we only get here if below condition is true
    if ((fido2 || otp || link) && password) {
      wizard.toPage(getPageIndex(pages.PasswordOrFido2Step))
    }
  }, [fido2, password])

  if (password && (fido2 || otp || link)) {
    return null
  } else {
    return <Redirect to="/something-went-wrong" />
  }
}
