import React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { injectIntl } from 'react-intl'
import get from 'lodash/get'

import conf from 'conf'
import { resolveErrorEntity } from 'common/utils/processBackendErrors'
import Typography from '@mui/material/Typography'

import Grid from '@mui/material/Grid'
import withStyles from '@mui/styles/withStyles'
import Box from '@mui/material/Box'
import Paper from '@mui/material/Paper'
import { List } from '@admin-ui-common/base-user'

import Page from 'common/components/Page'
import WrapperContainer from 'protected/common/WrapperContainer'
import BackdropLoader from 'common/components/BackdropLoader'

import EmptyView from './EmptyView'
import AccountView from './AccountView'
import LinkAccountDialog from './LinkAccountDialog'
import AssociateAccountDialog from './AssociateAccountDialog'
import ChallengeQuestionDialog from './ChallengeQuestionDialog'
import ManualDiscoveryDialog from './ManualDiscoveryDialog'
import LinkAccountButton from './LinkAccountButton'

import { addNotification } from 'common/actions/notification'
import {
  linkAccount,
  loadAccounts,
  identifierAccountStepAssociation,
  linkAccountV2,
} from 'common/actions/accounts'
import { getIsAccountsInProgress, getAccounts } from 'common/selectors/accounts'
import BasicLayout from '../../common/layouts/BasicLayout'
import EnterOTPDialog from './EnterOTPDialog'

const muStyles = theme => ({
  title: {
    display: 'flex',
    justifyContent: 'space-between',
    margin: theme.spacing(4, 0),
    [theme.breakpoints.down('sm')]: {
      margin: theme.spacing(4, 2),
      display: 'block',
    },
  },
  linkAccount: {
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(3),
    },
  },
  image: {
    marginTop: 22,
    marginBottom: 13,
    padding: 14,
    [theme.breakpoints.down('sm')]: {
      marginTop: 32,
    },
  },
  paper: {
    paddingBottom: 20,
  },
})

class AccountsView extends React.Component {
  static propTypes = {
    loadAccounts: PropTypes.func,
    intl: PropTypes.object,
    classes: PropTypes.object,
    inProgress: PropTypes.bool,
    accounts: PropTypes.array,
  }

  state = {
    openLinkAccount: false,
    associationInProgress: false,
    openAssociateAccount: false,
    openChallengeQuestion: false,
    openEnterOTP: false,
    openManualDiscovery: false,
    discoveredAccounts: [],
    stepData: {},
  }

  componentDidMount() {
    const { accounts, loadAccounts } = this.props

    if (!accounts.length) {
      loadAccounts()
    }
  }

  associationFlow = res => {
    const {
      discoveredAccounts,
      stepData,
      manual,
      challengeQuestionEnabled,
      enterOTPEnabled,
      accountNumber,
    } = res

    if (challengeQuestionEnabled) {
      // show screen for challenge question
      this.setState({
        accountNumber,
        stepData,
        openChallengeQuestion: true,
      })
      return
    }

    if (enterOTPEnabled) {
      // show screen for entering otp
      this.setState({
        accountNumber,
        stepData,
        openEnterOTP: true,
      })
      return
    }

    if (manual) {
      this.setState({
        stepData,
        openManualDiscovery: true,
      })
    } else {
      const nextState = {
        discoveredAccounts,
        stepData,
      }

      if (discoveredAccounts.length > 1) {
        nextState.openAssociateAccount = true
      }

      this.setState(nextState)
    }
  }

  initiateLinkAccount = () => {
    const { addNotification, intl, accounts } = this.props

    if (conf.dummyAccountAssociation) {
      this.setState({ associationInProgress: true })
      linkAccount(accounts.length)
        .then(this.associationFlow)
        .catch(err => {
          addNotification({
            message: resolveErrorEntity({ intl, error: err.body }),
          })
        })
        .finally(() => this.setState({ associationInProgress: false }))
    } else {
      this.setState({ openLinkAccount: true })
    }
  }

  initiateLinkAccountV2 = () => {
    const { addNotification, intl, accounts } = this.props

    if (conf.dummyAccountAssociation) {
      this.setState({ associationInProgress: true })
      linkAccountV2(accounts.length)
        .then(this.associationFlow)
        .catch(err => {
          addNotification({
            message: resolveErrorEntity({ intl, error: err.body }),
          })
        })
        .finally(() => this.setState({ associationInProgress: false }))
    } else {
      this.setState({ openLinkAccount: true })
    }
  }

  closeLinkAccount = () => this.setState({ openLinkAccount: false })

  closeAssociateAccount = stepData => {
    this.setState({ openAssociateAccount: false })
    if (stepData) {
      this.associationFlow(stepData)
    }
  }

  closeChallengeQuestion = () => this.setState({ openChallengeQuestion: false })

  closeEnterOTP = () => this.setState({ openEnterOTP: false })

  closeManualDiscovery = async payload => {
    const {
      stepData,
      challengeQuestionEnabled,
      enterOTPEnabled,
      discoveredAccounts,
      accountNumber,
    } = payload || {}

    if (challengeQuestionEnabled) {
      // show screen for challenge question
      this.setState({
        accountNumber,
        stepData,
        discoveredAccounts,
        openChallengeQuestion: true,
        openManualDiscovery: false,
      })
      return
    }

    if (enterOTPEnabled) {
      // show screen for entering otp
      this.setState({
        accountNumber,
        stepData,
        discoveredAccounts,
        openEnterOTP: true,
        openManualDiscovery: false,
      })
      return
    }

    if (stepData) {
      const res = await identifierAccountStepAssociation(stepData)
      this.setState({ openManualDiscovery: false })
      this.associationFlow(res)
    } else {
      this.setState({ openManualDiscovery: false })
    }
  }

  render = () => {
    const { classes, intl, inProgress, accounts } = this.props
    const {
      openLinkAccount,
      openAssociateAccount,
      openChallengeQuestion,
      openEnterOTP,
      openManualDiscovery,
      discoveredAccounts,
      accountNumber,
      stepData,
      associationInProgress,
    } = this.state

    let obfuscatedAccountNumber = ''
    let account = null
    if (Array.isArray(discoveredAccounts)) {
      account = discoveredAccounts[accountNumber]
      obfuscatedAccountNumber = get(account, 'obfuscatedAccountNumber', '')
    }

    const hasAccounts = Boolean(accounts.length)

    return (
      <BasicLayout>
        <WrapperContainer>
          <Grid item xs={12}>
            <Box className={classes.title}>
              <Page.Title>
                {intl.formatMessage({ id: 'accounts.title' })}
              </Page.Title>
              {hasAccounts && (
                <Box className={classes.linkAccount}>
                  <LinkAccountButton
                    onClick={this.initiateLinkAccountV2}
                    disabled={associationInProgress}
                  />
                </Box>
              )}
            </Box>
          </Grid>

          {hasAccounts ? (
            <Paper>
              <Grid item xs={12}>
                <List
                  subheader={
                    <List.ListSubheader
                      size="large"
                      component="div"
                      style={{ paddingTop: '16px' }}
                    >
                      <Typography variant="h6" color="textPrimary">
                        {intl.formatMessage({ id: 'accounts.sub-title' })}
                      </Typography>
                    </List.ListSubheader>
                  }
                >
                  {Array.isArray(accounts) &&
                    accounts.map(account => (
                      <AccountView key={account.id} account={account} />
                    ))}
                </List>
              </Grid>
            </Paper>
          ) : (
            <EmptyView
              onLinkAccount={this.initiateLinkAccountV2}
              disabled={associationInProgress}
            />
          )}

          <LinkAccountDialog
            open={openLinkAccount}
            onClose={this.closeLinkAccount}
          />
          <AssociateAccountDialog
            open={openAssociateAccount}
            onClose={this.closeAssociateAccount}
            discoveredAccounts={discoveredAccounts}
            stepData={stepData}
          />
          <ChallengeQuestionDialog
            open={openChallengeQuestion}
            onClose={this.closeChallengeQuestion}
            account={account}
            accountNumber={accountNumber}
            obfuscatedAccountNumber={obfuscatedAccountNumber}
            stepData={stepData}
          />
          <EnterOTPDialog
            open={openEnterOTP}
            onClose={this.closeEnterOTP}
            account={account}
            accountNumber={accountNumber}
            obfuscatedAccountNumber={obfuscatedAccountNumber}
            stepData={stepData}
          />
          <ManualDiscoveryDialog
            open={openManualDiscovery}
            onClose={this.closeManualDiscovery}
            stepData={stepData}
          />
          <BackdropLoader
            open={inProgress || associationInProgress}
            label={intl.formatMessage({
              id: 'accounts.searching-for-accounts',
            })}
          />
        </WrapperContainer>
      </BasicLayout>
    )
  }
}

const mapStateToProps = state => ({
  inProgress: getIsAccountsInProgress(state),
  accounts: getAccounts(state),
})

const mapDispatchToProps = {
  loadAccounts,
  addNotification,
}

const connectedToProps = connect(mapStateToProps, mapDispatchToProps)

export default compose(
  connectedToProps,
  injectIntl,
  withStyles(muStyles),
)(AccountsView)
