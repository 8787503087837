import React, { useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import FlowLayout from 'common/layouts/FlowLayout'
import Loader from 'common/components/Loader'
import { useLocation } from 'react-router-dom'
import { BackendErrorNotification } from 'common/components/backendErrorNotification/BackendErrorNotification'
import Box from '@mui/material/Box'
import { get } from 'lodash'
import process from 'common/utils/process'
import maskIdentity from 'common/utils/maskIdentity'

function AuthenticateStepup({
  wizard,
  steps,
  getPageIndex,
  pages,
  choose2FAMethod,
}) {
  const location = useLocation()
  const intl = useIntl()
  const params = new URLSearchParams(location.search)
  const acr_values = params.get('x-rp-acr_values')
  const [error, setError] = useState()

  const startLoginProcess = async () => {
    try {
      const response = await process.startGet('authentication.StepUp.v1.0', {
        acr_values: acr_values,
        ignoreErrorRedirect: true,
      })

      if (
        Object.keys(get(response, 'body.output.stepUpOptions', [])).length === 0
      ) {
        setError({
          operationErrors: [
            {
              code: 'method-not-found',
              message: 'Stepup method not found',
            },
          ],
        })
      } else if (
        Object.keys(get(response, 'body.output.stepUpOptions')).length === 1
      ) {
        const methods = Object.entries(
          get(response, 'body.output.stepUpOptions'),
        ).map(([id, value]) => ({
          id,
          ...value,
          value: maskIdentity(value?.value),
        }))
        const processId = get(response, 'body.processId')
        const stepUpOptionId = Object.keys(
          get(response, 'body.output.stepUpOptions'),
        )[0]
        const chooseResponse = await choose2FAMethod(
          { processId },
          { stepUpOptionId },
        )

        const body = get(chooseResponse, 'body')

        if (get(body, 'stepName') === steps.PasswordPrompt) {
          wizard.setPageState({
            processId: get(response, 'body.processId'),
            stepData: get(response, 'body.output'),
            twoStepOtpData: body,
            pkat: get(chooseResponse, 'body.output.pkat'),
          })
          wizard.toPage(getPageIndex(pages.PasswordPrompt))
        }

        if (get(body, 'stepName') === steps.OtpPrompt) {
          wizard.setPageState({
            processId: get(response, 'body.processId'),
            stepData: get(response, 'body.output'),
            selectedMethod: {
              ...methods[0],
              type:
                methods[0]?.subType.toUpperCase() ||
                methods[0].type.toUpperCase(),
            },
            twoStepOtpData: body,
            pkat: get(chooseResponse, 'body.output.pkat'),
          })
          wizard.toPage(getPageIndex(pages.TwoStepCode))
        }
      } else {
        if (get(response, 'body.stepName') === steps.StepUpOptionPrompt) {
          wizard.setPageState({
            previousPage: pages.AuthenticateStepup,
            lastStep: get(response, 'body.lastStep', false),
            stepData: get(response, 'body.output'),
            processId: response.body.processId,
          })
          wizard.toPage(getPageIndex(pages.TwoStepMethod))
        }
      }
      return response
    } catch (error) {
      setError(error.body)
    }
  }

  useEffect(() => {
    if (acr_values) {
      startLoginProcess()
    }
    if (!acr_values) {
      setError({
        operationErrors: [
          {
            code: 'try-again',
          },
        ],
      })
    }
  }, [acr_values])

  return (
    <FlowLayout>
      {error ? (
        <Box mb={3}>
          <BackendErrorNotification
            intl={intl}
            error={error}
            hideClose
            titleFallback={{ id: 'error.try-again' }}
          />
        </Box>
      ) : (
        <Loader />
      )}
    </FlowLayout>
  )
}

export default AuthenticateStepup
