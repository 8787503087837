import React, { useState, useEffect } from 'react'
import { get } from 'lodash'
import { useIntl } from 'react-intl'

import Box from '@mui/material/Box'

import * as UXPCard from 'common/components/Card'
import { Title, Body, Subtitle } from 'common/components/Typography'
import MobileNumberField from 'common/components/textfield/MobileNumberField'
import PasswordField from 'common/components/textfield/PasswordField'
import { TextPrimaryButton, Button } from 'common/components/button/Button'

import { resolveErrorEntity } from 'common/utils/processBackendErrors'
import process from 'common/utils/process'
import useMainRedirect from 'common/components/hooks/useMainRedirect'

import { analyticsProvider } from 'App.js'

const ThankYouNewUser = ({ wizard, flowType }) => {
  const pageState = wizard.getPageState()
  const { mobile, userAuthenticated, stepName, processId } = pageState
  const [loading, setLoading] = useState(false)
  const [password, setPassword] = useState('')
  const [error, setError] = useState('')
  const intl = useIntl()
  const mainRedirect = useMainRedirect(`/${flowType}`)

  const productInfo = get(
    pageState,
    'productDetailsDTO',
    get(pageState, 'productInfo', {}),
  )
  const { subscriptionName } = productInfo

  const pageTitle = 'thank_you_new_user'
  const baseEventTag = `${flowType}.thank_you_new_user`
  const passwordFieldLabel = intl.formatMessage({
    id: 'onboard.common.password',
  })

  useEffect(() => {
    analyticsProvider.sendAnalytics({
      type: 'page_view',
      page_title: pageTitle,
      page_path: `/${flowType}#${pageTitle}`,
    })
  }, [flowType])

  const createAccount = async noPassword => {
    let nextError
    const tagLabel = noPassword ? 'skip' : 'create'

    if (!password && !noPassword) {
      nextError = intl.formatMessage({
        id: 'onboard.error.please-enter-password',
      })

      analyticsProvider.sendAnalytics({
        type: 'event',
        action: 'exception',
        description: `${baseEventTag}.screen: ${nextError}`,
      })
    }

    setError(nextError)

    if (nextError) {
      return
    }

    try {
      setLoading(true)
      const stepPayload = {
        userAuthenticated,
        stepName,
        processId,
        parameters: {
          password: noPassword ? '' : password,
        },
      }

      const res = await process.step(stepPayload, true)

      analyticsProvider.sendAnalytics({
        type: 'event',
        action: 'click',
        event_category: 'button',
        event_label: `${baseEventTag}.${tagLabel}`,
        value: 0,
      })

      setLoading(false)

      const isStaticRedirect = mainRedirect(res.metadata)
      if (isStaticRedirect) {
        wizard.setPageState({})
        wizard.toPage(0)
      }
    } catch (err) {
      setLoading(false)

      const errorMessage = resolveErrorEntity({ intl, error: err.body })
      setError(errorMessage)

      analyticsProvider.sendAnalytics({
        type: 'event',
        action: 'click',
        event_category: 'button',
        event_label: `${baseEventTag}.${tagLabel}`,
        value: 1,
      })

      analyticsProvider.sendAnalytics({
        error: errorMessage,
      })
    }
  }

  const onCreate = () => {
    createAccount(false)
  }

  const onCancel = () => {
    createAccount(true)
  }

  return (
    <UXPCard.Card>
      <UXPCard.Content light>
        <Title>
          {intl.formatMessage(
            { id: 'onboard.thank-you.thank-you' },
            {
              subscriptionName,
            },
          )}
        </Title>
        <Body>
          {intl.formatMessage({
            id: 'onboard.thank-you.purchase-has-been-confirmed',
          })}
        </Body>
        <UXPCard.Divider />
        <Subtitle mb={2}>
          {intl.formatMessage({
            id: 'onboard-subscribe.thank-you.create-an-id',
          })}
        </Subtitle>
        <Body mb={2}>
          {intl.formatMessage({
            id: 'onboard-subscribe.thank-you.create-a-password',
          })}
        </Body>
        <MobileNumberField
          label={intl.formatMessage({ id: 'onboard.common.mobile-number' })}
          value={mobile}
          disabled
        />
        <Box mb={3} />
        <PasswordField
          required
          label={passwordFieldLabel}
          onChange={e => setPassword(e.target.value)}
          value={password}
          errorText={error}
        />
      </UXPCard.Content>
      <UXPCard.Divider />
      <UXPCard.Actions>
        <TextPrimaryButton onClick={onCancel}>
          {intl.formatMessage({ id: 'onboard.common.skip' })}
        </TextPrimaryButton>
        <Button color="primary" onClick={onCreate} isLoading={loading}>
          {intl.formatMessage({
            id: 'onboard.common.create',
          })}
        </Button>
      </UXPCard.Actions>
    </UXPCard.Card>
  )
}

export default ThankYouNewUser
