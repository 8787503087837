import React, { useEffect } from 'react'
import { useIntl } from 'react-intl'
import { get } from 'lodash'

import * as UXPCard from 'common/components/Card'
import { Title, Body } from 'common/components/Typography'
import { Button } from 'common/components/button/Button'
import AppLinks from './AppLinks'

import useMainRedirect from 'common/components/hooks/useMainRedirect'

import { analyticsProvider } from 'App.js'

const AlreadySubscribed = ({ wizard, flowType }) => {
  const intl = useIntl()
  const mainRedirect = useMainRedirect(`/${flowType}`)
  const pageState = wizard.getPageState()
  const { metadata, appleDownloadLink, androidDownloadLink } = pageState

  const productInfo = get(pageState, 'productDetailsDTO', {})
  const { shortServiceName, appName } = productInfo

  const pageTitle = 'already_subscribed'
  const baseEventTag = `${flowType}.already_subscribed`

  useEffect(() => {
    analyticsProvider.sendAnalytics({
      type: 'page_view',
      page_title: pageTitle,
      page_path: `/${flowType}#${pageTitle}`,
    })
  }, [flowType])

  const onOkay = () => {
    analyticsProvider.sendAnalytics({
      type: 'event',
      action: 'click',
      event_category: 'button',
      event_label: `${baseEventTag}.okay`,
      value: 0,
    })

    const isStaticRedirect = mainRedirect(metadata)
    if (isStaticRedirect) {
      wizard.setPageState({})
      wizard.toPage(0)
    }
  }

  return (
    <UXPCard.Card>
      <UXPCard.Content>
        <Title>
          {intl.formatMessage({
            id: 'onboard.already-subscribed.title',
          })}
        </Title>
        <Body>
          {intl.formatMessage(
            {
              id: 'onboard.already-subscribed.sign-in-and-enjoy',
            },
            {
              shortServiceName,
              appName,
            },
          )}
        </Body>
        <AppLinks
          appleDownloadLink={appleDownloadLink}
          androidDownloadLink={androidDownloadLink}
          baseEventTag={baseEventTag}
        />
      </UXPCard.Content>
      <UXPCard.Divider />
      <UXPCard.Actions>
        <Button onClick={onOkay}>
          {intl.formatMessage({ id: 'onboard.common.okay' })}
        </Button>
      </UXPCard.Actions>
    </UXPCard.Card>
  )
}

export default AlreadySubscribed
