import { Box } from '@mui/material'
import { Button } from 'common/components/button/Button'
import LockIcon from 'common/components/LockIcon'
import { Body } from 'common/components/Typography'

import FlowLayout from 'common/layouts/FlowLayout'
import React from 'react'
import { useIntl } from 'react-intl'

const TwoStepSetup = ({ wizard, getPageIndex, pages, twoStepTypeSelect }) => {
  const intl = useIntl()

  const handleEnableClicked = e => {
    e.preventDefault()
    if (wizard) {
      wizard.toPage(getPageIndex(pages.TwoStepTypeSelect))
    } else {
      twoStepTypeSelect({
        step: 'TwoStepTypeSelect',
      })
    }
  }

  return (
    <FlowLayout
      title={intl.formatMessage({
        id: 'separatedMultiStep.twoStepMethodPrompt.title',
      })}
      image={<LockIcon />}
    >
      <Body>
        {intl.formatMessage({
          id: 'separatedMultiStep.twoStepMethodPrompt.body',
        })}
      </Body>
      <Box mt={4}>
        <Button size="large" onClick={handleEnableClicked}>
          {intl.formatMessage({
            id: 'separatedMultiStep.twoStepMethodPrompt.ok',
          })}
        </Button>
      </Box>
    </FlowLayout>
  )
}

export default TwoStepSetup
