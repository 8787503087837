import React from 'react'
import makeStyles from '@mui/styles/makeStyles'
import classNames from 'classnames'

import { isUnsupportedBrowser } from 'common/utils/getBrowserDetails'

const useStyles = makeStyles(theme => ({
  page: {
    display: 'flex',
    flex: 1,
    flexDirection: props => (props.hasSession ? 'row' : 'column'),
    alignItems: props => (props.hasSession ? 'stretch' : 'center'),
    overflowX: 'hidden',
    overflowY: 'auto',
    [theme.breakpoints.down('sm')]: {
      paddingTop: 0,
      paddingBottom: 0,
      justifyContent: 'start',
    },
  },
  hasSession: {
    paddingTop: 0,
    paddingBottom: 0,
  },
  header: {
    zIndex: theme.zIndex.drawer + 1,
  },
}))

const AppWrapper = ({ children, ...props }) => {
  const classes = useStyles()
  const { hasSession, enableOnlyWizardRoutes } = props
  return (
    <div
      className={
        isUnsupportedBrowser()
          ? classNames(classes.page, 'flex justify-center items-center')
          : classNames(
              classes.page,
              'flex justify-center',
              hasSession && !enableOnlyWizardRoutes && classes.hasSession,
              {
                'items-center': !hasSession || enableOnlyWizardRoutes,
              },
              { 'flex-column': !hasSession },
            )
      }
    >
      {children}
    </div>
  )
}

export default AppWrapper
