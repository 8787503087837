const phoneUtil = require('google-libphonenumber').PhoneNumberUtil.getInstance()

export const getCountryCodeFromNumber = numberStr => {
  let countryCode = ''
  try {
    const numberProto = phoneUtil.parse('+' + numberStr, '')
    countryCode = numberProto.getCountryCode()
  } catch (e) {}
  return countryCode
}

export const getRegionCodeFromNumber = numberStr => {
  let regionCode = ''
  try {
    const numberProto = phoneUtil.parse('+' + numberStr, '')
    regionCode = numberProto.getRegionCodeFromNumber()
  } catch (e) {}
  return regionCode
}

export const getNationalNumberFromNumber = numberStr => {
  let nationalNumber = numberStr
  try {
    const numberProto = phoneUtil.parse('+' + numberStr, '')
    nationalNumber = numberProto.getNationalNumber()
  } catch (e) {}
  return nationalNumber
}
