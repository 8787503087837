import React from 'react'
import { compose } from 'redux'

import withStyles from '@mui/styles/withStyles'
import Link from '@mui/material/Link'

import Page from 'common/components/Page'
import RwdPaper from 'common/components/RwdPaper'
import { browserData } from 'common/constants'

const muStyles = theme => ({
  centerText: {
    margin: '0px 0px 46px',
    textAlign: 'center',
    [theme.breakpoints.down('md')]: {
      textAlign: 'left',
      margin: '0px 16px',
    },
  },
  title: {
    fontSize: '20px',
    paddingTop: '33px',
    color: '#302e45',
    [theme.breakpoints.up('sm')]: {
      fontSize: '2rem',
    },
  },
  subTitle: {
    fontSize: '1rem',
    marginTop: '19px',
    paddingBottom: '19px',
    color: theme.palette.primary.main,
    [theme.breakpoints.up('sm')]: {
      marginTop: '2rem',
      paddingBottom: '35px',
    },
  },
  box: {
    display: 'inline-table',
    padding: '12px 38px',
    [theme.breakpoints.up('sm')]: {
      padding: '12px 18px',
    },
    '&:hover': {
      background: '#c8c9c7',
      cursor: 'pointer',
    },
  },
  logo: {
    paddingBottom: '25px',
  },
  logoSection: {
    textAlign: 'center',
  },
  browserName: {
    fontSize: '16px',
    lineHeight: '20px',
    color: theme.palette.primary.main,
    marginBottom: '3px',
  },
  companyName: {
    fontSize: '14px',
    lineHeight: '16px',
    color: '#7f798a',
  },
})

class UnsupportedBrowser extends React.Component {
  renderBox = () => {}
  render = () => {
    const { classes } = this.props

    return (
      <RwdPaper>
        <div className={classes.centerText}>
          <Page.Title className={classes.title}>
            Your browser is unsupported
          </Page.Title>
          <Page.Title className={classes.subTitle}>
            Upgrade to a modern browser to experience amdocsID
          </Page.Title>
          <div className={classes.logoSection}>
            {browserData.map((browser, id) => {
              return (
                <Link href={browser.link}>
                  <div display="inline-block" className={classes.box} id={id}>
                    <img
                      src={browser.logo}
                      alt={browser.browserName}
                      className={classes.logo}
                    />
                    <div className={classes.browserName}>
                      {browser.browserName}
                    </div>
                    <div className={classes.companyName}>
                      {browser.companyName}
                    </div>
                  </div>
                </Link>
              )
            })}
          </div>
        </div>
      </RwdPaper>
    )
  }
}

export default compose(withStyles(muStyles))(UnsupportedBrowser)
