import messagesEn from '../../locales/en.json'
import messagesEs from '../../locales/es.json'
import { availableLocales as bundledLocales } from '@admin-ui-common/utils'
import { hasUIFeatureFlag } from 'common/services/FeatureFlagsService'

const EN_LANG = 'en'
const ES_LANG = 'es'
const definedLanguages = {
  [EN_LANG]: messagesEn,
  [ES_LANG]: { ...messagesEn, ...messagesEs },
}

const proxyHandler = {
  get: (obj, prop) => (prop in obj ? obj[prop] : messagesEn),
}

export const getAvailableLocales = () => {
  let configuredLocales
  if (Array.isArray(hasUIFeatureFlag('availableLocales'))) {
    configuredLocales = new Set(hasUIFeatureFlag('availableLocales'))
  }
  return configuredLocales || bundledLocales
}
export const messagesMap = new Proxy(definedLanguages, proxyHandler)
