import { GlobalFooter } from '@admin-ui-common/base-user'
import LocaleSelect from 'lander/signin/LocaleSelect'
import FeatureFlagsService from 'common/services/FeatureFlagsService'
import { Box, Typography } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { useIntl } from 'react-intl'
import { TextPrimaryButton } from '../button/Button'

const useStyles = makeStyles(theme => ({
  footerActionContainer: {
    display: 'flex',
    paddingTop: theme.spacing(1.5),
    paddingBottom: theme.spacing(1.5),
    flexDirection: 'column',
    alignItems: 'flex-start',
    [theme.breakpoints.up('sm')]: {
      flexDirection: 'row',
      alignItems: 'center',

      gridGap: '10px',
    },
  },
  button: {
    textTransform: 'none',
    color: 'inherit',
    '&:hover': {
      textDecoration: 'none',
    },
  },
}))

function Footer() {
  const classes = useStyles()
  const intl = useIntl()
  const termsOfServiceURL = FeatureFlagsService.get('system.termsOfServiceURL')
  const privacyPolicyURL = FeatureFlagsService.get('system.privacyPolicyURL')

  return (
    <GlobalFooter>
      <Box className={classes.footerActionContainer}>
        {FeatureFlagsService.get('uiFeatureFlags.idp.showLanguageSelector') ? (
          <LocaleSelect />
        ) : null}
        {termsOfServiceURL || privacyPolicyURL ? (
          <Box
            display="flex"
            gap={{ xs: '8px', sm: '12px' }}
            alignItems="center"
          >
            {termsOfServiceURL && (
              <TextPrimaryButton
                target="_blank"
                rel="noopener noreferrer nofollow"
                size="small"
                variant="text"
                href={termsOfServiceURL}
              >
                <Typography variant="body2">
                  {intl.formatMessage({ id: 'terms.of.service' })}
                </Typography>
              </TextPrimaryButton>
            )}
            {privacyPolicyURL && (
              <TextPrimaryButton
                target="_blank"
                rel="noopener noreferrer nofollow"
                size="small"
                variant="text"
                href={privacyPolicyURL}
              >
                <Typography variant="body2">
                  {intl.formatMessage({ id: 'privacy.policy' })}
                </Typography>
              </TextPrimaryButton>
            )}
          </Box>
        ) : null}
      </Box>
    </GlobalFooter>
  )
}

export default Footer
