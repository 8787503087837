import { combineReducers } from 'redux'
import { merge } from 'lodash'

import {
  LOAD_ACCOUNTS_REQUEST,
  LOAD_ACCOUNTS_SUCCESS,
  LOAD_ACCOUNTS_FAILURE,
  LOAD_USERS_REQUEST,
  LOAD_USERS_SUCCESS,
  LOAD_USERS_FAILURE,
} from 'common/actions/accounts'
import { END_SESSION_SUCCESS } from 'common/actions/session'

const accountsInitialState = {
  data: [],
  inProgress: false,
  error: null,
}
const accounts = (state = accountsInitialState, action) => {
  switch (action.type) {
    case LOAD_ACCOUNTS_REQUEST:
      return {
        ...state,
        inProgress: true,
        error: null,
      }
    case LOAD_ACCOUNTS_SUCCESS:
      return {
        ...state,
        data: action.accounts,
        inProgress: false,
        error: null,
      }
    case LOAD_ACCOUNTS_FAILURE:
      return {
        ...state,
        inProgress: false,
        error: action.error,
      }
    case END_SESSION_SUCCESS:
      return accountsInitialState
    default:
      return state
  }
}

const usersByAccountId = (state = {}, action) => {
  switch (action.type) {
    case LOAD_USERS_REQUEST: {
      const { accountId } = action.payload
      const nextState = {
        [accountId]: {
          inProgress: true,
          error: null,
        },
      }

      return merge({}, state, nextState)
    }
    case LOAD_USERS_SUCCESS: {
      const { accountId, users, totalUsers } = action.payload

      const nextState = {
        [accountId]: {
          inProgress: false,
          error: null,
          data: users,
          totalUsers,
        },
      }

      return merge({}, state, nextState)
    }
    case LOAD_USERS_FAILURE: {
      const { accountId, error } = action.payload

      const nextState = {
        [accountId]: {
          inProgress: false,
          error,
          data: [],
        },
      }

      return merge({}, state, nextState)
    }
    default:
      return state
  }
}

export default combineReducers({
  accounts,
  usersByAccountId,
})
