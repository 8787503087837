import React from 'react'
import classNames from 'classnames'

import withStyles from '@mui/styles/withStyles'
import Checkbox from '@mui/material/Checkbox'
import FormControlLabel from '@mui/material/FormControlLabel'

const checkBoxStyles = theme => ({
  root: {
    color: theme.palette.primary.main,
  },
  labelProps: {
    fontSize: 12,
  },
  controlLabel: {
    marginRight: 0,
  },
  mainTop: {
    display: 'flex',
    alignItems: 'flex-start',
  },
  labelTop: {
    paddingTop: 6,
  },
  checked: {},
})

class CheckBox extends React.Component {
  render() {
    const { label, classes, alignTop, ...rest } = this.props
    const labelControlClasses = classNames(
      classes.controlLabel,
      alignTop && classes.mainTop,
    )
    const labelClasses = classNames(
      classes.labelProps,
      alignTop && classes.labelTop,
    )

    return (
      <FormControlLabel
        className={labelControlClasses}
        classes={{
          label: labelClasses,
        }}
        control={
          <Checkbox
            className={classNames(classes.root, classes.checked)}
            {...rest}
            color="primary"
          />
        }
        label={label}
      />
    )
  }
}

export default withStyles(checkBoxStyles)(CheckBox)
