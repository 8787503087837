import React from 'react'

const withAuthDialog = WrappedComponent => {
  return class extends React.Component {
    state = {
      dialogOpen: false,
      showVerify: false,
      mode: '',
    }

    onChangeDialogOpen = () =>
      this.setState({
        dialogOpen: true,
        showVerify: false,
        mode: this._getMode(),
      })

    onVerifyDialogOpen = () =>
      this.setState({
        dialogOpen: true,
        showVerify: true,
        mode: this._getMode(),
      })

    onChangeDialogClose = () =>
      this.setState({
        dialogOpen: false,
        mode: '',
      })

    _getMode = () => {
      const { auth, pendingAuth, activatingAuth } = this.props
      return auth || pendingAuth || activatingAuth ? 'update' : 'add'
    }

    addDialogOpen = () =>
      this.setState({
        dialogOpen: true,
        showVerify: false,
        mode: 'add',
      })

    deleteDialogOpen = () =>
      this.setState({
        dialogOpen: true,
        showVerify: false,
        mode: 'delete',
      })

    render() {
      return (
        <WrappedComponent
          {...this.state}
          {...this.props}
          onChangeDialogOpen={this.onChangeDialogOpen}
          onVerifyDialogOpen={this.onVerifyDialogOpen}
          onChangeDialogClose={this.onChangeDialogClose}
          addDialogOpen={this.addDialogOpen}
          deleteDialogOpen={this.deleteDialogOpen}
        />
      )
    }
  }
}

export default withAuthDialog
