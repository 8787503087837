import React from 'react'
import PropTypes from 'prop-types'
import { noop } from 'lodash'

import Backdrop from '@mui/material/Backdrop'
import { Body2 } from 'common/components/Typography'
import Box from '@mui/material/Box'
import CircularProgress from '@mui/material/CircularProgress'
import makeStyles from '@mui/styles/makeStyles'

const useStyles = makeStyles(theme => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    backgroundColor: 'rgba(255, 255, 255, 0.8)',
  },
  progress: {
    color: theme.palette.primary.main,
  },
  label: {
    fontWeight: theme.typography.fontWeightBold,
  },
}))

const BackdropLoader = ({ open, label = '', size = 84, onClick = noop }) => {
  const classes = useStyles()

  return (
    <Backdrop
      open={open}
      className={classes.backdrop}
      onClick={onClick}
      unmountOnExit
      style={{
        transitionDelay: open ? '800ms' : '0ms',
      }}
    >
      <Box display="flex" flexDirection="column" alignItems="center">
        <CircularProgress className={classes.progress} size={size} />
        <Box m={1} />
        <Body2 className={classes.label}>{label}</Body2>
      </Box>
    </Backdrop>
  )
}

BackdropLoader.propTypes = {
  open: PropTypes.bool.isRequired,
  label: PropTypes.string,
  size: PropTypes.number,
  onClick: PropTypes.func,
}

export default BackdropLoader
