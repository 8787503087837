import conf from 'conf'
import i from 'common/utils/i'
import qs from 'qs'
import { isEmpty } from 'lodash'

export const completeStepAction =
  'com.uxpsystems.mint.framework.bpm.process.CompleteStepAction'

export const completeAuthStepAction =
  'com.uxpsystems.mint.framework.bpm.process.AuthenticationCompleteStepAction'

export const manualInputStepAction =
  'com.uxpsystems.mint.framework.bpm.process.ManualInputStepAction'

const urlRoot = `${conf.apiRoot}/process`

const start = id => i.post(`${urlRoot}/start/${id}`)

const startGet = (
  id,
  { ignoreErrorRedirect = false, ...params } = {},
  options,
) => {
  const query = isEmpty(params) ? '' : `?${qs.stringify(params)}`
  return i.get(`${urlRoot}/start/${id}${query}`, {
    ignoreErrorRedirect,
    ...options,
  })
}

const step = (data, ignoreErrorRedirect = false) =>
  i.put(`${urlRoot}/step`, {
    data,
    ignoreErrorRedirect,
  })

const complete = response => {
  const isProcessComplete = isCompleteResponse(response)

  return isProcessComplete
    ? Promise.resolve(response)
    : Promise.reject(response)
}

const isComplete = (userAuthenticated, lastStep = null) =>
  !!lastStep || !!userAuthenticated

const isCompleteResponse = response =>
  isComplete(response.body.userAuthenticated, response.body.lastStep)

const isProcess = (item = {}) => {
  if (typeof item !== 'object') {
    return false
  }
  return Boolean(item.processId)
}

const isAuthComplete = response =>
  response.body.lastStep && response.body.userAuthenticated

const processUtil = {
  idRoot: 'com.uxpsystems.mint.platform',
  isProcess,
  start,
  startGet,
  step,
  complete,
  isComplete,
  isCompleteResponse,
  isAuthComplete,
}

export default processUtil
