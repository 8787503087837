import { useSelector } from 'react-redux'
import Footer from './footer/Footer'

function UnauthenticatedFooter() {
  const hasSession = useSelector(state => state.session.hasSession)

  return hasSession ? null : <Footer />
}

export default UnauthenticatedFooter
